import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Close } from '../../images/dailyCheckIn'

const ExchangeStoreNotice = observer(({ visible, close, ...params }) => {
  const navigate = useNavigate()

  const Title = () => {
    return (
      <div className='py-3 font-bold' style={{ fontSize: '5vw' }}>
        올포인트 공지
      </div>
    )
  }

  const TextComponent = () => {
    return (
      <div style={{ fontSize: '4vw' }} className='text-[#525052] font-semibold'>
        <div className='h-4' />
        <div>
          고객님들의 편리를 위해 지금까지는 유효기간이 지난 올포인트도 사용이
          가능했으나, 8월 15일부터는 포인트가 소멸될 예정이에요.
        </div>
        <div className='h-2' />
        <div style={{ fontSize: '3vw' }}>
          * 소멸 대상 포인트는 지급된지 1년 이상 되었으나 아직까지 사용되지 않은
          포인트이며, 이미 사용된 포인트는 소멸되지 않아요.
        </div>
        <div style={{ fontSize: '3vw' }}>
          * 8월 15일 이후에는 유효기간이 6개월로 변경되오니 참고해주세요.
        </div>
        <div className='h-2' />
      </div>
    )
  }

  const ButtonComponent = () => {
    return (
      <button
        type='button'
        className='py-4 mt-4 w-3/5 bg-red-400 rounded-xl text-white text-base font-bold'
        onClick={() => {
          navigate(`/exchange-store`)
        }}
      >
        포인트 교환소 바로가기
      </button>
    )
  }

  return (
    <div>
      {/* 딤드 배경 */}
      <button
        type='button'
        className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-20'
        onClick={close}
        aria-label='background'
      />
      {/* 모달 상자 */}
      <div
        className='fixed left-1/2 py-6 z-30 bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
        style={{ top: '48%', width: '90vw', height: '44vh' }}
      />

      <button
        type='button'
        className='fixed p-2 z-40 bg-gray-300 rounded-full'
        style={{ left: '82%', top: '27%' }}
        onClick={() => {
          close()
        }}
      >
        <Close className='w-6 h-6' />
      </button>

      {/* 모달 내용 + 받기 버튼 */}
      <div
        style={{ left: '5%', top: '24%', width: '90vw', height: '44vh' }}
        className='p-4 flex flex-col fixed items-center justify-between z-30'
      >
        <Title />
        <TextComponent />
        <ButtonComponent />
      </div>
    </div>
  )
})

export default ExchangeStoreNotice
