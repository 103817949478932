import { observer } from 'mobx-react-lite'
import {
  TouchEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useMount } from 'react-use'
import styled from 'styled-components'

import { viewerStore } from '../../store'
import { DocumentAlign } from '../../types/viewerOptions'
import { throttle } from '../../utils/throttle'

export const ScrollSlider = observer(() => {
  const sliderRef = useRef<ISlider>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [currentPage, setCurrentPage] = useState<number>(0)

  useEffect(() => {
    const viewport = viewerStore.viewport
    if (!viewport) return

    const updateScrollProgress = throttle(() => {
      const scrollPos =
        viewerStore.options.documentAlign === DocumentAlign.Horizontal
          ? viewport.scrollLeft
          : viewport.scrollTop
      const scrollTotal =
        viewerStore.options.documentAlign === DocumentAlign.Horizontal
          ? viewport.scrollWidth - 2 * viewport.clientWidth
          : viewport.scrollHeight - 2 * viewport.clientHeight

      const progress = Math.min(Math.max(scrollPos / scrollTotal, 0), 1)
      const percent = Math.floor(progress * 100)

      sliderRef.current?.update(progress)

      if (!inputRef.current) return

      inputRef.current.value = String(percent)
      inputRef.current.style.width = `${inputRef.current.value.length}ch`
    }, 20)

    updateScrollProgress()
    viewport.addEventListener('scroll', updateScrollProgress)

    return () => viewport.removeEventListener('scroll', updateScrollProgress)
  }, [viewerStore.options.documentAlign, viewerStore.viewport])

  useEffect(() => {
    const viewport = viewerStore.viewport
    if (!viewport) return

    const updateCurrentPage = throttle(() => {
      setCurrentPage(viewerStore.currentPage)
    }, 20)

    viewport.addEventListener('scroll', updateCurrentPage)

    return () => viewport.removeEventListener('scroll', updateCurrentPage)
  }, [viewerStore.viewport])

  const handleSlide = useCallback(
    (dragPercent: number) => {
      const viewport = viewerStore.viewport
      if (!viewport) return

      const desiredScroll =
        viewerStore.options.documentAlign === DocumentAlign.Horizontal
          ? (viewport.scrollWidth - viewport.clientWidth) * dragPercent
          : (viewport.scrollHeight - viewport.clientHeight) * dragPercent

      viewport.scrollTo(
        viewerStore.options.documentAlign === DocumentAlign.Horizontal
          ? { left: desiredScroll }
          : { top: desiredScroll },
      )
    },
    [viewerStore.options.documentAlign, viewerStore.viewport],
  )

  return (
    <ScrollSliderWrapper>
      <Slider ref={sliderRef} onSlide={handleSlide} />
      {viewerStore.options.documentAlign === DocumentAlign.Vertical ? (
        <>
          <StyledInput ref={inputRef} disabled />%
        </>
      ) : (
        <PageText>
          {currentPage + 1}/{viewerStore.maxPage + 1}
        </PageText>
      )}
    </ScrollSliderWrapper>
  )
})

interface SliderProps {
  barColor?: string
  trackColor?: string
  width?: number
  height?: number
  size?: number
  color?: string
  customClass?: string
  onSlide?: (percentage: number) => void
}

interface ISlider {
  update: (progress: number) => void
}

const Slider = forwardRef<ISlider, SliderProps>((props, ref) => {
  const trackRef = useRef<HTMLDivElement>(null)
  const barRef = useRef<HTMLDivElement>(null)
  const progressRef = useRef<HTMLProgressElement>(null)

  // External control for updating the progress bar
  useImperativeHandle(ref, () => ({
    update: (progress: number) => {
      if (trackRef.current && barRef.current) {
        const trackWidth = trackRef.current.clientWidth
        barRef.current.style.width = `${trackWidth * progress}px`
      }
    },
  }))

  const handleSlide = throttle((e: TouchEvent<HTMLProgressElement>) => {
    if (!progressRef.current || !trackRef.current || !barRef.current) return

    const trackBounds = progressRef.current.getBoundingClientRect()
    const slidePercent = Math.min(
      Math.max((e.touches[0].clientX - trackBounds.x) / trackBounds.width, 0),
      1,
    )

    const trackWidth = trackRef.current.clientWidth
    barRef.current.style.width = `${trackWidth * slidePercent}px`

    props.onSlide?.(slidePercent)
  }, 20)

  return (
    <SliderWrapper>
      <Track
        bgColor='#F6F6FA'
        height={4}
        className={props.customClass}
        ref={trackRef}
      >
        <Progress>
          <ProgressBar ref={barRef} color='#464953' height={4} />
          <Handle size={12} color='#464953' />
        </Progress>
      </Track>
      <HiddenProgress ref={progressRef} onTouchMove={handleSlide} />
    </SliderWrapper>
  )
})

const ScrollSliderWrapper = styled.div`
  width: calc(100% - 32px);
  padding: 14px 16px;
  display: flex;
  align-items: center;
  position: relative;
`

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`

const Track = styled.div<{ bgColor: string; height: number }>`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
  height: ${({ height }) => height}px;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
`

const Progress = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
`

const ProgressBar = styled.div<{ color: string; height: number }>`
  background-color: ${({ color }) => color};
  height: ${({ height }) => height}px;
  border-radius: 24px;
`

const Handle = styled.div<{ size: number; color: string }>`
  background-color: ${({ color }) => color};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HiddenProgress = styled.progress`
  width: 120%;
  height: 40px;
  position: absolute;
  left: 0;
  opacity: 0;
`

const StyledInput = styled.input`
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: -0.20000000298023224px;
  text-align: right;
  width: 28px;
  padding: 0px;
  margin-left: 16px;
  margin-right: 4px;
  border: none;
  color: black;
  -webkit-text-fill-color: black;
  opacity: 1;
`

const PageText = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: -0.20000000298023224px;
  margin-left: 16px;
`
