import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import WatermelonAction from '../../../actions/WatermelonAction'

const CloseButton = ({ onClick }) => {
  const [isPressed, setIsPressed] = useState(false)

  const handlePress = () => {
    setIsPressed(true)
    onClick()
  }

  const handleRelease = () => {
    setIsPressed(false)
  }

  return (
    <div
      className={`default absolute right-0 -top-10 z-20 duration-200 ${
        isPressed ? 'scale-90' : 'scale-100'
      }
      pointer-events-auto
      `}
      aria-hidden='true'
      onPointerDown={handlePress}
      onPointerUp={() => {
        handleRelease()
        onClick()
      }}
      onPointerCancel={handleRelease}
      onPointerLeave={handleRelease}
    >
      <img
        className='w-8 h-8'
        alt='Close'
        src='../images/treasureHunt/whiteClose.png'
      />
    </div>
  )
}

const ContactPermissionModal = observer(({ isOpen, handleClose }) => {
  const [isPressed, setIsPressed] = useState(false)

  const handleRelease = () => {
    setIsPressed(false)
  }

  useEffect(() => {
    if (isPressed) {
      setTimeout(() => {
        setIsPressed(false)
      }, 500)
    }
  }, [isPressed])

  const content = (
    <div className='items-center justify-center default'>
      <div className='default font-bold  text-[5vw] mb-0'>친구 찾기</div>

      <div className='default font-bold  text-[4vw] mb-2 mt-2'>
        올웨이즈에서 친구 랭킹을 확인하기 위해 연락처 접근과 수집 동의가
        필요해요
      </div>

      <button
        type='button'
        className={`${isPressed ? 'transform scale-95 ' : ''}`}
        style={{
          left: '15%',
          fontSize: '4vw',
          borderRadius: '8px',
          padding: 8,
          marginTop: 12,
          width: '100%',
          zIndex: 2000,
          bottom: '10vw',
          backgroundColor: '#FF5951',
          color: 'white',
        }}
        onPointerDown={() => {
          setIsPressed(true)
        }}
        onPointerCancel={handleRelease}
        onPointerLeave={handleRelease}
        onClick={() => {
          WatermelonAction.checkContactPermission()
        }}
      >
        동의하고 친구 찾기
      </button>
    </div>
  )

  if (!isOpen) {
    return null
  }

  return (
    <>
      <div
        id='ModalBackground'
        className='fixed top-0 left-0 w-full h-full bg-black opacity-80 z-[30] transition-opacity duration-300'
      />
      <div
        className={`fixed top-1/3 left-1/2 py-4 z-40 bg-white rounded-xl px-4 w-[76%] transform transition-all duration-300 ${
          isOpen
            ? 'scale-100 translate-x-[-50%] translate-y-[-50%]'
            : 'scale-0 translate-x-[-50%] translate-y-[-50%]'
        }
  `}
      >
        {content}

        <CloseButton onClick={handleClose} />
      </div>
    </>
  )
})
export default ContactPermissionModal
