import styled, { css } from 'styled-components'

export const TouchArea = styled.div<{
  width: number
  height: number
  disabled?: boolean
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ disabled }) =>
    disabled &&
    css`
      * {
        /* fill: #bcbdc3; */
        color: #bcbdc3;
      }
    `}
`
