import React from 'react'
import { FiChevronLeft } from 'react-icons/fi'

import { useMyFlow } from '../../hooks/altoon/useMyFlow.ts'

const HeaderComponent = ({
  guideOn,
  chapterInfo,
  nextChapter,
  handleModal,
}) => {
  // const navigate = useNavigate()
  const { pop } = useMyFlow()

  return (
    <section
      className={`fixed top-0 w-full h-[8vh] font-bold z-10 py-2 flex flex-row items-center justify-between overflow-hidden bg-[#000000ba] ${
        guideOn
          ? 'opacity-100 animate-slideDown'
          : 'opacity-0 animate-slideUpFade'
      }`}
    >
      <button
        type='button'
        aria-label='back'
        className='px-3 py-2 whitespace-nowrap'
        onClick={() => {
          if (!nextChapter) {
            handleModal()
          } else {
            pop()
          }
        }}
        style={{ display: guideOn ? 'flex' : 'none' }}
      >
        <FiChevronLeft className='w-8 h-8 text-white' />
      </button>
      <div
        className={`text-start text-[6vw] text-white px-2 py-2 whitespace-nowrap ${
          guideOn ? 'visible' : 'hidden'
        }`}
      >
        {chapterInfo?.chapterTitle?.length > 16
          ? `${chapterInfo?.chapterTitle.slice(0, 16)}...`
          : chapterInfo?.chapterTitle}
      </div>
      <div className='w-10' />
    </section>
  )
}

export default HeaderComponent
