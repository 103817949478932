import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { OverlayProvider } from '@toss/use-overlay'
import React from 'react'
import { useState } from 'react'

import { CommentPage } from './Viewer/components/Comment/CommentPage'

const AltoonNovelCommentListPage = () => {
  const [queryClient] = useState(() => new QueryClient())
  return (
    <QueryClientProvider client={queryClient}>
      <OverlayProvider>
        <CommentPage />
      </OverlayProvider>
    </QueryClientProvider>
  )
}

export default AltoonNovelCommentListPage
