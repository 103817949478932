import commaNumber from 'comma-number'
import React from 'react'
import {
  Coupon,
  PigFoodColored,
  PigFoodLargeColor,
} from 'src/images/dailyCheckIn'

const data = [
  { id: 0, rewardType: 'food', value: 100 },
  { id: 1, rewardType: 'food', value: 150 },
  { id: 2, rewardType: 'food', value: 250 },
  { id: 3, rewardType: 'food', value: 500 },
  { id: 4, rewardType: 'food', value: 700 },
  { id: 5, rewardType: 'foodLarge', value: 1000 },
  { id: 6, rewardType: 'foodLarge', value: 2000 },
]

const Content = ({ each, count }) => {
  const target = each.id === count
  const final = each.id === 6
  const last = each.id < count
  const day = each.id + 1

  const RewardImage = ({ type }) => {
    if (type === 'food') {
      return <PigFoodColored className='h-6 my-2' />
    }
    if (type === 'coupon') {
      return <Coupon className='h-10' />
    }
    if (type === 'foodLarge') {
      return <PigFoodLargeColor className='h-10' />
    }
  }

  return (
    <div
      className={`${final ? 'w-36' : 'w-16'} h-28 mx-1 rounded-lg border-2 ${
        target ? 'border-red-500' : 'border-[#525052]'
      } ${last ? 'opacity-60' : null}`}
    >
      {last && (
        <div
          className={`absolute bg-white border-2 border-red-500 text-red-500 rounded-md px-1 -rotate-12 text-sm mt-7 ${
            final ? 'ml-12' : 'ml-3'
          }`}
        >
          성공!
        </div>
      )}
      <div
        className={`w-full h-5 ${
          target ? 'bg-red-500' : 'bg-[#525052]'
        } text-white flex items-center justify-center text-sm`}
      >
        {`${day}일차`}
      </div>
      <div className='flex flex-col items-center justify-center pt-2'>
        <RewardImage type={each.rewardType} />
        <div
          className={`w-full h-10 flex items-center justify-center font-bold ${
            target ? 'text-red-500' : 'text-[#525052]'
          }`}
        >
          {commaNumber(each.value)}
          {each.rewardType === 'coupon' ? '원' : 'g'}
        </div>
      </div>
    </div>
  )
}

const ConsecutiveCheckInContent = ({ count, available }) => {
  if (available) {
    return (
      <div className='w-full h-full my-4'>
        <div className='flex flex-row items-center justify-between mb-4'>
          {data.slice(0, 4).map((each) => (
            <Content each={each} count={count} />
          ))}
        </div>
        <div className='flex flex-row items-center justify-between'>
          {data.slice(4, 8).map((each) => (
            <Content each={each} count={count} />
          ))}
        </div>
      </div>
    )
  }
}

export default ConsecutiveCheckInContent
