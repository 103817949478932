import styled from 'styled-components'

interface PopupProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}

export const Popup = ({ isOpen, onClose, children }: PopupProps) => {
  if (!isOpen) {
    return null
  }
  return (
    <>
      <Backdrop onClick={onClose} />
      <PopupContainer isOpen={isOpen}>
        <PopupContent>{children}</PopupContent>
      </PopupContainer>
    </>
  )
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`

const PopupContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: ${({ isOpen }) =>
    isOpen ? 'translate(-50%, -50%)' : 'translate(-50%, -200%)'};
  transition: transform 0.3s ease-in-out;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 100vw;
  width: max-content;
`

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
