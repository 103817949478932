import React, { useLayoutEffect, useRef, useState } from 'react'

function TruncateText({ text, width, maxLine = 2 }) {
  const textRef = useRef(null)
  const [truncatedText, setTruncatedText] = useState(text)
  const [isReady, setIsReady] = useState(false)

  useLayoutEffect(() => {
    const handleResize = () => {
      const element = textRef.current
      const lineHeight = parseInt(getComputedStyle(element).lineHeight, 10)
      const maxHeight = lineHeight * maxLine

      if (element.offsetWidth > width || element.offsetHeight > maxHeight) {
        let newText = text
        while (
          (element.offsetWidth > width || element.offsetHeight > maxHeight) &&
          newText.length > 0
        ) {
          newText = newText.slice(0, -1)
          element.textContent = `${newText}...`
        }
        setTruncatedText(`${newText}...`)
      }
    }

    setIsReady(true)
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [text, maxLine, width])

  return (
    <div
      ref={textRef}
      className='overflow-hidden leading-tight line-clamp-2 text-ellipsis'
      style={{
        width: `${width}px`,
      }}
    >
      {isReady ? <span>{truncatedText}</span> : null}
    </div>
  )
}

export default TruncateText
