import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import AlwayzObserver from './AlwayzObserver'

// type AlwayzImageFit = 'contain' | 'cover' | 'fill' | 'initial' | 'none'

// type AlwayzImageProps = {
//   src: string
//   width: string
//   height: string
//   lazy?: boolean
//   placeholder?: React.ReactNode
//   onLoad?: (...args: any) => void
//   fit?: AlwayzImageFit
//   alt?: string
//   className?: string
//   onClick?: (e: MouseEvent<HTMLDivElement>) => void
// }

const browserCachedImages = new Set()

const AlwayzImage = memo(
  ({
    src,
    fit = 'initial',
    lazy = false,
    alt,
    placeholder = <DefaultPlaceHolder />,
    width,
    height,
    className,
    onLoad,
    onClick,
  }) => {
    const thisRef = useRef(null)
    const imgRef = useRef(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isIntersecting, setIsIntersecting] = useState(false)

    useEffect(() => {
      setIsLoaded(false)
    }, [src])

    const shouldRenderImg = useMemo(() => {
      if (browserCachedImages.has(src)) {
        return true
      }
      if (!lazy) {
        return true
      }
      if (lazy && isIntersecting) {
        return true
      }
      return false
    }, [isIntersecting, lazy, src])

    const onIntersect = useCallback((entry) => {
      setIsIntersecting(entry.isIntersecting)
    }, [])

    const onImageLoad = useCallback(() => {
      browserCachedImages.add(src)
      setIsLoaded(true)
      onLoad?.(thisRef, imgRef.current)
    }, [onLoad, src])

    useEffect(() => {
      if (shouldRenderImg) {
        const loadImage = (imageUrl) => {
          const tempImg = new Image()
          tempImg.onload = () => {
            if (imgRef.current) {
              imgRef.current.src = imageUrl
              onImageLoad()
            }
          }
          tempImg.onerror = () => {
            throw new Error('ImageLoadError')
          }
          tempImg.src = imageUrl
        }

        const img = new Image()
        img.onload = onImageLoad
        img.onerror = () => {
          // 올툰 에러로 인한 변경
          // 에러가 나는 이유: (추정) aws에 올라갈때 메타데이터가 제대로 맵핑되지 않음.
          // 업로드 시 제대로 올라가도록 하는 것이 근본적인 해결책이나, 구체적인 원인 파악이 어려워서 (잘 되다가 갑자기 특정 썸네일만 문제 발생)
          // 우선적으로 해결하기 위해 원본 이미지를 직접 로드하는 방법을 사용
          if (src?.includes('resize.ilevit.com')) {
            try {
              const base64Data = src.split('/').pop()
              const decodedData = JSON.parse(atob(base64Data))
              const originalUrl = `https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/${decodedData.key}`

              // 원본 이미지 로드 시도
              loadImage(originalUrl)
            } catch (error) {
              throw new Error('ImageLoadError')
            }
          }
        }
        img.src = src
      }
    }, [onImageLoad, shouldRenderImg, src])

    return (
      <div
        className={className}
        style={{
          width,
          height,
          isolation: 'isolate',
          overflow: 'hidden',
        }}
        ref={thisRef}
        onClick={onClick}
      >
        <AlwayzObserver callback={onIntersect} className='h-full'>
          {shouldRenderImg ? (
            <img
              ref={imgRef}
              src={src}
              style={{
                display: 'block',
                width: '100%',
                height: '100%',
                position: 'relative',
                opacity: isLoaded ? 1 : 0,
                transition: 'opacity 0.15s ease-out',
                objectFit: fit,
              }}
              alt={alt}
            />
          ) : (
            placeholder
          )}
        </AlwayzObserver>
      </div>
    )
  },
)

const DefaultPlaceHolder = () => {
  return (
    <div
      style={{
        background: '#d8d8d8',
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    />
  )
}

export default AlwayzImage
