import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import THModalStore from '../../../stores/treasureHunt/THModalStore'
import THUserStore from '../../../stores/treasureHunt/THUserStore'

const GiftCopmponent = observer(() => {
  return (
    <div
      className='flex text-[4vw] flex-row justify-center items-center mr-2 rounded-full bg-[#fff] pr-[16px] pl-[8px] py-[4px] text-[#313138] font-bold'
      onClick={() => {
        THModalStore.setType({
          type: 'infoList',
          config: {
            title: '선물상자 안내',
            textArray: [
              '미션을 완료하면 선물상자를 얻어요',
              '선물상자에서는 포인트 혹은 번개를 얻을 수 있어요',
              '부적절한 방법으로 선물상자를 취득하면 회수될 수 있어요',
              '해당 이벤트는 당사 사정에 의해 사전 공지 없이 내용이 변경되거나 종료될 수 있어요',
              '자세한 문의는 고객센터로 문의해주세요',
            ],
          },
        })
      }}
    >
      <img
        className='w-6 h-6 mr-2'
        alt='cash'
        src='../images/treasureHunt/gift.png'
      />
      {`${commaNumber(THUserStore?.gift ?? 0)}`}개
    </div>
  )
})

export default GiftCopmponent
