import { observer } from 'mobx-react-lite'
import React from 'react'

import THUserStore from '../../../stores/treasureHunt/THUserStore'
import MissionButton from './MissionButton'

const BottomButtons = observer(() => {
  return (
    <div className='flex flex-row w-full justify-start items-center overflow-x-scroll p-2 pl-0 py-1'>
      {THUserStore?.dailyMission?.missions?.length > 0 &&
        [...THUserStore?.dailyMission?.missions]
          ?.sort((a, b) => {
            const now = new Date()

            // endAt이 지난 경우를 확인
            const aExpired = new Date(a.endAt) < now
            const bExpired = new Date(b.endAt) < now
            if (aExpired && !bExpired) return 1
            if (!aExpired && bExpired) return -1

            // 첫 번째 우선순위: clearedAt이 있고 receivedAt은 없는 객체
            const aFirstPriority = a.clearedAt && !a.receivedAt
            const bFirstPriority = b.clearedAt && !b.receivedAt
            if (aFirstPriority && !bFirstPriority) return -1
            if (!aFirstPriority && bFirstPriority) return 1

            // 두 번째 우선순위: clearedAt이 없는 객체, 이러한 객체들은 endAt으로 정렬
            const aSecondPriority = !a.clearedAt
            const bSecondPriority = !b.clearedAt
            if (aSecondPriority && bSecondPriority) {
              return new Date(a.endAt) - new Date(b.endAt) // endAt로 정렬
            }
            if (aSecondPriority) return -1
            if (bSecondPriority) return 1

            // 세 번째 우선순위: receivedAt이 있는 객체
            return 0
          })
          .map((mission) => {
            return <MissionButton key={Math.random()} mission={mission} />
          })}
    </div>
  )
})

export default BottomButtons
