import BrandDealPriceBackground from './BrandDealPriceBackground.png'
import CategoryDealMainImage from './CategoryDealMainImage.png'
import ClockIconBlack from './ClockIconBlack.png'
import Close from './Close.png'
import Fire from './Fire.png'
import Locked from './Locked.png'
import LogoApp from './LogoApp.png'
import LureDealBackground from './LureDealBackground.png'
import PriceSortDealMainImage from './PriceSortDealMainImage.png'
import TreasureDealMainImage from './TreasureDealMainImage.png'
import Unlocked from './Unlocked.png'
import Up from './Up.png'
import VisitedTimeDealMainImage from './VisitedTimeDealMainImage.png'

const dealImages = {
  LureDealBackground,
  BrandDealPriceBackground,
  ClockIconBlack,
  TreasureDealMainImage,
  Locked,
  Unlocked,
  Up,
  Close,
  CategoryDealMainImage,
  PriceSortDealMainImage,
  VisitedTimeDealMainImage,
  LogoApp,
  Fire,
}
export default dealImages
