import { action, makeObservable, observable } from 'mobx'

class ModalStore {
  currentBottomSheet = null
  currentModal = null
  currentAlert = null
  currentToast = null

  constructor() {
    makeObservable(this, {
      currentBottomSheet: observable,
      currentModal: observable,
      currentAlert: observable,
      currentToast: observable,

      setCurrentBottomSheet: action,
      setCurrentModal: action,
      setCurrentAlert: action,
      setCurrentToast: action,
    })
  }

  setCurrentBottomSheet(currentBottomSheet) {
    if (this?.currentBottomSheet?.isOpen) return
    this.currentBottomSheet = currentBottomSheet
  }

  setCurrentModal(currentModal) {
    if (this?.currentModal?.isOpen) return
    this.currentModal = currentModal
  }

  setCurrentAlert(currentAlert) {
    this.currentAlert = currentAlert
  }

  setCurrentToast(currentToast) {
    this.currentToast = currentToast
  }
}
export default new ModalStore()
