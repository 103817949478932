import commaNumber from 'comma-number'
import { observable, toJS } from 'mobx'

import backendApis from '../utils/backendApis'
import { sendMessage } from '../utils/utils'
import AuthStore from './AuthStore'
import ToastStore from './ToastStore'
import UserStore from './UserStore'

const GroupPurchaseStore = observable({
  isFromGroupPurchaseCreate: false,
  itemInfo: {},
  groupPurchaseInfo: {},
  userInfo: {},
  displayType: 'ongoing',
  displayData: [],
  displayDataSkip: 0,
  modalConfig: {
    titleText: '모임구매를 만들었어요! 🎉',
    firstLineText: '24시간 안에 모임구매가 성사되면',
    secondLineFirstText: '',
    emphasizeImage: '../images/groupPurchase/icon/coin.png',
    secondLineEmphasizeText: '현금 리워드 ???원',
    secondLineSecondText: '을 받을 수 있어요',
    firstButtonText: '나도 참여하기',
    firstButtonFunction: () => {},
    secondButtonText: '추천하기',
    secondButtonFunction: () => {},
    close: () => {
      this.setModalType('close')
    },
  },
  isLoading: true,
  isLoadMoreAvailable: true,
  ongoingGroupPurchaseList: [],
  finishedGroupPurchaseList: [],
  rewardAvailableGroupPurchaseList: [],
  modalType: 'close',

  setModalType(type) {
    this.modalType = type
  },
  setRewardAvailableGroupPurchaseList(list) {
    this.rewardAvailableGroupPurchaseList = list
  },

  setOngoingGroupPurchaseList(list) {
    this.ongoingGroupPurchaseList = list
  },

  setFinishedGroupPurchaseList(list) {
    this.finishedGroupPurchaseList = list
  },

  setIsLoadMoreAvailable(bool) {
    this.isLoadMoreAvailable = bool
  },

  setIsLoading(bool) {
    this.isLoading = bool
  },

  setDisplayDataSkip(skip) {
    this.displayDataSkip = skip
  },

  setModalConfig(config) {
    this.modalConfig = config
  },

  setDisplayData(data) {
    this.displayData = data
  },
  setDisplayType(type) {
    this.displayType = type
  },

  changeDisplayList({ type }) {
    this.setDisplayType(type)
    if (type === 'ongoing') {
      this.setDisplayData(this.ongoingGroupPurchaseList)
    } else {
      this.setDisplayData(this.finishedGroupPurchaseList)
    }
  },

  initializeGroupPurchaseStore({ itemInfo }) {
    this.itemInfo = itemInfo
    this.groupPurchaseInfo = itemInfo?.characteristicItem?.groupPurchaseInfo
  },

  async initializeGroupPurchase() {
    this.setOngoingGroupPurchaseList([])
    this.setRewardAvailableGroupPurchaseList([])
    this.setFinishedGroupPurchaseList([])
    await this.fetchGroupPurchaseUserInfo()
    await this.fetchRewardAvailableGroupPurchase()
    await this.fetchOngoingGroupPurchase()
    this.setDisplayData(this.ongoingGroupPurchaseList)
    await this.fetchFinishedGroupPurchase()
    this.setIsLoading(false)
  },
  setIsFromGroupPurchaseCreate(bool) {
    this.isFromGroupPurchaseCreate = bool
  },
  shareItemLink({ item, itemInfo, discountRate }) {
    sendMessage({
      type: 'shareGroupPurchaseItem',
      shareType: 'shareGroupPurchaseItem',
      title: `${UserStore?.userInfo?.userName}님의 강력추천! [${itemInfo?.itemTitle}] 득템하기`,
      description: `늦기전에 🔥${discountRate}%🔥 할인받기`,
      bannerImage:
        'https://assets.ilevit.com/7ab3f811-7cfb-4108-a3b9-d9b3bfc13867.png',
      payload: {
        alwayzItemId: item?.itemId,
        alwayzTeamDealId: item?._id,
        alwayzInvitorId: UserStore?.userInfo?.userId,
        enteringComponent: 'GroupPurchase_ShareJoin',
        isFrom: 'GroupPurchase_ShareJoin',
      },
    })
  },

  participateGroupPurchase({ item }) {
    sendMessage({
      type: 'joinGroupPurchase',
      payload: {
        itemId: item?.itemId,
        teamDealId: item?._id,
        invitorId: UserStore?.userInfo?.userId,
        alwayzInvitorId: UserStore?.userInfo?.userId,
        enteringComponent: 'GroupPurchase_ShareJoin',
        isFrom: 'GroupPurchase_ShareJoin',
      },
    })
  },

  async giveReward({ reward, teamDealId, index }) {
    this.updateItemByIndex({
      index,
      data: { receivedGroupPurchaseRewardAt: new Date() },
    })
    const res = await backendApis.groupPurchaseGiveReward({
      reward,
      teamDealId,
      userInfo: this.userInfo,
    })
    if (res?.data?.status === 200) {
      this.increaseReward(res?.data?.reward)
      this.setModalConfig({
        titleText: '현금 리워드를 받았어요! 🎉',
        firstLineText: res?.data?.boosted
          ? `와우! 추가 보너스 ${Math.round(
              (res?.data?.boost - 1) * 100,
            )}% 당첨!`
          : `모임구매에 성공했어요!`,
        secondLineFirstText: '',
        emphasizeImage: '../images/groupPurchase/icon/coin.png',
        secondLineEmphasizeText: `총 현금 리워드 ${commaNumber(
          res?.data?.reward,
        )}원`,
        secondLineSecondText: '을 받았어요',
        firstButtonText: '보상 받기',
        firstButtonFunction: () => {},
        close: () => {
          this.setModalType('close')
        },
      })
      this.setModalType('oneButton')
    } else if (res?.data?.status === 201) {
      ToastStore.toastOn({
        type: 'error',
        message: '네트워크 에러 [1001]',
        duration: 3000,
      })
    }
    await this.initializeGroupPurchase()

    return res?.data?.reward
  },

  updateItemByIndex({ index, data }) {
    this.displayData[index] = { ...this.displayData[index], ...data }
  },

  async createGroupPurchaseUserInfo(isBoosted = false) {
    const res = await backendApis.createGroupPurchaseUserInfo(
      AuthStore?.token,
      'POST',
      { isBoosted },
    )
    if (res?.status === 200) {
      this.setUserInfo(res?.data)
    }
  },

  async fetchGroupPurchaseUserInfo(isGetOnly = false) {
    const res = await backendApis.fetchGroupPurchaseUserInfo(
      AuthStore?.token,
      'GET',
      { isGetOnly },
    )

    if (res?.status === 200) {
      this.setUserInfo(res?.data)
    }
  },

  setUserInfo(data) {
    this.userInfo = data
  },

  increaseReward(reward) {
    this.userInfo.reward += reward
  },

  async fetchOngoingGroupPurchase(token) {
    if (!(AuthStore?.token || token)) return
    const result = await backendApis.fetchOngoingGroupPurchase(
      AuthStore?.token || token,
    )
    this.setDisplayDataSkip(0)
    if (result?.status === 200) {
      this.setOngoingGroupPurchaseList(result?.data)
    } else if (result?.status === 201) {
      this.setOngoingGroupPurchaseList([])
    } else if (result?.status === 400) {
      ToastStore.toastOn({
        type: 'error',
        message: '모임구매 정보를 가져오는데 실패했습니다',
        duration: 3000,
      })
    }
  },

  async fetchFinishedGroupPurchase(token) {
    const result = await backendApis.fetchFinishedGroupPurchase(
      AuthStore?.token || token,
      'GET',
      { skip: this.displayDataSkip },
    )
    if (result?.status === 200) {
      let finishedList = [...result?.data]

      finishedList = finishedList.filter((item) => {
        const flag = this.rewardAvailableGroupPurchaseList.find(
          (rewardAvailableItem) => {
            console.log(
              toJS(rewardAvailableItem?._id?.toString()),
              toJS(item?._id?.toString()),
            )
            return (
              rewardAvailableItem?._id?.toString() === item?._id?.toString()
            )
          },
        )
        return !flag
      })

      if (this.displayDataSkip === 0) {
        this.setFinishedGroupPurchaseList(finishedList)
      } else {
        this.setFinishedGroupPurchaseList([
          ...this.finishedGroupPurchaseList,
          ...finishedList,
        ])
      }
      this.setIsLoading(false)
    } else if (result?.status === 201 && this.displayDataSkip > 0) {
      ToastStore.toastOn({
        type: 'error',
        message: '더 불러올 모임구매 정보가 없어요',
        duration: 3000,
      })
      this.setIsLoadMoreAvailable(false)
    } else if (result?.status === 400) {
      ToastStore.toastOn({
        type: 'error',
        message: '모임구매 정보를 가져오는데 실패했습니다',
        duration: 3000,
      })
    }
    this.setDisplayDataSkip(this.displayDataSkip + 10)
  },

  async fetchRewardAvailableGroupPurchase(token) {
    const result = await backendApis.fetchRewardAvailableGroupPurchase(
      AuthStore?.token || token,
    )
    if (result?.status === 200) {
      this.setRewardAvailableGroupPurchaseList([
        ...this.rewardAvailableGroupPurchaseList,
        ...result?.data,
      ])
    }
  },

  async requestWithdrawReward() {
    if (this.userInfo.reward < 5000) {
      ToastStore.toastOn({
        type: 'error',
        message: '출금 가능 단위는 5,000원입니다',
        duration: 3000,
      })
      return
    }
    const result = await backendApis.requestWithdrawReward()
    if (result?.data?.status === 200) {
      this.increaseReward(-5000)
      this.openWithdrawSuccessModal()
    }
    if (result?.data?.status === 201) {
      ToastStore.toastOn({
        type: 'error',
        message: '오늘은 이미 출금신청을 했어요',
        duration: 3000,
      })
    }
    // 리워드 모달 띄워주기
  },
  openWithdrawInfoModal() {
    this.setModalConfig({
      titleText: '출금 안내',
      textArray: [
        '1. 출금신청을 하시면 2-3 영업일안에 입금돼요',
        '2. 출금은 5,000원 단위만 가능해요',
        '3. 출금신청은 하루에 한번만 가능해요',
        '4. 60일이상 모임구매를 만들지 않으면 리워드가 소멸돼요',
        '5. 관련 문의는 내정보 > 고객문의 > 1:1 실시간 상담하기에서 가능해요',
        '6. 서비스는 당사의 사정에 의해 진행 중인 고객님이 계셔도 사전 고지 없이 변경 또는 조기종료 될 수 있어요',
      ],
      firstButtonText: '닫기',
      firstButtonFunction: () => {},
      close: () => {
        this.setModalType('close')
      },
    })
    this.setModalType('infoList')
  },

  openInfoModal() {
    this.setModalConfig({
      titleText: '모임구매 안내',
      firstLineText: '',
      secondLineFirstText: '모임구매는 좋은 상품을 추천하고',
      emphasizeImage: '../images/groupPurchase/icon/coin.png',
      secondLineEmphasizeText: `정말 큰 보상`,
      secondLineSecondText: '을 받는',
      thirdLineText: '"진짜 돈이 되는" 앱테크에요',
      firstButtonText: '닫기',
      firstButtonFunction: () => {},
      close: () => {
        this.setModalType('close')
      },
    })
    this.setModalType('oneButton')
  },
  openWithdrawSuccessModal() {
    this.setModalConfig({
      titleText: '출금 신청 성공!',
      firstLineText: '축하해요 모임장님! 🎉',
      secondLineFirstText: '앞으로 2~3 영업일내로',
      emphasizeImage: '../images/groupPurchase/icon/coin.png',
      secondLineEmphasizeText: `5,000원`,
      secondLineSecondText: '이 올웨이즈에 등록하신',
      thirdLineText: '휴대폰 번호로 입금될 예정이에요 💸',
      firstButtonText: '확인',
      firstButtonFunction: () => {},
      close: () => {
        this.setModalType('close')
      },
    })
    this.setModalType('oneButton')
  },
  openRewardInfoModal() {
    this.setModalConfig({
      titleText: '현금 리워드 안내',
      firstLineText: '모임구매를 성사하면 리워드를 받아요',
      secondLineFirstText: '리워드를 받을때마다 랜덤하게',
      emphasizeImage: '../images/groupPurchase/icon/coin.png',
      secondLineEmphasizeText: `추가 보너스`,
      secondLineSecondText: '가 있어요',
      firstButtonText: '닫기',
      firstButtonFunction: () => {},
      close: () => {
        this.setModalType('close')
      },
    })
    this.setModalType('oneButton')
  },

  openOneButtonModal({ title, firstLineText }) {
    console.log(title, firstLineText)
    this.setModalConfig({
      titleText: title,
      firstLineText,
      secondLineFirstText: '',
      emphasizeImage: '',
      secondLineEmphasizeText: ``,
      secondLineSecondText: '',
      thirdLineText: '',
      firstButtonText: '확인',
      firstButtonFunction: () => {},
      close: () => {
        this.setModalType('close')
      },
    })
    this.setModalType('oneButton')
  },

  openCannotCreateGroupPurchaseModal() {
    this.setModalConfig({
      titleText: '모임장이 되려면?',
      firstLineText: '',
      secondLineFirstText: '참여한 모임구매가',
      emphasizeImage: '../images/groupPurchase/etc/thumbUp.png',
      secondLineEmphasizeText: `성공적`,
      secondLineSecondText: '으로 마무리 하면',
      thirdLineText: '모임장이 될 수 있어요!',
      firstButtonText: '닫기',
      firstButtonFunction: () => {},
      secondButtonText: '초대하기',
      secondButtonFunction: () => {},
      close: () => {
        this.setModalType('close')
      },
    })
    this.setModalType('oneButton')
  },

  openCreatedGroupModal({ itemInfo, discountRate }) {
    this.setModalConfig({
      titleText: '모임구매를 만들었어요! 🎉',
      firstLineText: '24시간 안에 모임구매가 성사되면',
      secondLineFirstText: '',
      emphasizeImage: '../images/groupPurchase/icon/coin.png',
      secondLineEmphasizeText: `현금 리워드 ${commaNumber(
        this.groupPurchaseInfo?.reward ?? 1000,
      )}원`,
      secondLineSecondText: '을 받을 수 있어요',
      firstButtonText: '나도 참여하기',
      firstButtonFunction: () => {
        this.participateGroupPurchase({
          item: this.displayData?.[0],
        })
      },
      secondButtonText: '추천하기',
      secondButtonFunction: () => {
        this.shareItemLink({
          item: this.displayData?.[0],
          itemInfo,
          discountRate,
        })
      },
      close: () => {
        GroupPurchaseStore.setIsFromGroupPurchaseCreate(false)
        this.setModalType('close')
      },
    })
    this.setModalType('twoButtons')
  },

  openTutorialModal() {
    this.setModalConfig({
      titleText: '모임구매 설명',
      contentArray: [
        {
          imageUri: '../images/groupPurchase/tutorial/1.png',
        },
        {
          imageUri: '../images/groupPurchase/tutorial/2.png',
        },
        {
          imageUri: '../images/groupPurchase/tutorial/3.png',
        },
        {
          imageUri: '../images/groupPurchase/tutorial/4.png',
        },
        {
          imageUri: '../images/groupPurchase/tutorial/5.png',
        },
        {
          imageUri: '../images/groupPurchase/tutorial/6.png',
        },
      ],
      firstButtonText: '모임구매 만들기',
      firstButtonFunction: () => {
        this.createGroupPurchase()
      },
      close: () => {
        this.setModalType('close')
      },
    })
    this.setModalType('carousel')
  },

  createGroupPurchase() {
    if (!this?.userInfo?.canCreate) {
      this?.openCannotCreateGroupPurchaseModal()
      return
    }
    sendMessage({
      type: 'createGroupPurchase',
      data: {
        initialComponent: 'GroupPurchase_Create',
        enteringComponent: 'GroupPurchase_Create',
        headerText: '모임구매 만들기',
      },
      payload: {
        isBannerOnly: true,
        bannerUri:
          'https://assets.ilevit.com/f759d04f-7c5f-48b4-830a-59435f5f776c.png',
      },
    })
  },

  openGroupPurchaseDetailModal({ item, itemInfo, discountRate }) {
    this.setModalConfig({
      titleText: '모임구매 참여 정보',
      item,
      itemInfo,
      discountRate,
      firstButtonText: '닫기',
      firstButtonFunction: () => {},
      secondButtonText: '추천하기',
      secondButtonFunction: () => {
        this.shareItemLink(item, itemInfo, discountRate)
      },
      close: () => {
        this.setModalType('close')
      },
    })
    this.setModalType('groupPurchaseDetail')
  },
  openCloseNotice() {
    this.setModalConfig({
      titleText: '모임구매 종료 안내',
      textArray: [
        '본 서비스는 6월 30일 이후에 종료됩니다',
        '종료 후 모임구매 현금리워드는 소멸됩니다',
        '다음에 더 나은 모습으로 찾아뵙겠습니다',
        '그동안 이용해주셔서 감사합니다',
      ],
      firstButtonText: '닫기',
      firstButtonFunction: () => {},
      close: () => {
        this.setModalType('close')
      },
    })
    this.setModalType('infoList')
  },

  log(key) {
    console.log(toJS(this[key]))
  },
})

export default GroupPurchaseStore
