import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { CheckInEnvelope, Close, PigFoodColored } from 'src/images/dailyCheckIn'

const DailyCheckInPurchaseRewardModal = observer(
  ({ visible, close, goBackToMain, ...params }) => {
    const rewardData = params?.rewardData
    const Title = () => {
      return <div className='text-xl font-bold pt-3'>구매 보상 받기!</div>
    }

    const RewardList = () => {
      return (
        <div
          style={{
            flexDirection: 'row',
            paddingVertical: 20,
          }}
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          {rewardData.map((e) => {
            if (e.rewardType === 'point') {
              return (
                <button
                  key={e._id}
                  style={{
                    paddingVertical: 12,
                    paddingRight: 16,
                    alignItems: 'center',
                  }}
                >
                  <CheckInEnvelope className='w-20' />
                  <div className='text-lg font-bold pt-2'>1개</div>
                </button>
              )
            }
            if (e.rewardType === 'food') {
              return (
                <button
                  key={e._id}
                  style={{
                    paddingVertical: 12,
                    paddingRight: 16,
                    alignItems: 'center',
                  }}
                >
                  <PigFoodColored className='w-20' />
                  <div className='text-lg font-bold pt-2'>
                    {commaNumber(e.rewardAmount)}g
                  </div>
                </button>
              )
            }
            return (
              <button
                key={e._id}
                style={{
                  paddingVertical: 12,
                  paddingRight: 16,
                  alignItems: 'center',
                }}
              >
                <div className='text-xl font-bold pt-2'>
                  보상: {commaNumber(e.rewardTypeKOR)}
                </div>
                <div className='text-lg font-bold pt-3'>
                  수량: {commaNumber(e.rewardAmount)}
                  {e.unit}
                </div>
              </button>
            )
          })}
        </div>
      )
    }
    const ReceiveButton = () => {
      return (
        <button
          onClick={goBackToMain}
          className='w-4/5 bg-red-500 py-3 px-2 rounded-lg justify-center align-center text-white text-base font-bold'
        >
          보상 받기
        </button>
      )
    }

    return (
      <div>
        {/* 딤드 배경 */}
        <button
          className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-10'
          onClick={close}
        />
        {/* 모달 상자 */}
        <div
          className='fixed left-1/2 py-6 z-40 bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
          style={{ top: '42%', width: '90%', height: '44%', zIndex: 11 }}
        />

        <button
          className='fixed z-30'
          style={{ left: '86%', top: '16%' }}
          onClick={close}
        >
          <Close className='w-6 h-6' />
        </button>

        {/* 모달 내용 + 받기 버튼 */}
        <div
          style={{ left: '9%', top: '22%', width: '82%', height: '40%' }}
          className='flex flex-col fixed justify-between items-center z-30'
        >
          <Title />
          <RewardList />
          <ReceiveButton />
        </div>
      </div>
    )
  },
)

export default DailyCheckInPurchaseRewardModal
