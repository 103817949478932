import { AppScreen } from '@stackflow/plugin-basic-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { ClipLoader } from 'react-spinners'

import ToonModal from '../components/templates/ToonModal'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import AltoonEventPage from './AltoonEventPage'

const AltoonMainEventPage = observer(({ params }) => {
  const { pop } = useMyFlow()
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setIsLoading(false)
      }, 300)

      return () => clearTimeout(timer)
    }
  }, [activeTab, isLoading])

  const eventList = [
    {
      type: 'image',
      title: '100% 혜택',
      imageUri:
        'https://assets.ilevit.com/2355c040-27b2-41b7-a24d-fa90cfc9ff33.png',
      renderingComponent: () => (
        <div className='relative flex flex-col items-center justify-center bg-[#424242] font-bold text-[5vw]'>
          <button
            type='button'
            className='absolute -top-[104vw] w-[70vw] py-[5vw]'
            style={{
              backgroundImage: "url('/images/toon/eventPage/goldButton.png')",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            onClick={() => {
              setOpenModal(true)
            }}
          >
            충전하러 가기
          </button>
          <button
            type='button'
            className='absolute -top-[84vw] w-[70vw] py-[5vw]'
            style={{
              backgroundImage: "url('/images/toon/eventPage/whiteButton.png')",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            onClick={() => {
              setActiveTab(2)
            }}
          >
            투표하러 가기
          </button>
        </div>
      ),
    },
    {
      type: 'event',
      eventId: '675980a183b998b07bcd065a',
      title: '타임특가',
    },
    {
      type: 'event',
      eventId: '6759678783b998b07bcd0658',
      title: '인기투표',
    },
    {
      type: 'event',
      eventId: '67599d4c83b998b07bcd065c',
      title: '아듀기획전',
    },
    {
      type: 'event',
      eventId: '6750115044616d726c69613f',
      title: '정주행 마스터',
    },
  ]

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const HeaderComponent = () => {
    return (
      <section className='fixed top-0 w-full bg-white z-20 flex flex-col'>
        <div className='py-2 flex flex-row items-center justify-between overflow-hidden font-bold'>
          <button
            type='button'
            aria-label='back'
            className='px-3 py-2 whitespace-nowrap'
            onClick={() => pop()}
          >
            <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
          </button>
          <div className='text-[4.2vw] py-2 whitespace-nowrap'>이벤트</div>
          <div className='w-14' />
        </div>
      </section>
    )
  }

  const MainImageSection = () => {
    return (
      <div className='mt-[16vw]'>
        <img
          src='https://assets.ilevit.com/49e9eb9d-8aef-4a98-8726-5cba41f845a1.png'
          alt=''
          className='w-full h-full object-cover'
        />
      </div>
    )
  }

  const StickyHeader = ({ eventList }) => {
    return (
      <div className='sticky top-[16vw] z-20 bg-black text-[4vw] text-white flex overflow-x-auto sticky-header'>
        {eventList?.map((item, index) => (
          <button
            type='button'
            key={`${Math.random() + index}`}
            className={`flex-shrink-0 px-[4vw] pt-[5vw] pb-[4vw] text-[3.8vw] font-bold whitespace-nowrap
            ${
              activeTab === index ? 'border-b-2 border-white' : 'text-[#464953]'
            }`}
            onClick={() => {
              if (index === activeTab) {
                return
              }

              setIsLoading(true)
              setActiveTab(index)
              scrollToTop()
            }}
          >
            {item.title}
          </button>
        ))}
      </div>
    )
  }

  return (
    <AppScreen>
      <div
        className={
          isLoading || openModal
            ? 'fixed w-full min-h-screen bg-gray-50'
            : 'w-full min-h-screen bg-gray-50'
        }
      >
        <HeaderComponent />
        {activeTab === 0 && <MainImageSection />}
        <StickyHeader eventList={eventList} />

        {/* 첫 번째 탭의 컨텐츠는 display로 제어 */}
        <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
          <img
            src={eventList[0]?.imageUri}
            alt=''
            className='w-full h-full object-cover'
          />
          {eventList[0]?.renderingComponent?.()}
        </div>

        {/* AltoonEventPage를 조건부로 렌더링하지 않고, key prop으로 제어 - AltoonEventPage가 Unmount할 때 버그를 발생시키기 때문 */}
        <div
          className='pt-[15vw]'
          style={{ display: activeTab === 0 ? 'none' : 'block' }}
        >
          <AltoonEventPage
            key={eventList[activeTab]?.eventId}
            params={{
              eventId: eventList[activeTab]?.eventId || 'none',
              isFrom: 'AltoonMainEventPage',
            }}
          />
        </div>
      </div>

      {isLoading && (
        <div>
          <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
          <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
            <ClipLoader
              color='#ff3e3e'
              loading={isLoading}
              size='20vw'
              aria-label='Loading Spinner'
              data-testid='loader'
            />
          </div>
        </div>
      )}
      {openModal && (
        <ToonModal
          setOpenTicketModal={setOpenModal}
          modalName='ticketExchange'
          modalProp={{
            goods: 'toonCoin',
          }}
          modalTop='80'
        />
      )}
    </AppScreen>
  )
})

export default AltoonMainEventPage
