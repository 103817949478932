import commaNumber from 'comma-number'
import React, { memo, useState } from 'react'

import backendApis from '../../../utils/backendApis'
import TruncateText from '../../../utils/truncateText'
import ImageAtom from '../../atoms/imageAtom'
import DiscountTag from '../tag/discountTag'
import { handleOnClickFactory } from './itemNavigateHandler'

const RelatedTimeDealItemComponent = ({
  itemInfo = {},
  style = {},
  empty = false,
  isFrom = 'VisitedTimeDealPage',
  dealType = 'timeDeal',
}) => {
  const { width = 0 } = style
  const teamPrice = parseInt(itemInfo?.teamPurchasePrice, 10)
  const dealPrice = parseInt(itemInfo?.minDealTeamPurchasePrice, 10)
  const itemIndex = parseInt(itemInfo?.itemIndex, 10)
  const salesRatio =
    100 - Math.floor((dealPrice / teamPrice) * 100) || undefined
  const handleOnClick = handleOnClickFactory(itemInfo, isFrom, dealType)
  if (empty) {
    return (
      <div
        aria-hidden='true'
        style={{ ...style, flex: 1, display: 'flex', paddingRight: 12 }}
      >
        <div className='flex flex-col flex-1 bg-gray-200 border-gray-200 rounded-xl'>
          <div>
            <div className='flex flex-row items-center justify-between mx-2 mb-2 font-bold' />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div
      aria-hidden='true'
      style={{ ...style, flex: 1, display: 'flex', paddingRight: 12 }}
      onClick={handleOnClick}
    >
      <div className='rounded-xl'>
        <ImageAtom
          src={itemInfo?.mainImageUris[0]}
          className='object-cover w-32 h-32 border border-gray-200 rounded-xl'
          width={256}
          height={256}
        />
        <div>
          <div className='mx-2 mt-2'>
            <TruncateText text={itemInfo?.itemTitle} width={width - 24} />
          </div>
          <div className='flex flex-row items-center justify-start mx-2 mb-2 font-bold'>
            <div className='mr-1 font-bold text-red-500'>
              {salesRatio && `${salesRatio}%`}
            </div>
            <div className='text-gray-800'>{commaNumber(dealPrice)}원</div>
          </div>
          {/* <DiscountTag gap={teamPrice - dealPrice} /> */}
        </div>
      </div>
    </div>
  )
}
export default RelatedTimeDealItemComponent
