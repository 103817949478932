import React from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import backendApis from 'src/utils/backendApis'

const Header = (props) => {
  const {
    title = '',
    infoButton = true,
    infoNavigate = () => {
      navigate('/treasureboxtutorial')
      backendApis.checkTreasureBoxTutorial()
    },
    navigateBack = () => {
      window.location.href = '#goBack'
    },
  } = props

  const navigate = useNavigate()
  return (
    <section className='flex flex-row overflow-hidden h-12 w-screen z-10 relative items-center justify-between'>
      <button
        type='button'
        className='flex py-2 px-2 whitespace-nowrap'
        onClick={navigateBack}
      >
        <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
      </button>
      <div className='p-3 flex-1 text-center text-gray-800 font-medium absolute left-[50%] translate-x-[-50%]'>
        {title}
      </div>
      {infoButton ? (
        <button
          type='button'
          className='flex py-3 px-4 whitespace-nowrap text-gray-600 font-semibold'
          onClick={infoNavigate}
        >
          안내
        </button>
      ) : (
        <div className='flex py-2 px-2 w-12 h-12' />
      )}
    </section>
  )
}

export default Header
