import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import EggMoneyAction from '../../../actions/EggMoneyAction'
import Button from '../../../pages/EggMoney/Button'
import EggMoneyStore from '../../../stores/EggMoney/EggMoneyStore'
import THModalStore from '../../../stores/treasureHunt/THModalStore'

function renderDescription(text) {
  return text?.split?.('\n')?.map((line, index) => (
    <span key={Math.random()}>
      {line}
      {index < text?.split('\n').length - 1 && <br />}
    </span>
  ))
}

const EggMoneyRouletteModal = ({ config }) => {
  const [spinning, setSpinning] = useState(false)
  const [result, setResult] = useState(null)
  const [rotation, setRotation] = useState(0)

  const startRoulette = async () => {
    setSpinning(true)
    setResult(null)

    // 당첨 결과 로직
    const { finalRotation, isSSrank } =
      await EggMoneyAction.rouletteInviteResult()

    if (!finalRotation) {
      THModalStore.close()
    }

    // 천천히 멈추는 애니메이션
    setRotation(finalRotation)
    setTimeout(() => {
      setResult(isSSrank ? 'SS랭크' : 'S랭크')
      setSpinning(false)
    }, 5000) // 애니메이션 시간
  }

  return (
    <div className='items-center justify-center default'>
      {config?.title && (
        <div className='default font-bold text-center text-[6vw]'>
          {config?.title}
        </div>
      )}
      {config?.subTitle && (
        <div className='font-bold text-center mt-2 text-[#5D5F68]'>
          {renderDescription(config?.subTitle)}
        </div>
      )}
      <div className='relative flex items-center justify-center w-full my-4'>
        <img
          alt='main'
          src={config?.src}
          style={{
            transform: `rotate(${rotation}deg)`,
            transition: spinning
              ? 'transform 5s cubic-bezier(0.25, 0.1, 0.25, 1)'
              : 'none',
          }}
          className={`relative z-[10] w-[90%] h-auto ${config?.imgClassName}`}
        />
        <img
          alt='main'
          src='../images/eggMoney/roulette/pin.png'
          className='z-[10] w-7 h-auto absolute top-0 -translate-y-1/4'
        />
      </div>
      {result ? (
        <div className='font-bold text-center text-[5vw] text-[#5D5F68]'>
          {renderDescription(`축하합니다!\n${result}에 당첨되셨습니다!`)}
        </div>
      ) : (
        <div className='font-bold text-center text-[5vw] text-[#5D5F68]'>
          {renderDescription(`${spinning ? '뽑는중..' : ''}`)}
        </div>
      )}

      {(EggMoneyStore?.user?.rouletteTicket ?? 0) <= 0 &&
      !result &&
      !spinning ? (
        <>
          {config?.description && (
            <div className='font-bold text-center'>
              {renderDescription(config?.description)}
            </div>
          )}

          <Button
            className='w-full py-3 mt-6'
            clickable
            text={config?.buttonText ?? '초대하고'}
            secondTextImg='../images/eggMoney/roulette/ticket.png'
            secondText={config?.seconButtonText ?? '티켓 받기'}
            onClick={() => {
              if (config?.onClick) {
                config.onClick?.()
              }
              THModalStore?.close()
            }}
          />
        </>
      ) : (
        <>
          {!result && !spinning && (
            <div className='font-bold text-center text-[5vw] text-[#5D5F68]'>
              {renderDescription(`SS랭크에 도전해볼까요?!`)}
              <div className='flex flex-row justify-center items-center mt-2'>
                <div className='flex flex-row bg-[#fff8fe] px-4 py-1 rounded-full justify-center items-center text-sm text-[#F26FDD] border-[1px] border-[#FFD9F9]'>
                  <img
                    className='w-8   mr-2'
                    src='../images/eggMoney/roulette/ticket.png'
                    alt='ticket'
                  />
                  {`${commaNumber(
                    EggMoneyStore?.user?.rouletteTicket ?? 0,
                  )}개 남음`}
                </div>
              </div>
            </div>
          )}
          <Button
            className='py-3 mb-4 mt-4 px-6'
            clickable={
              ((EggMoneyStore?.user?.rouletteTicket ?? 0) > 0 && !spinning) ||
              (result && !spinning)
            }
            text={!result ? 'SS랭크 도전하기' : '지금 바로 에그 뽑기'}
            secondTextImg={
              !result ? '../images/eggMoney/roulette/ticket.png' : ''
            }
            secondText={!result ? '1개 소모' : ''}
            onClick={() => {
              if (!result) {
                startRoulette()
              } else {
                // 모달닫고 바로 claimEgg 시키기
                EggMoneyAction?.claimEgg()
                THModalStore.close()
              }
            }}
          />
        </>
      )}
    </div>
  )
}

export default observer(EggMoneyRouletteModal)
