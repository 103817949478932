import commaNumber from 'comma-number'
import { useCallback, useMemo, useRef } from 'react'

import dealImages from '../../../images/deal'
import GaugeBar from '../../atoms/gaugeBar'
import ImageAtom from '../../atoms/imageAtom'
import { handleOnClickFactory } from './itemNavigateHandler'

const TimeDealItemComponent = ({
  itemInfo,
  empty = false,
  version = 'mainTimeDeal',
  isFrom = 'TimeDealSection',
  isScrolling = false,
}) => {
  const ref = useRef(null)
  const priceDiv = useRef(null)
  const teamPrice = parseInt(itemInfo?.teamPurchasePrice, 10)
  const dealPrice = parseInt(itemInfo?.minDealTeamPurchasePrice, 10)
  const type = 'timeDeal'
  const validHandleOnClick = useMemo(
    () => handleOnClickFactory(itemInfo, isFrom, type),
    [itemInfo, isFrom, type],
  )
  const handleOnClick = useCallback(() => {
    if (!isScrolling) {
      return validHandleOnClick()
    }
  }, [isScrolling, validHandleOnClick])

  if (empty) {
    return (
      <div className='flex flex-col items-center justify-center flex-1 w-full'>
        <div className='flex flex-row items-center justify-start flex-1 w-full p-5'>
          <div className='flex-none w-32 h-32 mr-5 border border-gray-300 rounded-lg' />
          <div className='flex flex-col items-start justify-start flex-1' />
        </div>
        <div className='w-[90%] border-b border-gray-300' />
      </div>
    )
  }
  return (
    <div
      aria-hidden='true'
      className='flex items-center justify-center flex-1 w-full'
      onClick={version === 'mainTimeDeal' ? handleOnClick : () => {}}
    >
      <div className='flex flex-col items-center justify-center flex-1 h-full'>
        <div className='flex flex-row justify-start flex-1 w-full h-full p-5'>
          <div className='flex-none mr-3 place-self-center'>
            {version === 'mainTimeDeal' ? (
              <ImageAtom
                src={itemInfo?.mainImageUris[0]}
                className='object-cover w-32 h-32 border border-gray-300 rounded-lg'
                width={256}
                height={256}
              />
            ) : (
              <>
                <ImageAtom
                  src={itemInfo?.mainImageUris[0]}
                  className='absolute object-cover w-32 h-32 border border-gray-300 rounded-lg'
                  width={256}
                  height={256}
                />
                <div className='relative flex items-center justify-center flex-1 w-32 h-32 text-lg font-bold text-white bg-gray-500 rounded-lg opacity-70'>
                  오픈 예정
                </div>
              </>
            )}
          </div>
          <div className='flex flex-col justify-between flex-1'>
            <div
              className='overflow-hidden leading-tight line-clamp-2 text-ellipsis'
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {itemInfo?.itemTitle}
            </div>
            <>
              <div className='flex flex-row items-center flex-1 w-full mt-5'>
                <div className='relative flex flex-row items-center flex-1 mr-2'>
                  <div className='flex flex-1 w-[40%]'>
                    <GaugeBar value={itemInfo?.totalOrderQuantity} max={100} />
                    {itemInfo?.totalOrderQuantity >= 100 && (
                      <div className='absolute right-0'>
                        <img
                          src={dealImages?.ClockIconBlack}
                          alt='clock'
                          className='w-4 h-4'
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className='mr-5 text-xs font-bold text-gray-500'>
                  {`${commaNumber(itemInfo?.totalOrderQuantity)}개 구매중`}
                </div>
              </div>
              <div className='flex flex-col justify-start'>
                <div className='flex flex-row items-center flex-1'>
                  <div
                    ref={priceDiv}
                    className='mr-2 text-base font-bold text-red-500 whitespace-nowrap'
                  >
                    할인가
                    <span className='text-lg mx-0.5'>
                      {commaNumber(dealPrice)}
                    </span>
                    원
                  </div>
                  <div
                    ref={ref}
                    className='relative text-[12px] text-red-500 whitespace-nowrap'
                  >
                    <div className='relative text-[12px] z-10'>
                      <span>{commaNumber(teamPrice - dealPrice)}</span>원 할인
                    </div>
                    <div
                      style={{ width: ref?.current?.offsetWidth }}
                      className='absolute flex items-end flex-1 h-2 bg-red-100 top-2 opacity-70'
                    />
                  </div>
                </div>
                <div className='text-sm text-gray-500'>
                  곧 {commaNumber(teamPrice)}원으로 돌아가요
                </div>
              </div>
            </>
          </div>
        </div>
        <div className='w-[90%] border-b border-gray-300' />
      </div>
    </div>
  )
}

export default TimeDealItemComponent
