import React, { useEffect, useState } from 'react'

const Timer = ({ displayEndDate, type = 'lureDeal' }) => {
  const [remainingTime, setRemainingTime] = useState(null)
  const endTime = new Date(displayEndDate).getTime()
  const currentTime = new Date().getTime()
  const timeDifference = endTime - currentTime

  useEffect(() => {
    const calculateRemainingTime = () => {
      if (timeDifference <= 0) {
        clearInterval(timerInterval)
        setRemainingTime('타이머 종료')
        return
      }
      const seconds = Math.floor((timeDifference / 1000) % 60)
      const minutes = Math.floor((timeDifference / 1000 / 60) % 60)
      const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24)
      setRemainingTime(
        `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`,
      )
    }
    const formatTime = (time) => {
      return time < 10 ? `0${time}` : time
    }
    const timerInterval = setInterval(calculateRemainingTime, 1000)
    return () => {
      clearInterval(timerInterval)
    }
  }, [displayEndDate])
  if (!remainingTime) return <></>
  if (type === 'lureDeal')
    return (
      <>
        <div className='px-1 py-0.5 text-xs text-white bg-black rounded-md opacity-80'>
          {timeDifference > 1000 * 60 * 60 * 24
            ? '마감 1일전'
            : `${remainingTime} 남음`}
        </div>
      </>
    )
  return <div>{remainingTime}</div>
}

export default Timer
