import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import TreasureHuntButton from '../../components/treasureHunt/TreasureHuntButton'
import WatermelonUserStore from '../../stores/WatermelonUserStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import THUserStore from '../../stores/treasureHunt/THUserStore'

const TicketComponent = observer(({ watermelonArcade }) => {
  if (watermelonArcade) {
    return (
      <div
        className={`flex text-[3vw] space-x-3 flex-row justify-center items-center m-3 rounded-full bg-[${WatermelonUserStore.backgroundColor}] text-[#313138] font-bold
    whitespace-nowrap
    `}
      >
        <div className='flex items-center justify-center p-2 m-0 text-center bg-white rounded-full border-1 bg-opacity-40'>
          <img
            className='w-[5vw] h-[5vw] mr-1 ml-1'
            alt='cash'
            src='../watermelonAssets/dailyMission/arcadeTicket.png'
          />
          아케이드 티켓:&nbsp;
          <div className='text-red-400'>
            {`${commaNumber(
              WatermelonUserStore?.userData?.arcadeTicket ?? 0,
            )}개`}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='flex flex-row items-center justify-center'>
      <div
        className={`flex text-[3vw] space-x-3 flex-row justify-center items-center m-3 rounded-full bg-[${WatermelonUserStore.backgroundColor}] text-[#313138] font-bold
    whitespace-nowrap
    `}
      >
        <div className='flex items-center justify-center p-2 m-0 text-center bg-white rounded-full border-1 bg-opacity-40'>
          <img
            className='w-[4vw] h-[4vw] mx-1'
            alt='cash'
            src='../watermelonAssets/shop/coin.png'
          />
          코인:&nbsp;
          <div className='text-red-400'>
            {`${commaNumber(WatermelonUserStore?.userData?.coin ?? 0)}`}
          </div>
        </div>
        <div className='flex items-center justify-center p-2 m-0 text-center bg-white rounded-full border-1 bg-opacity-40'>
          <img
            className='w-[4vw] h-[4vw] mx-1'
            alt='cash'
            src='../watermelonAssets/energy.png'
          />
          도전권:&nbsp;
          <div className='mr-1 text-red-400'>
            {`${commaNumber(WatermelonUserStore?.userData?.ticket ?? 0)}개`}
          </div>
        </div>
        <div
          className={`flex justify-center items-center
    `}
          onClick={() => {
            THModalStore.setType({
              type: 'watermelonRule',
              config: {
                forceOpen: true,
              },
            })
          }}
        >
          <img
            className='w-[7vw] h-auto mr-1 ml-1'
            alt='cash'
            src='../watermelonAssets/ruleInfo.png'
          />
        </div>
      </div>
    </div>
  )
})

export default TicketComponent
