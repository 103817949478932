import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import EggMoneyAction from '../../../actions/EggMoneyAction'
import Button from '../../../pages/EggMoney/Button'
import ToastStore from '../../../stores/ToastStore'
import THModalStore from '../../../stores/treasureHunt/THModalStore'
import backendApis from '../../../utils/backendApis'

function renderDescription(text) {
  return text?.split?.('\n')?.map((line, index) => (
    <span key={Math.random()}>
      {line}
      {index < text?.split('\n').length - 1 && <br />}
    </span>
  ))
}

const EggMoneyKakaoShare = ({ config }) => {
  const [shareCount, setShareCount] = useState(0)

  useEffect(() => {
    kakaoShareCount()
  }, [])

  const kakaoShareCount = async () => {
    const res = await backendApis.kakaoShareCount({
      params: {
        shareType: 'EggMoney_KakaoShare',
      },
    })
    if (res?.status === 200) {
      setShareCount(res?.data)
    } else {
      ToastStore.networkError(res.status)
      THModalStore.close()
    }
  }

  return (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw]'>
        랜덤 에그 받기
      </div>
      <div className='relative flex items-center justify-center w-full my-8'>
        <img
          alt='main'
          src={config.src}
          className={`relative z-[10] w-1/2 h-auto ${config?.imgClassName}`}
        />
      </div>
      <div className='font-bold -mt-4 text-center'>
        {renderDescription(
          `카카오 공유하고 랜덤 에그를 바로 뽑아보세요!\n4번째 공유마다 B랭크 이상의 에그가 나와요!`,
        )}
      </div>
      <div className='text-[#FF717A] font-semibold mt-2 text-sm'>{`
        ${
          ((shareCount ?? 0) + 1) % 4 === 0
            ? '이번 공유 보상은 최소 B등급 이상이에요!'
            : `보너스까지 ${4 - ((shareCount ?? 0) % 4)}번 남았어요`
        }
        `}</div>

      <Button
        className='w-full py-3 mt-6'
        clickable
        text='공유하기'
        onClick={() => {
          EggMoneyAction.userLog({ type: 'click', tag: 'kakaoShareButton' })
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'kakaoShare',
              data: {
                shareType: config?.shareType,
                title: config?.title,
                description: config?.description,
                imageUrl: config?.imageUrl,
                payload: config?.payload,
                templateId: config?.templateId,
              },
            }),
          )
          THModalStore?.close()
        }}
      />
    </div>
  )
}

export default observer(EggMoneyKakaoShare)
