import { observable, toJS } from 'mobx'

const RouteStore = observable({
  navigatable: false,
  navigatePath: '',
  currentRoute: 'home',

  set(key, value) {
    if (key?.includes('.')) {
      const [parentKey, childKey] = key?.split('.')
      this[parentKey][childKey] = value
    } else {
      this[key] = value
    }
  },

  navigate(path) {
    this.navigatePath = path
    this.navigatable = true
    if (path.includes('/treasureHunt/')) {
      this.currentRoute = path?.split('/')[2]?.split('?')[0]
    } else {
      this.currentRoute = 'home'
    }
  },
})

export default RouteStore
