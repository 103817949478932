import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useInterval } from 'react-use'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import THBottomSheetStore from '../../../stores/treasureHunt/THBottomSheetStore'
import TreasureHuntButton from '../TreasureHuntButton'

const Tutorial = observer(() => {
  const [changeTextIndex, setChangeTextIndex] = useState(0)

  useInterval(
    () => {
      setChangeTextIndex(
        (changeTextIndex + 1) %
          THBottomSheetStore?.config?.changeTextList?.length,
      )
    },
    THBottomSheetStore?.config?.changeTextList ? 2300 : null,
  )

  return (
    <div className='flex flex-col p-6 pb-4 w-full text-[#313138]'>
      <img
        className='w-[14vw] h-[14vw] mb-4'
        src={THBottomSheetStore?.config?.subImg}
        alt='subImg'
      />
      <div
        className={`
        pb-1 font-semibold text-[4.5vw] 
        ${THBottomSheetStore?.config?.subtitleClass}
        `}
      >
        {THBottomSheetStore?.config?.subtitle}
      </div>
      <div
        className='pb-4 text-[6vw] font-bold'
        style={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'keep-all',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
        }}
      >
        {THBottomSheetStore?.config?.title}
        {THBottomSheetStore?.config?.changeTextList && (
          <div className='inline-block border-2 text-center border-[#ff857f] p-1 rounded-lg fadeIn-loop '>
            {THBottomSheetStore?.config?.changeTextList[changeTextIndex]}
            <span>를 무료로</span>
          </div>
        )}
        {THBottomSheetStore?.config?.secondTitle}
      </div>
      {THBottomSheetStore?.config?.mainImg && (
        <div className='flex justify-center items-center w-full'>
          <img
            className='w-full '
            src={THBottomSheetStore?.config?.mainImg}
            alt='mainImg'
          />
        </div>
      )}
      <TreasureHuntButton
        clickable
        text={THBottomSheetStore?.config?.CTAText}
        className='py-4 mt-6 text-[5vw]'
        onClick={() => {
          if (THBottomSheetStore?.config?.onClick) {
            THBottomSheetStore?.config?.onClick()
          }
          THBottomSheetStore?.close()
        }}
      />
      {THBottomSheetStore?.config?.subButton && (
        <div
          className='py-2 flex justify-center items-center text-[3.5vw] underline'
          onClick={() => {
            THBottomSheetStore?.close()
          }}
        >
          {THBottomSheetStore?.config?.subButton}
        </div>
      )}
    </div>
  )
})

export default Tutorial
