import { Spinner } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { FcAlarmClock } from 'react-icons/fc'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { useSearchParam } from 'react-use'

import DailyCheckInAction from '../../actions/DailyCheckInAction'
import ScrolltoTopButton from '../../components/atoms/ScrollToTopButton'
import HeaderComponent from '../../components/dailyCheckIn/HeaderComponent'
import OfferwallCheckInComponent from '../../components/dailyCheckIn/OfferwallCheckInComponent'
import OfferwallContentItem from '../../components/dailyCheckIn/OfferwallContentItem'
import VerticalList from '../../components/dailyCheckIn/verticalList'
import { YellowCoins } from '../../images/dailyCheckIn'
import yellowCoins from '../../images/dailyCheckIn/yellowCoins.png'
import UserStore from '../../stores/UserStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import AB_V3 from '../../utils/ab_v3'
import backendApis from '../../utils/backendApis'

const DescriptionTranslator = (type) => {
  switch (type) {
    // cpm, cpc는 노출형 광고이고 이외는 모두 액션형 광고
    case 'cpm':
      return '클릭하'
    case 'cpc':
      return '클릭하'
    case 'cpa':
      return '참여하'
    case 'cpk':
      return '카카오톡 채널 추가하'
    case 'cpq':
      return '퀴즈풀'
    case 'cpqlite':
      return '퀴즈풀'
    case 'cpi':
      return '설치하'
    case 'cps':
      return '구매하'
    case 'cptiktok':
      return '틱톡 팔로우하'
    case 'cpl':
      return '페이스북 좋아요하'
    case 'cpyoutube':
      return '유튜브 구독하'
    case 'cpnstore':
      return '네이버 스토어 알림설정하'
    case 'cpinsta':
      return '인스타그램 팔로우하'
    case 'cpylike':
      return '유튜브 구독 및 좋아요하'
    case 'cpe':
      return '이벤트 참여하'
    case 'cpcquiz':
      return '퀴즈풀'
    default:
      return '참여하'
  }
}

const renderDescription = (text) =>
  text.split('\n').map((line, index) => (
    <span key={Math.random()}>
      {line}
      {index < text.split('\n').length - 1 && <br />}
    </span>
  ))

let visibilityChange = ''
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  visibilityChange = 'webkitvisibilitychange'
}

const OfferwallPage = observer(() => {
  const navigate = useNavigate()
  const token = useSearchParam('token')
  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [payload, setPayload] = useState(undefined)
  const [offerwallList, setOfferwallList] = useState([])
  const [filter, setFilter] = useState([])
  const [bottomsheetData, setBottomsheetData] = useState({
    isOpen: false,
    ad: undefined,
  })
  const SCREEN_HEIGHT = window.innerHeight
  let isLoadingReward

  const fetchData = async ({ adTypeList = 'all' }) => {
    if (
      isLoading ||
      !UserStore.userInfo?._id ||
      !UserStore.offerwallInfo.platform
    )
      return
    setIsLoading(true)

    const params = {
      userId: UserStore.userInfo?._id,
      uniqueUserId: UserStore.offerwallInfo.uniqueUserId,
      ifa: UserStore.offerwallInfo.ifa,
      ifv: UserStore.offerwallInfo.ifv,
      platform: UserStore.offerwallInfo.platform,
      placement: UserStore.offerwallInfo.placement,
    }
    // 방어로직 - 버즈빌 오퍼월 정보 가져오기
    if (UserStore.offerwallInfo.unitId === '') {
      const offerwallMetaData = await backendApis.getOfferwallMetaBuzzvil(
        params,
      )
      if (offerwallMetaData?.status === 200) {
        UserStore.setOfferwallInfo(
          {
            totalReward: offerwallMetaData.data?.result?.total_reward,
            appId: offerwallMetaData.data?.appId,
            unitId: offerwallMetaData.data?.unitId,
            uniqueUserId:
              offerwallMetaData.data?.uniqueUserId || UserStore.userInfo?._id,
          },
          true,
        )
        params.uniqueUserId =
          offerwallMetaData.data?.uniqueUserId || UserStore.userInfo?._id
      } else {
        THModalStore.setType({
          type: 'confirm',
          config: {
            forceOpen: true,
            removeCloseButton: true,
            title: '참여할 수 있는 미션이 없어요',
            description:
              offerwallMetaData?.data?.msg ||
              '미성년자는 미션 참여가 제한될 수 있어요.',
            src: null,
            buttonText: '확인',
            onConfirm: () => {
              THModalStore.close()
            },
            clickable: true,
          },
        })
      }
    }

    if (adTypeList !== 'all') {
      params.adTypeList = JSON.stringify(adTypeList)
    }
    if (payload) {
      params.payload = payload
    }
    const result = await backendApis.getOfferwallListBuzzvil(params)
    if (result?.status === 200 && result.data?.ads?.length > 0) {
      setOfferwallList(
        offerwallList?.length > 0
          ? [...offerwallList, ...result?.data?.ads]
          : result?.data?.ads,
      )
      setPayload(result.data?.cursor)
    }

    setIsLoading(false)
    setIsInitialLoading(false)
  }

  const fetchRewardData = async () => {
    if (isLoadingReward) return
    isLoadingReward = true

    const result = await backendApis.getInintModalData()
    if (result?.status === 200 && result?.data?.initModalList?.length > 0) {
      setBottomsheetData({ isOpen: false })
      for (const init of result?.data?.initModalList) {
        THModalStore.setType({
          type: 'reward',
          config: {
            removeCloseButton: true,
            earnEffect: true,
            removeFont: true,
            rewardName: `${init?.point}원을 받았어요!`,
            description: `${
              init?.title.length > 15
                ? `${init?.title.substring(0, 15)}...`
                : init?.title
            }\n미션 완료`,
            src: yellowCoins,
            text: '확인',
            forceOpen: true,
          },
        })
      }
      backendApis.checkInSet({
        set: {
          initModalList: [],
        },
        collection: 'daily_check_in',
      })
    }

    const todayRewardResult = await backendApis.getTodayRewards()
    const offerWallMission =
      todayRewardResult?.result?.dailyCheckInRewardHistory?.filter(
        (e) => e?.rewardType === 'offerwall',
      )
    if (todayRewardResult?.status === 200 && offerWallMission?.length > 0) {
      const todayTotalRewardAmount = offerWallMission.reduce(
        (sum, mission) => sum + (mission.rewardAmount || 0),
        0,
      )
      UserStore.setOfferwallInfo(
        { todayTotalReward: todayTotalRewardAmount },
        true,
      )
    }

    isLoadingReward = false
  }

  const participateAd = async () => {
    if (isLoading) return
    setIsLoading(true)

    const thisAd = bottomsheetData?.ad
    DailyCheckInAction.goExternalbrowser(thisAd?.landing_url)
    setBottomsheetData((prevData) => ({
      ...prevData,
      ad: { ...prevData?.ad, isParticipated: true },
    }))

    // 참여 로깅
    const participateLog = {
      eventType: 'ad_participate',
      userId: UserStore.userInfo?._id,
      uniqueUserId: UserStore.offerwallInfo.uniqueUserId,
      unitId: UserStore.offerwallInfo.unitId,
      networkName: UserStore.offerwallInfo.networkName,
      placement: UserStore.offerwallInfo.placement,
      adId: thisAd?.id,
      adType: thisAd?.type,
      reward: thisAd?.reward,
      rewardPoint: thisAd?.rewardPoint,
      adName: thisAd?.name,
      creativeTitle: thisAd?.creative?.title,
      creativeDescription: thisAd?.creative?.description,
      reward_condition: thisAd?.reward_condition,
      check_participation_url: thisAd?.check_participation_url || '',
    }
    backendApis.recordOfferwallEventLog(participateLog)

    setIsLoading(false)
  }

  const clickInquiry = () => {
    if (isLoading) return
    setIsLoading(true)

    const params = {
      user_id: UserStore.offerwallInfo.uniqueUserId || UserStore.userInfo?._id,
      app_id: UserStore.offerwallInfo.appId,
      unit_id: UserStore.offerwallInfo.unitId,
      platform: UserStore.offerwallInfo.platform,
      ifa: UserStore.offerwallInfo.ifa,
      ifv: UserStore.offerwallInfo.ifv,
    }
    // 쿼리 문자열 생성
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&')
    const encodedString = btoa(queryString)

    // 최종 URL 생성
    const url = `https://ad.buzzvil.com/offerwall/inquiry?is_first_page=1&show_close_button=0&q=${encodedString}`
    DailyCheckInAction.goExternalbrowser(url)

    setIsLoading(false)
  }

  const HeaderComponent = () => {
    return (
      <div className='fixed top-0 z-20 w-full'>
        <div className='relative z-0 flex flex-row items-center pt-2 overflow-hidden bg-white'>
          <button
            type='button'
            className='flex flex-1 px-3 py-2 ml-1 duration-200 rounded-lg whitespace-nowrap active:scale-95 active:bg-gray-200'
            onClick={() => {
              navigate(`/daily-check-in?token=${token}`)
            }}
          >
            <FiChevronLeft className='w-6 h-6 stroke-gray-600' />{' '}
          </button>
          <div className='w-full p-2 text-lg font-semibold text-center text-gray-800'>
            올포인트 모으기
          </div>
          <button
            type='button'
            className='flex flex-1 py-2 pr-4 whitespace-nowrap'
            onClick={() => {
              clickInquiry()
            }}
          >
            문의
          </button>
        </div>
      </div>
    )
  }
  useEffect(() => {
    if (token && offerwallList?.length === 0) {
      fetchRewardData()
      fetchData({ adTypeList: 'all' })

      if (
        UserStore.offerwallInfo.ifa === '00000000-0000-0000-0000-000000000000'
      ) {
        if (UserStore.offerwallInfo.platform === 'A') {
          THModalStore.setType({
            type: 'confirm',
            config: {
              forceOpen: true,
              removeCloseButton: true,
              title: '광고 ID를 받아주세요',
              description: `ID가 있으면 더 많은 미션을 할 수 있어요.\n(설정 > Google > 모든 서비스 탭 > 광고 >새 광고 ID 받기)`,
              src: null,
              buttonText: '확인',
              onConfirm: () => {
                THModalStore.close()
              },
              clickable: true,
            },
          })
        } else {
          THModalStore.setType({
            type: 'confirm',
            config: {
              forceOpen: true,
              removeCloseButton: true,
              title: '앱 추적 허용이 필요해요',
              description: `허용하면 더 많은 미션을 할 수 있어요.\n(설정 > 개인정보 보호 및 보안 > 추적 > 올웨이즈 앱 허용)`,
              src: null,
              buttonText: '확인',
              onConfirm: () => {
                THModalStore.close()
              },
              clickable: true,
            },
          })
        }
      }
    }
  }, [token])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setTimeout(() => fetchRewardData(), 500)
      }
    }
    document.addEventListener(visibilityChange, handleVisibilityChange, false)
    return () => {
      document.removeEventListener(
        visibilityChange,
        handleVisibilityChange,
        false,
      )
    }
  }, [])

  const offerwallDailyCheckInAB = AB_V3(
    UserStore.userInfo?._id,
    'offerwallDailyCheckIn1118',
    [100],
  )

  const showDailyCheckIn = offerwallDailyCheckInAB === 'b'

  return (
    <div className='h-screen bg-white'>
      {/* <HeaderComponent
        bgWhite
        headerTitle='올포인트 모으기'
        subButtonText='문의'
        className='fixed top-0 z-20 w-full'
        onClickBackButton={() => navigate(-1)}
        onClickSubButton={() => clickInquiry()}
      /> */}
      <HeaderComponent />
      {/* 오퍼월 인트로 */}
      <div className='flex flex-row items-center justify-between px-6 py-8 mt-10'>
        {UserStore.offerwallInfo.totalReward > 10000 &&
        UserStore.offerwallInfo.ifa !==
          '00000000-0000-0000-0000-000000000000' ? (
          <div className='flex flex-col'>
            <div className='mb-0.5 text-lg text-gray-700'>
              미션 완료하면 올포인트
            </div>
            <div className='mb-0.5 text-2xl font-bold text-gray-900'>
              최대 {UserStore.offerwallInfo.totalReward?.toLocaleString()}원
              받아요
            </div>
            <div className='text-sm text-gray-700 min-h-5'>
              {UserStore.offerwallInfo.todayTotalReward > 0
                ? `오늘 모으기 미션으로
                ${UserStore.offerwallInfo.todayTotalReward?.toLocaleString()}원 받았어요`
                : ` `}
            </div>
          </div>
        ) : (
          <div className='flex flex-col'>
            <div className='mb-0.5 text-lg text-gray-700'>미션 완료하면</div>
            <div className='mb-0.5 text-2xl font-bold text-gray-900'>
              올포인트 받아요
            </div>
            <div className='text-sm text-gray-700 min-h-5'>
              {UserStore.offerwallInfo.todayTotalReward > 0
                ? `오늘 모으기 미션으로
                ${UserStore.offerwallInfo.todayTotalReward?.toLocaleString()}원 받았어요`
                : ` `}
            </div>
          </div>
        )}
        <button
          type='button'
          onClick={() => {
            if (UserStore.userInfo?._id === '626aa6a6c4d18f4110ecd6cb') {
              DailyCheckInAction.getIFA()
              alert(JSON.stringify(UserStore.offerwallInfo))
            }
          }}
        >
          <YellowCoins className='w-12 mx-2' />{' '}
        </button>
      </div>
      {/* 필터 및 정렬 */}
      <div className='flex flex-row items-center gap-2 mb-0 ml-6 text-sm font-semibold whitespace-nowrap'>
        <button
          type='button'
          className={`h-8 px-3 duration-200 rounded-lg active:brightness-90 active:scale-95 min-w-14
          ${filter?.length === 0 ? ' text-red-500  bg-red-50' : 'bg-gray-100'}`}
          onClick={() => setFilter([])}
        >
          전체
        </button>
        <button
          type='button'
          className={`h-8 px-3 duration-200 rounded-lg active:brightness-90 active:scale-95 min-w-14
            ${
              filter?.includes('cpm')
                ? 'text-red-500  bg-red-50'
                : 'bg-gray-100'
            }`}
          onClick={() => setFilter(['cpm', 'cpc'])}
        >
          방문하기
        </button>
        <button
          type='button'
          className={`h-8 px-3 duration-200 rounded-lg active:brightness-90 active:scale-95 min-w-14
            ${
              filter?.includes('cpa')
                ? 'text-red-500  bg-red-50'
                : 'bg-gray-100'
            }`}
          onClick={() => setFilter(['cpq', 'cpa', 'cpqlite'])}
        >
          참여하기
        </button>
        <button
          type='button'
          className={`h-8 px-3 duration-200 rounded-lg active:brightness-90 active:scale-95 min-w-14
            ${
              filter?.includes('cpk')
                ? 'text-red-500  bg-red-50'
                : 'bg-gray-100'
            }`}
          onClick={() =>
            setFilter(['cpk', 'cpl', 'cpyoutube', 'cpylike', 'cpinsta'])
          }
        >
          구독하기
        </button>
      </div>
      {showDailyCheckIn && (
        <OfferwallCheckInComponent
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isInitialLoading && (
        <div className='flex justify-center w-full my-20'>
          <Spinner className='w-8 h-8' color='red' />
        </div>
      )}
      {/* 광고 리스트 */}
      {isInitialLoading || offerwallList?.length !== 0 ? (
        <>
          <VerticalList
            height={SCREEN_HEIGHT}
            isFrom='OfferWall_CheckIn'
            columnCount={1}
            itemData={(() => {
              const filteredList =
                filter?.length > 0
                  ? offerwallList.filter((ad) => filter?.includes(ad.type))
                  : offerwallList

              if (filteredList.length === 0) {
                fetchData({
                  adTypeList: filter?.length > 0 ? filter : 'all',
                })
              }

              return filteredList
            })()}
            renderItem={(index, data, isScrolling) => (
              <OfferwallContentItem
                key={index}
                empty={isInitialLoading}
                itemInfo={data}
                isScrolling={isScrolling}
                setBottomsheetData={setBottomsheetData}
                setOfferwallList={setOfferwallList}
              />
            )}
            loadMore={() => {
              fetchData({
                adTypeList: filter?.length > 0 ? filter : 'all',
              })
            }}
          />
          <div className='flex justify-center w-full my-2'>
            <Spinner className='w-8 h-8' color='red' />
          </div>
        </>
      ) : (
        <div className='flex flex-col items-center mx-4 my-20 text-center h-80'>
          <FcAlarmClock className='w-12 h-12 m-6' />
          <div className='text-lg font-semibold'>미션을 준비중이에요</div>
          <div className='text-sm text-gray-700'>
            참여할 수 있는 미션을
            <br /> 다시 불러올까요?
          </div>
          <button
            type='button'
            className='h-8 px-3 mx-auto my-4 text-sm font-semibold duration-200 bg-gray-200 rounded-lg active:brightness-90 active:scale-95 min-w-14'
            onClick={() => {
              fetchData({
                adTypeList: filter?.length > 0 ? filter : 'all',
              })
            }}
          >
            불러오기
          </button>
        </div>
      )}
      <ScrolltoTopButton />
      <BottomSheet
        open={bottomsheetData.isOpen}
        onDismiss={() => setBottomsheetData({ isOpen: false })}
        className='relative z-20'
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight * 0.85]}
      >
        {bottomsheetData?.ad && (
          <div className='mx-5 mt-1'>
            <img
              className='w-full rounded-xl'
              alt='ad_image'
              src={bottomsheetData.ad?.creative.image_url}
            />

            <div className='my-6 text-xl font-bold text-gray-900'>
              <div className='mb-0'>
                {bottomsheetData.ad?.creative.title.length > 15
                  ? `${bottomsheetData.ad?.creative.title.substring(0, 15)}..`
                  : bottomsheetData.ad?.creative.title}{' '}
                {DescriptionTranslator(bottomsheetData.ad.type)}면
              </div>
              <div className='text-gray-900'>
                올포인트{' '}
                {bottomsheetData.ad?.rewardPoint
                  ? `${bottomsheetData.ad?.rewardPoint?.toLocaleString()}원`
                  : ''}{' '}
                드려요
              </div>
            </div>
            <div className='p-3 mb-4 text-sm text-gray-700 bg-gray-200 rounded-lg'>
              {bottomsheetData.ad?.action_description
                ? renderDescription(bottomsheetData.ad?.action_description)
                : bottomsheetData.ad?.creative.description}
            </div>

            <div className='mt-8 mb-24'>
              <div className='mb-4 border border-gray-100' />
              <div className='mb-2 font-semibold text-gray-600'>유의사항</div>
              <p className='text-sm text-gray-600 text-wrap'>
                • 받은 올포인트는 &apos;출석체크&apos; 화면에서 확인할 수
                있습니다.
                <br />
                • 미션을 완료하고 포인트를 받기까지 약 5분 정도 걸릴 수
                있습니다.
                <br />
                • 포인트를 받지 못했다면 상단 &apos;문의&apos;를 통해
                문의해주시길 부탁드립니다.
                <br />
                • 부적절한 방법으로 참여한 경우, 포인트를 받을 수 없거나
                올포인트 모으기를 이용하지 못할 수 있습니다.
                <br />• &apos;올포인트 모으기&apos;는 제휴사 혹은 회사 사정에
                따라 사전고지 없이 변경되거나 종료될 수 있습니다.
                <br />• 네트워크 장애가 생기면 미션을 완료하고 포인트를 받을
                때까지 시간이 더 소요될 수 있습니다.
              </p>
            </div>

            <button
              type='button'
              className={`fixed bottom-4 left-0 z-20 right-0 p-3 my-4 w-full text-lg font-semibold rounded-xl whitespace-nowrap
                ${
                  bottomsheetData.ad?.rewardPoint === 0 ||
                  bottomsheetData.ad?.isParticipated
                    ? 'bg-gray-300 text-gray-600'
                    : 'bg-red-500 text-white active:brightness-90 active:scale-95 duration-200'
                }
              `}
              disabled={
                bottomsheetData.ad?.rewardPoint === 0 ||
                isLoading ||
                (bottomsheetData.ad?.isParticipated &&
                  bottomsheetData.ad?.reward_condition === 'click')
              }
              style={{ margin: '0 auto', width: '90%' }}
              onClick={() => participateAd()}
            >
              {bottomsheetData.ad?.isParticipated ? (
                <>
                  {bottomsheetData.ad?.reward_condition === 'click'
                    ? '참여 완료'
                    : '참여 확인중'}
                </>
              ) : (
                <>
                  {bottomsheetData.ad?.rewardPoint
                    ? `${DescriptionTranslator(
                        bottomsheetData.ad.type,
                      )}고 ${bottomsheetData.ad?.rewardPoint?.toLocaleString()}원 받기`
                    : '이미 참여했거나 현재 참여가 어려워요'}
                </>
              )}
            </button>
          </div>
        )}
      </BottomSheet>
    </div>
  )
})

export default OfferwallPage
