import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useInterval } from 'react-use'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import THUserStore from '../../stores/treasureHunt/THUserStore'
import utility from '../../utils/utility'
import TreasureHuntButton from './TreasureHuntButton'

const THPet = observer(() => {
  const [title, setTitle] = useState('야옹이가 기념품을 준비했어요! 🎉')
  const [CTAText, setCTAText] = useState('기념품 받기')
  const [emojiId, setEmojiId] = useState(0)
  const [travelId, setTravelId] = useState(utility.random(0, 29))

  useEffect(() => {
    if (THUserStore?.pet?.status === 'home') {
      setTitle('여행을 보내주면 다음날 기념품을 받아요! 🎁')
      setCTAText('야옹이 여행 보내주기')
    } else if (THUserStore?.pet?.status === 'travel') {
      setTitle('야옹이는 지금 여행 중 🥰')
      setCTAText('내일 기념품을 받을 수 있어요')
    } else if (THUserStore?.pet?.status === 'gift') {
      setTitle('야옹이가 기념품을 준비했어요! 🎉')
      setCTAText('기념품 받기')
    }
  }, [THUserStore?.pet?.status])

  useInterval(
    () => {
      setEmojiId(utility.random(0, 8))
      setTravelId(utility.random(0, 29))
    },
    THUserStore?.pet?.status === 'travel' ? utility.random(2000, 3000) : null,
  )

  return (
    <div
      className={`default relative justify-center items-center w-full pb-4 text-[#313138] p-4 ${
        THUserStore?.pet?.status === 'travel' && 'bg-black/20'
      }`}
    >
      {THUserStore?.pet?.status === 'home' && (
        <TreasureHuntButton
          clickable={
            !THUserStore?.pet ||
            THUserStore?.pet?.status === 'gift' ||
            THUserStore?.pet?.status === 'home'
          }
          text={CTAText}
          className='w-full py-3 mb-4'
          onClick={() => {
            if (!THUserStore?.pet) {
              TreasureHuntAction.collectPetGift()
            } else if (THUserStore?.pet?.status === 'gift') {
              TreasureHuntAction.collectPetGift()
            } else if (THUserStore?.pet?.status === 'home') {
              TreasureHuntAction.sendPetTravel()
            }
          }}
        />
      )}
      <div className='relative flex flex-col justify-center items-center'>
        <img
          className='w-[35vw] h-[35vw]'
          src='../images/treasureHunt/pet/speechBubble.png'
          alt='bubble'
        />
        {THUserStore?.pet?.status === 'travel' ? (
          <img
            className='w-[20vw] h-[20vw] absolute bottom-[11vw]'
            src={`../images/treasureHunt/pet/travel/${travelId}.png`}
            alt='bubble'
          />
        ) : (
          <img
            className='w-[20vw] h-[20vw] absolute bottom-[11vw]'
            src={`../images/treasureHunt/pet/${
              THUserStore?.pet?.status ?? 'gift'
            }.png`}
            alt='bubble'
          />
        )}
      </div>
      <div className='relative flex flex-col flex-1 justify-center items-center my-4'>
        <img
          className='w-[20vw] h-[20vw]'
          src={`../images/treasureHunt/pet/${emojiId}.png`}
          alt='cat'
        />
        {THUserStore?.pet?.status === 'travel' && (
          <img
            className='w-[17vw] h-[17vw] absolute -top-[8vw] -right-12'
            src='../images/treasureHunt/pet/paw.png'
            alt='cat'
          />
        )}
      </div>
      <div className='flex flex-col justify-center items-center mb-4'>
        <div className='font-bold text-[#313138]'>{title}</div>
      </div>

      {(!THUserStore?.pet ||
        THUserStore?.pet?.status === 'gift' ||
        THUserStore?.pet?.status === 'travel') && (
        <TreasureHuntButton
          clickable={
            !THUserStore?.pet ||
            THUserStore?.pet?.status === 'gift' ||
            THUserStore?.pet?.status === 'home'
          }
          text={CTAText}
          className='w-full py-3'
          onClick={() => {
            if (!THUserStore?.pet) {
              TreasureHuntAction.collectPetGift()
            } else if (THUserStore?.pet?.status === 'gift') {
              TreasureHuntAction.collectPetGift()
            } else if (THUserStore?.pet?.status === 'home') {
              TreasureHuntAction.sendPetTravel()
            }
          }}
        />
      )}
    </div>
  )
})

export default THPet
