import { AppScreen } from '@stackflow/plugin-basic-ui'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useSearchParam } from 'react-use'

import ComponentTitle from '../components/atoms/ComponentTitle'
import ToonModal from '../components/templates/ToonModal'
import BottomBarComponent from '../components/toon/BottomBarComponent'
import MainNovelComponent from '../components/toon/MainNovelComponent'
import QuicklinkComponent from '../components/toon/QuicklinkComponent'
import BannerSwiperComponent from '../components/toon/bannerSwiperComponent'
import MainToonComponent from '../components/toon/mainToonComponent'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import AltoonUserStore from '../stores/AltoonUserStore'
import AuthStore from '../stores/AuthStore'
import THModalStore from '../stores/treasureHunt/THModalStore'
import backendApis from '../utils/backendApis.ts'

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const AltoonMainPage = observer(() => {
  const scrollContainerRef = useRef(null)
  const hasProcessedParams = useRef(false)

  const { push } = useMyFlow()
  const navigate = useNavigate()

  const token = AuthStore.token || useSearchParam('token')
  const code = useSearchParam('code')
  const alfarmMissionToonId = useSearchParam('missionToonId')

  const inflowToonId = useSearchParam('toonId')
  const inflowNovelId = useSearchParam('novelId')
  const inflowEventId = useSearchParam('eventId')

  let isCancelled = false

  const [isLoading, setIsLoading] = useState(false)
  const [openTicketModal, setOpenTicketModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [modalProp, setModalProp] = useState([])

  const [toons, setToons] = useState([])
  const [bannerData, setBannerData] = useState()
  const [shortBannerData, setShortBannerData] = useState()
  const [recentlyViewedToons, setRecentlyViewedToons] = useState([])
  const [trendingToons, setTrendingToons] = useState([])
  const [newMainToons, setNewMainToons] = useState([])
  const [commentRecommendToons, setCommentRecommendToons] = useState([])
  const [previewToons, setPreviewToons] = useState([])
  // URL 쿼리 파라미터를 제거하는 함수
  const clearUrlParams = () => {
    const url = new URL(window.location.href)
    url.searchParams.delete('toonId')
    url.searchParams.delete('code')
    url.searchParams.delete('missionToonId')
    url.searchParams.delete('novelId')
    url.searchParams.delete('eventId')
    window.history.replaceState({}, '', url)
  }

  useEffect(() => {
    const fetchToonData = async () => {
      if (hasProcessedParams.current) return

      // 올툰 유저정보 가져오기
      const userData = await backendApis.loadUserData()

      if (userData?.status === 200) {
        AltoonUserStore.set('userData', userData?.data)
        if (userData.data?.dailyMission?.appPushInfo) {
          AltoonUserStore.set(
            'dailyMission.appPushInfo',
            userData.data.dailyMission.appPushInfo,
          )
        }
        if (userData.data?.dailyMission?.firstPurchase) {
          AltoonUserStore.set(
            'dailyMission.firstPurchase',
            userData.data.dailyMission.firstPurchase,
          )
        }
        if (code === 'alfarmMission') {
          hasProcessedParams.current = true
          clearUrlParams()

          // 올팜 물미션으로 온 친구들
          await sleep(500)
          setModalType('alfarmOnboarding')
          setOpenTicketModal(true)
          // navigate(`/altoon-farm-landing?token=${token}`)
        } else if (code === 'alfarmFertMission') {
          hasProcessedParams.current = true

          // 올팜 비료미션으로 온 친구들
          // await sleep(500)
          clearUrlParams()

          push('AltoonDetailPage', { toonId: alfarmMissionToonId })
        } else if (inflowToonId) {
          hasProcessedParams.current = true
          clearUrlParams()

          push('AltoonDetailPage', { toonId: inflowToonId })
        } else if (inflowNovelId) {
          hasProcessedParams.current = true
          clearUrlParams()
          navigate(`/altoon-novel-detail?novelId=${inflowNovelId}`)
        } else if (inflowEventId) {
          hasProcessedParams.current = true
          clearUrlParams()

          push('AltoonEventPage', { eventId: inflowEventId })
        }

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

        const showModalSequentially = async (initModalList) => {
          for (const init of initModalList) {
            await delay(800)
            if (isCancelled) break
            THModalStore.setType(init)
          }
          if (!isCancelled) {
            await backendApis.setToonInitModal({ initModalList: [] })
          }
        }

        if (userData?.data?.initModalList) {
          showModalSequentially(userData.data.initModalList)
        }
      }

      // 배너 정보 가져오기
      const bannerData = await backendApis.loadBannerData()
      if (bannerData?.status === 200) {
        if (userData?.data?.dailyMission?.firstPurchase) {
          const filteredBannerData = bannerData?.data?.filter(
            (banner) =>
              !banner?.bannerType &&
              banner?.itemId?.toString() !== '65e0160e3401440ed9cecde3',
          )
          setBannerData(filteredBannerData)
        } else {
          const filteredBannerData = bannerData?.data?.filter(
            (banner) => !banner?.bannerType,
          )
          setBannerData(filteredBannerData)
        }

        const filteredShortBannerData = bannerData?.data?.filter(
          (banner) => banner?.bannerType === 'mainShortBanner',
        )
        setShortBannerData(filteredShortBannerData)
      }

      const modalRejectedAt = userData?.data?.modalRejectedAt
      if (
        !modalRejectedAt ||
        moment()
          .startOf('day')
          .diff(moment(modalRejectedAt).startOf('day'), 'days') >= 1
      ) {
        const modalData = await backendApis.loadToonPopUpModal()
        if (modalData?.status === 200) {
          const handleModalClick = (data) => {
            const pathMapper = {
              '/altoon-reader': 'AltoonReaderPage',
              '/altoon-novel-reader': 'AltoonNovelReaderPage',
              '/altoon-item': 'AltoonItemPage',
              '/altoon-event-page': 'AltoonEventPage',
            }

            // URL에서 pathname과 쿼리 파라미터 분리
            const queryString = data?.pathname?.split('?')
            const searchParams = new URLSearchParams(queryString[1])
            const eventId = searchParams.get('eventId') || ''
            const itemId = searchParams.get('itemId') || ''

            push(pathMapper[queryString[0]], {
              state: {
                ...data?.state,
              },
              ...(eventId && { eventId }),
              ...(itemId && { itemId }),
              component: 'main_modal',
            })
          }
          const modalList = modalData.data
          if (modalList.length > 0) {
            THModalStore.setType({
              type: 'toonModal',
              config: {
                forceOpen: true,
                src: `${modalList[0]?.imageUri}`,
                navigateTo: () => {
                  handleModalClick(modalList[0]?.navigateTo)
                },
                onClickReject: async () => {
                  await backendApis.logModalReject()
                },
              },
            })
          }
        }
      }

      // 작품 정보 가져오기
      const toonResult = await backendApis.loadAllToons()
      if (toonResult?.status === 200) {
        // deletedAt이 없는 모든 작품 가져와서 isAdmin인 작품을 거름
        setToons(toonResult.data.filter((toon) => !toon.isAdmin))
        if (AltoonUserStore.userData?.isAdmin === true) {
          // 어드민 계정이면 맨 뒤에 어드민 작품을 붙여줌
          setToons([
            ...toonResult.data.filter((toon) => !toon.isAdmin),
            ...toonResult.data.filter((toon) => toon.isAdmin),
          ])
        }

        // 인기순 데이터 가져오기
        const trendingToonLog = await backendApis.loadTrendingToons()

        if (trendingToonLog?.status === 200) {
          const toonMap = new Map(
            toonResult.data.map((toon) => [toon._id, toon]),
          )

          const trendingToonList = trendingToonLog.data.map((trending) => {
            const toonDetails = toonMap.get(trending.toonId)

            return {
              ...trending, // 트렌딩 만화의 기존 데이터
              toonDetails, // 매핑된 만화의 상세 정보
            }
          })

          setTrendingToons(trendingToonList)
        }

        // 새로운 작품 등장! 영역 작품
        const newToonLog = await backendApis.loadRecommendingToons(
          'hot',
          'mainNew',
        )

        if (newToonLog?.status === 200) {
          const toonMap = new Map(
            toonResult.data.map((toon) => [toon._id, toon]),
          )

          const newToonList = newToonLog.data.map((toon) => {
            const toonDetails = toonMap.get(toon.toonId)

            return {
              ...toon, // 트렌딩 만화의 기존 데이터
              toonDetails, // 매핑된 만화의 상세 정보
            }
          })

          setNewMainToons(newToonList)
        }

        // 미리보기 영역 작품
        const commentRecommendToons = await backendApis.loadRecommendingToons(
          'hot',
          'commentRecommend',
        )

        if (commentRecommendToons?.status === 200) {
          const toonMap = new Map(
            toonResult.data.map((toon) => [toon._id, toon]),
          )

          const commentRecommendToonList = commentRecommendToons.data.map(
            (toon) => {
              const toonDetails = toonMap.get(toon.toonId)

              return {
                ...toon, // 트렌딩 만화의 기존 데이터
                toonDetails, // 매핑된 만화의 상세 정보
              }
            },
          )
          setCommentRecommendToons(commentRecommendToonList)
        }

        // 미리보기 영역 작품
        const previewToonLog = await backendApis.loadRecommendingToons(
          'hot',
          'preview',
        )

        if (previewToonLog?.status === 200) {
          const toonMap = new Map(
            toonResult.data.map((toon) => [toon._id, toon]),
          )

          const previewToonList = previewToonLog.data.map((toon) => {
            const toonDetails = toonMap.get(toon.toonId)

            return {
              ...toon, // 트렌딩 만화의 기존 데이터
              toonDetails, // 매핑된 만화의 상세 정보
            }
          })
          setPreviewToons(previewToonList)
        }

        await backendApis.logToonAction('AltoonMainPage', 'enteredScreen', '')
      }

      // 최근 조회 데이터 가져오기
      const recentlyViewedToonsResult =
        await backendApis.loadRecentlyViewedToons()
      if (recentlyViewedToonsResult?.status === 200) {
        // data가 배열인지 확인하고 배열이 아니면 빈 배열로 처리
        const dataArray = Array.isArray(recentlyViewedToonsResult?.data)
          ? recentlyViewedToonsResult.data
          : []

        const sortedData = dataArray.sort((a, b) => {
          const aTime = new Date(a?.viewLog?.lastViewedAt).getTime()
          const bTime = new Date(b?.viewLog?.lastViewedAt).getTime()
          return bTime - aTime
        })
        setRecentlyViewedToons(sortedData)
      }
    }
    try {
      if (
        window.ReactNativeWebView &&
        typeof window.ReactNativeWebView.postMessage === 'function'
      ) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'allowiPhoneGesture',
            boolean: true,
          }),
        )
      }
    } catch (error) {
      console.warn('ReactNativeWebView postMessage 실행 실패:', error)
    }

    fetchToonData()

    return () => {
      isCancelled = true // 컴포넌트 언마운트 또는 useEffect 재실행 시 현재 진행 중인 작업 취소
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    AuthStore.setToken(token)
    setIsLoading(false)
  }, [])

  const HeaderComponent = () => {
    return (
      <section className='z-10 py-2 flex flex-row items-center justify-between overflow-hidden'>
        <button
          aria-label='goBack'
          type='button'
          className='mx-2 p-2 whitespace-nowrap'
          onClick={async () => {
            window.location.href = '#goBack'
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
        </button>
        <div className='flex font-bold'>올툰</div>
        <div className='w-[16vw]' />
      </section>
    )
  }

  const NewToonList = () => {
    return (
      <div className='py-[4vw] mx-[4vw]'>
        <ComponentTitle text='새로운 작품 등장!' />
        {newMainToons?.length > 0 && (
          <div className='grid grid-cols-3 gap-[2vw]'>
            {newMainToons?.slice(0, 6)?.map((toon, index) => (
              <MainToonComponent
                key={toon?._id}
                toon={toon}
                index={index}
                type='new'
                component='main_new'
              />
            ))}
          </div>
        )}
        {/* <div>나에게 맞는 신작 더보기</div> */}
      </div>
    )
  }

  /**
   * 최근 본 작품
   */
  const RecentlyViewedHorizontalList = () => {
    if (recentlyViewedToons?.length > 0) {
      return (
        <div className='mt-[4vw] pl-[4vw] py-[4vw]'>
          <button
            aria-label='mypage'
            type='button'
            className='w-full'
            onClick={() => {
              push(
                'AltoonMyPage',
                { component: 'main_recentlyViewed_seeAll', index: 0 },
                { animate: false },
              )
            }}
          >
            <ComponentTitle text='최근 본 작품' chevronRight />
          </button>

          <div className='flex items-center overflow-x-scroll whitespace-nowrap'>
            {recentlyViewedToons
              .slice(0, 6)
              .map((toon, index) =>
                toon?.viewLog?.toonId ? (
                  <MainToonComponent
                    key={toon?._id}
                    toon={toon}
                    index={index}
                    type='horizontal'
                    component='main_recentlyViewed'
                  />
                ) : (
                  <MainNovelComponent
                    key={toon?._id}
                    novel={toon}
                    index={index}
                    type='horizontal'
                  />
                ),
              )}
            <button
              type='button'
              className='mx-4 text-[3vw]'
              onClick={() => {
                push(
                  'AltoonMyPage',
                  {
                    component: 'main_recentlyViewed_seeAll',
                    index: 1,
                  },
                  { animate: false },
                )
              }}
            >
              <div className='w-[10vw] h-[10vw] min-w-[10vw] min-h-[10vw] rounded-full border border-[#F5F5F5] flexCol items-center mb-2'>
                <FiChevronRight className='w-[5vw] h-[5vw]' />
              </div>
              <div>전체보기</div>
            </button>
          </div>
        </div>
      )
    }
  }

  /**
   * 새로운 작품 등장!
   */
  const TrendingToonList = () => {
    if (trendingToons?.length > 0) {
      return (
        <div className='pl-[4vw] py-[4vw]'>
          <ComponentTitle text='오늘 인기 TOP 10' />

          <div className='flex items-center overflow-x-scroll whitespace-nowrap'>
            {trendingToons?.slice(0, 10).map((toon, index) => (
              <MainToonComponent
                key={toon?.toonId}
                toon={toon?.toonDetails}
                index={index}
                type='trending'
                component='main_trending'
              />
            ))}
          </div>
        </div>
      )
    }
  }

  /**
   * 독자 픽! TOP 3
   */
  const CommentRecommendComponent = () => {
    return (
      <div className='pl-[4vw] py-[4vw]'>
        <ComponentTitle text='독자 픽! TOP 3' />

        <div className='flexRow flex items-center overflow-x-scroll justify-start '>
          {commentRecommendToons?.slice(0, 3).map((toon, index) => (
            <MainToonComponent
              key={toon?._id}
              toon={toon}
              index={index}
              type='commentRecommend'
              component='main_commentRecommend'
            />
          ))}
        </div>
      </div>
    )
  }

  /**
   * 미리보기 한 컷!
   */
  const PreviewComponent = () => {
    return (
      <div className='pl-[4vw] py-[4vw]'>
        <ComponentTitle text='미리보기 한 컷!' />

        <div className='flexRow flex items-center overflow-x-scroll justify-start '>
          {previewToons?.slice(0, 3).map((toon, index) => (
            <MainToonComponent
              key={toon?._id}
              toon={toon}
              index={index}
              type='preview'
              component='main_preview'
            />
          ))}
        </div>
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <AppScreen>
      <div
        className='w-full overflow-y-scroll'
        style={{ height: '100vh' }}
        ref={scrollContainerRef}
      >
        <HeaderComponent />
        {bannerData?.length > 0 && (
          <BannerSwiperComponent bannerData={bannerData} bannerType='main' />
        )}
        {!bannerData && <BannerSwiperComponent empty bannerType='main' />}

        <QuicklinkComponent />

        <RecentlyViewedHorizontalList />

        <NewToonList />

        {shortBannerData?.length > 0 && (
          <BannerSwiperComponent
            bannerData={shortBannerData}
            bannerType='short'
          />
        )}

        <TrendingToonList />

        <CommentRecommendComponent />
        <PreviewComponent />

        <div className='h-[20vw]' />
        <BottomBarComponent />

        {isLoading && <LoadingIndicator />}
        {openTicketModal && (
          <ToonModal
            modalName={modalType}
            modalProp={modalProp}
            setOpenTicketModal={setOpenTicketModal}
          />
        )}
      </div>
    </AppScreen>
  )
})

export default AltoonMainPage
