import { createStore, get, set } from 'idb-keyval'

export interface BookmarkType {
  chapterId: string
  page: number
  name: string
  timestamp: string
}

const BookmarkDB = createStore('bookmark-database', 'bookmark-store')

const bookmarkKey = (chapterId: string) => `${chapterId}`

const bookmark = (params: BookmarkType) => {
  return params
}

export const storeBookmark = async (
  chapterId: string,
  page: number,
  name: string,
) => {
  const bookmarkList = await retrieveBookmarkList(chapterId)

  const newBookmark = bookmarkList
    .filter((bookmark) => bookmark.page !== page)
    .concat(
      bookmark({
        chapterId,
        page,
        name,
        timestamp: new Date().toISOString(),
      }),
    )

  return set(bookmarkKey(chapterId), newBookmark, BookmarkDB)
}

export const deleteBookmark = async (chapterId: string, page: number) => {
  const bookmarkList = await retrieveBookmarkList(chapterId)

  const newBookmark = bookmarkList.filter((bookmark) => bookmark.page !== page)

  return set(bookmarkKey(chapterId), newBookmark, BookmarkDB)
}

export const retrieveBookmarkList = async (chapterId: string) => {
  try {
    const bookmarkList = await get<BookmarkType[]>(
      bookmarkKey(chapterId),
      BookmarkDB,
    )
    return bookmarkList ?? []
  } catch {
    console.warn('Failed to retrieve bookmark list')
    return []
  }
}
