import styled from 'styled-components';
import { Counter } from './Counter';

interface OptionCounterProps {
  label: string;
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
}

export const OptionCounter = ({
  label,
  value,
  onChange,
  min,
  max,
}: OptionCounterProps) => {
  return (
    <Option>
      <OptionText>{label}</OptionText>
      <Counter min={min} max={max} value={value} onChange={onChange} />
    </Option>
  );
};

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
`;

const OptionText = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
`;
