import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import Button from '../../../pages/EggMoney/Button'
import THModalStore from '../../../stores/treasureHunt/THModalStore'

function renderDescription(text) {
  return text.split('\n').map((line, index) => (
    <span key={Math.random()}>
      {line}
      {index < text.split('\n').length - 1 && <br />}
    </span>
  ))
}

const EggTradeCancelOrderConfirmModal = ({ config }) => {
  const [, set] = useState()

  return (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw] mb-4'>
        {config?.title}
      </div>
      <div className='bg-[#F6F6FA] rounded-2xl p-0 w-1/2 mb-4'>
        <img alt='main' src={config.src} className='' />
      </div>
      <div className='flex flex-col w-full space-y-4 px-8 py-4 font-medium'>
        <div className='flex flex-row w-full justify-between items-center'>
          <div>주문 가격</div>
          <div>{commaNumber(config?.price)}원</div>
        </div>
        <div className='flex flex-row w-full justify-between items-center'>
          <div>주문 수량</div>
          <div>{commaNumber(config?.count)}개</div>
        </div>
        <div className='flex flex-row w-full justify-between items-center'>
          <div>총 환불 {config?.modeKor} 금액</div>
          <div className='font-bold'>
            {commaNumber(config?.price * config?.count)}원
          </div>
        </div>
      </div>
      <div className='flex flex-row w-full space-x-4'>
        <Button
          className='w-full py-3 mt-6 bg-gray-200'
          style={{ color: '#4E5867' }}
          clickable={config.clickable}
          text='닫기'
          onClick={() => {
            THModalStore?.close()
          }}
        />
        <Button
          className='w-full py-3 mt-6'
          clickable={config.clickable}
          style={{ ...config?.confirmButtonStyle }}
          text={config?.buttonText ?? '확인'}
          onClick={() => {
            config.onConfirm()
            THModalStore?.close()
          }}
        />
      </div>
    </div>
  )
}

export default observer(EggTradeCancelOrderConfirmModal)
