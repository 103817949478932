import moment from 'moment'
import React, { forwardRef, useEffect, useState } from 'react'

import backendApis from '../../utils/backendApis'

/**
 * 올툰 열람권 사용 및 획득 내역 영역
 */
const CoinHistorySection = forwardRef((props, ref) => {
  const selectedTab = props?.type

  const [isLoaded, setIsLoaded] = useState(false)
  const [historyData, setHistoryData] = useState([])
  const [dataLength, setDataLength] = useState(0)
  const [showLoadButton, setShowLoadButton] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(true)
      if (selectedTab === 'used') {
        // 사용 내역 가져오기
        const usedData = await backendApis.loadCoinHistoryData('used', 0, 16)
        setHistoryData(usedData?.data?.data)
        setDataLength(usedData?.data?.length)
      }
      if (selectedTab === 'gained') {
        // 획득 내역 가져오기
        const gainedData = await backendApis.loadCoinHistoryData(
          'gained',
          0,
          16,
        )
        setHistoryData(gainedData?.data?.data)
        setDataLength(gainedData?.data?.length)
      }

      setIsLoaded(true)
    }
    fetchData()
  }, [selectedTab])

  const loadData = async () => {
    if (selectedTab === 'used') {
      // 사용 내역 가져오기
      const usedData = await backendApis.loadCoinHistoryData(
        'used',
        historyData?.length,
        16,
      )
      setHistoryData([...historyData, ...usedData?.data?.data])
    }
    if (selectedTab === 'gained') {
      // 획득 내역 가져오기
      const gainedData = await backendApis.loadCoinHistoryData(
        'gained',
        historyData?.length,
        16,
      )
      setHistoryData([...historyData, ...gainedData?.data?.data])
    }
  }

  const TicketHistoryComponent = ({ item, type }) => {
    if (type === 'used') {
      const descriptionMap = {
        abuse: '과지급 코인 회수',
      }
      return (
        <div className='flexRow px-4 py-2 border-b-[0.3vw] border-[#F3F3F3] justify-between text-[4vw]'>
          <div className='items-start flexCol'>
            <div>
              {' '}
              {item?.toonTitle
                ? item?.toonTitle
                : descriptionMap[item?.description] || '열람권 교환'}
            </div>
            <div className='pt-1 text-[#999999] text-[3vw]'>
              {moment(item?.createdAt).format('YY.MM.DD HH:mm')}
            </div>
          </div>
          <div className='font-bold'> 코인 {item?.quantity}C</div>
        </div>
      )
    }
    if (type === 'gained') {
      const missionList = [
        { missionType: 'dailyCheckIn', missionName: '출석체크 미션' },
        { missionType: 'rewardedAd', missionName: '광고 보기 미션' },
        { missionType: 'attendanceDinner', missionName: '오후 출석 미션' },
        { missionType: 'attendanceMorning', missionName: '오전 출석 미션' },
        { missionType: 'browsing30', missionName: '구경하기 미션' },
        { missionType: 'setAppPush', missionName: '알림 받기 미션' },
        { missionType: 'first-purchase', missionName: '첫 구매 혜택' },
        {
          missionType: 'mission_Altoon_purchase',
          missionName: '구매하기 미션',
        },
        {
          missionType: 'mission_Altoon_purchase_cancelPurchase',
          missionName: '구매하기 미션 주문취소',
        },
        {
          missionType: 'mission_Altoon_purchase_withdrawCancel',
          missionName: '구매하기 미션 취소철회',
        },
        { missionType: 'first-purchase', missionName: '첫 구매 혜택' },
        {
          missionType: 'coin-exchange',
          missionName: '열람권 구매 (코인 사용)',
        },
        {
          missionType: 'first-purchase-calibration',
          missionName: '첫구매 오지급 회수',
        },
        {
          missionType: 'admin-coin-calibration',
          missionName: '열람권 이관',
        },
        {
          missionType: 'admin',
          missionName: '고객센터 지급',
        },
        {
          missionType: 'shareKakao',
          missionName: '링크 공유하기 미션',
        },
        {
          missionType: 'coin1500',
          missionName: '코인 구매',
        },
        {
          missionType: 'coin3000',
          missionName: '코인 구매',
        },
        {
          missionType: 'coin30000',
          missionName: '코인 구매',
        },
        {
          missionType: 'coin6000',
          missionName: '코인 구매',
        },
        {
          missionType: 'coin12000',
          missionName: '코인 구매',
        },
        {
          missionType: 'commentEvent',
          missionName: '댓글 이벤트',
        },
        {
          missionType: 'novelReadEvent',
          missionName: '웹소설 감상 이벤트',
        },
        {
          missionType: 'submitPoll',
          missionName: '투표 이벤트 참여',
        },
      ]
      const mission = missionList.find(
        (m) => m.missionType === item?.missionType,
      )
      const missionName = mission ? mission.missionName : '기타'

      return (
        <div className='flexRow px-4 py-2 border-b-[0.3vw] border-[#F3F3F3] justify-between text-[4vw]'>
          <div className='items-start flexCol'>
            <div className='whitespace-nowrap'> {missionName}</div>
            <div className='pt-1 text-[#999999] text-[3vw]'>
              {moment(item?.createdAt).format('YY.MM.DD HH:mm')}
            </div>
          </div>
          <div className='font-bold whitespace-nowrap'>
            {' '}
            코인 {item?.quantity}C
          </div>
        </div>
      )
    }
  }

  const LoadButton = ({ historyData, dataLength }) => {
    return (
      <button
        type='button'
        className='w-4/5 mt-4 py-2 text-[4vw] text-[#999999] rounded border border-[#F3F3F3] '
        onClick={() => {
          if (historyData?.length >= dataLength) {
            setShowLoadButton(false)
            return
          }
          loadData()
        }}
      >
        더보기
      </button>
    )
  }

  return (
    <div className='px-4 text-center'>
      {isLoaded &&
        historyData?.map((item) => {
          return (
            <TicketHistoryComponent
              key={item._id}
              item={item}
              type={selectedTab}
            />
          )
        })}
      {isLoaded && showLoadButton && (
        <LoadButton historyData={historyData} dataLength={dataLength} />
      )}
    </div>
  )
})
export default CoinHistorySection
