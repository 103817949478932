import { ClipLoader } from 'react-spinners'

const LoadingIndicator = ({ isLoading }) => {
  if (!isLoading) return <></>
  return (
    <div>
      <div className='absolute inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
      <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
        <ClipLoader
          color='#ff3e3e'
          loading={isLoading}
          size='20vw'
          aria-label='Loading Spinner'
          data-testid='loader'
        />
      </div>
    </div>
  )
}

export default LoadingIndicator
