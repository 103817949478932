import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { ClipLoader } from 'react-spinners'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

const ToonCommentAdminScreen = observer(() => {
  const itemsPerPage = 100
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0) // 전체 아이템 수 상태
  const [tempData, setTempData] = useState([])
  const [toonId, setToonId] = useState()
  const [chapterData, setChapterData] = useState()
  const [selectedCell, setSelectedCell] = useState(null)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleCellClick = async (id, value, type) => {
    const TYPE_LABELS = {
      toon: '작품',
      chapter: '회차',
      user: '유저',
    }

    setSelectedCell(selectedCell === `${type}-${id}` ? null : `${type}-${id}`)

    try {
      const textArea = document.createElement('textarea')
      textArea.value = value
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)

      toast.success(`${TYPE_LABELS[type]} ID가 복사되었습니다.`)
    } catch (err) {
      console.error('클립보드 복사 실패:', err)
      toast.error('클립보드 복사에 실패했습니다.')
    }
  }

  const fetchData = async (params = {}) => {
    AltoonCMSUserStore.setIsLoading(true)
    const result = await altoonCMSBackendApis.toonComment({
      skip: (currentPage - 1) * itemsPerPage,
      limit: itemsPerPage,
      toonId: params.toonId || undefined,
    })
    if (result?.status === 200) {
      setTempData(result?.data?.result)
      setTotalItems(result?.data?.totalCount)
    }
    AltoonCMSUserStore.setIsLoading(false)
  }

  const fetchChapterData = async () => {
    const result = await altoonCMSBackendApis.loadAllChapter()
    if (result?.status === 200) {
      setChapterData(result?.data)
    }
  }

  // filterData 함수를 검색 버튼 핸들러로 변경
  const handleSearch = async ({ toonId }) => {
    setCurrentPage(1)
    await fetchData({ toonId })
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '50%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading
            size={40}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    AltoonCMSUserStore.setIsLoading(true)
    if (!chapterData) {
      fetchChapterData()
    }
    fetchData({ toonId })
    AltoonCMSUserStore.setIsLoading(false)
  }, [currentPage]) // currentPage 또는 itemsPerPage가 변경될 때마다 요청

  const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    let pages = []

    // 이전 페이지 추가
    if (currentPage > 1) {
      pages.push(currentPage - 1)
    }

    // 현재 페이지 추가
    pages.push(currentPage)

    // 이후 3개 페이지 추가
    for (let i = 1; i <= 3; i++) {
      if (currentPage + i < totalPages) {
        pages.push(currentPage + i)
      }
    }

    // 첫 페이지 추가
    if (!pages.includes(1)) {
      // '...' 표시와 함께 마지막 페이지 번호 추가
      pages = [1, '...', ...pages]
    }
    // 마지막 페이지 추가

    if (!pages.includes(totalPages)) {
      // '...' 표시와 함께 마지막 페이지 번호 추가
      pages = [...pages, '...', totalPages]
    }

    return (
      <div>
        {pages.map((page) => (
          <button
            type='button'
            key={page}
            onClick={() => page !== '...' && onPageChange(page)}
            style={
              page === currentPage
                ? { margin: 5, padding: 5, backgroundColor: 'red' }
                : { margin: 5, padding: 5 }
            }
          >
            {page}
          </button>
        ))}
      </div>
    )
  }

  const RegisteredToonList = ({
    commentData,
    currentPage,
    handlePageChange,
  }) => {
    const TableCell = ({ children, className, style }) => (
      <td
        className={`${className} border-l whitespace-pre-wrap`}
        style={{ textWrap: 'nowrap', ...style }}
      >
        {children}
      </td>
    )

    const CopyButton = ({ onClick, mainText, subText, showSub }) => (
      <button type='button' className='w-full text-center' onClick={onClick}>
        {mainText}
        {showSub && (
          <div className='text-[0.3vw] mt-1 text-gray-500'>{subText}</div>
        )}
      </button>
    )

    return (
      <div>
        {commentData?.length > 0 && (
          <table>
            <thead className='whitespace-nowrap text-[1.4vh] border-b-2'>
              <tr>
                <th>id</th>
                <th>toonInfo</th>
                <th>chapterInfo</th>
                <th>userName</th>
                <th>comment</th>
                <th>createdAt</th>
                <th>liked</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 12, textAlign: 'center' }}>
              {commentData?.map((item) => (
                <tr key={item._id} className='border-b'>
                  <TableCell className='text-[0.2vw]' style={{ width: '5vw' }}>
                    {item._id}
                  </TableCell>

                  <TableCell className='text-[0.6vw]' style={{ width: '10vw' }}>
                    <CopyButton
                      onClick={() =>
                        handleCellClick(item._id, item.toonId, 'toon')
                      }
                      mainText={
                        chapterData?.find(
                          (chapter) =>
                            chapter._id.toString() ===
                            item.chapterId.toString(),
                        )?.toonTitle
                      }
                      subText={item.toonId}
                      showSub={selectedCell === `toon-${item._id}`}
                    />
                  </TableCell>

                  <TableCell className='text-[0.6vw]' style={{ width: '5vw' }}>
                    <CopyButton
                      onClick={() =>
                        handleCellClick(item._id, item.chapterId, 'chapter')
                      }
                      mainText={
                        chapterData?.find(
                          (chapter) => chapter._id === item.chapterId,
                        )?.chapterTitle
                      }
                      subText={item.chapterId}
                      showSub={selectedCell === `chapter-${item._id}`}
                    />
                  </TableCell>

                  <TableCell className='text-[0.6vw]' style={{ width: '5vw' }}>
                    <CopyButton
                      onClick={() =>
                        handleCellClick(item._id, item.userId, 'user')
                      }
                      mainText={
                        item?.userName?.length > 20
                          ? `${item.userName.slice(0, 20)}..`
                          : item.userName
                      }
                      subText={item.userId}
                      showSub={selectedCell === `user-${item._id}`}
                    />
                  </TableCell>

                  <TableCell
                    style={{
                      padding: 8,
                      fontSize: 12,
                      width: '40vw',
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-word',
                    }}
                  >
                    {item.comment}
                  </TableCell>

                  <TableCell className='text-[0.4vw]'>
                    {moment(item.createdAt).format('YY년 MM월 DD일')}
                    <br />
                    {moment(item.createdAt).format('HH시 mm분')}
                  </TableCell>

                  <TableCell>{item.liked}</TableCell>

                  <TableCell>
                    <button
                      type='button'
                      className='m-1 w-[5vw] whitespace-nowrap px-2 py-1 bg-red-50 text-red-600 rounded hover:bg-red-100'
                      onClick={async () => {
                        if (!window.confirm('삭제하시겠습니까?')) return

                        const result =
                          await altoonCMSBackendApis.deleteToonComment({
                            commentId: item._id,
                          })

                        if (result?.status === 200) {
                          alert('삭제되었습니다.')
                          fetchData()
                          return
                        }
                        alert(
                          '오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.',
                        )
                      }}
                    >
                      삭제하기
                    </button>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Pagination
          totalPages={Math.ceil(totalItems / itemsPerPage)}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    )
  }

  return (
    <div className='w-full my-10 min-w-7xl max-w-10xl select-text'>
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>올툰 댓글 모니터링</div>
        <div className='flex justify-center items-center pb-[3vh]'>
          <div className='text-[1.4vh] mr-2'>toonId별 댓글 조회</div>
          <input
            type='text'
            value={toonId}
            onChange={(e) => setToonId(e.target.value)}
            placeholder='툰 ID를 입력하세요'
          />
          <button
            type='button'
            onClick={() => handleSearch({ toonId })}
            className='ml-2 px-4 py-2 bg-blue-500 text-white rounded'
          >
            조회하기
          </button>
          {/* // 초기화 버튼 */}
          <button
            type='button'
            className='ml-2 px-4 py-2 border-2 border-gray-300 text-gray-500 rounded'
            onClick={() => {
              setToonId('')
              handleSearch({ toonId: '' })
            }}
          >
            초기화
          </button>
        </div>
        {tempData?.length > 0 && (
          <RegisteredToonList
            commentData={tempData}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        )}
        {!tempData?.length > 0 && <LoadingIndicator />}
      </div>
    </div>
  )
})

export default ToonCommentAdminScreen
