import { observer } from 'mobx-react-lite'
import React, { memo, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Header from '../../components/atoms/header'
import CategoryDealItemComponent from '../../components/deal/item/categoryDealItemComponent'
import HorizontalList from '../../components/deal/item/horizontalList'
import VerticalList from '../../components/deal/item/verticalList'
import GaugeTimer from '../../components/gaugeTimer'
import dealImages from '../../images/deal'
import AuthStore from '../../stores/AuthStore'
import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'
import CategoryName from '../../utils/categoryName'
import log from '../../utils/log'

const CategoryDealPage = observer(() => {
  const navigate = useNavigate()
  const token = AuthStore?.token
  const { state } = useLocation()
  const categoryIds = state?.categoryIds || []
  const mode = state?.mode || 'categoryExpansion'
  const [dealItemsInfo, setDealItemsInfo] = useState([])
  const [categoryId, setCategoryId] = useState(-1)
  const [isOpen, setIsOpen] = useState(false)
  const [dealEndedAt, setDealEndedAt] = useState()
  const [indices, setIndices] = useState([])
  const loadNum = dealItemsInfo?.length
  const dealType = 'categoryDeal'
  const skuDealType = 'treasureDeal'
  const version = mode
  const condition = {}
  const SCREEN_WIDTH = window.innerWidth
  const SCREEN_HEIGHT = window.innerHeight

  const initialFetch = async (version) => {
    const condition = {
      categoryId: categoryIds[0],
    }
    setCategoryId(categoryIds[0])
    await fetchDealItem(skuDealType, loadNum, version, [], condition, true)
  }

  const loadDealUserStatus = async (dealType) => {
    const result = await backendApis.getUserDealStatus()
    if (result?.status === 2000) {
      const { dealAuth } = result?.data
      const matchedDeal = dealAuth?.find((item) => item?.dealType === dealType)
      const { endedAt } = matchedDeal
      setIsOpen(new Date(endedAt) > new Date())
      setDealEndedAt(new Date(endedAt))
      UserStore.setUserDealStatus(result?.data)
    }
  }

  const fetchDealItem = async (
    dealType,
    loadNum,
    version,
    indices,
    condition,
    first = false,
  ) => {
    const itemIndices = indices.slice(0, 32)
    setIndices((arr) => arr.slice(32))
    const result = await backendApis.getDealItemsV3(
      dealType,
      loadNum,
      version,
      itemIndices,
      condition,
    )
    dealStateSetter(result, first)
  }

  const dealStateSetter = (result, first) => {
    if (result?.status === 200) {
      if (first) {
        setDealItemsInfo(result?.data)
      } else {
        setDealItemsInfo((arr) => [...arr, ...result?.data])
      }
      if (result?.itemIndices?.length > 0) {
        setIndices(result?.itemIndices)
      }
    }
  }

  const CategorySelectSection = () => {
    const renderItem = memo(({ index, style, data }) => {
      return (
        <div
          key={`${index}`}
          aria-hidden
          onClick={async () => {
            if (categoryId !== data[index]) {
              setCategoryId(data[index])
              const condition = {
                categoryId: data[index],
              }
              await fetchDealItem(skuDealType, 0, version, [], condition, true)
            }
          }}
          className={`flex flex-1 w-full text-lg p-3 ${
            categoryId === data[index]
              ? 'bg-blue-100 text-blue-500 font-bold'
              : 'bg-gray-100 text-gray-800'
          }  rounded-lg items-center justify-center mx-2`}
          style={{ ...style, width: style?.width - 8 }}
        >
          {CategoryName(data[index], true)}
        </div>
      )
    })
    return (
      <div className='flex flex-col items-center justify-center flex-1 w-full mt-2 mr-4'>
        <HorizontalList
          screenWidth={SCREEN_WIDTH}
          itemHeight={30}
          itemWidth={80}
          itemCount={categoryIds?.length || 0}
          itemData={categoryIds}
          renderItem={renderItem}
          isItemList={false}
        />
      </div>
    )
  }

  const renderItem = (index, data, isScrolling) => {
    return (
      <CategoryDealItemComponent
        key={`${index}`}
        itemInfo={data}
        version={version}
        dealEndedAt={dealEndedAt}
        isFrom='CategoryDealPage'
        isScrolling={isScrolling}
      />
    )
  }
  const Skeleton = () => {
    return (
      <>
        <CategoryDealItemComponent empty dealEndedAt={dealEndedAt} />
        <CategoryDealItemComponent empty dealEndedAt={dealEndedAt} />
        <CategoryDealItemComponent empty dealEndedAt={dealEndedAt} />
        <CategoryDealItemComponent empty dealEndedAt={dealEndedAt} />
        <CategoryDealItemComponent empty dealEndedAt={dealEndedAt} />
        <CategoryDealItemComponent empty dealEndedAt={dealEndedAt} />
      </>
    )
  }

  const loadMore = async () => {
    const itemIndices = indices.slice(0, 32)
    setIndices((arr) => arr.slice(32))
    await fetchDealItem(
      skuDealType,
      loadNum,
      version,
      itemIndices,
      condition,
      false,
    )
  }

  useEffect(() => {
    if (!isOpen) loadDealUserStatus(dealType)
    initialFetch(version)
  }, [token, version])

  return (
    <div className='bg-white'>
      <Header
        title='카테고리 특가'
        callbackFunction={() => {
          ModalStore.setCurrentModal({
            open: true,
            type: 'eventDealExit',
            event: '/deal/time-deal',
            closeCallback: () => log.dealLog('give_up', 'reward'),
            confirmCallback: () => log.dealLog('give_up', 'reward'),
          })
        }}
      />
      <div className='pt-12' />
      {isOpen ? (
        <>
          <div className='fixed z-50 flex flex-row flex-1 w-full h-10 bg-white shadow-md'>
            <GaugeTimer
              endDate={new Date(dealEndedAt)}
              totalHour={0}
              totalMin={10}
              onTimerEnd={() => {
                navigate('/deal/time-deal')
              }}
            />
          </div>
          <div className='px-2 mt-10 mb-3'>
            <img
              src={dealImages?.CategoryDealMainImage}
              alt='CategoryDealMainImage'
            />
          </div>
          {Array.isArray(categoryIds) && categoryIds?.length > 0 && (
            <CategorySelectSection />
          )}
          {dealItemsInfo?.length > 0 ? (
            <VerticalList
              itemData={dealItemsInfo}
              height={SCREEN_HEIGHT}
              renderItem={renderItem}
              loadMore={loadMore}
              isFrom='CategoryDealPage'
              isItemList
            />
          ) : (
            <Skeleton />
          )}
        </>
      ) : (
        <div className='flex items-center justify-center flex-col flex-1 w-screen h-screen bg-[#171717]'>
          <img
            src={dealImages?.Locked}
            alt='Locked'
            className='mb-10 w-28 h-28'
          />
          <div className='text-lg text-white'>특가 접근 권한이 없어요.</div>
          <div className='mb-5 text-lg text-white'>다음에 만나요!</div>
        </div>
      )}
    </div>
  )
})

export default CategoryDealPage
