import commaNumber from 'comma-number'
import { memo } from 'react'
import { MdArrowForwardIos as ArrowRight } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import ItemImpressionStore from '../../../stores/ItemImpressionStore'
import ImageAtom from '../../atoms/imageAtom'
import HorizontalList from '../item/horizontalList'

const optionsSetter = (dealType) => {
  if (dealType === 'lureDeal') {
    return {
      color: '#ec5729',
      title: '순삭특가',
      subTitle: '온라인 초인기 상품이에요',
      textBox: '딱 하루만 할인',
      router: '/deal/lure-deal',
    }
  }
  if (dealType === 'brandDeal') {
    return {
      color: '#129DEF',
      title: '브랜드딜',
      subTitle: '브랜드 상품도 온라인 최저가로!',
      textBox: '오늘의 브랜드',
      router: '/deal/brand-deal',
    }
  }
  if (dealType === 'priceSortDeal') {
    return {
      color: '#F33C6C',
      title: '99특가',
      subTitle: '전상품 만원 이하! 무료배송!',
      textBox: '만원으로 쇼핑하기',
      router: '/deal/price-sort-deal',
    }
  }
  return {
    color: '#ec5729',
    title: '기본',
    subTitle: '조기 품절이 예상되는 온라인 최저가 상품이에요',
    textBox: '딱 하루만 할인',
  }
}

const InsertDealSection = ({ data }) => {
  const navigate = useNavigate()
  const itemArray = data?.itemData || []
  const dealType = data?.dealType || ''
  const option = optionsSetter(dealType)
  const fullWidth = window.innerWidth
  const width = fullWidth - 64
  const ITEM_HEIGHT = 290
  const ITEM_WIDTH = 120

  const handleOnClick = () => {
    ItemImpressionStore.setPreviousIsFrom('InsertDealSection')
    navigate(option.router)
  }

  const renderItem = memo(({ index, style, data }) => {
    const teamPrice = parseInt(data?.[index]?.teamPurchasePrice, 10)
    const dealPrice = parseInt(data?.[index]?.minDealTeamPurchasePrice, 10)
    if (!data[index])
      return (
        <div key={`${index}`} style={{ ...style }}>
          <div className='object-cover mb-1 bg-gray-200 rounded-lg w-28 h-28' />
          <div className='h-6 mt-2 mr-2 bg-gray-200 rounded-lg' />
        </div>
      )
    return (
      <div
        key={`${index}`}
        style={{ ...style, paddingRight: 10 }}
        className='flex flex-col items-end leading-tight'
        onClick={handleOnClick}
        aria-hidden
      >
        <ImageAtom
          className='object-cover rounded-lg w-28 h-28'
          src={data?.[index]?.mainImageUris?.[0]}
          width={256}
          height={256}
        />
        <div className='mt-1 text-xs text-gray-500'>
          팀구매가 {commaNumber(teamPrice)}원
        </div>
        <div className='font-bold text-red-500'>
          특가 {commaNumber(dealPrice)}원
        </div>
      </div>
    )
  })

  return (
    <div
      style={{ height: ITEM_HEIGHT }}
      onClick={handleOnClick}
      aria-hidden
      className='flex flex-col items-center flex-1'
    >
      <div className='p-4 h-72'>
        <div
          className='w-full h-44 rounded-xl '
          style={{ backgroundColor: option?.color }}
        >
          <div className='absolute right-8'>
            <div
              className='p-2 text-xs font-bold bg-white rounded-b-lg shadow-md'
              style={{ color: option?.color }}
            >
              {option?.textBox}
            </div>
          </div>
          <div className='flex flex-col items-start justify-center flex-1 p-3'>
            <div className='mb-1 text-lg font-bold text-white'>
              {option?.title}
            </div>
            <div className='flex flex-row items-center px-2 text-sm text-white border-2 border-white rounded-full '>
              <span className='mr-1'>{option?.subTitle}</span>
              <ArrowRight />
            </div>
          </div>
          <div className='top-0 p-2 mx-2 bg-white shadow-lg h-44 drop-shadow absoulute rounded-xl'>
            <div className='rounded-xl'>
              <HorizontalList
                screenWidth={width}
                itemWidth={ITEM_WIDTH}
                itemHeight={ITEM_HEIGHT}
                itemData={itemArray}
                itemCount={5}
                renderItem={renderItem}
                isFrom={`InsertDealSection_${dealType}`}
                isItemList
              />
            </div>
          </div>
        </div>
      </div>
      <div className='w-[90%] border-b border-gray-300' />
    </div>
  )
}
export default InsertDealSection
