import React, { useEffect, useState } from 'react'

const FertilizerBottomSheet = ({
  nutriment = 70,
  giveWaterCount = 0,
  setOpenFertBottomSheet,
  fertilizerCount = 0,
  fertilizerSetCount = 0,
  setFertilizerCount,
  setFertilizerSetCount,
  setNutriment,
  setGiveWaterCount,
  setIntroModalType,
  setShowIntroModal,
}) => {
  const Card = ({ increaseNutriment, fertilizerSet, count = 0 }) => {
    return (
      <div
        style={{
          // backgroundColor: 'red',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          padding: 8,
        }}
      >
        <div
          style={{
            width: '20vw',
            height: '20vw',
            backgroundImage: fertilizerSet
              ? 'url(/images/alfarmTrial/fertilizerSet.png)'
              : 'url(/images/alfarmTrial/fertilizer.png)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: 'red',
              color: 'white',
              borderRadius: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'maplestory',
              padding: '0px 8px 0px 8px',
              position: 'absolute',
              marginTop: '12vw',
              marginLeft: '10vw',
            }}
          >{`x ${count}`}</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <div
            style={{
              fontFamily: 'maplestory',
              fontSize: '1rem',
              marginBottom: 8,
            }}
          >{`양분 + ${increaseNutriment}`}</div>
          <div
            style={{
              background: 'linear-gradient(180deg, #F2BA73 0%, #ED7732 100%)',
              width: '16vw',
              height: '10vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 100,
              fontFamily: 'maplestory',
              color: 'white',
            }}
            onClick={() => {
              if (fertilizerSet) {
                if (fertilizerSetCount < 1) return
                setFertilizerSetCount((prev) => prev - 1)
              } else {
                if (fertilizerCount < 1) return

                setFertilizerCount((prev) => prev - 1)
              }
              setGiveWaterCount((prev) => {
                if (prev + 2 === 11) {
                  setOpenFertBottomSheet(false)
                  return prev + 1
                }
                return prev + 2
              })

              setNutriment((prev) => prev + increaseNutriment)
            }}
          >
            사용
          </div>
        </div>
      </div>
    )
  }

  const [rewardPopUp, setRewardPopUp] = useState(false)

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 16,
      }}
    >
      <div
        style={{
          width: '8vw',
          height: '8vw',
          backgroundImage: 'url(/images/alfarmTrial/bottomCloseButton.png)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          position: 'absolute',
          right: 16,
          top: 16,
        }}
        onClick={() => {
          setOpenFertBottomSheet(false)
        }}
      />
      <div
        style={{
          background: 'linear-gradient(180deg, #F2BA73 0%, #ED7732 100%)',
          border: '6.07692px solid #FFEAB5',
          width: '25vw',
          height: '25vw',
          borderRadius: 100,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <div style={{ fontFamily: 'maplestory', color: 'white' }}>양분</div>
          <div
            style={{
              fontFamily: 'maplestory',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '2rem',
            }}
          >
            {nutriment}
          </div>
        </div>
      </div>
      <div
        id='fertCard'
        style={{
          width: '100%',
          height: '140px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          marginTop: 16,
        }}
      >
        <div
          style={{
            width: '45%',
            height: '100px',
            backgroundColor: '#FFF4D7',
            borderRadius: 16,
          }}
        >
          <Card
            increaseNutriment={35}
            fertilizerSet
            count={fertilizerSetCount}
          />
        </div>
        <div
          style={{
            width: '45%',
            height: '100px',
            backgroundColor: '#FFF4D7',
            borderRadius: 16,
            zIndex: giveWaterCount > 2 ? 101 : 0,
          }}
        >
          <Card
            increaseNutriment={10}
            fertilizerSet={false}
            count={fertilizerCount}
          />
          {giveWaterCount > 2 && giveWaterCount < 10 && (
            <div
              style={{
                position: 'absolute',
                width: '12vw',
                height: '14vw',
                top: '14vw',
                right: '18vw',
                zIndex: 101,
              }}
            >
              <img
                className='slide-bottom'
                style={{
                  width: '12vw',
                  height: '14vw',
                  position: 'absolute',

                  // transform: 'rotateX( 180deg )',
                }}
                src='../images/alfarmTrial/guideIndicator.png'
                alt=''
              />
              <div
                style={{
                  fontFamily: 'maplestory',
                  fontWeight: 'bold',
                  color: 'white',
                  width: '40vw',
                  textAlign: 'center',
                  position: 'absolute',
                  right: '-13vw',
                  top: '-14vw',
                  height: '20vw',
                }}
              >
                비료를 사용해서 양분을 높여보세요
              </div>
            </div>
          )}
        </div>
      </div>
      <div id='missionSection'>
        <img
          style={{
            width: '100%',
            height: '100%',
            // transform: 'rotateX( 180deg )',
          }}
          src='../images/alfarmTrial/missions.png'
          alt=''
        />
      </div>
      {giveWaterCount < 3 && (
        <div
          style={{
            width: '22vw',
            height: '10vw',
            borderRadius: 8,
            zIndex: 101,
            backgroundColor: '#FFC700',
            fontFamily: 'maplestory',
            fontWeight: 'bold',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            right: 12,
            top: '50%',
            transform: 'translate(0%, 30%)',
            position: 'absolute',
          }}
          onClick={() => {
            setRewardPopUp(true)
            setGiveWaterCount((prev) => prev + 1)
            setFertilizerCount((prev) => prev + 2)

            setTimeout(() => {
              setRewardPopUp(false)
            }, 1200)
          }}
        >
          <div>받기</div>
          {giveWaterCount < 3 && (
            <div
              style={{
                position: 'absolute',
                width: '12vw',
                height: '14vw',
                top: '-20vw',
              }}
            >
              <img
                className='slide-bottom'
                style={{
                  width: '12vw',
                  height: '14vw',
                  position: 'absolute',

                  // transform: 'rotateX( 180deg )',
                }}
                src='../images/alfarmTrial/guideIndicator.png'
                alt=''
              />
              <div
                style={{
                  fontFamily: 'maplestory',
                  fontWeight: 'bold',
                  color: 'white',
                  width: '40vw',
                  textAlign: 'center',
                  position: 'absolute',
                  right: '0vw',
                  top: '-12vw',
                  height: '20vw',
                  wordBreak: 'keep-all',
                }}
              >
                출석을 하면 비료를 얻을 수 있어요
              </div>
            </div>
          )}
        </div>
      )}

      {giveWaterCount < 10 && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            backgroundColor: 'rgba(0,0,0,0.7)',
            zIndex: 100,
          }}
        />
      )}

      {rewardPopUp && (
        <div
          style={{
            width: '200px',
            height: '200px',
            position: 'fixed',
            backgroundColor: 'rgba(0,0,0,0.7)',
            borderRadius: 16,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: 32,
            flexDirection: 'column',
            zIndex: 101,
          }}
        >
          <div
            style={{
              width: '30vw',
              height: '30vw',
              backgroundImage: 'url(/images/alfarmTrial/fertilizer.png)',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: 'red',
                fontFamily: 'maplestory',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.5rem',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 100,
                padding: '0px 4px 0px 4px',
                textAlign: 'center',
                width: '15vw',
                marginTop: '20vw',
              }}
            >
              x 2
            </div>
          </div>
          <div
            style={{
              // backgroundColor: 'white',
              fontFamily: 'maplestory',
              fontWeight: 'bold',
              fontSize: '1.5rem',
              borderRadius: 100,
              padding: '4px 8px 4px 8px',
              color: 'white',
            }}
          >
            획득!
          </div>
        </div>
      )}

      <div
        style={{
          width: '22vw',
          height: '10vw',
          borderRadius: 8,
          // backgroundColor: '#FFC700',
          fontFamily: 'maplestory',
          fontWeight: 'bold',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          right: 12,
          top: '66.2%',
          transform: 'translate(0%, 30%)',
          position: 'absolute',
        }}
        onClick={() => {
          setIntroModalType('url(/images/alfarmTrial/fertIntroModal.png)')
          setShowIntroModal(true)
        }}
      />
      <div
        style={{
          width: '22vw',
          height: '10vw',
          borderRadius: 8,
          // backgroundColor: '#FFC700',
          fontFamily: 'maplestory',
          fontWeight: 'bold',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          right: 12,
          top: '84%',
          transform: 'translate(0%, 30%)',
          position: 'absolute',
        }}
        onClick={() => {
          setIntroModalType('url(/images/alfarmTrial/fertIntroModal.png)')
          setShowIntroModal(true)
        }}
      />
    </div>
  )
}

export default FertilizerBottomSheet
