import { observer } from 'mobx-react-lite'
import React from 'react'

import { Close } from '../../images/dailyCheckIn'

const TeamCheckInExplainModal = observer(({ visible, close, ...params }) => {
  const Title = () => {
    return (
      <div className='pt-3 font-bold' style={{ fontSize: '7vw' }}>
        팀 출석체크 유의사항
      </div>
    )
  }

  const TextComponent = () => {
    return (
      <div style={{ fontSize: '4vw' }} className='text-[#525052] font-semibold'>
        <div className='h-4' />
        <div>
          * 팀 출석체크는 더 많은 포인트를 획득할 수 있는 기능으로, 팀이 완성된
          후부터 출석체크 횟수가 계산돼요
        </div>
        <div className='h-2' />
        <div>
          * 본 기능은 일부 고객님들에게 보이는 이벤트로, 미션을 진행중이더라도
          당사 사정에 의해 사전 고지없이 종료될 수 있어요
        </div>
        <div className='h-2' />
        <div>
          * 팀은 3명이 모인 날부터 7일동안 유지되며, 7일이 지나면 미션 현황에
          상관 없이 초기화돼요
        </div>
        <div className='h-2' />
        <div>
          * 올웨이즈가 처음인 팀원을 초대하면 신규 팀원 수에 따라 출석체크 시
          포인트를 각각 10, 50배로 받을 수 있어요 (단, 주간스탬프 보너스는
          중복적용되지 않아요)
        </div>
        <div className='h-2' />
        <div>
          * 팀이 완성되지 않았거나 신규 팀원이 팀에서 나가는 경우, 포인트 배수
          효과가 적용되지 않아요
        </div>
        <div className='h-2' />
        <div>
          * 서비스 운영상 오류를 악용하는 경우 운영정책에 따라 서비스 이용이
          제한될 수 있어요
        </div>
      </div>
    )
  }

  return (
    <div>
      {/* 딤드 배경 */}
      <button
        type='button'
        className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-20'
        onClick={close}
        aria-label='background'
      />
      {/* 모달 상자 */}
      <div
        className='fixed left-1/2 py-6 z-30 bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
        style={{ top: '48%', width: '90vw', height: '74vh' }}
      />

      <button
        type='button'
        className='fixed p-2 z-40 bg-gray-300 rounded-full'
        style={{ left: '83%', top: '12%' }}
        onClick={() => {
          close()
        }}
      >
        <Close className='w-6 h-6' />
      </button>

      {/* 모달 내용 + 받기 버튼 */}
      <div
        style={{ left: '5%', top: '12%', width: '90vw', height: '74vh' }}
        className='p-4 flex flex-col fixed items-center z-30'
      >
        <Title />
        <TextComponent />
      </div>
    </div>
  )
})

export default TeamCheckInExplainModal
