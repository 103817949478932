import React from 'react'
import useDebounce from 'src/components/useDebounce'

import {
  CheckInPigIcon,
  DailyCheckInAlarm,
  DailyCheckInAlfarm,
  DailyCheckInCart,
  DailyCheckInClock,
  DailyCheckInFriends,
  DailyCheckInQuiz,
  DailyCheckInShoppingBag,
  HappinessMudOnly,
  HappinessToyOnly,
  Heart,
  PigBoostMissionFeed,
  PigBoostMissionPurchase,
  PigBoostMissionStamp,
  PigFoodColored,
  PigHappinessMissionPet,
  PigHappinessMissionShop,
  PigHappinessMissionTimer,
  PigMissionAlarm,
  PigMissionCheckIn,
  PigMissionConsecutiveCheckIn,
  PigMissionPlay,
  PigMissionShop,
  PigMissionShorts,
  PigMissionTimer,
  PigMissionWakeUp,
  SuperPig,
} from '../images/dailyCheckIn'

const DailyCheckInScreenBottomSheetItem = ({
  leftImage = '',
  title,
  subTitle,
  reward,
  rewardType = 'food',
  subReward,
  subRewardType,
  content,
  timer,
  headText,
  footerText,
  impressTextInContent = '',
  moveTo,
  disabled = false,
  stampComponent,
  button = '',
}) => {
  const debounce = useDebounce()
  const ImageMapper = ({ leftImage = '' }) => {
    if (leftImage === 'DailyCheckInAlfarm') {
      return (
        <img
          src='/images/dailyCheckIn/Water.svg'
          alt='water'
          className='w-8 mr-2'
        />
      )
    }
    if (leftImage === 'DailyCheckInAlarm') {
      return (
        <img
          src='/images/dailyCheckIn/Bell.svg'
          alt='water'
          className='w-8 mr-2'
        />
      )
    }
    if (leftImage === 'DailyCheckInClock') {
      return (
        <img
          src='/images/dailyCheckIn/타이머.svg'
          alt='water'
          className='w-8 mr-2'
        />
      )
    }
    if (leftImage === 'DailyCheckInFriends') {
      return (
        <img
          src='/images/dailyCheckIn/Money.svg'
          alt='water'
          className='w-8 mr-2'
        />
      )
    }
    if (leftImage === 'DailyCheckInQuiz') {
      return (
        <img
          src='/images/dailyCheckIn/타이머.svg'
          alt='water'
          className='w-8 mr-2'
        />
      )
    }
    if (leftImage === 'DailyCheckInBrowsingMission') {
      return (
        <img
          src='/images/dailyCheckIn/돋보기.svg'
          alt='water'
          className='w-8 mr-2'
        />
      )
    }
    if (leftImage === 'DailyCheckInCashbackMission') {
      return (
        <img
          src='/images/dailyCheckIn/장바구니.svg'
          alt='water'
          className='w-8 mr-2'
        />
      )
    }
    if (leftImage === 'PigMissionTimer') {
      return <PigMissionTimer className='w-12' />
    }
    if (leftImage === 'PigMissionAlarm') {
      return <PigMissionAlarm className='w-12' />
    }
    if (leftImage === 'PigMissionShop') {
      return <PigMissionShop className='w-12' />
    }
    if (leftImage === 'PigMissionShorts') {
      return <PigMissionShorts className='w-12' />
    }
    if (leftImage === 'PigMissionCheckIn') {
      return <PigMissionCheckIn className='w-12' />
    }
    if (leftImage === 'PigMissionConsecutiveCheckIn') {
      return <PigMissionConsecutiveCheckIn className='w-12' />
    }
    if (leftImage === 'PigMissionPlay') {
      return <PigMissionPlay className='w-12' />
    }
    if (leftImage === 'PigMissionWakeUp') {
      return <PigMissionWakeUp className='w-12' />
    }
    if (leftImage === 'PigHappinessMissionPet') {
      return <PigHappinessMissionPet className='w-12' />
    }
    if (leftImage === 'PigHappinessMissionShop') {
      return <PigHappinessMissionShop className='w-12' />
    }
    if (leftImage === 'PigHappinessMissionTimer') {
      return <PigHappinessMissionTimer className='w-12' />
    }
    if (leftImage === 'PigBoostMissionStamp') {
      return <PigBoostMissionStamp className='w-12' />
    }
    if (leftImage === 'PigBoostMissionFeed') {
      return <PigBoostMissionFeed className='w-12' />
    }
    if (leftImage === 'PigBoostMissionPurchase') {
      return <PigBoostMissionPurchase className='w-12' />
    }
    if (leftImage === 'CheckInPigIcon') {
      return (
        <img
          src='/images/dailyCheckIn/돈돈이.svg'
          alt='water'
          className='w-8 mr-2'
        />
      )
    }
    if (leftImage === 'DailyCheckInRewardedAdMission') {
      return (
        <img
          src='/images/dailyCheckIn/티비.svg'
          alt='water'
          className='w-8 mr-2'
        />
      )
    }
  }

  return (
    <div
      className='flex-row flex flex-1 items-center py-4 border-b-[0.5vw] border-[#f6f6fa]'
      style={{ fontFamily: 'pretendard Variable' }}
    >
      <div className='flex items-center flex-1'>
        <ImageMapper leftImage={leftImage} />
        <div className='flex flex-col items-start justify-center flex-1 pl-2'>
          {/* {headText && <div className='text-sm text-red-500'>{headText}</div>} */}
          <div className='flex flex-row items-center justify-center'>
            <div className='flex flex-row items-center text-base font-semibold '>
              {title}
              <div className='flex pl-1 text-xs text-[#9c9da4]'>{subTitle}</div>
            </div>
            {reward && (
              <div
                className='flex flex-row items-center text-xs rounded-lg'
                style={{ color: '#DD524B' }}
              >
                {rewardType === 'food' && (
                  <PigFoodColored className='w-5 mx-1' />
                )}
                {rewardType === 'happiness' && (
                  <Heart className='w-4 mx-1 mt-1' />
                )}
                {rewardType === 'toy' && (
                  <HappinessToyOnly className='w-8 mr-2' />
                )}
                {rewardType === 'mud' && (
                  <HappinessMudOnly className='w-8 mr-2' />
                )}
                {rewardType === 'boostDondon' && (
                  <SuperPig className='w-6 mr-1' />
                )}

                {reward}
              </div>
            )}
            {subReward && (
              <div
                className='flex flex-row items-center justify-center ml-2 text-sm'
                style={{ color: '#9c9da4' }}
              >
                <div className='mt-1 mr-1'>{subRewardType}</div>
                {subReward}
              </div>
            )}
          </div>
          <div className='text-sm' style={{ color: '#9c9da4' }}>
            {impressTextInContent}
            {content}
          </div>
          {footerText && (
            <div className='text-sm' style={{ color: '#9c9da4' }}>
              {footerText}
            </div>
          )}
          {stampComponent && (
            <div className='text-sm' style={{ color: '#9c9da4' }}>
              {stampComponent}
            </div>
          )}
        </div>
      </div>
      <div className='flex flex-col items-center justify-center'>
        {timer && <div className='text-xs'>{timer} 후 가능</div>}

        {button || (
          <button
            type='button'
            className={
              disabled
                ? 'w-20 h-10 bg-[#EEEFF3] text-[#9C9DA4] px-2 rounded-lg justify-center font-bold'
                : 'w-20 h-10 bg-[#FFEDEE] text-[#F8323E] px-2 rounded-lg justify-center font-bold'
            }
            onClick={() => {
              debounce(moveTo, 500)
            }}
            disabled={disabled}
          >
            {disabled ? '미션완료' : '보상받기'}
            {button}
          </button>
        )}
      </div>
    </div>
  )
}

export default DailyCheckInScreenBottomSheetItem
