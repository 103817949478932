import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { css } from 'styled-components'

import { Flex } from '../Flex'
import { BookmarkType } from './useBookmarkStore'

interface BookmarkProps {
  bookmark: BookmarkType
  onClick: () => void
  onDelete: () => void
}

export const Bookmark = observer((props: BookmarkProps) => {
  return (
    <Flex.Column
      css={css`
        padding: 16px 0px;
        gap: 6px;
      `}
      onClick={props.onClick}
    >
      <Flex.Row
        css={css`
          justify-content: space-between;
        `}
      >
        <Flex.Column
          css={css`
            gap: 6px;
          `}
        >
          <div css={bookmarkNameCSS}>{props.bookmark.name}</div>
          <Flex.Row css={bookmarkMetaCSS}>
            <div>{props.bookmark.page + 1} 페이지</div>
            <div>
              {moment(props.bookmark.timestamp).format('YYYY.MM.DD HH:mm:ss')}
            </div>
          </Flex.Row>
        </Flex.Column>

        <div
          onClick={(e) => {
            e.stopPropagation()
            props.onDelete()
          }}
        >
          <img src='/icon_cancel.svg' alt='icon' width={16} height={16} />
        </div>
      </Flex.Row>
    </Flex.Column>
  )
})

const bookmarkNameCSS = css`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.20000000298023224px;
  text-align: left;

  color: #30333d;
`

const bookmarkMetaCSS = css`
  /* //styleName: Detail 2/Medium;
  font-family: Pretendard Variable; */
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  align-items: center;
  gap: 6px;

  color: #bcbdc3;
`
