const Layout = ({ children, style }) => {
  return (
    <section
      style={{
        position: 'relative',
        zIndex: 1,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        transition: 'opacity 0.3s ease',
        ...style,
      }}
    >
      {children}
    </section>
  )
}

export default Layout
