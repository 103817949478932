import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import EggMoneyAction from '../../../actions/EggMoneyAction'
import Button from '../../../pages/EggMoney/Button'
import EggMoneyStore from '../../../stores/EggMoney/EggMoneyStore'
import ToastStore from '../../../stores/ToastStore'
import THModalStore from '../../../stores/treasureHunt/THModalStore'

function renderDescription(text) {
  return text?.split?.('\n')?.map((line, index) => (
    <span key={Math.random()}>
      {line}
      {index < text?.split('\n').length - 1 && <br />}
    </span>
  ))
}

const EggMoneyBasicModal = ({ config }) => {
  const navigate = useNavigate()

  return (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw]'>
        {config?.title}
      </div>
      <div className='relative flex items-center justify-center w-full my-8'>
        {config?.rarity && (
          <div
            className={`absolute text-sm  px-2 py-1 rounded-md rank-${config?.rarity} -top-4 left-1/2 -translate-x-1/2 z-[1]`}
          >
            <div className={`font-semibold `}>
              {config?.rarity?.toUpperCase()}
              등급
            </div>
          </div>
        )}
        {config?.earnEffect && (
          <img
            className='absolute w-[50vw] h-[50vw] z-[0]  rotate-infinite'
            alt='earnEffect'
            src='../images/treasureHunt/earnEffect.png'
          />
        )}
        {config.src && (
          <img
            alt='main'
            src={config.src}
            className={`relative z-[10] w-1/2 h-auto ${config?.imgClassName}`}
          />
        )}
        {config?.name && (
          <div className='absolute z-[1] -bottom-4 left-1/2 -translate-x-1/2 font-bold'>
            {config?.name}
          </div>
        )}
      </div>
      {config?.description && (
        <div className='font-bold -mt-4 text-center'>
          {renderDescription(config?.description)}
        </div>
      )}

      <Button
        className='w-full py-3 mt-6'
        clickable={config.clickable}
        text={config?.buttonText ?? '확인'}
        onClick={() => {
          if (config?.onConfirm) {
            config.onConfirm?.()
          }
          if (config?.navigateTo) {
            window.localStorage.setItem('eggName', config?.name)
            navigate(`/eggMoney/${config?.navigateTo}`)
          }
          if (config?.backendOnClickType === 'bookmark') {
            navigate('/eggMoney/eggBox')

            ToastStore.toastOn({
              message: '에그를 클릭하고 찜해보세요',
              type: 'emoji',
              emoji: '🥚',
              duration: 1000,
            })
          } else if (config?.backendOnClickType === 'rouletteInvite') {
            THModalStore.setType({
              type: 'eggMoneyRouletteModal',
              config: {
                title: 'SS랭크 뽑기 룰렛',
                src: '../images/eggMoney/roulette/roulette_1.png',
                description:
                  '올웨이즈를 안해본 친구를 초대하고\nSS랭크 뽑기 룰렛을 돌려보세요!',
                onClick: () => {
                  EggMoneyAction.userLog({
                    type: 'click',
                    tag: 'EggMoney_RouletteInvite',
                  })
                  EggMoneyAction.linkShare({
                    shareType: 'EggMoney_RouletteInvite',
                    title: '계란만 뽑아도 부자되는 신개념 앱테크',
                    description: '에그를 모아서 현금을 인출해보세요!',
                    imageUrl:
                      'https://assets.ilevit.com/c544de1c-9c4e-4f0d-b6e2-1fee5a3b2df5.png',
                    payload: {},
                  })
                },
                buttonText: '초대하고 티켓 받기',
                forceOpen: true,
              },
            })
          }
          THModalStore?.close()
        }}
      />
    </div>
  )
}

export default observer(EggMoneyBasicModal)
