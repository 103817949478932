import React, { useEffect, useRef, useState } from 'react'
import useDebounce from 'src/components/useDebounce'

const styleBook = {
  groupPurchase: {
    mainColor: 'bg-[#FF4D00]',
    pointColor: '#FFD600',
    lightColor: '#FFD600',
    lightestColor: '#FFD600',
    clickedBackgroundColor: 'bg-[#FF8F60]',
    darkestColor: '#FFD600',
    border: 'border-[#FF4D00] border-[2px]',
    textColor: 'text-white',
  },
}

const Button = ({ props, fixedClassName }) => {
  const { text, className, style, onClick = () => {} } = props
  const [pressed, setPressed] = useState(false)

  let mainColor = styleBook.groupPurchase.mainColor
  let clickedBackgroundColor = styleBook.groupPurchase.clickedBackgroundColor
  let border = styleBook.groupPurchase.border
  let textColor = styleBook.groupPurchase.textColor

  if (className?.includes('inverted')) {
    mainColor = 'bg-white'
    textColor = 'text-[#FF4D00]'
  } else if (className?.includes('disabled')) {
    mainColor = 'bg-gray-300'
    textColor = 'text-white'
    clickedBackgroundColor = 'bg-gray-300'
    border = 'border-gray-300 border-[2px]'
  }

  const debounce = useDebounce()

  return (
    <button
      className={
        fixedClassName +
        ' ' +
        border +
        ' ' +
        textColor +
        ' ' +
        className +
        ` ${pressed ? clickedBackgroundColor : mainColor}`
      }
      style={style}
      onClick={() => debounce(onClick, 200)}
      onPointerDown={() => {
        setPressed(true)
      }}
      onPointerUp={() => {
        setPressed(false)
      }}
      onPointerCancel={() => {
        setPressed(false)
      }}
    >
      {text}
    </button>
  )
}

const CTA = (props) => {
  return (
    <div className='shadow-[0_30px_8px_32px_rgba(0,0,0,0.15)] shadow-top w-full p-2 fixed bottom-0 bg-white'>
      <Button
        props={props}
        fixedClassName={`rounded-xl w-full flex h-[16vw] justify-center items-center font-bold py-2 px-4 text-[6vw] `}
      />
    </div>
  )
}

const Normal = (props) => {
  return (
    <Button
      props={props}
      fixedClassName={`rounded-lg flex justify-center items-center font-bold py-1 px-2`}
    />
  )
}

export default { CTA, Normal }
