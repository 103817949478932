import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { Oval } from 'react-loader-spinner'
import { useSearchParam } from 'react-use'

import Button from '../components/groupPurchase/Button'
import GroupPurchaseItemList from '../components/groupPurchase/GroupPurchaseItemList'
import Header from '../components/groupPurchase/Header'
import Layout from '../components/groupPurchase/Layout'
import Modal from '../components/groupPurchase/Modal'
import Reward from '../components/groupPurchase/Reward'
import RewardModal from '../components/groupPurchase/RewardModal'
import TopMenu from '../components/groupPurchase/TopMenu'
import WithdrawReward from '../components/groupPurchase/WithdrawReward'
import AuthStore from '../stores/AuthStore'
import GroupPurchaseStore from '../stores/GroupPurchaseStore'
import ToastStore from '../stores/ToastStore'
import UserStore from '../stores/UserStore'
import { sendMessage } from '../utils/utils'

let visibilityChange
if (typeof document.hidden !== 'undefined') {
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  visibilityChange = 'webkitvisibilitychange'
}

const GroupPurchase = observer(() => {
  const token = useSearchParam('token')
  const [contentTitle, setContentTitle] = useState('내가 만든 모임구매')

  AuthStore.setToken(token)

  useEffect(() => {
    if (moment().isAfter(moment('2023-07-01'))) {
      alert('서비스가 종료되었습니다.')
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'goBack',
        }),
      )
    }
  }, [])

  useEffect(() => {
    const init = async () => {
      await GroupPurchaseStore?.initializeGroupPurchase()
    }
    if (AuthStore?.token || token) {
      init()
      GroupPurchaseStore.openCloseNotice()

      const tutorialCheckAt = JSON.parse(
        window.localStorage.getItem('tutorialCheckAt'),
      )
      if (!tutorialCheckAt) {
        window.localStorage.setItem(
          'tutorialCheckAt',
          JSON.stringify(new Date().getTime()),
        )
        GroupPurchaseStore?.openTutorialModal()
      }
    }

    document.addEventListener(
      visibilityChange,
      () => {
        if (document.visibilityState === 'visible') {
          GroupPurchaseStore?.setIsLoading(true)
          setTimeout(() => {
            init()
          }, 500)
        }
      },
      false,
    )
  }, [AuthStore?.token])

  useEffect(() => {
    if (GroupPurchaseStore?.isFromGroupPurchaseCreate) {
      const itemInfo = GroupPurchaseStore?.displayData?.[0]?.permanentMarks
      const discountRate = Math.round(
        (1 - itemInfo?.teamPurchasePrice / itemInfo?.individualPurchasePrice) *
          100,
      )

      GroupPurchaseStore?.openCreatedGroupModal({ itemInfo, discountRate })
    }
  }, [
    GroupPurchaseStore?.isFromGroupPurchaseCreate,
    GroupPurchaseStore?.displayData,
  ])

  if (GroupPurchaseStore?.isLoading) {
    return (
      <div className='w-screen h-screen fixed flex items-center justify-center top-0 bottom-0 bg-[#EEEEEE]'>
        <Oval color='#EA3F49' secondaryColor='#212121' height='40' width='40' />
      </div>
    )
  }

  return (
    <Layout
      style={{
        backgroundColor: '#F7F3E8',
        border: 0,
        width: '100%',
        height: '100vh',
        margin: 0,
        padding: 0,
        justifyContent: 'flex-start',
      }}
    >
      <div className='flex'>
        <Header
          title='모임구매'
          infoButton
          infoNavigate={() => {
            GroupPurchaseStore?.openTutorialModal()
          }}
          navigateBack={() => {
            window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'goBack',
              }),
            )
          }}
        />
      </div>

      <div className='relative flex flex-1 flex-col w-screen pb-[15vw] justify-start overflow-y-scroll'>
        <Reward />
        <div className='bg-white w-full flex-1 mt-4 rounded-tl-3xl rounded-tr-3xl '>
          <TopMenu setContentTitle={setContentTitle} />
          {GroupPurchaseStore?.rewardAvailableGroupPurchaseList?.length > 0 && (
            <>
              <div className='pl-4 pt-6 font-bold text-[6vw] '>
                성공한 모임구매
              </div>
              <GroupPurchaseItemList
                displayData={
                  GroupPurchaseStore.rewardAvailableGroupPurchaseList
                }
                isRewardAvailableList
              />
            </>
          )}
          <div className='pl-4 pt-6 font-bold text-[6vw]'>{contentTitle}</div>
          <GroupPurchaseItemList displayData={GroupPurchaseStore.displayData} />
        </div>
      </div>
      <Button.CTA
        text='모임구매 만들기'
        onClick={() => {
          GroupPurchaseStore.createGroupPurchase()
        }}
      />

      {GroupPurchaseStore?.modalType === 'oneButton' && (
        <Modal.OneButton props={GroupPurchaseStore?.modalConfig} />
      )}
      {GroupPurchaseStore?.modalType === 'twoButtons' && (
        <Modal.TwoButtons props={GroupPurchaseStore?.modalConfig} />
      )}
      {GroupPurchaseStore?.modalType === 'carousel' && (
        <Modal.Carousel props={GroupPurchaseStore?.modalConfig} />
      )}
      {GroupPurchaseStore?.modalType === 'groupPurchaseDetail' && (
        <Modal.GroupPurchaseDetail props={GroupPurchaseStore?.modalConfig} />
      )}
      {GroupPurchaseStore?.modalType === 'infoList' && (
        <Modal.InfoList props={GroupPurchaseStore?.modalConfig} />
      )}
      {GroupPurchaseStore?.openWithdrawReward && <WithdrawReward />}

      {/* <Toaster
        position='top-center'
        toastOptions={{
          style: {
            marginTop: '40vw',
            fontFamily: 'maplestory',
            color: '#965F42',
          },
        }}
      /> */}
    </Layout>
  )
})

export default GroupPurchase
