import { action, makeObservable, observable } from 'mobx'

class AuthStore {
  token = null
  codePushVersion = '1.0.0'
  publicToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQzZGY2MjVhN2EzZTMyNjA5NmFlY2U2In0sImlhdCI6MTY5NTUxMzY4MX0.zPdEkVyJL8_sd9iJ_oWe-MCu2haUet-fmkmPeU_ejsA'

  constructor() {
    makeObservable(this, {
      token: observable,
      codePushVersion: observable,
      setToken: action,
      setCodePushVersion: action,
    })
  }

  setToken(token) {
    this.token = token
  }

  setCodePushVersion(codePushVersion) {
    this.codePushVersion = codePushVersion
  }
}

export default new AuthStore()
