export const mergeRefs = <T>(
  refs: Array<React.MutableRefObject<T> | ((instance: T) => void)>
) => {
  return (value: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        ref.current = value;
      }
    });
  };
};
