import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { Close, RedPoint, YellowMoney } from 'src/images/dailyCheckIn'
import UserStore from 'src/stores/UserStore'
import backendApis from 'src/utils/backendApis'

let buttonPressed = false

const MoneyTreeExchangeModal = observer(
  ({ visible, close, reward, cost, ...params }) => {
    const Title = () => {
      return <div className='text-3xl pt-3'>환전하기</div>
    }

    const TextComponent = () => {
      return (
        <>
          <div className='text-base pt-3'>돈나무 돈 {cost}원을 사용해</div>
          <div className='text-base pt-3'>
            올포인트 {reward}P로 바꾸시겠어요?
          </div>
        </>
      )
    }

    const ButtonComponent = () => {
      return (
        <div className='p-4 flex flex-row w-full'>
          <button
            type='button'
            className='py-3 px-4 bg-gray-200 text-gray-500 w-full transition ease-in duration-200 text-center text-base rounded-full'
            onClick={close}
          >
            취소
          </button>
          <div className='w-4' />
          <button
            type='button'
            onClick={async () => {
              if (buttonPressed) return
              buttonPressed = true

              close()

              buttonPressed = false
            }}
            className='py-3 px-4 bg-[#19AB97] text-white w-full transition ease-in duration-200 text-center text-base rounded-full'
          >
            교환하기
          </button>
        </div>
      )
    }

    return (
      <div>
        {/* 딤드 배경 */}
        <button
          className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-10'
          onClick={close}
        />
        {/* 모달 상자 */}
        <div
          className='fixed left-1/2 py-6 z-40 bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
          style={{ top: '52%', width: '90%', height: '40%', zIndex: 11 }}
        />

        <button
          className='fixed z-30'
          style={{ left: '88%', top: '28%' }}
          onClick={close}
        >
          <Close className='w-6 h-6' />
        </button>

        {/* 모달 내용 + 받기 버튼 */}
        <div
          style={{ top: '32%', left: '5%', width: '90%', height: '40%' }}
          className='p-4 flex flex-col justify-between fixed items-center z-30'
        >
          <div className='flex flex-col w-full justify-between items-center'>
            <Title />
            <div className='flex flex-row items-center py-4'>
              <YellowMoney className='w-12 h-12 mr-2' />
              &gt;
              <RedPoint className='w-12 h-12 ml-2' />
            </div>
            <TextComponent />
          </div>
          <ButtonComponent />
        </div>
      </div>
    )
  },
)

export default MoneyTreeExchangeModal
