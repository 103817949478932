import { useInfiniteQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import backendApis from '../../../../utils/backendApis'
import { CommentSortType } from '../../../../utils/interface'
import { novelChapterCommentListQuery } from '../../remotes/comment'
import { BottomSheet } from '../Bottomsheet'
import { useConfirmModal } from '../Confirm'
import { Divider } from '../Divider'
import { Flex } from '../Flex'
import { useToast } from '../Toast'
import { TouchArea } from '../TouchArea/TouchArea'
import { Comment } from './Comment'
import { CommentSkeleton } from './CommentSkeleton'
import { CommentTabs } from './CommentTabs'

const COUNT_PER_PAGE = 12

export const CommentPage = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const { chapterId, novelId } = location.state || {}
  const [sortType, setSortType] = useState<CommentSortType>(CommentSortType.new)
  const { data, fetchNextPage, refetch, hasNextPage } = useInfiniteQuery({
    queryKey: ['novelChapterCommentList', { chapterId, novelId, sortType }],
    queryFn: async ({ pageParam = 0 }) => {
      const res = await backendApis.loadNovelChapterCommentList({
        chapterId,
        novelId,
        sortType,
        count: COUNT_PER_PAGE,
        skip: pageParam,
      })
      return res
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.data.length < COUNT_PER_PAGE) {
        return undefined
      }
      return allPages.length * COUNT_PER_PAGE
    },
    initialPageParam: 0,
  })
  const { open } = useConfirmModal()
  const { openToast } = useToast()

  const commentList = data?.pages.flatMap((page) => page.data) || []

  const likeComment = async (commentId: string, setLiked: boolean) => {
    await backendApis.likeNovelChapterComment({
      chapterId,
      novelId,
      commentId,
      setLiked,
    })

    refetch()
  }

  const reportComment = async (commentId: string) => {
    const isConfirmed = await open({
      title: '댓글을 신고할까요?',
      description: '신고된 댓글은 검수 후 삭제됩니다.',
      confirmText: '신고하기',
      cancelText: '취소',
    })

    if (!isConfirmed) {
      return
    }

    await backendApis.reportNovelChapterComment({
      chapterId,
      novelId,
      commentId,
      type: 'report',
    })

    openToast({
      text: '신고를 완료했어요.',
    })

    refetch()
  }

  const blockComment = async (commentId: string) => {
    const isConfirmed = await open({
      title: '댓글을 차단할까요?',
      description: '차단한 댓글은 더 이상 보이지 않아요.',
      confirmText: '차단하기',
      cancelText: '취소',
    })

    if (!isConfirmed) {
      return
    }

    await backendApis.reportNovelChapterComment({
      chapterId,
      novelId,
      commentId,
      type: 'block',
    })

    openToast({
      text: '차단을 완료했어요.',
    })

    refetch()
  }

  const deleteComment = async (commentId: string) => {
    const isConfirmed = await open({
      title: '댓글을 삭제할까요?',
      description: '삭제한 댓글은 더 이상 보이지 않아요.',
      confirmText: '삭제하기',
      cancelText: '취소',
    })

    if (!isConfirmed) {
      return
    }

    await backendApis.deleteNovelChapterComment({
      chapterId,
      novelId,
      commentId,
    })

    openToast({
      text: '댓글을 삭제했어요.',
    })

    refetch()
  }

  return (
    <Wrapper>
      {/* TODO: 댓글 없는 경우 skeleton */}
      <Flex.Row
        css={css`
          justify-content: center;
        `}
      >
        <TouchArea
          width={44}
          height={44}
          onClick={() => navigate(-1)}
          css={css`
            position: absolute;
            top: 0px;
            left: 0px;
          `}
        >
          <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
        </TouchArea>
        <div css={textCountCSS}>댓글 ({data?.pages[0]?.totalLength ?? 0})</div>
      </Flex.Row>

      <Flex.Row>
        <CommentTabs activeTab={sortType} onClickTab={setSortType} />
      </Flex.Row>

      <ScrollView>
        {commentList?.length > 0 ? (
          commentList.map((item) => (
            <>
              <Comment
                isBest={sortType === CommentSortType.bestAll}
                isLiked={item.isLiked}
                userName={item.userName}
                isMyComment={item.isMine}
                comment={item.comment}
                likeCount={item.liked}
                onBlock={() => blockComment(item._id)}
                onReport={() => reportComment(item._id)}
                onDelete={() => deleteComment(item._id)}
                onLike={(setLiked) => likeComment(item._id, setLiked)}
                createdAt={item.createdAt}
              />
              <Divider />
            </>
          ))
        ) : (
          <CommentSkeleton />
        )}
        <Divider />

        {/* TODO 네비게이션 */}
        {hasNextPage && (
          <LoadMore onClick={() => fetchNextPage()}>댓글 더보기</LoadMore>
        )}
      </ScrollView>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  position: relative;
  height: calc(100% - 28px);
  display: flex;
  flex-direction: column;
`

const ScrollView = styled.div`
  padding: 16px 16px 12px;
  overflow: scroll;
  flex: 1;
`

const textCountCSS = css`
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;

  padding: 10px 0px 12px;
`

const LoadMore = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
  color: #4f4f4f;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin: 16px;
  border: 1px solid #eeeff3;
`
