import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import THUserStore from '../../stores/treasureHunt/THUserStore'

const THClosetItem = observer(({ type, index, captured }) => {
  const [isWearing, setIsWearing] = useState(false)

  useEffect(() => {
    if (
      THUserStore?.characterInfo?.closetId === index &&
      THUserStore?.characterInfo?.closetType === type
    ) {
      setIsWearing(true)
    } else {
      setIsWearing(false)
    }
  }, [
    THUserStore?.characterInfo?.closetId,
    THUserStore?.characterInfo?.closetType,
  ])

  return (
    <div
      className='inline-block w-[20%]  px-[2vw] py-2 relative'
      onClick={() => {
        if (captured) {
          TreasureHuntAction.selectCloset({ closetType: type, closetId: index })
        }
      }}
    >
      <img
        className={`${captured ? 'opacity-100' : 'opacity-[15%]'} ${
          isWearing
            ? 'border-2 rounded-xl p-2 border-[#FF5951]'
            : 'border-2 rounded-xl p-[1vw] border-[#eee]'
        }`}
        src={`../images/treasureHunt/closet/showcase/${type}/${index}.png`}
        alt='ornament'
      />
      {isWearing && (
        <div className='absolute right-0 top-0 border-[1px] border-[#fff] text-[2.5vw] font-bold bg-[#FF5951] rounded-md px-[1vw] py-[0.5vw] text-[#fff]'>
          장착중
        </div>
      )}
      {type === 'empty' && (
        <div className='absolute-center font-bold text-[#595864] text-[3vw] whitespace-nowrap'>
          장식 없음
        </div>
      )}
    </div>
  )
})

export default THClosetItem
