import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import TreasureHuntButton from '../TreasureHuntButton'

const DailyMission = observer(({ mission }) => {
  const [unit, setUnit] = useState('원')
  const [buttonText, setButtonText] = useState('보상 받기')

  useEffect(() => {
    if (mission?.rewardType === 'point') {
      setUnit('원')
    } else if (mission?.rewardType === 'energy') {
      setUnit('개')
    } else if (mission?.rewardType === 'starboxTierOne') {
      setUnit('개')
    } else if (mission?.rewardType === 'starboxTierTwo') {
      setUnit('개')
    }

    if (mission?.status === 'received' || mission?.status === 'finish') {
      setButtonText('내일 가능')
    } else if (mission?.status === 'reward') {
      setButtonText('보상 받기')
    } else if (mission?.status === 'action') {
      setButtonText(mission?.actionText)
    }
  }, [mission])

  return (
    <div className='flex-shrink-0 flex flex-row justify-between items-center w-full bg-[#FFF6F6] rounded-lg text-[#313138] mb-2 p-2'>
      <div className='flex flex-row items-center justify-start'>
        <div className='relative'>
          <img
            className='z-[10] w-[12vw] h-[12vw] mr-4'
            alt='emoji'
            src={`../images/treasureHunt/dailyMission/${mission?.type}.png`}
          />
          <div className='absolute -bottom-1 right-2 z-[10] bg-white rounded-full p-1 border-[1px] border-[#FFE2BA]'>
            <img
              className=' w-[4vw] h-[4vw] '
              alt='emoji'
              src={`../images/treasureHunt/${mission?.rewardType}.png`}
            />
          </div>
        </div>
        <div>
          <div className='font-bold w-full mt-1 mb-1 text-[#313138] flex flex-row justify-between items-start'>
            <div className='text-[4vw] flex flex-row'>{`${mission?.title}`}</div>
          </div>
          <div className='flex flex-row justify-between items-end w-full'>
            <div className='text-[3.5vw]  font-bold text-[#595864] flex flex-row justify-start items-center'>
              {`${mission?.rewardFixed ? '확정 ' : '최대 '}`}
              <img
                className='z-[10] w-[4vw] h-[4vw] ml-1 mr-[0.5vw]'
                alt='emoji'
                src={`../images/treasureHunt/${mission?.rewardType}.png`}
              />
              {`${mission?.rewardAmount}${unit}`}
              {`${
                mission?.dailyLimit ? `, 하루 ${mission?.dailyLimit}번` : ''
              }`}
            </div>
          </div>
        </div>
      </div>
      <TreasureHuntButton
        className='font-bold text-[4vw] shadow-none px-4'
        style={{}}
        clickable={mission?.status === 'action' || mission?.status === 'reward'}
        text={buttonText}
        onClick={() => {
          if (mission?.status === 'action') {
            mission?.actionHandler()
          }
          if (mission?.status === 'reward') {
            mission?.rewardHandler()
          }
        }}
      />
    </div>
  )
})

export default DailyMission
