import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { loadBookmarkQuery } from '../../remotes/comment'
import { CancelButton, ConfirmButton } from '../Button'
import { Flex } from '../Flex'
import { Popup } from '../Popup'
import { Spacing } from '../Spacing/Spacing'
import { useToast } from '../Toast'
import { storeBookmark } from './useBookmarkStore'

interface BookmarkConfirmProps {
  isOpen: boolean
  onClose: (isConfirmed: boolean) => void
  chapterId: string
  page: number
}

export const BookmarkConfirm = (props: BookmarkConfirmProps) => {
  const location = useLocation()
  const { chapterId } = location.state || {}
  const { openToast } = useToast()

  const { refetch } = useQuery(
    loadBookmarkQuery({
      chapterId,
    }),
  )

  const [bookmarkName, setBookmarkName] = useState(`${props.page + 1}페이지`)

  const handleSave = async () => {
    await storeBookmark(props.chapterId, props.page, bookmarkName)
    refetch()
    openToast({
      text: '책갈피에 저장했어요.',
    })
    props.onClose(true)
  }

  return (
    <Popup isOpen={props.isOpen} onClose={() => props.onClose(false)}>
      <Wrapper>
        <Title>책갈피 이름을 입력해주세요.</Title>
        <Spacing size={26}></Spacing>
        <input
          value={bookmarkName}
          onChange={(e) => setBookmarkName(e.target.value)}
          placeholder='책갈피 이름을 입력해주세요'
          css={css`
            border: 1px solid #e2e2e7;
            outline: none;
            border-radius: 4px;
            padding: 12px;
            width: calc(100% - 32px);
          `}
        />
        <Spacing size={20} />
        <Flex.Row
          css={css`
            gap: 10px;
          `}
        >
          <CancelButton onClick={() => props.onClose(false)}>취소</CancelButton>
          <ConfirmButton onClick={handleSave}>저장하기</ConfirmButton>
        </Flex.Row>
      </Wrapper>
    </Popup>
  )
}

const Title = styled.div`
  //styleName: Title 4/Bold;
  /* font-family: Pretendard Variable; */
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
`

const Wrapper = styled.div`
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`
