import { observer } from 'mobx-react-lite'
import React from 'react'

import { Close } from '../../images/dailyCheckIn'

const MoneyTreeNoticeModal = observer(({ visible, close, ...params }) => {
  const Title = () => {
    return (
      <div className='pt-3' style={{ fontSize: '7vw' }}>
        돈나무 유의사항
      </div>
    )
  }

  const TextComponent = () => {
    return (
      <div style={{ fontSize: '4vw' }} className='text-[#525052]'>
        <div className='h-4' />
        <div>
          * 돈나무 이벤트는 일부 고객님들에게 보이는 이벤트이며, 당사 사정에
          의해 사전 고지없이 종료될 수 있어요
        </div>
        <div className='h-2' />
        <div>
          * 서비스 이용에 동의한 올웨이즈 회원만 이용이 가능하며, 이용을
          위해서는 GPS 기능을 활성화시켜야 해요
        </div>
        <div className='h-2' />
        <div>
          * 일부 기종에서는 흔들기/GPS 기능이 정상적으로 작동하지 않을 수 있어요
        </div>
        <div className='h-4' />
        <div style={{ fontSize: '5vw', color: 'black' }}>보상 안내</div>
        <div>* 오늘 매칭된 친구는 다음날 다시 매칭될 수 있어요</div>
        <div className='h-2' />
        <div>
          * 친구를 찾을 때는 근처에서 흔들고 있는 회원을 우선 탐색한 뒤, 이후
          위치 관계 없이 현재 흔들고 있는 회원을 탐색해요
        </div>
        <div className='h-2' />
        <div>
          * 흔들기 보상은 최대 50회까지 지급되며, 매칭 거리나 흔든 횟수 등에
          따라 보상이 임의로 정해져요
        </div>
        <div className='h-2' />
        <div>
          * 근처에서 함께 흔들고 있는 친구와 매칭되면 기본 보상의 10배가
          지급돼요
        </div>
        <div className='h-2' />
        <div>
          * 관련 문의는 "내 정보 &gt; 고객문의 &gt; 1:1 실시간 상담하기"에서
          가능해요
        </div>
        <div className='h-2' />
      </div>
    )
  }

  return (
    <div>
      {/* 딤드 배경 */}
      <button
        type='button'
        className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-20'
        onClick={close}
        aria-label='background'
      />
      {/* 모달 상자 */}
      <div
        className='fixed left-1/2 py-6 z-30 bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
        style={{ top: '52%', width: '90%', height: '78%' }}
      />

      <button
        type='button'
        className='fixed p-2 z-40 bg-gray-300 rounded-full'
        style={{ left: '83%', top: '14%' }}
        onClick={close}
      >
        <Close className='w-6 h-6' />
      </button>

      {/* 모달 내용 */}
      <div
        style={{ top: '11%', left: '5%', width: '90%', height: '78%' }}
        className='p-4 flex flex-col fixed items-center z-30'
      >
        <Title />
        <TextComponent />
      </div>
    </div>
  )
})

export default MoneyTreeNoticeModal
