import React from 'react'

const GaugeBar = ({
  style,
  barColor = 'bg-red-500',
  backgroundColor = 'bg-gray-200',
  fontSize = 'text-xs',
  value = 0,
  height = 4,
  max = 100,
}) => {
  const heightCss = `h-${height}`
  const percent = parseInt(
    Math.min((Math.min(value, 100) / max) * 100, 100),
    10,
  )

  return (
    <div
      className={`relative w-full ${heightCss} rounded-full ${backgroundColor}`}
      style={style}
    >
      <div
        className={`absolute top-0 left-0 transition duration-150 ease-out h-full rounded-full ${barColor}`}
        style={{ width: `${Math.max(percent, 20)}%` }}
      />
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold ${fontSize} ${
          percent < 40 ? 'text-black' : 'text-white'
        }`}
      >
        {`${Math.max(percent, 8)}%`}
      </div>
    </div>
  )
}

export default GaugeBar
