import { action, makeObservable, observable } from 'mobx'

class ItemStore {
  insertDealInfo = []
  currentItemIndex = 0

  constructor() {
    makeObservable(this, {
      insertDealInfo: observable.ref,
      currentItemIndex: observable.ref,
      setInsertDealInfo: action,
      setCurrentItemIndex: action,
    })
  }

  setInsertDealInfo(insertDealInfo) {
    this.insertDealInfo = insertDealInfo
  }

  setCurrentItemIndex(currentItemIndex) {
    this.currentItemIndex = currentItemIndex
  }
}

export default new ItemStore()
