import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { TutorialTouch } from 'src/images/dailyCheckIn'

const DailyCheckInTutorial = observer(({ visible, close }) => {
  async function onClicked() {
    close()
    // afterClick()
  }

  return (
    <div>
      {/* 딤드 배경 */}
      <button
        style={{ height: 180 }}
        className='w-full fixed left-0 top-0 flex items-center justify-center bg-gray-500 opacity-70 z-10'
        onClick={async () => {
          await onClicked()
        }}
      />
      <button
        style={{ top: 180, height: 90 }}
        className='w-full fixed left-0 top-0 flex items-center justify-center bg-transparent z-10'
        onClick={async () => {
          await onClicked()
        }}
      />
      <button
        style={{ height: '80%', top: 280 }}
        className='w-full fixed left-0 flex items-center justify-center bg-gray-500 opacity-70 z-10'
        onClick={async () => {
          await onClicked()
        }}
      />
      {/* 모달 상자 */}
      <button
        style={{ left: '8%', top: '40%', borderRadius: 8, height: 150 }}
        className='flex flex-col items-center justify-center w-5/6 fixed bg-white z-20 text-black'
        onClick={async () => {
          await onClicked()
        }}
      >
        <div className='pb-2 font-bold text-2xl'>
          출석만 해도 포인트를 드려요!
        </div>
        <div className='font-bold text-base'>
          버튼을 눌러 출석체크를 해보세요
        </div>
      </button>
      <button
        className='fixed z-30'
        style={{ left: '74%', top: 220 }}
        onClick={async () => {
          await onClicked()
        }}
      >
        <TutorialTouch className='w-10 h-full' />
      </button>
    </div>
  )
})

export default DailyCheckInTutorial
