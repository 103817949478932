import commaNumber from 'comma-number'
import moment from 'moment'

import AltoonCMSUserStore from '../stores/AltoonCMS/AltoonCMSUserStore'

// import tableUtils from './tableUtils'

const Format = {
  Title: (value) => {
    return value.replace(/\p{Emoji_Presentation}/gu, '')
  },
  Recipient: (value) => {
    if (!value) return '고객님'
    return (
      value.replace(/\p{Emoji_Presentation}|\p{S}|[^a-zA-Z0-9가-힣]/gu, '') ||
      '고객님'
    )
  },
  Price: (value) => {
    if (!value) return '0원'
    return `${commaNumber(parseInt(value, 10))}원`
  },
  DateString: (value) => {
    if (!value) return ''
    const saveDate = new Date(value)
    const year = saveDate.getFullYear()
    const month = saveDate.getMonth() + 1
    const date = saveDate.getDate()
    const hours = saveDate.getHours()
    return `${year}년 ${month}월 ${date}일 ${hours}시`
  },
  DateToday: (value) => {
    const saveDate = new Date(value)
    // const year = saveDate.getFullYear()
    // const month = saveDate.getMonth() + 1
    // const date = saveDate.getDate()
    // return `${year}${month}${date}`
    return moment(saveDate).format('YYYYMMDD')
  },

  SettlementExcelHeaders: [
    { header: '매출 기간(월)', key: 'selectedMonth', width: 20 },
    { header: '작품명', key: 'toonTitle', width: 40 },
    { header: '정산 수수료율(%)', key: 'settlementFeeRate', width: 15 },
    { header: '코인 사용수량(개)', key: 'totalQuantity', width: 20 },
    { header: '총 매출액(원)', key: 'settlementAmount', width: 20 },
    {
      header: '앱스토어 수수료(원)',
      key: 'settlementAppStoreFeeAmount',
      width: 20,
    },
    {
      header: '올웨이즈 수수료(원)',
      key: 'settlementAlwayzFeeAmount',
      width: 20,
    },
    {
      header: '순매출액(원, 앱스토어 수수료 제)',
      key: 'settlementAmountWithoutAppStoreFee',
      width: 30,
    },
    {
      header: '정산대상금액(원, 전체 수수료 제)',
      key: 'settlementConfirmAmount',
      width: 30,
    },
  ],
  SettlementExceptExcelHeaders: [
    { header: '정산 일자', key: 'settlementDate', width: 30 },
  ],

  checkIfValidUUID: (str) => {
    // Regular expression to check if string is a valid UUID
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

    return regexExp.test(str)
  },

  formatOrderForSettlementExcel: (order) => {
    return {
      selectedMonth: `${AltoonCMSUserStore.selectedMonth.getFullYear()}년 ${
        AltoonCMSUserStore.selectedMonth.getMonth() + 1
      }월`,
      ...order,
    }
  },
}
export default Format
