import ItemStore from '../stores/ItemStore'

const insertDealHandler = {
  dataSetter(dealType, itemData) {
    if (
      Array.isArray(ItemStore?.insertDealInfo) &&
      ItemStore?.insertDealInfo?.filter((item) => item?.dealType === dealType)
        ?.length === 0
    ) {
      ItemStore.setInsertDealInfo([
        ...ItemStore?.insertDealInfo,
        {
          dealType,
          inserted: true,
          itemData: [...itemData],
        },
      ])
    }
  },
  dataMixer(itemData, insertData) {
    const result = []
    for (let i = 0; i < itemData.length; i += 1) {
      result.push(itemData[i])
      if ((i + 1) % 8 === 0) {
        // 특정 데이터를 추가하는 부분입니다.
        const insertIndex = parseInt((i + 1) / 8, 10) - 1
        if (insertData[insertIndex]) result.push(insertData[insertIndex])
      }
    }
    return result
  },
}

export default insertDealHandler
