import Heart from './heart.png'
import Paper from './paper.png'
import Rock from './rock.png'
import Scissors from './scissors.png'
import Ticket from './ticket.png'

const gameImages = {
  Rock,
  Paper,
  Scissors,
  Heart,
  Ticket,
}
export default gameImages
