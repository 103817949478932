import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useSearchParam } from 'react-use'

import Button from '../components/groupPurchase/Button'
import Header from '../components/groupPurchase/Header'
import Layout from '../components/groupPurchase/Layout'
import Modal from '../components/groupPurchase/Modal'
import AuthStore from '../stores/AuthStore'
import GroupPurchaseStore from '../stores/GroupPurchaseStore'
import ToastStore from '../stores/ToastStore'

const GroupPurchaseRegister = observer(() => {
  const token = useSearchParam('token')

  AuthStore.setToken(token)

  useEffect(() => {
    const init = async () => {
      await GroupPurchaseStore?.fetchGroupPurchaseUserInfo(true)
      GroupPurchaseStore?.setIsLoading(false)
    }
    if (AuthStore?.token || token) {
      init()
    }
  }, [])

  if (GroupPurchaseStore?.isLoading) {
    return <div />
  }

  return (
    <Layout
      style={{
        backgroundColor: '#F7F3E8',
        border: 0,
        width: '100%',
        height: '100vh',
        margin: 0,
        padding: 0,
        justifyContent: 'flex-start',
      }}
    >
      <div className='flex'>
        <Header
          title='모임구매 선착순'
          infoButton
          infoNavigate={() => {
            GroupPurchaseStore?.openInfoModal()
          }}
          navigateBack={() => {
            window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'goBack',
              }),
            )
          }}
        />
      </div>
      <div className='overflow-y-scroll pb-[20vw]'>
        <img
          className='w-full'
          alt='explain'
          src='../images/groupPurchase/forRegister/1.png'
        />
        <div className='border-2 border-[#F7F3E8]' />
        <img
          className='w-full'
          alt='explain'
          src='../images/groupPurchase/forRegister/2.png'
        />

        <img
          className='w-full '
          alt='explain'
          src='../images/groupPurchase/forRegister/3.png'
        />

        <img
          className='w-full'
          alt='explain'
          src='../images/groupPurchase/forRegister/4.png'
        />
        <div className='h-2 bg-white' />
        <div className='border-2 border-[#F7F3E8]' />

        <img
          className='w-full'
          alt='explain'
          src='../images/groupPurchase/forRegister/5.png'
        />
      </div>

      <Button.CTA
        text='사전등록 신청하기'
        className={`${GroupPurchaseStore?.userInfo?.userId ? 'disabled' : ''}`}
        onClick={() => {
          GroupPurchaseStore?.createGroupPurchaseUserInfo(true)

          if (!GroupPurchaseStore?.userInfo?.userId) {
            GroupPurchaseStore?.createGroupPurchaseUserInfo(true)
            ToastStore.toastOn({
              type: 'success',
              message: '성공적으로 신청을 완료했어요!',
              duration: 3000,
            })
          } else {
            ToastStore.toastOn({
              type: 'success',
              message: '이미 신청을 완료했어요!',
              duration: 3000,
            })
          }
        }}
      />

      {GroupPurchaseStore?.oneButtonModalOn && (
        <Modal.OneButton props={GroupPurchaseStore?.modalConfig} />
      )}

      {/* <Toaster
        position='top-center'
        toastOptions={{
          style: {
            marginTop: '20vw',
            fontFamily: 'maplestory',
            color: '#965F42',
          },
        }}
      /> */}
    </Layout>
  )
})

export default GroupPurchaseRegister
