import { FiChevronRight } from 'react-icons/fi'

import THImageText from '../treasureHunt/THImageText'

const ComponentTitle = ({ text, style, chevronRight }) => {
  return (
    <div className='flexRow text-start justify-between items-center pb-[4vw]'>
      <div className='text-[4.6vw] font-bold'>
        <THImageText style={style} text={text?.replace(/\\n/g, '\n')} />
      </div>
      {chevronRight && (
        <FiChevronRight className='w-6 h-6 mr-[2vw] stroke-[0.2vw]' />
      )}
    </div>
  )
}

export default ComponentTitle
