import { observer } from 'mobx-react-lite'
import React from 'react'

import { Close, ModalPig } from '../../images/dailyCheckIn'
import UserStore from '../../stores/UserStore'

const CheckInPigRouletteInviteModal = observer(
  ({
    visible,
    close,
    setShowTutorialModal,
    checkInInvitorName,
    alreadyParticipated,
    invitorMissionAlreadyCleared,
  }) => {
    const TextComponent = () => {
      if (invitorMissionAlreadyCleared) {
        return (
          <div className='flex flex-col justify-center items-center'>
            <div>
              앗!{' '}
              {checkInInvitorName.length < 6
                ? checkInInvitorName
                : `${checkInInvitorName.slice(0, 6)}...`}
              님은 오늘{' '}
            </div>
            <div> 친구의 도움을 받았대요</div>
            <div>다른 친구를 도와줘보는건 어떨까요?</div>
          </div>
        )
      }
      if (alreadyParticipated) {
        return (
          <div className='flex flex-col justify-center items-center'>
            <div>오늘은 이미 친구를 도와주셨군요!</div>
            <div>아쉽게도 내일 다시 도와줄 수 있어요</div>
            <div className='pt-4'>대신 제게 밥을 주시면 어떨까요~?</div>
          </div>
        )
      }
      return (
        <>
          <div className='flex flex-col justify-center items-center'>
            <div className='relative'>
              {checkInInvitorName.length < 6
                ? checkInInvitorName
                : `${checkInInvitorName.slice(0, 6)}...`}
              님을 도와주셔서 감사해요!
            </div>
            {UserStore?.userInfo?.userName && (
              <div>
                {UserStore?.userInfo?.userName.length < 6
                  ? UserStore?.userInfo?.userName
                  : `${UserStore?.userInfo?.userName.slice(0, 6)}...`}
                님도 제게 밥을 주시면,
              </div>
            )}
            <div className='relative'>
              제가 돈을 드릴게요!{' '}
              <span
                className='absolute inset-x-0 h-1 ml-8'
                style={{
                  bottom: 3,
                  borderBottom: '8px solid rgba(255, 223, 221, 0.9)',
                  zIndex: '-1',
                }}
              />
            </div>
          </div>
        </>
      )
    }

    return (
      <div>
        {/* 딤드 배경 */}
        <button
          type='button'
          className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-900 opacity-70 z-10'
          onClick={close}
          aria-label='Background'
        />

        {/* 모달 상자 */}
        <div
          style={{ left: '8%', top: '20vh', borderRadius: 8, height: '54vh' }}
          className='w-5/6 fixed bg-white z-20'
        />
        <button
          type='button'
          className='fixed z-30 p-1 bg-gray-300 rounded-full'
          style={{ left: '80%', top: '22vh' }}
          onClick={close}
        >
          <Close className='w-6 h-6' />
        </button>

        {/* 모달 내용 + 받기 버튼 */}
        <div style={{ left: '16vw', top: '24vh' }} className='fixed z-30 w-4/6'>
          <div className='w-full h-full flex flex-col justify-between items-center text-[#525052] text-lg jua'>
            <ModalPig className='animate-float w-44 h-full' />
            <TextComponent />
            <div className='h-4' />
            <div
              className='w-full py-3 bg-red-500 rounded-xl text-lg text-white text-center'
              aria-hidden='true'
              onClick={async () => {
                close()
                setShowTutorialModal(true)
              }}
            >
              확인
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export default CheckInPigRouletteInviteModal
