import { observable } from 'mobx'

import backendApis from '../../utils/backendApis'
import THModalStore from './THModalStore'
import THUserStore from './THUserStore'

const THBottomSheetStore = observable({
  type: 'none',
  config: {},
  key: Math.random(),
  titleOff: false,

  setType({ type, config, titleOff }) {
    this.type = type
    this.config = config
    this.key = Math.random()
    this.titleOff = titleOff
  },

  close() {
    if (this.type === 'tutorial') {
      if (this.config?.tutorialMinorStep) {
        backendApis.THSet({
          set: {
            tutorialMinorStep: this.config?.tutorialMinorStep,
          },
          collection: 'user',
        })
        THUserStore?.set('tutorialMinorStep', this.config?.tutorialMinorStep)
      }
      if (this.config?.nextStep) {
        backendApis.THSet({
          set: {
            tutorialStep: this.config?.nextStep,
          },
          collection: 'user',
        })
        THUserStore?.set('tutorialStep', this.config?.nextStep)
      } else if (this.config?.endTutorial) {
        backendApis.THSet({
          set: {
            tutorialEndAt: new Date(),
          },
          collection: 'user',
        })
        THUserStore?.set('tutorialEndAt', new Date())
        THModalStore.showNextModal()
      }
    }
    this.type = 'none'
    this.config = {}
    this.key = Math.random()
  },
})

export default THBottomSheetStore
