import { observer } from 'mobx-react-lite'
import React from 'react'
import { Close, InvitationStamp } from 'src/images/dailyCheckIn'
import UserStore from 'src/stores/UserStore'

const DailyCheckInInviteNudging = observer(
  ({
    visible,
    close,
    goBackToMain,
    setShowInviteNudgingModal = () => {},
    setOpenInviteFriendsNewModal,
    setShowTutorialModal,
    checkInInvitorName,
    checkInInvitorId,
  }) => {
    return (
      <div>
        {/* 딤드 배경 */}
        <button
          className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-10'
          onClick={close}
        />

        {/* 모달 상자 */}
        <div
          style={{ left: '8%', top: '20%', borderRadius: 8, height: '58%' }}
          className='w-5/6 fixed bg-white z-20'
        />
        <button
          className='fixed z-30 p-1 bg-gray-300 rounded-full'
          style={{ left: '80%', top: '22%' }}
          onClick={close}
        >
          <Close className='w-6 h-6' />
        </button>

        {/* 모달 내용 + 받기 버튼 */}
        <div
          style={{ left: '12%', top: '24%', width: '76%', height: '55%' }}
          className='fixed z-30 flex flex-col justify-between items-center'
        >
          <div className='flex items-center text-2xl font-bold my-2'>
            {checkInInvitorName}님의 초대장
          </div>
          <InvitationStamp className='w-40 h-40 ' />
          <div className='w-full'>
            {checkInInvitorId === UserStore?.userInfo?._id ? (
              <div className='flex flex-col justify-center items-center text-center text-base'>
                <div>
                  <div className='mb-2 font-bold'>본인은 초대가 불가해요😭</div>
                  <div>친구를 출석체크에 초대하고 </div>
                  <div>2,000원 적립의 혜택을 받으세요! </div>
                </div>
                <button
                  className='mt-2 p-2 w-4/5 bg-red-500 rounded font-bold text-lg text-white'
                  onClick={() => {
                    setShowInviteNudgingModal(false)
                    setOpenInviteFriendsNewModal(true)
                  }}
                >
                  초대하고 포인트 받기
                </button>
              </div>
            ) : (
              <div className='flex flex-col justify-center items-center text-center text-base'>
                {' '}
                <div>
                  <div className='mb-2 font-bold'>친구가 나를 초대했어요</div>
                  <div>내가 출석체크를 하면 </div>
                  <div>친구가 포인트를 받아요!</div>
                </div>
                <button
                  className='mt-2 p-2 w-4/5 bg-red-500 rounded font-bold text-lg text-white'
                  onClick={() => {
                    setShowInviteNudgingModal(false)
                    setShowTutorialModal(true)
                  }}
                >
                  출석체크 하러 가기
                </button>
              </div>
            )}
            <div style={{ height: 24 }} />
          </div>
        </div>
      </div>
    )
  },
)

export default DailyCheckInInviteNudging
