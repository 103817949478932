import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import GroupPurchaseStore from '../../stores/GroupPurchaseStore'
import ToastStore from '../../stores/ToastStore'
import UserStore from '../../stores/UserStore'
import { sendMessage } from '../../utils/utils'
import BlackTextTimer from '../BlackTextTimer'
import Button from './Button'
import GaugeBar from './GaugeBar'

const GroupPurchaseItem = observer(({ item, index, isRewardAvailableList }) => {
  const itemInfo = item?.permanentMarks
  const groupPurchaseInfo = itemInfo?.characteristicItem?.groupPurchaseInfo
  const discountRate = Math.round(
    (1 - itemInfo?.teamPurchasePrice / itemInfo?.individualPurchasePrice) * 100,
  )

  let rewardConfig = {
    text: '모임구매 실패 🥲',
    className: 'disabled border-gray-300 ',
    onClick: () => {},
  }
  if (
    item?.membersInfo?.length < groupPurchaseInfo?.teamDealThreshold &&
    item?.groupPurchaseRewardAvailableAt &&
    item?.teamCompleted &&
    !item?.receivedGroupPurchaseRewardAt
  ) {
    rewardConfig = {
      text: '구매 취소 모임구매 (보상 미지급)',
      className: 'disabled border-gray-300 ',
      onClick: async () => {},
    }
  } else if (
    item?.groupPurchaseRewardAvailableAt &&
    item?.teamCompleted &&
    !item?.receivedGroupPurchaseRewardAt
  ) {
    rewardConfig = {
      text: '현금 리워드 받기',
      className: 'border-yellow-300 ',
      onClick: async () => {
        const res = await GroupPurchaseStore?.giveReward({
          reward: groupPurchaseInfo?.reward,
          teamDealId: item?._id,
          index,
        })
      },
    }
  } else if (
    !item?.groupPurchaseRewardAvailableAt &&
    item?.teamCompleted &&
    !item?.receivedGroupPurchaseRewardAt
  ) {
    rewardConfig = {
      text: '배송완료시 현금 리워드 지급',
      className: 'disabled border-orange-400 bg-orange-300',
      onClick: async () => {
        ToastStore?.toastOn({
          type: 'success',
          message: '배송이 완료되면 리워드가 지급돼요',
          duration: 3000,
        })
      },
    }
  } else if (
    item?.groupPurchaseRewardAvailableAt &&
    item?.teamCompleted &&
    item?.receivedGroupPurchaseRewardAt
  ) {
    rewardConfig = {
      text: '현금 리워드 지급 완료',
      className: 'disabled border-gray-300 ',
      onClick: () => {},
    }
  }

  return (
    <div
      className='flex flex-col w-100% justify-between items-center border-[1px] p-4
      rounded-lg mb-2 relative'
    >
      {item?.receivedGroupPurchaseRewardAt && (
        <div className='absolute top-[24vw] right-[4px] text-gray-400 text-[3vw] font-medium'>{`${moment(
          item?.receivedGroupPurchaseRewardAt,
        ).format('YYYY-MM-DD')} 지급`}</div>
      )}
      <div className='flex flex-row items-center w-[100%] h-20 justify-start'>
        <img
          className='w-20 h-20 object-contain border-[1px] rounded-md'
          alt=''
          src={`${itemInfo?.itemImageUri}`}
        />
        <div className='w-full h-20 flex flex-col justify-between items-start'>
          <div className='flex ml-4 flex-row justify-start items-start text-[4vw] font-bold text-red-400 '>
            {`성공 보상 ${commaNumber(groupPurchaseInfo?.reward)}원 지급!`}
          </div>
          <div className='ml-4 text-[3.5vw] font-medium'>
            {`${
              itemInfo?.itemTitle?.length > 20
                ? `${itemInfo?.itemTitle?.slice(0, 20)}..`
                : itemInfo?.itemTitle
            }`}
          </div>
          <div className='ml-4 text-[3.5vw] text-gray-400 line-through'>{`원래 가격: ${commaNumber(
            itemInfo?.individualPurchasePrice,
          )}원`}</div>
          <div className='flex flex-row justify-start w-full '>
            <div className='ml-4 font-bold text-[4vw] text-red-600'>{`${discountRate}%`}</div>
            <div className='ml-2 font-bold text-[4vw]'>{`${commaNumber(
              itemInfo?.teamPurchasePrice,
            )}원`}</div>
          </div>
        </div>
      </div>
      <div
        className={`flex mt-4 w-full justify-between items-center ${
          item?.membersInfo?.length > 0 ? 'p-1' : ''
        }`}
      >
        {item?.membersInfo?.length > 0 && (
          <div
            className='flex flex-row flex-1 justify-start items-center'
            onClick={() => {
              GroupPurchaseStore?.openGroupPurchaseDetailModal({
                item,
                itemInfo,
                discountRate,
              })
            }}
          >
            {item?.membersInfo?.map((memberInfo, index) => (
              <img
                key={Math.random()}
                className={`w-[8vw] h-[8vw] object-contain border-[2px] border-white rounded-full ${
                  index > 0 ? 'ml-[-3vw]' : ''
                }`}
                alt='profile'
                src={`${memberInfo?.userImageUri}`}
              />
            ))}
            <div className='text-[3vw] text-[#666] ml-2'>{`${
              item?.membersInfo?.[0]?.userName
            }님 ${
              item?.membersInfo?.length > 1
                ? `외 ${item?.membersInfo?.length - 1}명`
                : ''
            } 참여`}</div>
          </div>
        )}
        {GroupPurchaseStore?.displayType === 'ongoing' &&
          !isRewardAvailableList && (
            <div className='text-[3vw] text-[#666]'>
              종료까지{' '}
              <BlackTextTimer
                timeStamp={moment(item?.createdAt).add(1, 'd').toDate()}
                timerMinutes={0}
                fontSize='3vw'
                marginTop={-8}
                color='#666'
                onTimeEnd={() => {
                  GroupPurchaseStore.initializeGroupPurchase()
                }}
              />{' '}
              남았어요!
            </div>
          )}
      </div>

      <div className='mt-2 w-full'>
        {(GroupPurchaseStore?.displayType === 'ongoing' ||
          item?.membersInfo?.length ===
            groupPurchaseInfo?.teamDealThreshold) && (
          <GaugeBar
            currentExp={Math.max(0.3, item?.membersInfo?.length)}
            totalExp={groupPurchaseInfo?.teamDealThreshold}
          />
        )}
      </div>
      {GroupPurchaseStore?.displayType === 'history' ||
      isRewardAvailableList ? (
        <div className='flex flex-row items-center justify-center w-full mt-4'>
          <Button.Normal
            text={rewardConfig?.text}
            className={`flex-1 pt-[2vw] pb-[2vw] border-[2px] ${rewardConfig?.className}`}
            onClick={rewardConfig?.onClick}
          />
        </div>
      ) : (
        <div className='flex flex-row items-center justify-center w-full mt-4'>
          <Button.Normal
            text='나도 참여하기'
            className='inverted flex-1 mr-2 pt-[2vw] pb-[2vw]'
            onClick={() => {
              GroupPurchaseStore?.participateGroupPurchase({ item })
            }}
          />
          <Button.Normal
            text='추천하기'
            className='flex-1 ml-2 pt-[2vw] pb-[2vw] '
            onClick={() => {
              GroupPurchaseStore?.shareItemLink({
                item,
                itemInfo,
                discountRate,
              })
            }}
          />
        </div>
      )}
    </div>
  )
})

export default GroupPurchaseItem
