import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const NextChapterButton = ({
  src,
  title,
  onClick,
}: {
  src: string
  title: string
  onClick: () => void
}) => (
  <button
    type='button'
    onClick={onClick}
    className='w-full flex flex-row justify-between items-center border-[0.5vw] border-x-transparent mb-3 p-3'
  >
    <div className='flex items-center'>
      <img src={src} alt={title} className='w-[36vw] h-auto rounded-md' />
      <div className='flex-column justify-start items-start text-left ml-5 text-[4.6vw] text-black'>
        다음화 보기
        <div> {title}</div>
      </div>
    </div>
    <div className='text-right'>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  </button>
)
