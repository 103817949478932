import { observer } from 'mobx-react-lite'
import React from 'react'
import { Close, InvitationStamp } from 'src/images/dailyCheckIn'
import UserStore from 'src/stores/UserStore'

const DailyCheckInInviteGuideModal = observer(
  ({
    visible,
    close,
    setOpenInviteGuide,
    goBackToMain,
    checkInInvitorName,
    setShowTutorialModal,
    setShowInviteNudgingModal,
    checkInInvitorId,
    isLeftUser = undefined,
  }) => {
    return (
      <div>
        {/* 딤드 배경 */}
        <button
          className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-10'
          onClick={goBackToMain}
        />

        {/* 모달 상자 */}
        <div
          style={{ left: '8%', top: '20%', borderRadius: 8, height: '58%' }}
          className='w-5/6 fixed bg-white z-20'
        />
        <button
          className='fixed z-30 p-1 bg-gray-300 rounded-full'
          style={{ left: '80%', top: '22%' }}
          onClick={() => {
            setOpenInviteGuide(false)
          }}
        >
          <Close className='w-6 h-6' />
        </button>

        {/* 모달 내용 + 받기 버튼 */}
        <div
          style={{ left: '12%', top: '24%', width: '76%', height: '55%' }}
          className='fixed z-30 flex flex-col justify-between items-center'
        >
          <div className='flex flex-col items-center text-2xl font-bold my-2'>
            <div>{checkInInvitorName}님의 초대장</div>
            <InvitationStamp className='mt-4 w-40 h-40 ' />
          </div>
          <div className='w-full'>
            {checkInInvitorId === UserStore?.userInfo?.userId && (
              <div className='flex flex-col justify-center items-center text-center text-base'>
                <div>
                  <div className='mb-2 font-bold'>본인은 초대가 불가해요😭</div>
                  <div>초대장 없이도 가능한</div>

                  <div>출석체크를 하고 혜택을 받아보세요!</div>
                </div>
                <button
                  className='mt-2 p-2 w-4/5 bg-red-500 rounded font-bold text-lg text-white'
                  onClick={() => {
                    setOpenInviteGuide(false)
                    setShowInviteNudgingModal(true)
                  }}
                >
                  출석체크 하러 가기
                </button>
              </div>
            )}
            {checkInInvitorId !== UserStore?.userInfo?.userId && isLeftUser && (
              <div className='flex flex-col justify-center items-center text-center text-base'>
                <div>
                  <div className='flex flex-row items-center justify-center '>
                    <div className='text-xl mb-2 font-bold'>반가워요!</div>
                    <div className='text-3xl ml-1 mb-2 animate-wiggle'>👋</div>
                  </div>
                  <div>출석을 하고 포인트를 받으면</div>
                  <div>{checkInInvitorName}님에게 혜택이 주어져요!</div>
                  <div className='my-1 text-xs'>
                    (탈퇴이력이 있어서 새친구 혜택은 못받아요😭)
                  </div>
                </div>

                <button
                  className='mt-2 p-2 w-4/5 bg-red-500 rounded font-bold text-lg text-white'
                  onClick={setOpenInviteGuide}
                >
                  출석체크 하러 가기
                </button>
              </div>
            )}
            {checkInInvitorId !== UserStore?.userInfo?.userId &&
              !isLeftUser && (
                <div className='flex flex-col justify-center items-center text-center text-base'>
                  <div>
                    <div className='flex flex-row items-center justify-center '>
                      <div className='text-xl mb-2 font-bold'>반가워요!</div>
                      <div className='text-3xl ml-1 mb-2 animate-wiggle'>
                        👋
                      </div>
                    </div>
                    <div>출석을 하고 포인트를 받으면</div>
                    <div>{checkInInvitorName}님에게 혜택이 주어져요!</div>
                  </div>
                  <button
                    className='mt-2 p-2 w-4/5 bg-red-500 rounded font-bold text-lg text-white'
                    onClick={() => {
                      setOpenInviteGuide(false)
                      setShowTutorialModal(true)
                    }}
                  >
                    출석체크 하러가기
                  </button>
                </div>
              )}
            <div style={{ height: 24 }} />
          </div>
        </div>
      </div>
    )
  },
)

export default DailyCheckInInviteGuideModal
