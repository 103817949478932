import React, { memo, useEffect, useState } from 'react'

import Header from '../../components/atoms/header'
import HorizontalList from '../../components/deal/item/horizontalList'
import PriceSortDealItemComponent from '../../components/deal/item/priceSortDealItemComponent'
import VerticalList from '../../components/deal/item/verticalList'
import dealImages from '../../images/deal'
import AuthStore from '../../stores/AuthStore'
import backendApis from '../../utils/backendApis'

const SellerDealPage = () => {
  const priceSortList = [5900, 7900, 9900]
  const [dealItemsInfo, setDealItemsInfo] = useState([])
  const [priceLimit, setPriceLimit] = useState(priceSortList?.[0])
  const [indices, setIndices] = useState([])
  const token = AuthStore?.token
  const loadNum = dealItemsInfo?.length
  const dealType = 'timeDeal'
  const version = 'mainTimeDeal'
  const condition = {
    priceUpperLimit: priceLimit,
  }
  const SCREEN_WIDTH = window.innerWidth
  const SCREEN_HEIGHT = window.innerHeight

  const CategorySelectSection = () => {
    const renderItem = memo(({ index, style, data }) => {
      return (
        <div
          key={`${index}`}
          aria-hidden
          onClick={async () => {
            if (priceLimit !== data[index]) {
              setPriceLimit(data[index])
            }
          }}
          className={`flex flex-1 w-full text-lg p-3 ${
            priceLimit === data[index]
              ? 'bg-red-100 text-red-500 font-bold'
              : 'bg-gray-100 text-gray-800'
          }  rounded-lg items-center justify-center mr-2`}
          style={{ ...style, width: style?.width - 8 }}
        >
          {data?.[index]}
        </div>
      )
    })
    return (
      <div className='flex flex-col items-center justify-center flex-1 w-full mt-2 mr-4'>
        <HorizontalList
          screenWidth={SCREEN_WIDTH}
          itemHeight={30}
          itemWidth={80}
          itemCount={priceSortList?.length || 0}
          itemData={priceSortList}
          renderItem={renderItem}
          isItemList={false}
        />
      </div>
    )
  }

  const fetchDealItem = async (
    dealType,
    loadNum,
    version,
    indices,
    condition,
    first = false,
  ) => {
    const itemIndices = indices.slice(0, 32)
    setIndices((arr) => arr.slice(32))
    const result = await backendApis.getDealItemsV3(
      dealType,
      loadNum,
      version,
      itemIndices,
      condition,
    )
    dealStateSetter(result, first)
  }

  const dealStateSetter = (result, first) => {
    if (result?.status === 200) {
      const dealItems = result?.data
      if (first) {
        setDealItemsInfo(dealItems)
      } else {
        setDealItemsInfo((arr) => [...arr, ...dealItems])
      }
      if (result?.itemIndices?.length > 0) {
        setIndices(result?.itemIndices)
      }
    }
  }

  const initialFetch = async (version) => {
    await fetchDealItem(dealType, loadNum, version, [], condition, true)
  }

  const loadMore = async () => {
    const itemIndices = indices.slice(0, 32)
    setIndices((arr) => arr.slice(32))
    await fetchDealItem(
      dealType,
      loadNum,
      version,
      itemIndices,
      condition,
      false,
    )
  }
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const renderItem = (index, data) => {
    return (
      <PriceSortDealItemComponent
        key={`${index}`}
        itemInfo={data}
        version={version}
        isFrom='PriceSortDealPage'
      />
    )
  }
  const Skeleton = () => {
    return (
      <>
        <PriceSortDealItemComponent empty />
        <PriceSortDealItemComponent empty />
        <PriceSortDealItemComponent empty />
        <PriceSortDealItemComponent empty />
        <PriceSortDealItemComponent empty />
        <PriceSortDealItemComponent empty />
      </>
    )
  }

  useEffect(() => {
    initialFetch(version)
  }, [token, priceLimit])

  return (
    <>
      <Header title='99특가' goBackRoute='/deal/time-deal' />
      <div className='pt-12'>
        <div className='fixed z-50 h-12 bg-white'>
          <CategorySelectSection />
        </div>
        <div className='px-2 mt-10 mb-3'>
          <img
            src={dealImages?.PriceSortDealMainImage}
            alt='CategoryDealMainImage'
          />
        </div>
        {dealItemsInfo?.length > 0 ? (
          <VerticalList
            itemData={dealItemsInfo}
            height={SCREEN_HEIGHT}
            renderItem={renderItem}
            loadMore={loadMore}
            isFrom='CategoryDealPage'
            isItemList
          />
        ) : (
          <Skeleton />
        )}
        <button
          type='button'
          className='fixed p-2 bg-white rounded-full shadow-lg bottom-6 right-6'
          onClick={scrollToTop}
        >
          <img src={dealImages.Up} className='w-5 h-5' alt='scroll up' />
        </button>
      </div>
    </>
  )
}
export default SellerDealPage
