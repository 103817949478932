import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import AltoonUserStore from '../../stores/AltoonUserStore'
import ToonModal from '../templates/ToonModal'

const MyTicketSection = observer(() => {
  const [modalType, setModalType] = useState('')
  const [modalProp, setModalProp] = useState([])
  const [openTicketModal, setOpenTicketModal] = useState(false)
  const currentCoin =
    AltoonUserStore?.userData?.toonCoin > 0
      ? AltoonUserStore?.userData?.toonCoin
      : 0
  const currentPaidCoin =
    AltoonUserStore?.userData?.paidCoin > 0
      ? AltoonUserStore?.userData?.paidCoin
      : 0

  const GoodsComponent = observer(() => {
    return (
      <button
        type='button'
        className='p-4 w-full relative bg-[#F6F6F6] rounded-lg text-[4vw]'
        onClick={() => {
          setModalType('ticketExchange')
          setModalProp({ goods: 'toonCoin' })
          setOpenTicketModal(true)
        }}
      >
        <div className='items-start w-full flexCol'>
          <div className='items-center justify-between w-full flexRow'>
            <div className='flexRow justify-center items-center pl-[4vw]'>
              <div className='items-center justify-between flexRow whitespace-nowrap'>
                <img
                  src='/images/toon/toonCoin.png'
                  alt='toonCoin'
                  className='w-[6vw] h-[6vw] mr-2'
                />
                보유 코인
              </div>
              <div className='text-[#FF8317] pl-3'>
                {commaNumber(currentCoin)}C
              </div>
            </div>
            <div>
              <div className='absolute top-[6vw] right-[4vw] px-[4vw] py-[3vw] text-white text-[3.6vw] bg-[#FF8317] rounded-lg'>
                충전하기
              </div>
            </div>
          </div>
          <div className='pl-[12vw] pt-2 text-start text-gray-500 text-[3vw]'>
            <div className=''>
              미션 획득 코인 : {commaNumber(currentCoin - currentPaidCoin)}C
            </div>
            <div className='text-gray-500 text-[3vw]'>
              유료 결제 코인 : {commaNumber(currentPaidCoin)}C
            </div>
          </div>
        </div>
      </button>
    )
  })

  return (
    <>
      <div className='flex flex-col items-center w-full px-4'>
        <div className='items-center w-full p-2 mb-2 font-semibold flexCol'>
          <GoodsComponent data={AltoonUserStore?.userData} />
        </div>
      </div>
      {openTicketModal && (
        <ToonModal
          modalName={modalType}
          coinCount={
            AltoonUserStore?.userData?.toonCoin > 0
              ? AltoonUserStore?.userData?.toonCoin || 0
              : 0
          }
          modalProp={modalProp}
          setOpenTicketModal={setOpenTicketModal}
          setModalType={setModalType}
          setModalProp={setModalProp}
        />
      )}
    </>
  )
})
export default MyTicketSection
