// stackflow.js
import { basicUIPlugin } from '@stackflow/plugin-basic-ui';
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic';
import { stackflow } from '@stackflow/react';

import AltoonMainPage from './pages/AltoonMainPage';
import AltoonCommentPage from './pages/AltoonCommentPage'
import AltoonDetailPage from './pages/AltoonDetailPage'
import AltoonEventListPage from './pages/AltoonEventListPage'
import AltoonEventPage from './pages/AltoonEventPage'
import AltoonFarmLandingPage from './pages/AltoonFarmLandingPage'
import AltoonItemPage from './pages/AltoonItemPage'
import AltoonMyPage from './pages/AltoonMyPage'
import AltoonNovelCommentListPage from './pages/AltoonNovelCommentListPage'
import AltoonNovelDetailPage from './pages/AltoonNovelDetailPage'
import AltoonNovelPage from './pages/AltoonNovelPage'
import AltoonNovelReaderPage from './pages/AltoonNovelReaderPage'
import AltoonOnboardingPage from './pages/AltoonOnboardingPage'
import AltoonReaderPage from './pages/AltoonReaderPage'
import AltoonTeenProtectionTerms from './pages/AltoonTeenProtectionTerms'
import AltoonTermsOfUse from './pages/AltoonTermsOfUse'
import AltoonTicketPage from './pages/AltoonTicketPage'
import AltoonTicketPurchasePage from './pages/AltoonTicketPurchasePage'
import AltoonToonPage from './pages/AltoonToonPage'
import AltoonMainEventPage from './pages/AltoonMainEventPage';

// 다른 액티비티 임포트

export const { Stack, activities, actions } = stackflow({
  transitionDuration: 350,
  activities: {
    AltoonMainPage,
    AltoonCommentPage,
    AltoonDetailPage,
    AltoonEventListPage,
    AltoonEventPage,
    AltoonFarmLandingPage,
    AltoonItemPage,
    AltoonMyPage,
    AltoonNovelCommentListPage,
    AltoonNovelDetailPage,
    AltoonNovelPage,
    AltoonNovelReaderPage,
    AltoonOnboardingPage,
    AltoonReaderPage,
    AltoonTeenProtectionTerms,
    AltoonTermsOfUse,
    AltoonTicketPage,
    AltoonTicketPurchasePage,
    AltoonToonPage,
    AltoonMainEventPage,
    // 다른 액티비티 등록
  },
  initialActivity: () => 'AltoonMainPage',
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      theme: 'cupertino',
    }),
  ],
})

// 액티비티 타입 내보내기
export type TypeActivities = typeof activities;

