import React, { useEffect } from 'react'

import UserStore from '../stores/UserStore'

const AlwalkCashwalkPage = () => {
  const cta = () => {
    if (UserStore.userInfo.needsLogin) {
      window.location.href = `#alluluLoginModal.${JSON.stringify({
        screen: 'AlluluScreen',
        prop: {
          shareType: 'Allulu_cashwalkEvent',
        },
      })}`
    } else if (!UserStore.userInfo.needsLogin) {
      window.location.href = `#navigate.${JSON.stringify({
        screen: 'AlluluScreen',
        prop: {
          params: {
            shareType: 'Allulu_cashwalkEvent',
          },
        },
      })}`
    }
  }

  return (
    <>
      <div>
        <div
          style={{
            position: 'fixed',
            width: '80vw',
            background: '#FF5CA2',
            bottom: '4vw',
            marginLeft: '10vw',
            borderRadius: '100vw',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            fontSize: '6vw',
            fontFamily: 'pretendard-bold',
            color: '#ffffff',
            padding: '3.5vw',
            border: '1vw solid #ffffff',
            boxShadow:
              '0vw -2vw 0vw 0vw rgba(0, 0, 0, 0.25) inset, 0vw 1vw 2vw 0vw rgba(0, 0, 0, 0.25)',
          }}
          onClick={() => {
            cta()
          }}
        >
          올워크 시작하기
        </div>
        <img
          alt=''
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alwalkCashwalk/1.png'
        />
        <img
          alt=''
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alwalkCashwalk/2.png'
        />
        <img
          alt=''
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alwalkCashwalk/3.png'
        />
        <img
          alt=''
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alwalkCashwalk/4.png'
        />
        <img
          alt=''
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alwalkCashwalk/5.png'
        />
        <img
          alt=''
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alwalkCashwalk/6.png'
        />
        <img
          alt=''
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alwalkCashwalk/7.png'
        />
        <img
          alt=''
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alwalkCashwalk/8.png'
        />
        <img
          alt=''
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alwalkCashwalk/9.png'
        />
      </div>
    </>
  )
}

export default AlwalkCashwalkPage
