import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import RouteStore from '../stores/RouteStore'

const RouteController = observer(() => {
  const navigate = useNavigate()

  useEffect(() => {
    if (RouteStore?.navigatable) {
      navigate(RouteStore?.navigatePath)
      RouteStore?.set('navigatable', false)
    }
  }, [RouteStore?.navigatePath])

  return <></>
})

export default RouteController
