import { useSuspenseQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router-dom'
import { css } from 'styled-components'

import RatingComponent from '../../../../components/toon/RatingComponent'
import { useNavigateViewer } from '../../../../hooks/altoon/useNavigateViewer'
import { useNovelChapterPermission } from '../../hooks/useNovelChapterPermission'
import { getNovelChapterInfoQuery } from '../../remotes/novelChapter'
import { viewerStore } from '../../store'
import { DocumentAlign } from '../../types/viewerOptions'
import { BridgeCommentList } from './BridgeCommentList'
import { NextChapterButton } from './NextChapterButton'

export const LastPagePanel = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const { chapterId, novelId } = location.state || {}

  const { data: novelChapter } = useSuspenseQuery(
    getNovelChapterInfoQuery({
      novelId,
      chapterId,
    }),
  )

  const { checkPermission } = useNovelChapterPermission()
  const { navigateToViewer } = useNavigateViewer()

  return (
    <div
      css={css`
        width: 100vw;
        min-height: 100vh;
        overflow-x: hidden;
        overflow-y: ${viewerStore.options.documentAlign ===
        DocumentAlign.Horizontal
          ? css`
              overflow-y: auto;
              height: 100vh;
            `
          : css`
              overflow-y: hidden;
              min-height: 100vh;
            `};
        scroll-snap-align: start;
        background: white;
        padding: 84px 16px;
      `}
    >
      <RatingComponent
        toonId={novelId}
        toonInfo={{}}
        chapterInfo={novelChapter}
        type='novel'
      />

      {novelChapter?.nextChapter && (
        <NextChapterButton
          src={novelChapter.detailThumbnail}
          title={novelChapter?.nextChapter.chapterTitle}
          onClick={async () => {
            if (!novelChapter.nextChapter) return

            const isPermitted = await checkPermission({
              targetChapterId: novelChapter.nextChapter?._id,
            })

            if (!isPermitted) {
              return
            }
            navigateToViewer(
              {
                novelId,
                chapterId: novelChapter.nextChapter?._id,
              },
              {
                replace: true,
              },
            )
          }}
        />
      )}
      {!novelChapter?.nextChapter && (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 500;
            color: #333;
            inline-block;
            width: 40%;
            border: 1px solid #e5e5e5;
            margin-top: 100px;
            margin-left: 30%;
            padding: 10px;
            border-radius: 5px;
            background-color: #f5f5f5;
          `}
          onClick={() => navigate(-1)}
        >
          홈으로 가기
        </div>
      )}

      {/* TODO: 브릿지 댓글 영역은 안드로이드에서 textarea focus시 스크롤되는 이슈로 제거함*/}
      {/* <BridgeCommentList /> */}
    </div>
  )
})
