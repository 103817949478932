import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import AnimatedNumbers from 'react-animated-numbers'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useSearchParam } from 'react-use'
import { Virtuoso } from 'react-virtuoso'

import ExpireHistoryComponent from '../components/deal/item/ExpireHistoryComponent'
import { YellowMoney } from '../images/dailyCheckIn'
import AuthStore from '../stores/AuthStore'
import UserStore from '../stores/UserStore'
import backendApis from '../utils/backendApis'

const ExpireHistoryPage = observer(() => {
  const navigate = useNavigate()

  const [animatePoints, setAnimatePoints] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [accumulatedPoints, setAccumulatedPoints] = useState(0)
  const [expireHistory, setExpireHistory] = useState([])
  const [recentlyEarnedPoints, setRecentlyEarnedPoints] = useState(0)
  const SCREEN_HEIGHT = window.innerHeight
  const year = new Date().getFullYear()
  const month = new Date().getMonth() + 1

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
    if (!AuthStore.token) {
      navigate(`/daily-check-in`)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const pointHistory = await backendApis.getCheckInPoint()
      const expireHistory = await backendApis.getExpiringPoint()
      const monthlyPointHistory = await backendApis.getSixMonthPointHistory()

      if (monthlyPointHistory?.status === 200) {
        setRecentlyEarnedPoints(
          monthlyPointHistory?.data?.[0]?.totalGainedPoints,
        )
      }
      if (pointHistory?.status === 200 && expireHistory?.status === 200) {
        setAccumulatedPoints(pointHistory?.data[0]?.totalCheckInPoints)
        setExpireHistory(expireHistory?.data)
      }
      if (isLoading) {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [AuthStore.token])

  useEffect(() => {
    setTimeout(() => {
      setAnimatePoints(true)
      setAccumulatedPoints(UserStore?.accumulatedPoints)
      setTimeout(() => {
        setAnimatePoints(false)
      }, 3000)
    }, 200)
  }, [UserStore?.accumulatedPoints])

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  const HeaderComponent = () => {
    return (
      <section className='flex flex-row overflow-hidden py-2 px-4 z-0 relative items-center bg-white justify-between'>
        <button
          type='button'
          aria-label='back'
          onClick={() => {
            navigate(`/daily-check-in?token=${AuthStore.token}`)
            // navigate(-1)
          }}
        >
          <img
            src='/images/dailyCheckIn/Arrow_Left.svg'
            alt='설정'
            className='w-7 h-7'
          />
        </button>
        <div
          style={{ fontSize: '4.5vw' }}
          className='p-2 font-bold text-center'
        >
          올포인트 소멸내역
        </div>
        <div className='w-10' />
      </section>
    )
  }

  const PointStatusComponent = () => {
    const userInfo = UserStore.userInfo
    return (
      <div style={{ fontSize: '4.5vw' }} className='w-full flex flex-row'>
        <div className='w-full mt-4 flex flex-col'>
          <div className='flex flex-col items-start justify-center px-[4.5vw] py-[5vw] mx-[4.5vw] mt-2 mb-4 bg-white rounded-xl'>
            <div className='w-full flex items-start justify-between'>
              <div className='flex flex-col items-start justify-center pl-2 text-[4.5vw] font-bold'>
                {userInfo ? `${userInfo.userName}님의 올포인트` : `내 올포인트`}
                <div className='flex flex-row items-end py-1 text-3xl text-[#F8323E] font-bold'>
                  {/* {animatePoints ? ( */}
                  {false ? (
                    <AnimatedNumbers
                      includeComma
                      animateToNumber={accumulatedPoints || 0}
                      fontStyle={{ fontSize: 24 }}
                      configs={[{ mass: 1, tension: 320, friction: 70 }]}
                    />
                  ) : (
                    commaNumber(accumulatedPoints || 0)
                  )}
                  <div className='ml-0.5 '>원</div>
                </div>
              </div>
              <div
                className='flex flex-row items-center text-[4vw] font-normal text-[#9C9DA4] border-[0.5vw] px-4 py-[1.5vw] rounded-full border-[#eeeff3] mt-1'
                onClick={() => {
                  navigate(`/check-in-exchange-history`)
                }}
              >
                교환 내역
                <img
                  src='/images/dailyCheckIn/Arrow_Right.svg'
                  alt='arrow-right'
                  className='ml-2'
                />
              </div>
            </div>
            <div className='flex flex-row items-center justify-between w-full border-t border-[#f6f6fa] pt-4 pb-1 mt-4 text-base'>
              <div className='text-[#464953h]'>{month}월 소멸 예정 포인트</div>

              <div>
                {accumulatedPoints - recentlyEarnedPoints > 0
                  ? `${commaNumber(accumulatedPoints - recentlyEarnedPoints)}P`
                  : '0P'}
              </div>
            </div>
            <div className='w-full text-[#BCBDC3] text-[3.4vw]'>
              <div>
                *획득한지 6개월이 지난 포인트는 매달 말일 새벽에 소멸돼요
              </div>
              <div className='font-light'>
                {' '}
                (예시: 1월 기준, 30일에서 31일이 되는 새벽)
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        backgroundColor: '#F6F6FA',
      }}
    >
      <HeaderComponent />
      <PointStatusComponent />
      <div className='text-xl font-bold pb-4 px-4'>포인트 소멸 내역</div>
      <div className='bg-white rounded-2xl mx-4 px-6'>
        {expireHistory?.filter(
          (item) => item.isExpired === true && !item.deletedAt,
        ).length < 1 && (
          <div className='text-center font-semibold text-lg py-4'>
            아직 소멸된 포인트가 없어요
          </div>
        )}
        <Virtuoso
          data={
            expireHistory?.filter(
              (item) => item.isExpired === true && !item.deletedAt,
            ) || []
          }
          itemContent={(index, data) => {
            return <ExpireHistoryComponent data={data} />
          }}
          style={{
            height: SCREEN_HEIGHT,
            backgroundColor: 'white',
          }}
        />
      </div>

      {isLoading && <LoadingIndicator />}
    </div>
  )
})

export default ExpireHistoryPage
