import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import GroupPurchaseStore from '../../stores/GroupPurchaseStore'
import { sendMessage } from '../../utils/utils'
import Button from './Button'
import GroupPurchaseItem from './GroupPurchaseItem'

const GroupPurchaseItemList = observer(
  ({ displayData, isRewardAvailableList = false }) => {
    if (
      displayData?.length === 0 &&
      GroupPurchaseStore?.rewardAvailableGroupPurchaseList?.length === 0
    ) {
      return (
        <div
          className='flex flex-col justify-center w-full text-center text-[6vw] items-center mt-[10vw]'
          onClick={() => GroupPurchaseStore.createGroupPurchase()}
        >
          <img
            className='w-[30vw] h-[30vw]'
            src='../images/groupPurchase/etc/plus.png'
            alt='plus'
          />
          <div className='flex flex-col'>
            <div>모임구매 성공하고</div>
            <div className='flex flex-row justify-center items-center'>
              <img
                className='w-6 h-6 mr-1'
                alt='Close'
                src='../images/groupPurchase/icon/coin.png'
              />
              <strong>현금 리워드</strong> &nbsp;받기
            </div>
          </div>
        </div>
      )
    }

    return (
      <div
        className={`flex flex-col w-full flex-1 p-4 pb-[${
          isRewardAvailableList ? 0 : 8
        }vw]`}
      >
        {displayData?.map((item, index) => {
          return (
            <GroupPurchaseItem
              key={`${index}gpi`}
              item={item}
              index={index}
              isRewardAvailableList={isRewardAvailableList}
            />
          )
        })}
        {GroupPurchaseStore?.displayType === 'history' &&
          !isRewardAvailableList && (
            <Button.Normal
              text='더 불러오기'
              className={`flex-1 pt-[2vw] pb-[2vw] border-[2px] inverted ${
                !GroupPurchaseStore?.isLoadMoreAvailable && 'disabled'
              }`}
              onClick={async () => {
                await GroupPurchaseStore?.fetchFinishedGroupPurchase()
                GroupPurchaseStore.changeDisplayList({ type: 'history' })
              }}
            />
          )}
      </div>
    )
  },
)

export default GroupPurchaseItemList
