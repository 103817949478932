import React, { useEffect, useState } from 'react'
import { MdArrowForwardIos as ArrowRight } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import useInterval from '../../../hooks/useInterval'
import dealImages from '../../../images/deal'
import ItemImpressionStore from '../../../stores/ItemImpressionStore'
import UserStore from '../../../stores/UserStore'
import TextTimer from '../../TextTimer'

const EnteranceSection = ({ game, reward }) => {
  const endedAt = new Date(new Date().getTime() + 1000 * 10)
  const [open, setOpen] = useState(true)
  const navigate = useNavigate()
  const infoByGame = (game) => {
    if (game === 'rockPaperScissors')
      return {
        text: (
          <div>
            지금 <span className='font-bold text-white'>가위바위보</span> 한 판
            어때요?
          </div>
        ),
        router: '/deal/rock-paper-scissors',
      }
    if (game === 'randomPick')
      return {
        text: (
          <div>
            지금 <span className='font-bold text-white'>뽑기</span> 한 번
            하실래요?
          </div>
        ),
        router: '/deal/random-pick',
      }
  }
  const info = infoByGame(game)
  useInterval(() => {
    if (endedAt <= new Date()) setOpen(false)
  }, 1000)
  return (
    <>
      {open && (
        <div
          className='px-4 py-4 bg-blue-400 animate-pulse'
          aria-hidden
          onClick={() => {
            UserStore?.setTargetEventDeal('done')
            navigate(info?.router)
          }}
        >
          <div className='flex flex-row items-center justify-between flex-1'>
            <div className='flex flex-row items-center text-white'>
              <img alt='fire' src={dealImages?.Fire} className='w-5 h-5 mr-1' />
              <span>{info?.text}</span>
            </div>
            <div>
              <div className='flex flex-row items-center text-white'>
                <TextTimer
                  endedAt={endedAt}
                  boxCss='px-1 text-gray-600 text-xs bg-white rounded'
                  dotCss='mx-1 text-base'
                />
                <ArrowRight />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default EnteranceSection
