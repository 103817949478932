import React, { useEffect, useState } from 'react'

import AltoonUserStore from '../../stores/AltoonUserStore'
import backendApis from '../../utils/backendApis'
import ToonModal from '../templates/ToonModal'

// 백엔드 API 호출 함수들

// 사용자 정보 접근을 위한 스토어

const RatingComponent = ({ toonId, toonInfo, chapterInfo, type = 'toon' }) => {
  const [chapterRating, setChapterRating] = useState(null)
  const [openRatingModal, setOpenRatingModal] = useState(false)

  useEffect(() => {
    // 별점 데이터 로딩 로직
    const fetchRating = async () => {
      try {
        const response = await backendApis.loadToonRating(toonId)
        if (response.status === 200 && response.data) {
          const tempChapterRating = response.data?.find(
            (item) => item.chapterId === chapterInfo._id,
          )
          setChapterRating(tempChapterRating?.ratingScore || 0)
        } else {
          console.error('Failed to load rating')
        }
      } catch (error) {
        console.error('Error fetching rating:', error)
      }
    }

    fetchRating()
  }, [openRatingModal, toonId, chapterInfo])

  if (type === 'novel') {
    return (
      <>
        <div className='flex flex-row justify-between px-4 pb-[6vw]'>
          <div
            className={`flex flex-row items-center text-[4vw] italic font-bold ${
              chapterRating?.ratingScore === 0 ? 'text-gray-400' : ''
            }`}
          >
            <img
              alt=''
              style={{
                marginRight: 4,
                width: '6vw',
                height: '6vw',
              }}
              src='/images/toon/star.png'
            />
            {chapterInfo?.chapterRatingCount === 0
              ? '0.00 '
              : Math.round(
                  (chapterInfo?.chapterRatingScore /
                    chapterInfo?.chapterRatingCount) *
                    100,
                ) / 100 || '0.00'}
          </div>
          <button
            type='button'
            onClick={() => setOpenRatingModal(true)}
            className='self-end text-center px-2 py-2 w-[20vw] border border-black font-semibold text-[3vw] rounded whitespace-nowrap'
          >
            {chapterRating > 0 ? `내 별점: ${chapterRating}점` : '별점주기'}
          </button>
          {openRatingModal && (
            <ToonModal
              modalName='ratingChapter'
              toonId={toonId}
              coinCount={
                AltoonUserStore?.userData?.toonCoin > 0
                  ? AltoonUserStore?.userData?.toonCoin
                  : 0
              }
              modalProp={{
                chapterId: chapterInfo._id,
                modalTop: `${window.scrollY + 200}px`,
                ratingScore: chapterRating,
                toonInfo,
                type,
              }}
              setOpenTicketModal={setOpenRatingModal}
              modalTop='50%'
            />
          )}
        </div>
      </>
    )
  }
  return (
    <>
      <div className='flex flex-row justify-between px-4 pt-6'>
        <div
          className={`flex flex-row items-center text-[4vw] italic font-bold ${
            chapterRating?.ratingScore === 0 ? 'text-gray-400' : ''
          }`}
        >
          <img
            alt=''
            style={{
              marginRight: 4,
              width: '6vw',
              height: '6vw',
            }}
            src='/images/toon/star.png'
          />
          {chapterInfo?.chapterRatingCount === 0
            ? '0.00 '
            : Math.round(
                (chapterInfo?.chapterRatingScore /
                  chapterInfo?.chapterRatingCount) *
                  100,
              ) / 100 || '0.00'}
        </div>
        <button
          type='button'
          onClick={() => setOpenRatingModal(true)}
          className='self-end text-center px-2 py-2 w-1/5 border border-black font-semibold text-[3vw] rounded whitespace-nowrap'
        >
          {chapterRating > 0 ? `내 별점: ${chapterRating}점` : '별점주기'}
        </button>
        {openRatingModal && (
          <ToonModal
            modalName='ratingChapter'
            toonId={toonId}
            coinCount={
              AltoonUserStore?.userData?.toonCoin > 0
                ? AltoonUserStore?.userData?.toonCoin
                : 0
            }
            modalProp={{
              chapterId: chapterInfo._id,
              modalTop: `${window.scrollY + 200}px`,
              ratingScore: chapterRating,
              toonInfo,
              type,
            }}
            setOpenTicketModal={setOpenRatingModal}
            modalTop='50%'
          />
        )}
      </div>
    </>
  )
}

export default RatingComponent
