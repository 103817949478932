import styled from 'styled-components';
import { Theme } from '../../types/viewerOptions';
import { themeColors } from '../../utils/viewerOptions';

interface OptionThemeSelectorProps {
  label: string;
  value: Theme;
  onSelect: (option: Theme) => void;
}

export const OptionThemeSelector = ({
  label,
  value,
  onSelect,
}: OptionThemeSelectorProps) => {
  return (
    <Option>
      <OptionText>{label}</OptionText>
      <OptionsContainer>
        {Object.values(Theme).map((theme, index) => {
          const isSelected = value === theme;
          return (
            <OptionItem
              key={index}
              onClick={() => onSelect(theme)}
              isSelected={isSelected}
              bgColor={themeColors[theme].bgColor}
              color={themeColors[theme].fontColor}
            >
              가
            </OptionItem>
          );
        })}
      </OptionsContainer>
    </Option>
  );
};

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
`;

const OptionText = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
`;

const OptionsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const OptionItem = styled.div<{
  isSelected: boolean;
  color: string;
  bgColor: string;
}>`
  padding: 3px 8px;
  background-color: ${({ bgColor }) => bgColor};
  border: ${({ isSelected }) =>
    isSelected ? '1px solid #FF3E3E' : '1px solid #FFFFFF'};
  border-radius: 4px;
  box-sizing: border-box;

  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  color: ${({ color }) => color};

  display: flex;
  align-items: center;
`;
