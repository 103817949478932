import { useState, MouseEvent } from 'react';
import { viewerStore } from '../store';
import { DocumentAlign, TransitionEffect } from '../types/viewerOptions';
import { sleep } from '../utils/sleep';

export const useNavigation = () => {
  const [fading, setFading] = useState(false);

  const navigateLeft = async () => {
    // console.log('navigateLeft: ', viewerStore.currentPage);
    if (viewerStore.currentPage <= 0) return;

    const previousPage = viewerStore.currentPage - 1;
    const scrollToPosition = previousPage * window.innerWidth;

    if (viewerStore.options.transitionEffect === TransitionEffect.Fade) {
      setFading(true);
      await sleep(200);
      setFading(false);
    }

    viewerStore.viewport?.scrollTo({
      left: scrollToPosition,
      behavior:
        viewerStore.options.transitionEffect === TransitionEffect.Slide
          ? 'smooth'
          : 'auto',
    });
  };

  const navigateRight = async () => {
    if (viewerStore.currentPage >= viewerStore.maxPage) return;

    const nextPage = viewerStore.currentPage + 1;
    const scrollToPosition = nextPage * window.innerWidth;

    if (viewerStore.options.transitionEffect === TransitionEffect.Fade) {
      setFading(true);
      await sleep(200);
      setFading(false);
    }

    // console.log(
    //   'navigateRight: ',
    //   viewerStore.options.transitionEffect === TransitionEffect.Slide
    // );

    viewerStore.viewport?.scrollTo({
      left: scrollToPosition,
      behavior:
        viewerStore.options.transitionEffect === TransitionEffect.Slide
          ? 'smooth'
          : 'auto',
    });
  };

  const handlePageClick = (e: MouseEvent<HTMLDivElement>) => {
    // console.log('handlePageClick: ', viewerStore.options.documentAlign);

    if (viewerStore.options.documentAlign === DocumentAlign.Horizontal) {
      const clickPosition = e.clientX;
      if (clickPosition < window.innerWidth / 3) {
        navigateLeft(); // Left side click
      } else if (clickPosition > (window.innerWidth * 2) / 3) {
        navigateRight(); // Right side click
      } else {
        viewerStore.toggleIsControllerFocused(); // Center click
      }
    } else if (viewerStore.options.documentAlign === DocumentAlign.Vertical) {
      viewerStore.toggleIsControllerFocused(); // Toggle controller on vertical alignment
    }
  };

  return {
    fading,
    navigateLeft,
    navigateRight,
    handlePageClick,
  };
};
