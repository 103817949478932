import React, { useEffect, useState } from 'react'
import { useSearchParam } from 'react-use'
import useDebounce from 'src/components/useDebounce'
import backendApis from 'src/utils/backendApis'
import { URLList } from 'src/utils/coffeeURLList'

import FertilizerBottomSheet from '../components/alfarmTrial/FertilizerBottomSheet'

const LEVEL = {
  level1: 60,
  level2: 460,
}
const BUTTON_SIZE = '18vw'

const AlfarmTrial = () => {
  const channelType = useSearchParam('channelType')
  const [showWelcomeModal, setShowWelcomeModal] = useState(false)
  const [waterAmount, setWaterAmount] = useState(
    window.localStorage.getItem('water')
      ? +window.localStorage.getItem('water')
      : 500,
  )

  const [exp, setExp] = useState(
    window.localStorage.getItem('exp')
      ? +window.localStorage.getItem('exp')
      : 0,
  )
  const [waterEffectOn, setWaterEffectOn] = useState(false)
  const [level, setLevel] = useState(
    window.localStorage.getItem('level')
      ? +window.localStorage.getItem('level')
      : 1,
  )
  const [giveWaterCount, setGiveWaterCount] = useState(
    window.localStorage.getItem('giveWaterCount')
      ? +window.localStorage.getItem('giveWaterCount')
      : 0,
  )
  const [waterEffectURL, setWaterEffectURL] = useState(
    '../images/alfarmTrial/giveWaterEffect.gif',
  )
  const [openFertBottomSheet, setOpenFertBottomSheet] = useState(false)
  const [nutriment, setNutriment] = useState(
    window.localStorage.getItem('nutriment')
      ? +window.localStorage.getItem('nutriment')
      : 70,
  )
  const [fertilizerCount, setFertilizerCount] = useState(
    window.localStorage.getItem('fertilizer')
      ? +window.localStorage.getItem('fertilizer')
      : 2,
  )
  const [fertilizerSetCount, setFertilizerSetCount] = useState(
    window.localStorage.getItem('fertilizerSet')
      ? +window.localStorage.getItem('fertilizerSet')
      : 2,
  )
  const [showLevelUpModal, setShowLevelUpModal] = useState(false)
  const [showIntroModal, setShowIntroModal] = useState(false)
  const [introModalType, setIntroModalType] = useState(
    'url(/images/alfarmTrial/minigameIntroModal.png)',
  )
  const [showInstallNudgeModal, setShowInstallNudgeModal] = useState(false)
  const [didInstallNudeModalCheck, setDidInstallNudeModalCheck] = useState(
    window.localStorage.getItem('didInstallNudeModalCheck')
      ? window.localStorage.getItem('didInstallNudeModalCheck') === 'true'
      : false,
  )

  useEffect(() => {
    if (exp > LEVEL[`level${level}`]) {
      setLevel(2)
      if (!didInstallNudeModalCheck) {
        setShowLevelUpModal(true)
        setFertilizerCount((prev) => prev + 5)
        setWaterAmount((prev) => prev + 1000)
      }
      setGiveWaterCount(20)
    }
  }, [exp])

  useEffect(() => {
    window.localStorage.setItem('exp', exp)
    window.localStorage.setItem('waterAmount', waterAmount)
    window.localStorage.setItem('nutriment', nutriment)
    window.localStorage.setItem('level', level)
    window.localStorage.setItem('giveWaterCount', giveWaterCount)
    window.localStorage.setItem('fertilizerCount', fertilizerCount)
    window.localStorage.setItem('fertilizerSetCount', fertilizerSetCount)
    window.localStorage.setItem(
      'didInstallNudeModalCheck',
      didInstallNudeModalCheck,
    )
  }, [
    exp,
    level,
    waterAmount,
    nutriment,
    giveWaterCount,
    fertilizerCount,
    fertilizerSetCount,
    didInstallNudeModalCheck,
  ])

  useEffect(() => {
    if (waterEffectOn) {
      setTimeout(() => {
        setWaterEffectOn(false)
      }, 1400)
    }
  }, [waterEffectOn])

  useEffect(() => {
    if (giveWaterCount === 0) {
      setShowWelcomeModal(true)
    }
  }, [])

  const debounce = useDebounce()

  const logEvent = async (eventLocation, eventAction) => {
    await backendApis.recordEventLogForWeb('POST', {
      eventLocation,
      eventAction,
    })
  }

  const inputButton = async () => {
    const eventLocation = channelType?.includes('DirectTrial')
      ? 'direct_alfarmTrial'
      : 'alfarmTrial'

    logEvent(eventLocation, 'click start alfarm button')
    window.location.href = URLList[channelType ?? 'basic'] ?? URLList.basic
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundImage: 'url(/images/alfarmTrial/background.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      id='background'
    >
      {/* 위 타이틀 */}
      <div
        style={{
          width: '60vw',
          height: '15vw',
          backgroundImage: 'url(/images/alfarmTrial/title.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          position: 'absolute',
          left: '50%',
          top: '14vw',
          transform: 'translate(-50%,0%)',
        }}
        id='title'
      />

      {/* 작물 & 게이지 */}
      <div
        style={{
          width: '70vw',
          height: '70vw',
          backgroundImage: `url(/images/alfarmTrial/farm/${level}.gif)`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          position: 'absolute',
          left: '50%',
          top: '55%',
          transform: 'translate(-50%,-50%)',
        }}
        id='plant'
      >
        <div
          style={{
            position: 'absolute',
            left: '50%',
            bottom: '5vw',
            transform: 'translate(-50%, 0%)',
          }}
        >
          <div className='gauge-bg'>
            <div
              className='gauge-bar'
              style={{
                width: `${(exp / LEVEL[`level${level}`]) * 100}%`,
              }}
            />
            <div
              className='gauge-exp-up'
              style={{
                width: `${(exp / LEVEL[`level${level}`]) * 98.8}%`,
              }}
            />
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            bottom: '-1vw',
            transform: 'translate(-50%, 0%)',
            fontFamily: 'maplestory',
            fontSize: '0.8rem',
            color: 'brown',
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {`레벨업까지 ${Math.round(
            (1 - exp / LEVEL[`level${level}`]) * 100,
          )}% 남았어요!`}
        </div>
      </div>

      {/* 물주기 이펙트 */}
      {waterEffectOn && (
        <div
          style={{
            width: '35vw',
            height: '70vw',
            position: 'absolute',
            left: '50%',
            top: '55%',
            transform: 'translate(-50%,-50%)',
          }}
          id='plant'
        >
          <img
            src={`${waterEffectURL}?a=${String(Math.random())}`}
            alt=''
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      )}

      {/* 양분 */}
      <div
        style={{
          background: 'linear-gradient(180deg, #F2BA73 0%, #ED7732 100%)',
          border: '2px solid #FFEAB5',
          width: '15vw',
          height: '15vw',
          borderRadius: 100,
          position: 'absolute',
          top: '60%',
          left: '10vw',
          transform: 'translateY(-50%)',
          zIndex: giveWaterCount > 9 && giveWaterCount < 14 ? 101 : 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <div style={{ fontFamily: 'maplestory', color: 'white' }}>양분</div>
          <div
            style={{
              fontFamily: 'maplestory',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '1rem',
            }}
          >
            {nutriment}
          </div>
        </div>
      </div>

      {/* 주전자 */}
      <div
        style={{
          width: '25vw',
          height: '25vw',
          backgroundImage: 'url(/images/alfarmTrial/giveWater.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          position: 'absolute',
          right: 8,
          bottom: 8,
          zIndex:
            giveWaterCount < 2 || (giveWaterCount > 9 && giveWaterCount < 14)
              ? 101
              : 0,
        }}
        id='waterBasket'
        onClick={() => {
          if (!waterEffectOn) {
            setWaterEffectOn(true)
            window.localStorage.setItem('water', waterAmount - 10)
            window.localStorage.setItem(
              'exp',
              exp + (Math.floor(Math.random() * (13 - 10)) + 10),
            )
            setWaterAmount((prev) => prev - 10)
            setExp(
              (prev) => prev + (Math.floor(Math.random() * (13 - 10)) + 10),
            )
            setGiveWaterCount((prev) => prev + 1)
            if (nutriment - 4 > 0) {
              setNutriment((prev) => prev - 4)
            } else {
              setNutriment(0)
            }
          }
        }}
      >
        <div
          style={{
            fontFamily: 'mapleStory',
            position: 'absolute',
            top: '82%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            fontWeight: 'bold',
          }}
        >
          {`${waterAmount}g`}
          {giveWaterCount > 9 && giveWaterCount < 14 && !waterEffectOn && (
            <div
              style={{
                position: 'absolute',
                width: '12vw',
                height: '14vw',
                top: '-40vw',
                left: 0,
                // right: '18vw',
                zIndex: 101,
              }}
            >
              <img
                className='slide-bottom'
                style={{
                  width: '12vw',
                  height: '14vw',
                  position: 'absolute',

                  // transform: 'rotateX( 180deg )',
                }}
                src='../images/alfarmTrial/guideIndicator.png'
                alt=''
              />
              <div
                style={{
                  fontFamily: 'maplestory',
                  fontWeight: 'bold',
                  color: 'white',
                  width: '30vw',
                  textAlign: 'center',
                  position: 'absolute',
                  right: '-6vw',
                  top: '-16vw',
                  height: '20vw',
                  wordBreak: 'keep-all',
                  // backgroundColor: 'black',
                }}
              >
                {`${
                  giveWaterCount > 10
                    ? '물을 줘서 레벨업 해보세요'
                    : '양분이 높으면 경험치 2배!'
                }`}
              </div>
            </div>
          )}
        </div>
        {giveWaterCount < 2 && !waterEffectOn && (
          <div
            style={{
              flex: 1,
              position: 'absolute',

              zIndex: 101,
              fontSize: 18,
              textAlign: 'center',
              width: '12vw',
              height: '14vw',
              top: '-85%',
              left: '25%',
            }}
          >
            <img
              className='slide-bottom'
              style={{
                width: '100%',
                height: '100%',
                // transform: 'rotateX( 180deg )',
              }}
              src='../images/alfarmTrial/guideIndicator.png'
              alt=''
            />
            <div
              style={{
                fontFamily: 'maplestory',
                fontWeight: 'bold',
                color: 'white',
                width: '100vw',
                textAlign: 'center',
                position: 'absolute',
                left: '-70vw',
                top: '-16vw',
                height: '20vw',
              }}
            >
              {`${
                giveWaterCount === 0
                  ? '버튼을 클릭해 커피 새싹에 물을 주세요'
                  : `물을 줘서 경험치가 올랐어요!`
              }`}
            </div>
            <div
              style={{
                fontFamily: 'maplestory',
                fontWeight: 'bold',
                color: 'white',
                width: '100vw',
                textAlign: 'center',
                position: 'absolute',
                left: '-70vw',
                top: '-8vw',
                height: '20vw',
              }}
            >
              {`${giveWaterCount === 0 ? '' : `다시 한번 물을 주세요`}`}
            </div>
          </div>
        )}
      </div>

      {/* 밑에 버튼 */}
      <div
        style={{
          bottom: 0,
          width: '72vw',
          // height: '3vw',
          // backgroundColor: 'white',
          position: 'absolute',
          left: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'space-around',
          justifyContent: 'flex-end',
          marginBottom: 16,
        }}
        id='bottomButtons'
      >
        <div
          style={{
            width: BUTTON_SIZE,
            height: BUTTON_SIZE,
            backgroundImage: 'url(/images/alfarmTrial/feedButton.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          id='feed'
          onClick={() => {
            setIntroModalType('url(/images/alfarmTrial/feedIntroModal.png)')
            setShowIntroModal(true)
          }}
        />
        <div
          style={{
            zIndex: giveWaterCount >= 2 && giveWaterCount < 10 ? 101 : 1,
            width: BUTTON_SIZE,
            height: BUTTON_SIZE,
            backgroundImage: 'url(/images/alfarmTrial/fertButton.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          id='fertilizer'
          onClick={() => {
            setOpenFertBottomSheet(!openFertBottomSheet)
          }}
        >
          {giveWaterCount === 2 && !waterEffectOn && (
            <div
              style={{
                flex: 1,
                position: 'absolute',

                zIndex: 101,
                fontSize: 18,
                textAlign: 'center',
                width: '12vw',
                height: '14vw',
                top: '-22vw',
                left: '21vw',
              }}
            >
              <img
                className='slide-bottom'
                style={{
                  width: '100%',
                  height: '100%',
                  // transform: 'rotateX( 180deg )',
                }}
                src='../images/alfarmTrial/guideIndicator.png'
                alt=''
              />
              <div
                style={{
                  fontFamily: 'maplestory',
                  fontWeight: 'bold',
                  color: 'white',
                  width: '100vw',
                  textAlign: 'center',
                  position: 'absolute',
                  left: '-25vw',
                  top: '-16vw',
                  height: '20vw',
                }}
              >
                이제 비료를 받아볼까요?
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            width: BUTTON_SIZE,
            height: BUTTON_SIZE,
            backgroundImage: 'url(/images/alfarmTrial/waterButton.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          id='water'
          onClick={() => {
            setIntroModalType('url(/images/alfarmTrial/waterIntroModal.png)')
            setShowIntroModal(true)
          }}
        />
        <div
          style={{
            width: BUTTON_SIZE,
            height: BUTTON_SIZE,
            backgroundImage: 'url(/images/alfarmTrial/minigameButton.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          id='minigame'
          onClick={() => {
            setIntroModalType('url(/images/alfarmTrial/minigameIntroModal.png)')

            setShowIntroModal(true)
          }}
        />
      </div>

      {/* 비료받기 바텀싯 */}
      {openFertBottomSheet && (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.7)',
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: 101,
          }}
          onClick={() => {
            // console.log('1')
            // setOpenFertBottomSheet(false)
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '100vw',
              // height: '400px',
              backgroundColor: 'white',
              bottom: 0,
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
            }}
            onClick={() => {
              // console.log('stop')
            }}
          >
            <FertilizerBottomSheet
              nutriment={nutriment}
              giveWaterCount={giveWaterCount}
              setOpenFertBottomSheet={setOpenFertBottomSheet}
              fertilizerCount={fertilizerCount}
              fertilizerSetCount={fertilizerSetCount}
              setFertilizerCount={setFertilizerCount}
              setFertilizerSetCount={setFertilizerSetCount}
              setNutriment={setNutriment}
              setGiveWaterCount={setGiveWaterCount}
              setIntroModalType={setIntroModalType}
              setShowIntroModal={setShowIntroModal}
            />
          </div>
        </div>
      )}

      {/* 튜토 가림막 */}
      {giveWaterCount < 14 && !waterEffectOn && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            backgroundColor: 'rgba(0,0,0,0.7)',
            zIndex: 100,
          }}
        />
      )}

      {/* 레벨업 모달 */}
      {showLevelUpModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 999,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.7)',
          }}
        >
          <div
            style={{
              position: 'fixed',
              zIndex: 1000,
              top: '9%',
              width: '40%',
              left: '30%',
            }}
            className='scale-up-center'
          >
            <img alt='' src='../images/alfarmTrial/farm/2.gif' />
          </div>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <div
              style={{
                position: 'relative',
                // backgroundColor: "blue",
                zIndex: 999,
                left: 0,
                top: '17%',
                height: window.innerWidth * 1.24,
              }}
              className='scale-up-hor-center'
            >
              <img
                style={{
                  zIndex: 999,
                  bottom: 0,
                  width: '90%',
                  left: '5%',
                  position: 'absolute',
                }}
                src='../images/alfarmTrial/Ready6.png'
                alt=''
              />

              <button
                style={{
                  width: '85%',
                  bottom: '0%',
                  height: '15%',
                  left: '7%',
                  borderRadius: 30,
                  zIndex: 999,
                  position: 'absolute',
                }}
                onClick={() => {
                  setShowLevelUpModal(false)
                  setShowInstallNudgeModal(true)
                  setDidInstallNudeModalCheck(true)
                }}
                className='scale-up-center-level-up-button'
              >
                <img src='../images/alfarmTrial/levelRewardButton.png' alt='' />
              </button>
            </div>
          </div>
        </div>
      )}

      {/* 설명 모달 */}
      {showIntroModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 999,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.7)',
          }}
        >
          <div
            style={{
              width: '80%',
              height: '100%',
              backgroundImage: introModalType,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            onClick={() => {
              setShowIntroModal(false)
            }}
          />
        </div>
      )}

      {/* 설치 넛지모달 */}
      {showInstallNudgeModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 999,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.7)',
          }}
        >
          <div
            style={{
              width: '300px',
              height: '600px',
              backgroundImage: 'url(/images/alfarmTrial/installNudgeModal.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
              position: 'absolute',
            }}
          >
            <div
              style={{
                // backgroundColor: 'red',
                width: '100%',
                height: '50px',
                position: 'absolute',
                bottom: 100,
              }}
              onClick={() => {
                debounce(inputButton, 500)
              }}
            />
            <div
              style={{
                // backgroundColor: 'white',
                width: '100%',
                height: '40px',
                position: 'absolute',
                bottom: 59,
              }}
              onClick={() => {
                setShowInstallNudgeModal(false)
              }}
            />
          </div>
        </div>
      )}

      {/* 환영 모달 */}
      {showWelcomeModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 999,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.7)',
          }}
        >
          <div
            style={{
              width: '80%',
              height: '100%',
              backgroundImage: 'url(/images/alfarmTrial/welcomeModal.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            onClick={() => {
              setShowWelcomeModal(false)
            }}
          />
        </div>
      )}

      {/* 앱설치 버튼 */}
      {giveWaterCount >= 20 && didInstallNudeModalCheck === true && (
        <div
          style={{
            position: 'fixed',
            top: '35vw',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <button
            style={{
              backgroundColor: '#FFCA28',
              border: '2px solid #C1650A',
              width: '80vw',
              height: '17vw',
              borderRadius: 99,
              fontFamily: 'maplestory',
              // fontWeight: 'bold',
              // color: 'white',
              textAlign: 'center',

              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '1.2rem',
            }}
            className='shake-bottom'
            onClick={async () => {
              debounce(inputButton, 500)
            }}
          >
            공짜 스타벅스 커피 키우기
          </button>
          <div
            style={{
              backgroundImage: 'url(/images/alfarmTrial/finger.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              position: 'absolute',
              width: '14vw',
              height: '14vw',
              right: '-6vw',
              bottom: '-4vw',
            }}
            className='shake-bottom'
            onClick={async () => {
              debounce(inputButton, 500)
            }}
          />
        </div>
      )}

      {/* 앱설치 혜택 진입 아이콘 */}
      {didInstallNudeModalCheck === true && (
        <div
          style={{
            width: '20vw',
            height: '20vw',
            backgroundImage: 'url(/images/alfarmTrial/bonusEntryIcon.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'fixed',
            right: 16,
            bottom: '50vw',
          }}
          onClick={() => {
            setShowInstallNudgeModal(true)
          }}
        />
      )}

      {/* 뒤로가기 */}
      <div
        style={{
          width: '8vw',
          height: '8vw',
          backgroundImage: 'url(/images/alfarmTrial/back.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          position: 'fixed',
          top: '16vw',
          left: '6vw',
        }}
        onClick={() => {
          window.location.href = `/alfarmCoffeeBridgePage?channelType=${channelType}`
        }}
      />

      {/* 스킵하기 */}
      {giveWaterCount < 20 && (
        <div
          style={{
            width: '20vw',
            height: '10vw',
            zIndex: '999',
            position: 'fixed',
            top: '16vw',
            right: 0,
            fontWeight: 'bold',
            fontFamily: 'maplestory',
            color: 'white',
          }}
          onClick={() => {
            setGiveWaterCount(20)
            setShowWelcomeModal(false)
            setShowInstallNudgeModal(true)
            setDidInstallNudeModalCheck(true)
          }}
        >
          스킵하기
        </div>
      )}
    </div>
  )
}

export default AlfarmTrial
