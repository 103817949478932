import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import {
  EmptyProfileImage,
  FireIcon,
  NewTeammateBadge,
  NewTeammateNudge2,
  OrangeMe,
  RedPoint,
} from 'src/images/dailyCheckIn'

const TeamCheckInInviteNudgeModal = observer(
  ({
    visible,
    close,
    action,
    goBackToMain,
    joinTeam,
    leaveTeam,
    checkInInvitorName,
    checkInInvitorId,
    isLeftUser = undefined,
    isNewUser,
    userInfo,
    notCheckedInToday,
    displayName,
    newTeammateCount,
    teamGathered,
    filteredTeamInfo,
  }) => {
    const MyInfoComponent = () => {
      return (
        <div className='px-4 py-3 flex flex-row justify-between items-center'>
          <div className='relative flex flex-row items-center'>
            <div
              style={{
                width: 32,
                height: 32,
                borderRadius: 28,
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                borderWidth: 2,
                borderColor: 'orange',
              }}
            >
              <img src={userInfo?.thumbnailUserImageUri} alt='' />
            </div>
            <div className='absolute left-2 -bottom-1'>
              <OrangeMe className='w-4 h-4' />
            </div>
            <div className='flex flex-col ml-2'>
              <div className='font-bold text-sm'>{userInfo?.userName}</div>
              {filteredTeamInfo?.length > 1 && (
                <div className='text-gray-800 font-medium text-xs'>
                  0회 출석
                </div>
              )}
            </div>
          </div>
          {teamGathered && (
            <button
              className={`px-3 py-2 ${
                notCheckedInToday ? 'bg-[#EB4F47]' : 'bg-gray-300'
              } text-white text-sm font-bold rounded-lg`}
              disabled={!notCheckedInToday}
            >
              {notCheckedInToday ? '출석하기' : '출석완료'}
            </button>
          )}
        </div>
      )
    }
    const TeammateComponent = ({ userData }) => {
      const lastCheckedInAt =
        moment(userData.lastCheckedInAt).format('D') || new Date(0)
      const now = moment().format('D')

      return (
        <div className='px-1 mx-3 py-3 border-t flex flex-row justify-between items-center'>
          <div className='flex flex-row items-center'>
            <div
              style={{
                width: 32,
                height: 32,
                borderRadius: 28,
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img src={userData?.profileUrl} alt='Teammate' />
            </div>
            <div className='flex flex-col ml-2'>
              <div className='flex flex-row items-center'>
                <div className='font-bold text-sm'>{userData?.userName}</div>
                {userData?.isNewUser && (
                  <NewTeammateBadge className='ml-2 w-12' />
                )}
              </div>
              {filteredTeamInfo?.length > 1 && (
                <div className='text-gray-800 font-medium text-xs'>
                  {userData?.checkInCount}회 출석
                </div>
              )}
            </div>
          </div>
          {teamGathered && (
            <button
              className={`px-3 py-2 ${
                lastCheckedInAt === now ? 'bg-gray-300' : 'bg-[#EB4F47]'
              } text-white text-sm font-bold rounded-lg`}
              disabled={lastCheckedInAt === now}
            >
              {lastCheckedInAt === now ? '출석완료' : '콕찌르기'}
            </button>
          )}
        </div>
      )
    }
    const EmptyComponent = () => {
      return (
        <button className='px-1 mx-3 py-3 border-t flex flex-row justify-between items-center text-sm font-bold'>
          <div className='flex flex-row items-center'>
            <EmptyProfileImage className='w-8' />
            <div className='mx-2'>팀원 초대하기</div>
          </div>
          <RedPoint className='animate-pulse w-8' />
        </button>
      )
    }

    const TeamListComponent = () => {
      return (
        <div className='relative flex flex-col rounded-xl w-full'>
          <div className='flex flex-row items-center '>
            {displayName && (
              <div className='px-4 py-2 text-base font-bold'>
                {displayName}팀
              </div>
            )}
            {newTeammateCount > 0 && (
              <div className='py-2 flex flex-row items-end text-sm text-red-500 font-bold'>
                <FireIcon className='animate-pulse w-6' />
                보너스 {newTeammateCount === 2 ? '50' : '10'}배!
              </div>
            )}
          </div>
          <MyInfoComponent />

          {filteredTeamInfo?.map((member) => (
            <TeammateComponent key={member.userId} userData={member} />
          ))}
          {Array.from({ length: 2 - filteredTeamInfo.length })?.map(() => (
            <EmptyComponent />
          ))}
        </div>
      )
    }

    const MainTextComponent = () => {
      return (
        <div className='flex flex-col justify-center items-center text-center text-xl'>
          <NewTeammateNudge2 className='w-72' />
          <div className=''>팀을 이뤄 출석체크하고</div>
          <div className='flex flex-row'>
            <div className='text-red-500'>보너스 포인트</div>를 받으세요!
          </div>
        </div>
      )
    }

    const ButtonComponent = () => {
      return (
        <button
          className='fixed z-40 mt-2 py-4 w-5/6 bg-red-500 rounded font-bold text-lg text-white'
          style={{ top: '55vh', left: '8%' }}
          onClick={() => {
            close()
          }}
        >
          확인
        </button>
      )
    }

    return (
      <button onClick={close}>
        {/* 딤드 배경 */}
        <div className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-white opacity-96 z-10' />

        {/* 모달 상자 */}
        <div
          style={{
            left: '8%',
            top: '25vh',
            borderWidth: 1,
            borderColor: 'red',
            height: 212,
          }}
          className='w-5/6 fixed bg-white z-20 rounded-xl'
        >
          <TeamListComponent />
        </div>
        <ButtonComponent />

        {/* 모달 내용 + 받기 버튼 */}
        <div
          style={{ left: '14vw', top: '12vh' }}
          className='fixed z-30 font-bold'
        >
          <MainTextComponent />
        </div>
      </button>
    )
  },
)

export default TeamCheckInInviteNudgeModal
