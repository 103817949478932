import { action, makeObservable, observable } from 'mobx'
import { v4 as uuid } from 'uuid'

import backendApis from '../utils/backendApis'

class ItemImpressionStore {
  screenResetCount = 0
  previousIsFrom = null
  impressionId = `webscreen_${uuid()}`
  currentVisibleImpressions = {}
  impressionBuffer = []
  journeyBlockBase = 'webscreen'
  journeyBlock = ''

  constructor() {
    makeObservable(this, {
      screenResetCount: observable,
      previousIsFrom: observable.ref,
      impressionId: observable.ref,
      currentVisibleImpressions: observable.ref,
      impressionBuffer: observable.ref,
      journeyBlock: observable.ref,
      journeyBlockBase: observable.ref,
      increaseScreenResetCount: action,
      setPreviousIsFrom: action,
      setImpressionId: action,
      setCurrentVisibleImpressions: action,
      setImpressionBuffer: action,
      setJourneyBlock: action,
      setJourneyBlockBase: action,
    })
  }

  increaseScreenResetCount(value = 1) {
    this.screenResetCount += value
  }

  setPreviousIsFrom(previousIsFrom) {
    this.previousIsFrom = previousIsFrom
  }

  setImpressionId(impressionId) {
    this.impressionId = impressionId
  }

  setCurrentVisibleImpressions(currentVisibleImpressions) {
    this.currentVisibleImpressions = currentVisibleImpressions
  }

  setImpressionBuffer(impressionBuffer) {
    this.impressionBuffer = impressionBuffer
  }

  setJourneyBlock(journeyBlock) {
    this.journeyBlock = journeyBlock
  }

  setJourneyBlockBase(journeyBlockBase) {
    this.journeyBlockBase = journeyBlockBase
  }

  /// business logic

  createBaseImpression(
    isFrom,
    index,
    itemId,
    itemTitle,
    recoMeta,
    loggedAt = new Date(),
  ) {
    return {
      isFrom,
      index,
      itemId,
      itemTitle,
      recoMeta,
      loggedAt,
      previousIsFrom: this.previousIsFrom,
      journeyBlock: this.journeyBlock || this.journeyBlockBase,
    }
  }

  updateVisibleImpressions(isFromKey, newImpressionsObj) {
    const existingImpressionsObj = this.currentVisibleImpressions[isFromKey]

    // check existing impressions, pass on remaining impressions, save removed impressions
    if (existingImpressionsObj) {
      for (const [index, existingImp] of Object.entries(
        existingImpressionsObj,
      )) {
        const newImp = newImpressionsObj[index]
        if (
          newImp &&
          newImp.itemId.toString() === existingImp.itemId.toString()
        ) {
          // keeping impression
          newImp.loggedAt = existingImp.loggedAt
        } else {
          // removing impression
          existingImp.leftAt = new Date()

          console.debug(
            `New impression ${isFromKey} ${index}\n (prev ${existingImp.previousIsFrom}, journey ${existingImp.journeyBlock},\n from ${existingImp.loggedAt} ~ ${existingImp.leftAt})`,
          )
          this.impressionBuffer.push(existingImp)
        }
      }
    }

    if (!newImpressionsObj || Object.keys(newImpressionsObj).length > 0) {
      this.setCurrentVisibleImpressions({
        ...this.currentVisibleImpressions,
        [isFromKey]: newImpressionsObj,
      })
    } else {
      delete this.currentVisibleImpressions[isFromKey]
    }
  }

  onVisibleItemsChange(isFrom, randomId, visibleItemsData) {
    const isFromKey = `${isFrom}_${randomId}`

    // make visible impressions
    const newImpressions = visibleItemsData.map((itemData) => {
      const index = itemData?.index
      const itemId = itemData?.itemId
      const itemTitle = itemData?.itemTitle
      const recoMeta = itemData?.recoMeta

      if (index == null || !itemId || !itemTitle) {
        console.warn(`onVisibleItemsChange: invalid itemData`)
        // send Sentry error
        return null
      }
      return this.createBaseImpression(
        isFrom,
        index,
        itemId,
        itemTitle,
        recoMeta,
      )
    })

    const newImpressionsObj = newImpressions.reduce((acc, imp) => {
      acc[imp.index] = imp
      return acc
    }, {})

    this.updateVisibleImpressions(isFromKey, newImpressionsObj)
  }

  clearVisibleImpressions(isFrom, randomId) {
    // run when item list unloads
    const isFromKey = `${isFrom}_${randomId}`
    console.debug(`clearVisibleImpressions ${isFromKey}`)
    this.updateVisibleImpressions(isFromKey, {})
  }

  clearAllVisibleImpressions() {
    console.debug('clearAllVisibleImpressions')
    for (const isFromKey of Object.keys(this.currentVisibleImpressions)) {
      this.updateVisibleImpressions(isFromKey, {})
    }
  }

  resetImpressionId() {
    this.setImpressionId(`webscreen_${uuid()}`)
  }

  /// apis

  async uploadAndClearImpressions() {
    // run when visits item / exits webscreen / exits app
    this.clearAllVisibleImpressions()
    console.debug(
      `uploadAndClearImpressions; Should log ${this.impressionBuffer.length} impressions`,
    )
    if (this.impressionBuffer.length === 0) return

    // console.debug(JSON.stringify(this.impressionBuffer).slice(0, 500))
    const result = await backendApis.uploadUserImpressions({
      impressionId: this.impressionId,
      impressionItems: this.impressionBuffer,
    })
    if (result?.status === 200) {
      this.setImpressionBuffer([])
    } else {
      console.warn(`uploadAndClearImpressions failed ${result}`)
      // Sentry error log
    }
  }
}

export default new ItemImpressionStore()
