import { css } from 'styled-components'

import { Flex } from '../Flex'

export const CommentSkeleton = () => {
  return (
    <Flex.Row
      css={css`
        justify-content: center;
        align-items: center;
        height: 300px;
        width: 100%;
      `}
    >
      <div
        css={css`
          //styleName: Body 1/Medium;
          /* font-family: Pretendard Variable; */
          font-size: 18px;
          font-weight: 500;
          line-height: 25.2px;
          letter-spacing: -0.20000000298023224px;
          text-align: center;
          color: #bcbdc3;
        `}
      >
        댓글이 없어요.
        <br />첫 댓글을 남겨보세요!
      </div>
    </Flex.Row>
  )
}
