import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import Button from '../../../pages/EggMoney/Button'
import THModalStore from '../../../stores/treasureHunt/THModalStore'

function renderDescription(text) {
  return text?.split('\n').map((line, index) => (
    <span key={Math.random()}>
      {line}
      {index < text?.split('\n')?.length - 1 && <br />}
    </span>
  ))
}

const EggMoneyOrderCompleteModal = ({ config }) => {
  const [, set] = useState()

  return (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw] mb-4'>
        {config?.title}
      </div>
      <div className='bg-[#F6F6FA] rounded-2xl p-0 w-1/2 mb-4 flex justify-center items-center relative'>
        <img
          alt='main'
          src={config.src}
          className={`${config?.imgClassName}`}
        />
        <img
          alt='main'
          src={`../images/eggMoney/${config?.mode}Tag.png`}
          className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        />
      </div>
      <div className='font-bold text-lg text-center'>
        {renderDescription(config?.desc ?? '')}
      </div>

      <Button
        className='w-full py-3 mt-6'
        clickable={config.clickable}
        text={config?.buttonText ?? '확인'}
        onClick={() => {
          config.onConfirm()
          THModalStore?.close()
        }}
      />
    </div>
  )
}

export default observer(EggMoneyOrderCompleteModal)
