import React, { useEffect, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'

import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'

export default function VerticalList({
  itemData,
  height = '100%',
  renderItem = () => {},
  loadMore = () => {},
  columnCount = 1,
  overscan = 5,
  isFrom = null,
  isItemList = true,
}) {
  const randomId = Math.random().toString(36).slice(2, 10)
  const columnWidth = `${Math.floor(100 / columnCount)}%`
  const [isScrolling, setIsScrolling] = useState(false)
  const [currentTouchCount, setCurrentTouchCount] = useState(0)
  const [isScrollTouchActive, setIsScrollTouchActive] = useState(false)
  const [isScrollDelay, setIsScrollDelay] = useState(false)
  const [itemDataHash, setItemDataHash] = useState('')
  const [currentRange, setCurrentRange] = useState({
    startIndex: 0,
    endIndex: -1,
  })
  const [groupedItemData, setGroupedItemData] = useState([])
  const [impData, setImpData] = useState([])

  const RowWrapper = (index, data, isScrolling) => {
    const emptyGroup = Array.from({ length: columnCount }).map((_, i) => ({
      index: index + i,
      data: null,
    }))
    const itemGroup = data || emptyGroup

    return (
      <div className='flex flex-row'>
        {itemGroup.map(({ index, data }) => {
          return (
            <div key={index} style={{ width: columnWidth }}>
              {renderItem(
                index,
                data,
                isScrolling || isScrollDelay || isScrollTouchActive,
              )}
            </div>
          )
        })}
      </div>
    )
  }

  const getItemArrayHash = (itemData) => {
    return itemData?.slice(0, 4).reduce((acc, cur) => {
      return `${acc}-${cur.itemId}`
    }, '')
  }

  function onVisibleRangeChangeFactory(
    isFrom,
    randomId,
    itemData,
    setCurrentRange,
  ) {
    const onVisibleRangeChange = ({ startIndex, endIndex }) => {
      if (!itemData || !isFrom) return
      setCurrentRange({ startIndex, endIndex })

      if (itemData.length === 0) {
        // will run again after itemData is loaded
        return
      }

      // push all visible items
      const visibleItemData = []
      for (let i = startIndex; i <= endIndex; i += 1) {
        const itemId = itemData[i]?.id
        const itemName = itemData[i]?.name
        const itemImpUrl = itemData[i]?.impression_urls
        const itemType = itemData[i]?.type
        const itemReward = itemData[i]?.reward
        const itemRewardPoint = itemData[i]?.rewardPoint
        const itemCheckParticipationUrl = itemData[i]?.check_participation_url

        if (itemId && itemImpUrl) {
          const currentItemData = {
            index: i,
            itemId,
            itemName,
            itemImpUrl,
            itemType,
            itemReward,
            itemRewardPoint,
            itemCheckParticipationUrl,
          }
          visibleItemData.push(currentItemData)
        }
      }

      const impDataSet = new Set(impData)
      const filteredVisibleItemData = visibleItemData.filter(
        (e) => !impDataSet.has(e?.itemId),
      )

      // imp 로그 남긴 데이터와 다른 것들만 로깅
      if (filteredVisibleItemData?.length > 0) {
        for (const item of filteredVisibleItemData) {
          // buzzvil imp log
          const impUrls = item?.itemImpUrl
          for (const url of impUrls) {
            fetch(url)
          }

          const impressionLog = {
            eventType: 'ad_impression',
            userId: UserStore.userInfo._id,
            uniqueUserId: UserStore.offerwallInfo.uniqueUserId,
            unitId: UserStore.offerwallInfo.unitId,
            networkName: UserStore.offerwallInfo.networkName,
            placement: UserStore.offerwallInfo.placement,
            adId: item?.itemId,
            adType: item?.itemType,
            reward: item?.itemReward,
            rewardPoint: item?.itemRewardPoint,
            adName: item?.itemName,
            check_participation_url: item?.itemCheckParticipationUrl,
          }
          backendApis.recordOfferwallEventLog(impressionLog)
        }
      }

      // imp 로그 찍은것 저장
      setImpData(visibleItemData.map((e) => e?.itemId))
    }

    return onVisibleRangeChange
  }

  const onChange = onVisibleRangeChangeFactory(
    isFrom,
    randomId,
    itemData,
    setCurrentRange,
  )

  const onVisibleRangeChange = ({
    startIndex: groupStartIndex,
    endIndex: groupEndIndex,
  }) => {
    if (!isItemList) return
    const startIndex = groupStartIndex * columnCount
    const endIndex = groupEndIndex * columnCount + columnCount - 1
    return onChange({ startIndex, endIndex })
  }

  const groupArray = (array, columnCount) => {
    const groupedArray = []
    for (let i = 0; i < array?.length; i += columnCount) {
      const group = array
        .slice(i, i + columnCount)
        .map((item, j) => ({ index: i + j, data: item }))

      if (group.length < columnCount) {
        for (let j = group.length; j < columnCount; j += 1) {
          group.push({ index: i + j, data: null })
        }
      }

      groupedArray.push(group)
    }
    return groupedArray
  }

  const onTouchChange = (e) => {
    setCurrentTouchCount(e.touches.length)
  }

  useEffect(() => {
    if (!isItemList) return

    window.addEventListener('touchstart', onTouchChange)
    window.addEventListener('touchend', onTouchChange)

    return () => {
      window.removeEventListener('touchstart', onTouchChange)
      window.removeEventListener('touchend', onTouchChange)
    }
  }, [])

  useEffect(() => {
    if (isScrolling) {
      setIsScrollDelay(true)
    } else {
      setTimeout(() => {
        setIsScrollDelay(false)
      }, 150)
    }
  }, [isScrolling])

  useEffect(() => {
    if (isScrolling) {
      setIsScrollTouchActive(true)
    } else {
      setIsScrollTouchActive(currentTouchCount > 0)
    }
  }, [isScrolling, currentTouchCount])

  // 1) to handle items rendered before the data was loaded
  // 2) to handle items after item data is altered
  useEffect(() => {
    if (!isItemList || itemData?.length === 0) return
    setGroupedItemData(groupArray(itemData, columnCount))

    const newHash = getItemArrayHash(itemData)
    if (itemDataHash !== newHash) {
      setItemDataHash(newHash)
      if (currentRange.endIndex !== -1) {
        onChange(currentRange)
      }
    }
  }, [itemData, columnCount])

  return (
    <Virtuoso
      useWindowScroll
      style={{ height }}
      totalCount={groupedItemData.length}
      data={groupedItemData}
      endReached={loadMore}
      overscan={overscan}
      isScrolling={setIsScrolling}
      itemContent={(index, data, context) =>
        RowWrapper(index, data, isScrolling)
      }
      rangeChanged={onVisibleRangeChange}
    />
  )
}
