import { Spinner } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import useThrottle from '../../hooks/useThrottle'
import TreasureHuntTimer from './TreasureHuntTimer'

const TreasureHuntButton = observer(
  ({
    id = '',
    text,
    clickable = false,
    onClick = () => {},
    className,
    textImg,
    style,
    secondText,
    secondTextImg,
    secondTextImgStyle = {},
    expiryTimestamp = null,
    onExpire = () => {},
    pingOn = false,
    pingStyle,
    onDisabledClick = () => {},
    isLoading = false,
    /**
     * It defines loading indicator React Element
     */
    loadingIndicator = null,
  }) => {
    const [isPressed, setIsPressed] = useState(false)
    const [isClickable, setIsClickable] = useState(clickable)
    const throttledOnClick = useThrottle() // Adjust the throttle delay as needed (in milliseconds)

    const handlePress = (e) => {
      e.stopPropagation()
      e.preventDefault()
      if (!isClickable) {
        throttledOnClick(onDisabledClick, 800)
        return
      }
      throttledOnClick(onClick, 800)
    }

    const renderDescription = (text) =>
      text.split('\n').map((line, index) => (
        <span key={Math.random()}>
          {line}
          {index < text.split('\n').length - 1 && <br />}
        </span>
      ))

    const handleRelease = () => {
      if (!isClickable) return
      setIsPressed(false)
    }

    useEffect(() => {
      setIsClickable(clickable)
    }, [clickable])

    return (
      <div
        id={id}
        className={`flex flex-row text-center text-white font-bold px-3 py-2 rounded-lg shadow-md transition-colors justify-center items-center
      ${isClickable ? 'bg-[#FF5951]' : 'text-white bg-gray-400 '}
      ${isPressed ? 'transform scale-95 ' : ''}
      ${className}
      relative whitespace-nowrap
      `}
        style={style}
        onClick={(e) => {
          handlePress(e)
        }}
        onPointerDown={() => {
          setIsPressed(true)
        }}
        // onPointerUp={handlePress}
        onPointerCancel={handleRelease}
        onPointerLeave={handleRelease}
      >
        {textImg && (
          <img
            className='justify-center items-center w-5 h-5 mr-1'
            alt='imgText'
            src={textImg}
          />
        )}
        {isLoading ? (
          <div>
            <Spinner className='w-4 h-6' />
          </div>
        ) : (
          <div>{renderDescription(`${text}`)}</div>
        )}
        {secondTextImg && (
          <img
            className='w-5 mx-1'
            alt='imgText'
            src={secondTextImg}
            style={{ ...secondTextImgStyle }}
          />
        )}
        {secondText}
        {expiryTimestamp && !isClickable && (
          <TreasureHuntTimer
            expiryTimestamp={expiryTimestamp}
            onExpire={() => {
              onExpire()
              setIsClickable(true)
            }}
          />
        )}
        {pingOn && (
          <div
            className='absolute w-[6vw] h-[6vw] top-[-2vw] right-[-2vw]'
            style={pingStyle}
          >
            <img alt='ping' src='../images/utility/exclamation.png' />
          </div>
        )}
        {loadingIndicator}
      </div>
    )
  },
)
export default TreasureHuntButton
