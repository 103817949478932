import React, { useEffect, useState } from 'react'
import useDebounce from 'src/components/useDebounce'
import backendApis from 'src/utils/backendApis'

const AlfarmBridgePage = () => {
  const debounce = useDebounce()
  const logEvent = async (eventLocation, eventAction) => {
    await backendApis.recordEventLogForWeb('POST', {
      eventLocation,
      eventAction,
    })
  }

  const inputButton = async () => {
    logEvent('bridgeScreen', 'click start alfarm button')
    window.location.href = `https://alwayzshop.page.link/SwZe4JpyTSuxqjgr7`
  }

  const Button = () => {
    return (
      <button
        onClick={async () => {
          debounce(inputButton, 500)
        }}
      >
        <img
          style={{
            position: 'fixed',
            width: '80vw',
            zIndex: 3,
            bottom: '7vw',
            left: '10vw',
          }}
          src='/images/alfarmBridge/button.png'
        />
      </button>
    )
  }
  return (
    <>
      <div>
        <img
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alfarmBridge/alfarmBridge1.png'
        />
        <img
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alfarmBridge/alfarmBridge2.png'
        />
        <img
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alfarmBridge/alfarmBridge3.png'
        />
        <img
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alfarmBridge/alfarmBridge4.png'
        />
        <img
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alfarmBridge/alfarmBridge5.png'
        />
        <img
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alfarmBridge/alfarmBridge6.png'
        />
        <img
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alfarmBridge/alfarmBridge7.png'
        />
        {Button()}
      </div>
    </>
  )
}

export default AlfarmBridgePage
