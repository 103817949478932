import { useOverlay } from '@toss/use-overlay'
import { useMount } from 'react-use'
import styled, { keyframes } from 'styled-components'

interface Props {
  text: string
  isOpen: boolean
  close: () => void
}

export const Toast = (props: Props) => {
  useMount(() => {
    setTimeout(() => {
      props.close()
    }, 2000)
  })
  return <Wrapper>{props.text}</Wrapper>
}

export const useToast = () => {
  const { open } = useOverlay()
  return {
    openToast: (props: Omit<Props, 'isOpen' | 'close'>) =>
      open(({ isOpen, close }) => (
        <>
          {isOpen && <Toast text={props.text} isOpen={isOpen} close={close} />}
        </>
      )),
  }
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`

const Wrapper = styled.div`
  background: #000000b2;
  border-radius: 16px;

  //styleName: Body 2/Bold;
  /* font-family: Pretendard Variable; */
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;

  color: #ffffff;

  padding: 12px 26px;

  display: flex;
  justify-content: center;
  align-items: center;

  animation: ${fadeIn} 0.2s ease-in-out;
  animation-fill-mode: forwards;

  position: fixed;
  bottom: 35px;
  left: 50%;

  z-index: 9999;
`
