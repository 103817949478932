import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import Button from './Button'

const GaugeBar = observer(({ currentExp, totalExp }) => {
  return (
    <>
      <div className='relative w-full'>
        <div className={'gauge-bg-group-purchase w-full'}>
          <div
            className={'gauge-bar-group-purchase'}
            style={{
              width: `${
                currentExp !== totalExp
                  ? (currentExp / totalExp) * 100 + 10
                  : (currentExp / totalExp) * 100 - 2
              }%`,
            }}
          />
          <div
            className={`absolute text-center left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-[2.5vw] ${
              currentExp === totalExp ? ' text-white font-bold' : ' font-medium'
            }`}
          >
            {currentExp === totalExp
              ? `성공!`
              : `성공까지 ${totalExp - Math.floor(currentExp)}명만 더!`}
          </div>
        </div>
      </div>
    </>
  )
})

export default GaugeBar
