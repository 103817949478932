import { observer } from 'mobx-react-lite'
import React from 'react'

import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import THUserStore from '../../stores/treasureHunt/THUserStore'

const THBackpack = observer(() => {
  return (
    <div className='block overflow-y-scroll px-2 pointer-events-auto overflow-scroll pb-4'>
      <div className='flex w-full justify-around items-center'>
        <div
          className='flex w-[30vw] h-[30vw] px-[2vw] py-2 '
          onClick={() => {
            THBottomSheetStore?.setType({ type: 'collection', config: {} })
            THUserStore.set('collecitonPingOn', false)
            window.localStorage.setItem('collecitonPingOn', 'false')
          }}
        >
          <div className='flex flex-col w-full h-full bg-[#F8F8F8] rounded-xl mt-2 justify-center items-center relative'>
            <img
              className='w-[50%] h-[50%]'
              src='../images/treasureHunt/backpack/collection.png'
              alt='collection'
            />
            <div className='mt-2 font-bold text-[4vw]'>도감</div>
            {THUserStore?.collecitonPingOn && (
              <img
                className='absolute -right-[2vw] -top-[2vw] w-[5vw] h-[5vw]'
                src='../images/treasureHunt/ping.png'
                alt='ping'
              />
            )}
          </div>
        </div>
        <div
          className='flex  w-[30vw] h-[30vw] px-[2vw] py-2 '
          onClick={() => {
            THBottomSheetStore?.setType({ type: 'pet', config: {} })
            THUserStore.set('petPingOn', false)
          }}
        >
          <div className='flex flex-col w-full h-full bg-[#F8F8F8] rounded-xl mt-2 justify-center items-center relative'>
            <img
              className='w-[50%]'
              src='../images/treasureHunt/backpack/pet.png'
              alt='collection'
            />
            <div className='mt-2 font-bold text-[4vw]'>야옹이</div>
            {THUserStore?.petPingOn && (
              <img
                className='absolute -right-[2vw] -top-[2vw] w-[5vw] h-[5vw]'
                src='../images/treasureHunt/ping.png'
                alt='ping'
              />
            )}
          </div>
        </div>
        <div
          className='flex  w-[30vw] h-[30vw] px-[2vw] py-2 '
          onClick={() => {
            THBottomSheetStore?.setType({ type: 'closet', config: {} })
            THUserStore.set('closetPingOn', false)
          }}
        >
          <div className='flex flex-col w-full h-full bg-[#F8F8F8] rounded-xl mt-2 justify-center items-center relative'>
            <img
              className='w-[50%]'
              src='../images/treasureHunt/backpack/closet.png'
              alt='closet'
            />
            <div className='mt-2 font-bold text-[4vw]'>꾸미기</div>
            <div className='absolute -right-[2vw] -top-[2vw] text-[#fff] text-[3vw] font-bold bg-[#FF5951] whitespace-nowrap px-2 py-1 rounded-full'>
              NEW
            </div>
          </div>
        </div>
      </div>
      <div className='flex w-full justify-around items-center'>
        <div
          className='flex  w-[30vw] h-[30vw] px-[2vw] py-2 '
          onClick={() => {
            THBottomSheetStore?.setType({ type: 'achievement', config: {} })
            THUserStore.set('achievementPingOn', false)
          }}
        >
          <div className='flex flex-col w-full h-full bg-[#F8F8F8] rounded-xl mt-2 justify-center items-center relative'>
            <img
              className='w-[50%]'
              src='../images/treasureHunt/backpack/achievement.png'
              alt='achievement'
            />
            <div className='mt-2 font-bold text-[4vw]'>업적</div>
            {THUserStore?.achievementPingOn && (
              <img
                className='absolute -right-[2vw] -top-[2vw] w-[5vw] h-[5vw]'
                src='../images/treasureHunt/ping.png'
                alt='ping'
              />
            )}
          </div>
        </div>

        <div
          className='flex  w-[30vw] h-[30vw] px-[2vw] py-2 '
          onClick={() => {
            THModalStore?.setType({ type: 'scratchPurchase' })
          }}
        >
          <div className='flex flex-col w-full h-full bg-[#F8F8F8] rounded-xl mt-2 justify-center items-center relative'>
            <img
              className='w-[50%]'
              src='../images/treasureHunt/backpack/scratch.png'
              alt='collection'
            />
            <div className='mt-2 font-bold text-[4vw]'>행운뽑기</div>
          </div>
        </div>
        <div className='flex  w-[30vw] h-[30vw] px-[2vw] py-2 ' />
      </div>
    </div>
  )
})

export default THBackpack
