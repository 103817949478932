import React from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import backendApis from 'src/utils/backendApis'

const Header = (props) => {
  const {
    title = '',
    infoButton = true,
    infoNavigate = () => {
      navigate('/treasureboxtutorial')
      backendApis.checkTreasureBoxTutorial()
    },
    navigateBack = () => {
      window.location.href = '#goBack'
    },
  } = props

  const navigate = useNavigate()
  return (
    <section className='relative z-10 flex flex-row items-center w-screen h-12 overflow-hidden'>
      <button
        type='button'
        className='flex flex-1 px-3 py-2 whitespace-nowrap'
        onClick={() => (window.location.href = '#goBack')}
      >
        <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
      </button>
      <div className='w-full p-3 font-medium text-center text-gray-800' />
      <button
        type='button'
        className='flex flex-1 px-4 py-3 font-semibold text-gray-600 whitespace-nowrap'
        onClick={() => {
          navigate('/treasureboxtutorial')
          backendApis.checkTreasureBoxTutorial()
        }}
      >
        안내
      </button>
    </section>
  )
}

export default Header
