import { observer } from 'mobx-react-lite'
import React from 'react'
import { Close } from 'src/images/dailyCheckIn'
import UserStore from 'src/stores/UserStore'

const TeamCheckInConfirm = observer(
  ({
    visible,
    close,
    action,
    joinTeam,
    leaveTeam,
    checkInInvitorName,
    checkInInvitorId,
    isNewUser,
  }) => {
    let buttonPressed = false

    const handleButton = async () => {
      if (action === 'join') {
        await joinTeam()
      } else if (action === 'leave') {
        await leaveTeam()
      }
      close()
    }

    const TitleComponent = () => {
      if (action === 'join') {
        return (
          <div className='flex flex-col items-center text-2xl font-bold my-2'>
            <div>팀 참여하기</div>
          </div>
        )
      }
      if (action === 'leave') {
        return (
          <div className='flex flex-col items-center text-2xl font-bold my-2'>
            <div>팀 나가기</div>
          </div>
        )
      }
    }

    const MainTextComponent = () => {
      if (
        action === 'join' &&
        checkInInvitorId === UserStore?.userInfo?.userId
      ) {
        return (
          <div className='flex flex-col justify-center items-center text-center text-lg'>
            <div className='mb-4'>
              자신을 팀에 초대하는건 불가능해요. 친구를 초대해보세요!
            </div>
          </div>
        )
      }
      if (
        action === 'join' &&
        checkInInvitorId !== UserStore?.userInfo?.userId
      ) {
        return (
          <div className='flex flex-col justify-center items-center text-center text-lg'>
            {isNewUser && (
              <div className='mb-4'>올웨이즈에 오신걸 환영해요!</div>
            )}
            <div className='mb-4'>
              {checkInInvitorName}님의 팀에 참여하시겠어요?
            </div>
          </div>
        )
      }
      if (action === 'leave') {
        return (
          <div className='flex flex-col justify-center items-center text-center text-lg'>
            <div className='mb-4'>팀에서 나가시겠어요?</div>
          </div>
        )
      }
    }

    const ButtonComponent = () => {
      if (
        checkInInvitorId === UserStore?.userInfo?.userId &&
        action !== 'leave'
      ) {
        return (
          <button
            className='mt-2 px-2 py-4 w-4/5 bg-red-500 rounded font-bold text-lg text-white'
            onClick={() => {
              close()
            }}
          >
            확인
          </button>
        )
      }
      return (
        <div className='w-full flex flex-col items-center justify-center '>
          <button
            className='mt-2 px-2 py-4 w-4/5 bg-red-500 rounded font-bold text-lg text-white'
            onClick={() => {
              if (buttonPressed) return

              buttonPressed = true
              handleButton()
              buttonPressed = false
            }}
          >
            {action === 'join' ? '참여하기' : '팀 나가기'}
          </button>
          <button
            className='mt-2 -mb-4 px-2 py-1 w-4/5 text-gray-300 rounded font-bold text-base text-white'
            onClick={() => {
              close()
            }}
          >
            닫기
          </button>
        </div>
      )
    }

    return (
      <div>
        {/* 딤드 배경 */}
        <button
          className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-10'
          onClick={close}
        />

        {/* 모달 상자 */}
        <div
          style={{ left: '8%', top: '30%', borderRadius: 8, height: '38%' }}
          className='w-5/6 fixed bg-white z-20'
        />
        <button
          className='fixed z-30 p-1 bg-gray-300 rounded-full'
          style={{ left: '82%', top: '24%' }}
          onClick={close}
        >
          <Close className='w-6 h-6' />
        </button>

        {/* 모달 내용 + 받기 버튼 */}
        <div
          style={{ left: '12%', top: '32%', width: '76%', height: '30%' }}
          className='fixed z-30 flex flex-col justify-between items-center'
        >
          <TitleComponent />
          <div className='w-full'>
            <MainTextComponent />
            <div style={{ height: 10 }} />
          </div>
          <ButtonComponent />
        </div>
      </div>
    )
  },
)

export default TeamCheckInConfirm
