import moment from 'moment'
import { css } from 'styled-components'

import { Flex } from '../Flex'

interface CommentProps {
  isBest?: boolean
  isLiked: boolean
  userName: string
  comment: string
  createdAt: string
  likeCount: number
  isMyComment?: boolean
  onDelete?: () => void
  onReport?: () => void
  onBlock?: () => void
  onLike?: (setLiked: boolean) => void
}

export const Comment = (props: CommentProps) => {
  return (
    <Flex.Column
      css={css`
        padding: 16px 0px;
        gap: 6px;
      `}
    >
      <Flex.Row
        css={css`
          justify-content: space-between;
        `}
      >
        <Flex.Row
          css={css`
            align-items: center;
            gap: 6px;
          `}
        >
          {props.isBest && (
            <img
              src='/icon_best_comment.svg'
              alt='icon'
              width={38}
              height={18}
            />
          )}
          <div css={textWriterCSS}>{props.userName}</div>
          <div css={textDateCSS}>
            {moment(props.createdAt).format('YYYY.MM.DD HH:mm:ss')}
          </div>
        </Flex.Row>

        {props.isMyComment ? (
          <div onClick={props.onDelete}>
            <img src='/icon_cancel.svg' alt='icon' width={16} height={16} />
          </div>
        ) : (
          <Flex.Row
            css={css`
              gap: 8px;
            `}
          >
            <div onClick={props.onBlock}>
              <img src='/icon_block.svg' alt='icon' width={16} height={16} />
            </div>
            <div onClick={props.onReport}>
              <img src='/icon_report.svg' alt='icon' width={16} height={16} />
            </div>
          </Flex.Row>
        )}
      </Flex.Row>

      <Flex.Row css={textContentCSS}>{props.comment}</Flex.Row>

      <Flex.Row
        css={css`
          align-items: center;
          gap: 2px;
          color: ${props.isLiked ? '#191D28' : '#BCBDC3'};
        `}
      >
        {props.isLiked ? (
          <div onClick={() => props.onLike?.(false)}>
            <img src='/icon_heart_fill.svg' alt='icon' width={20} height={20} />
          </div>
        ) : (
          <div onClick={() => props.onLike?.(true)}>
            <img
              src='/icon_heart_empty.svg'
              alt='icon'
              width={20}
              height={20}
            />
          </div>
        )}
        <div css={textLikeCSS}>
          {props.likeCount > 999 ? '999+' : props.likeCount}
        </div>
      </Flex.Row>
    </Flex.Column>
  )
}

const textWriterCSS = css`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.20000000298023224px;
  text-align: left;

  color: #30333d;
`

const textDateCSS = css`
  /* //styleName: Detail 2/Medium;
  font-family: Pretendard Variable; */
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.20000000298023224px;
  text-align: left;

  color: #bcbdc3;
`

const textContentCSS = css`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.20000000298023224px;
  text-align: left;

  color: #30333d;
`

const textLikeCSS = css`
  //styleName: Detail 1/Medium;
  /* font-family: Pretendard Variable; */
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: -0.20000000298023224px;

  text-align: center;
`
