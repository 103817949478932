import { IconButton, Carousel as Slider } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'

import Button from './Button'

const CloseButton = ({ onClick }) => (
  <div
    className='absolute right-0 -top-10 z-20'
    aria-hidden='true'
    onClick={onClick}
  >
    <img
      className='w-8 h-8'
      alt='Close'
      src='../images/groupPurchase/icon/close.png'
    />
  </div>
)

const Modal = observer(({ close, content }) => {
  return (
    <>
      <div
        className='fixed top-0 left-0 w-full h-full bg-black opacity-80 z-30'
        aria-hidden='true'
        onClick={close}
      />
      <div
        className='fixed top-1/2 left-1/2 py-6 z-40 bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
        style={{ width: '90%' }}
      >
        {content}
        <CloseButton onClick={close} />
      </div>
    </>
  )
})

const TwoButtons = ({ props }) => {
  const content = (
    <>
      <div className='font-bold text-center text-[4vw] mb-4'>{`${props?.titleText}`}</div>

      <div className='font-medium text-center'>{`${props?.firstLineText}`}</div>
      <div className='font-medium text-center'>{`${props?.secondLineFirstText}`}</div>
      <div className='font-medium text-center flex flex-row justify-center items-center'>
        <div className='flex flex-row justify-center items-center font-bold text-[#FF4D00]'>
          {props?.emphasizeImage && (
            <img
              className='w-4 h-4 mr-1'
              alt='Close'
              src={props?.emphasizeImage}
            />
          )}
          {`${props?.secondLineEmphasizeText}`}
        </div>
        <div>{`${props?.secondLineSecondText}`}</div>
      </div>
      {props?.thirdLineText && (
        <div className='font-medium text-center'>{`${props?.thirdLineText}`}</div>
      )}

      <div className='flex flex-row w-full mt-6'>
        <Button.Normal
          text={props?.firstButtonText}
          className='inverted flex-1 mr-2 pt-[2vw] pb-[2vw] border-[#FF4D00] border-[2px]'
          onClick={() => {
            props?.firstButtonFunction()
            props?.close()
          }}
        />
        <Button.Normal
          text={props?.secondButtonText}
          className='flex-1 ml-2 pt-[2vw] pb-[2vw] border-[#FF4D00] border-[2px]'
          onClick={() => {
            props?.secondButtonFunction()
            props?.close()
          }}
        />
      </div>
    </>
  )

  return <Modal content={content} close={props?.close} />
}

const OneButton = ({ props }) => {
  const content = (
    <>
      <div className='font-bold text-center text-[4vw] mb-4'>{`${props?.titleText}`}</div>
      <div className='font-medium text-center'>{`${props?.firstLineText}`}</div>
      <div className='font-medium text-center'>{`${props?.secondLineFirstText}`}</div>
      <div className='font-medium text-center flex flex-row justify-center items-center'>
        <div className='flex flex-row justify-center items-center font-bold text-[#FF4D00]'>
          {props?.emphasizeImage && (
            <img
              className='w-4 h-4 mr-1'
              alt='Close'
              src={props?.emphasizeImage}
            />
          )}
          {`${props?.secondLineEmphasizeText}`}
        </div>
        <div>{`${props?.secondLineSecondText}`}</div>
      </div>
      {props?.thirdLineText && (
        <div className='font-medium text-center'>{`${props?.thirdLineText}`}</div>
      )}
      <div className='flex flex-row w-full mt-6'>
        <Button.Normal
          text={props?.firstButtonText}
          className='flex-1 mr-2 pt-[2vw] pb-[2vw] '
          onClick={() => {
            props?.firstButtonFunction()
            props?.close()
          }}
        />
      </div>
    </>
  )

  return <Modal content={content} close={props?.close} />
}

const InfoList = ({ props }) => {
  const content = (
    <>
      <div className='font-bold text-center text-[4vw] mb-4'>{`${props?.titleText}`}</div>
      <div className='flex flex-col flex-1 overflow-y-scroll'>
        {props?.textArray?.map((text, index) => (
          <div key={Math.random()} className='font-medium text-left p-2'>
            {text}
          </div>
        ))}
      </div>

      <div className='flex flex-row w-full mt-6'>
        <Button.Normal
          text={props?.firstButtonText}
          className='flex-1 pt-[2vw] pb-[2vw] '
          onClick={() => {
            props?.firstButtonFunction()
            props?.close()
          }}
        />
      </div>
    </>
  )

  return <Modal content={content} close={props?.close} />
}

const GroupPurchaseDetail = ({ props }) => {
  const [history, setHistory] = useState([])

  // useEffect(() => {
  //   const joinLog = props?.item?.joinLogs?.map((joinLog) => {
  //     return {
  //       createdAt: joinLog?.joinedAt,
  //       isJoined: true,
  //     }
  //   })
  //   const leaveLog = props?.item?.leaveLogs?.map((leaveLog) => {
  //     return {
  //       createdAt: leaveLog?.leftAt,
  //       isLeft: true,
  //     }
  //   })
  //   const temp = [...joinLog, ...leaveLog]
  //   temp.sort((a, b) => {
  //     return new Date(b.createdAt) - new Date(a.createdAt)
  //   })
  //   setHistory(temp)
  // }, [])

  const content = (
    <>
      <div className='font-bold text-center text-[4vw] mb-4'>{`${props?.titleText}`}</div>
      <div className='flex flex-col flex-1 overflow-y-scroll'>
        현재 참여중
        {props?.item?.membersInfo?.map((memberInfo, index) => (
          <div
            key={Math.random()}
            className='font-medium text-left p-2 flex felx-row items-center justify-center'
          >
            <img
              key={Math.random()}
              className={`w-[8vw] h-[8vw] object-contain border-[2px] border-white rounded-full `}
              alt='profile'
              src={`${memberInfo?.userImageUri}`}
            />
            <div className='ml-4'>{`${memberInfo?.userName}님`}</div>
          </div>
        ))}
      </div>
      {/* <div className='flex flex-col flex-1 overflow-y-scroll'>
        참여 내역
        {props?.item?.membersInfo?.map((memberInfo, index) => (
          <div
            key={Math.random()}
            className='font-medium text-left p-2 flex felx-row items-center justify-center'
          >
            <img
              key={Math.random()}
              className={`w-[8vw] h-[8vw] object-contain border-[2px] border-white rounded-full `}
              alt='profile'
              src={`${memberInfo?.userImageUri}`}
            />
            <div className='ml-4'>{`${memberInfo?.userName}`}</div>
          </div>
        ))}
      </div> */}
      <div className='flex flex-row w-full mt-6'>
        <Button.Normal
          text={props?.firstButtonText}
          className='flex-1 pt-[2vw] pb-[2vw] '
          onClick={() => {
            props?.firstButtonFunction()
            props?.close()
          }}
        />
      </div>
    </>
  )

  return <Modal content={content} close={props?.close} />
}

const Carousel = ({ props }) => {
  const [buttonActive, setButtonActive] = useState(false)

  const content = (
    <div>
      <Slider
        autoplay
        // loop
        className='rounded-xl pb-4'
        prevArrow={({ handlePrev }) => (
          <IconButton
            variant='text'
            color='gray'
            size='lg'
            onClick={handlePrev}
            className='!absolute top-2/4 -translate-y-2/4 left-[-2vw]'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='3'
              stroke='currentColor'
              aria-hidden='true'
              className='-ml-1 h-7 w-7'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M15.75 19.5L8.25 12l7.5-7.5'
              />
            </svg>
          </IconButton>
        )}
        nextArrow={({ handleNext }) => (
          <IconButton
            variant='text'
            color='gray'
            size='lg'
            onClick={handleNext}
            className='!absolute top-2/4 -translate-y-2/4 !right-[-2vw]'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='3'
              stroke='currentColor'
              aria-hidden='true'
              className='ml-1 h-7 w-7'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M8.25 4.5l7.5 7.5-7.5 7.5'
              />
            </svg>
          </IconButton>
        )}
        navigation={({ setActiveIndex, activeIndex, length }) => {
          if (activeIndex === 5) {
            setButtonActive(true)
          }
          return (
            <div className='absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2'>
              {new Array(length).fill('').map((_, i) => (
                <span
                  key={i}
                  className={`!mt-4 block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                    activeIndex === i ? 'bg-black w-8' : 'bg-black/50 w-4'
                  } `}
                  onClick={() => setActiveIndex(i)}
                />
              ))}
            </div>
          )
        }}
      >
        {props.contentArray?.map((content, index) => (
          <div
            key={Math.random()}
            className='flex flex-col flex-1 justify-start items-center h-[70vw]'
          >
            <img
              className='w-[60vw] h-[60vw] object-contain'
              alt={`${index}carousel`}
              src={content?.imageUri}
            />
          </div>
        ))}
      </Slider>

      <div className='flex flex-row w-full mt-2'>
        <Button.Normal
          text={props?.firstButtonText}
          className={`flex-1 pt-[2vw] pb-[2vw] ${
            buttonActive ? '' : 'disabled'
          }`}
          onClick={() => {
            if (buttonActive) {
              props?.firstButtonFunction()
              props?.close()
            }
          }}
        />
      </div>
    </div>
  )

  return <Modal content={content} close={props?.close} />
}

export default {
  TwoButtons,
  OneButton,
  InfoList,
  Carousel,
  GroupPurchaseDetail,
}
