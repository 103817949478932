import { useActions, useActivity } from "@stackflow/react";
import { TypeActivities } from "../../stackflow";
import backendApis from "../../utils/backendApis";

export const useMyFlow = () => {
  const actions = useActions<TypeActivities>();
  const activity = useActivity()
  const logScreenNavigation = (type: string, from: string, to: string, params?: any) => {
    backendApis.logScreenNavigation({
      type,
      from,
      to,
      params,
    })
  }

  return {
    ...actions,
    push: (activityName: keyof TypeActivities, params?: any, options?: any) => {
      logScreenNavigation('push', activity?.name, activityName, params)

      return actions.push(activityName, params, options)
    },
    pop: (count: number = 1, options?: any) => {
      logScreenNavigation('pop', activity?.name, 'pop', count)
      return actions.pop(count, options)
    },
    replace: (activityName: keyof TypeActivities, params?: any, options?: any) => {
      logScreenNavigation('replace', activity?.name, activityName, params)
      return actions.replace(activityName, params, options);
    }
  };
};