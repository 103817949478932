import commaNumber from 'comma-number'

import TruncateText from '../../../utils/truncateText'
import ImageAtom from '../../atoms/imageAtom'
import NameTag from '../tag/nameTag'
import { handleOnClickFactory } from './itemNavigateHandler'

const BrandDealItemComponent = ({
  itemInfo = {},
  style = {},
  version = 'horizontal',
  empty = false,
  isFrom = 'BrandDealSection',
  isScrolling,
}) => {
  const { width = 0 } = style
  const teamPrice = parseInt(itemInfo?.teamPurchasePrice, 10)
  const dealPrice = parseInt(itemInfo?.minDealTeamPurchasePrice, 10)
  const salesRatio =
    100 - Math.floor((dealPrice / teamPrice) * 100) || undefined
  const type = 'brandDeal'
  const validHandleOnClick = handleOnClickFactory(itemInfo, isFrom, type)
  const handleOnClick = isScrolling ? () => {} : validHandleOnClick

  if (version === 'horizontal') {
    if (empty) {
      return (
        <div
          aria-hidden='true'
          style={{ ...style, flex: 1, display: 'flex', paddingRight: 12 }}
        >
          <div className='flex flex-col flex-1 bg-gray-200 rounded-xl'>
            <div>
              <div className='flex flex-row items-center justify-between mx-2 mb-2 font-bold' />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div
        aria-hidden='true'
        style={{ ...style, flex: 1, display: 'flex', paddingRight: 12 }}
        onClick={handleOnClick}
      >
        <div className='bg-white rounded-xl'>
          <ImageAtom
            src={itemInfo?.mainImageUris[0]}
            className='object-cover border border-gray-200 rounded-xl'
            style={{ width: `${width}px`, height: `${width}px` }}
            width={256}
            height={256}
          />
          <div>
            <div className='mx-2 mt-3'>
              <NameTag name='brandDeal' />
            </div>
            <div className='mx-2 mt-2'>
              <TruncateText text={itemInfo?.itemTitle} width={width - 24} />
            </div>

            <div className='flex flex-row items-center justify-start mx-2 mb-2 font-bold'>
              <div className='mr-1 font-bold text-red-500'>
                {salesRatio && `${salesRatio}%`}
              </div>
              <div className='text-gray-800'>{commaNumber(dealPrice)}원</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (version === 'vertical') {
    if (empty) {
      return (
        <div className='flex flex-row flex-1'>
          <div className='flex flex-row items-center justify-start flex-1 p-5 border-t border-gray-300'>
            <div className='flex-none w-32 h-32 mr-5 border border-gray-300 rounded-lg' />
            <div className='flex flex-col items-start justify-start flex-1' />
          </div>
        </div>
      )
    }
    return (
      <div
        aria-hidden='true'
        className='flex flex-col justify-center flex-1'
        onClick={handleOnClick}
      >
        <div className='flex flex-col items-center justify-center flex-1'>
          <div className='flex flex-row items-center justify-start flex-1 p-5'>
            <div className='items-center justify-center flex-none mr-5'>
              <ImageAtom
                src={itemInfo?.mainImageUris[0]}
                className='object-cover w-32 h-32 border border-gray-300 rounded-lg'
                width={256}
                height={256}
              />
            </div>
            <div className='flex flex-col items-start justify-start flex-1 h-full'>
              <NameTag type='brandDeal' />
              <div className='mt-1'>
                <TruncateText text={itemInfo?.itemTitle} />
              </div>
              <div className='flex flex-row items-end justify-center flex-1 w-full mt-5'>
                <div className='flex flex-row items-end justify-between flex-1'>
                  <div>
                    <div className='flex flex-row items-center justify-start flex-1 w-full -mb-1 text-sm text-gray-500'>
                      기존가
                    </div>
                    <div className='text-sm text-gray-500 deleted-text'>
                      {commaNumber(itemInfo?.teamPurchasePrice)}원
                    </div>
                  </div>
                  <div className='px-3 text-lg font-bold text-white bg-red-500 rounded-xl'>
                    {commaNumber(itemInfo?.dealTeamPurchasePrice)}원
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-[90%] justify-center items-center border-t border-gray-300' />
        </div>
      </div>
    )
  }
  return <></>
}
export default BrandDealItemComponent
