import { observer } from 'mobx-react-lite'
import { Suspense, useRef } from 'react'

import { BottomController } from './components/BottomController/BottomController'
import { HTMLRenderer } from './components/HTMLRenderer/HTMLRenderer'
import { OpacityMask } from './components/OpacityMask/OpacityMask'
import { TitleBar } from './components/TitleBar/TitleBar'
import { Viewport } from './components/ViewPort/Viewport'
import { useDragNavigation } from './hooks/useDragNavigation'
import { useNavigation } from './hooks/useNavigation'
import { viewerStore } from './store'
import { themeColors } from './utils/viewerOptions'

const Viewer = observer(() => {
  const viewportRef = useRef<HTMLDivElement>(null)

  const { navigateRight, navigateLeft, handlePageClick, fading } =
    useNavigation()

  useDragNavigation({
    listenerRef: viewportRef,
    onDragToLeft: navigateRight,
    onDragToRight: navigateLeft,
  })

  return (
    <Viewport onClick={handlePageClick} innerRef={viewportRef}>
      <Suspense>
        <TitleBar />
      </Suspense>

      <Suspense>
        <HTMLRenderer />
      </Suspense>

      <OpacityMask
        fading={fading}
        color={themeColors[viewerStore.options.theme].bgColor}
      />

      <Suspense>
        <BottomController />
      </Suspense>
    </Viewport>
  )
})

export default Viewer
