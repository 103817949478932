import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { viewerStore } from '../../store';

export const SwipeCliffs = observer(() => {
  const pageArray = Array(viewerStore.maxPage).fill(null);

  return (
    <>
      {pageArray.map((_, index) => (
        <SwipeCliff key={index} />
      ))}
    </>
  );
});

const SwipeCliff = styled.div`
  scroll-snap-align: start;
  min-width: 100vw;
  height: 100vh;
`;
