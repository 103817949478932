import React, { useEffect, useState } from 'react'

const TextTimer = ({
  endedAt,
  boxCss = 'px-1 py-0.5 text-black text-xs bg-white rounded',
  dotCss = 'mx-1 text-xl',
}) => {
  const endTime = parseInt(new Date(endedAt).getTime(), 10)
  const getRemainingTime = (endTime) => {
    const currentTime = new Date().getTime()
    const remainingTime = Math.max(0, endTime - currentTime)
    return Math.floor(remainingTime / 1000)
  }
  const [remainingTime, setRemainingTime] = useState(() =>
    getRemainingTime(endedAt),
  )

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(getRemainingTime(endTime))
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [endedAt])

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time.toString()
  }

  const hours = Math.floor(remainingTime / 3600)
  const minutes = Math.floor((remainingTime % 3600) / 60)
  const seconds = remainingTime % 60

  return (
    <div className='flex items-center justify-center'>
      <div className={boxCss}>{formatTime(hours)}</div>
      <div className={dotCss}>:</div>
      <div className={boxCss}>{formatTime(minutes)}</div>
      <div className={dotCss}>:</div>
      <div className={boxCss}>{formatTime(seconds)}</div>
    </div>
  )
}

export default TextTimer
