import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import GroupPurchaseStore from '../../stores/GroupPurchaseStore'
import Button from './Button'

const TopMenu = observer(({ displayType, setContentTitle }) => {
  return (
    <div className='flex-row flex w-100% justify-around pt-2 border-b-[0.5px]'>
      <div
        className='flex-1 pl-[18vw] pr-[18vw]'
        onClick={() => {
          GroupPurchaseStore?.changeDisplayList({ type: 'ongoing' })
          // GroupPurchaseStore?.fetchOngoingGroupPurchase()
          setContentTitle('내가 만든 모임구매')
        }}
      >
        <div
          className={`text-center pb-2 ${
            GroupPurchaseStore?.displayType === 'ongoing'
              ? 'border-b-[2px] border-black font-bold'
              : 'text-gray-400'
          }`}
        >
          진행중
        </div>
      </div>
      <div
        className='flex-1 pl-[18vw] pr-[18vw]'
        onClick={() => {
          GroupPurchaseStore?.changeDisplayList({ type: 'history' })
          // GroupPurchaseStore?.fetchFinishedGroupPurchase()
          setContentTitle('완료한 모임구매')
        }}
      >
        <div
          className={`text-center pb-2 ${
            GroupPurchaseStore?.displayType === 'history'
              ? 'border-b-[2px] border-black font-bold'
              : 'text-gray-400'
          }`}
        >
          완료
        </div>
      </div>
    </div>
  )
})

export default TopMenu
