import { ReactNode } from 'react';
import styled from 'styled-components';

interface BottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const BottomSheet = ({
  isOpen,
  onClose,
  children,
}: BottomSheetProps) => {
  return (
    <>
      {isOpen && <Backdrop onClick={onClose} />}
      <SheetContainer isOpen={isOpen}>
        <SheetContent>{children}</SheetContent>
      </SheetContainer>
    </>
  );
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const SheetContainer = styled.div<{
  isOpen: boolean;
}>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 80vh;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 11;
  display: flex;
`;

const SheetContent = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
