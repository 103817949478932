import { observer } from 'mobx-react-lite'
import React, { memo, useEffect, useState } from 'react'
import { MdArrowForwardIos as ArrowRight } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import AuthStore from '../../../stores/AuthStore'
import ItemImpressionStore from '../../../stores/ItemImpressionStore'
import backendApis from '../../../utils/backendApis'
import insertDealHandler from '../../../utils/insertDealHandler'
import Banner from '../banner/banner'
import BrandDealItemComponent from '../item/brandDealItemComponent'
import HorizontalList from '../item/horizontalList'

const BrandDealSection = () => {
  const navigate = useNavigate()
  const [dealItemsInfo, setDealItemsInfo] = useState([])
  const [loadNum, setLoadNum] = useState(0)
  const dealType = 'brandDeal'
  const ITEM_HEIGHT = 280
  const ITEM_WIDTH = 160
  const SCREEN_WIDTH = window.innerWidth
  const fetchDealItem = async (dealType, loadNum) => {
    const result = await backendApis.getDealItemsV3(dealType, loadNum)
    if (result?.status === 200) {
      insertDealHandler.dataSetter(dealType, result?.data)
      setDealItemsInfo((arr) => [...arr, ...result?.data])
      setLoadNum(loadNum + result?.data?.length)
    }
  }

  const renderItem = memo(({ index, style, data }) => {
    if (!data[index])
      return (
        <BrandDealItemComponent
          key={`${index}`}
          style={style}
          version='horizontal'
          empty
        />
      )
    return (
      <BrandDealItemComponent
        key={`${index}`}
        style={style}
        version='horizontal'
        itemInfo={data[index]}
      />
    )
  })

  useEffect(() => {
    if (AuthStore.token) fetchDealItem(dealType, loadNum)
  }, [AuthStore.token])

  return (
    <>
      {dealItemsInfo && dealItemsInfo.length > 0 && (
        <div className='relative flex flex-col items-start justify-center flex-1 p-3 overflow-hidden select-none'>
          <div className='flex flex-row items-center justify-between flex-1 w-full'>
            <div className='my-3 text-lg font-bold '>오늘의 브랜드딜</div>
            <div
              aria-hidden
              onClick={async () => {
                ItemImpressionStore.setPreviousIsFrom('BrandDealSection')
                navigate('/deal/brand-deal')
              }}
              className='flex flex-row items-center text-gray-500'
            >
              더보기
              <ArrowRight />
            </div>
          </div>
          <div className='items-center justify-center mb-5'>
            <Banner
              type='brandDeal'
              version='horizontal'
              onClick={async () => {
                navigate('/deal/brand-deal')
              }}
            />
          </div>
          <div className='inset-0'>
            <HorizontalList
              screenWidth={SCREEN_WIDTH}
              itemWidth={ITEM_WIDTH}
              itemHeight={ITEM_HEIGHT}
              itemData={dealItemsInfo}
              itemCount={loadNum === 0 ? 20 : loadNum}
              renderItem={renderItem}
              isFrom='BrandDealSection'
              isItemList
            />
          </div>
        </div>
      )}
    </>
  )
}
export default observer(BrandDealSection)
