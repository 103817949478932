import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import TextTimer from '../../components/TextTimer'
import Header from '../../components/atoms/header'
import LureDealItemComponent from '../../components/deal/item/lureDealItemComponent'
import VerticalList from '../../components/deal/item/verticalList'
import dealImages from '../../images/deal'
import AuthStore from '../../stores/AuthStore'
import backendApis from '../../utils/backendApis'

const LureDealPage = observer(() => {
  const [dealItemsInfo, setDealItemsInfo] = useState([])
  const [loadNum, setLoadNum] = useState(0)
  const SCREEN_HEIGHT = window.innerHeight
  const dealType = 'lureDeal'
  const token = AuthStore.token
  const currentDate = new Date()
  const endedAt = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    23,
    59,
    59,
  )
  const fetchDealItem = async (dealType, loadNum) => {
    const result = await backendApis.getDealItemsV3(dealType, loadNum)
    if (result?.status === 200) {
      setDealItemsInfo((arr) => [...arr, ...result?.data])
      setLoadNum(loadNum + result?.data?.length)
    }
  }
  const loadMore = async () => {
    await fetchDealItem(dealType, loadNum)
  }
  const renderItem = (index, data, isScrolling) => {
    if (!data)
      return (
        <LureDealItemComponent
          key={`${index}`}
          empty
          version='vertical'
          isFrom='LureDealPage'
          isScrolling={isScrolling}
        />
      )
    return (
      <LureDealItemComponent
        key={`${index}`}
        itemInfo={data}
        version='vertical'
        isFrom='LureDealPage'
        isScrolling={isScrolling}
      />
    )
  }

  useEffect(() => {
    if (token) fetchDealItem(dealType, loadNum)
  }, [token])

  return (
    <div className='flex flex-col flex-1 bg-[#ec5729] pb-24'>
      <Header title='순삭특가' goBackRoute='/deal/time-deal' />
      <div className='flex flex-col items-center justify-center flex-1 pt-12'>
        <div className='z-10 flex flex-col items-center justify-center flex-1 mb-10'>
          <div className='pt-12 text-xl font-bold text-white'>
            오늘만 이 가격, 순삭특가!
          </div>
          <div className='mb-5 text-white'>
            조기 품절이 예상되는 온라인 최저가 상품이에요
          </div>
          <TextTimer
            endedAt={endedAt}
            boxCss='text-orange-500 bg-white text-lg px-3 py-1 text-center font-bold rounded-lg shadow-lg tracking-[.25em]'
            dotCss='text-white font-bold px-2'
          />
        </div>
        <img
          src={dealImages.LureDealBackground}
          className='absolute inset-0 object-cover w-full h-screen'
          alt='lureDeal'
        />
      </div>
      <VerticalList
        itemData={dealItemsInfo}
        height={SCREEN_HEIGHT}
        renderItem={renderItem}
        loadMore={loadMore}
        isFrom='LureDealPage'
        isItemList
      />
    </div>
  )
})
export default LureDealPage
