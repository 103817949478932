import { Spinner } from '@material-tailwind/react'
import commaNumber from 'comma-number'
import React, { memo, useEffect, useState } from 'react'
import {
  RxChevronDown as DownIcon,
  RxChevronUp as UpIcon,
} from 'react-icons/rx'
import { CSSTransition } from 'react-transition-group'

import dealImages from '../../../images/deal'
import ItemStore from '../../../stores/ItemStore'
import backendApis from '../../../utils/backendApis'
import TruncateText from '../../../utils/truncateText'
import ImageAtom from '../../atoms/imageAtom'
import DiscountTag from '../tag/discountTag'
import HorizontalList from './horizontalList'
import { handleOnClickFactory } from './itemNavigateHandler'
import RelatedTimeDealItemComponent from './relatedTimeDealItemComponent'

const VisitedTimeDealItemComponent = ({
  itemInfo = {},
  style = {},
  empty = false,
  direction = 'horizontal',
  isFrom = 'VisitedTimeDealPage',
  isScrolling = false,
}) => {
  const [relatedItems, setRelatedItems] = useState([])
  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const SCREEN_WIDTH = window.innerWidth - 20
  const HZ_ITEM_HEIGHT = 200
  const HZ_ITEM_WIDTH = 142
  const { width = 0 } = style
  const teamPrice = parseInt(itemInfo?.teamPurchasePrice, 10)
  const dealPrice = parseInt(itemInfo?.minDealTeamPurchasePrice, 10)
  const lowestPrice =
    parseInt(itemInfo?.crawledItemTheLowest?.price, 10) || undefined
  const platform = itemInfo?.crawledItemTheLowest?.source || 'alwayz'
  const itemIndex = parseInt(itemInfo?.itemIndex, 10)
  const salesRatio =
    100 - Math.floor((dealPrice / teamPrice) * 100) || undefined
  const type = 'timeDeal'
  const version = 'relatedTimeDeal'
  const condition = {
    targetItemIndex: itemIndex,
    itemTitle: itemInfo?.itemTitle,
  }
  const PriceTag = () => {
    const isAlwayz = platform === 'alwayz'
    let platformClass = ''
    let platformText = ''

    if (!isAlwayz) {
      if (platform === 'coupang') {
        platformClass = 'bg-red-500'
        platformText = 'C'
      } else {
        platformClass = 'bg-green-500'
        platformText = 'N'
      }
    }

    const RenderPlatformBadge = () => {
      if (isAlwayz) return <></>
      if (lowestPrice < teamPrice) return <></>
      return (
        <div className='flex flex-row items-center mb-1'>
          <span
            className={`p-[5px] py-[1px] mr-1 font-bold text-white ${platformClass} rounded-md text-sm`}
          >
            {platformText}
          </span>
          <span className='text-sm font-bold text-gray-500'>
            최저가 {commaNumber(lowestPrice)}원
          </span>
        </div>
      )
    }

    return (
      <div
        className={`flex flex-col items-start justify-center ${
          isAlwayz ? '' : 'mb-1'
        }`}
      >
        <RenderPlatformBadge />
        <div className='flex flex-row items-center'>
          <img src={dealImages?.LogoApp} alt='logo' className='w-5 h-5 mr-1' />
          <span className='text-sm font-bold text-gray-500'>
            일반가 {commaNumber(teamPrice)}원
          </span>
        </div>
        <div className='mt-1 text-lg font-bold text-red-500 whitespace-nowrap'>
          특가<span className='text-lg mx-0.5'>{commaNumber(dealPrice)}</span>원
        </div>
      </div>
    )
  }

  const ExpansionButton = ({ isVisible, isLoading }) => {
    if (!isVisible) return <DownIcon />
    if (isLoading) return <Spinner className='w-4 h-4' />
    if (isVisible) return <UpIcon />
  }

  useEffect(() => {
    if (itemIndex === ItemStore.currentItemIndex && relatedItems.length > 0) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }, [itemIndex, relatedItems])
  const renderItem = memo(({ index, style, data }) => {
    if (!data[index])
      return (
        <RelatedTimeDealItemComponent key={`${index}`} style={style} empty />
      )
    return (
      <RelatedTimeDealItemComponent
        key={`${index}`}
        style={style}
        itemInfo={data[index]}
      />
    )
  })
  const validHandleOnClick = handleOnClickFactory(itemInfo, isFrom, type)
  const handleOnClick = isScrolling ? () => {} : validHandleOnClick

  if (direction === 'horizontal') {
    if (empty) {
      return (
        <div
          aria-hidden='true'
          style={{ ...style, flex: 1, display: 'flex', paddingRight: 12 }}
        >
          <div className='flex flex-col flex-1 bg-gray-200 border-gray-200 rounded-xl'>
            <div>
              <div className='flex flex-row items-center justify-between mx-2 mb-2 font-bold' />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div
        aria-hidden='true'
        style={{ ...style, flex: 1, display: 'flex', paddingRight: 12 }}
        onClick={handleOnClick}
      >
        <div className='bg-white rounded-xl'>
          <ImageAtom
            src={itemInfo?.mainImageUris[0]}
            className='object-cover border border-gray-200 rounded-xl'
            width={256}
            height={256}
          />
          <div>
            <div className='mx-2 mt-2'>
              <TruncateText text={itemInfo?.itemTitle} width={width - 24} />
            </div>

            <div className='flex flex-row items-center justify-start mx-2 mb-2 font-bold'>
              <div className='mr-1 font-bold text-red-500'>
                {salesRatio && `${salesRatio}%`}
              </div>
              <div className='text-gray-800'>{commaNumber(dealPrice)}원</div>
            </div>
            <DiscountTag gap={teamPrice - dealPrice} />
          </div>
        </div>
      </div>
    )
  }
  if (direction === 'vertical') {
    if (empty) {
      return (
        <div className='flex flex-col items-center justify-center flex-1 w-full'>
          <div className='flex flex-row items-center justify-start flex-1 w-full p-5'>
            <div className='flex-none w-32 h-32 mr-5 border border-gray-300 rounded-lg' />
            <div className='flex flex-col items-start justify-start flex-1' />
          </div>
          <div className='w-[90%] border-b border-gray-300' />
        </div>
      )
    }
    return (
      <>
        <div
          aria-hidden='true'
          className='flex items-center justify-center flex-1 w-full p-5'
        >
          <div className='flex flex-col items-center justify-center flex-1 h-full'>
            <div className='flex flex-row justify-start flex-1 w-full h-full'>
              <div
                aria-hidden='true'
                onClick={handleOnClick}
                className='flex-none mr-3 place-self-center'
              >
                <ImageAtom
                  src={itemInfo?.mainImageUris[0]}
                  className='object-cover w-32 h-32 border border-gray-300 rounded-lg'
                  width={256}
                  height={256}
                />
              </div>
              <div className='flex flex-col items-stretch justify-between flex-1 w-full'>
                <div
                  aria-hidden='true'
                  onClick={handleOnClick}
                  className='flex flex-col justify-between flex-1'
                >
                  <div
                    className='overflow-hidden leading-tight line-clamp-2 text-ellipsis'
                    style={{
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {itemInfo?.itemTitle}
                  </div>
                  <div className='flex flex-row items-center flex-1 w-full mt-5'>
                    <div className='relative flex flex-row items-center flex-1 mr-2'>
                      <div className='flex flex-1 w-[40%]' />
                    </div>
                  </div>
                </div>
                <div className='flex flex-row items-end justify-between flex-1 w-full'>
                  <div className='flex flex-col items-stretch'>
                    <div className='flex flex-col items-start justify-center flex-1'>
                      <div className='text-base whitespace-nowrap'>
                        <PriceTag />
                      </div>
                    </div>
                  </div>
                  <button
                    type='button'
                    className='w-10 h-10 px-3 py-1 ml-2 font-bold text-gray-500 bg-gray-200 border border-gray-500 rounded-xl'
                    onClick={async () => {
                      if (isVisible) return setIsVisible(false)
                      if (isLoading) return
                      setIsLoading(true)
                      ItemStore.setCurrentItemIndex(itemIndex)
                      const result = await backendApis.getDealItemsV3(
                        type,
                        0,
                        version,
                        [],
                        condition,
                      )
                      if (result?.status === 200) setRelatedItems(result?.data)
                      setIsLoading(false)
                    }}
                  >
                    <ExpansionButton
                      isVisible={isVisible}
                      isLoading={isLoading}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CSSTransition
          in={isVisible}
          timeout={300}
          classNames='list-transition'
          unmountOnExit
        >
          <div className='flex flex-col flex-1 w-full px-5 py-5 bg-gray-100'>
            <div className='flex flex-row items-center flex-1 mb-3 font-bold text-gray-800'>
              <span className='mr-1'>
                <img src={dealImages?.Fire} alt='fire' className='w-5 h-5' />
              </span>
              <div>
                비슷한 <span className='text-red-500'>특가상품</span>만
                모아봤어요!
              </div>
              <span className='ml-1'>
                <img src={dealImages?.Fire} alt='fire' className='w-5 h-5' />
              </span>
            </div>
            <HorizontalList
              screenWidth={SCREEN_WIDTH}
              itemWidth={HZ_ITEM_WIDTH}
              itemHeight={HZ_ITEM_HEIGHT}
              itemData={relatedItems}
              itemCount={relatedItems?.length}
              renderItem={renderItem}
              isFrom={`${isFrom}_relatedItemsSection`}
              isItemList
            />
          </div>
        </CSSTransition>
      </>
    )
  }
}
export default VisitedTimeDealItemComponent
