import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import TreasureHuntButton from '../TreasureHuntButton'
import TreasureHuntTimer from '../TreasureHuntTimer'

const MissionButton = observer(({ mission }) => {
  const [title, setTitle] = useState('')
  const [unit, setUnit] = useState('원')
  const [missionType, setMissionType] = useState('일일')
  const [expired, setExpired] = useState(false)
  const [buttonText, setButtonText] = useState('보상 받기')

  useEffect(() => {
    if (mission.type === 'catch') {
      setTitle('발견하기')
    }
    if (mission?.rewardType === 'point') {
      setUnit('원')
    } else if (mission?.rewardType === 'energy') {
      setUnit('개')
    }

    if (
      moment().isAfter(moment(mission?.endAt)) &&
      !mission?.clearedAt &&
      !mission?.receivedAt
    ) {
      setExpired(true)
      setButtonText('시간 초과')
    }

    if (mission?.receivedAt) {
      setButtonText('받기 완료')
    } else if (mission?.clearedAt) {
      setButtonText('보상 받기')
    }
  }, [])

  return (
    <div className='flex-shrink-0 flex flex-col justify-center items-start w-[40vw] bg-[#FFF6F6] mr-1 rounded-lg px-3 pb-2 pt-[1.5vw] text-[#313138]'>
      <div className='font-bold w-full mt-1 mb-2 text-[#313138] flex flex-row justify-between items-start'>
        <div className='flex flex-row'>
          <img
            className='z-[10] w-[6vw] h-[6vw] mr-1'
            alt='emoji'
            src={`../images/treasureHunt/emoji/${mission?.emojiType}/${mission?.emojiId}.png`}
          />
          {`${title}`}
        </div>
        <div className='text-[3vw] text-[#8E8D9B] mt-[0.5vw]'>미션</div>
      </div>
      {mission?.clearedAt || expired ? (
        <TreasureHuntButton
          className='w-full font-bold text-[3.5vw] shadow-none'
          style={{
            padding: '0.5vw 0',
          }}
          clickable={!mission?.receivedAt && !expired}
          text={buttonText}
          onClick={() => {
            TreasureHuntAction.getMissionReward({ mission })
          }}
        />
      ) : (
        <div className='flex flex-row justify-between items-end w-full'>
          <div className='font-bold text-[#595864] flex flex-row justify-start items-center'>
            <img
              className='z-[10] w-[4vw] h-[4vw] mr-1'
              alt='emoji'
              src={`../images/treasureHunt/${mission?.rewardType}.png`}
            />
            {`${mission?.rewardAmount}${unit}`}
          </div>
          <TreasureHuntTimer
            style={{
              color: '#FF5951',
              fontSize: '3vw',
              fontWeight: 'normal',
            }}
            format='number'
            expiryTimestamp={moment(mission?.endAt).toDate()}
            onExpire={() => {
              setExpired(true)
            }}
          />
        </div>
      )}
    </div>
  )
})

export default MissionButton
