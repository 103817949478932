import bronzeBox from './bronzeBox.png'
import diamondBox from './diamondBox.png'
import expBadge from './expBadge.png'
import expPocket from './expPocket.png'
import goldBox from './goldBox.png'
import goldenKey from './goldenKey.png'
import silverBox from './silverBox.png'

const BronzeBox = ({ className = 'w-48 h-48', children }) => (
  <div>
    <img className={className} alt='bronzeBox' src={bronzeBox} />
    <div>{children}</div>
  </div>
)
const SilverBox = ({ className = 'w-48 h-48', children }) => (
  <div>
    <img className={className} alt='silverBox' src={silverBox} />
    <div>{children}</div>
  </div>
)
const GoldBox = ({ className = 'w-48 h-48', children }) => (
  <div>
    <img className={className} alt='goldBox' src={goldBox} />
    <div>{children}</div>
  </div>
)
const DiamondBox = ({ className = 'w-48 h-48', children }) => (
  <div>
    <img className={className} alt='diamondBox' src={diamondBox} />
    <div>{children}</div>
  </div>
)
const GoldenKey = ({ className = 'w-12 h-12', children }) => (
  <div>
    <img className={className} alt='goldenKey' src={goldenKey} />
    <div>{children}</div>
  </div>
)
const ExpBadge = ({ className = 'w-12 h-12' }) => (
  <img className={className} alt='expBadge' src={expBadge} />
)
const ExpPocket = ({ className = 'w-12 h-12' }) => (
  <img className={className} alt='expPocket' src={expPocket} />
)

export {
  BronzeBox,
  SilverBox,
  GoldBox,
  DiamondBox,
  GoldenKey,
  ExpBadge,
  ExpPocket,
}
