import { useQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { useNavigateViewer } from '../../../../hooks/altoon/useNavigateViewer'
import { useNovelChapterPermission } from '../../hooks/useNovelChapterPermission'
import { loadNovelDetailQuery } from '../../remotes/comment'
import { Divider } from '../Divider'

export const ChapterList = observer(() => {
  const location = useLocation()
  const { chapterId, novelId } = location.state || {}
  const { navigateToViewer } = useNavigateViewer()

  const { data: novel } = useQuery(loadNovelDetailQuery({ novelId }))

  const { checkPermission } = useNovelChapterPermission()

  const navigateToChapter = async (chapterId: string) => {
    const isPermitted = await checkPermission({
      targetChapterId: chapterId,
    })

    if (!isPermitted) {
      return
    }

    navigateToViewer(
      {
        novelId,
        chapterId,
      },
      {
        replace: true,
      },
    )
  }

  return (
    <div
      css={css`
        position: absolute;
        width: max-content;
        max-width: 120px;
        height: 200px;
        background: #eeeff3;
        bottom: calc(100% + 20px);
        overflow-y: scroll;
        left: 0;
        border-radius: 12px;
        padding: 0px 14px;
      `}
    >
      <div>
        {novel?.data.map((chapter) => {
          return (
            <Chapter
              onClick={() => navigateToChapter(chapter._id)}
              className={
                chapter._id === chapterId ? 'text-blue-500' : 'text-black'
              }
            >
              {chapter.chapterTitle}
            </Chapter>
          )
        })}
      </div>
    </div>
  )
})

const Chapter = styled.div`
  //styleName: Body 2/Medium;
  /* font-family: Pretendard Variable; */
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;

  padding: 8px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #e2e2e7;
`
