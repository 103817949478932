import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthStore from '../../stores/AuthStore'
import GroupPurchaseStore from '../../stores/GroupPurchaseStore'
import ToastStore from '../../stores/ToastStore'
import Button from './Button'

const Reward = observer(() => {
  const navigate = useNavigate()

  return (
    <div className='flex flex-col bg-white shadow-md rounded-2xl px-[8vw] py-[4vw] m-4'>
      <div className='flex flex-row items-start justify-start flex-1'>
        <div className='font-bold'>나의 현금 리워드</div>
        <div
          className='relative font-bold mt-[0.3vw] ml-2 bg-gray-200 p-[2.5vw] rounded-full'
          onClick={() => {
            GroupPurchaseStore?.openRewardInfoModal()
          }}
        >
          <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-[3.5vw] text-gray-400 '>
            ?
          </div>
        </div>
        {/* <div className='text-gray-500 self-end flex-1 text-right text-[4vw]'>
          기록
        </div> */}
      </div>

      <div className='flex flex-row w-[100%] justify-between items-center'>
        <div className='font-bold text-[8vw] flex-1'>
          {`${commaNumber(GroupPurchaseStore?.userInfo?.reward)}원`}
        </div>
        <Button.Normal
          text='출금하기'
          onClick={() => {
            if (GroupPurchaseStore.userInfo?.reward < 5000) {
              ToastStore.toastOn({
                type: 'error',
                message: '현금 리워드가 5,000원 이상이 필요해요',
                duration: 3000,
              })
              return
            }
            navigate(`../groupPurchaseWithdraw?token=${AuthStore?.token}`)
          }}
        />
      </div>
    </div>
  )
})

export default Reward
