import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import ToastStore from '../../../stores/ToastStore'
import THBottomSheetStore from '../../../stores/treasureHunt/THBottomSheetStore'
import THUserStore from '../../../stores/treasureHunt/THUserStore'
import TreasureHuntTimer from '../TreasureHuntTimer'

const EnergyComponent = observer(() => {
  const [energyChargeTimeStamp, setEnergyChargeTimeStamp] = useState(null)

  useEffect(() => {
    if (THUserStore?.energyBounceAnimationOn) {
      setTimeout(() => {
        THUserStore?.set('energyBounceAnimationOn', false)
      }, 300)
    }
  }, [THUserStore?.energyBounceAnimationOn])

  useEffect(() => {
    setEnergyChargeTimeStamp(
      moment(THUserStore?.lastEnergyChargeAt).add(30, 'minutes').toDate(),
    )
  }, [THUserStore?.lastEnergyChargeAt])

  return (
    <div
      className={`flex text-[4vw] flex-row justify-center items-center mr-2 rounded-full bg-[#FFE2BA] pr-[4px] pl-[4px] py-[4px] text-[#313138] font-bold
        ${THUserStore?.energyBounceAnimationOn ? '' : ''}
        whitespace-nowrap
        `}
      onClick={() => {
        THBottomSheetStore.setType({
          type: 'mission',
          config: {},
        })
      }}
    >
      <img
        className='w-[5vw] h-[5vw] mr-[1.5vw]'
        alt='energy'
        src='../images/treasureHunt/energy.png'
      />
      {`${commaNumber(THUserStore?.energy ?? 0)}개`}
      <div className='ml-2 bg-[#fff7ed] rounded-full px-2 py-1'>
        {THUserStore?.energy >= THUserStore?.maxEnergy ? (
          <div className='text-[3.5vw] text-[#FF9400]'>가득참</div>
        ) : (
          <div className=''>
            {THUserStore?.dailyEnergy >= 20 ? (
              <div className='text-[3.5vw] text-[#FF9400]'>일일 제한</div>
            ) : (
              <div className='w-[10vw]'>
                {THUserStore?.isChargingEnergy && energyChargeTimeStamp && (
                  <TreasureHuntTimer
                    style={{
                      color: '#FF9400',
                      fontSize: '3.5vw',
                    }}
                    format='number'
                    expiryTimestamp={moment(
                      energyChargeTimeStamp ?? '2023-01-01',
                    )}
                    onExpire={() => {
                      TreasureHuntAction.energyCooltime()
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
})

export default EnergyComponent
