import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'

import WatermelonUserStore from '../../stores/WatermelonUserStore'
import TreasureHuntButton from './TreasureHuntButton'

const WatermelonRankItem = observer(({ user, index, currentTab }) => {
  return (
    <div className='flex flex-row relative w-full justify-between items-center py-2'>
      {currentTab ? (
        <div
          className={`w-10 h-10 mr-2 rounded-full flex justify-center items-center font-bold ${
            index > 20 && 'ml-2'
          }`}
        >
          {currentTab === 'all'
            ? `${
                WatermelonUserStore?.myRank > 20
                  ? `20+`
                  : WatermelonUserStore?.myRank + 1
              }`
            : `${
                WatermelonUserStore?.myRankLocal > 20
                  ? `20+`
                  : WatermelonUserStore?.myRankLocal + 1
              }`}
        </div>
      ) : (
        <div
          className={`w-10 h-10 mr-2 rounded-full flex justify-center items-center font-bold ${
            index > 20 && 'ml-2'
          }`}
        >
          {index > 20 ? `20+` : index + 4}
        </div>
      )}
      <img
        className='w-10 h-10 mr-4 rounded-full'
        src={user?.userImageUri ?? WatermelonUserStore?.DEFAULT_IMAGE}
        onError={(e) => {
          e.target.src = WatermelonUserStore?.DEFAULT_IMAGE
        }}
        alt='mission profile'
      />
      <div className='flex-1 text-[4vw] font-bold'>{user?.userName}</div>

      <div className='text-[4vw] font-bold'>{`${commaNumber(
        user?.highscore,
      )}점`}</div>
    </div>
  )
})

export default WatermelonRankItem
