const NameTag = ({ type = 'brandDeal', css = 'text-gray-500 bg-gray-200' }) => {
  const translator = (type) => {
    if (type === 'brandDeal') return '브랜드딜'
    if (type === 'treasureDeal') return '보물특가'
    if (type === 'categoryDeal') return '카테고리특가'
  }
  return (
    <div className={`px-1 text-xs font-bold ${css} rounded-sm w-max`}>
      {translator(type)}
    </div>
  )
}
export default NameTag
