import { useSuspenseQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { loadBookmarkQuery } from '../../remotes/comment'
import { viewerStore } from '../../store'
import { DocumentAlign } from '../../types/viewerOptions'
import { BottomSheet } from '../Bottomsheet'
import { useConfirmModal } from '../Confirm'
import { Divider } from '../Divider'
import { useToast } from '../Toast'
import { Bookmark } from './Bookmark'
import { deleteBookmark, retrieveBookmarkList } from './useBookmarkStore'

interface BookmarkBottomsheetProps {
  isOpen: boolean
  onClose: () => void
}

export const BookmarkBottomsheet = observer(
  (props: BookmarkBottomsheetProps) => {
    const location = useLocation()
    const { chapterId } = location.state || {}
    const { openToast } = useToast()
    const { open } = useConfirmModal()

    const { data: bookmarkList, refetch } = useSuspenseQuery(
      loadBookmarkQuery({
        chapterId,
      }),
    )

    const handleDeleteBookmark = async (
      chapterId: string,
      page: number,
      name: string,
    ) => {
      const isConfirmed = await open({
        title: '책갈피를 삭제할까요?',
        description: name,
        confirmText: '삭제하기',
        cancelText: '취소',
      })

      if (!isConfirmed) {
        return
      }

      await deleteBookmark(chapterId, page)

      openToast({
        text: '책갈피를 삭제했어요.',
      })
      refetch()
    }

    const handleMoveToBookmark = (page: number) => {
      const scrollDirection =
        viewerStore.options.documentAlign === DocumentAlign.Horizontal
          ? 'left'
          : 'top'
      const scrollPosition =
        viewerStore.options.documentAlign === DocumentAlign.Horizontal
          ? window.innerWidth * page
          : window.innerHeight * page

      viewerStore.viewport?.scrollTo({
        [scrollDirection]: scrollPosition,
      })
    }

    return (
      <BottomSheet isOpen={props.isOpen} onClose={props.onClose}>
        <Wrapper>
          {/* TODO: 책갈피 없는 경우 skeleton */}
          <div css={textCountCSS}>책갈피 목록</div>
          <Divider />

          <ScrollView>
            {bookmarkList
              .sort((a, b) => a.page - b.page)
              .map((bookmark) => (
                <>
                  <Bookmark
                    bookmark={bookmark}
                    onDelete={() =>
                      handleDeleteBookmark(
                        chapterId,
                        bookmark.page,
                        bookmark.name,
                      )
                    }
                    onClick={() => handleMoveToBookmark(bookmark.page)}
                  />
                  <Divider />
                </>
              ))}
          </ScrollView>
        </Wrapper>
      </BottomSheet>
    )
  },
)

const Wrapper = styled.div`
  padding: 16px 16px 12px;
  position: relative;
  display: flex;
  flex-direction: column;
`

const ScrollView = styled.div`
  overflow: scroll;
  flex: 1;
`

const textCountCSS = css`
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;

  padding: 10px 0px 12px;
`
