import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  RaffleBall,
  RaffleBalls,
  RaffleMachine,
} from '../../images/dailyCheckIn'
import backendApis from '../../utils/backendApis'

const RafflingModal = observer(
  ({
    modalName = 'raffling',
    previousWinner = '',
    raffleHistory = '',
    raffling = '',
    checkInAlarmConsent = '',
    setOpenRafflingModal = '',
    setOpenAlarmConsentModal = '',
  }) => {
    const navigate = useNavigate()
    const raffleIndex = Math.max(raffleHistory?.enteringInfo?.length - 1, 0)
    if (modalName === 'raffling') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div className='absolute top-[15vh] left-[10vw] z-30 w-4/5 h-[60vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'>
            <div className='py-4 text-lg'>
              {raffling
                ? '응모할 행운번호를 뽑고 있어요'
                : '행운번호를 뽑았어요!'}
            </div>
            <div className='relative items-center '>
              <RaffleMachine className='w-[60vw] h-full' />
              <RaffleBalls
                className={
                  raffling
                    ? 'animate-shake absolute top-[2vh] left-[6vw] w-[52vw] h-[52vw]'
                    : 'absolute top-[2vh] left-[6vw] w-[52vw] h-[52vw]'
                }
              />
            </div>
            <div className='flex flex-row items-center justify-center bg-[#FAEBEB] w-[70vw] h-[10vh] text-[4vw] rounded px-4 py-2 mb-2'>
              <RaffleBall className='w-8 h-8 mr-2' />
              {raffling &&
              raffleHistory?.enteringInfo[raffleIndex]?.uniqueNumber
                ? '...'
                : `뽑은 행운번호: `}
              {!raffling &&
                raffleHistory?.enteringInfo[raffleIndex]?.uniqueNumber && (
                  <div className='ml-2 text-[#EB4F47]'>
                    {raffleHistory?.enteringInfo[raffleIndex]?.uniqueNumber}
                  </div>
                )}
            </div>
            <button
              type='button'
              className={
                raffling
                  ? 'w-full bg-[#BCA1A0] py-4 text-white rounded-b-xl'
                  : 'w-full bg-[#EB4F47] py-4 text-white rounded-b-xl'
              }
              disabled={raffling}
              onClick={async () => {
                if (checkInAlarmConsent === 'false') {
                  setOpenAlarmConsentModal(true)
                }
                setOpenRafflingModal(false)
              }}
            >
              번호 받기
            </button>
          </div>
        </>
      )
    }
    if (modalName === 'announceWinner') {
      const TitleComponent = () => {
        return (
          <div
            className={
              raffling ? 'mt-4 py-4 text-lg' : 'py-4 text-base text-center'
            }
          >
            {raffling ? (
              '지난 주 뽑기 결과를 확인해볼까요?'
            ) : (
              <>
                아쉬워요 😔
                <br />
                다음엔 당첨되길 기도할게요!
              </>
            )}
          </div>
        )
      }

      const ItemInfoComponent = () => {
        return (
          <div className='flex flex-col items-center justify-center text-[4vw] rounded w-full px-4 py-2 mb-2'>
            <div className='flex py-1'>
              {previousWinner?.itemTitle?.length > 10
                ? `${previousWinner?.itemTitle.slice(0, 14)}...`
                : previousWinner?.itemTitle}
            </div>
            <div className='flex flex-col items-start text-[3vw] font-semibold'>
              <div className='flex flex-row'>
                <div className='text-[#525252] mr-1 font-normal'>
                  응모 기간 |{' '}
                </div>
                {moment(previousWinner?.createdAt).format('YYYY.MM.DD')} ~{' '}
                {moment(previousWinner?.finishedAt)
                  .add(-1, 'day')
                  .format('YYYY.MM.DD')}
              </div>
              <div className='flex flex-row'>
                <div className='text-[#525252] mr-1 font-normal'>
                  당첨 발표 |{' '}
                </div>
                {moment(previousWinner?.finishedAt).format('YYYY.MM.DD')}
              </div>
            </div>
          </div>
        )
      }

      const WinnerComponent = () => {
        const userName = previousWinner?.winnerList[0]?.userName
        const anonymousUserName =
          userName.slice(0, Math.floor((1 * userName.length) / 4)) +
          userName
            .slice(
              Math.floor((1 * userName.length) / 4),
              Math.floor((3 * userName.length) / 4),
            )
            .replace(/./g, '*') +
          userName.slice(Math.floor((3 * userName.length) / 4))

        return (
          <div className='flex flex-col items-center justify-center text-[4vw] w-[90vw] px-4 py-2 mb-2'>
            <div className='flex py-1 text-[#716F71]'>지난주 행운의 당첨자</div>
            <div className='flex flex-row items-center justify-center text-[4vw] h-[10vh] font-semibold border rounded-lg w-4/5 p-2'>
              <img
                src={previousWinner?.winnerList[0]?.profileImage}
                className='rounded-full w-6 h-6'
                alt=''
              />
              {anonymousUserName}님
              {/* <div className='text-[#EB4F47] ml-1'>
                {previousWinner?.winnerList[0]?.winningNumber}
              </div> */}
            </div>
          </div>
        )
      }

      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div className='absolute top-[15vh] left-[10vw] z-30 w-4/5 h-[60vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'>
            <TitleComponent />
            <div className='relative items-center '>
              <RaffleMachine className='w-[40vw] h-full' />
              <RaffleBalls
                className={
                  raffling
                    ? 'animate-shake absolute top-[2vh] left-[6vw] w-[32vw] h-[32vw]'
                    : 'absolute top-[2vh] left-[6vw] w-[32vw] h-[32vw]'
                }
              />
              <img
                src={previousWinner?.imageUri}
                className={
                  raffling
                    ? 'rounded-full animate-shake absolute top-[3vh] left-[7vw] w-[28vw] h-[28vw]'
                    : 'rounded-full absolute top-[3vh] left-[7vw] w-[28vw] h-[28vw]'
                }
                alt=''
              />
            </div>
            {raffling && <ItemInfoComponent />}
            {!raffling && <WinnerComponent />}

            <button
              type='button'
              className={
                raffling
                  ? 'w-full bg-[#BCA1A0] py-4 text-white rounded-b-xl'
                  : 'w-full bg-[#EB4F47] py-4 text-white rounded-b-xl'
              }
              disabled={raffling}
              onClick={async () => {
                await backendApis.logAnnouncementViewed(previousWinner?._id)
                setOpenRafflingModal(false)
              }}
            >
              {raffling ? '당첨번호 확인 중...' : '다시 응모하러 가기'}
            </button>
          </div>
        </>
      )
    }
    if (modalName === 'preparingWinner') {
      const TitleComponent = () => {
        return (
          <div className='py-4 text-base text-center'>
            아직 당첨 번호를 뽑고 있어요
            <br />
            <div className='flex flex-row'>
              <div className='text-[#EB4F47]'>
                {moment(previousWinner?.announcedAt).format('M월 D일 H시')}
              </div>
              에 발표될 예정이에요
            </div>
          </div>
        )
      }

      const ItemInfoComponent = () => {
        return (
          <div className='flex flex-col items-center justify-center text-[4vw] rounded w-full px-4 py-2 mb-2'>
            <div className='flex py-1'>
              {previousWinner?.itemTitle?.length > 10
                ? `${previousWinner?.itemTitle.slice(0, 14)}...`
                : previousWinner?.itemTitle}
            </div>
            <div className='flex flex-col items-start text-[3vw] font-semibold'>
              <div className='flex flex-row'>
                <div className='text-[#525252] mr-1 font-normal'>
                  응모 기간 |{' '}
                </div>
                {moment(previousWinner?.createdAt).format('YYYY.MM.DD')} ~{' '}
                {moment(previousWinner?.finishedAt)
                  .add(-1, 'day')
                  .format('YYYY.MM.DD')}
              </div>
              <div className='flex flex-row'>
                <div className='text-[#525252] mr-1 font-normal'>
                  당첨 발표 |{' '}
                </div>
                {moment(previousWinner?.announcedAt).format('YYYY.MM.DD H')}시
                예정
              </div>
            </div>
          </div>
        )
      }

      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div className='absolute top-[15vh] left-[10vw] z-30 w-4/5 h-[60vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'>
            <TitleComponent />
            <div className='relative items-center '>
              <RaffleMachine className='w-[40vw] h-full' />
              <RaffleBalls className='absolute top-[2vh] left-[6vw] w-[32vw] h-[32vw]' />
              <img
                src={previousWinner?.imageUri}
                className={
                  raffling
                    ? 'rounded-full animate-shake absolute top-[3vh] left-[7vw] w-[28vw] h-[28vw]'
                    : 'rounded-full absolute top-[3vh] left-[7vw] w-[28vw] h-[28vw]'
                }
                alt=''
              />
            </div>
            <ItemInfoComponent />

            <button
              type='button'
              className='w-full bg-[#EB4F47] py-4 text-white rounded-b-xl'
              onClick={async () => {
                navigate(-1)
                setOpenRafflingModal(false)
              }}
            >
              확인
            </button>
          </div>
        </>
      )
    }
  },
)

export default RafflingModal
