import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'

import TreasureHuntButton from '../treasureHunt/TreasureHuntButton'

const AltoonMissionComponent = observer(({ mission }) => {
  const [unit, setUnit] = useState('개')
  const [buttonText, setButtonText] = useState('보상 받기')

  useEffect(() => {
    if (mission?.rewardType === 'ticket') {
      setUnit('개')
    } else if (mission?.rewardType === 'toonCoin') {
      setUnit('C')
    }

    if (mission?.status === 'received' || mission?.status === 'finish') {
      setButtonText(mission?.finishText ?? '내일 가능')
    } else if (mission?.status === 'reward') {
      setButtonText('보상 받기')
    } else if (mission?.status === 'action') {
      setButtonText(mission?.actionText)
    } else if (mission?.status === 'loading') {
      setButtonText('준비중')
    }
  }, [mission])

  return (
    <div
      className={
        mission?.isSpecial
          ? 'w-full flex flex-row justify-between items-center my-2 px-2 py-4 border-b-[0.2vw] bg-[#ffb70081] rounded-lg'
          : 'w-full flex flex-row justify-between items-center py-4 border-b-[0.2vw]'
      }
    >
      <div className='flex flex-row items-center justify-start'>
        <div className='relative'>
          <img
            className='w-[14vw] h-[14vw] mr-2 p-2'
            alt='type'
            src={`/images/toon/dailyMission/${mission?.type}.png`}
          />
        </div>
        <div>
          <div className='flex flex-row text-[4vw] font-semibold'>{`${mission?.title}`}</div>
          <div className='flex flex-row justify-between items-end w-full'>
            <div className='flex flex-row justify-start items-center text-[3.5vw] text-[#999999]'>
              {mission?.description}
              {!mission?.noText && `${mission?.rewardAmount}${unit}`}
              {`${
                mission?.dailyLimit ? `, 하루 ${mission?.dailyLimit}번` : ''
              }`}
            </div>
          </div>
        </div>
      </div>
      <TreasureHuntButton
        loadingIndicator={
          mission?.status === 'loading' ? (
            <ClipLoader
              color='#ff3e3e'
              loading
              size={20}
              aria-label='Loading Spinner'
              data-testid='loader'
              cssOverride={{ marginLeft: 8 }}
            />
          ) : null
        }
        className='font-bold text-[4vw] shadow-none px-4'
        style={{}}
        clickable={mission?.status === 'action' || mission?.status === 'reward'}
        text={buttonText}
        onClick={() => {
          if (mission?.status === 'action') {
            mission?.actionHandler()
          }
          if (mission?.status === 'reward') {
            mission?.rewardHandler()
          }
        }}
      />
    </div>
  )
})

export default AltoonMissionComponent
