import styled from 'styled-components';

interface CounterProps {
  min: number;
  max: number;
  value: number;
  onChange: (count: number) => void;
}

export const Counter = ({ min, max, value, onChange }: CounterProps) => {
  const handleIncrement = () => {
    if (value < max) {
      onChange(value + 1);
    }
  };

  const handleDecrement = () => {
    if (value > min) {
      onChange(value - 1);
    }
  };

  return (
    <Container>
      <Button onClick={handleDecrement} disabled={value <= min}>
        {/* TODO disable 처리 */}
        <img src="/icon_minus.svg" alt="minus" width={28} height={28} />
      </Button>
      <Count>{value}</Count>
      <Button onClick={handleIncrement} disabled={value >= max}>
        <img src="/icon_plus.svg" alt="plus" width={28} height={28} />
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const Button = styled.button<{ disabled: boolean }>`
  width: 28px;
  height: 28px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 24px;
  color: #71737c;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ disabled }) => (disabled ? '#F6F6FA' : '#FFFFFF')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const Count = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
`;
