import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import {
  Close,
  DailyCheckInFiveGuests,
  FriendIcon,
  NewBalloon,
  PlusSmall,
} from 'src/images/dailyCheckIn'
import UserStore from 'src/stores/UserStore'
import backendApis from 'src/utils/backendApis'

const DailyCheckInInviteFriendsNewModal = observer(
  ({
    visible,
    close,
    handleCheckInButton,
    todayTotalNewInviteNum,
    weeklyStampData,
    missionConfig,
  }) => {
    const [fiveGuestsInfoList, setFiveGuestsInfoLiset] = useState([])
    let isPressRewardButton

    useEffect(() => {
      const getCheckInInviteFriendsNewInfo = async () => {
        const result = await backendApis.getCheckInInviteFriendsNewInfo()
        if (result?.data) {
          const validGuestInfoList = result?.data?.guestInfoList?.slice(0, 5)
          if (!validGuestInfoList?.some((elem) => !elem?.didOpen)) {
            const temp = []
            validGuestInfoList?.forEach((each) => {
              temp.push(
                <CheckInGuestInfoComponent
                  key={Math.random()}
                  isInvited
                  userId={each?.userId}
                  thumbnailUserImageUri={each?.thumbnailUserImageUri}
                  didOpen={each?.didOpen}
                  isNewUser={each?.isNewUser}
                />,
              )
            })
            while (temp?.length < 5) {
              temp.push(<CheckInGuestInfoComponent isInvited={false} />)
            }
            setFiveGuestsInfoLiset(temp)
          } else {
            const temp = []
            const openTemp = validGuestInfoList.filter((elem) => elem?.didOpen)
            openTemp.push(validGuestInfoList?.find((elem) => !elem?.didOpen))
            openTemp?.forEach((each) => {
              temp.push(
                <CheckInGuestInfoComponent
                  key={Math.random()}
                  isInvited
                  userId={each?.userId}
                  thumbnailUserImageUri={each?.thumbnailUserImageUri}
                  didOpen={each?.didOpen}
                  isNewUser={each?.isNewUser}
                />,
              )
            })
            while (temp?.length < 5) {
              temp.push(<CheckInGuestInfoComponent isInvited={false} />)
            }
            setFiveGuestsInfoLiset(temp)
          }
        }
      }
      getCheckInInviteFriendsNewInfo()
    }, [])

    const CheckInGuestInfoComponent = ({
      isInvited = false,
      userId,
      thumbnailUserImageUri,
      didOpen,
      isNewUser,
    }) => {
      return (
        <div className='flex'>
          {!isInvited ? (
            <div className='flex'>
              <button
                className='flex flex-col items-center w-12'
                onClick={() => {
                  window.location.href = `#openBottomShareSheet.${JSON.stringify(
                    {
                      code: 'CheckInInviteFriends',
                      shareParams: UserStore?.userInfo?._id,
                    },
                  )}`
                }}
              >
                <div className='flex flex-col absolute items-center justify-center'>
                  <PlusSmall className='flex justify-center items-center w-8 h-8 p-1 mb-2 border border-dashed border-gray-300 rounded-full' />
                  <div
                    style={{
                      backgroundColor: '#ff3e3e',
                      width: 52,
                      height: 28,
                      borderRadius: 12,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  />
                </div>
              </button>
            </div>
          ) : (
            <div className='flex'>
              <button
                className='flex flex-col items-center w-12'
                disabled={didOpen}
                onClick={async () => {
                  if (isPressRewardButton) return
                  isPressRewardButton = true
                  await handleCheckInButton(
                    true,
                    'invite-friend-new',
                    weeklyStampData,
                    missionConfig,
                    isNewUser,
                  )
                  const result =
                    await backendApis.setTodayCheckInNewInvitationHistory(
                      userId,
                    )
                  if (result?.status === 200) {
                    isPressRewardButton = false
                  }
                }}
              >
                <div className='flex flex-col absolute items-center justify-center'>
                  {isNewUser && (
                    <NewBalloon className='w-8 h-8 absolute left-8 bottom-12 z-30' />
                  )}
                  {thumbnailUserImageUri && (
                    <img
                      src={thumbnailUserImageUri}
                      alt=''
                      style={{
                        width: 32,
                        height: 32,
                        marginBottom: 8,
                        borderRadius: 99,
                      }}
                    />
                  )}
                  {!thumbnailUserImageUri && (
                    <FriendIcon className='w-8 h-8 mb-2' />
                  )}

                  {didOpen ? (
                    <div
                      style={{
                        backgroundColor: '#bdbdbd',
                        width: 52,
                        height: 28,
                        borderRadius: 12,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      className='flex text-xs text-white font-bold'
                    >
                      완료
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: '#ff3e3e',
                        width: 52,
                        height: 28,
                        borderRadius: 12,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      className='flex text-xs text-white font-bold'
                    >
                      받기
                    </div>
                  )}
                </div>
              </button>
            </div>
          )}
        </div>
      )
    }

    const CheckInOneGuestComponent = () => {
      return (
        <>
          <div className='flex justify-center items-center h-40'>
            <button
              onClick={() => {
                window.location.href = `#openBottomShareSheet.${JSON.stringify({
                  code: 'CheckInInviteFriends',
                  shareParams: UserStore?.userInfo?._id,
                })}`
              }}
              style={{
                borderColor: '#bbb',
                borderStyle: 'dashed',
                borderWidth: 1.5,
                borderRadius: 80,
                padding: 10,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PlusSmall className='w-9 h-9' />
            </button>
          </div>
          <div
            style={{ color: '#919191' }}
            className='flex justify-center items-end text-sm'
          >
            * 공유한 링크로 출석할 때만 인정돼요
          </div>
        </>
      )
    }

    const CheckInFiveGuestsComponent = ({ fiveGuestsInfoList }) => {
      return (
        <div>
          <div
            style={{
              width: '99%',
            }}
          >
            <DailyCheckInFiveGuests className='' />
          </div>
          <div
            style={{ height: 70 }}
            className='flex flex-row justify-around items-center mx-2'
          >
            {fiveGuestsInfoList}
          </div>
        </div>
      )
    }

    return (
      <div>
        {/* 딤드 배경 */}
        <button
          className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-10'
          onClick={close}
        />
        {/* 모달 상자 */}
        <div
          style={
            fiveGuestsInfoList?.length > 0
              ? { left: '8%', top: '20%', borderRadius: 8, height: 440 }
              : { left: '8%', top: '20%', borderRadius: 8, height: 320 }
          }
          className='border-2 border-red-500 w-5/6 fixed bg-white z-20'
        />
        <div
          style={{ left: '30%', top: '18%', borderRadius: 8, height: 36 }}
          className='py-1 flex w-2/5 fixed bg-red-500 rounded-lg z-40 justify-center items-center font-bold text-white text-xl'
        >
          친구 초대
        </div>
        <button
          className='fixed z-30'
          style={{ left: '82%', top: '16%' }}
          onClick={close}
        >
          <Close className='w-6 h-6' />
        </button>

        {/* 모달 내용 + 받기 버튼 */}
        <div
          style={
            fiveGuestsInfoList?.length > 0
              ? { left: '9%', top: '22%', width: '82%', height: 420 }
              : { left: '9%', top: '22%', width: '82%', height: 300 }
          }
          className='flex flex-col fixed justify-between items-center z-30'
        >
          <div>
            <div className='text-center font-bold text-lg mt-3'>
              링크를 공유하고 랜덤 포인트를 받으세요
            </div>

            <div>
              {fiveGuestsInfoList?.length > 0 ? (
                <CheckInFiveGuestsComponent
                  fiveGuestsInfoList={fiveGuestsInfoList}
                />
              ) : (
                <CheckInOneGuestComponent />
              )}
            </div>
          </div>

          <div className='w-4/5'>
            {todayTotalNewInviteNum > 5 ? (
              <button
                style={{
                  backgroundColor: '#bdbdbd',
                  width: '100%',
                }}
                className='py-3 mb-3 rounded-2xl items-center font-bold text-base text-white mt-1'
                onClick={close}
              >
                친구 초대를 모두 사용했어요
              </button>
            ) : (
              <>
                {todayTotalNewInviteNum && todayTotalNewInviteNum === 5 ? (
                  <button
                    className='bg-red-500 py-3 mb-3 rounded-2xl items-center font-bold text-base text-white mt-1'
                    style={{ width: '100%' }}
                    onClick={async () => {
                      // result 결과 5번 보상을 받았으면 보너스 보상 주기
                      if (isPressRewardButton) return
                      isPressRewardButton = true
                      await handleCheckInButton(
                        true,
                        'invite-friend-new',
                        weeklyStampData,
                        missionConfig,
                        false,
                      )
                      isPressRewardButton = false
                    }}
                  >
                    보너스 포인트 받기
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      window.location.href = `#openBottomShareSheet.${JSON.stringify(
                        {
                          code: 'CheckInInviteFriends',
                          shareParams: UserStore?.userInfo?._id,
                        },
                      )}`
                    }}
                    className='bg-red-500 py-3 mb-3 rounded-2xl items-center font-bold text-base text-white mt-1'
                    style={{ width: '100%' }}
                  >
                    친구 초대하고 포인트 받기
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    )
  },
)

export default DailyCheckInInviteFriendsNewModal
