import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Header from '../../components/atoms/header'
import LoadingIndicator from '../../components/atoms/loadingIndicator'
import BrandDealSection from '../../components/deal/section/brandDealSection'
import EnteranceSection from '../../components/deal/section/enteranceSection'
import LureDealSection from '../../components/deal/section/lureDealSection'
import TimeDealSection from '../../components/deal/section/timeDealSection'
import VisitedTimeDealSection from '../../components/deal/section/visitedTimeDealSection'
import useInterval from '../../hooks/useInterval'
import dealImages from '../../images/deal'
import AuthStore from '../../stores/AuthStore'
import ItemImpressionStore from '../../stores/ItemImpressionStore'
import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import log from '../../utils/log'

const TimeDealPage = observer(() => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [isScreenFocused, setIsScreenFocused] = useState(true)
  const [randomBannerOpen, setRandomBannerOpen] = useState(false)
  const gameDealCondition =
    UserStore.getAbInfo('purechunk0618') !== 'a' &&
    UserStore?.getAbInfo('gamedeal0706') === 'a'
  const brandDealHideCondition =
    UserStore.getAbInfo('purechunk0618') !== 'a' &&
    UserStore.getAbInfo('brandhide0706') === 'a'

  useEffect(() => {
    const handleFocus = () => {
      setIsScreenFocused(true)
    }
    const handleBlur = () => {
      setIsScreenFocused(false)
    }

    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)
    return () => {
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
  }, [])

  // initialize with backend
  useEffect(() => {
    if (AuthStore.token) {
      UserStore.loadTargetEventDeal().then(() => {
        ItemImpressionStore.setJourneyBlock(
          `${ItemImpressionStore?.journeyBlockBase}_${UserStore?.targetEventGame}`,
        )
        setIsLoading(false)
      })
    }
  }, [AuthStore.token])
  const routerByDealType = (dealType) => {
    if (dealType === 'treasureDeal') {
      return '/deal/treasure-deal'
    }
    if (dealType === 'categoryDeal') {
      return '/deal/category-deal'
    }
  }

  const frequencySelector = (dealType) => {
    if (dealType === 'treasureDeal') {
      return 30
    }
    if (dealType === 'categoryDeal') {
      return 30
    }
  }

  useInterval(() => {
    if (isScreenFocused && gameDealCondition) {
      const probability = 1
      const randomValue = Math.random()
      if (randomValue < probability && UserStore?.targetEventDeal !== 'done') {
        setRandomBannerOpen(true)
      }
    }
  }, 1000)

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <Header title='타임특가' isWebViewEntrance />
      <div className='pt-12'>
        {!isLoading && (
          <>
            <LureDealSection />
            <VisitedTimeDealSection />
            {!brandDealHideCondition && <BrandDealSection />}
            {randomBannerOpen && UserStore?.targetEventGame !== 'none' && (
              <EnteranceSection game={UserStore?.targetEventGame} />
            )}
            <TimeDealSection focused={isScreenFocused} />
            <button
              type='button'
              className='fixed p-2 bg-white rounded-full shadow-lg bottom-6 right-6'
              onClick={scrollToTop}
            >
              <img src={dealImages.Up} className='w-5 h-5' alt='scroll up' />
            </button>
          </>
        )}
      </div>
    </>
  )
})

export default TimeDealPage
