import { useEffect, useState } from 'react'
import { FixedSizeList as List } from 'react-window'

import ItemImpressionStore from '../../../stores/ItemImpressionStore'
import {
  getItemArrayHash,
  onVisibleRangeChangeFactory,
} from './itemImpressionHandler'

export default function HorizontalList({
  screenWidth = '100%',
  itemWidth = 220,
  itemHeight = 220,
  itemData = [],
  itemCount = 0,
  renderItem = () => {},
  isFrom = null,
  isItemList = true,
}) {
  const [randomId, setRandomId] = useState(
    Math.random().toString(36).slice(2, 10),
  )
  const [itemDataHash, setItemDataHash] = useState('')
  const [currentRange, setCurrentRange] = useState({
    startIndex: 0,
    endIndex: -1,
  })

  useEffect(() => {
    if (!isItemList) return
    return () => {
      ItemImpressionStore.clearVisibleImpressions(isFrom, randomId)
    }
  }, [])

  const onChange = onVisibleRangeChangeFactory(
    isFrom,
    randomId,
    itemData,
    setCurrentRange,
  )
  const onVisibleRangeChange = ({ visibleStartIndex, visibleStopIndex }) => {
    if (!isItemList) return
    onChange({
      startIndex: visibleStartIndex,
      endIndex: visibleStopIndex,
    })
  }

  // 1) to handle items rendered before the data was loaded
  // 2) to handle items after item data is altered
  useEffect(() => {
    if (!isItemList || itemData.length === 0) return

    const newHash = getItemArrayHash(itemData)
    if (itemDataHash !== newHash && currentRange.endIndex !== -1) {
      setItemDataHash(newHash)
      onChange(currentRange)
    }
  }, [itemData])

  return (
    <List
      width={screenWidth}
      layout='horizontal'
      height={itemHeight}
      itemCount={itemCount}
      itemSize={itemWidth}
      overscanCount={5}
      itemData={itemData}
      onItemsRendered={onVisibleRangeChange}
    >
      {renderItem}
    </List>
  )
}
