import { observer } from 'mobx-react-lite'
import { MouseEvent, RefObject, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import { viewerStore } from '../../store'
import { DocumentAlign } from '../../types/viewerOptions'
import { mergeRefs } from '../../utils/mergeRefs'

interface ViewportProps {
  children: React.ReactNode
  onClick: (e: MouseEvent<HTMLDivElement>) => void
  innerRef: RefObject<HTMLDivElement>
}

export const Viewport = observer((props: ViewportProps) => {
  const viewportRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const viewport = viewportRef.current
    if (!viewport) return
    viewerStore.setViewport(viewport)
  }, [])

  const mergedViewportRef = mergeRefs([props.innerRef, viewportRef])

  return (
    <Wrapper
      onClick={props.onClick}
      ref={mergedViewportRef}
      touchAction={
        viewerStore.options.documentAlign === DocumentAlign.Horizontal
          ? 'none'
          : 'auto'
      }
      css={
        viewerStore.options.documentAlign === DocumentAlign.Horizontal
          ? horizontalCSS
          : verticalCSS
      }
    >
      {props.children}
    </Wrapper>
  )
})

const Wrapper = styled.div<{ touchAction: 'none' | 'auto' }>`
  ${({ touchAction }) => css`
    touch-action: ${touchAction};
  `}
`

const horizontalCSS = css`
  width: 100vw;
  height: 100vh;

  position: relative;
  overflow: hidden;
  overflow-x: scroll;
  overscroll-behavior: none;

  scroll-snap-type: x mandatory;

  ::-webkit-scrollbar {
    display: none;
  }
`

const verticalCSS = css`
  width: 100vw;
  height: 100vh;

  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  overscroll-behavior: none;

  ::-webkit-scrollbar {
    display: none;
  }
`
