import { AppScreen } from '@stackflow/plugin-basic-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { ClipLoader } from 'react-spinners'

import ComponentTitle from '../components/atoms/ComponentTitle'
import BottomBarComponent from '../components/toon/BottomBarComponent'
import MainNovelComponent from '../components/toon/MainNovelComponent'
import BannerSwiperComponent from '../components/toon/bannerSwiperComponent'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import AuthStore from '../stores/AuthStore'
import ToastStore from '../stores/ToastStore'
import backendApis from '../utils/backendApis'

const AltoonNovelPage = observer(() => {
  const scrollContainerRef = useRef(null)
  const tabRef = useRef(null)
  const initialTabOffsetTop = useRef(null) // Store initial offset

  const { pop } = useMyFlow()
  const token = AuthStore.token

  const [isLoading, setIsLoading] = useState(false)
  const [novelData, setNovelData] = useState()
  const [bannerData, setBannerData] = useState()

  useEffect(() => {
    const handleResize = () => {
      if (tabRef.current.style.position === 'fixed') {
        tabRef.current.style.width = `${scrollContainerRef.current.offsetWidth}px`
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const fetchNovelData = async () => {
      // 작품 정보 가져오기
      const novelInfo = await backendApis.loadAllNovels()
      if (novelInfo?.status === 200) {
        setNovelData(novelInfo.data)
      } else {
        ToastStore.toastOn({
          type: 'error',
          message: '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
          duration: 2000,
        })
      }
    }

    fetchNovelData()
  }, [])

  useEffect(() => {
    const fetchBannerData = async () => {
      // 배너 정보 가져오기
      const bannerData = await backendApis.loadBannerData()
      if (bannerData?.status === 200) {
        const filteredBannerData = bannerData?.data?.filter(
          (banner) =>
            banner?.bannerType === `novel_banner` &&
            banner?.itemId?.toString() !== '65e0160e3401440ed9cecde3',
        )
        setBannerData(filteredBannerData)
      }
    }

    fetchBannerData()
  }, [])

  useEffect(() => {
    // 배너 데이터 로드 후 offsetTop 설정
    if (bannerData && tabRef.current) {
      initialTabOffsetTop.current = tabRef.current.offsetTop
    }
  }, [bannerData]) // bannerData가 변경될 때마다 실행

  useEffect(() => {
    setIsLoading(true)
    AuthStore.setToken(token)
    setIsLoading(false)
  }, [])

  const HeaderComponent = () => {
    return (
      <section className='z-10 py-2 flex flex-row items-center justify-between overflow-hidden'>
        <button
          aria-label='goBack'
          type='button'
          className='mx-2 p-2 whitespace-nowrap'
          onClick={async () => {
            pop()
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
        </button>
        <div className='flex font-bold'>소설</div>
        <div className='w-[16vw]' />
      </section>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <AppScreen>
      <div
        className='w-full overflow-y-scroll'
        style={{ height: '100vh' }}
        ref={scrollContainerRef}
      >
        <HeaderComponent />
        {bannerData?.length > 0 && (
          <div className='w-[92vw] ml-[4vw]'>
            <BannerSwiperComponent bannerData={bannerData} bannerType='toon' />
          </div>
        )}
        {!bannerData && (
          <div className='w-[92vw] ml-[4vw]'>
            <BannerSwiperComponent empty bannerType='toon' />
          </div>
        )}

        <div className='mx-[4vw] py-[4vw]'>
          <ComponentTitle text='웹소설 신작!' />

          {novelData?.map((novel, index) => (
            <div key={novel?._id} className='mb-[4vw]'>
              <MainNovelComponent novel={novel} index={index} type='' />
            </div>
          ))}
        </div>

        <div className='h-[20vw]' />
        <BottomBarComponent />
        {isLoading && <LoadingIndicator />}
      </div>
    </AppScreen>
  )
})

export default AltoonNovelPage
