import { RefObject } from 'react';
import { useDrag } from '@use-gesture/react';

interface UseDragTransitionProps {
  listenerRef: RefObject<HTMLElement>;
  onDragToLeft: () => void;
  onDragToRight: () => void;
}

export const useDragNavigation = (props: UseDragTransitionProps) => {
  useDrag(
    (state) => {
      const [x] = state.movement;

      if (state.last) {
        if (x < -40) {
          props.onDragToLeft();
        }
        if (x > 40) {
          props.onDragToRight();
        }
      }
    },
    {
      pointer: { touch: true },
      axis: 'x',
      target: props.listenerRef,
      eventOptions: { passive: false },
    }
  );
};
