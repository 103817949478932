import { observer } from 'mobx-react-lite'
import React from 'react'
import { FiChevronLeft } from 'react-icons/fi'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import TicketComponent from '../../../games/watermelon/TicketComponent'
import WatermelonUserStore from '../../../stores/WatermelonUserStore'
import THUserStore from '../../../stores/treasureHunt/THUserStore'
import EnergyComponent from './EnergyComponent'
import GiftCopmponent from './GiftCopmponent'
import PointComponent from './PointComponent'

const HeaderComponent = observer(
  ({
    hideButtons = false,
    backButtonOnClick = null,
    settingOn = false,
    giftOn = false,
    title = '보물찾기',
    watermelonTicketOn = null,
    watermelonArcade = null,
  }) => {
    return (
      <section
        className={`
      flex flex-row overflow-hidden py-2 relative items-center justify-between w-full  whitespace-nowrap 
      ${
        watermelonTicketOn
          ? `bg-[${WatermelonUserStore.backgroundColor}]/0`
          : 'bg-[#fff]'
      }
      `}
      >
        <div className='flex flex-row justify-start items-center'>
          <button
            type='button'
            className='py-2 px-3 whitespace-nowrap'
            onClick={() => {
              if (backButtonOnClick) {
                backButtonOnClick()
                return
              }
              TreasureHuntAction.postMessage({ type: 'close' })
            }}
          >
            <FiChevronLeft className='w-8 h-8 stroke-[#313138]' />
          </button>
          <div className='font-bold text-[5vw] text-[#313138]'>{title}</div>
        </div>
        {!hideButtons && (
          <div className='flex flex-row'>
            {Number(THUserStore?.tutorialStep) > 0 && <EnergyComponent />}
            <PointComponent />
          </div>
        )}
        {giftOn && (
          <div className='flex flex-row'>
            <GiftCopmponent />
          </div>
        )}
        {watermelonTicketOn && (
          <div className='flex flex-row'>
            <TicketComponent watermelonArcade={watermelonArcade} />
          </div>
        )}
        {settingOn && (
          <div
            className='w-[7vw] h-[7vw] rounded-full flex justify-center items-center mr-4'
            onClick={() => {}}
            style={{
              backgroundImage: `url(../images/treasureHunt/setting.png)`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        )}
      </section>
    )
  },
)

export default HeaderComponent
