import ItemImpressionStore from '../stores/ItemImpressionStore'
import THBottomSheetStore from '../stores/treasureHunt/THBottomSheetStore'

function backHandler() {
  if (THBottomSheetStore?.type !== 'none') {
    THBottomSheetStore.close()
  } else {
    const index = window.history?.state?.idx
    if (window.history.length > 1 && index > 0) {
      window.history.back()
    } else {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'goBack',
        }),
      )
      ItemImpressionStore.uploadAndClearImpressions()
    }
  }
}

export default backHandler
