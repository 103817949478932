import commaNumber from 'comma-number'
import { useRef } from 'react'

import ImageAtom from '../../atoms/imageAtom'
import StarRating from '../../atoms/starRating'
import { handleOnClickFactory } from './itemNavigateHandler'
import ProfileCarousel from './profileCarousel'

const PriceSortDealItemComponent = ({
  itemInfo,
  empty = false,
  version = 'mainTimeDeal',
  isFrom = 'PriceSortDealPage',
  isScrolling = false,
}) => {
  const ref = useRef(null)
  const teamPrice = parseInt(itemInfo?.teamPurchasePrice, 10)
  const dealPrice = parseInt(itemInfo?.minDealTeamPurchasePrice, 10)
  const ratingScore =
    parseInt(itemInfo?.totalRatingScore, 10) /
    parseInt(itemInfo?.totalRatingCount, 10)
  const ratingCount = parseInt(itemInfo?.totalRatingCount, 10)
  const type = 'timeDeal'
  const validHandleOnClick = handleOnClickFactory(itemInfo, isFrom, type)
  const handleOnClick = isScrolling ? () => {} : validHandleOnClick

  if (empty) {
    return (
      <div className='flex flex-col items-center justify-center flex-1 w-full'>
        <div className='flex flex-row items-center justify-start flex-1 w-full p-5'>
          <div className='flex-none w-32 h-32 mr-5 border border-gray-300 rounded-lg' />
          <div className='flex flex-col items-start justify-start flex-1' />
        </div>
        <div className='w-[90%] border-b border-gray-300' />
      </div>
    )
  }
  return (
    <div
      aria-hidden='true'
      className='flex items-center justify-center flex-1 w-full'
      onClick={version === 'mainTimeDeal' ? handleOnClick : () => {}}
    >
      <div className='flex flex-col items-center justify-center flex-1 h-full'>
        <div className='flex flex-row justify-start flex-1 w-full h-full p-2'>
          <div className='flex-none mr-3 place-self-center'>
            <ImageAtom
              src={itemInfo?.mainImageUris[0]}
              className='object-cover w-32 h-32 border border-gray-300 rounded-lg'
              width={256}
              height={256}
            />
          </div>
          <div className='flex flex-col justify-between flex-1 leading-tight'>
            <div
              className='overflow-hidden leading-tight line-clamp-2 text-ellipsis'
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {itemInfo?.itemTitle}
            </div>
            <div>
              <div className='flex flex-row items-center justify-end w-full mb-1'>
                <div className='mr-2 text-xs font-bold text-gray-500'>
                  {`${commaNumber(itemInfo?.totalOrderQuantity)}개 구매중`}
                </div>
              </div>
              <div className='flex flex-row flex-1'>
                <div className='flex flex-col items-start'>
                  <div className='flex flex-row text-xs font-bold text-yellow-800'>
                    <div className='mr-2 font-bold text-gray-500'>
                      리뷰 {ratingCount}개
                    </div>
                    <div>{ratingScore?.toFixed(1) || 3}점</div>
                  </div>
                  <StarRating score={Math.round(ratingScore) || 3} />
                </div>
                <div className='flex flex-row items-center justify-end flex-1'>
                  <div className='px-2 py-1 mr-2 text-base font-bold text-white bg-red-500 divide-x-2 shadow-md divide-dashed whitespace-nowrap'>
                    <span />
                    <span className='pl-2 text-lg mx-0.5'>
                      ₩{commaNumber(dealPrice)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[90%] border-b border-gray-300' />
      </div>
    </div>
  )
}

export default PriceSortDealItemComponent
