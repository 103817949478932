import styled from 'styled-components'

export const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 140px;
  height: 48px;

  border-radius: 12px;
  background: #ff3e3e;

  color: #ffffff;

  //styleName: Title 4/Bold;
  /* font-family: Pretendard Variable; */
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.6000000238418579px;
  text-align: center;
`

export const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 140px;
  height: 48px;

  border-radius: 12px;
  background: #eeeff3;

  color: #bcbdc3;

  //styleName: Title 4/Bold;
  /* font-family: Pretendard Variable; */
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.6000000238418579px;
  text-align: center;
`
