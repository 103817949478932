import React, { useState } from 'react'
import { FcSalesPerformance as IdeaIcon } from 'react-icons/fc'
import { useLocation, useNavigate } from 'react-router-dom'

import gameImages from '../../../images/deal/game'
import ItemImpressionStore from '../../../stores/ItemImpressionStore'
import ModalStore from '../../../stores/ModalStore'
import UserStore from '../../../stores/UserStore'
import backendApis from '../../../utils/backendApis'
import log from '../../../utils/log'
import useCheckCodePushVersion from '../../../utils/useConvertCodePushVersion'
import GaugeBar from '../../atoms/gaugeBar'
import Header from '../../atoms/header'

const choices = ['Scissors', 'Rock', 'Paper']

const RockPaperScissors = ({ isFrom = 'EventDealModal' }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const codePushVersionAvailable = useCheckCodePushVersion()
  // const { stageCount = 3, userWinBonus = 0.1 } = location?.state
  const stageCount = 3
  const winModalName = 'celebrate'
  const loseModalName = 'lose'
  const winCallBack = async () => {
    ModalStore.setCurrentModal({
      open: true,
      type: winModalName,
      closeCallback: () => log.dealLog('win', 'join'),
      confirmCallback: () => log.dealLog('win', 'join'),
    })
    await backendApis.updateUserDealStatus('treasureDeal', 2)
    ItemImpressionStore.setPreviousIsFrom('RockPaperScissors')
    navigate('/deal/treasure-deal', {
      state: {
        hours: 0,
        minutes: 30,
      },
      replace: true,
    })
  }
  const loseCallBack = () => {
    ModalStore.setCurrentModal({
      open: true,
      type: loseModalName,
      closeCallback: () => log.dealLog('lose', 'join'),
      confirmCallback: () => log.dealLog('lose', 'join'),
    })
    if (
      codePushVersionAvailable(UserStore?.userInfo?.codepushVersion, '2.5.49')
    ) {
      navigate(-1)
    } else {
      navigate('/deal/time-deal')
    }
  }
  const [computerChoice, setComputerChoice] = useState(null)
  const [userChoice, setUserChoice] = useState(null)
  const [computerScore, setComputerScore] = useState(0)
  const [userScore, setUserScore] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [round, setRound] = useState(0)
  const [result, setResult] = useState(null)
  const processRate =
    userScore === stageCount
      ? 100
      : parseInt((userScore / stageCount) * 100, 10)

  const handleUserChoice = (choice) => {
    setUserChoice(choice)
    let computerChoiceTemp = null
    computerChoiceTemp = choices[Math.floor(Math.random() * choices.length)]
    // if (Math.random() < userWinBonus)
    //   computerChoiceTemp = getComputerLosingChoice(choice)
    setComputerChoice(computerChoiceTemp)
    determineResult(choice, computerChoiceTemp)
    setRound(round + 1)
  }

  const determineResult = (user, computer) => {
    if (user === computer) {
      setResult('무승부')
    } else if (
      (user === 'Scissors' && computer === 'Paper') ||
      (user === 'Rock' && computer === 'Scissors') ||
      (user === 'Paper' && computer === 'Rock')
    ) {
      setResult('승리')
      setUserScore(userScore + 1)
      if (userScore + 1 === stageCount) winCallBack()
    } else {
      setResult('패배')
      setComputerScore(computerScore + 1)
      if (computerScore + 1 === stageCount) loseCallBack()
    }
  }

  const getComputerLosingChoice = (userChoice) => {
    if (userChoice === 'Rock') {
      return 'Scissors'
    }
    if (userChoice === 'Paper') {
      return 'Rock'
    }
    if (userChoice === 'Scissors') {
      return 'Paper'
    }
  }

  return (
    <>
      <Header
        title='가위 바위 보'
        bgColor='#171717'
        textColor='white'
        callbackFunction={() => {
          ModalStore.setCurrentModal({
            open: true,
            type: 'eventDealExit',
            event: '/deal/time-deal',
            closeCallback: () => log.dealLog('give_up', 'join'),
            confirmCallback: () => log.dealLog('give_up', 'join'),
          })
        }}
      />
      <div className='flex flex-col items-center flex-1 h-screen pt-12 text-white bg-black'>
        <div className='flex flex-col items-center justify-center flex-1 w-full'>
          <div className='flex flex-row items-center justify-center my-5 animate-bounce'>
            <IdeaIcon className='w-6 h-6 mr-2' /> {stageCount}판을 이기면 특별한
            기회를 얻어요!
          </div>
          <div className='flex flex-row items-start justify-center flex-1 w-full px-8'>
            <div className='flex flex-row w-1/2'>
              {[...new Array(stageCount - computerScore)].map((e, idx) => {
                return (
                  <img
                    key={e + idx.toString()}
                    src={gameImages?.Heart}
                    className='w-12 pr-2'
                    alt='heart'
                  />
                )
              })}
            </div>
            <div className='w-1/2'>
              <GaugeBar value={processRate} max={100} height={8} />
            </div>
          </div>
        </div>
        <div className='flex flex-col items-center justify-center'>
          {computerChoice && (
            <div>
              <img
                src={gameImages?.[computerChoice]}
                className='w-24 m-2'
                alt={`${computerChoice}`}
              />
            </div>
          )}
          {result && (
            <div className='my-5 text-xl font-bold'>{`ROUND ${round} / ${result}`}</div>
          )}
          {userChoice && (
            <div>
              <img
                src={gameImages?.[userChoice]}
                className='w-24 m-2'
                alt={`${userChoice}`}
              />
            </div>
          )}
        </div>
        <div className='flex flex-col items-center justify-end flex-1 w-full px-2 mb-5'>
          <div>
            {choices.map((choice) => (
              <button
                type='button'
                key={choice}
                onClick={() => {
                  if (!isLoading) {
                    setIsLoading(true)
                    handleUserChoice(choice)
                    setIsLoading(false)
                  }
                }}
              >
                <img
                  src={gameImages?.[choice]}
                  className='w-24 m-2'
                  alt={`${choice}`}
                />
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default RockPaperScissors
