import { makeAutoObservable } from 'mobx'

import backendApis from '../../../utils/backendApis'

class AlfluencerCMSStore {
  constructor() {
    makeAutoObservable(this)
  }

  async loadTargetItemsInfo(itemIds = []) {
    const result = await backendApis.getSpecificItemsInfo(itemIds)
    return result
  }
}

export default new AlfluencerCMSStore()
