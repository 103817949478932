import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../../pages/EggMoney/Button'
import THModalStore from '../../../stores/treasureHunt/THModalStore'

function renderDescription(text) {
  return text?.split?.('\n')?.map((line, index) => (
    <span key={Math.random()}>
      {line}
      {index < text?.split('\n').length - 1 && <br />}
    </span>
  ))
}

const textList = [
  '돈 복사가 되는 신개념 앱테크\n귀여운 에그를 모으고 돈도 벌어보세요!',
  '시간이 될때마다 에그를 뽑아보세요\n높은 등급 에그를 모아보세요',
  '뽑은 계란은 에그장터에서 사고 팔 수 있어요\n등급이 높은 에그는 비싼 값에 팔려요',
  '에그를 팔아서 번 돈을\n현금으로 인출 할 수 있어요!',
  '귀엽고 독특한 에그를 모으고\n값비싼 에그를 팔아서 부자가 되어보세요!',
]

const EggMoneyTutorialModal = ({ config }) => {
  const [carouselIndex, setCarouselIndex] = useState(0)
  const [buttonText, setButtonText] = useState('설명 보기')

  useEffect(() => {
    if (carouselIndex === 0) {
      setButtonText('설명 보기')
    } else if (carouselIndex === textList?.length - 1) {
      setButtonText('닫기')
    } else {
      setButtonText('다음')
    }
  }, [carouselIndex])

  return (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw]'>에그머니</div>
      <div className='relative flex items-center justify-center w-full my-8 '>
        <img
          alt='main'
          src={`../images/eggMoney/tutorialImages/${carouselIndex}.png`}
          className={`relative z-[10] w-full h-auto ${config?.imgClassName}`}
        />
      </div>
      <div className='font-bold -mt-4 text-center'>
        {renderDescription(textList?.[carouselIndex])}
      </div>

      <Button
        className='w-full py-3 mt-6'
        clickable
        text={buttonText}
        onClick={() => {
          if (carouselIndex === 0) {
            setCarouselIndex(carouselIndex + 1)
          }
          if (carouselIndex <= textList?.length - 2) {
            setCarouselIndex(carouselIndex + 1)
          } else {
            THModalStore?.close()
          }
        }}
      />
    </div>
  )
}

export default observer(EggMoneyTutorialModal)
