import { observer } from 'mobx-react-lite'
import React from 'react'

import dealImages from '../../images/deal'
import gameImages from '../../images/deal/game'
import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'
import ModalContents from '../organisms/modalContents'

const ImageByType = ({ type }) => {
  if (type === 'rockPaperScissors')
    return (
      <div className='absolute w-20 h-20 -translate-x-1/2 left-1/2 -top-10 '>
        <img src={gameImages?.Rock} className='animate-wiggle' alt='title' />
      </div>
    )
  if (type === 'randomPick') {
    return (
      <div className='absolute w-20 h-20 -translate-x-1/2 left-1/2 -top-10 '>
        <img src={gameImages?.Ticket} className='animate-wiggle' alt='title' />
      </div>
    )
  }
}

const Blank = () => <div className='h-6' />

const CloseButton = ({ onClick, className }) => (
  <div
    className={`absolute z-20 ${className}`}
    aria-hidden='true'
    onClick={onClick}
  >
    <img src={dealImages?.Close} alt='close' className='w-8 h-8 ' />
  </div>
)

const Modals = observer(() => {
  const isOpen = ModalStore?.currentModal?.open
  const mainType = ModalStore?.currentModal?.mainType

  if (!isOpen) return null

  const closeModal = () => {
    if (ModalStore?.currentModal?.closeCallback)
      ModalStore?.currentModal?.closeCallback()
    setTimeout(() => ModalStore.setCurrentModal({ open: false }), 50)
  }

  if (mainType === 'noBackground') {
    return (
      <>
        <div
          className='absolute top-0 left-0 z-30 w-full h-full bg-black opacity-80'
          aria-hidden='true'
          onClick={closeModal}
        />
        <div className='absolute z-40 w-full px-4 py-6 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 rounded-xl'>
          <ModalContents />
          <CloseButton onClick={closeModal} className='right-10 -top-8' />
        </div>
      </>
    )
  }

  return (
    <>
      <div
        className='fixed top-0 left-0 z-30 w-screen h-screen bg-black opacity-50 '
        aria-hidden='true'
        onClick={closeModal}
      />
      <div className='fixed z-40 w-4/5 px-4 py-6 -translate-x-1/2 -translate-y-1/2 bg-white top-1/2 left-1/2 bg-gradient-to-b rounded-xl'>
        <ImageByType className type={ModalStore?.currentModal?.type} />
        <Blank />
        <ModalContents />
        <CloseButton onClick={closeModal} className='right-0 -top-12' />
      </div>
    </>
  )
})

export default Modals
