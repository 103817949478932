import backendApis from '../../../utils/backendApis'
import { CommentSortType } from '../../../utils/interface'
import { retrieveBookmarkList } from '../components/Bookmark/useBookmarkStore'

export const novelChapterCommentListQuery = (params: {
  novelId: string
  chapterId: string
  sortType: CommentSortType
  count: number
  skip: number
}) => ({
  queryFn: () => backendApis.loadNovelChapterCommentList(params),
  queryKey: ['loadNovelChapterCommentList', params],
})

export const loadNovelDetailQuery = (params: { novelId: string }) => ({
  queryFn: () => backendApis.loadNovelDetail(params),
  queryKey: ['loadNovelDetail', params],
})

export const loadBookmarkQuery = (params: { chapterId: string }) => ({
  queryFn: () => retrieveBookmarkList(params.chapterId),
  queryKey: ['bookmarkList', params.chapterId],
})
