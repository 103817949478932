import ItemImpressionStore from '../../../stores/ItemImpressionStore'
import UserStore from '../../../stores/UserStore'

const handleOnClickFactory = (
  itemInfo,
  isFrom,
  dealType = null,
  dealEndedAt = null,
) => {
  const normalDealList = ['timeDeal', 'lureDeal', 'brandDeal']
  const privateDealList = ['treasureDeal', 'categoryDeal']
  if (!itemInfo || Object.keys(itemInfo).length === 0) {
    return () => {}
  }

  const data = {
    screen: 'MainStackDItemScreen',
    prop: {
      itemId: itemInfo?._id,
      isFrom,
      enteringComponent: UserStore.enteringComponent,
      itemInfo: {
        ...itemInfo?.itemInfo,
        teamPurchasePrice: itemInfo?.minDealTeamPurchasePrice,
        optionsInfo: itemInfo?.dealOptionsInfo,
        recoMeta: itemInfo?.recoMeta,
      },
      nonDealTeamPurchasePrice: itemInfo?.teamPurchasePrice,
      type: dealType || null,
      webViewRenderDelayMs: 300,
    },
    payload: {
      journeyBlock: ItemImpressionStore.journeyBlock,
    },
  }

  if (normalDealList.includes(dealType)) {
    data.prop.displayEndDate = itemInfo?.displayEndDate?.toString()
  } else if (privateDealList.includes(dealType)) {
    data.prop.dealEndDate = dealEndedAt?.toString()
  }

  const handleOnClick = () => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'navigate',
        data,
      }),
    )
  }
  return handleOnClick
}

export { handleOnClickFactory }
