import React from 'react'

import Timer from './Timer'

const BlackTextTimer = ({
  timeStamp,
  onTimeEnd,
  interval = 1000,
  timerMinutes = 60 * 24,
  color = 'black',
  fontSize = 16,
  marginTop = 0,
  marginBottom = 0,
  noHours,
}) => {
  return (
    <span
      style={{
        color,
        fontSize,
        marginTop,
        marginBottom,
      }}
    >
      {
        Timer({ timeStamp, onTimeEnd, interval, timerMinutes, noHours })
          .formattedTime
      }
    </span>
  )
}

export default BlackTextTimer
