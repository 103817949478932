import { useState } from 'react'
import styled from 'styled-components'

import { CommentSortType } from '../../../../utils/interface'

interface Props {
  activeTab: CommentSortType
  onClickTab: (tab: CommentSortType) => void
}

export const CommentTabs = (props: Props) => {
  return (
    <TabContainer>
      <Tab
        isActive={props.activeTab === CommentSortType.bestAll}
        onClick={() => props.onClickTab(CommentSortType.bestAll)}
      >
        베스트 댓글
        <ActiveLine isActive={props.activeTab === CommentSortType.bestAll} />
      </Tab>
      <Tab
        isActive={props.activeTab === CommentSortType.new}
        onClick={() => props.onClickTab(CommentSortType.new)}
      >
        최신 댓글
        <ActiveLine isActive={props.activeTab === CommentSortType.new} />
      </Tab>
    </TabContainer>
  )
}

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #eee;
  width: 100%;
`

const Tab = styled.div<{ isActive: boolean }>`
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  color: ${(props) => (props.isActive ? '#FF3E3E' : '#BCBDC3')};
  position: relative;

  //styleName: Body 1/Medium;
  /* font-family: Pretendard Variable; */
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
`

const ActiveLine = styled.div<{ isActive: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${(props) => (props.isActive ? '#FF3E3E' : 'transparent')};
`
