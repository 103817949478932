import commaNumber from 'comma-number'
import React, { useEffect, useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import { useSearchParam } from 'react-use'

import BlackTextTimer from '../components/BlackTextTimer'
import ZeroWonStoreSelectConfirmModal from '../components/ZeroWonStoreSelectConfirmModal'
import Header from '../components/header'
import Layout from '../components/layout'
import useDebounce from '../components/useDebounce'
import NewUserNudgeModal from '../modals/NewUserNudgeModal'
import backendApis from '../utils/backendApis'

let isButtonDisable = false

const isButtonActive = (isAlreadyJoin, threeInvite) => {
  if (isAlreadyJoin) {
    return false
  }
  if (threeInvite) {
    return false
  }
  return true
}

function ZeroWonStoreFriendItemPage() {
  const scrollRef = useRef(null)
  const alert = useAlert()

  const [
    ongoingZeroWonStoreFriendTeamInfo,
    setOngoingZeroWonStoreFriendTeamInfo,
  ] = useState()
  const [ongoingZeroWonStoreTeamInfo, setOngoingZeroWonStoreTeamInfo] =
    useState()

  const [inviteList, setInviteList] = useState([])
  const token = useSearchParam('token')
  const userId = useSearchParam('userId')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const [zeroWonStoreItemList, setZeroWonStoreItemList] = useState()
  const [lureDealItem, setLureDealItem] = useState()
  const [showNewUserNudgeModal, setShowNewUserNudgeModal] = useState(false)
  const [toggles, setToggles] = useState()
  const debounce = useDebounce()
  const [randomString, setRandomString] = useState(
    Math.random().toString(36).substr(2, 8),
  )
  const [itemId, setItemId] = useState()
  const [userName, setUserName] = useState()
  const [showSelectConfirmModal, setShowSelectConfirmModal] = useState(false)

  useEffect(() => {
    if (userId === alwayzInvitorId) {
      const check = async () => {
        const result = await backendApis.getEventPageMappingUrl(token, 'GET', {
          eventPageName: 'zeroWonStore',
        })
        window.location.href = `#pop.${JSON.stringify({
          number: 1,
        })}`
        window.location.href = `#push.${JSON.stringify({
          screen: 'WebEventPage',
          prop: {
            eventPageMapping: {
              redirectUrl: `${result.data?.redirectUrl}/zeroWonStoreItemPage/?token=${token}`,
            },
          },
        })}`
      }
      check()
    } else return
  }, [])

  useEffect(() => {
    const getZeroWonStoreFriendTeamInfo = async () => {
      const result = await backendApis.getZeroWonStoreFriendTeamInfo(
        token,
        'GET',
        { hostUserId: alwayzInvitorId },
      )
      if (result?.status === 200) {
        setOngoingZeroWonStoreFriendTeamInfo(result?.data)
      } else {
        alert.show(`만료된 상품이에요`, {
          closeCopy: '확인',
        })
      }
    }

    const getZeroWonStoreItemList = async () => {
      const result = await backendApis.getZeroWonStoreItemList(token, 'GET')
      if (result?.status === 200) {
        setZeroWonStoreItemList(result?.data)
      } else {
        // alert.show(`리스트 오류가 발생하였어요. 다시 시도해 주세요`)
      }
    }

    const getZeroWonStoreUserInvitedInfos = async () => {
      const result = await backendApis.getZeroWonStoreUserInvitedInfos(
        token,
        'GET',
      )
      if (result?.status === 200) {
        setInviteList(result?.data)
      } else {
        // alert.show(`리스트 오류가 발생하였어요. 다시 시도해 주세요`)
      }
    }

    const getOngoingZeroWonStoreTeamInfo = async () => {
      const result = await backendApis.getZeroWonStoreTeamInfo(token)
      if (result?.status === 200) {
        setOngoingZeroWonStoreTeamInfo(result?.data)

        const lureDealItems = await backendApis.getLureDealItems(token)
        const toggles = await backendApis.getCurrentToggles(token)
        if (lureDealItems?.status === 200) {
          setLureDealItem(lureDealItems?.data?.[0])
        }
        if (toggles?.status === 200) {
          setToggles(toggles?.data)
        }
      }
    }

    getZeroWonStoreFriendTeamInfo()
    getZeroWonStoreItemList()
    getOngoingZeroWonStoreTeamInfo()
    getZeroWonStoreUserInvitedInfos()
  }, [])

  const discountButton = async () => {
    if (
      !isButtonActive(
        !!ongoingZeroWonStoreFriendTeamInfo?.guestUserInfosArray?.find(
          (x) =>
            JSON.stringify(x?.userId) ===
              JSON.stringify(
                ongoingZeroWonStoreFriendTeamInfo?.requestUserId,
              ) && x?.userId,
        ),
        inviteList?.length >= 3,
      )
    ) {
      isButtonDisable = false
      return
    }
    // 토큰의 user document가 가입이 안되어 있는 경우
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)

    // 로그인 안되어 있는 경우
    if (
      userInfo?.status !== 200 ||
      userInfo?.data?.phoneNumber === '' ||
      userInfo?.data?.userName === ''
    ) {
      window.location.href = '#openLoginModal.'
      isButtonDisable = false
      return
    }

    // 본인 인증 안되어 있는 경우 <- 10월 15일 이후로 부터 본인 인증
    if (
      userInfo?.status !== 200 ||
      (!userInfo?.data?.selfAuthenticationInfo &&
        !userInfo?.data?.selfAuthenticationInfoLogs &&
        new Date(userInfo?.data?.createdAt) > new Date('2022-10-12T15:00:00'))
    ) {
      window.location.href = '#openAuthenticationModal.'
      isButtonDisable = false
      return
    }

    const result = await backendApis.updateZeroWonStoreTeamInfo(token, 'PUT', {
      hostUserId: alwayzInvitorId,
    })

    if (result?.status === 200 && result?.data?._id) {
      setOngoingZeroWonStoreTeamInfo({
        ...ongoingZeroWonStoreTeamInfo,
        hostUserInfo: {
          ...ongoingZeroWonStoreTeamInfo?.hostUserInfo,
          _id: result?.data?.invitedUserId,
        },
      })
      setInviteList([...inviteList, ''])
      setOngoingZeroWonStoreFriendTeamInfo({
        ...ongoingZeroWonStoreFriendTeamInfo,
        ...result?.data,
      })
      if (
        result?.data?.invitedUserId &&
        result?.data?.isNewUser &&
        !result?.data?.isAlreadyMakeZeroWonStore
      ) {
        // setShowNewUserNudgeModal(true)
        const result = await backendApis.getEventPageMappingUrl(token, 'GET', {
          eventPageName: 'zeroWonStore',
        })
        if (result?.status === 200) {
          const a = await backendApis.createZeroWonStoreTeamInfo(
            token,
            'POST',
            // dev에서의 감귤 아이디 6363d364e252a3fc17b592d4 production에서는 6363d220f17fd2dd1a6f93cb
            { itemId: '6363d220f17fd2dd1a6f93cb' },
          )
          window.location.href = `#pop.${JSON.stringify({
            number: 2,
          })}`
          window.location.href = `#push.${JSON.stringify({
            screen: 'WebEventPage',
            prop: {
              eventPageMapping: {
                redirectUrl: `${result.data?.redirectUrl}/zeroWonStoreItemPage/?token=${token}&randomString=${randomString}`,
              },
            },
          })}`
        } else {
        }
      } else {
        alert.show('랜덤 쿠폰을 받았어요', {
          closeCopy: '확인',
        })
      }
    } else if (result?.data?.msg === 'already invited') {
      alert.show('이미 참여한 내역이 있어요', {
        closeCopy: '확인',
      })
    } else if (result?.data?.msg === 'invitee is under age') {
      alert.show('본 이벤트는 20세 이상 고객만 참여 가능해요', {
        closeCopy: '확인',
      })
    } else if (result?.data?.msg === 'already did 3 invite') {
      alert.show('참여 횟수를 다 소진했어요. 24시간 후에 다시 시도해 주세요.', {
        closeCopy: '확인',
      })
    } else if (result?.data?.msg === 'left service in 24 hours') {
      alert.show('24시간 이내 탈퇴한 이력이 있는 고객은 참여 할 수 없어요', {
        closeCopy: '확인',
      })
    } else {
      alert.show(`알 수 없는 오류가 발생했어요.\n다시 시도해주세요`, {
        closeCopy: '확인',
      })
    }
    isButtonDisable = false
  }

  return (
    <Layout>
      <Header />
      <div style={{ overflowY: 'scroll', height: '93%' }}>
        {ongoingZeroWonStoreFriendTeamInfo?._id ? (
          <div
            style={{
              backgroundColor: '#EE8254',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                color: 'white',
                fontSize: 18,
                fontWeight: 'bold',
                padding: 16,
              }}
            >
              {ongoingZeroWonStoreFriendTeamInfo?.hostUserInfo?.userName}님의
              0원 상점
            </div>
            <div
              style={{
                backgroundColor: '#fff',
                width: '90%',
                borderRadius: 10,
                overflow: 'hidden',
                padding: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: 16,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  backgroundColor: '#FCF8EC',
                  borderRadius: 4,
                  overflow: 'hidden',
                  padding: 8,
                }}
              >
                <div
                  style={{
                    width: 120,
                    height: 120,
                    borderRadius: 4,
                    backgroundColor: 'gray',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    style={{ width: 120, height: 120, borderRadius: 4 }}
                    src={
                      ongoingZeroWonStoreFriendTeamInfo?.itemInfo
                        ?.mainImageUri[0]
                    }
                  />
                </div>
                <div
                  style={{
                    paddingLeft: 16,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ paddingLeft: 8, paddingTop: 8 }}>
                    {ongoingZeroWonStoreFriendTeamInfo?.itemInfo?.itemTitle}
                  </div>
                  <div
                    style={{
                      borderRadius: 8,
                      padding: 8,
                      fontWeight: 'bold',
                      paddingLeft: 8,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {ongoingZeroWonStoreFriendTeamInfo?.createdAt && (
                      <BlackTextTimer
                        timeStamp={
                          new Date(ongoingZeroWonStoreFriendTeamInfo?.createdAt)
                        }
                        timerMinutes={60 * 24}
                      />
                    )}
                    <span style={{ paddingLeft: 4 }}>남음</span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  padding: 8,
                  textAlign: 'center',
                  lineHeight: '21.6px',
                }}
              >
                {ongoingZeroWonStoreFriendTeamInfo?.hostUserInfo?.userName}
                님이 지금까지{' '}
                <span style={{ fontWeight: 'bold', color: '#ff3e3e' }}>
                  {commaNumber(
                    (
                      ongoingZeroWonStoreFriendTeamInfo?.goalPrice -
                      ongoingZeroWonStoreFriendTeamInfo?.remainedPrice
                    ).toFixed(),
                  )}
                  원
                </span>
                을 할인 받았어요.
                <br />
                쿠폰을 받고{' '}
                {ongoingZeroWonStoreFriendTeamInfo?.hostUserInfo?.userName}
                님에게{' '}
                <span style={{ fontWeight: 'bold', color: '#ff3e3e' }}>
                  최대 5만원
                </span>
                의 할인을 도와주세요!
              </div>
              <div
                style={{
                  backgroundColor: '#FBE5BD',
                  width: '100%',
                  borderRadius: '12px',
                  height: '24px',
                  boxSizing: 'border-box',
                  marginTop: 4,
                  marginBottom: 12,
                  overflow: 'hidden',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#F09637',
                    width: `${
                      (1 -
                        ongoingZeroWonStoreFriendTeamInfo?.remainedPrice /
                          ongoingZeroWonStoreFriendTeamInfo?.goalPrice) *
                      100
                    }%`,
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                    height: '100%',
                    marginBottom: 8,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: 'white',
                    lineHeight: '24px',
                  }}
                >
                  {(
                    (1 -
                      ongoingZeroWonStoreFriendTeamInfo?.remainedPrice /
                        ongoingZeroWonStoreFriendTeamInfo?.goalPrice) *
                    100
                  ).toFixed(1)}
                  %
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: 8,
                }}
              >
                <button
                  onClick={() => {
                    if (isButtonDisable) {
                      return
                    }
                    isButtonDisable = true
                    debounce(discountButton, 500)
                  }}
                  style={{
                    borderWidth: 0,
                    paddingTop: 12,
                    paddingBottom: 12,
                    paddingRight: 24,
                    paddingLeft: 24,
                    fontSize: 16,
                    borderRadius: 24,
                    color: 'white',
                    backgroundColor: isButtonActive(
                      !!ongoingZeroWonStoreFriendTeamInfo?.guestUserInfosArray?.find(
                        (x) =>
                          JSON.stringify(x?.userId) ===
                            JSON.stringify(
                              ongoingZeroWonStoreFriendTeamInfo?.requestUserId,
                            ) && x?.userId,
                      ),
                      inviteList?.length >= 3,
                    )
                      ? '#E8612D'
                      : '#ccc',
                    fontWeight: 600,
                    width: '100%',
                  }}
                >
                  {ongoingZeroWonStoreFriendTeamInfo?.hostUserInfo?.userName}
                  님이 뿌린 쿠폰 받기
                </button>
              </div>
              <div
                style={{
                  marginBottom: 12,
                  textAlign: 'center',
                  fontWeight: '500',
                  color: '#333',
                }}
              >
                {inviteList?.length >= 3
                  ? '참여 기회를 모두 사용했어요'
                  : `오늘 ${3 - inviteList?.length}번 더 받기 가능해요`}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                textAlign: 'center',
                paddingTop: 16,
                paddingBottom: 16,
                fontSize: 21,
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: '#ff3e3e',
                lineHeight: '24.6px',
              }}
            >
              0원 상점 시작하기
              <div style={{ fontWeight: '500', marginTop: 12, fontSize: 18 }}>
                0원 상점을 시작하고 0원에 상품을 받아보세요
              </div>
            </div>
          </>
        )}

        {toggles?.find(({ lureDeal }) => lureDeal) &&
          ongoingZeroWonStoreTeamInfo?._id &&
          lureDealItem?.itemId &&
          !lureDealItem?.soldOut && (
            <div
              style={{
                paddingLeft: 12,
                paddingRight: 12,
                boxSizing: 'border-box',
                marginTop: 12,
                marginBottom: 12,
                fontWeight: 600,
                fontSize: 18,
              }}
            >
              <div>오늘만 이 가격, 순삭특가!</div>
              <button
                style={{
                  boxSizing: 'border-box',
                  marginTop: 12,
                  display: 'flex',
                  backgroundColor: 'transparent',
                  borderWidth: 0,
                }}
                onClick={() => {
                  window.location.href = `#pushToItemScreen.${JSON.stringify({
                    screen: 'MainStackDItemScreen',
                    prop: {
                      itemId: lureDealItem?.itemId,
                      itemInfo: lureDealItem?.itemInfo,
                      type: 'overProducedDeal',
                      enteringComponent: 'zeroWonStoreDealComponent',
                    },
                  })}`
                }}
              >
                <img
                  src={lureDealItem?.mainImageUris?.[0]}
                  style={{
                    width: '32vw',
                    marginLeft: 0,
                    paddingLeft: 0,
                  }}
                />
                <div
                  style={{
                    marginLeft: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '32vw',
                  }}
                >
                  <div>
                    <div
                      style={{
                        fontSize: 18,
                        color: 'black',
                        textAlign: 'left',
                        fontWeight: 600,
                        lineHeight: '1.3em',
                        height: '2.6em',
                        overflow: 'hidden',
                      }}
                    >
                      {lureDealItem?.itemTitle}
                    </div>
                    <div
                      style={{
                        marginTop: 4,
                        fontSize: 15,
                        color: '#ff3e3e',
                        textAlign: 'left',
                        fontWeight: 600,
                        overflow: 'hidden',
                      }}
                    >
                      {lureDealItem?.lureStock}
                    </div>
                  </div>
                  <div>
                    <div style={{ display: 'flex', marginBottom: 2 }}>
                      <div
                        style={{
                          fontSize: 16,
                          color: '#ff3e3e',
                          textAlign: 'left',
                          fontWeight: 600,
                          marginRight: 4,
                        }}
                      >
                        오늘의 특가
                      </div>
                      <div
                        style={{
                          fontSize: 15,
                          color: '#ccc',
                          textAlign: 'left',
                          fontWeight: 600,
                        }}
                      >
                        {commaNumber(lureDealItem?.individualPurchasePrice)}원
                      </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          fontSize: 18,
                          color: '#ff3e3e',
                          textAlign: 'left',
                          fontWeight: 600,
                          marginRight: 4,
                        }}
                      >
                        {Math.floor(
                          100 -
                            (100 * lureDealItem?.minDealTeamPurchasePrice) /
                              lureDealItem?.individualPurchasePrice,
                        )}
                        %
                      </div>
                      <div
                        style={{
                          fontSize: 18,
                          color: 'black',
                          textAlign: 'left',
                          fontWeight: 600,
                        }}
                      >
                        {commaNumber(lureDealItem?.minDealTeamPurchasePrice)}원
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          )}
        {!ongoingZeroWonStoreTeamInfo?._id && zeroWonStoreItemList && (
          <>
            <div
              ref={scrollRef}
              style={{
                display: 'flex',
                justifyContent: 'center',
                boxSizing: 'border-box',
                padding: 5,
                backgroundColor: '#F4F6F8',
              }}
            >
              <img
                style={{
                  width: '100%',
                  borderRadius: 10,
                  marginTop: 5,
                  marginBottom: 5,
                }}
                src='/images/ZeroWonStoreProcess.png'
              />
            </div>
            {zeroWonStoreItemList?.map((each, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: 150,
                  width: '100vw',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    height: '80%',
                    width: '30vw',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 30,
                    boxSizing: 'border-box',
                    marginLeft: 15,
                  }}
                >
                  <img
                    src={each?.mainImageUri}
                    alt=''
                    style={{ borderRadius: 30, height: 135, width: 135 }}
                  />
                </div>
                <div style={{ width: '70vw', flex: 1 }}>
                  <div
                    style={{
                      height: 80,
                      justifyContent: 'flex-start',
                      display: 'flex',
                      boxSizing: 'border-box',
                      padding: 20,
                      fontSize: 14,
                      fontWeight: 'bold',
                    }}
                  >
                    {each?.itemTitle}
                  </div>
                  <div
                    style={{
                      height: 70,
                      justifyContent: 'flex-end',
                      display: 'flex',
                      boxSizing: 'border-box',
                      padding: 15,
                    }}
                  >
                    <button
                      onClick={async () => {
                        // 토큰의 user document가 가입이 안되어 있는 경우
                        const userInfo =
                          await backendApis.getUserBasicInfoWithAuthentication(
                            token,
                          )
                        // 로그인 안되어 있는 경우
                        if (
                          userInfo?.status !== 200 ||
                          userInfo?.data?.phoneNumber === '' ||
                          userInfo?.data?.userName === ''
                        ) {
                          window.location.href = '#openLoginModal.'
                          return
                        }
                        if (ongoingZeroWonStoreTeamInfo?._id) {
                          alert.show(
                            `지난 0원 상점을 열고나서 24시간 이후에 새로운 0원 상점을 열 수 있어요.`,
                          )
                          return
                        }
                        setItemId(each?._id)
                        setShowSelectConfirmModal(true)
                        // const result = await backendApis.getEventPageMappingUrl(
                        //   token,
                        //   'GET',
                        //   { eventPageName: 'zeroWonStore' },
                        // )
                        // = `#push.${JSON.stringify({
                        //   screen: 'WebEventPage',
                        //   prop: {
                        //     eventPageMapping: {
                        //       redirectUrl: `${result.data?.redirectUrl}/addressInfo/?token=${token}&itemId=${each?._id}&itemTitle=${each?.itemTitle}&mainImageUri=${each?.mainImageUri}&randomString=${randomString}`,
                        //     },
                        //   },
                        // })}`
                      }}
                      style={{
                        backgroundColor: '#EE3E3E',
                        color: 'white',
                        borderWidth: 0,
                        borderRadius: 20,
                        width: 120,
                        height: '80%',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                    >
                      선택하기
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
        <div
          style={{
            width: '100%',
            backgroundColor: '#F4F6F8',
            boxSizing: 'border-box',
            padding: 16,
            paddingBottom: 56,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 8 }}>
            유의사항
          </div>
          <div style={{ lineHeight: '22.6px' }}>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 한번에 하나의 상품만 진행할 수 있어요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 성공한 상품은 재도전이 안돼요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 20세 이상의 고객만 참여갸 가능해요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 지난 0원 상점을 열고나서 24시간 이후에 새로운 0원 상점을 열 수
              있어요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 성공하였을 경우 24시간 동안만 상품 주문이 가능해요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 성공한 이후 24시간이 지나면 상품 주문을 할 수 없어요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 일반 상품 구매를 통해 추가할인을 받은 후, 일반 상품을 의도적으로
              취소하면 0원 상품 수령에 제한이 있을 수 있어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 경품은 영업일 기준 20일 내외로 발송되며 지정일 배송과 출고 후
              주소 변경은 불가능해요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 사전에 고지된 상품의 옵션은 변경이 불가능해요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 제한 시간이 종료되면 이전 기록은 인정되지 않으며 처음부터 다시
              시작 해야해요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 상품은 입력하신 주소지로 배송돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 친구를 한명 초대할 때 마다 가격이 할인돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 친구 초대를 통한 금액은 이벤트참여시기, 이벤트달성정도 등 다양한
              요소를 고려하여 랜덤하게 지급돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 가입한지 2시간 이내이며 올웨이즈의 타 이벤트 참여 이력이 없는
              고객만 신규 고객으로 인정돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 이벤트가 시작된 이후 가입한 고객은 반드시 본인인증을 진행해야만
              신규 고객으로 인정돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 기존 친구를 초대해도 랜덤으로 큰 할인 금액이 주어져요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 탈퇴 후 재참여하시면 새친구 보상을 드리지 않으며, 회원탈퇴할
              경우 24시간 동안 친구에게 가격 할인을 제공할 수 없어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 친구에게 하루 3번 할인을 도와줄 수 있고, 오전 9시를 기준으로
              다시 3번의 기회가 생겨요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 부정한 방법을 통한 참여는 인정되지 않으며, 성공하여도 취소될 수
              있습니다.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 재고 소진 시 유사 상품이나 해당 금액으로 대체하여 제공될 수
              있어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 10만원 이상의 상품에 대해서는 제세공과금이 발생할 수 있어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 본 이벤트는 당사의 사정에 의해 진행 중인 고객님이 있어도 사전
              고지 없이 변경 또는 조기종료될 수 있어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 문의는 내 정보 > 고객문의 > 1:1 실시간 상담하기에서 하실 수
              있어요.
            </div>
          </div>
        </div>
      </div>
      {showNewUserNudgeModal && (
        <NewUserNudgeModal
          setShowNewUserNudgeModal={setShowNewUserNudgeModal}
          userName={userName}
          onClick={async () => {
            const result = await backendApis.getEventPageMappingUrl(
              token,
              'GET',
              {
                eventPageName: 'zeroWonStore',
              },
            )
            if (result?.status === 200) {
              const a = await backendApis.createZeroWonStoreTeamInfo(
                token,
                'POST',
                // dev에서의 감귤 아이디 6363d364e252a3fc17b592d4 production에서는 6363d220f17fd2dd1a6f93cb
                { itemId: '6363d220f17fd2dd1a6f93cb' },
              )
              window.location.href = `#pop.${JSON.stringify({
                number: 1,
              })}`
              window.location.href = `#push.${JSON.stringify({
                screen: 'WebEventPage',
                prop: {
                  eventPageMapping: {
                    redirectUrl: `${result.data?.redirectUrl}/zeroWonStoreItemPage/?token=${token}&randomString=${randomString}`,
                  },
                },
              })}`
            } else {
            }
          }}
        />
      )}
      {showSelectConfirmModal && (
        <ZeroWonStoreSelectConfirmModal
          setShowSelectConfirmModal={setShowSelectConfirmModal}
          token={token}
          itemId={itemId}
          randomString={randomString}
          onClick={() => {
            // pageScroll()
          }}
        />
      )}
    </Layout>
  )
}

export default ZeroWonStoreFriendItemPage
