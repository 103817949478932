const sendMessage = (props) => {
  if (props?.type === undefined) {
    alert(`props?.type === undefined ${props}`)
    return
  }
  if (props?.payload === undefined) {
    console.warn(`props?.payload === undefined ${props}`)
    return
  }

  console.log(`sendMessage ${JSON.stringify(props)}`)
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({
      ...props,
    }),
  )
}

export { sendMessage }
