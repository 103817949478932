import React from 'react'

const AuthorCommentSection = ({ author, comment, isVerticalToon }) => {
  // If there's no comment, you could return null or a default message
  if (!comment) return null

  return (
    <div className='rounded-lg p-4 my-4'>
      <div className='font-bold mb-1'>{author}</div>
      <p
        className={
          isVerticalToon ? (comment.length >= 36 ? 'mb-24' : 'mb-14') : ''
        }
      >
        {comment}
      </p>
      {/* Tailwind CSS로 추가 스타일링 가능 */}
    </div>
  )
}

export default AuthorCommentSection
