import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import DailyCheckInAction from '../../actions/DailyCheckInAction'
import UserStore from '../../stores/UserStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import backendApis from '../../utils/backendApis'
import ImageAtom from '../atoms/imageAtom'

const DescriptionTranslator = (type) => {
  switch (type) {
    // cpm, cpc는 노출형 광고이고 이외는 모두 액션형 광고
    case 'cpm':
      return '클릭하'
    case 'cpc':
      return '클릭하'
    case 'cpa':
      return '참여하'
    case 'cpk':
      return '카카오톡 채널 추가하'
    case 'cpq':
      return '퀴즈풀'
    case 'cpqlite':
      return '퀴즈풀'
    case 'cpi':
      return '설치하'
    case 'cps':
      return '구매하'
    case 'cptiktok':
      return '틱톡 팔로우하'
    case 'cpl':
      return '페이스북 좋아요하'
    case 'cpyoutube':
      return '유튜브 구독하'
    case 'cpnstore':
      return '네이버 스토어 알림설정하'
    case 'cpinsta':
      return '인스타그램 팔로우하'
    case 'cpylike':
      return '유튜브 구독 및 좋아요하'
    case 'cpe':
      return '이벤트 참여하'
    case 'cpcquiz':
      return '퀴즈풀'
    default:
      return '참여하'
  }
}

const OfferwallContentItemButton = observer(({ itemInfo }) => {
  return (
    <button
      type='button'
      className={`px-2.5 py-2 text-xs font-semibold rounded-lg  whitespace-nowrap duration-200 active:brightness-90 active:scale-95
       ${
         itemInfo?.isParticipated
           ? 'bg-gray-100 text-gray-500'
           : 'text-red-500 bg-red-50 active:brightness-90 active:scale-95 duration-200'
       }`}
    >
      {itemInfo?.isParticipated ? (
        <>
          {itemInfo?.reward_condition === 'click' ? '참여 완료' : '참여 확인중'}
        </>
      ) : (
        <>
          {itemInfo?.rewardPoint
            ? `${itemInfo?.rewardPoint?.toLocaleString()}원 받기`
            : '더 보기'}
        </>
      )}
    </button>
  )
})

const OfferwallContentItem = ({
  itemInfo,
  empty = false,
  isFrom = 'OfferWall_CheckIn',
  isScrolling = false,
  icon = false,
  setBottomsheetData,
  setOfferwallList,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const onClick = async () => {
    if (isLoading) return
    setIsLoading(true)

    const adType = itemInfo?.type
    const custom = {
      placement: UserStore.offerwallInfo.placement,
      networkName: UserStore.offerwallInfo.networkName,
      rewardPoint: itemInfo?.rewardPoint,
      userId: UserStore.userInfo?._id,
    }
    let url = ''

    if (adType === 'cpm' || adType === 'cpc') {
      // cpm, cpc는 노출형 광고이므로 click_url로 이동
      url = `${itemInfo?.creative?.click_url}&custom=${encodeURIComponent(
        JSON.stringify(custom),
      )}`
      DailyCheckInAction.goExternalbrowser(url)
    } else {
      // cpm, cpc 이외는 액션형 광고이므로 참여 api 호출
      const query = {
        campaignId: itemInfo.id,
        payload: itemInfo.payload,
        userId: UserStore.userInfo._id,
        uniqueUserId: UserStore.offerwallInfo.uniqueUserId,
        unitId: UserStore.offerwallInfo.unitId,
        ifa: UserStore.offerwallInfo.ifa,
        ifv: UserStore.offerwallInfo.ifv,
        placement: UserStore.offerwallInfo.placement,
        networkName: UserStore.offerwallInfo.networkName,
        rewardPoint: itemInfo?.rewardPoint,
      }
      const result = await backendApis.getOfferwallAdBuzzvil(query)
      if (result?.status === 200) {
        setBottomsheetData({
          isOpen: true,
          ad: {
            ...itemInfo,
            action_description: result.data?.action_description,
            landing_url: result.data?.landing_url,
          },
        })
      } else {
        THModalStore.setType({
          type: 'confirm',
          config: {
            forceOpen: true,
            title: '참여 안내',
            description:
              result?.data?.msg || '이미 참여하셨거나 현재 참여가 어려워요',
            src: null,
            buttonText: '확인',
            onConfirm: () => {
              THModalStore.close()
            },
            clickable: true,
          },
        })
      }
    }
    setOfferwallList((prevData) =>
      prevData?.map((ad) =>
        ad.id === itemInfo?.id ? { ...ad, isParticipated: true } : ad,
      ),
    )

    // click 로깅
    const clickLog = {
      eventType: 'ad_click',
      userId: UserStore.userInfo?._id,
      uniqueUserId: UserStore.offerwallInfo.uniqueUserId,
      unitId: UserStore.offerwallInfo.unitId,
      networkName: UserStore.offerwallInfo.networkName,
      placement: UserStore.offerwallInfo.placement,
      adId: itemInfo?.id,
      adType: itemInfo?.type,
      reward: itemInfo?.reward,
      rewardPoint: itemInfo?.rewardPoint,
      adName: itemInfo?.name,
      creativeTitle: itemInfo?.creative?.title,
      creativeDescription: itemInfo?.creative?.description,
      reward_condition: itemInfo?.reward_condition,
      check_participation_url: itemInfo?.check_participation_url || '',
    }
    backendApis.recordOfferwallEventLog(clickLog)

    setIsLoading(false)
  }

  // skeleton
  if (empty) {
    return (
      <div className='flex flex-col items-center w-full p-6 animate-pulse'>
        {/* image */}
        <div
          className='w-full h-full bg-gray-300 rounded-lg'
          style={{ aspectRatio: 2 }}
        />

        <div className='flex flex-row items-center justify-between w-full px-2 mt-4'>
          <div className='flex flex-row items-center mr-2 whitespace-normal'>
            {/* icon */}
            {icon && (
              <div className='w-12 h-12 mr-3 bg-gray-300 border rounded-xl' />
            )}

            {/* title, description */}
            <div className='flex flex-col'>
              <div className='w-40 h-5 mb-2 bg-gray-300 rounded' />
              <div className='w-20 h-5 bg-gray-300 rounded' />
            </div>
          </div>

          {/* CTA button */}
          <div className='px-2.5 py-2 w-16 h-8 rounded-lg bg-gray-300' />
        </div>
      </div>
    )
  }

  return (
    <div className='flex w-full p-6'>
      <div className='flex flex-col items-center w-full' onClick={onClick}>
        <ImageAtom
          src={itemInfo?.creative?.image_url}
          className='object-cover w-full h-full border border-gray-200 rounded-lg'
          style={{ aspectRatio: 2 }}
        />

        <div className='flex flex-row items-center justify-between w-full px-2 mt-4'>
          <div className='flex flex-row items-center mr-2 whitespace-normal'>
            {icon && (
              <img
                className='w-12 h-12 mr-3 border rounded-2xl'
                alt='icon_image'
                src={itemInfo?.creative.icon_url}
              />
            )}

            <div className='flex flex-col text-left'>
              <div className='text-gray-700 '>
                {itemInfo?.creative?.title?.length > 18
                  ? `${itemInfo?.creative?.title.substring(0, 18)}..`
                  : itemInfo?.creative?.title}
              </div>
              <div className='font-bold text-gray-900 '>
                {DescriptionTranslator(itemInfo?.type)}고
              </div>
            </div>
          </div>

          <OfferwallContentItemButton itemInfo={itemInfo} />
        </div>
      </div>
    </div>
  )
}

export default OfferwallContentItem
