import styled from 'styled-components';

interface OptionSelectorProps<T> {
  label: string;
  options: T[];
  selectedOption: T;
  onSelect: (option: T) => void;
}

export const OptionSelector = <T extends string>({
  label,
  options,
  selectedOption,
  onSelect,
}: OptionSelectorProps<T>) => {
  return (
    <Option>
      <OptionText>{label}</OptionText>
      <OptionsContainer>
        {options.map((option, index) => {
          const isSelected = selectedOption === option;
          return (
            <OptionItem
              key={index}
              onClick={() => onSelect(option)}
              isSelected={isSelected}
            >
              {option}
            </OptionItem>
          );
        })}
      </OptionsContainer>
    </Option>
  );
};

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
`;

const OptionText = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
`;

const OptionsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const OptionItem = styled.div<{ isSelected: boolean }>`
  padding: 4px 16px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? '#30333D' : '#F6F6FA')};
  color: ${({ isSelected }) => (isSelected ? '#ffffff' : '#71737C')};
  border-radius: 4px;

  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
`;
