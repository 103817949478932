export enum Theme {
  Light = 'Light',
  Dark = 'Dark',
  Sepia = 'Sepia',
  NightBlue = 'NightBlue',
  ForestGreen = 'ForestGreen',
}

export enum FontFamily {
  고딕 = '고딕',
  바탕 = '바탕',
  명조 = '명조',
}

export enum DocumentAlign {
  Horizontal = '페이지',
  Vertical = '스크롤',
}

export enum TransitionEffect {
  Fade = '서서히',
  Slide = '슬라이드',
  None = '없음',
}

export enum FontSize {
  XSmall = 1,
  Small = 2,
  Medium = 3,
  Large = 4,
  XLarge = 5,
}

export enum LineHeight {
  XSmall = 1,
  Small = 2,
  Medium = 3,
  Large = 4,
  XLarge = 5,
}

export enum Padding {
  XSmall = 1,
  Small = 2,
  Medium = 3,
  Large = 4,
  XLarge = 5,
}
