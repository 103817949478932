/* eslint-disable react/button-has-type */

/* eslint-disable no-nested-ternary */

/* eslint-disable react/jsx-key */
import React, { useState } from 'react'
import { useFilters, usePagination, useSortBy, useTable } from 'react-table'

function DataTable({ data, columns }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of using 'rows', we'll use page
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: 'settlementAmount', // '총 매출액' 열의 id
            desc: true, // 내림차순으로 정렬
          },
        ],
      }, // Pass our initial page index
    },
    useSortBy,
    usePagination, // Using the usePagination hook
  )

  return (
    <>
      <div className='flex justify-between items-center my-4'>
        <h1 className='text-xl font-bold'>작품별 매출</h1>
      </div>
      <table
        {...getTableProps()}
        className='min-w-full divide-y divide-gray-200'
      >
        <thead className='bg-gray-50'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className='bg-white divide-y divide-gray-200'
        >
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => (
                  <td
                    {...cell.getCellProps()}
                    className={`px-6 py-4 whitespace-nowrap ${
                      i < 3 ? 'text-center' : 'text-right'
                    }`}
                  >
                    {typeof cell.value === 'number'
                      ? cell.value.toLocaleString()
                      : cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className='py-3 flex items-center justify-center'>
        <div className='flex items-center'>
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className='mr-1 btn'
          >
            {'<<'}
          </button>{' '}
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className='mr-1 btn'
          >
            {'<'}
          </button>{' '}
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className='mr-1 btn'
          >
            {'>'}
          </button>{' '}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className='btn'
          >
            {'>>'}
          </button>{' '}
        </div>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
      </div>
    </>
  )
}

export default DataTable
