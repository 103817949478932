import { AppScreen } from '@stackflow/plugin-basic-ui'
import React from 'react'
import { FiChevronLeft } from 'react-icons/fi'

import THImageText from '../components/treasureHunt/THImageText'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'

/**
 * 올툰 청소년 보호정책
 */
const AltoonTeenProtectionTerms = () => {
  const { pop } = useMyFlow()

  const HeaderComponent = () => {
    return (
      <section className='w-full h-16 bg-white z-10 py-2 flex flex-row items-center justify-between overflow-hidden'>
        <button
          type='button'
          aria-label='back'
          className='px-3 py-2 whitespace-nowrap'
          onClick={async () => {
            pop()
          }}
        >
          <FiChevronLeft className='w-7 h-7 stroke-[0.2vw]' />
        </button>
        <div>올툰 청소년 보호정책</div>
        <div className='w-12' />
      </section>
    )
  }

  const TermsOfUse = () => {
    return (
      <div className='px-4 py-2 text-[3.6vw]'>
        <button type='button' className='w-full flexRow justify-between'>
          <div>올툰 청소년 보호정책</div>
        </button>
        <div>
          <THImageText
            fontSize={3}
            style={{
              color: '#8E8D9B',
            }}
            text={`올툰을 이용해주시는 모든 회원님들께 감사드리며, 올툰은 청소년이 건강하고 안전하게 웹툰 콘텐츠를 즐길 수 있도록 다음과 같은 청소년 보호정책을 수립하여 시행하고 있습니다.
 
1. 청소년 보호 계획 수립
올툰은 청소년이 정신적, 신체적으로 유해한 환경으로부터 보호받고 유익한 환경에서 웹툰을 즐길 수 있도록 청소년 보호계획을 수립하고 시행하고 있습니다. 당사는 청소년의 유해정보 접근을 차단하기 위한 구체적인 방안을 마련하여, 올툰 서비스가 안전한 웹툰 환경을 제공하도록 최선을 다하고 있습니다.
 
2. 청소년 유해 정보에 대한 접근 제한 및 관리 조치
청소년이 유해 정보에 노출되지 않도록 철저히 관리하고 있으며, 이에 따라 청소년 유해 매체물에 대한 인증 시스템을 도입하여 적용하고 있습니다. 청소년 유해 정보로 지정된 작품은 본인 인증을 요구하며, 실시간 모니터링을 통해 청소년에게 유해 정보가 노출되지 않도록 체계적으로 관리하고 있습니다.
- 실시간 모니터링 체계 구축: 모든 댓글과 콘텐츠를 실시간으로 검토하여 유해 요소가 청소년에게 노출되는 것을 방지합니다.
- 성인 인증 서비스: 청소년 유해 정보 접근 시 성인 인증 시스템을 필수로 적용하여 안전한 환경을 조성하고 있습니다.
 
 3. 청소년 보호를 위한 교육 및 대응 체계
올툰은 청소년 보호 담당자 및 관련 서비스 담당자들을 대상으로 청소년 보호 관련 법령과 기준, 유해 정보 발견 시의 대처 방안 및 위반 사항 처리 절차에 대한 교육을 정기적으로 시행하고 있습니다. 이를 통해 올툰의 모든 구성원이 청소년 보호에 대한 책임을 이해하고, 안전한 서비스 제공을 위해 일관된 대응을 할 수 있도록 노력하고 있습니다.
 
 4. 청소년 보호 책임자 및 담당자 정보
이용자분들께서는 아래 명시된 [청소년 보호 책임자]에게 이메일을 통해 피해 상담 및 고충 처리를 요청하실 수 있습니다.
 
[청소년보호책임자]
ㆍ성명 : 김성아
ㆍ이메일 : altoon@levit.com`}
          />
        </div>
      </div>
    )
  }

  return (
    <AppScreen>
      <div className='pb-20'>
        <HeaderComponent />
        <TermsOfUse />
      </div>
    </AppScreen>
  )
}

export default AltoonTeenProtectionTerms
