import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

const BannerAdminScreen = observer(() => {
  const [bannerList, setBannerList] = useState()

  const fetchData = async () => {
    const result = await altoonCMSBackendApis.loadToonBanner()
    if (result?.status === 200) {
      setBannerList(result?.data)
    }
  }

  useEffect(() => {
    AltoonCMSUserStore.setIsLoading(true)
    fetchData()
    setTimeout(() => {
      AltoonCMSUserStore.setIsLoading(false)
    }, 500)
  }, []) // currentPage 또는 itemsPerPage가 변경될 때마다 요청

  const RegisteredProviderList = ({ bannerList }) => {
    return (
      <div
        className={`w-full ${
          bannerList?.length > 0 ? 'h-[40vh]' : ''
        } overflow-y-scroll bg-white`}
      >
        {bannerList?.length > 0 && (
          <table>
            <thead className='whitespace-nowrap text-[1vh] border-b-2'>
              <tr>
                <th>고유아이디</th>
                <th>작품명</th>
                <th>이벤트 아이디</th>
                <th>작품 아이디</th>
                <th>1회차 아이디</th>
                <th>이미지</th>
                <th>노출여부</th>
                <th>회차</th>
                <th>종류</th>
                <th>삭제하기</th>
              </tr>
              <tr>
                <th>id</th>
                <th>toonTitle</th>
                <th>eventId</th>
                <th>toonId</th>
                <th>chapterId</th>
                <th>imageUri</th>
                <th>isDisplayed</th>
                <th>episode</th>
                <th>type</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 12, textAlign: 'center' }}>
              {bannerList?.map((item) => (
                <tr key={item._id} className='border-b'>
                  <td style={{ fontSize: 4, width: '5vw', textWrap: 'nowrap' }}>
                    {item._id}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.toonTitle}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.eventId}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.toonId}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.chapterId}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.imageUri}
                  </td>

                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.isDisplayed === true ? 'O' : 'X'}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.episode}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.type}
                  </td>
                  <td>
                    <button
                      type='button'
                      style={{ margin: 4, width: '5vw', textWrap: 'nowrap' }}
                      onClick={async () => {
                        const deleteConfirmation =
                          window.confirm('삭제하시겠습니까?')

                        if (deleteConfirmation) {
                          const result =
                            await altoonCMSBackendApis.deleteToonPromotion({
                              _id: item._id,
                              promotionType: 'banner',
                            })
                          if (result?.status === 200) {
                            alert('삭제되었습니다.')
                            fetchData()
                            return
                          }
                          alert(
                            '오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.',
                          )
                        }
                      }}
                    >
                      삭제하기
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  const ToonBannerRegisterSection = () => {
    const [toonId, setToonId] = useState('')
    const [toonTitle, setToonTitle] = useState('')
    const [chapterId, setChapterId] = useState('')
    const [episode, setEpisode] = useState('')
    const [imageUri, setImageUri] = useState('')

    return (
      <>
        <div className='p-4 bg-white rounded-xl'>
          <div className='py-4 font-bold text-[2.6vh]'>작품 배너 등록</div>
          <div className='grid-cols-3 grid'>
            <div className='pt-4 flexRow items-center'>
              작품아이디
              <input
                className='ml-2'
                value={toonId}
                onChange={(e) => setToonId(e.target.value)}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              작품명
              <input
                className='ml-2'
                value={toonTitle}
                onChange={(e) => setToonTitle(e.target.value)}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              회차 아이디
              <input
                className='ml-2'
                value={chapterId}
                onChange={(e) => setChapterId(e.target.value)}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              회차
              <input
                className='ml-2'
                value={episode}
                onChange={(e) => setEpisode(e.target.value)}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              이미지 주소
              <input
                className='ml-2'
                value={imageUri}
                onChange={(e) => setImageUri(e.target.value)}
              />
            </div>
          </div>
          <div style={{ paddingTop: 12 }}>
            <button
              type='button'
              className='mt-10 px-4 py-2 bg-gray-500 text-white rounded-xl'
              onClick={async () => {
                if (
                  !toonId ||
                  !toonTitle ||
                  !chapterId ||
                  !episode ||
                  !imageUri
                ) {
                  return
                }

                const result = await altoonCMSBackendApis.registerBanner({
                  type: 'toon',
                  toonId,
                  toonTitle,
                  chapterId,
                  episode,
                  imageUri,
                })
                if (result?.status === 200) {
                  alert('등록되었습니다.')
                  fetchData()
                  setToonId('')
                  setToonTitle('')
                  setChapterId('')
                  setEpisode('')
                  setImageUri('')
                } else {
                  alert(
                    '오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.',
                  )
                }
              }}
            >
              작품 배너 등록하기
            </button>
          </div>
        </div>
      </>
    )
  }

  const EventBannerRegisterSection = () => {
    const [eventId, setEventId] = useState('')
    const [eventTitle, setEventTitle] = useState('')
    const [imageUri, setImageUri] = useState('')

    return (
      <>
        <div className='p-4 bg-white rounded-xl'>
          <div className='py-4 font-bold text-[2.6vh]'>이벤트 배너 등록</div>
          <div className='grid-cols-3 grid'>
            <div className='pt-4 flexRow items-center'>
              이벤트 아이디
              <input
                className='ml-2'
                value={eventId}
                onChange={(e) => setEventId(e.target.value)}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              이벤트명
              <input
                className='ml-2'
                value={eventTitle}
                onChange={(e) => setEventTitle(e.target.value)}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              이미지 주소
              <input
                className='ml-2'
                value={imageUri}
                onChange={(e) => setImageUri(e.target.value)}
              />
            </div>
          </div>
          <div style={{ paddingTop: 12 }}>
            <button
              type='button'
              className='mt-10 px-4 py-2 bg-gray-500 text-white rounded-xl'
              onClick={async () => {
                if (!eventId || !eventTitle || !imageUri) {
                  return
                }

                const result = await altoonCMSBackendApis.registerBanner({
                  type: 'special',
                  eventId,
                  toonTitle: eventTitle,
                  imageUri,
                })
                if (result?.status === 200) {
                  alert('등록되었습니다.')
                  fetchData()
                  setEventId('')
                  setEventTitle('')
                  setImageUri('')
                } else {
                  alert(
                    '오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.',
                  )
                }
              }}
            >
              작품 배너 등록하기
            </button>
          </div>
        </div>
      </>
    )
  }

  const UpdateToonInfo = () => {
    const [bannerId, setBannerId] = useState('')
    const [field, setField] = useState('')
    const [value, setValue] = useState('')

    return (
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>배너 정보 수정</div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 12,
          }}
        >
          배너 아이디
          <div style={{ marginBottom: 8 }}>
            <input
              type='text'
              value={bannerId}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setBannerId(e.target.value)
              }}
            />
          </div>
          바꿀 field
          <div style={{ marginBottom: 8 }}>
            <input
              type='text'
              value={field}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setField(e.target.value)
              }}
            />
          </div>
          value
          <div style={{ marginBottom: 4 }}>
            <input
              type='text'
              value={value}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setValue(e.target.value)
              }}
            />
          </div>
        </div>

        <button
          type='button'
          className='mt-10 px-4 py-2 bg-gray-500 text-white rounded-xl'
          onClick={async () => {
            const result = await altoonCMSBackendApis.updateBannerInfo({
              bannerId,
              field,
              value,
            })
            if (result?.status === 200) {
              alert('업데이트 완료')
            }
          }}
        >
          작품 정보 업데이트
        </button>
      </div>
    )
  }

  return (
    <div className='w-full my-10 min-w-7xl max-w-10xl select-text'>
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>배너 관리</div>
        {bannerList?.length > 0 && (
          <RegisteredProviderList bannerList={bannerList} />
        )}
      </div>
      <div className='h-4' />
      <UpdateToonInfo />
      <div className='h-4' />
      <ToonBannerRegisterSection />
      <div className='h-4' />
      <EventBannerRegisterSection />
    </div>
  )
})

export default BannerAdminScreen
