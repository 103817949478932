import React, { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'

const formatTime = (totalSeconds) => {
  const rawSeconds = Math.floor(totalSeconds % 60)
  const rawMinutes = Math.floor((totalSeconds / 60) % 60)
  const rawHours = Math.floor(totalSeconds / 3600)

  const hours = rawHours.toString().padStart(2, '0') // Always two digits
  const minutes = rawMinutes.toString().padStart(2, '0') // Always two digits
  const seconds = rawSeconds.toString().padStart(2, '0') // Always two digits

  return { seconds, minutes, hours }
}

const TreasureHuntTimer = ({
  expiryTimestamp,
  onExpire = () => {},
  style,
  format = 'normal',
}) => {
  const { totalSeconds } = useTimer({ expiryTimestamp, onExpire })
  const { hours, minutes, seconds } = formatTime(totalSeconds)

  if (format === 'number') {
    return (
      <div
        className='text-white default flex-row justify-center items-center font-bold'
        style={style}
      >
        {`${hours !== '00' ? `${hours}:` : ''}${minutes}:${seconds}`}
      </div>
    )
  }

  return (
    <div
      className='text-white default flex-row justify-center items-center font-bold'
      style={style}
    >
      {`${hours > 0 ? `${hours}시간 ` : ''}
      ${hours > 0 || minutes > 0 ? `${minutes}분 ` : ''}
      ${seconds}초 남음`}
    </div>
  )
}

export default TreasureHuntTimer
