import { useEffect, useRef } from 'react'

import useEventCallback from '../../hooks/useEventCallback'

const callbacks = new Map()

// export type AlwayzObserverProps = React.ComponentProps<'div'> & {
//   callback: (entry: IntersectionObserverEntry) => void
//   options?: IntersectionObserverInit
//   className?: string
// }

const AlwayzObserver = ({
  callback,
  options,
  children,
  className,
  ...props
}) => {
  const elementRef = useRef(null)

  // eventCallback으로 참조를 유지해야 callback의 참조가 바뀔 때 unobserve되지 않음
  const eventCallback = useEventCallback((entry) => callback(entry))

  useEffect(() => {
    if (elementRef.current) {
      const element = elementRef.current
      const intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const cb = callbacks.get(entry.target)

          if (!cb) {
            return
          }

          cb(entry)
        })
      }, options)

      intersectionObserver.observe(element)
      callbacks.set(element, eventCallback)

      return () => {
        intersectionObserver.unobserve(element)
        callbacks.delete(element)
      }
    }
  }, [eventCallback, options])

  return (
    <div ref={elementRef} className={className} {...props}>
      {children}
    </div>
  )
}

export default AlwayzObserver
