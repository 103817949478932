import commaNumber from 'comma-number'
import { useCallback, useEffect, useMemo } from 'react'

import ImageAtom from '../../atoms/imageAtom'
import { handleOnClickFactory } from './itemNavigateHandler'

const CheckInItemComponent = ({
  itemInfo,
  empty = false,
  isFrom = 'CheckInMain',
  isScrolling = false,
}) => {
  const validHandleOnClick = useMemo(
    () => handleOnClickFactory(itemInfo, isFrom),
    [itemInfo, isFrom],
  )
  const handleOnClick = useCallback(() => {
    // if (!isScrolling) {
    return validHandleOnClick()
    // }
  }, [isScrolling, validHandleOnClick])

  const EmptyComponent = () => {
    return (
      <div className='flex flex-col items-start justify-center flex-1 w-full p-1'>
        <div
          className='bg-gray-100 rounded-lg'
          style={{
            width: '98%',
            height: '98%',
            aspectRatio: 1,
          }}
        />
        <div
          className='bg-gray-100 rounded-lg'
          style={{
            height: 28,
            width: '90%',
            marginTop: 10,
            marginBottom: 20,
            paddingBottom: 6,
          }}
        />
      </div>
    )
  }
  const ItemImageComponent = () => {
    return (
      <div className='flex flex-col items-center justify-start'>
        <ImageAtom
          src={itemInfo?.mainImageUris[0]}
          className='object-cover rounded-lg'
          width={256}
          height={256}
          style={{
            width: '98%',
            height: '98%',
            aspectRatio: 1,
            borderRadius: 12,
            borderWidth: 1,
            borderColor: 'rgba(0, 0, 0, 0.06)',
          }}
        />
      </div>
    )
  }
  const ItemTitleComponent = () => {
    return (
      <div
        className='overflow-hidden leading-tight line-clamp-2 text-ellipsis text-start'
        style={{
          marginTop: 10,
          fontSize: 14,
        }}
      >
        {itemInfo?.itemTitle}
      </div>
    )
  }
  const ItemPriceComponent = () => {
    return (
      <>
        {itemInfo?.individualPurchasePrice ? (
          <div
            style={{
              color: '#999999',
              textDecorationLine: 'line-through',
              fontSize: 14,
              marginTop: 1,
            }}
          >
            <span>{commaNumber(itemInfo?.individualPurchasePrice)}원</span>
          </div>
        ) : (
          <></>
        )}
        <div
          className='flex flex-row items-end whitespace-nowrap'
          style={{ fontWeight: 'bold' }}
        >
          <div
            style={{
              color: '#FF3E3E',
              fontSize: 16,
              marginRight: 3,
            }}
          >
            {Math.floor(itemInfo?.discountRate * 100)}%
          </div>
          <div className='flex flex-row items-end'>
            <div style={{ fontSize: 16, fontWeight: 'bold' }}>
              {commaNumber(itemInfo?.teamPurchasePrice)}
            </div>
            <div style={{ marginLeft: 1, marginBottom: 1, fontSize: 15 }}>
              원
            </div>
          </div>
        </div>
      </>
    )
  }
  const ItemReviewComponent = () => {
    if (itemInfo?.totalRatingCount > 0) {
      return (
        <>
          <div
            style={{
              paddingLeft: 1,
              fontSize: 12,
              paddingBottom: 3,
            }}
            className='flex flex-row items-center'
          >
            <div>
              <img
                alt=''
                style={{
                  marginRight: 4,
                  width: 14,
                  height: 14,
                }}
                src='/images/StarFullVer2.png'
              />
            </div>
            <div className='font-bold' style={{ marginRight: 4 }}>
              {`${(
                Math.ceil(
                  (itemInfo?.totalRatingScore / itemInfo?.totalRatingCount) *
                    10,
                ) / 10 || 0
              ).toFixed(1)}`}
            </div>
            <div
              style={{
                color: '#A5A6A8',
              }}
            >{`후기 ${commaNumber(itemInfo?.totalRatingCount || 0)}`}</div>
          </div>
        </>
      )
    }
  }

  if (empty) {
    return <EmptyComponent />
  }

  return (
    <button
      type='button'
      aria-hidden='true'
      className='flex items-center justify-start flex-1 flex-col w-full h-[320px]'
      onClick={handleOnClick}
    >
      <div className='flex flex-col items-center justify-start flex-1 p-1'>
        <ItemImageComponent />
        <div className='flex w-[94%] flex-col items-start justify-start flex-1 pb-2'>
          <ItemTitleComponent />
          <ItemPriceComponent />
          <ItemReviewComponent />
        </div>
      </div>
    </button>
  )
}

export default CheckInItemComponent
