import {
  DocumentAlign,
  FontFamily,
  FontSize,
  LineHeight,
  Padding,
  Theme,
} from '../types/viewerOptions';
import { css } from 'styled-components';

const paddingVal = (padding: Padding) => {
  switch (padding) {
    case 1:
      return {
        top: 24,
        side: 24,
        bottom: 24,
      };
    case 2:
      return {
        top: 32,
        side: 28,
        bottom: 32,
      };
    case 3:
      return {
        top: 40,
        side: 32,
        bottom: 40,
      };
    case 4:
      return {
        top: 48,
        side: 40,
        bottom: 48,
      };
    case 5:
      return {
        top: 56,
        side: 44,
        bottom: 56,
      };
    default:
      return {
        top: 40,
        side: 32,
        bottom: 40,
      };
  }
};

export const paddingCSS = (padding: Padding) => {
  return css`
    padding: ${paddingVal(padding).top}px ${paddingVal(padding).side}px;
  `;
};

export const lineHeightCSS = (lineHeight: LineHeight) => {
  switch (lineHeight) {
    case 1:
      return css`
        p {
          line-height: 16px !important;
        }
      `;
    case 2:
      return css`
        p {
          line-height: 20px !important;
        }
      `;
    case 3:
      return css`
        p {
          line-height: 24px !important;
        }
      `;
    case 4:
      return css`
        p {
          line-height: 28px !important;
        }
      `;
    case 5:
      return css`
        p {
          line-height: 32px !important;
        }
      `;
    default:
      return css`
        p {
          line-height: 32px !important;
        }
      `;
  }
};

export const fontSizeCSS = (fontSize: FontSize) => {
  switch (fontSize) {
    case 1:
      return css`
        font-size: 12px;
        letter-spacing: -0.4px;
      `;

    case 2:
      return css`
        font-size: 14px;
        letter-spacing: -0.4px;
      `;
    case 3:
      return css`
        font-size: 16px;
        letter-spacing: -0.4px;
      `;
    case 4:
      return css`
        font-size: 18px;
        letter-spacing: -0.4px;
      `;
    case 5:
      return css`
        font-size: 20px;
        letter-spacing: -0.4px;
      `;
    default:
      return css`
        font-size: 16px;
        letter-spacing: -0.4px;
      `;
  }
};

export const documentAlignCSS = (params: {
  documentAlign: DocumentAlign;
  padding: Padding;
  hasCover: boolean;
}) => {
  if (params.documentAlign === DocumentAlign.Horizontal) {
    return css`
      position: absolute;
      top: 0px;
      left: ${params.hasCover ? 100 : 0}vw;
      width: ${`calc(100vw - ${paddingVal(params.padding).side * 2}px)`};
      height: ${`calc(100vh - ${paddingVal(params.padding).top + paddingVal(params.padding).bottom}px)`};
      column-width: 100vw;
      column-count: auto;
      column-fill: auto;
      column-gap: ${paddingVal(params.padding).side * 2}px;
      box-sizing: unset;
    `;
  }
  // DocumentAlign.Vertical
  else {
    return css`
      width: ${`calc(100vw - ${paddingVal(params.padding).side * 2}px)`};
      min-height: 100vh;
      box-sizing: unset;
    `;
  }
};

export const fontFamilyCSS = (fontFamily: FontFamily) => {
  switch (fontFamily) {
    case FontFamily.고딕:
      return css`
        * {
          font-family: 'Nanum Gothic';
        }
      `;
    case FontFamily.바탕:
      return css`
        * {
          font-family: 'Gowun Batang';
        }
      `;
    case FontFamily.명조:
      return css`
        * {
          font-family: 'Nanum Myeongjo';
        }
      `;
    default:
      return css`
        * {
          font-family: 'Nanum Gothic';
        }
      `;
  }
};

export const themeColors: {
  [key in Theme]: {
    bgColor: string;
    fontColor: string;
  };
} = {
  [Theme.Light]: {
    bgColor: '#fdfdfd', // 밝은 흰색
    fontColor: '#2d2d2d', // 어두운 회색
  },
  [Theme.Dark]: {
    bgColor: '#1e1e1e', // 짙은 회색
    fontColor: '#cfcfcf', // 밝은 회색
  },
  [Theme.Sepia]: {
    bgColor: '#f5e4c2', // 옅은 세피아
    fontColor: '#4c3b31', // 어두운 갈색
  },
  [Theme.NightBlue]: {
    bgColor: '#003f63', // 깊은 바다색
    fontColor: '#c1e1ec', // 옅은 푸른색
  },
  [Theme.ForestGreen]: {
    bgColor: '#3b5323', // 이끼색
    fontColor: '#d7dec5', // 연한 녹색
  },
};
