import { observer } from 'mobx-react-lite'
import React from 'react'

import Button from './Button'

const CloseButton = ({ onClick }) => (
  <div
    className='absolute right-0 -top-10 z-20'
    aria-hidden='true'
    onClick={onClick}
  >
    <img
      className='w-8 h-8'
      alt='Close'
      src={'../images/groupPurchase/icon/close.png'}
    />
  </div>
)

const RewardModal = observer(({ close, title, onClickBox }) => {
  return (
    <>
      <div
        className='fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.8)] z-30'
        aria-hidden='true'
        onClick={close}
      >
        <div className='fixed top-1/2 left-1/2 py-6 z-40 bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4 opacity-100 w-[90%]'>
          <CloseButton onClick={close} />
          <div>{title}</div>
          <img />
        </div>
      </div>
    </>
  )
})

export default RewardModal
