import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

const ToonEventAdminScreen = observer(() => {
  const [eventList, setEventList] = useState()

  const fetchData = async () => {
    const result = await altoonCMSBackendApis.loadToonEventPage()
    if (result?.status === 200) {
      setEventList(result?.data)
    }
  }

  useEffect(() => {
    AltoonCMSUserStore.setIsLoading(true)
    fetchData()
    setTimeout(() => {
      AltoonCMSUserStore.setIsLoading(false)
    }, 500)
  }, []) // currentPage 또는 itemsPerPage가 변경될 때마다 요청

  const RegisteredEventList = ({ eventList }) => {
    return (
      <div
        className={`w-full ${
          eventList?.length > 0 ? 'h-[40vh]' : ''
        } overflow-y-scroll bg-white`}
      >
        {eventList?.length > 0 && (
          <table>
            <thead className='whitespace-nowrap text-[1vh] border-b-2'>
              <tr>
                <th>고유아이디</th>
                <th>이벤트명</th>
                <th>설명</th>
                <th>이미지</th>
                <th>작품목록 제목</th>
                <th>작품목록</th>
                <th>배경 컬러코드</th>
                <th>삭제하기</th>
              </tr>
              <tr>
                <th>id</th>
                <th>eventTitle</th>
                <th>description</th>
                <th>imageUri</th>
                <th>toonListTitle</th>
                <th>toonIdList</th>
                <th>backgroundColor</th>
                <th>delete</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 12, textAlign: 'center' }}>
              {eventList?.map((item) => (
                <tr key={item._id} className='border-b'>
                  <td style={{ fontSize: 4, width: '5vw', textWrap: 'nowrap' }}>
                    {item._id}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.eventTitle}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.description}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.eventImageUri}
                    <img
                      src={item.eventImageUri}
                      alt=''
                      style={{ width: '5vw' }}
                    />
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.toonListTitle}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item?.toonIdList && (
                      <td>
                        <table className='border self-center'>
                          <thead>
                            <tr>
                              <th className='whitespace-nowrap text-[0.4vw]'>
                                작품 ID
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className='whitespace-nowrap text-[0.4vw]'>
                                {item?.toonIdList.map((item) => (
                                  <tr key={item}>{item}</tr>
                                ))}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    )}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.backgroundColor}
                  </td>
                  <td>
                    <button
                      type='button'
                      style={{ margin: 4, width: '5vw', textWrap: 'nowrap' }}
                      onClick={async () => {
                        const deleteConfirmation =
                          window.confirm('삭제하시겠습니까?')

                        if (deleteConfirmation) {
                          const result =
                            await altoonCMSBackendApis.deleteToonPromotion({
                              _id: item._id,
                              promotionType: 'eventPage',
                            })
                          if (result?.status === 200) {
                            alert('삭제되었습니다.')
                            fetchData()
                            return
                          }
                          alert(
                            '오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.',
                          )
                        }
                      }}
                    >
                      삭제하기
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  const ToonEventRegisterSection = () => {
    const [description, setDescription] = useState('')
    const [eventTitle, setEventTitle] = useState('')
    const [backgroundColor, setBackgroundColor] = useState('')
    const [imageUri, setImageUri] = useState('')
    const [toonListTitle, setToonListTitle] = useState('')
    const [toonIdList, setToonIdList] = useState('')

    return (
      <>
        <div className='p-4 bg-white rounded-xl'>
          <div className='py-4 font-bold text-[2.6vh]'>이벤트페이지 등록</div>
          <div className='grid-cols-3 grid'>
            <div className='pt-4 flexRow items-center'>
              영문설명
              <input
                className='ml-2'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              이벤트명
              <input
                className='ml-2'
                value={eventTitle}
                onChange={(e) => setEventTitle(e.target.value)}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              배경 컬러코드
              <input
                className='ml-2'
                value={backgroundColor}
                onChange={(e) => setBackgroundColor(e.target.value)}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              작품목록 제목
              <input
                className='ml-2'
                value={toonListTitle}
                onChange={(e) => setToonListTitle(e.target.value)}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              이미지
              <input
                className='ml-2'
                value={imageUri}
                onChange={(e) => setImageUri(e.target.value)}
              />
            </div>
            <div style={{ paddingTop: 20 }}>
              <div>
                작품 아이디 목록(ex: ["id_1", "id_2"])
                <div>
                  <textarea
                    value={toonIdList}
                    onChange={(e) => setToonIdList(e.target.value)}
                    style={{ width: 400, height: 100 }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: 12 }}>
            <button
              type='button'
              className='mt-10 px-4 py-2 bg-gray-500 text-white rounded-xl'
              onClick={async () => {
                if (
                  !description ||
                  !eventTitle ||
                  !backgroundColor ||
                  !toonListTitle ||
                  !imageUri ||
                  !toonIdList?.length
                ) {
                  return
                }

                const result = await altoonCMSBackendApis.registerToonPromotion(
                  {
                    type: 'eventPage',
                    description,
                    eventTitle,
                    backgroundColor,
                    toonListTitle,
                    imageUri,
                    toonIdList,
                  },
                )
                if (result?.status === 200) {
                  alert('등록되었습니다.')
                  fetchData()
                  setDescription('')
                  setEventTitle('')
                  setBackgroundColor('')
                  setToonListTitle('')
                  setImageUri('')
                  setToonIdList('')
                } else {
                  alert(
                    '오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.',
                  )
                }
              }}
            >
              이벤트페이지 등록하기
            </button>
          </div>
        </div>
      </>
    )
  }

  const UpdateEventInfo = () => {
    const [eventId, setEventId] = useState('')
    const [field, setField] = useState('')
    const [value, setValue] = useState('')

    return (
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>
          이벤트페이지 정보 수정
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 12,
          }}
        >
          이벤트페이지 아이디
          <div style={{ marginBottom: 8 }}>
            <input
              type='text'
              value={eventId}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setEventId(e.target.value)
              }}
            />
          </div>
          바꿀 field
          <div style={{ marginBottom: 8 }}>
            <input
              type='text'
              value={field}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setField(e.target.value)
              }}
            />
          </div>
          value
          <div style={{ marginBottom: 4 }}>
            <input
              type='text'
              value={value}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setValue(e.target.value)
              }}
            />
          </div>
        </div>

        <button
          type='button'
          className='mt-10 px-4 py-2 bg-gray-500 text-white rounded-xl'
          onClick={async () => {
            const result = await altoonCMSBackendApis.updatePromotionInfo({
              _id: eventId,
              promotionType: 'eventPage',
              field,
              value,
            })
            if (result?.status === 200) {
              alert('업데이트 완료')
            }
          }}
        >
          이벤트페이지 정보 업데이트
        </button>
      </div>
    )
  }

  return (
    <div className='w-full my-10 min-w-7xl max-w-10xl select-text'>
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>이벤트페이지 관리</div>
        {eventList?.length > 0 && <RegisteredEventList eventList={eventList} />}
      </div>
      <div className='h-4' />
      <UpdateEventInfo />
      <div className='h-4' />
      <ToonEventRegisterSection />
    </div>
  )
})

export default ToonEventAdminScreen
