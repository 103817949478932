import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Oval } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import Button from '../components/groupPurchase/Button'
import Header from '../components/groupPurchase/Header'
import Modal from '../components/groupPurchase/Modal'
import AuthStore from '../stores/AuthStore'
import GroupPurchaseStore from '../stores/GroupPurchaseStore'
import ModalStore from '../stores/ModalStore'
import backendApis from '../utils/backendApis'
// import useThrottle from '../hooks/useThrottle'
import groupPurchaseWithdrawUtil from '../utils/groupPurchaseWithdrawUtil'

const Banks = ({ bankList, accountInfo, setAccountInfo }) => (
  <div className='mx-6'>
    <div className='mx-1'>은행을 선택해 주세요.</div>
    <select
      className='w-full h-12 border-2 border-[#F7F7F7] rounded-md text-md my-3'
      placeholder='  기타 은행'
      value={accountInfo?.bankCode || ''}
      onChange={(e) => {
        const correspondingBankInfo = bankList.find(
          (bankInfo) => bankInfo.bankCode === e.target.value,
        )
        if (correspondingBankInfo) {
          setAccountInfo({
            bankCode: correspondingBankInfo.bankCode,
            bankName: correspondingBankInfo.bankName,
          })
        } else {
          setAccountInfo({ ...accountInfo, bankCode: null, bankName: null })
        }
      }}
    >
      <option value=''>입금 은행</option>
      {bankList.map((bankInfo) => (
        <option key={bankInfo?.bankCode} value={bankInfo?.bankCode}>
          {bankInfo?.bankName}
        </option>
      ))}
    </select>
  </div>
)

const Account = ({ accountInfo, setAccountInfo }) => (
  <div className='mx-6'>
    <div className='mx-1 mb-2'>계좌번호를 입력해 주세요. (- 제외)</div>
    <input
      type='number'
      pattern='[0-9]*'
      id='account_number'
      className='bg-[#F7F7F7] border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5 focus:ring-blue-500 focus:border-blue-500'
      placeholder='00000000'
      required
      value={accountInfo?.account || ''}
      onInput={(e) =>
        setAccountInfo((prev) => ({ ...prev, account: e.target.value }))
      }
    />
  </div>
)

const Name = ({ accountInfo, setAccountInfo }) => (
  <div className='mx-6'>
    <div className='mx-1 mb-2'>예금주명을 입력해 주세요.</div>
    <input
      type='text'
      id='account_name'
      className='bg-[#F7F7F7] border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5 focus:ring-blue-500 focus:border-blue-500'
      placeholder='홍길동'
      required
      value={accountInfo?.receiver || ''}
      onInput={(e) =>
        setAccountInfo((prev) => ({ ...prev, receiver: e.target.value }))
      }
    />
  </div>
)

const onClickButton = async (
  token,
  accountInfo,
  setAccountInfo,
  setIsLoading,
  navigate,
) => {
  const { bankCode, account, receiver } = accountInfo

  if (!bankCode) {
    setIsLoading(false)
    GroupPurchaseStore.openOneButtonModal({
      title: '안내',
      firstLineText: '은행을 선택해주세요',
    })
    return
  }
  if (!account) {
    setIsLoading(false)
    GroupPurchaseStore.openOneButtonModal({
      title: '안내',
      firstLineText: '계좌번호를 입력해주세요',
    })
    return
  }
  if (!receiver) {
    setIsLoading(false)
    GroupPurchaseStore.openOneButtonModal({
      title: '안내',
      firstLineText: '예금주명을 입력해주세요',
    })
    return
  }

  const result = await groupPurchaseWithdrawUtil(
    token,
    accountInfo,
    setAccountInfo,
    GroupPurchaseStore.userInfo,
  )
  if (result === 2000) {
    navigate(-1)
  }
  setIsLoading(false)
}

const GroupPurchaseWithdraw = observer(() => {
  const navigate = useNavigate()
  // const throttle = useThrottle()
  const token = useSearchParam('token')
  AuthStore.setToken(token)

  const [isLoading, setIsLoading] = useState(true)
  const [bankList, setBankList] = useState([])
  const [accountInfo, setAccountInfo] = useState({
    bankCode: null,
    bankName: null,
    account: null,
    receiver: null,
  })

  useEffect(() => {
    const initializeBankList = async () => {
      const result = await backendApis.groupPurchaseBankList(token, 'GET')
      if (result?.status === 200) {
        setBankList(result?.data)
        setIsLoading(false)
      }
    }
    initializeBankList()
  }, [])

  return (
    <div>
      {!isLoading ? (
        <>
          <Header
            title='출금하기'
            infoButton
            infoNavigate={() => {
              GroupPurchaseStore?.openWithdrawInfoModal()
            }}
            navigateBack={() => {
              navigate(-1)
            }}
          />
          <div className='h-24' />
          <div className='w-full text-center text-2xl text-[#A45B54] font-bold'>
            <span className='text-lg'>출금 금액: </span>5,000원
          </div>
          <div className='h-12' />
          <Banks
            bankList={bankList}
            accountInfo={accountInfo}
            setAccountInfo={setAccountInfo}
          />
          <div className='h-4' />
          <Account accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
          <div className='h-8' />
          <Name accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
          <div className='flex flex-col items-center justify-center mt-6'>
            <Button.Normal
              text='출금하기'
              className='flex-1 ml-2 pt-[2vw] pb-[2vw] '
              onClick={() => {
                onClickButton(
                  token,
                  accountInfo,
                  setAccountInfo,
                  setIsLoading,
                  navigate,
                )
              }}
            />
          </div>
        </>
      ) : (
        <div className='w-screen h-screen fixed flex items-center justify-center top-0 bottom-0 bg-[#EEEEEE]'>
          <Oval
            color='#EA3F49'
            secondaryColor='#212121'
            height='40'
            width='40'
          />
        </div>
      )}

      {GroupPurchaseStore?.modalType === 'oneButton' && (
        <Modal.OneButton props={GroupPurchaseStore?.modalConfig} />
      )}
      {GroupPurchaseStore?.modalType === 'twoButtons' && (
        <Modal.TwoButtons props={GroupPurchaseStore?.modalConfig} />
      )}
      {GroupPurchaseStore?.modalType === 'carousel' && (
        <Modal.Carousel props={GroupPurchaseStore?.modalConfig} />
      )}
      {GroupPurchaseStore?.modalType === 'infoList' && (
        <Modal.InfoList props={GroupPurchaseStore?.modalConfig} />
      )}
    </div>
  )
})

export default GroupPurchaseWithdraw
