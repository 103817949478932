import ItemImpressionStore from '../../../stores/ItemImpressionStore'

function onVisibleRangeChangeFactory(
  isFrom,
  randomId,
  itemData,
  setCurrentRange,
) {
  const onVisibleRangeChange = ({ startIndex, endIndex }) => {
    if (!itemData || !isFrom) return
    setCurrentRange({ startIndex, endIndex })

    if (itemData.length === 0) {
      // will run again after itemData is loaded
      return
    }

    // push all visible items
    const visibleItemData = []
    for (let i = startIndex; i <= endIndex; i += 1) {
      const itemId = itemData[i]?.itemId || itemData[i]?._id
      const itemTitle = itemData[i]?.itemTitle

      if (itemId && itemTitle) {
        const currentItemData = {
          index: i,
          itemId,
          itemTitle,
        }
        visibleItemData.push(currentItemData)
      }
    }

    ItemImpressionStore.onVisibleItemsChange(isFrom, randomId, visibleItemData)
  }

  return onVisibleRangeChange
}

function getItemArrayHash(itemData) {
  return itemData?.slice(0, 4).reduce((acc, cur) => {
    return `${acc}-${cur.itemId}`
  }, '')
}

export { onVisibleRangeChangeFactory, getItemArrayHash }
