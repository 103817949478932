/* eslint-disable no-alert */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/atoms/button'
import backendApis from '../../utils/backendApis'

const FindPasswordScreen = () => {
  const navigate = useNavigate()
  const [providerName, setSellerName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [sentVerificationNumber, setSentVerificationNumber] = useState(false)
  const [verificationNumber, setVerificationNumber] = useState('')
  const [actualVerificationNumber, setActualVerificationNumber] = useState('')

  return (
    <>
      <div className='flex flex-col items-center justify-center flex-1 w-full px-10 py-10 mt-10 w-min-7xl'>
        <div className='flex flex-row items-center flex-1 mb-5'>
          <div className='text-2xl font-bold select-none'>비밀번호 찾기</div>
        </div>
        <div className='overflow-hidden shadow sm:rounded-md my-6 w-96'>
          {!sentVerificationNumber ? (
            <>
              <div className='bg-white px-4 py-5 sm:p-6'>
                <div className='grid grid-cols-6 gap-6'>
                  <div className='col-span-6'>
                    <span className='block text-sm font-medium text-gray-700'>
                      아이디
                    </span>
                    <input
                      type='text'
                      value={providerName}
                      placeholder='아이디'
                      onChange={(e) => {
                        setSellerName(e.target.value)
                      }}
                      className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                    />
                  </div>

                  <div className='col-span-6'>
                    <span className='block text-sm font-medium text-gray-700'>
                      전화번호
                    </span>
                    <input
                      type='text'
                      value={phoneNumber}
                      placeholder='전화번호(- 없이 입력해주세요)'
                      onChange={(e) => {
                        setPhoneNumber(e.target.value)
                      }}
                      className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                    />
                  </div>
                </div>
              </div>
              <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
                <Button
                  type='submit'
                  size='lg'
                  onClick={async (e) => {
                    e.preventDefault()
                    if (!providerName) {
                      alert('올툰 콘텐츠 제공자 아이디를 입력해주세요.')
                      return
                    }
                    if (!phoneNumber) {
                      alert('가입 시 등록하신 휴대폰 번호를 입력해주세요.')
                      return
                    }
                    const result = await backendApis.requestVerificationNumber(
                      phoneNumber,
                      providerName,
                    )
                    if (result.status === 200) {
                      setActualVerificationNumber(result.data)
                      setSentVerificationNumber(true)
                    } else {
                      alert(
                        '올툰 콘텐츠 제공자 아이디가 정확하지 않거나, 해당 계정으로 등록된 연락처 번호가 아닙니다. 자세한 문의는 올툰 지원센터로 연락 부탁드립니다.',
                      )
                    }
                  }}
                >
                  인증번호 받기
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className='bg-white px-4 py-5 sm:p-6'>
                <div className='grid grid-cols-6 gap-6'>
                  <div className='col-span-6'>
                    <span className='block text-sm font-medium text-gray-700'>
                      인증번호
                    </span>
                    <input
                      type='text'
                      placeholder='문자로 전송된 인증번호를 입력해주세요.'
                      value={verificationNumber}
                      onChange={(e) => {
                        setVerificationNumber(e.target.value)
                      }}
                      className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                    />
                  </div>
                </div>
              </div>
              <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
                <Button
                  type='submit'
                  size='lg'
                  onClick={async (e) => {
                    e.preventDefault()
                    if (
                      verificationNumber === actualVerificationNumber &&
                      actualVerificationNumber
                    ) {
                      await backendApis.reportPasswordMissing(
                        providerName,
                        phoneNumber,
                      )
                    }
                    navigate('/login', { sellerName: providerName })
                  }}
                >
                  임시 비밀번호 발급(문자 발송)
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default FindPasswordScreen
