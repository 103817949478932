import { autorun, makeAutoObservable } from 'mobx'
import store from 'store2'

import {
  DocumentAlign,
  FontFamily,
  FontSize,
  LineHeight,
  Padding,
  Theme,
  TransitionEffect,
} from './types/viewerOptions'

const VIEWER_OPTION_KEY = 'viewer-novel-options'

const defaultOptions = {
  theme: Theme.Light,
  fontFamily: FontFamily.고딕,
  fontSize: FontSize.Medium,
  lineHeight: LineHeight.Medium,
  padding: Padding.Medium,
  documentAlign: DocumentAlign.Horizontal,
  transitionEffect: TransitionEffect.None,
  brightness: 100,
}

class ViewerStore {
  isLoaded = false
  private isControllerFocused: boolean = false

  maxPage: number = 1
  viewport: HTMLDivElement | null = null

  options: {
    theme: Theme
    fontFamily: FontFamily
    fontSize: FontSize
    lineHeight: LineHeight
    padding: Padding
    documentAlign: DocumentAlign
    transitionEffect: TransitionEffect
    brightness: number
  }

  constructor() {
    this.options = store.get(VIEWER_OPTION_KEY) ?? defaultOptions
    makeAutoObservable(this)
  }

  setViewport(value: HTMLDivElement) {
    this.viewport = value
  }

  setMaxPage(value: number) {
    this.maxPage = value
  }

  setOption<K extends keyof ViewerStore['options']>(
    key: K,
    value: ViewerStore['options'][K],
  ) {
    this.options[key] = value
  }

  toggleIsControllerFocused(value?: boolean) {
    if (value !== undefined) {
      this.isControllerFocused = value
    } else {
      this.isControllerFocused = !this.isControllerVisible
    }
  }

  resetViewerOptions() {
    this.options = { ...defaultOptions }
  }

  get isControllerVisible() {
    return this.isControllerFocused
  }

  get currentPage() {
    if (this.viewport === null) return 0
    if (this.options.documentAlign === DocumentAlign.Horizontal) {
      return Math.floor(this.viewport.scrollLeft / window.innerWidth)
    } else {
      return Math.floor(this.viewport.scrollTop / window.innerHeight)
    }
  }
}

export const viewerStore = new ViewerStore()
export type IViewerStore = typeof viewerStore

autorun(() => {
  return store.set(VIEWER_OPTION_KEY, viewerStore.options)
})
