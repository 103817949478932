import { useQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { CommentSortType } from '../../../../../utils/interface'
import { novelChapterCommentListQuery } from '../../../remotes/comment'
import { BottomSheet } from '../../Bottomsheet'
import { Divider } from '../../Divider'
import { CommentList } from '../CommentList'
import { CommentInput } from './CommentInput'

interface CommentBottomsheetProps {
  isOpen: boolean
  onClose: () => void
}

export const CommentBottomsheet = observer((props: CommentBottomsheetProps) => {
  const location = useLocation()
  const { chapterId, novelId } = location.state || {}
  const scrollRef = useRef<HTMLDivElement>(null)
  const { data: bestCommentList } = useQuery(
    novelChapterCommentListQuery({
      chapterId,
      novelId,
      sortType: CommentSortType.my,
      count: 3,
      skip: 0,
    }),
  )

  return (
    <BottomSheet isOpen={props.isOpen} onClose={props.onClose}>
      <Wrapper>
        {/* TODO: 댓글 없는 경우 skeleton */}
        <div css={textCountCSS}>댓글 {bestCommentList?.totalLength ?? 0}</div>
        <Divider />

        <ScrollView ref={scrollRef}>
          <CommentList chapterId={chapterId} novelId={novelId} />
        </ScrollView>

        <CommentInput
          onSubmitFinish={() =>
            scrollRef.current?.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }
        />
      </Wrapper>
    </BottomSheet>
  )
})

const Wrapper = styled.div`
  padding: 16px 16px 12px;
  position: relative;
  height: calc(100% - 28px);
  display: flex;
  flex-direction: column;
`

const ScrollView = styled.div`
  flex: 1;
  height: max-content;
  max-height: 50vh;
  height: auto;
  overflow-y: auto;
`

const textCountCSS = css`
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;

  padding: 10px 0px 12px;
`
