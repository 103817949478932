const URLList = {
  meta: 'https://alwayzshop.page.link/SWgdVSMc3VjY3CZv9',
  metaDirectTrial: 'https://alwayzshop.page.link/SWgdVSMc3VjY3CZv9',
  everytime: 'https://alwayzshop.page.link/rhUK6qMDv5Cs7tWj8',
  kakao: 'https://alwayzshop.page.link/7HR7CbFpwhJeR1fm7',
  google: 'https://alwayzshop.page.link/CQietWajMxjMKfAH9',
  toss: 'https://alwayzshop.page.link/r8MjycLYMXeg9aRa6',
  carrot: 'https://alwayzshop.page.link/VrtbBHK9mrJY8JMB9',
  basic: 'https://alwayzshop.page.link/VxLtXiD8ZGRzu69e8', // general link
  test: 'https://alwayzshop.page.link/VxLtXiD8ZGRzu69e8',
}

export { URLList }
