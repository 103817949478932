import { useEffect, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'

import ItemImpressionStore from '../../../stores/ItemImpressionStore'
import {
  getItemArrayHash,
  onVisibleRangeChangeFactory,
} from './itemImpressionHandler'

const groupArray = (array, columnCount) => {
  const groupedArray = []
  for (let i = 0; i < array?.length; i += columnCount) {
    const group = array
      .slice(i, i + columnCount)
      .map((item, j) => ({ index: i + j, data: item }))

    if (group.length < columnCount) {
      for (let j = group.length; j < columnCount; j += 1) {
        group.push({ index: i + j, data: null })
      }
    }

    groupedArray.push(group)
  }
  return groupedArray
}

export default function VerticalList({
  itemData,
  height = '100%',
  renderItem = () => {},
  loadMore = () => {},
  columnCount = 1,
  overscan = 5,
  isFrom = null,
  isItemList = true,
}) {
  const [isScrolling, setIsScrolling] = useState(false)
  const [currentTouchCount, setCurrentTouchCount] = useState(0)
  const [isScrollTouchActive, setIsScrollTouchActive] = useState(false)
  const [isScrollDelay, setIsScrollDelay] = useState(false)
  const [randomId, setRandomId] = useState(
    Math.random().toString(36).slice(2, 10),
  )
  const [itemDataHash, setItemDataHash] = useState('')
  const [currentRange, setCurrentRange] = useState({
    startIndex: 0,
    endIndex: -1,
  })
  const [groupedItemData, setGroupedItemData] = useState([])
  const columnWidth = `${Math.floor(100 / columnCount)}%`

  useEffect(() => {
    if (!isItemList) return

    const onTouchChange = (e) => {
      setCurrentTouchCount(e.touches.length)
    }
    window.addEventListener('touchstart', onTouchChange)
    window.addEventListener('touchend', onTouchChange)

    return () => {
      window.removeEventListener('touchstart', onTouchChange)
      window.removeEventListener('touchend', onTouchChange)
      ItemImpressionStore.clearVisibleImpressions(isFrom, randomId)
    }
  }, [])

  useEffect(() => {
    if (isScrolling) {
      setIsScrollDelay(true)
    } else {
      setTimeout(() => {
        setIsScrollDelay(false)
      }, 150)
    }
  }, [isScrolling])
  useEffect(() => {
    if (isScrolling) {
      setIsScrollTouchActive(true)
    } else {
      setIsScrollTouchActive(currentTouchCount > 0)
    }
  }, [isScrolling, currentTouchCount])

  const onChange = onVisibleRangeChangeFactory(
    isFrom,
    randomId,
    itemData,
    setCurrentRange,
  )
  const onVisibleRangeChange = ({
    startIndex: groupStartIndex,
    endIndex: groupEndIndex,
  }) => {
    if (!isItemList) return
    const startIndex = groupStartIndex * columnCount
    const endIndex = groupEndIndex * columnCount + columnCount - 1
    return onChange({ startIndex, endIndex })
  }

  // 1) to handle items rendered before the data was loaded
  // 2) to handle items after item data is altered
  useEffect(() => {
    if (!isItemList || itemData?.length === 0) return
    setGroupedItemData(groupArray(itemData, columnCount))
    const newHash = getItemArrayHash(itemData)
    if (itemDataHash !== newHash) {
      setItemDataHash(newHash)
      if (currentRange.endIndex !== -1) {
        onChange(currentRange)
      }
    }
  }, [itemData, columnCount])

  const RowWrapper = (index, data, isScrolling) => {
    const emptyGroup = Array.from({ length: columnCount }).map((_, i) => ({
      index: index + i,
      data: null,
    }))
    const itemGroup = data || emptyGroup
    const renderResults = itemGroup.map(({ index, data }) => {
      return (
        <div key={index} style={{ width: columnWidth }}>
          {renderItem(
            index,
            data,
            isScrolling || isScrollDelay || isScrollTouchActive,
          )}
        </div>
      )
    })
    return <div className='flex flex-row'>{renderResults}</div>
  }

  return (
    <Virtuoso
      useWindowScroll
      style={{ height }}
      totalCount={groupedItemData.length}
      data={groupedItemData}
      endReached={loadMore}
      overscan={overscan}
      isScrolling={setIsScrolling}
      itemContent={(index, data, context) =>
        RowWrapper(index, data, isScrolling)
      }
      rangeChanged={onVisibleRangeChange}
    />
  )
}
