const matches = [
  {
    bucket: 'always-bucket-dev',
    match: 'https://always-bucket-dev.s3.us-west-1.amazonaws.com/',
    endpoint: 'https://d15y6p73qu79m1.cloudfront.net/',
  },
  {
    bucket: 'alwayz-assets',
    match: 'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/',
    endpoint: 'https://resize.ilevit.com/',
  },
  {
    bucket: 'alwayz-assets',
    match: 'https://assets.ilevit.com/',
    endpoint: 'https://resize.ilevit.com/',
  },
]

function getMetadataHash(bucketName, key, w, h, fit) {
  let metadata = {
    bucketName,
    key,
  }
  if (w > 0) {
    metadata = {
      bucketName,
      key,
      edits: {
        resize: {
          width: w,
          // height: h || w,
          fit,
        },
        toFormat: 'webp',
      },
    }
  }

  return btoa(JSON.stringify(metadata))
}

const oldMatchUri = 'https://always-bucket-dev.s3.us-west-1.amazonaws.com/'
const oldEndpointUri = 'https://d22yqrpf3lokox.cloudfront.net/'

const imageUriWrapper = (inputUri, w = 0, h = 0, fit = 'cover') => {
  const url = decodeURIComponent(inputUri)

  if (!url) {
    // Assets Cloudfront is already cached
    return inputUri
  }

  if (url.startsWith(oldMatchUri)) {
    const cfEndpointUri = url.replace(oldMatchUri, oldEndpointUri)
    return cfEndpointUri
  }

  let bucket
  let endpoint
  let parsed

  matches.forEach((match) => {
    const {
      bucket: matchBucket,
      match: matchUrl,
      endpoint: matchEndpoint,
    } = match
    if (url.includes(matchUrl)) {
      parsed = url.split(matchUrl)
      bucket = matchBucket
      endpoint = matchEndpoint
    }
  })

  if (!parsed) return inputUri

  const bucketKey = parsed[1].split('+').join(' ')

  if (
    !bucketKey ||
    bucketKey.toUpperCase().includes('.GIF') ||
    bucketKey.toUpperCase().includes('.HEIC')
  )
    return inputUri

  const key = encodeURIComponent(bucketKey).replace(
    /%([0-9A-F]{2})/g,
    (match, p1) => String.fromCharCode(`0x${p1}`),
  )

  const hash = getMetadataHash(bucket, key, w, h, fit)
  const resizeUri = endpoint + hash
  return resizeUri
}

export default imageUriWrapper
