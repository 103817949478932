import React, { useEffect, useState } from 'react'

import backendApis from '../../../utils/backendApis'

const Banner = ({ type, version, onClick = () => {}, token }) => {
  const [bannerData, setBannerData] = useState([])
  const fetchBanner = async (type, version) => {
    // TODO: 나중에 api 하나 새로 파기. 바빠서 일단 이렇게 함.
    const result = await backendApis.getBrandDealBanners(version)
    if (result?.status === 200 && result?.data?.length > 0) {
      setBannerData(result?.data)
    }
  }

  useEffect(() => {
    if (type === 'brandDeal') {
      fetchBanner(type, version)
    }
  }, [token])

  return (
    <>
      {bannerData && bannerData.length > 0 && (
        <div aria-hidden onClick={onClick}>
          <img src={bannerData[0]?.banner} alt='banner' />
        </div>
      )}
    </>
  )
}
export default Banner
