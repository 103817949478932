import { observer } from 'mobx-react-lite'
import React from 'react'
import { FcAbout } from 'react-icons/fc'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

const EndPage = observer(() => {
  const navigate = useNavigate()

  return (
    <>
      <section className='flex flex-row overflow-hidden h-12 w-screen z-10 relative items-center'>
        <button
          className='flex flex-1 py-2 px-3 whitespace-nowrap'
          onClick={() => navigate('/treasurebox')}
        >
          <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
        </button>
        <div className='p-3 w-full text-center text-gray-800 font-medium'>
          {` `}
        </div>
        <div className='flex w-[60px]'>{` `}</div>
      </section>
      <div className='flex flex-col items-center justify-between flex-1 m-12'>
        <FcAbout className='h-20 w-20' />
      </div>
      <h2 className='p-6 text-2xl text-center font-bold'>
        이벤트가 종료되었어요 <br />
        다음 이벤트에서 만나요 👋
      </h2>
    </>
  )
})

export default EndPage
