import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import ToastStore from '../../stores/ToastStore'
import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import THUserStore from '../../stores/treasureHunt/THUserStore'
import backendApis from '../../utils/backendApis'
import TreasureHuntButton from './TreasureHuntButton'
import TreasureHuntTimer from './TreasureHuntTimer'

const communities = [
  {
    type: 'everytime',
    text: '자유게시판',
    name: '에브리타임',
  },
  {
    type: 'carrot',
    text: '동네생활',
    name: '당근',
  },
  {
    type: 'band',
    text: '자유게시판',
    name: '밴드',
  },
]

const THUploadChallenge = observer(() => {
  const [CTAText, setCTAText] = useState('링크 복사하기')

  useEffect(() => {
    if (THUserStore?.selectedImage) {
      setCTAText('챌린지 완료하기')
    } else {
      setCTAText('링크 복사하기')
    }
  }, [THUserStore?.selectedImage])

  const CommunityIcon = ({ community }) => (
    <div
      className='flex flex-row w-full justify-center items-center'
      onClick={() => {
        TreasureHuntAction.postMessage({
          type: 'sendToOtherApp',
          data: community,
        })
      }}
    >
      <img
        className='rounded-full w-[10vw] h-[10vw] mr-2'
        src={`../images/treasureHunt/uploadChallenge/communities/${community?.type}.png`}
        alt='log'
      />
      <div className='h-[10vw]'>
        <div className='text-[3vw] text-[#313138] font-bold'>
          {community?.name}
        </div>
        <div className='text-[3vw] text-[#313138] font-normal'>
          {community?.text}
        </div>
      </div>
    </div>
  )

  return (
    <div className='default relative justify-between items-center w-full pb-4 text-[#313138] p-4 '>
      <div className='w-full max-h-[60vh] overflow-y-scroll'>
        <div className='flex flex-col flex-1 overflow-y-scroll w-full text-[#313138] '>
          <div className='font-bold text-[5vw]'>
            1시간안에 커뮤니티에 글을 올리면
          </div>
          <div className='font-bold text-[5vw] mb-4 flex flex-row justify-start items-center'>
            최대{' '}
            <span className='text-[#ff5951] text-[6vw] flex flex-row justify-center items-center'>
              <img
                className='w-6 h-6 ml-2 mr-1'
                src='../images/treasureHunt/point.png'
                alt='point'
              />
              600원
            </span>
            을 받아요!
          </div>

          <div className='flex flex-col bg-[#ffecec] border-[#ff5951] justify-start items-center p-4 rounded-xl mb-4'>
            <div className='text-[4vw] font-bold mb-2'>참여방법</div>
            <div className='flex flex-row justify-start items-center font-semibold w-full my-1'>
              <img
                className='w-[6vw] h-[6vw] mr-3 '
                src='../images/treasureHunt/uploadChallenge/1.png'
                alt='letter'
              />{' '}
              링크 복사하기 버튼 클릭하기
            </div>
            <div className='flex flex-row justify-start items-center font-semibold w-full my-1'>
              <img
                className='w-[6vw] h-[6vw] mr-3'
                src='../images/treasureHunt/uploadChallenge/2.png'
                alt='hand'
              />{' '}
              링크를 붙여넣은 게시글 작성하기
            </div>
            <div className='flex flex-row justify-start items-center font-semibold w-full my-1'>
              <img
                className='w-[6vw] h-[6vw] mr-3'
                src='../images/treasureHunt/uploadChallenge/3.png'
                alt='hand'
              />{' '}
              인증 스크린샷 올리고 최대 600원 받기!
            </div>
            <div className='mt-1 text-[3vw] font-semibold text-[#595864]'>
              *부정행위를 하면 포인트가 회수될 수 있어요. 😕
            </div>
          </div>
        </div>
        <div
          className='flex flex-col justify-center items-center mb-4'
          onClick={() => {
            TreasureHuntAction.postMessage({ type: 'uploadImage' })
          }}
        >
          {THUserStore?.isUploadingImage && (
            <div className='font-bold text-[#313138]'>
              이미지를 업로드 중이에요...
            </div>
          )}
          {!THUserStore?.isUploadingImage && THUserStore?.selectedImage && (
            <>
              <img
                className='w-[20vw] h-[20vw] mb-1'
                src={`${THUserStore?.selectedImage}`}
                alt='selectedImage'
              />
              <div className='text-[#313138] font-semibold'>업로드 완료</div>
            </>
          )}
          {!THUserStore?.isUploadingImage && !THUserStore?.selectedImage && (
            <>
              <img
                className='w-[20vw] h-[20vw] mb-1'
                src='../images/treasureHunt/uploadChallenge/plus.png'
                alt='plus'
              />
              <div className='text-[#313138] font-semibold'>
                인증 스크린샷 업로드
              </div>
            </>
          )}
        </div>
        <div className='w-full flex flex-col rounded-xl bg-[#F7F7F7] justify-center items-center py-4 px-0 mb-4'>
          <div className='mb-4 font-bold text-[#313138]'>
            아래 커뮤니티의 게시판만 인정이 돼요!
          </div>
          <div className='w-full flex flex-row  justify-around items-center'>
            {communities?.map((item) => (
              <CommunityIcon key={Math.random()} community={item} />
            ))}
          </div>
        </div>
      </div>

      <div className='flex flex-row justify-center items-center font-bold text-[#ff5951]'>
        <TreasureHuntTimer
          style={{
            color: '#ff5951',
            marginRight: 4,
          }}
          format='number'
          expiryTimestamp={moment(THUserStore?.uploadChallenge?.endAt).toDate()}
          onExpire={() => {
            THUserStore.set('uploadChallenge', {})
            THBottomSheetStore.close()
          }}
        />
        후에 종료
      </div>
      <TreasureHuntButton
        clickable
        text={CTAText}
        className='w-full py-3 text-[5vw]'
        onClick={() => {
          if (THUserStore?.selectedImage) {
            TreasureHuntAction.giveUploadChallengeReward()
            backendApis.THInsert({
              data: {
                type: 'clickUploadChallengeReceiveRewardButton',
              },
              collection: 'clickLog',
            })
          } else {
            backendApis.THInsert({
              data: {
                type: 'clickCopyAndGenerateLinkButton',
              },
              collection: 'clickLog',
            })
            TreasureHuntAction.postMessage({
              type: 'copyAndGenerateLink',
              data: {
                shareType: 'TreasureHunt_UploadChallenge2',
                title: '✨ 반짝지원금 3,000원이 뿌려요!! ✨',
                description: `
[올웨이즈 보물찾기]에서 매달 공짜 귀걸이 놓치지말고 받으세요~! 🎁
매달 공짜 귀걸이까지 주는 혜자앱 올웨이즈에서 
새로 출시한 보물찾기 시작하기! 😘
`,
                imageUrl:
                  'https://assets.ilevit.com/80ec3d27-37c3-456d-a487-ec5650ff3546.png',
                payload: {},
              },
            })
            ToastStore.toastOn({
              type: 'success',
              message: '링크가 복사되었습니다.',
              duration: 2000,
            })
          }
        }}
      />
    </div>
  )
})

export default THUploadChallenge
