const getThisTimeText = (currentHour) => {
  if (currentHour === 22) {
    return '11시에 끝나는 오늘의 마지막 타임특가!'
  }
  if (currentHour < 7 || currentHour >= 23) {
    return '오전 7시에 시작돼요!'
  }
  return `${currentHour < 12 ? '오전' : '오후'} ${currentHour % 12 || 12}시`
}

const getNextTimeText = (laterHour) => {
  if (laterHour >= 7 && laterHour < 8) {
    return '7시에 시작되는 오늘의 첫 타임특가!'
  }
  return `${laterHour < 12 ? '오전' : '오후'} ${laterHour % 12 || 12}시`
}

export { getThisTimeText, getNextTimeText }
