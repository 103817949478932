/* eslint-disable react/button-has-type */

/* eslint-disable no-alert */
// salesInfo.js
import commaNumber from 'comma-number'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { SiGooglesheets } from 'react-icons/si'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import TableData from '../../components/atoms/tableData'
import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import Format from '../../utils/format'

const headers = Format.SettlementExcelHeaders

const SalesInfo = observer(
  ({ selectedProviderId = '', selectedToonId = '' }) => {
    const className = 'border-2 border-transparent'
    const className2 = 'border-2 border-transparent font-bold'

    const [salesData, setSalesData] = useState(null)
    const [totalCoinUsed, setTotalCoinUsed] = useState(0)
    const [totalSales, setTotalSales] = useState(0)
    const [netSales, setNetSales] = useState(0)

    /// 매출 현황 데이터 가져오기
    useEffect(() => {
      const fetchData = async () => {
        const result = await AltoonCMSUserStore.loadDashboardInfo(
          selectedProviderId,
          selectedToonId,
        )
        if (result === true) {
          const tempSalesData = Object.entries(
            AltoonCMSUserStore.dashboardInfoByDate,
          ).map(([date, sales]) => {
            return { date, sales }
          })
          tempSalesData.sort((a, b) => new Date(a.date) - new Date(b.date))
          setSalesData(tempSalesData)

          // 총 매출액 계산
          const totalSales = tempSalesData.reduce(
            (sum, item) => sum + item.sales,
            0,
          )
          setTotalSales(totalSales)

          // 순 매출액 계산
          const netSales = Object.values(
            AltoonCMSUserStore.dashboardInfoByToonId,
          ).reduce(
            (sum, item) =>
              sum +
              (item.settlementConfirmAmount !== '-'
                ? item.settlementConfirmAmount || 0
                : 0),
            0,
          )
          setNetSales(netSales)
        }
      }
      fetchData()
    }, [AltoonCMSUserStore.selectedMonth, selectedProviderId, selectedToonId])

    return (
      <div className='flex flex-col flex-1 h-full select-none'>
        <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md rounded-xl'>
          {AltoonCMSUserStore.loadingDashboardInfo ? (
            <div className='text-lg font-bold'>
              매출 현황을 불러오는 중입니다.
            </div>
          ) : (
            <>
              <div className='flex justify-between items-center mb-4'>
                <h1 className='text-xl font-bold'>매출 현황</h1>
                <button
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex'
                  onClick={async () => {
                    const confirmResult = window.confirm(
                      `정말 ${AltoonCMSUserStore?.selectedMonth.getFullYear()}년 ${
                        AltoonCMSUserStore?.selectedMonth.getMonth() + 1
                      }월 매출 현황을 다운로드 하시겠습니까? 오랜 시간이 걸릴 수 있습니다.`,
                    )
                    if (confirmResult === true) {
                      const loadedOrders =
                        AltoonCMSUserStore?.dashboardInfoByToonId

                      // console.log('loadedOrders: ', loadedOrders)
                      const workbook = new ExcelJS.Workbook()
                      const worksheet = workbook.addWorksheet('매출 내역')
                      const worksheetData = loadedOrders.map((order) => {
                        return Format.formatOrderForSettlementExcel(order)
                      })

                      // console.log('worksheetData: ', worksheetData)

                      worksheet.columns = headers
                      worksheet.addRows(worksheetData)

                      const headerRow = worksheet.getRow(1)
                      headers.forEach((header, index) => {
                        headerRow.getCell(index + 1).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'FFEA3F49' },
                        }
                      })
                      worksheet.getRow(1).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true,
                      }
                      const mimeType = {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      }
                      const buffer = await workbook.xlsx.writeBuffer()
                      const blob = new Blob([buffer], mimeType)
                      saveAs(
                        blob,
                        `${
                          AltoonCMSUserStore?.providerInfo?.providerDisplayName
                        }_정산 예정 매출 내역_${moment().format(
                          'YYYY-MM-DD',
                        )}.xlsx`,
                      )
                    }
                  }}
                >
                  <SiGooglesheets className='mr-2 w-6 h-auto' /> 매출 내역
                  다운로드
                </button>
              </div>
              <table className='border-transparent'>
                <tbody>
                  <tr>
                    <TableData className={className}>총 매출액</TableData>
                    <TableData className={className2}>
                      {commaNumber(totalSales || 0)}원
                    </TableData>
                  </tr>
                  <tr>
                    <TableData className={className}>순 매출액</TableData>
                    <TableData className={className2}>
                      {commaNumber(Math.ceil(netSales) || 0)}원
                    </TableData>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md rounded-xl'>
            <h2 className='text-lg font-bold'>일별 매출 현황</h2>
            <BarChart width={1000} height={300} data={salesData}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='date' />
              <YAxis width={80} />
              <Tooltip formatter={(value) => value.toLocaleString()} />
              <Legend />
              <Bar dataKey='sales' fill='#82ca9d' />
            </BarChart>
          </div>
        </div>
      </div>
    )
  },
)

export default SalesInfo
