import { useEffect, useRef } from 'react'

export default function useThrottle() {
  const timer = useRef(null)
  const block = useRef(false)

  const throttle = (callback, time) => {
    if (block.current) return
    block.current = true
    callback()
    setTimeout(() => {
      block.current = false
    }, time)
  }

  return throttle
}
