import { useMemo, useRef } from 'react'

// type Fn<ARGS extends any[], R> = (...args: ARGS) => R

const useEventCallback = (fn) => {
  const ref = useRef(fn)

  ref.current = fn

  return useMemo(
    () =>
      (...args) => {
        const { current } = ref
        return current(...args)
      },
    [],
  )
}

export default useEventCallback
