import commaNumber from 'comma-number'

import TruncateText from '../../../utils/truncateText'
import ImageAtom from '../../atoms/imageAtom'
import Timer from '../../atoms/timer'
import { handleOnClickFactory } from './itemNavigateHandler'

const LureDealItemComponent = ({
  itemInfo = {},
  style = {},
  version = 'horizontal',
  isFrom = 'LureDealSection',
  empty = false,
  isScrolling = false,
}) => {
  const { width = 0 } = style
  const teamPrice = parseInt(itemInfo?.teamPurchasePrice, 10)
  const dealPrice = parseInt(itemInfo?.minDealTeamPurchasePrice, 10)
  const salesRatio =
    100 - Math.floor((dealPrice / teamPrice) * 100) || undefined
  const type = 'lureDeal'
  const validHandleOnClick = handleOnClickFactory(itemInfo, isFrom, type)
  const handleOnClick = isScrolling ? () => {} : validHandleOnClick

  if (version === 'horizontal') {
    if (empty) {
      return (
        <div
          aria-hidden='true'
          style={{
            ...style,
            flex: 1,
            display: 'flex',
            paddingRight: 12,
          }}
        >
          <div className='flex flex-col flex-1 bg-white rounded-xl'>
            <div>
              <div className='flex flex-row items-center justify-between mx-2 mb-2 font-bold' />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div
        aria-hidden='true'
        style={{ ...style, flex: 1, display: 'flex', paddingRight: 12 }}
        onClick={handleOnClick}
      >
        <div className='bg-white rounded-xl'>
          <ImageAtom
            src={itemInfo?.mainImageUris[0]}
            className='object-cover border-b border-gray-200 rounded-t-xl'
            style={{ width: `${width}px`, height: `${width}px` }}
            width={256}
            height={256}
          />
          <div className='absolute top-2 left-2 '>
            <Timer displayEndDate={itemInfo?.displayEndDate} type='lureDeal' />
          </div>
          <div className='px-2'>
            <div>
              <div className='pt-2 text-sm font-normal text-gray-400'>
                {Math.max(parseInt(itemInfo?.visitedCount, 10) || 0, 31)} 명이
                봤어요
              </div>
              <div className='mt-2'>
                <TruncateText text={itemInfo?.itemTitle} width={width - 24} />
              </div>
              <div className='flex flex-row items-center justify-start mb-3 font-bold'>
                <div className='mr-1 font-bold text-red-500'>
                  {salesRatio && `${salesRatio}%`}
                </div>
                <div className='text-gray-800'>
                  {commaNumber(itemInfo?.minDealTeamPurchasePrice)}원
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (version === 'vertical') {
    if (empty) {
      return (
        <div style={{ ...style, flex: 1, display: 'flex' }}>
          <div className='flex flex-row items-center justify-start flex-1 p-5 border-t border-gray-300'>
            <div className='flex-none w-32 h-32 mr-5 border border-gray-300 rounded-lg' />
            <div className='flex flex-col items-start justify-start flex-1' />
          </div>
        </div>
      )
    }
    return (
      <div
        aria-hidden='true'
        className='flex flex-row items-center justify-center flex-1 w-[90%] h-max mx-5 my-3 p-3 bg-white rounded-xl'
        onClick={handleOnClick}
      >
        <div className='flex flex-col flex-1'>
          <div className='flex flex-row justify-start flex-1 '>
            <div className='flex-none mr-3 place-self-center'>
              <ImageAtom
                src={itemInfo?.mainImageUris[0]}
                className='object-cover border border-gray-300 rounded-lg w-28 h-28'
                width={256}
                height={256}
              />
            </div>
            <div className='flex flex-col items-start justify-between flex-1'>
              <div>
                <TruncateText text={itemInfo?.itemTitle} />
              </div>
              <div className='flex flex-col justify-end font-bold text-red-500'>
                <div className='text-sm'>{salesRatio}% 오늘의 특가</div>
                <div className='flex flex-row items-end flex-1 text-base font-bold text-red-500 '>
                  <div className='mr-1 text-lg text-gray-800'>
                    {commaNumber(dealPrice)}원
                  </div>
                  <div className='mb-1 text-sm font-normal text-gray-400 line-through'>
                    {commaNumber(teamPrice)}원
                  </div>
                </div>
                <div className='text-sm font-normal text-gray-400'>
                  {Math.max(parseInt(itemInfo?.visitedCount, 10) || 0, 31)} 명이
                  봤어요
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default LureDealItemComponent
