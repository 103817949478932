import { actions } from '../stackflow.ts'

function toonBackHandler() {
  const currentStack = actions.getStack()
  const currentActivity = currentStack?.activities[0]

  if (window.location.pathname.includes('/altoon-novel-')) {
    window.history.back()
  } else if (
    currentActivity?.name === 'AltoonMainPage' &&
    currentActivity.isTop
  ) {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'goBack',
      }),
    )
  } else {
    actions.pop()
  }
}

export default toonBackHandler
