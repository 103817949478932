import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useSearchParam } from 'react-use'

import WriteBokMessageModal from '../modals/WriteBokMessageModal'
import ToastStore from '../stores/ToastStore'
import backendApis from '../utils/backendApis'

const DecoBokMessagePage = observer(() => {
  const navigate = useNavigate()

  const token = useSearchParam('token')
  const bokNumber = useSearchParam('bokNumber')
  const isLoggedIn = useSearchParam('isLoggedIn')
  const isTutorial = useSearchParam('isTutorial')

  const [modalMessage, setModalMessage] = useState()
  const [shareLink, setShareLink] = useState('')
  const [showWriteMessageModal, setShowWriteMessageModal] = useState(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [showTutorial, setShowTutorial] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [messageData, setMessageData] = useState()
  const targetDate = moment('2024-02-10')
  const leftDays = moment(targetDate).diff(moment().add(-1, 'days'), 'days')
  const today = moment()
  const [bokData, setBokData] = useState()
  const [openAlwayzInstallModal, setOpenAlwayzInstallModal] = useState(false)
  const [openMessageContentModal, setOpenMessageContentModal] = useState(false)

  const [currentDate, setCurrentDate] = useState()

  const fetchDate = async () => {
    const result = await backendApis.getCurrentDate()
    if (result?.status === 200) {
      setCurrentDate(result?.data)
    } else {
      ToastStore.networkError()
    }
  }

  // useEffect(() => {
  //   const fetchBokData = async () => {
  //     setIsLoading(true)
  //     const userData = await backendApis.bokUser({
  //       phoneNumber: bokNumber,
  //     })
  //     if (userData?.status === 200) {
  //       setBokData(userData?.data)
  //     } else {
  //       ToastStore.networkError()
  //     }

  //     const result = await backendApis.bokMessage({
  //       bokNumber,
  //     })
  //     if (result?.status === 200) {
  //       setMessageData(result?.data)
  //     }

  //     const shareLinkFromStorage = window.localStorage.getItem(
  //       `bok_dynamic_link_${bokNumber}`,
  //     )
  //     if (shareLinkFromStorage) {
  //       setShareLink(shareLinkFromStorage)
  //     } else {
  //       const link = await backendApis.createDynamicLink({
  //         id: bokNumber,
  //         code: 'DecoBok',
  //         type: 'copyLink',
  //       })
  //       if (link?.status === 200) {
  //         setShareLink(link?.data?.data?.shortLink)

  //         window.localStorage.setItem(
  //           `bok_dynamic_link_${bokNumber}`,
  //           link?.data?.data?.shortLink,
  //         )
  //       } else {
  //         ToastStore.networkError()
  //       }
  //     }

  //     setIsLoading(false)
  //   }
  //   window.ReactNativeWebView?.postMessage(
  //     JSON.stringify({
  //       type: 'allowiPhoneGesture',
  //       boolean: false,
  //     }),
  //   )
  //   if (token) {
  //     fetchDate()
  //   }
  //   fetchBokData()
  // }, [])

  const HeaderComponent = () => {
    return (
      <section
        className='flex flex-row overflow-hidden w-screen fixed items-start'
        style={{ zIndex: 1 }}
      >
        <button
          type='button'
          className='p-4'
          onClick={async () => {
            navigate(-1)
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
        </button>
      </section>
    )
  }

  const TextComponent = () => {
    return (
      <div className='w-full absolute top-[10vh] p-4 text-center text-[3vw] text-[#363846]'>
        <div className='pb-1 text-[6vw] text-center whitespace-nowrap oneMobilePop'>
          복주머니 이벤트는 종료되었어요
        </div>
        {/* <div>
          * <span className='font-bold'> {leftDays}일 </span>후에 내용이
          공개돼요
        </div> */}
      </div>
    )
  }

  const MainComponent = () => {
    // 실제 메시지 데이터 또는 더미 데이터를 포함하는 배열 생성
    const filledMessageData = [...messageData]
    const minimumItems = 9

    // 부족한 아이템 수를 계산하고 빈 아이템으로 채움
    const itemsToAdd = minimumItems - filledMessageData?.length
    for (let i = 0; i < itemsToAdd; i++) {
      filledMessageData?.push({ _id: `empty-${i}`, empty: true }) // 빈 아이템 식별을 위한 구조
    }

    const BokMessageComponent = ({ msg }) => {
      const randomNumber = Math.floor(Math.random() * 6) + 1

      if (msg.empty) {
        // 빈 아이템을 위한 렌더링
        return (
          <div className='flexCol items-center py-2'>
            <div className='w-[26vw] h-full flex items-center justify-center'>
              {/* 여기에 빈 동그라미 이미지 또는 아이콘을 넣을 수 있습니다. */}

              <img
                src='/images/viralEvent/bok_empty_hanging.png'
                alt=''
                className='w-[26vw] h-full'
              />
            </div>
          </div>
        )
      }

      return (
        <button
          type='button'
          className='flexCol items-center py-2'
          onClick={() => {
            if (isLoggedIn !== 'true') {
              return
            }
            setModalMessage(msg)
            backendApis.logBokAction({
              bokNumber,
              screen: 'DecoBokMessagePage',
              action: 'clickedMessage',
            })

            if (moment(today).isBefore(targetDate)) {
              setShowTutorial(true)
            } else if (!token && moment(currentDate).isAfter(targetDate)) {
              // 올웨이즈에서만 볼 수 있다는 모달 띄우기
              setOpenAlwayzInstallModal(true)
            }
            if (token && moment(currentDate).isAfter(targetDate)) {
              // 메시지 모달 띄우기
              setOpenMessageContentModal(true)
            }
          }}
        >
          <img
            src={`/images/viralEvent/bok_${
              msg?.bokType ?? randomNumber
            }_hanging.png`}
            alt=''
            className='w-[26vw] h-full'
          />
          <div className='whitespace-nowrap oneMobilePop'>{msg.nickname}</div>
        </button>
      )
    }

    return (
      <div className='relative grid grid-cols-3 gap-2 px-8 pb-20 items-start justify-center w-full h-full overflow-y-scroll'>
        {/* 복주머니 엽서 나열 */}
        {filledMessageData?.map((msg) => (
          <BokMessageComponent key={msg._id} msg={msg} />
        ))}
      </div>
    )
  }

  const MessageModal = () => {
    return (
      <>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div className='fixed z-20 top-[20vw] left-[10vw] w-[80vw] h-[100vw] bg-white flexCol justify-around rounded-lg'>
          <div>
            <div>From: {modalMessage?.nickname}</div>
            <div>{moment(modalMessage?.createdAt).format('M월 D일 작성')}</div>
          </div>
          <div>{modalMessage?.message}</div>
          <button
            type='button'
            className='w-[70vw] py-4 left-[15vw] text-center bg-red-500 text-white font-bold rounded-full shadow-sm'
            onClick={() => {
              setShowMessageModal(false)
            }}
          >
            확인
          </button>
        </div>
      </>
    )
  }

  const NotNowModal = () => {
    const handleCopyClipBoard = async (text) => {
      try {
        await navigator.clipboard.writeText(text)
        alert('링크가 복사되었어요!\n친구, 가족, 친척들에게 공유해보세요')

        // ToastStore.toastOn({
        //   type: 'emoji',
        //   message: `링크가 복사되었어요!\n친구, 가족, 친척들에게 공유해보세요`,
        //   emoji: '😘',
        //   duration: 3000,
        // })
      } catch (e) {
        ToastStore.networkError()
      }
    }

    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        {!isTutorial && (
          <button
            type='button'
            className='fixed z-20 top-[18vw] right-[10vw] w-[10vw] h-[10vw] bg-gray-400 transparent-10 rounded-full flex justify-center items-center'
            onClick={() => setShowTutorial(false)}
          >
            <img src='/images/close.png' alt='' className='w-[6vw] h-[6vw]' />
          </button>
        )}

        <div className='fixed z-20 top-[30vw] left-[10vw] w-[80vw] h-[100vw] bg-[#FFF5E9] flexCol justify-between rounded-t-lg'>
          <div className='flexCol w-full pt-[3vw] pb-[3vw] items-center'>
            <div className='whitespace-pre-wrap py-[4vw] text-[5vw] text-[#424452] oneMobilePop'>
              {`내용은 설 당일에 볼 수 있어용\n\n링크를 공유하고\n복주머니를 가득 채워보세용!`}
            </div>

            <img
              src='/images/viralEvent/bokNotNow.png'
              alt=''
              className='w-[50vw] h-[50vw]'
            />
          </div>
          <div className='w-full'>
            <button
              type='button'
              className='w-full py-4 left-[15vw] text-center bg-[#F74A4A] text-white font-bold rounded-b-lg shadow-sm'
              onClick={async () => {
                handleCopyClipBoard(shareLink)
                backendApis.logBokAction({
                  bokNumber,
                  screen: 'DecoBokMessagePage',
                  action: 'clickedCopyLink',
                })
                navigate(-1)
              }}
            >
              링크 복사하기
            </button>
          </div>
        </div>
      </div>
    )
  }

  const WriteMessageButton = () => {
    return (
      <div className='flexCol'>
        <button
          type='button'
          className='w-[80vw] py-4 absolute bottom-[6vh] left-[10vw] text-[5vw] text-center text-white bg-[#ff5454bf] rounded-full shadow-sm whitespace-nowrap oneMobilePop hover:bg-[#ff5454]'
          onClick={() => {
            setShowWriteMessageModal(true)
            backendApis.logBokAction({
              bokNumber,
              screen: 'DecoBokMessagePage',
              action: 'clickWriteMessageButton',
            })
          }}
        >
          복주머니 선물하기
        </button>
      </div>
    )
  }

  const AlwayzInstallModal = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <button
          type='button'
          className='fixed z-20 top-[36vw] right-[10vw] w-[10vw] h-[10vw] bg-gray-400 transparent-10 rounded-full flex justify-center items-center'
          onClick={() => setOpenAlwayzInstallModal(false)}
        >
          <img src='/images/close.png' alt='' className='w-[6vw] h-[6vw]' />
        </button>

        <div className='fixed z-20 top-[50vw] left-[10vw] w-[80vw] h-[80vw] bg-[#FFF5E9] flexCol justify-between rounded-t-lg'>
          <div className='flexCol w-full pt-[3vw] pb-[3vw] items-center'>
            <div className='whitespace-pre-wrap py-[4vw] text-[5vw] text-[#424452] oneMobilePop'>
              {`덕담은 보안을 위해\n올웨이즈 앱에서만 보실 수 있어요\n`}
            </div>

            <img
              src='/images/viralEvent/bokLocationInfo.png'
              alt=''
              className='w-[70vw] h-full'
            />
          </div>
          <div className='w-full'>
            <button
              type='button'
              className='w-full py-4 left-[15vw] text-center bg-[#F74A4A] text-white font-bold rounded-b-lg shadow-sm'
              onClick={async () => {
                window.location.href = 'https://alwayzshop.page.link/kWfa'
              }}
            >
              내게 온 덕담 보러가기
            </button>
          </div>
        </div>
      </div>
    )
  }

  const MessageContentModal = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <button
          type='button'
          className='fixed z-20 top-[36vw] right-[8vw] w-[10vw] h-[10vw] bg-gray-400 transparent-10 rounded-full flex justify-center items-center'
          onClick={() => setOpenMessageContentModal(false)}
        >
          <img src='/images/close.png' alt='' className='w-[6vw] h-[6vw]' />
        </button>
        <img
          src='/images/viralEvent/bokMessageTemplate.png'
          alt=''
          className='fixed z-20 top-[40vw] left-[5vw] w-[90vw] h-[90vw]'
        />

        <div className='fixed z-20 top-[50vw] left-[18vw] w-[64vw] h-[80vw] flexCol justify-between rounded-t-lg'>
          <div className='flexCol w-full pt-[3vw] pb-[3vw] items-start'>
            <div className='whitespace-pre-wrap w-full text-start py-[4vw] text-[4vw] text-[#424452] oneMobilePop'>
              {`From: ${modalMessage?.nickname}`}
              <div className='whitespace-pre-wrap pt-[1vw] text-end text-[3vw] text-[#D4C5A8] oneMobilePop'>
                {`${modalMessage?.createdDate} 작성`}
              </div>
            </div>
            <div className='whitespace-pre-wrap h-[40vw] py-[1vw] text-center text-[4vw] text-[#424452] oneMobilePop overflow-y-scroll'>
              {`${modalMessage?.message}`}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }
  return (
    <div className='w-full h-full fixed'>
      <img
        src='/images/viralEvent/bokBackground.png'
        className='absolute z-0 -top-[22vw] w-full h-full object-fit'
        alt='bg'
      />
      <img
        src='/images/viralEvent/bokMessageBoard.png'
        className='absolute z-1 bottom-0 w-full h-[80vh] object-fit'
        alt='board'
      />
      <HeaderComponent />
      <TextComponent />
      {/* {messageData && (
        <div className='absolute top-[34vh] w-full h-[66vh]'>
          <MainComponent />
        </div>
      )}
      {showMessageModal && <MessageModal />}
      {(isTutorial || showTutorial) && <NotNowModal />}
      {isLoggedIn !== 'true' && isLoggedIn !== true && <WriteMessageButton />}

      {isLoading && <LoadingIndicator />}
      {openAlwayzInstallModal && <AlwayzInstallModal />}
      {openMessageContentModal && <MessageContentModal />}
      {showWriteMessageModal && (
        <WriteBokMessageModal
          setShowWriteMessageModal={setShowWriteMessageModal}
          bokData={bokData}
        />
      )} */}
    </div>
  )
})

export default DecoBokMessagePage
