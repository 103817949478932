import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import AuthStore from '../../stores/AuthStore'
import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'
import CategoryName from '../../utils/categoryName'
import log from '../../utils/log'
import useCheckCodePushVersion from '../../utils/useConvertCodePushVersion'

const ModalContents = observer(() => {
  const token = AuthStore?.token
  const target = ModalStore?.currentModal
  const propsToTransfer = {
    stageCount: 3,
    userWinRatio: 0.7,
  }
  const navigate = useNavigate()
  const codePushVersionAvailable = useCheckCodePushVersion()

  if (!target?.open) return null

  const { type } = target
  if (!type) return null

  if (type === 'rockPaperScissors') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='my-2 text-xl font-bold'>
          가위바위보 <span className='text-red-500'>EVENT</span>
        </div>
        <div>가위바위보 한 판 어때요?</div>
        <div>승리하면 특별한 기회를 드려요!</div>
        <button
          type='button'
          className='flex flex-row flex-1 px-4 py-2 mt-5 text-lg font-bold text-white bg-[#129DEF] hover:bg-[#1258A4] rounded-xl'
          onClick={() => {
            navigate('/deal/rock-paper-scissors', {
              state: propsToTransfer,
            })
            if (ModalStore?.currentModal?.confirmCallback)
              ModalStore?.currentModal?.confirmCallback()
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          지금 입장하기
        </button>
      </div>
    )
  }
  if (type === 'randomPick') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='my-2 text-xl font-bold'>
          뽑기 <span className='text-red-500'>EVENT</span>
        </div>
        <div>두근두근 뽑기!</div>
        <div>무엇이 나올까요?</div>
        <button
          type='button'
          className='flex flex-row flex-1 px-4 py-2 mt-5 text-lg font-bold text-white bg-[#129DEF] hover:bg-[#1258A4] rounded-xl'
          onClick={() => {
            navigate('/deal/random-pick', {
              state: propsToTransfer,
            })
            if (ModalStore?.currentModal?.confirmCallback)
              ModalStore?.currentModal?.confirmCallback()
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          지금 입장하기
        </button>
      </div>
    )
  }
  if (type === 'eventDealExit') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='my-2 text-xl font-bold'>
          지금 나가면 <span className='text-red-500'>보상이 사라져요</span>
        </div>
        <div>특별한 기회를 포기할까요?</div>
        <div className='flex flex-row items-center justify-between flex-1 w-full'>
          <button
            type='button'
            className='flex flex-row justify-center flex-1 px-4 py-2 mt-5 mr-4 text-lg font-bold text-gray-400 hover:bg-red-200 hover:text-white rounded-xl'
            onClick={() => {
              if (
                codePushVersionAvailable(
                  UserStore?.userInfo?.codepushVersion,
                  '2.5.49',
                )
              ) {
                navigate(-1)
              } else {
                navigate('/deal/time-deal')
              }
              if (ModalStore?.currentModal?.confirmCallback)
                ModalStore?.currentModal?.confirmCallback()
              ModalStore.setCurrentModal({ open: false })
            }}
          >
            포기하기
          </button>
          <button
            type='button'
            className='flex flex-row flex-1 justify-center px-4 py-2 mt-5 text-lg font-bold text-white bg-[#129DEF] hover:bg-[#1258A4] rounded-xl'
            onClick={() => {
              ModalStore.setCurrentModal({ open: false })
            }}
          >
            계속하기
          </button>
        </div>
      </div>
    )
  }
  if (type === 'celebrate') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='my-2 text-xl font-bold text-[#129DEF]'>
          축하드립니다
        </div>
        <div>게임에 승리하셨어요!</div>
        <div>보상으로 특가가 제공돼요</div>
        <div className='flex flex-row items-center justify-between flex-1 w-full'>
          <button
            type='button'
            className='flex flex-row flex-1 justify-center px-4 py-2 mt-5 text-lg font-bold text-white bg-[#129DEF] hover:bg-[#1258A4] rounded-xl'
            onClick={() => {
              if (ModalStore?.currentModal?.confirmCallback)
                ModalStore?.currentModal?.confirmCallback()
              ModalStore.setCurrentModal({ open: false })
            }}
          >
            확인
          </button>
        </div>
      </div>
    )
  }
  if (type === 'lose') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='my-2 text-xl font-bold text-[#129DEF]'>아쉬워요</div>
        <div>게임에 패배하셨어요!</div>
        <div>다음에 또 만나요!</div>
        <div className='flex flex-row items-center justify-between flex-1 w-full'>
          <button
            type='button'
            className='flex flex-row flex-1 justify-center px-4 py-2 mt-5 text-lg font-bold text-white bg-[#129DEF] hover:bg-[#1258A4] rounded-xl'
            onClick={() => {
              if (ModalStore?.currentModal?.confirmCallback)
                ModalStore?.currentModal?.confirmCallback()
              ModalStore.setCurrentModal({ open: false })
            }}
          >
            확인
          </button>
        </div>
      </div>
    )
  }
  if (type === 'randomPickWin') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='my-2 text-xl font-bold text-[#129DEF]'>
          뽑기를 완료하셨어요!
        </div>
        <div className='flex flex-col jutify-items-center'>
          {ModalStore?.currentModal?.categoryIds?.map((categoryId, idx) => {
            return (
              <div
                key={categoryId}
                className='px-3 py-1 mb-2 mr-2 font-bold text-center text-white bg-green-500 shadow-lg justify-items-center rounded-xl'
              >
                {CategoryName(categoryId)}
              </div>
            )
          })}{' '}
        </div>
        <div>카테고리 특가가 제공돼요</div>
        <div className='flex flex-row items-center justify-between flex-1 w-full'>
          <button
            type='button'
            className='flex flex-row flex-1 justify-center px-4 py-2 mt-5 text-lg font-bold text-white bg-[#129DEF] hover:bg-[#1258A4] rounded-xl'
            onClick={() => {
              if (ModalStore?.currentModal?.confirmCallback)
                ModalStore?.currentModal?.confirmCallback()
              ModalStore.setCurrentModal({ open: false })
            }}
          >
            확인
          </button>
        </div>
      </div>
    )
  }

  return null
})

export default ModalContents
