import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { OverlayProvider } from '@toss/use-overlay'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import './App.css'
import Viewer from './Viewer'

export const ViewerApp = () => {
  const [queryClient] = useState(() => new QueryClient())
  const location = useLocation()
  const { chapterId } = location.state || {}

  return (
    <QueryClientProvider client={queryClient}>
      <OverlayProvider>
        {/* Viewer 컴포넌트는 chapter가 바뀌면 unmount 하는 걸 전제로 useEffect등이 구현되어있어 key를 chapId로 설정해줍니다. */}
        <Viewer key={chapterId} />
      </OverlayProvider>
    </QueryClientProvider>
  )
}
