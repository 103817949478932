import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useSearchParam } from 'react-use'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import AuthStore from '../../stores/AuthStore'
import RouteStore from '../../stores/RouteStore'
import ToastStore from '../../stores/ToastStore'
import THUserStore from '../../stores/treasureHunt/THUserStore'
import backendApis from '../../utils/backendApis'
import MovingComponentsScreen from './MovingComponentsScreen'
import THImageText from './THImageText'
import THSelectItemComponent from './THSelectItemComponent'
import TreasureHuntButton from './TreasureHuntButton'
import HeaderComponent from './molecules/HeaderComponent'

const THSelectItem = observer(() => {
  const [isLoading, setIsLoading] = useState(true)
  const [itemList, setItemList] = useState([
    {
      itemTitle: '스타벅스 아메리카노',
      teamPurchasePrice: 3000,
      mainImageUri: '../images/etc/star.png',
    },
  ])

  const [clearedUserList, setClearedUserList] = useState([])
  const [scrollIndicatorOn, setScrollIndicatorOn] = useState(true)

  useEffect(() => {
    const getItem = async () => {
      const result = await backendApis.fetchTreasureHuntItems({ skip: 0 })
      if (result?.status === 200) {
        const temp = [...result.data].reverse()
        setItemList(temp)
        setIsLoading(false)
      } else {
        ToastStore.networkError()
      }
    }
    const fetchTreasureHuntOrders = async ({ skip = 0 }) => {
      const result = await backendApis.fetchTreasureHuntOrders({ skip })
      setClearedUserList(result?.data)
    }

    getItem()
    // fetchTreasureHuntOrders({ skip: 0 })
  }, [])

  if (isLoading) return <></>

  return (
    <div className='fixed w-[100%] h-[100%] bg-white'>
      <HeaderComponent hideButtons />

      <div className='default h-full w-full overflow-y-hidden overflow-x-hidden px-[4vw] z-[11] justify-start items-center'>
        <div className='default justify-center items-center w-full bg-[#ffe6e6] rounded-lg px-4 py-2 mb-2'>
          <div className='font-bold text-[5vw] text-[#ff7d7d] w-full'>
            <THImageText
              className='text-center'
              text={`하루 1분만 투자하면\n 매달 신상 귀걸이가 공짜! 😘`}
            />
          </div>
        </div>
        <div
          className='relative bg-black/50 rounded-xl p-[3vw]'
          onPointerDown={() => {
            setScrollIndicatorOn(false)
          }}
        >
          {scrollIndicatorOn && (
            <div
              className='rounded-xl text-white'
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#000000aa',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                fontWeight: 'bold',
                fontSize: '6vw',
                zIndex: 10,
                top: '50%',
                left: '50%',

                transform: 'translate(-50%, -50%)',
              }}
            >
              <img
                style={{
                  width: '15vw',
                  zIndex: 222,
                  right: '40%',
                  top: '40%',
                }}
                src='../images/treasureHunt/finger.png'
                alt=''
                className='scroll-indicator '
              />
              <THImageText
                className='text-center'
                text={'공짜로 받을\n귀걸이들을 구경해보세요'}
              />
            </div>
          )}
          <div className='h-[60vh] overflow-y-scroll'>
            <div className='flex w-full justify-center items-center font-bold text-white'>
              👉 다양한 상품이 계속 추가될 예정이에요 👈
            </div>
            <div className='flex flex-wrap justify-around items-center gap-[1vw] mb-4 mt-4'>
              {itemList.map((item) => (
                <THSelectItemComponent item={item} key={Math.random()}>
                  {item.itemTitle}
                </THSelectItemComponent>
              ))}
            </div>
          </div>
        </div>
        <div className='relative mt-2 w-full'>
          <TreasureHuntButton
            text='보물찾기 시작하기'
            clickable={!scrollIndicatorOn}
            className={`
              py-3 w-full text-[5vw]
              ${!scrollIndicatorOn && 'pulsate-fwd'}
              `}
            onClick={() => {
              backendApis.THSet({
                set: {
                  selectPagePass: new Date(),
                },
                collection: 'user',
              })
              RouteStore.navigate(`/treasureHunt?token=${AuthStore?.token}`)
            }}
          />
          {/* {!scrollIndicatorOn && (
            <img
              className='flex absolute z-[101] w-[12vw] pulsate-fwd pointer-events-none right-0 -bottom-[3vw]'
              alt='txt'
              src='../images/treasureHunt/finger.png'
            />
          )} */}
        </div>
        <div className='flex flex-row text-[3vw] font-bold justify-center mt-1 bg-slate-50/50 rounded-full py-1 w-[55vw]'>
          <div
            className='default w-[4vw] mr-1'
            style={{
              backgroundImage: `url(../images/treasureHunt/selectShield.png)`,
            }}
          />
          <div className='text-[#D3526A]'>올웨이즈</div>가 보증하는 무료 상품
        </div>

        {/* <div className='default w-full h-[14vh] justify-around items-center flex-col mb-2 mt-2'>
          <MovingComponentsScreen
            userList={clearedUserList?.slice(0, 10) ?? []}
          />
          <MovingComponentsScreen
            userList={clearedUserList?.slice(10, 20) ?? []}
          />
        </div> */}
      </div>
    </div>
  )
})

export default THSelectItem
