import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import { Close } from '../../images/dailyCheckIn'
import backendApis from '../../utils/backendApis'

let isPressed = false

const TeamCheckInCreateTeamModal = observer(
  ({ visible, close, nameArray, reload, ...params }) => {
    const [selectedName, setSelectedName] = useState('')

    const handleNameSelection = (name) => {
      setSelectedName(name)
    }

    const Title = () => {
      return (
        <div className='flex flex-col items-center'>
          <div className='text-xl font-bold'>팀 만들기</div>
          <div className='text-sm py-1'>마음에 드는 팀 이름을 선택해주세요</div>
        </div>
      )
    }

    const NameCandidateList = () => {
      return (
        <>
          {nameArray.map((name) => {
            const displayName = name.replace(/_/g, ' ') // Replace underscores with spaces
            return (
              <button
                type='button'
                key={name}
                className={
                  selectedName === ''
                    ? `mx-4 py-4 border-b font-bold text-center cursor-pointer`
                    : `mx-4 py-4 border-b font-bold text-center cursor-pointer ${
                        selectedName === name
                          ? 'text-red-500 border-red-500'
                          : 'text-gray-300'
                      }`
                }
                onClick={() => handleNameSelection(name)}
              >
                {displayName}
              </button>
            )
          })}
        </>
      )
    }

    const CreateButton = () => {
      return (
        <button
          type='button'
          disabled={!selectedName}
          onClick={async () => {
            if (isPressed) return

            isPressed = true
            await backendApis.createTeamCheckIn(selectedName)
            reload()
            isPressed = false
            close()
          }}
          className={`w-4/5 ${
            !selectedName ? 'bg-gray-300' : 'bg-red-500'
          } py-3 px-2 rounded-lg justify-center align-center text-white text-base font-bold`}
        >
          선택하기
        </button>
      )
    }

    return (
      <div>
        {/* 딤드 배경 */}
        <button
          type='button'
          className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-10'
          onClick={close}
          aria-label='background'
        />
        {/* 모달 상자 */}
        <div
          className='fixed left-1/2 py-6 z-40 bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
          style={{ top: '46vh', width: '90%', height: '50vh', zIndex: 11 }}
        />

        <button
          type='button'
          className='fixed z-30'
          style={{ left: '86%', top: '16vh' }}
          onClick={close}
        >
          <Close className='w-6 h-6' />
        </button>

        {/* 모달 내용 + 받기 버튼 */}
        <div
          style={{ left: '9%', top: '24vh', width: '82vw', height: '44vh' }}
          className='flex flex-col fixed justify-between items-center z-30'
        >
          <Title />
          <div className='p-4 border rounded-lg w-full'>
            <NameCandidateList />
          </div>
          <CreateButton />
        </div>
      </div>
    )
  },
)

export default TeamCheckInCreateTeamModal
