import React from 'react'

const GuideTutorial = ({ setGuideTutorialOn }) => {
  return (
    <button
      type='button'
      className='flexCol items-center w-full h-full border fixed top-[00vw] bg-[#000000ba] px-4 py-2 shadow-md z-30 opacity-100 animate-slideUp text-center font-semibold text-white whitespace-nowrap'
      onClick={() => {
        setGuideTutorialOn(false)
      }}
    >
      <div>왼쪽으로 스크롤하는 작품이에요</div>
      <div>
        <strong>←</strong> 방향으로 감상해주세요!
      </div>
      <div className='w-[30vw] mt-[8vw] border border-white text-white font-bold py-2 px-4 rounded'>
        확인
      </div>
    </button>
  )
}

export default GuideTutorial
