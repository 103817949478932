import React from 'react'
import { FiChevronLeft } from 'react-icons/fi'

import UserStore from '../../stores/UserStore'

const HeaderComponent = ({
  checkInAlarmConsent,
  checkInAlarmTime,
  bgWhite = false,
  headerTitle = '',
  className = '',
  onClickBackButton = () => {
    window.location.href = `#navigate.${JSON.stringify({
      screen: 'MainALargeCategoryTabNavigator',
    })}`
    UserStore.setNavigation('MainALargeCategoryTabNavigator')
  },
  subButtonText = '설정',
  onClickSubButton = () => {
    window.location.href = `#navigate.${JSON.stringify({
      screen: 'CheckInSettingScreen',
      prop: {
        checkInAlarmConsent,
        checkInAlarmTime,
      },
    })}`
    UserStore.setNavigation('CheckInSettingScreen')
  },
}) => {
  return (
    <div className={className}>
      <div
        className={`flex flex-row  overflow-hidden z-0 relative items-center py-2 px-4
    ${bgWhite ? 'bg-white' : 'bg-[#F9D7D6]'} 
    `}
      >
        <button
          type='button'
          className={` duration-200 whitespace-nowrap active:scale-95
           ${bgWhite ? 'active:bg-gray-200' : 'active:bg-red-100'}`}
          onClick={() => {
            onClickBackButton()
          }}
        >
          <img
            src='/images/dailyCheckIn/Arrow_Left.svg'
            alt='설정'
            className='w-7 h-7'
          />
        </button>
        <div className='w-full p-2 text-lg font-semibold text-center text-[000000]'>
          {headerTitle}
        </div>
        <button
          type='button'
          onClick={() => {
            onClickSubButton()
          }}
        >
          <img
            src='/images/dailyCheckIn/Setting.svg'
            alt='설정'
            className='w-7 h-7'
          />
        </button>
      </div>
    </div>
  )
}

export default HeaderComponent
