import moment from 'moment'

import AuthStore from '../stores/AuthStore'
import ToastStore from '../stores/ToastStore'
import WatermelonUserStore from '../stores/WatermelonUserStore'
import THModalStore from '../stores/treasureHunt/THModalStore'
import backendApis from '../utils/backendApis'
import utility from '../utils/utility'

class WatermelonAction {
  async initGame({ data }) {
    if (data) {
      AuthStore.setCodePushVersion(data?.codepushVersion)
      AuthStore.setToken(data?.token)
    }
    const result = await backendApis.getWatermelonGameData({
      routeParams: data?.routeParams,
      phoneNumber: data?.phoneNumber,
      userName: data?.userName,
    })

    if (result?.status === 200) {
      // this.checkQuotaLimit()
      for (const init of result?.data?.initModalList ?? []) {
        THModalStore.setType({
          type: init?.type,
          config: { ...init?.config, forceOpen: true },
        })
      }

      backendApis.commonSet({
        set: {
          initModalList: [],
        },
        collection: 'watermelon_user',
      })
      if (process.env.NODE_ENV === 'development') {
        console.log('userData: ', result.data)
      }
      WatermelonUserStore.set('userData', result?.data)
      WatermelonUserStore.set('isLoading', false)
      WatermelonUserStore.set('dailyMission', result?.data?.dailyMission)
      const isNewbie =
        moment().isSameOrBefore(
          moment(result?.data?.createdAt ?? '2023-01-01').add(2, 'minutes'),
        ) && result?.data?.ticket === 3
      WatermelonUserStore.set('isNewbie', isNewbie)
    } else {
      ToastStore.networkError()
    }
  }

  async getRankingAll() {
    const result = await backendApis.getWatermelonRankingAll()
    if (result?.status === 200) {
      WatermelonUserStore.set('rankData', result?.data?.users)
      const rank = result?.data?.users?.findIndex(
        (user) =>
          user?.userId?.toString() ===
          WatermelonUserStore?.userData?.userId?.toString(),
      )
      if (rank < 0) {
        WatermelonUserStore.set('myRank', 99)
      } else {
        WatermelonUserStore.set('myRank', rank)
      }
      WatermelonUserStore.set('userData.userImageUri', result?.data?.myImage)
      WatermelonUserStore.set('rankLoadDone', true)
    } else {
      ToastStore.networkError()
    }
  }

  async giveWatermelonMissionReward({ type }) {
    const result = await backendApis.giveWatermelonMissionReward({ type })
    if (result?.status === 200) {
      WatermelonUserStore.set('dailyMission', result?.data?.dailyMission)
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '미션 성공!',
          description: `미션 보상으로 ${utility.translateVariable(
            result?.data?.rewardType,
          )} ${result?.data?.rewardAmount}${utility.translateUnitVariable(
            result?.data?.rewardType,
          )} 획득!`,
          src: `../watermelonAssets/dailyMission/${result?.data?.rewardType}.png`,
          text: '받기',
          forceOpen: true,
        },
      })

      if (WatermelonUserStore?.userData?.[`${result?.data?.rewardType}`]) {
        WatermelonUserStore.increase(
          `userData.${result?.data?.rewardType}`,
          result?.data?.rewardAmount,
        )
      } else {
        WatermelonUserStore.set(
          `userData.${result?.data?.rewardType}`,
          result?.data?.rewardAmount,
        )
      }
    } else if (result?.status === 204) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '미션 실패',
          description: `시간이 초과되었어요!`,
          src: `../watermelonAssets/sad.png`,
          text: '확인',
          forceOpen: true,
        },
      })
    } else {
      ToastStore.networkError()
    }
  }

  async checkContactPermission() {
    WatermelonUserStore.set('contactSkip', 0)
    WatermelonUserStore.set('uninstallUsers', [])

    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ type: 'checkContactPermission' }),
    )
  }

  async getRankingLocal({ contacts, skip = 0 }) {
    WatermelonUserStore.set('contactList', contacts)
    if (WatermelonUserStore.isBountyInviteLoad) {
      WatermelonUserStore.set('isBountyInviteLoad', false)
      const result2 = await backendApis.getChurnedUsersByContacts({
        contacts: [...contacts?.slice(skip, skip + 50)],
      })
      if (result2?.status === 200) {
        WatermelonUserStore.set('churnedUsers', [
          ...WatermelonUserStore?.churnedUsers,
          ...result2?.data,
        ])
        WatermelonUserStore.set('contactSkip', skip + 50)
      } else {
        ToastStore.networkError()
      }
      return
    }

    if (WatermelonUserStore.isContactInviteLoad) {
      WatermelonUserStore.set('isContactInviteLoad', false)
      const result2 = await backendApis.getUninstallUsersByContacts({
        contacts: [...contacts?.slice(skip, skip + 20)],
      })
      if (result2?.status === 200) {
        WatermelonUserStore.set('uninstallUsers', [
          ...WatermelonUserStore?.uninstallUsers,
          ...result2?.data,
        ])
        WatermelonUserStore.set('contactSkip', skip + 20)
      } else {
        ToastStore.networkError()
      }
      return
    }

    if (!WatermelonUserStore.isContactInviteLoad) {
      const result = await backendApis.getWatermelonRankingLocal({
        contacts: contacts?.map((contact) => contact?.phoneNumber),
      })
      if (result?.status === 200) {
        WatermelonUserStore.set('rankData', result?.data?.users)
        const rank = result?.data?.users?.findIndex(
          (user) =>
            user?.userId?.toString() ===
            WatermelonUserStore?.userData?.userId?.toString(),
        )
        if (rank < 0) {
          WatermelonUserStore.set('myRankLocal', 99)
        } else {
          WatermelonUserStore.set('myRankLocal', rank)
        }
        WatermelonUserStore.set('userData.userImageUri', result?.data?.myImage)

        const rankWithoutMe = result?.data?.users?.filter(
          (user) =>
            user?.userId?.toString() !==
            WatermelonUserStore?.userData?.userId?.toString(),
        )
        const currentRank = rankWithoutMe?.reduce(
          (closestIndex, user, currentIndex, array) => {
            const currentScoreDifference =
              user?.highscore - WatermelonUserStore?.currentScore
            const closestScoreDifference =
              array[closestIndex]?.highscore - WatermelonUserStore?.currentScore
            return Math.abs(currentScoreDifference) <
              Math.abs(closestScoreDifference)
              ? currentIndex
              : closestIndex
          },
          0,
        )
        const usersBelowMe = rankWithoutMe?.slice(currentRank, currentRank + 3)

        WatermelonUserStore.set('usersBelowCurrentRank', usersBelowMe)
        WatermelonUserStore.set('rankLoadDone', true)
      } else {
        ToastStore.networkError()
      }
    }
  }

  async linkShare({
    shareType,
    title,
    description,
    imageUrl,
    itemId,
    payload,
  }) {
    this.checkQuotaLimit()
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'share',
        data: {
          shareType,
          title,
          description,
          imageUrl,
          itemId,
          payload,
        },
      }),
    )
  }

  async kakaoLinkShare({
    shareType,
    title,
    description,
    imageUrl,
    itemId,
    payload,
    templateId,
  }) {
    this.checkQuotaLimit()
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'kakaoShare',
        data: {
          shareType,
          title,
          description,
          imageUrl,
          itemId,
          payload,
          templateId,
        },
      }),
    )
  }

  async checkQuotaLimit() {
    const result = await backendApis.checkQuotaLimit()
    if (result?.status === 200) {
      WatermelonUserStore.set(
        'kakaoQuotaLimit',
        result?.data?.kakaoQuotaLimit ?? false,
      )
      WatermelonUserStore.set(
        'dynamicQuotaLimit',
        result?.data?.dynamicQuotaLimit ?? false,
      )
      WatermelonUserStore.set(
        'hasKakaoLink',
        result?.data?.hasKakaoLink ?? false,
      )
      WatermelonUserStore.set(
        'hasInviteLink',
        result?.data?.hasInviteLink ?? false,
      )
      // 카카오 공유 보일지 말지
      if (result?.data?.kakaoQuotaLimit) {
        WatermelonUserStore?.set('showKakaoShare', false)
      } else if (
        result?.data?.dynamicQuotaLimit &&
        !result?.data?.hasKakaoLink
      ) {
        WatermelonUserStore?.set('showKakaoShare', false)
      } else {
        WatermelonUserStore?.set('showKakaoShare', true)
      }

      // 새친구 초대 보일지 말지
      if (result?.data?.dynamicQuotaLimit && !result?.data?.hasInviteLink) {
        WatermelonUserStore?.set('showInviteShare', false)
      } else {
        WatermelonUserStore?.set('showInviteShare', true)
      }

      // 비상공유 보일지 말지
      if (result?.data?.dynamicQuotaLimit && result?.data?.kakaoQuotaLimit) {
        WatermelonUserStore?.set('showEmergencyShare', false)
      }

      if (!result?.data?.dynamicQuotaLimit && result?.data?.kakaoQuotaLimit) {
        THModalStore.setType({
          type: 'watermelonRule',
          config: {
            title: '카카오 공유 안내',
            forceOpen: true,
            src: 'kakaoQuota',
            onClick: () => {
              this.linkShare({
                shareType: 'Watermelon_Invite',
                title: '[양파게임] 무료 양파 1Kg에 도전하세요!',
                description: '작물을 합쳐서 양파를 만들어내면 양파 1Kg가 무료!',
                imageUrl:
                  'https://assets.ilevit.com/e9741bf3-54c4-4fef-aa5c-3c80292086e1.png',
                payload: {},
              })
            },
            text: '새친구 초대하기',
          },
        })
      } else if (
        result?.data?.kakaoQuotaLimit &&
        result?.data?.dynamicQuotaLimit &&
        result?.data.hasInviteLink
      ) {
        THModalStore.setType({
          type: 'watermelonRule',
          config: {
            title: '카카오 공유 안내',
            forceOpen: true,
            src: 'kakaoQuota',
            onClick: () => {
              this.linkShare({
                shareType: 'Watermelon_Invite',
                title: '[양파게임] 무료 양파 1Kg에 도전하세요!',
                description: '작물을 합쳐서 양파를 만들어내면 양파 1Kg가 무료!',
                imageUrl:
                  'https://assets.ilevit.com/e9741bf3-54c4-4fef-aa5c-3c80292086e1.png',
                payload: {},
              })
            },
            text: '새친구 초대하기',
          },
        })
      }
    } else {
      ToastStore.networkError()
    }
  }

  async endTeamGame() {
    const result = await backendApis.endTeamGame()
    if (result?.status === 200) {
      this.initGame({ data: null })
    } else {
      ToastStore.networkError()
      this.initGame({ data: null })
    }
  }

  async startTeamGame() {
    const result = await backendApis.startTeamGame()
    if (result?.status === 200) {
      WatermelonUserStore.set('userData.teamgame', { ...result?.data })
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '3인 팀게임 시작!',
          description: `20분안에 3명 모두 성공하면 전원 교촌치킨 1마리씩 드려요!`,
          src: `../watermelonAssets/smile.png`,
          text: '확인',
          forceOpen: true,
        },
      })
    } else {
      ToastStore.networkError()
    }
  }

  async exitTeamGame({ isLeader }) {
    const result = await backendApis.exitTeamGame({ isLeader })
    if (result?.status === 200) {
      WatermelonUserStore.set('userData.teamgame', result?.data?.teamGame)
      this.initGame({ data: null })
    } else {
      this.initGame({ data: null })
    }
  }

  async fetchTeamgame() {
    const result = await backendApis.fetchTeamgame()
    if (result?.status === 200) {
      WatermelonUserStore.set('userData.teamgame', result?.data)
    } else {
      ToastStore.networkError()
      this.initGame({ data: null })
    }
  }

  async checkTeamgameClear() {
    const result = await backendApis.checkTeamgameClear()
    if (result?.status === 200) {
      WatermelonUserStore.set('userData.teamgame', result?.data)
      this.initGame({ data: null })
    } else {
      ToastStore.networkError()
      this.initGame({ data: null })
    }
  }

  postMessage(data) {
    window?.ReactNativeWebView?.postMessage(JSON.stringify(data))
  }
}

export default new WatermelonAction()
