import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

// eslint-disable-next-line import/no-cycle
import Roulette from '../../components/atoms/roulette'
import { Close } from '../../images/dailyCheckIn'
// eslint-disable-next-line import/no-cycle
import DailyCheckInModalHandler from '../dailyCheckIn/DailyCheckInModalHandler'

const CheckInPigDailyRouletteModal = observer(
  ({ visible, close, contents, fetchCustomizationData }) => {
    const [modalConfig, setModalConfig] = useState(false)
    const CloseButton = ({ onPointerDown }) => (
      <button
        type='button'
        className='absolute right-4 top-4 z-40'
        aria-hidden='true'
        onClick={onPointerDown}
      >
        <Close className='w-8 h-8' />
      </button>
    )
    const NoticeButton = () => {
      return (
        <div
          className='absolute left-4 top-4 z-20 text-white text-base border-b mb-1 border-white jua'
          aria-hidden='true'
          onPointerDown={async () => {
            setModalConfig({
              ...modalConfig,
              visible: true,
              modalType: 'CheckInPigModal',
              contents: 'RouletteNotice',
              setModalConfig,
            })
          }}
        >
          유의사항
        </div>
      )
    }

    return (
      <>
        <div
          className='fixed top-0 left-0 w-full h-full bg-black opacity-80 z-30'
          aria-hidden='true'
        />
        <div
          className='fixed top-1/2 left-1/2 pt-12 z-40 -translate-x-1/2 -translate-y-1/2'
          style={{ width: '100%', height: '100%' }}
        >
          <div className='w-full h-full flex flex-col items-center jua'>
            <div className='text-white text-2xl pb-5 z-40'>
              매일매일 행운룰렛
            </div>
            <Roulette
              prizes={[
                {
                  image: '/roulette/pigFoodLargeColor.png',
                  imageWidth: '10%',
                  imageHeight: '12%',
                  imageTop: '22%',
                  imageRight: '22%',
                  captionTop: '38%',
                  captionRight: '18%',
                  caption: '사료 1500g',
                  winningPercentage: 0.05,
                  rewardType: 'food',
                  rewardAmount: 1500,
                },
                {
                  image: '/roulette/pigFoodColored.png',
                  imageWidth: '14%',
                  imageHeight: '10%',
                  imageTop: '50%',
                  imageRight: '4%',
                  captionTop: '50%',
                  captionRight: '12%',
                  caption: '사료 100g',
                  winningPercentage: 0.3,
                  rewardType: 'food',
                  rewardAmount: 100,
                },
                {
                  image: '/roulette/happinessToyOnly.png',
                  imageWidth: '22%',
                  imageHeight: '22%',
                  imageTop: '78%',
                  imageRight: '10%',
                  captionTop: '62%',
                  captionRight: '18%',
                  caption: '장난감 2개',
                  winningPercentage: 0.2,
                  rewardType: 'toy',
                  rewardAmount: 2,
                },
                {
                  image: '/roulette/sadPig.png',
                  imageWidth: '14%',
                  imageHeight: '14%',
                  imageTop: '78%',
                  imageRight: '52%',
                  captionTop: '62%',
                  captionRight: '32%',
                  caption: '꽝! 😔',
                  winningPercentage: 0.15,
                  rewardType: 'fail',
                  rewardAmount: 0,
                },
                {
                  image: '/roulette/pinkPigItem.png',
                  imageWidth: '22%',
                  imageHeight: '22%',
                  imageTop: '50%',
                  imageRight: '60%',
                  captionTop: '50%',
                  captionRight: '38%',
                  caption: '핑크 돈돈이',
                  winningPercentage: 0.15,
                  rewardType: 'skin',
                  rewardAmount: 'pink',
                },
                {
                  image: '/roulette/superPig.png',
                  imageWidth: '14%',
                  imageHeight: '14%',
                  imageTop: '22%',
                  imageRight: '52%',
                  captionTop: '38%',
                  captionRight: '33%',
                  caption: '슈퍼돈돈이',
                  winningPercentage: 0.15,
                  rewardType: 'boostDondon',
                  rewardAmount: 1,
                },
              ]}
              rouletteTitle='dailyRoulette'
            />
          </div>
          <CloseButton
            onPointerDown={() => {
              fetchCustomizationData()
              close()
            }}
          />
          <NoticeButton />
        </div>
        {modalConfig?.visible && (
          <DailyCheckInModalHandler
            setModalConfig={setModalConfig}
            {...modalConfig}
          />
        )}
      </>
    )
  },
)

export default CheckInPigDailyRouletteModal
