import { observer } from 'mobx-react-lite'
import React from 'react'

import { Close, MoneyTreeIllustration } from '../../images/dailyCheckIn'

const MoneyTreeFriendInviteShakeNudge = observer(
  ({ visible, close, ...params }) => {
    const Title = () => {
      return (
        <div className='py-4 mt-2' style={{ fontSize: '6vw' }}>
          핸드폰을 <span className='text-red-500 '>흔들어보세요!</span>
        </div>
      )
    }

    const TextComponent = () => {
      return (
        <div
          style={{
            fontSize: '4vw',
            textAlign: 'center',
            color: '#517469',
            paddingTop: 4,
            paddingBottom: 4,
            marginTop: 12,
          }}
        >
          <div className='flex flex-row justify-center items-center'>
            <div className='px-2 py-1 mr-1 flex flex-row bg-[#2CCC77] text-white rounded-full'>
              <div className='w-6 mr-1 rounded-full overflow-hidden border border-white'>
                <img src={params?.profileUrl} alt=' ' />
              </div>
              {params?.userName.length < 5
                ? params?.userName
                : `${params?.userName.slice(0, 4)}...`}
            </div>
            <div>님과 텔레파시가 연결되어</div>
          </div>
          <div className='h-1' />
          <div>큰 보상을 받을 수 있어요</div>
        </div>
      )
    }

    return (
      <div>
        {/* 딤드 배경 */}
        <button
          type='button'
          className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-900 opacity-70 z-20'
          onClick={close}
          aria-label='Close Button'
        />
        {/* 모달 상자 */}
        <div
          className='fixed left-1/2 py-6 z-30  bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
          style={{ top: '52%', width: '90%', height: '44%' }}
        />

        <button
          type='button'
          className='fixed p-2 z-40 bg-gray-300 rounded-full'
          style={{ right: '6%', top: '24%' }}
          onClick={close}
        >
          <Close className='w-5 h-5' />
        </button>

        {/* 모달 내용 */}
        <div
          style={{ top: '28%', left: '5%', width: '90%', height: '40%' }}
          className='p-4 flex flex-col fixed items-center z-30'
        >
          <Title />
          <MoneyTreeIllustration className='w-36 h-36' />
          <TextComponent />
        </div>
      </div>
    )
  },
)

export default MoneyTreeFriendInviteShakeNudge
