// import React, { useState, useEffect } from 'react'
// import backendApis from '../utils/backendApis'
// import Layout from '../components/layout'
// import Header from '../components/header'
// import { useSearchParam } from 'react-use'
// import useDebounce from '../components/useDebounce'
// import { useAlert } from 'react-alert'
// import ZeroWonStoreSelectConfirmModal from '../components/ZeroWonStoreSelectConfirmModal'
// const AddressInfoPage = () => {
//   const [showSelectConfirmModal, setShowSelectConfirmModal] = useState(false)
//   const alert = useAlert()
//   const [isLoading, setIsLoading] = useState(true)
//   const [addressInfo, setAddressInfo] = useState()
//   const [zeroWonStoreItemList, setZeroWonStoreItemList] = useState()
//   const itemId = useSearchParam('itemId')
//   const itemTitle = useSearchParam('itemTitle')
//   const mainImageUri = useSearchParam('mainImageUri')
//   const debounce = useDebounce()
//   const [randomString, setRandomString] = useState(
//     Math.random().toString(36).substr(2, 8),
//   )
//   const token = useSearchParam('token')
//   const onMessageHandler = (event) => {
//     const getAddressInfo = async () => {
//       const result = await backendApis.getAddressInfo(token, 'GET')
//       if (result?.status === 200) {
//         setAddressInfo(result?.data)
//       } else {
//         alert.show(
//           `오류가 발생하였어요. 다시 시도해 주세요 ${JSON.stringify(result)}`,
//           {
//             closeCopy: '확인',
//           },
//         )
//       }
//     }
//     getAddressInfo()
//   }
//   useEffect(() => {
//     window.addEventListener('focus', onMessageHandler)
//     document.addEventListener('focus', onMessageHandler)
//     return () => {
//       window.removeEventListener('focus', onMessageHandler)
//       document.removeEventListener('focus', onMessageHandler)
//     }
//   }, [])
//   useEffect(() => {
//     const getAddressInfo = async () => {
//       const result = await backendApis.getAddressInfo(token, 'GET')
//       if (result?.status === 200) {
//         setAddressInfo(result?.data)
//       } else {
//         alert.show(`오류가 발생하였어요.\n다시 시도해 주세요`, {
//           closeCopy: '확인',
//         })
//       }
//     }
//     getAddressInfo()
//   }, [])
//   useEffect(() => {
//     const getZeroWonStoreItemList = async () => {
//       const result = await backendApis.getZeroWonStoreItemList(token, 'GET')
//       if (result?.status === 200) {
//         setZeroWonStoreItemList(result?.data)
//       } else {
//         alert.show(`오류가 발생하였어요. 다시 시도해 주세요`, {
//           closeCopy: '확인',
//         })
//       }
//     }
//     getZeroWonStoreItemList()
//   }, [])
//   const selectItemButton = async () => {
//     if (!addressInfo?.defaultAddressInfo?.addressId) {
//       alert.show('배송지를 추가해야 상품을 선택할 수 있어요', {
//         closeCopy: '확인',
//       })
//       return
//     }
//     setShowSelectConfirmModal(true)
//   }
//   return (
//     <Layout>
//       <Header />
//       <div style={{ backgroundColor: '#F5F5F5', height: 1 }} />
//       {/* 배송지 있을 때  시작 */}
//       {addressInfo?.defaultAddressInfo?.addressId ? (
//         <>
//           <div
//             style={{
//               //   backgroundColor: 'blue',
//               boxSizing: 'border-box',
//               padding: 20,
//               //   borderTop: 'solid 0.1px #BCBCBC',
//             }}
//           >
//             <div
//               style={{
//                 display: 'flex',
//                 flexDirection: 'row',
//                 justifyContent: 'space-between',
//               }}
//             >
//               <div style={{ fontSize: 18, marginBottom: 10 }}>배송지</div>
//               <div
//                 style={{
//                   fontSize: 18,
//                   marginBottom: 10,
//                   color: '#ff3e3e',
//                 }}
//                 onClick={() =>
//                   debounce(
//                     (window.location.href = `#navigate.${JSON.stringify({
//                       screen: 'ShippingAddress',
//                       prop: { origin: 'zeroWonStoreAddressScreen' },
//                     })}`),
//                     500,
//                   )
//                 }
//                 // onClick={() => {
//                 //   window.location.href = `#navigate.${JSON.stringify({
//                 //     screen: 'ShippingAddress',
//                 //     prop: { origin: 'zeroWonStoreAddressScreen' },
//                 //   })}`
//                 // }}
//               >
//                 변경하기
//               </div>
//             </div>
//             <div
//               style={{ fontSize: 16, color: '#BCBCBC', lineHeight: '22.4px' }}
//             >
//               <div style={{ paddingBottom: 2 }}>
//                 <span style={{ paddingRight: 10, paddingBottom: 50 }}>
//                   {addressInfo?.defaultAddressInfo?.recipient}
//                 </span>
//                 <span>
//                   {addressInfo?.defaultAddressInfo?.recipientPhoneNumber}
//                 </span>
//               </div>
//               <div style={{ paddingBottom: 2 }}>
//                 <span style={{ paddingRight: 5 }}>
//                   ({addressInfo?.defaultAddressInfo?.postcode})
//                 </span>
//                 <span>{addressInfo?.defaultAddressInfo?.postcodeAddress}</span>
//               </div>
//               <div style={{ paddingBottom: 2 }}>
//                 {addressInfo?.defaultAddressInfo?.detailAddress}
//               </div>
//               <div style={{ paddingBottom: 2 }}>
//                 공동현관 비밀번호 :
//                 {addressInfo?.defaultAddressInfo?.frontDoorPassword
//                   ? addressInfo?.defaultAddressInfo?.frontDoorPassword
//                   : ' 없음'}
//               </div>
//               <div style={{ paddingBottom: 2 }}>
//                 배송 수령 방법 :
//                 {addressInfo?.defaultAddressInfo?.receivingMethod !== 'door'
//                   ? addressInfo?.defaultAddressInfo?.receivingMethod
//                   : ' 문 앞'}
//               </div>
//             </div>
//           </div>
//         </>
//       ) : (
//         <>
//           <div
//             style={{
//               boxSizing: 'border-box',
//               padding: 20,
//             }}
//           >
//             <div
//               style={{
//                 display: 'flex',
//                 flexDirection: 'column',
//               }}
//             >
//               <div style={{ fontSize: 18, marginBottom: 10 }}>배송지</div>
//               <div
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                 }}
//               >
//                 <button
//                   style={{
//                     backgroundColor: '#fff',
//                     fontWeight: 'bold',
//                     fontSize: 18,
//                     paddingTop: 8,
//                     paddingBottom: 8,
//                     width: '100%',
//                     borderColor: '#ff3e3e',
//                     borderWidth: 1,
//                     borderRadius: 4,
//                     color: '#ff3e3e',
//                   }}
//                   onClick={() => {
//                     window.location.href = `#navigate.${JSON.stringify({
//                       screen: 'ShippingAddress',
//                       prop: { origin: 'zeroWonStoreAddressScreen' },
//                     })}`
//                   }}
//                 >
//                   배송지 추가하기
//                 </button>
//               </div>
//             </div>
//           </div>
//         </>
//       )}
//       <div style={{ backgroundColor: '#F5F5F5', height: 20 }} />
//       <div
//         style={{
//           boxSizing: 'border-box',
//           padding: 20,
//         }}
//       >
//         <div
//           style={{
//             fontSize: 20,
//             fontWeight: 'bold',
//             marginBottom: 10,
//           }}
//         >
//           0원 상품
//         </div>
//         <div style={{ display: 'flex', flexDirection: 'row' }}>
//           <img
//             src={
//               mainImageUri ||
//               'https://assets.ilevit.com/c4ca34f8-0772-49ad-aa0b-65f1aa1d5773_original.jpg'
//             }
//             style={{ width: 100, height: 100 }}
//           />
//           <div style={{ fontSize: 17, padding: 10 }}>
//             {itemTitle || '하이드로 더치커피 콜드브루 커피원액 30ml 8종 8팩'}
//           </div>
//         </div>
//       </div>
//       <div style={{ backgroundColor: '#F5F5F5', height: '100%' }}>
//         <div
//           style={{
//             justifyContent: 'center',
//             display: 'flex',
//             boxSizing: 'border-box',
//             padding: 15,
//           }}
//         >
//           <button
//             onClick={() => debounce(selectItemButton, 500)}
//             style={{
//               backgroundColor: '#EE3E3E',
//               color: 'white',
//               borderWidth: 0,
//               borderRadius: 24,
//               width: '80vw',
//               fontSize: 18,
//               fontWeight: 'bold',
//               paddingTop: 12,
//               paddingBottom: 12,
//               boxSizing: 'border-box',
//             }}
//           >
//             상품 선택하기
//           </button>
//         </div>
//       </div>
//       {showSelectConfirmModal && (
//         <ZeroWonStoreSelectConfirmModal
//           setShowSelectConfirmModal={setShowSelectConfirmModal}
//           token={token}
//           itemId={itemId}
//           randomString={randomString}
//           onClick={() => {
//             // pageScroll()
//           }}
//         />
//       )}
//     </Layout>
//   )
// }
// export default AddressInfoPage
import React, { Fragment, PureComponent, createRef } from 'react'
import { FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

const LOADING = 1
const LOADED = 2
const itemStatusMap = {}

const isItemLoaded = (index) => !!itemStatusMap[index]
const loadMoreItems = (startIndex, stopIndex) => {
  for (let index = startIndex; index <= stopIndex; index++) {
    itemStatusMap[index] = LOADING
  }
  return new Promise((resolve) =>
    setTimeout(() => {
      for (let index = startIndex; index <= stopIndex; index++) {
        itemStatusMap[index] = LOADED
      }
      resolve()
    }, 2500),
  )
}

class Row extends PureComponent {
  render() {
    const { index, style } = this.props
    let label
    if (itemStatusMap[index] === LOADED) {
      label = `Row ${index}`
    } else {
      label = 'Loading...'
    }
    return (
      <div className='ListItem' style={style}>
        {label}
      </div>
    )
  }
}

export default function TreasureDealPage() {
  return (
    <>
      <p className='Note'>
        This demo app mimics loading remote data with a 2.5s timer. While rows
        are "loading" they will display a "Loading..." label. Once data has been
        "loaded" the row number will be displayed. Start scrolling the list to
        automatically load data.
      </p>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={1000}
        loadMoreItems={loadMoreItems}
      >
        {({ onItemsRendered, ref }) => (
          <List
            className='List'
            height={150}
            itemCount={1000}
            itemSize={30}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={300}
          >
            {Row}
          </List>
        )}
      </InfiniteLoader>
      <p className='Note'>
        Check out the documentation to learn more:
        <br />
        <a href='https://github.com/bvaughn/react-window-infinite-loader#documentation'>
          github.com/bvaughn/react-window-infinite-loader
        </a>
      </p>
    </>
  )
}
