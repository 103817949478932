import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import AnimatedNumbers from 'react-animated-numbers'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import { Close, PigFoodColored } from 'src/images/dailyCheckIn'

const DailyCheckInFoodReceiveModal = observer(
  ({ visible, close, rewardAmount, ...params }) => {
    const navigate = useNavigate()
    const token = useSearchParam('token')
    const [displayAmount, setDisplayAmount] = useState(0)
    const [buttonDisabled, setButtonDisabled] = useState(true)

    useEffect(() => {
      if (!rewardAmount) return
      setTimeout(() => {
        setDisplayAmount(rewardAmount)
        setButtonDisabled(false)
      }, 500)
    }, [rewardAmount])

    return (
      <div>
        {/* 딤드 배경 */}
        <button
          className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-10'
          onClick={close}
        />
        {/* 모달 상자 */}
        <div
          className='fixed left-1/2 py-6 z-40 bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
          style={{ top: '42%', width: '90%', height: '44%', zIndex: 11 }}
        />
        <button
          className='fixed z-30'
          style={{ left: '82%', top: '16%' }}
          onClick={close}
        >
          <Close className='w-6 h-6' />
        </button>

        {/* 모달 내용 + 받기 버튼 */}
        <div
          style={{ left: '9%', top: '22%', width: '82%', height: '40%' }}
          className='flex flex-col fixed justify-around items-center z-30'
        >
          <div
            style={{ left: '30%', top: '18%', borderRadius: 8, height: 36 }}
            className='py-1 flex w-2/5 fixed bg-red-500 rounded-lg z-40 justify-center items-center font-bold text-white text-xl'
          >
            축하합니다!
          </div>
          <div
            className='flex flex-col justify-center items-end'
            style={{
              marginTop: 40,
            }}
          >
            <PigFoodColored className='w-32' />
            <div className='flex flex-row'>
              <AnimatedNumbers
                includeComma
                animateToNumber={displayAmount}
                fontStyle={{
                  fontSize: 32,
                  fontWeight: 'bold',
                }}
                configs={[{ mass: 1, tension: 320, friction: 50 }]}
              />
              <div
                style={{ marginBottom: 4, fontSize: 32, fontWeight: 'bold' }}
              >
                g
              </div>
            </div>
          </div>
          <button
            disabled={buttonDisabled}
            onClick={() => {
              navigate(`/check-in-pig?token=${token}`)
              close()
            }}
            className={
              buttonDisabled
                ? 'w-4/5 bg-gray-300 py-3 px-2 rounded-lg justify-center align-center text-white text-base font-bold'
                : 'w-4/5 bg-red-500 py-3 px-2 rounded-lg justify-center align-center text-white text-base font-bold'
            }
          >
            밥 주러가기
          </button>
        </div>
      </div>
    )
  },
)

export default DailyCheckInFoodReceiveModal
