import { observer } from 'mobx-react-lite'
import React from 'react'

import utility from '../../utils/utility'

const THSelectItemComponent = observer(
  ({ item, setSelectedItem, selectedItem }) => {
    return (
      <div
        className={`
  default rounded-lg justify-start items-center mb-4 transition-all
  `}
      >
        <img
          className={`
          h-[40vw] w-[40vw] rounded-lg mb-2 bg-white border-2 border-white
        
          `}
          alt='item'
          src={item?.mainImageUris?.[0]}
        />
        <div className='flex flex-row justify-start items-start font-bold'>
          <div className='text-left w-[40vw] h-[10vw] mb-[1px] text-[3.5vw] text-restrain text-[white]'>
            {`${utility.shortenText(item?.itemTitle.slice(6), 32)} `}
          </div>
        </div>
      </div>
    )
  },
)

export default THSelectItemComponent
