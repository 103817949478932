import styled, { css } from 'styled-components'

import { useAsyncOverlay } from '../utils/useAsyncOverlay'
import { CancelButton, ConfirmButton } from './Button'
import { Flex } from './Flex'
import { Popup } from './Popup'
import { Spacing } from './Spacing/Spacing'

interface Props {
  title?: string
  description?: string
  confirmText: string
  cancelText: string
  isOpen: boolean
  close: (isConfirmed: boolean) => void
}

const Confirm = (props: Props) => {
  return (
    <Popup isOpen={props.isOpen} onClose={() => props.close(false)}>
      <Wrapper>
        {props.title && <Title>{props.title}</Title>}

        {props.description && <Description>{props.description}</Description>}

        <Flex.Row
          css={css`
            gap: 10px;
            margin-top: 26px;
          `}
        >
          <CancelButton onClick={() => props.close(false)}>
            {props.cancelText}
          </CancelButton>
          <ConfirmButton onClick={() => props.close(true)}>
            {props.confirmText}
          </ConfirmButton>
        </Flex.Row>
      </Wrapper>
    </Popup>
  )
}

export const useConfirmModal = () => {
  const { openAsync } = useAsyncOverlay()
  return {
    open: (props: Omit<Props, 'isOpen' | 'close'>) =>
      openAsync<boolean>(({ isOpen, close }) => (
        <Confirm {...props} isOpen={isOpen} close={close} />
      )),
  }
}

const Wrapper = styled.div`
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  //styleName: Title 4/Bold;
  /* font-family: Pretendard Variable; */
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
`

const Description = styled.div`
  //styleName: Body 2/Medium;
  /* font-family: Pretendard Variable; */
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;

  margin-top: 12px;
`
