import { useSuspenseQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { useRenderEngine } from '../../hooks/useRenderEngine'
import { useScrollPositionMemo } from '../../hooks/useScrollPositionMemo'
import { getNovelChapterDocumentQuery } from '../../remotes/novelChapter'
import { viewerStore } from '../../store'
import { DocumentAlign, Theme } from '../../types/viewerOptions'
import { themeColors } from '../../utils/viewerOptions'
import { LastPagePanel } from '../LastPagePanel/LastPagePanel'
import { ShadowDOM } from '../ShadowDOM/ShadowDOM'
import { SwipeCliffs } from '../SwipeCliffs/SwipeCliffs'
import { PureHTMLRenderer } from './PureHTMLRenderer'

export const HTMLRenderer = observer(() => {
  const location = useLocation()
  const { chapterId, novelId } = location.state || {}

  const innerRef = useRef<HTMLDivElement>(null)

  const { data: novelChapterDoc } = useSuspenseQuery(
    getNovelChapterDocumentQuery({
      novelId,
      chapterId,
    }),
  )

  useRenderEngine({
    contentRef: innerRef,
    documentAlign: viewerStore.options.documentAlign,
    hasCover: false, // TODO
    totalPages: viewerStore.maxPage,
    updateTotalPages: (totalPages) => {
      viewerStore.setMaxPage(totalPages)
    },
  })

  useScrollPositionMemo({
    chapterId,
  })

  return (
    <Wrapper
      documentAlign={viewerStore.options.documentAlign}
      maxPage={viewerStore.maxPage}
      themeKey={viewerStore.options.theme}
    >
      {viewerStore.options.documentAlign === DocumentAlign.Horizontal && (
        <SwipeCliffs />
      )}

      {/* TODO: 스타일 관련 이슈 시 복구 - 스크롤 모드에서 뷰어 재진입 시 css 버그가 있어 제거해둠*/}
      {/* <ShadowDOM> */}
      <PureHTMLRenderer
        innerRef={innerRef}
        coverImgUrl={null} // TODO
        htmlString={novelChapterDoc.htmlString}
        documentAlign={viewerStore.options.documentAlign}
        maxPage={viewerStore.maxPage}
        fontFamily={viewerStore.options.fontFamily}
        fontSize={viewerStore.options.fontSize}
        lineHeight={viewerStore.options.lineHeight}
        padding={viewerStore.options.padding}
      />
      {/* </ShadowDOM> */}

      <LastPagePanel />
    </Wrapper>
  )
})

const Wrapper = styled.div<{
  documentAlign: DocumentAlign
  maxPage: number
  themeKey: Theme
}>`
  ${({ documentAlign, maxPage, themeKey }) => {
    const theme = themeColors[themeKey]

    if (documentAlign === DocumentAlign.Horizontal) {
      return css`
        position: relative;
        width: ${(maxPage + 1) * 100}vw;
        -webkit-transform: translate3d(0, 0, 0);
        display: flex;
        background: ${theme.bgColor};
        color: ${theme.fontColor};
      `
    }

    if (documentAlign === DocumentAlign.Vertical) {
      return css`
        display: flex;
        flex-direction: column;
        background: ${theme.bgColor};
        color: ${theme.fontColor};
      `
    }
  }}
`
