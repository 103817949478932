import React from 'react'

import { useMyFlow } from '../../hooks/altoon/useMyFlow.ts'
import AltoonUserStore from '../../stores/AltoonUserStore.jsx'

const QuicklinkComponent = () => {
  const { push } = useMyFlow()

  const QuicklinkButton = ({
    path,
    prop,
    selectTab,
    src,
    srcType = 'png',
    label,
    children,
  }) => {
    return (
      <button
        type='button'
        className='relative w-full flex flex-col justify-center items-center '
        onClick={() => {
          push(path, {
            prop: {
              ...prop,
            },
            component: 'main_quicklink',
          })
          if (selectTab) {
            window.localStorage.setItem('toon_default_tab', selectTab)
          }
        }}
      >
        <img
          src={`/images/toon/quicklink/mainQuicklink_${src}.${srcType}`}
          className={
            srcType === 'gif'
              ? 'w-[18vw] h-[18vw] object-cover'
              : 'w-[14vw] h-[14vw]'
          }
          alt=''
        />
        <div className='absolute -bottom-[5vw] text-[3vw]'>{label}</div>
      </button>
    )
  }

  return (
    <div className='w-full px-[2vw] py-[4vw] flex flex-row justify-between'>
      {AltoonUserStore?.userData?.isAdmin &&
        AltoonUserStore?.userData?.isAdmin === true && (
          <QuicklinkButton
            path='AltoonMainEventPage'
            src='seasonPromo'
            srcType='gif'
            label='연말결산'
          />
        )}
      {/* 연말결산 퀵링크가 있는 동안에는 웹툰으로 통합 운영 */}
      <QuicklinkButton
        path='AltoonToonPage'
        selectTab='daily'
        src='daily'
        label='요일연재'
      />
      <QuicklinkButton
        path='AltoonToonPage'
        selectTab='recommend'
        src='recommend'
        label='완결작품'
      />
      {/* <QuicklinkButton path='AltoonToonPage' src='recommend' label='웹툰' /> */}
      <QuicklinkButton path='AltoonNovelPage' src='novel' label='웹소설' />
      <QuicklinkButton path='AltoonTicketPage' src='coin' label='무료코인' />
      <QuicklinkButton path='AltoonEventListPage' src='event' label='이벤트' />
    </div>
  )
}
export default QuicklinkComponent
