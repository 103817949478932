import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import Header from '../../components/atoms/header'
import VerticalList from '../../components/deal/item/verticalList'
import VisitedTimeDealItemComponent from '../../components/deal/item/visitedTimeDealItemComponent'
import dealImages from '../../images/deal'
import AuthStore from '../../stores/AuthStore'
import ItemImpressionStore from '../../stores/ItemImpressionStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'

const VisitedTimeDealPage = observer(() => {
  const [dealItemsInfo, setDealItemsInfo] = useState([])
  const [dealEndedAt, setDealEndedAt] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [indices, setIndices] = useState([])
  const dealType = 'timeDeal'
  const version = 'visitedTimeDeal'
  const loadNum = dealItemsInfo?.length
  const SCREEN_WIDTH = window.innerWidth
  const SCREEN_HEIGHT = window.innerHeight

  const fetchDealItem = async (
    dealType,
    loadNum,
    version,
    indices,
    condition,
    first = false,
  ) => {
    const itemIndices = indices?.slice(0, 32)
    setIndices((arr) => arr.slice(32))
    const result = await backendApis.getDealItemsV3(
      dealType,
      loadNum,
      version,
      itemIndices,
      condition,
    )
    dealStateSetter(result, first)
  }

  const dealStateSetter = (result, first) => {
    if (result?.status === 200) {
      if (first) {
        setDealItemsInfo(result?.data)
      } else {
        setDealItemsInfo((arr) => [...arr, ...result?.data])
      }
      if (result?.itemIndices?.length > 0) {
        setIndices(result?.itemIndices)
      }
    }
  }
  const renderItem = (index, data, isScrolling) => {
    return (
      <VisitedTimeDealItemComponent
        key={`${index}`}
        itemInfo={data}
        direction='vertical'
        dealEndedAt={dealEndedAt}
        isFrom='VisitedTimeDealPage'
        isScrolling={isScrolling}
      />
    )
  }
  const Skeleton = () => {
    return (
      <>
        <VisitedTimeDealItemComponent direction='vertical' empty />
        <VisitedTimeDealItemComponent direction='vertical' empty />
        <VisitedTimeDealItemComponent direction='vertical' empty />
        <VisitedTimeDealItemComponent direction='vertical' empty />
        <VisitedTimeDealItemComponent direction='vertical' empty />
        <VisitedTimeDealItemComponent direction='vertical' empty />
      </>
    )
  }

  useEffect(() => {
    if (AuthStore.token) {
      fetchDealItem(dealType, loadNum, version)
      UserStore.loadTargetEventDeal().then(() => {
        ItemImpressionStore.setJourneyBlock(
          `${ItemImpressionStore?.journeyBlockBase}_${UserStore?.targetEventGame}`,
        )
        setIsLoading(false)
      })
    }
  }, [AuthStore.token])

  return (
    <>
      <Header title='맞춤특가' goBackRoute='/deal/time-deal' />
      <div className='pt-12'>
        <img
          src={dealImages?.VisitedTimeDealMainImage}
          alt='VisitedTimeDealMainImage'
        />
        {!isLoading && dealItemsInfo?.length > 0 ? (
          <VerticalList
            itemData={dealItemsInfo}
            height={SCREEN_HEIGHT}
            renderItem={renderItem}
            // loadMore={loadMore}
            isFrom='VisitedTimeDealPage'
            isItemList
          />
        ) : (
          <Skeleton />
        )}
      </div>
    </>
  )
})
export default VisitedTimeDealPage
