import EggMoneyStore from '../stores/EggMoney/EggMoneyStore'
import RouteStore from '../stores/RouteStore'
import ToastStore from '../stores/ToastStore'
import ABStore from '../stores/treasureHunt/ABStore'
import THModalStore from '../stores/treasureHunt/THModalStore'
import backendApis from '../utils/backendApis'

class EggMoneyAction {
  haptic() {
    this.postMessage({ type: 'haptic', haptic: true })
  }

  postMessage(data) {
    window?.ReactNativeWebView?.postMessage(JSON.stringify(data))
  }

  async claimEgg() {
    const res = await backendApis.claimEgg()
    if (res?.status === 200) {
      const claimEggHistory = EggMoneyStore?.claimEggHistory
      const lastClaimedEgg = res?.data?.lastClaimedEgg

      claimEggHistory.unshift(lastClaimedEgg)
      EggMoneyStore.set('claimEggHistory', [...claimEggHistory])
      if ((EggMoneyStore?.user?.bonusClaimCount ?? 0) > 0) {
        EggMoneyStore.increase('user.bonusClaimCount', -1)
      }

      //------------ 7/20일에 제거------------------
      if (
        ABStore.bonusClaimTester === 'a' &&
        EggMoneyStore?.user?.claimCount < 3
      ) {
        EggMoneyStore.set('user.claimCount', 4)
        backendApis.eggMoneySet({
          set: {
            claimCount: 4,
          },
          collection: 'eggMoney_user',
        })
      }
      //------------ 7/20일에 제거------------------

      // Open modal
      const claimedEgg = res?.data?.egg
      EggMoneyStore.set('claimedEgg', {
        ...claimedEgg,
        isNewEgg: res?.data?.isNewEgg,
        tags: res?.data?.tags,
      })
      window.localStorage.setItem('eggName', claimedEgg?.name)
      // THModalStore.setType({
      //   type: 'eggMoneyBasicModal',
      //   config: {
      //     forceOpen: true,
      //     title: `새 에그 획득!`,
      //     name: claimedEgg?.name,
      //     src: claimedEgg?.img,
      //     earnEffect: true,
      //     clickable: true,
      //     buttonText: newbie ? '또 뽑아보기' : '보관함으로 이동',
      //     rarity: claimedEgg?.rarity,
      //     onConfirm: () => {
      //       if (newbie) {
      //         THModalStore.close()
      //       } else {
      //         THModalStore.close()
      //         window.localStorage.setItem('eggName', claimedEgg?.name)
      //         RouteStore?.navigate(`/eggMoney/eggBox`)
      //       }
      //     },
      //   },
      // })
    } else {
      ToastStore.networkError(res.status)
    }
  }

  async fetchBookmarkedEggs() {
    const res = await backendApis.fetchAllBookmarks({})
    if (res?.status === 200) {
      EggMoneyStore.set('allBookmarks', res?.data?.allBookmarks)
    } else {
      ToastStore.networkError(res.status)
    }
  }

  sortByName({ arr, saveFn = () => {} }) {
    if (arr?.length <= 0) return
    const array = arr.sort((a, b) => {
      return a?.name?.localeCompare(b?.name)
    })
    saveFn(array)
    return array
  }

  filterByRarity({ arr, rarity, saveFn, type }) {
    let array = arr.filter((egg) => egg?.rarity === rarity)
    if (type === 'eggList') {
      array = arr.filter((egg) => egg?.egg?.rarity === rarity)
    } else {
      array = arr.filter((egg) => egg?.rarity === rarity)
    }
    saveFn(array)
    return array
  }

  sortByRarity({ arr, saveFn = () => {} }) {
    if (arr?.length <= 0) {
      saveFn(arr)
      return
    }
    let array = arr.sort((a, b) => {
      return a?.name?.localeCompare(b?.name)
    })
    array = arr.sort((a, b) => {
      const rarityOrder = { ss: 5, s: 4, a: 3, b: 2, c: 1 }
      const defaultOrder = Object.keys(rarityOrder).length + 1
      const rarityA = rarityOrder[a?.rarity] || defaultOrder // 예외 케이스를 위해 기본값 설정
      const rarityB = rarityOrder[b?.rarity] || defaultOrder // 예외 케이스를 위해 기본값 설정
      return rarityA - rarityB
    })
    saveFn(array)
    return array
  }

  sortByPrice({ arr, saveFn = () => {}, isDesc = true }) {
    const sortedArray = arr.slice().sort((a, b) => {
      if ((a.price ?? 0) === (b.price ?? 0)) {
        return a.name.localeCompare(b.name)
      }
      return isDesc
        ? (b.price ?? 0) - (a.price ?? 0)
        : (a.price ?? 0) - (b.price ?? 0)
    })

    saveFn(sortedArray)
    return sortedArray
  }

  sortByCount({ arr, saveFn = () => {} }) {
    if (arr?.length <= 0) return
    let array = arr.sort((a, b) => {
      return (b.count ?? 0) - (a.count ?? 0)
    })
    array = this.sortByName({ arr: array })
    saveFn(array)
    return array
  }

  async linkShare({
    shareType,
    title,
    description,
    imageUrl,
    itemId,
    payload,
  }) {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'share',
        data: {
          shareType,
          title,
          description,
          imageUrl,
          itemId,
          payload,
        },
      }),
    )
  }

  async initiateEggMoney({ routeParams }) {
    this.fetchBookmarkedEggs()
    const res = await backendApis.initiateEggMoney({ params: { routeParams } })
    if (res?.status === 200) {
      EggMoneyStore.setMultiple(res.data)
      const userData = res.data?.user

      if (userData?.initModalList) {
        const latestTenModalList = userData?.initModalList.slice(-10)
        for (const init of latestTenModalList) {
          THModalStore.setType(init)
        }
        backendApis.eggMoneySet({
          set: {
            initModalList: [],
          },
          collection: 'eggMoney_user',
        })
      }
    } else {
      ToastStore.networkError(res.status)
    }
  }

  userLog({ tag, payload, type }) {
    backendApis.userLog({
      params: {
        tag,
        payload,
        type,
      },
    })
  }

  async rouletteInviteResult() {
    const res = await backendApis.rouletteInviteResult({})
    if (res?.status === 200) {
      EggMoneyStore.increase('user.rouletteTicket', -1)
      return {
        finalRotation: res.data.finalRotation,
        isSSrank: res.data.isSSrank,
      }
    }
    if (res.status === 201) {
      ToastStore.toastOn({
        message: '티켓이 부족해요!',
        type: 'emoji',
        emoji: '🐣',
        duration: 600,
      })
    }
    ToastStore.networkError()
    return { finalRoation: false, isSSrank: false }
  }
}
export default new EggMoneyAction()
