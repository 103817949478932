import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useMyFlow } from '../../hooks/altoon/useMyFlow.ts'
import AuthStore from '../../stores/AuthStore'
import backendApis from '../../utils/backendApis'
import resize from '../../utils/resize'

const BannerSwiperComponent = ({ bannerData, bannerType = '', empty }) => {
  const pathMapper = {
    '/altoon-reader': 'AltoonReaderPage',
    '/altoon-novel-reader': 'AltoonNovelReaderPage',
    '/altoon-item': 'AltoonItemPage',
    '/altoon-event-page': 'AltoonEventPage',
  }

  const [swiperIndex, setSwiperIndex] = useState(0)
  const navigate = useNavigate()
  const { push } = useMyFlow()
  const handleBannerClick = (data, index) => {
    backendApis.logToonAction('AltoonMainBanner', 'clickedBanner', {
      toonInfo: data?.state?.toonInfo?._id,
      title: data?.state?.toonInfo?.title,
    })

    // URL에서 pathname과 쿼리 파라미터 분리
    const queryString = data?.pathname?.split('?')
    const searchParams = new URLSearchParams(queryString[1])
    const eventId = searchParams.get('eventId') || ''
    const itemId = searchParams.get('itemId') || ''

    if (queryString[0] === '/altoon-novel-reader') {
      const novelId = data?.state?.novelId
      navigate(`/altoon-novel-detail?novelId=${novelId}`)
    } else {
      push(pathMapper[queryString[0]], {
        state: {
          ...data?.state,
        },
        ...(eventId && { eventId }),
        ...(itemId && { itemId }),
        component: `banner_${bannerType}`,
        index,
      })
    }
  }

  if (empty) {
    if (bannerType === 'main') {
      return (
        <div className='flex justify-center w-full h-[66vw] mb-[2vw] bg-gray-200' />
      )
    }
    if (bannerType === 'toon') {
      return (
        <div className='flex justify-center w-full h-[100vw] mb-[2vw] bg-gray-200 rounded-xl' />
      )
    }
  }

  if (bannerType === 'short') {
    return (
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        initialSlide={swiperIndex}
        onSlideChange={(swiper) => {
          setSwiperIndex(swiper.activeIndex)
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        navigation
        modules={[Pagination, Navigation, Autoplay]}
        direction='horizontal'
      >
        {bannerData?.map((banner, index) => (
          <SwiperSlide key={index || Math.random()}>
            <button
              type='button'
              className='w-[100vw] h-[20vw] mb-[4vw]'
              onClick={() => {
                handleBannerClick(banner?.navigateTo, index)
              }}
            >
              <img
                key={index || Math.random()}
                className='w-full h-auto'
                src={resize(banner?.imageUri, 1200, 240)}
                alt='banner'
              />
            </button>
          </SwiperSlide>
        ))}
      </Swiper>
    )
  }

  if (bannerType === 'toon') {
    return (
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        initialSlide={swiperIndex}
        onSlideChange={(swiper) => {
          setSwiperIndex(swiper.activeIndex)
        }}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        navigation
        modules={[Pagination, Navigation, Autoplay]}
        direction='horizontal'
      >
        {bannerData?.map((banner, index) => (
          <SwiperSlide key={index || Math.random()}>
            <button
              type='button'
              className='w-full h-[100vw]'
              onClick={() => {
                handleBannerClick(banner?.navigateTo, index)
              }}
            >
              <img
                key={index || Math.random()}
                className='w-full h-full rounded-lg'
                src={resize(banner?.imageUri, 920, 1000)}
                alt='banner'
              />
            </button>
          </SwiperSlide>
        ))}
        <div className='absolute top-[4vw] right-[2vw] text-[3vw] z-10 px-[3vw] py-[1vw] text-white rounded-full bg-[#00000035]'>
          {swiperIndex + 1}/{bannerData?.length}
        </div>
      </Swiper>
    )
  }

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      initialSlide={swiperIndex}
      onSlideChange={(swiper) => {
        setSwiperIndex(swiper.activeIndex)
      }}
      autoplay={{
        delay: 4500,
        disableOnInteraction: false,
      }}
      // pagination={{
      //   type: 'bullets',
      //   clickable: true,
      //   dynamicBullets: true,
      // }}
      navigation
      modules={[Pagination, Navigation, Autoplay]}
      direction='horizontal'
    >
      {bannerData?.map((banner, index) => (
        <SwiperSlide key={index || Math.random()}>
          <button
            type='button'
            onClick={() => {
              handleBannerClick(banner?.navigateTo, index)
            }}
          >
            <img
              key={index || Math.random()}
              className='w-[100vw] h-full'
              src={resize(banner?.imageUri, 1230, 825)}
              alt='banner'
            />
          </button>
        </SwiperSlide>
      ))}
      <div className='absolute bottom-[4vw] right-[2vw] text-[3vw] z-10 px-[3vw] py-[1vw] text-white rounded-full bg-[#00000035]'>
        {swiperIndex + 1}/{bannerData?.length}
      </div>
    </Swiper>
  )
}
export default BannerSwiperComponent
