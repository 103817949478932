import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import THUserStore from '../../stores/treasureHunt/THUserStore'
import THImageText from './THImageText'

const THAchievementItem = observer(({ achievement }) => {
  const [progress, setProgress] = useState(0)
  // useEffect(() => {
  //   if (achievement?.type === 'earn') {
  //     setProgress(THUserStore?.dailyPoint)
  //   } else if (achievement?.type === 'search') {
  //     setProgress(THUserStore?.dailyFindTreasureCount?.count)
  //   } else if (achievement?.type === 'missionClear') {
  //     console.log(
  //       'dailyMissionClearCount?.count',
  //       THUserStore?.dailyMissionClearCount?.count,
  //     )
  //     setProgress(THUserStore?.dailyMissionClearCount?.count)
  //   } else if (achievement?.type === 'consecutiveAttendance') {
  //   } else if (achievement?.type === 'lunchFairy') {
  //   } else if (achievement?.type === 'friend') {
  //   } else if (achievement?.type === 'specialAnimal') {
  //   } else if (achievement?.type === 'scratch') {
  //   } else if (achievement?.type === 'searchCumulative') {
  //   }
  // }, [THUserStore?.dailyMissionClearCount])

  return (
    <div className='flex flex-row justify-around items-center w-full mb-8 text-[#313138]'>
      {achievement.objectives.map((item, level) => {
        return (
          <div key={Math.random()}>
            <div
              className={`w-[30vw] h-[30vw] mb-3 rounded-xl relative
              ${
                THUserStore?.achievement?.[achievement?.type]?.[level]
                  ?.clearedAt
                  ? 'bg-[#FFEDEC]'
                  : 'bg-[#f8f8f8]'
              }
              `}
            >
              <img
                className='w-full h-full'
                src={`../images/treasureHunt/achievement/${
                  achievement?.type
                }-${level}-${
                  THUserStore?.achievement?.[achievement?.type]?.[level]
                    ?.clearedAt
                    ? 'cleared'
                    : 'yet'
                }.png`}
                alt='emoji'
              />
              <img
                className='absolute -bottom-2 -right-2 w-[8vw] h-[8vw]'
                src={`../images/treasureHunt/achievement/${
                  THUserStore?.achievement?.[achievement?.type]?.[level]
                    ?.clearedAt
                    ? level
                    : 'empty2'
                }medal.png`}
                alt='medal'
              />
            </div>
            <div className='text-[3.2vw] font-bold -mb-1'>
              {`${achievement?.textOne} ${commaNumber(item)}${
                achievement?.textTwo
              }`}
            </div>
            {/* <div className='text-[2.5vw] font-bold -mb-1 text-[#8E8D9B]'>
              {`${commaNumber(progress)}/${commaNumber(item)} (${Math.round(
                (progress / item) * 100,
              )}%)`}
            </div> */}
            {THUserStore?.achievement?.[achievement?.type]?.[level]
              ?.clearedAt ? (
              <THImageText
                fontSize={3}
                style={{
                  color: '#8E8D9B',
                }}
                text={`${moment(
                  THUserStore?.achievement?.[achievement?.type]?.[level]
                    ?.clearedAt,
                )?.format('YYYY.MM.DD')} 달성!`}
              />
            ) : (
              <THImageText
                fontSize={3}
                style={{
                  color: '#595864',
                }}
                text={`달성시<${achievement?.reward?.[level]?.rewardType}> ${achievement?.reward?.[level]?.rewardAmount}개`}
              />
            )}
          </div>
        )
      })}
    </div>
  )
})

export default THAchievementItem
