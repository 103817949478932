import { observer } from 'mobx-react-lite'
import React from 'react'

const describeMessage = (isReceived, isPurchasableStatus) => {
  if (isReceived) {
    return (
      <>
        <div
          style={{
            marginBottom: 6,
            fontSize: 16,
          }}
        >
          내가 공유한 링크로 들어온 새친구만
        </div>
        <div
          style={{
            marginBottom: 16,
            fontSize: 16,
          }}
        >
          0원 감귤을 받을 수 있어요!
        </div>
      </>
    )
  }
  if (!isReceived && !isPurchasableStatus) {
    return (
      <>
        <div
          style={{
            marginBottom: 6,
            fontSize: 16,
          }}
        >
          새친구에게 링크를 공유한 이후에
        </div>
        <div
          style={{
            marginBottom: 16,
            fontSize: 16,
          }}
        >
          즉시 감귤을 받을 수 있어요!
        </div>
      </>
    )
  }
  return (
    <>
      <div
        style={{
          marginBottom: 16,
          fontSize: 16,
        }}
      >
        지금 바로 0원 감귤을 받아보세요!
      </div>
    </>
  )
}

const MandarinShareNudgeModal = observer(
  ({
    setShowMandarinShareNudgeModal,
    isReceived,
    isPurchasableStatus,
    onClick = () => {},
  }) => (
    <>
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 100,
          backgroundColor: 'rgba(0,0,0,0.8)',
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: 24,
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              zIndex: 2,
              flex: 1,
              top: '24%',
              textAlign: 'center',
              paddingTop: 24,
              paddingBottom: 18,
              borderRadius: 18,
            }}
          >
            <div
              style={{
                marginBottom: 12,
                fontSize: 18,
                fontWeight: '600',
              }}
            >
              {isPurchasableStatus
                ? '친구에게 공유하기를 완료했어요!'
                : '0원 감귤을 친구에게도 공유해보세요!'}
            </div>
            {describeMessage(isReceived, isPurchasableStatus)}
            {/* <div
                style={{
                  marginBottom: 12,
                  fontSize: 36,
                  fontWeight: '600',
                }}
              >
                🥳
              </div> */}
            {/* {isReceived ? (
                <>
                  <div
                    style={{
                      marginBottom: 6,
                      fontSize: 16,
                    }}
                  >
                    내가 공유한 링크로 들어온 새친구만
                  </div>
                  <div
                    style={{
                      marginBottom: 16,
                      fontSize: 16,
                    }}
                  >
                    0원 감귤을 받을 수 있어요!
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      marginBottom: 6,
                      fontSize: 16,
                    }}
                  >
                    새친구에게 링크를 공유한 이후에
                  </div>
                  <div
                    style={{
                      marginBottom: 16,
                      fontSize: 16,
                    }}
                  >
                    즉시 감귤을 받을 수 있어요!
                  </div>
                </>
              )} */}

            {/* <div
              style={{
                marginBottom: 12,
                fontSize: 36,
                fontWeight: '600',
                color: '#ff3e3e',
              }}
            >
              + 47,500원
            </div> */}
            <div
              style={{
                // marginBottom: 12,
                // fontSize: 36,
                fontWeight: '600',
                // backgroundColor: 'red',
                // color: '#ff3e3e',
                // height: '50vw',
              }}
            >
              <img
                style={{ width: '50%' }}
                src='https://assets.ilevit.com/45c8e408-aae6-41ea-aee9-0a45663c2140_original.jpg'
              />
            </div>
            <button
              style={{
                bottom: '0',
                right: '0',
                fontSize: 18,
                borderRadius: 30,
                height: 48,
                borderWidth: 0,
                background: '#F09637',
                width: '90%',
                margin: 4,
              }}
              onClick={() => {
                onClick()
              }}
            >
              <div
                style={{
                  color: 'white',
                  fontSize: 18,
                  fontWeight: 'bold',
                }}
              >
                {!isReceived && isPurchasableStatus
                  ? '0원 감귤 받으러 가기'
                  : '친구에게 공유하기'}
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  ),
)
export default MandarinShareNudgeModal
