import { Button, Card, Col, Input, Pagination, Row } from 'antd'
import { useEffect, useState } from 'react'

import AlfluencerCMSStore from './AlfluencerCMSStore'
import AlfluencerConfigs from './config'

const SectionTitle = () => {
  return (
    <div className='text-2xl mb-8'>
      <span>광고하고 싶은 상품을 선택해주세요!</span>
    </div>
  )
}

const ItemList = () => {
  const [itemData, setItemData] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState('0')
  const [filteredData, setFilteredData] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(15)

  const itemIds = AlfluencerConfigs.target1000ItemIdList

  useEffect(() => {
    const fetchData = async () => {
      const result = await AlfluencerCMSStore.loadTargetItemsInfo(itemIds)
      if (result?.length > 0) {
        setItemData(result)
        setFilteredData(result)
      }
    }
    fetchData()
  }, [])

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId)
    setCurrentPage(1)

    if (categoryId === '0') {
      setFilteredData(itemData)
    } else {
      const filtered = itemData.filter(
        (item) =>
          (categoryId === '0' || item?.categoryInfo?.largeCategoryId === Number(categoryId)) &&
        item?.itemTitle?.includes(searchQuery)
      )
      setFilteredData(filtered)
    }
  }

  const handleSearch = (e) => {
    const query = e.target.value
    setSearchQuery(query)
    setCurrentPage(1)

    const filtered = itemData.filter(
      (item) =>
        (selectedCategory === '0' || item?.categoryInfo?.largeCategoryId === Number(selectedCategory)) &&
        item?.itemTitle.includes(query)
    )
    setFilteredData(filtered)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage)

  const categoryMap = {
    0: 'All',
    1: '뷰티',
    2: '출산/유아',
    3: '식품',
    4: '주방',
    5: '생활',
    7: '반려/애완',
  }

  return (
    <div className='flex flex-col items-center'>
      <div className='mb-4 ml-4 flex'>
        {Object.keys(categoryMap).map((key) => (
          <Button
            key={key}
            className='mx-1'
            type={selectedCategory === key ? 'primary' : 'default'}
            onClick={() => handleCategoryChange(key)}
          >
            {categoryMap[key]}
          </Button>
        ))}
        <Input
          placeholder="검색"
          value={searchQuery}
          onChange={handleSearch}
          style={{ width: '25vw', marginLeft: 16 }}
        />
      </div>
      <div className='flex mx-auto items-center w-[97%] h-full'>
        <Row gutter={[16, 16]} className=''>
          {paginatedData &&
            paginatedData?.map((item, idx) => (
              <Col span={8} key={item?.itemTitle} className='h-full'>
                <Card
                  title={
                    <div className='flex mt-2 justify-center items-center w-full h-[12vh] whitespace-normal break-words'>
                      {item?.itemTitle}
                    </div>
                  }
                >
                  <img
                    alt='example'
                    style={{
                      display: 'flex',
                      width: '100%',
                      marginBottom: 0,
                    }}
                    src={item?.mainImageUris?.[0]}
                  />
                  <Row>
                    <div className='mt-2'>
                      대표 가격 : {item?.teamPurchasePrice}
                    </div>
                  </Row>
                  <Row>
                    <div className='mt-2'>
                      평점 :{' '}
                      {(
                        item?.totalRatingScore / item?.totalRatingCount
                      ).toFixed(2)}
                    </div>
                  </Row>
                  <Row>
                    <div className='mt-2'>
                      리뷰 수 : {item?.totalRatingCount}
                    </div>
                  </Row>
                  <Row>
                    <div>
                      카테고리 정보 : {item?.categoryInfo?.largeCategoryId}
                    </div>
                  </Row>
                </Card>
              </Col>
            ))}
        </Row>
      </div>
      <Pagination
        current={currentPage}
        pageSize={itemsPerPage}
        total={filteredData.length}
        onChange={handlePageChange}
        className='mt-4'
      />
    </div>
  )
}

const AlfluencerCMS = () => {
  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='w-full h-12 font-bold text-3xl mt-8 mb-8 text-center'>
        <h1>올웨이즈 파트너 인플루언서</h1>
      </div>
      <SectionTitle />
      <ItemList />
    </div>
  )
}

export default AlfluencerCMS
