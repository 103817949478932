import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import WatermelonAction from '../../actions/WatermelonAction'
import AuthStore from '../../stores/AuthStore'
import UserStore from '../../stores/UserStore'
import WatermelonUserStore from '../../stores/WatermelonUserStore'
import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import backendApis from '../../utils/backendApis'
import utility from '../../utils/utility'
import WatermelonDailyMission from './molecules/WatermelonDailyMission'

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

function attendanceTimeTextConverter(time) {
  if (time === 'Morning' || time === 'PreMorning' || time === 'Dawn') {
    return '오전'
  }
  if (time === 'Dinner' || time === 'PreDinner') {
    return '오후'
  }
}

function getNextTimeText(time) {
  if (time === 'Morning' || time === 'PreDinner') {
    return '오후'
  }
  if (time === 'Dinner' || time === 'PreMorning' || time === 'Dawn') {
    return '오전'
  }
}

const WatermelonBackpack = observer(() => {
  const [attendanceTimeText, setAttendanceTimeText] = useState('Dawn')
  const [attendanceMissionFinishText, setAttendanceMissionFinishText] =
    useState('오전 7시 가능')
  const [dailyCheckInFinishText, setDailyCheckInFinishText] =
    useState('출석완료')
  const [timeTempAttendance, setTimeTempAttendance] = useState(null)
  const [tempDailyCheckIn, setTempDailyCheckIn] = useState(null)

  useEffect(() => {
    const HHmm = moment().format('HHmm')
    if (HHmm < '0000') {
      setAttendanceTimeText('Dawn')
    } else if (HHmm >= '0000' && HHmm < '1200') {
      setAttendanceTimeText('Morning')
      // } else if (HHmm >= '1100' && HHmm < '1100') {
      //   setAttendanceTimeText('PreDinner')
    } else if (HHmm >= '1200' && HHmm < '2400') {
      setAttendanceTimeText('Dinner')
      // } else if (HHmm >= '2400') {
      //   setAttendanceTimeText('PreMorning')
    } else {
      setAttendanceTimeText('')
    }
  }, [])

  useEffect(() => {
    if (['Dinner', 'PreMorning', 'Dawn']?.includes(attendanceTimeText)) {
      setAttendanceMissionFinishText('내일 오전 가능')
    } else {
      setAttendanceMissionFinishText('오후 가능')
    }
    if (WatermelonUserStore?.userData?.ticket >= 3) {
      setAttendanceMissionFinishText('도전권 가득참')
    }
  }, [attendanceTimeText, WatermelonUserStore?.userData?.ticket])

  useEffect(() => {
    if (WatermelonUserStore?.userData?.ticket >= 3) {
      setTimeTempAttendance({
        ...WatermelonUserStore?.dailyMission?.[
          `attendance${attendanceTimeText}`
        ],
        status: 'finish',
      })
      setTempDailyCheckIn({
        ...WatermelonUserStore?.dailyMission?.dailyCheckIn,
        status: 'finish',
      })
      setDailyCheckInFinishText('도전권 가득참')
    } else if (WatermelonUserStore?.userData?.ticket < 3) {
      setTimeTempAttendance({
        ...WatermelonUserStore?.dailyMission?.[
          `attendance${attendanceTimeText}`
        ],
      })
      setTempDailyCheckIn({
        ...WatermelonUserStore?.dailyMission?.dailyCheckIn,
      })
      setDailyCheckInFinishText('출석완료')
    }
  }, [
    WatermelonUserStore?.userData?.ticket,
    WatermelonUserStore?.dailyMission?.dailyCheckIn?.status,
    WatermelonUserStore?.dailyMission?.[`attendance${attendanceTimeText}`]
      ?.status,
    WatermelonUserStore?.dailyMission?.[`attendance${attendanceTimeText}`],
    WatermelonUserStore?.dailyMission?.dailyCheckIn,
  ])

  useEffect(() => {
    const handleRewardedAdMission = async () => {
      if (UserStore.isWatchingAd === true) {
        // 1초 대기(앱러빈 시청완료 api 오는데 살짝 시간이 걸릴수 있어서)
        await sleep(1000)
        const result = await backendApis.getWatermelonGameData({
          routeParams: {},
        })
        if (result?.status === 200) {
          WatermelonUserStore.set('dailyMission', result?.data?.dailyMission)
          WatermelonUserStore.set('userData', result?.data)
          if (result?.data?.initModalList) {
            for (const init of result?.data?.initModalList) {
              THModalStore.setType({
                type: init?.type,
                config: { ...init?.config, forceOpen: true },
              })
            }
            backendApis.commonSet({
              set: {
                initModalList: [],
              },
              collection: 'watermelon_user',
            })
          }
          UserStore.setIsWatchingAd(false)
        }
      }
    }
    handleRewardedAdMission()
  }, [UserStore.isWatchingAd])

  return (
    <div className='default relative justify-center items-center w-full pb-4 text-[#313138] p-4 max-h-[60vh] overflow-y-scroll'>
      <div className='flex flex-col items-center justify-start w-full py-2 overflow-x-scroll'>
        <WatermelonDailyMission
          mission={{
            rewardType: 'energy',
            rewardAmount: 1,
            rewardFixed: '확정',
            finishText: dailyCheckInFinishText,
            status: 'reward',
            type: 'attendance',
            actionText: '보상 받기',
            title: '출석체크',
            rewardHandler: () => {
              WatermelonAction.giveWatermelonMissionReward({
                type: `dailyCheckIn`,
              })
            },
            ...tempDailyCheckIn,
          }}
        />

        {/* <WatermelonDailyMission
          mission={{
            rewardType: 'energy',
            rewardAmount: 1,
            rewardFixed: '확정',
            finishText: attendanceMissionFinishText,
            status: ['Morning', 'Dinner']?.includes(attendanceTimeText)
              ? 'reward'
              : 'finish',
            type: 'attendance',
            actionText: '보상 받기',
            title: `${
              WatermelonUserStore?.dailyMission?.[
                `attendance${attendanceTimeText}`
              ]?.status === 'finish'
                ? `${getNextTimeText(attendanceTimeText)} 도전권 받기`
                : `${attendanceTimeTextConverter(
                    attendanceTimeText,
                  )} 도전권 받기`
            }`,
            rewardHandler: () => {
              WatermelonAction.giveWatermelonMissionReward({
                type: `attendance${attendanceTimeText}`,
              })
            },
            ...timeTempAttendance,
          }}
        /> */}

        <WatermelonDailyMission
          mission={{
            rewardType: 'energy',
            rewardAmount: 1,
            rewardFixed: '확정',
            status: WatermelonUserStore?.showKakaoShare ? 'action' : 'finish',
            type: 'linkShare',
            actionText: '공유하기',
            title: '공유하기',
            actionHandler: () => {
              backendApis.commonInsert({
                data: {
                  type: 'clickShareButton',
                },
                collection: 'watermelon_clickLog',
              })
              WatermelonAction.kakaoLinkShare({
                shareType: 'Watermelon_LinkShare',
                title: '[양파게임] 무료 양파 1Kg에 도전하세요!',
                description: '작물을 합쳐서 양파를 만들어내면 양파 1Kg가 무료!',
                imageUrl:
                  'https://assets.ilevit.com/e9741bf3-54c4-4fef-aa5c-3c80292086e1.png',
                payload: {},
                templateId: 100565,
              })
            },
          }}
        />

        <WatermelonDailyMission
          mission={{
            rewardType: 'energy',
            rewardAmount: 3,
            status: WatermelonUserStore.showInviteShare ? 'action' : 'finish',
            type: 'invite',
            actionText: '초대하기',
            title: '새친구 초대하기',
            rewardFixed: true,
            actionHandler: () => {
              backendApis.commonInsert({
                data: {
                  type: 'clickInviteButton',
                },
                collection: 'watermelon_clickLog',
              })
              THModalStore.setType({
                type: 'watermelonRule',
                config: {
                  title: '새친구 초대',
                  forceOpen: true,
                  src: 'inviteModal',
                  onClick: () => {
                    TreasureHuntAction.linkShare({
                      shareType: 'Watermelon_Invite',
                      title: '[양파게임] 무료 양파 1Kg에 도전하세요!',
                      description:
                        '작물을 합쳐서 양파를 만들어내면 양파 1Kg가 무료!',
                      imageUrl:
                        'https://assets.ilevit.com/e9741bf3-54c4-4fef-aa5c-3c80292086e1.png',
                      payload: {},
                    })
                  },
                  text: '초대하기',
                },
              })
            },
          }}
        />
        {utility.codepushVersionCheck(AuthStore.codePushVersion, '5.0.24') && (
          <WatermelonDailyMission
            mission={{
              rewardType: 'energy',
              rewardAmount: 1,
              status: (() => {
                if (
                  WatermelonUserStore?.dailyMission?.rewardedAd?.status ===
                  'finish'
                ) {
                  return 'finish'
                }
                if (UserStore.isRewardAdReady) {
                  return 'action'
                }
                return 'loading'
              })(),
              type: 'rewardedAd',
              // This is fine because inside the componet,
              // The internal button text state will be changed to action text
              actionText: '광고 보기',
              finishText:
                WatermelonUserStore?.dailyMission?.rewardedAd?.status ===
                'finish'
                  ? '내일 가능'
                  : '광고 준비중',
              title: '광고 보고 도전권 받기',
              rewardFixed: true,
              actionHandler: () => {
                THModalStore?.setType({
                  type: 'confirm',
                  config: {
                    forceOpen: true,
                    title: '광고 보기 미션',
                    src: null,
                    description: `광고를 보고 상단에 나타나는\nX버튼을 눌러주세요\n소리가 나올 수 있어요`,
                    buttonText: '광고 보고 도전권 받기',
                    onConfirm: () => {
                      THModalStore.close()
                      WatermelonAction.postMessage({
                        type: 'showRewardedAd',
                        data: {
                          adType: 'rewarded',
                          placement: 'WATERMELON_MISSION_REWARD',
                          product: 'WATERMELON',
                        },
                      })
                    },
                    clickable: true,
                  },
                })
              },
            }}
          />
        )}
        {/* {WatermelonUserStore.showContactInvite && (
          <WatermelonDailyMission
            mission={{
              rewardType: 'energy',
              rewardAmount: 5,
              status: WatermelonUserStore.showInviteShare ? 'action' : 'finish',
              type: 'invite',
              actionText: '초대하기',
              title: '연락처 초대하기',
              rewardFixed: true,
              actionHandler: () => {
                THBottomSheetStore.setType({
                  type: 'watermelonContacts',
                })
              },
            }}
          />
        )} */}
        {utility.codepushVersionCheck(AuthStore.codePushVersion, '4.3.58') &&
          !WatermelonUserStore?.userData?.isAlfarmUser && (
            <WatermelonDailyMission
              mission={{
                rewardType: 'energy',
                rewardAmount: 2,
                status: 'action',
                type: 'alfarm',
                actionText: '올팜하기',
                title: '올팜 레벨업 2번하기',
                rewardFixed: true,
                actionHandler: () => {
                  THModalStore.setType({
                    type: 'watermelonRule',
                    config: {
                      title: '올팜 레벨업 미션',
                      forceOpen: true,
                      src: 'alfarmModal',
                      onClick: () => {
                        window.location.href = `#navigate.${JSON.stringify({
                          screen: 'AlfarmMain',
                          payload: {
                            itemType:
                              WatermelonUserStore?.userData?.alfarmItemType,
                            isAlreadyExist:
                              WatermelonUserStore?.userData
                                ?.alfarmIsAlreadyExists,
                          },
                        })}`
                        setTimeout(() => {
                          window.location.href = ''
                        }, 1000)
                      },
                      text: '올팜하기',
                    },
                  })
                },
              }}
            />
          )}

        {/* {codepushVersionCheck(AuthStore.codePushVersion, '4.3.58') &&
          !WatermelonUserStore?.userData?.missionClearLog
            ?.gganbuMissionClearedAt &&
          WatermelonUserStore?.userData?.missionClearLog
            ?.isGganbuMissionAvailable && (
            <WatermelonDailyMission
              mission={{
                rewardType: 'fixedTicket',
                rewardAmount: 3,
                status:
                  WatermelonUserStore?.userData?.hasMoreThanThreeGganbu === true
                    ? 'reward'
                    : 'action',
                type: 'alfarmGganbu',
                actionText: '올팜하기',
                title: '올팜 맞팜친구 3명 맺기',
                rewardFixed: true,
                actionHandler: () => {
                  THModalStore.setType({
                    type: 'watermelonRule',
                    config: {
                      title: '올팜 맞팜친구 3명 맺기',
                      forceOpen: true,
                      src: 'alfarmGganbuModal',
                      onClick: () => {
                        window.location.href = `#navigate.${JSON.stringify({
                          screen: 'AlfarmMain',
                          payload: {
                            itemType:
                              WatermelonUserStore?.userData?.alfarmItemType,
                            isAlreadyExist:
                              WatermelonUserStore?.userData
                                ?.alfarmIsAlreadyExists,
                          },
                        })}`
                        setTimeout(() => {
                          window.location.href = ''
                        }, 1000)
                      },
                      text: '올팜하기',
                    },
                  })
                },
                rewardHandler: () => {
                  WatermelonAction.giveWatermelonMissionReward({
                    type: 'gganbuMission',
                  })
                },
                ...WatermelonUserStore?.dailyMission?.gganbuMission,
              }}
            />
          )} */}

        {!WatermelonUserStore?.userData?.isPayedUser && (
          <WatermelonDailyMission
            mission={{
              rewardType: 'energy',
              rewardAmount: 3,
              status: 'action',
              type: 'purchaseMission',
              actionText: '구매하기',
              title: '올웨이즈 첫 구매',
              rewardFixed: true,
              actionHandler: () => {
                const payload = {
                  enteringComponent: 'Watermelon_Purchase',
                  text: '양파게임',
                  source:
                    'https://assets.ilevit.com/3b839fc0-72b6-4a81-a35d-1176e3bfd06a.png',
                  rewardText: `상품을 구매하고 도전권 3개를 받아보세요`,
                }

                TreasureHuntAction.navigateRN({
                  screen: 'TreasureHuntMissionPurchaseScreen',
                  payload,
                })
                THBottomSheetStore.close()
              },
              ...WatermelonUserStore?.dailyMission?.purchaseMission,
            }}
          />
        )}

        {WatermelonUserStore?.userData?.isPayedUser &&
          WatermelonUserStore?.userData?.isShareEnoughUser && (
            <WatermelonDailyMission
              mission={{
                rewardType: 'energy',
                rewardAmount: 3,
                status: 'action',
                type: 'purchaseMission',
                actionText: '구매하기',
                title: '상품 구매하기 (1일 1회)',
                rewardFixed: true,
                actionHandler: () => {
                  const payload = {
                    enteringComponent: 'Watermelon_Purchase',
                    text: '양파게임',
                    source:
                      'https://assets.ilevit.com/3b839fc0-72b6-4a81-a35d-1176e3bfd06a.png',
                    rewardText: `상품을 구매하고 도전권 3개를 받아보세요`,
                  }

                  TreasureHuntAction.navigateRN({
                    screen: 'TreasureHuntMissionPurchaseScreen',
                    payload,
                  })
                  THBottomSheetStore.close()
                },
                ...WatermelonUserStore?.dailyMission?.purchaseMission,
              }}
            />
          )}
      </div>

      {/* <div className='text-[#313138] font-bold text-left w-full text-[5vw] my-2'>
        챌린지 미션
      </div>
      <div className='border-2 h-[50vh] w-full '>
        <div />
      </div> */}
    </div>
  )
})

export default WatermelonBackpack
