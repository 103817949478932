// components/toon/ItemConnectingSection.js
import commaNumber from 'comma-number'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import backendApis from '../../utils/backendApis'

const ItemConnectingSection = ({ connectingItemId }) => {
  const [connectingItemInfo, setConnectingItemInfo] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const connectingItemInfo = await backendApis.loadConnectingItemInfo(
        connectingItemId,
      )
      if (connectingItemInfo?.status === 200) {
        setConnectingItemInfo(connectingItemInfo?.data)
      } else {
        setConnectingItemInfo(null)
      }
    }
    fetchData()
  }, [])

  const navigate = useNavigate()
  if (connectingItemInfo) {
    return (
      <button
        type='button'
        className='my-[6vw] mx-[6vw] w-[88vw] pb-[2vw] flex flex-col items-start text-[3.6vw] border rounded-lg'
        onClick={() => {
          navigate(-1)
          window.location.href = `#navigate.${JSON.stringify({
            screen: 'MainStackDItemScreen',
            prop: {
              itemId: connectingItemInfo?._id,
              itemInfo: connectingItemInfo,
              enteringComponent: 'Altoon_connectedItem',
              index: connectingItemInfo?.itemIndex,
              isFrom: 'Altoon_connectedItem',
            },
          })}`
        }}
      >
        <div className='px-4 pt-2 font-bold text-gray-400'>
          작품과 관련된 상품이에요
        </div>
        <div className='px-[4vw] flexRow justify-center py-2 h-full'>
          <img
            alt=''
            className='mr-4 w-[24vw] h-[24vw] rounded-lg border border-gray-100'
            src={connectingItemInfo?.mainImageUris[0]}
          />
          <div className='flexCol py-2 w-full items-start'>
            <div className='text-start line-clamp-2 leading-4'>
              {connectingItemInfo?.itemTitle}
            </div>
            <div className='flexCol items-start pt-2'>
              <div className='flexRow font-bold text-red-500'>
                {Math.round(connectingItemInfo?.discountRate * 100)}%
                <div className='ml-1 line-through font-normal text-gray-600'>
                  {connectingItemInfo?.individualPurchasePrice}원
                </div>
              </div>
              <div className='font-bold text-black text-[4vw]'>
                {commaNumber(connectingItemInfo?.teamPurchasePrice)}원
              </div>
            </div>
          </div>
        </div>
      </button>
    )
  }
}

export default ItemConnectingSection
