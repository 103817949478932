import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import Iframe from 'react-iframe'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import WatermelonAction from '../../actions/WatermelonAction'
import TreasureHuntButton from '../../components/treasureHunt/TreasureHuntButton'
import TreasureHuntTimer from '../../components/treasureHunt/TreasureHuntTimer'
import HeaderComponent from '../../components/treasureHunt/molecules/HeaderComponent'
import AuthStore from '../../stores/AuthStore'
import ToastStore from '../../stores/ToastStore'
import WatermelonUserStore from '../../stores/WatermelonUserStore'
import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import backendApis from '../../utils/backendApis'

let visibilityChange
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  visibilityChange = 'webkitvisibilitychange'
}

const mode = {
  type: 'arcade',
  backgroundImage: `url(/watermelonAssets/background/arcade_timeAttack_bg.png)`,
  backgroundColor: '#FFCCC9',
  map: 'map_time_attack',
  isFirstGame: false,
}

const WatermelonArcade = observer(() => {
  // const navigate = useNavigate()
  const childRef = useRef()

  const [status, setStatus] = useState('lobby')
  const [attendanceTimeText, setAttendanceTimeText] = useState('Dawn')
  const token = useSearchParam('token')
  const [missionPingOn, setMissionPingOn] = useState(false)

  const GAMEMODE_TIME_ATTACK = 'timeAttack'
  const GAMEMODE_TIME_LIMIT = 'timeLimit'
  const GAMEMODE_REVERSE = 'reverse'

  const gameModeConfig = {
    reverse: {
      map: 'map_onion',
      mode: GAMEMODE_REVERSE,
      isReverse: true,
      isTimerGame: false,
    },
    timeAttack: {
      map: 'map_time_attack',
      mode: GAMEMODE_TIME_ATTACK,
      isTimerGame: true,
      timerGameSeconds: 80, // 1분 20초
    },
    timeLimit: {
      map: 'map_onion',
      mode: GAMEMODE_TIME_LIMIT,
      isTimerGame: true,
      timerGameSeconds: 600, // 10분
    },
  }

  const modeToKor = (mode) => {
    if (mode === GAMEMODE_TIME_ATTACK) return '양파 10개'
    if (mode === GAMEMODE_TIME_LIMIT) return '10분 제한'
    if (mode === GAMEMODE_REVERSE) return '거꾸로'
  }

  // 아케이드 게임 모드 세팅
  const [modeIndex, setGameMode] = useState(0)
  const modeList = [GAMEMODE_TIME_ATTACK, GAMEMODE_TIME_LIMIT, GAMEMODE_REVERSE]
  let gameModeSettings = gameModeConfig[modeList[modeIndex]]

  const onPressGameMode = (index) => {
    setGameMode(index)
    gameModeSettings = gameModeConfig[modeList[index]]
  }

  useEffect(() => {
    const HHmm = moment().format('HHmm')
    if (HHmm >= '0000' && HHmm < '1200') {
      setAttendanceTimeText('Morning')
    } else if (HHmm >= '1200' && HHmm < '2400') {
      setAttendanceTimeText('Dinner')
    } else {
      setAttendanceTimeText('')
    }

    const arcadeMissionPingAt = window.localStorage.getItem(
      'arcadeMissionPingAt',
    )
    if (
      WatermelonUserStore?.arcadeDailyMission?.[
        `arcadeAttendance${attendanceTimeText}`
      ]?.status !== 'finish' &&
      ['Morning', 'Dinner']?.includes(attendanceTimeText)
    ) {
      setMissionPingOn(true)
    } else if (
      moment().format('YYYY-MM-DD') ===
      moment(arcadeMissionPingAt).format('YYYY-MM-DD')
    ) {
      setMissionPingOn(false)
    } else {
      setMissionPingOn(true)
      window.localStorage.setItem(
        'arcadeMissionPingAt',
        moment().format('YYYY-MM-DD'),
      )
    }
  }, [WatermelonUserStore?.arcadeDailyMission])

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && token) {
      WatermelonAction.initGame({ data: { token, codepushVersion: '4.3.31' } })
    }
  }, [token])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        if (document.visibilityState === 'visible') {
          setTimeout(() => {
            if (!WatermelonUserStore.isPlaying) {
              window.location.reload()
            }
          }, 500)
        }
      },
      false,
    )
  }, [])

  useEffect(() => {
    if (status === 'playing') {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'allowiPhoneGesture',
          boolean: false,
        }),
      )
    } else {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'allowiPhoneGesture',
          boolean: true,
        }),
      )
    }
  }, [status])

  useEffect(() => {
    if (!WatermelonUserStore?.isLoading) {
      const handleMessage = async (e) => {
        // e.data가 전달받은 메시지
        if (e.data?.status === 'gameOver') {
          WatermelonUserStore.set('isFixedGame', false)
          WatermelonUserStore.set('hideCloseButton', false)
          setStatus('gameOver')
          WatermelonUserStore.set('isPlaying', false)

          if (WatermelonUserStore.gameMode === GAMEMODE_TIME_ATTACK) {
            if (WatermelonUserStore?.onionCount > 9) {
              // 클리어 기록 남기기
              await backendApis.watermelonReachedFinal({
                type: mode.type,
                gameMode: GAMEMODE_TIME_ATTACK,
              })

              THModalStore.setType({
                type: 'reward',
                config: {
                  rewardName: `도전 성공!`,
                  src: `../watermelonAssets/smile.png`,
                  description: `양파를 ${
                    WatermelonUserStore?.onionCount || 0
                  }개 만들었어요!`,
                  forceOpen: true,
                },
              })
            } else {
              THModalStore.setType({
                type: 'reward',
                config: {
                  rewardName: `아까워요`,
                  src: `../watermelonAssets/sad.png`,
                  description: `아까워요, 양파를 ${
                    WatermelonUserStore?.onionCount || 0
                  }개 만들었어요!`,
                  forceOpen: true,
                  onClick: () => {
                    WatermelonUserStore.set('onionCount', 0)
                  },
                },
              })
            }
            if (WatermelonUserStore?.userData?.arcadeTicket < 1) {
              THModalStore.setType({
                type: 'reward',
                config: {
                  rewardName: `공유하기`,
                  src: `../watermelonAssets/smile.png`,
                  description:
                    '친구에게 양파게임을 알려주고\n티켓을 받아보세요!',
                  text: '공유하기',
                  onClick: () => {
                    backendApis.commonInsert({
                      data: {
                        type: 'clickShareButton',
                        gameType: WatermelonUserStore?.gameType,
                      },
                      collection: 'watermelon_clickLog',
                    })

                    WatermelonAction.kakaoLinkShare({
                      shareType: 'Watermelon_ArcadeLinkShare',
                      title: '[양파게임] 아케이드 모드 출시! 훨씬 쉬워졌어요',
                      description: '양파를 10번 만들어 보세요!',
                      imageUrl:
                        'https://assets.ilevit.com/a1ec211f-5e7e-4bfd-8da6-5912a93491f1.png',
                      payload: {},
                      templateId: 101363,
                    })
                  },
                },
              })
            }
          }

          // 게임 방식 변경(코인 획득) 공지
          if (!window.localStorage.getItem('didOpenWatermelonCoinNotice')) {
            THModalStore.setType({
              type: 'singlePageNotice',
              config: {
                title: `코인이 생겼어요!`,
                src: `../watermelonAssets/shop/coinNotice.png`,
                forceOpen: true,
              },
            })
            window.localStorage.setItem('didOpenWatermelonCoinNotice', 'true')
          }
        } else if (e.data?.status === 'gameInitSuccess') {
          childRef.current = document.getElementById('child') // iframe
          if (childRef.current) {
            childRef.current.contentWindow.postMessage(
              {
                msg: 'startClicked',
                mode: mode.type,
                gameMode: WatermelonUserStore?.gameMode,
                backgroundColor: mode.backgroundColor,
                isFirstGame: mode.isFirstGame,
                isFixedGame: WatermelonUserStore?.isFixedGame,
                isBonuseNewbie: WatermelonUserStore?.userData?.isBonusNewbie,
              },
              '*',
            )
          }
        } else if (e.data?.status === 'hideCloseButton') {
          WatermelonUserStore.set('hideCloseButton', true)
        } else if (e.data?.status === 'onionCount') {
          WatermelonUserStore.set('onionCount', Number(e?.data?.onionCount))
          if (WatermelonUserStore?.onionCount > 9) {
            ToastStore.toastOn({
              type: 'emoji',
              message: `양파 10개 만들기 성공! 🔥`,
              duration: 2000,
              emoji: '🎉',
            })
          }
        }
      }
      window.addEventListener('message', handleMessage)

      return () => {
        window.removeEventListener('message', handleMessage)
      }
    }
  }, [WatermelonUserStore?.isLoading])

  const startGame = async () => {
    WatermelonUserStore.set('isNewbie', false)
    WatermelonUserStore.set('isFixedGame', false)
    const result = await backendApis.watermelonStartGame({
      type: mode.type,
      gameMode: WatermelonUserStore?.gameMode,
    })
    if (process.env.NODE_ENV === 'development') {
      console.log('result data: ', result)
      console.log('gameMode: ', WatermelonUserStore.gameMode)
      console.log('gameType: ', WatermelonUserStore.gameType)
      console.log('currentScore: ', WatermelonUserStore.currentScore)
      console.log('isFixedGame: ', WatermelonUserStore.isFixedGame)
      console.log('isTeamGame: ', WatermelonUserStore.isTeamGame)
      console.log('isTimerGame: ', WatermelonUserStore.isTimerGame)
      console.log('timerGameSeconds: ', WatermelonUserStore.timerGameSeconds)
      console.log('onionCount: ', WatermelonUserStore.onionCount)
      console.log('isFixedGame: ', WatermelonUserStore.isFixedGame)
    }
    if (result?.status === 200) {
      setStatus('playing')
      WatermelonUserStore.set('isPlaying', true)
      WatermelonUserStore.increase('userData.arcadeTicket', -1)
    } else if (result?.status === 201) {
      ToastStore.toastOn({
        type: 'emoji',
        message: `아케이드 티켓이 없어요. \n미션을 하고 받아보세요!`,
        duration: 2000,
        emoji: '🎟️',
      })
      THBottomSheetStore.setType({
        type: 'watermelonArcadeTicket',
      })
    } else {
      ToastStore.networkError()
    }
  }

  if (WatermelonUserStore?.isLoading) {
    return (
      <div className='flex flex-col loadingPage jua'>
        <div className='slowRotateCenter pigNose'>🧅</div>
        <div>로딩 중...</div>
      </div>
    )
  }

  return (
    <div
      id='watermelon'
      className='fixed'
      style={{
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(/watermelonAssets/background/arcade_bg.png)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        fontFamily: 'maplestory',
        backgroundColor: mode.backgroundColor,
      }}
    >
      {(status === 'lobby' || status === 'gameOver') && (
        <HeaderComponent
          title=''
          hideButtons
          backButtonOnClick={() => {
            window.location.href = `/watermelon?token=${AuthStore?.token}`
          }}
          watermelonTicketOn
          watermelonArcade
        />
      )}
      {status !== 'playing' && (
        <div className='absolute bottom-0 flex flex-col w-full p-4 space-y-4'>
          <img
            className='w-full h-auto'
            alt={mode}
            src={`/watermelonAssets/background/arcade_${modeList[modeIndex]}_desc.png`}
          />
          <div className='flex rounded-2xl relative justify-center h-[20vw]'>
            <div className='flex flex-row items-center justify-between px-2'>
              <div key={mode} className='grid grid-cols-3'>
                {modeList.map((mode, index) => (
                  <div key={mode}>
                    <button
                      className='items-center justify-center p-8 text-center'
                      onClick={() => onPressGameMode(index)}
                      type='button'
                    >
                      <img
                        className='w-[15vw] h-auto flex justify-center items-center'
                        alt={mode}
                        src={
                          // 선택된 게임 모드 활성화 이미지 on
                          modeIndex === index
                            ? `../watermelonAssets/modeIcon/${modeList[index]}_on.png`
                            : `../watermelonAssets/modeIcon/${modeList[index]}_off.png`
                        }
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <br />
          </div>{' '}
          <div className='flex items-center justify-between w-full bg-white flew-row'>
            {/* <div
              className='relative '
              onClick={() => {
                setMissionPingOn(false)
                THBottomSheetStore.setType({
                  type: 'watermelonArcadeTicket',
                })
              }}
            >
              <img
                className='w-[44vw] h-full'
                src='../watermelonAssets/mainIcon/ticketMission.png'
                alt='mission'
              />
              {missionPingOn && (
                <img
                  className='absolute w-[10vw] h-[10vw] -top-[4vw] -right-[4vw] pointer-events-none'
                  src='../watermelonAssets/ping2.png'
                  alt='ping'
                />
              )}
            </div>
            <div
              className=''
              onClick={() => {
                backendApis.commonInsert({
                  data: {
                    type: 'clickArcadeRankingButton',
                  },
                  collection: 'watermelon_clickLog',
                })
                ToastStore.toastOn({
                  type: 'emoji',
                  message: `아직 준비중이에요`,
                  duration: 2000,
                  emoji: '⏰',
                })
              }}
            >
              <img
                className='w-[44vw] h-full'
                src='../watermelonAssets/mainIcon/arcadeRanking.png'
                alt='ranking'
              />
            </div> */}
          </div>
          <TreasureHuntButton
            style={{
              width: '100%',
              padding: '4vw 4vw',
            }}
            text='게임시작'
            clickable
            onClick={async () => {
              // Apply settings based on game mode
              const currentGameMode = modeList?.[modeIndex]

              WatermelonUserStore.set('gameMode', currentGameMode)
              WatermelonUserStore.set('gameType', 'onion')

              if (process.env.NODE_ENV === 'development') {
                console.log('currentGameMode: ', currentGameMode)
                console.log('gameModeSettings: ', gameModeSettings)
              }

              WatermelonUserStore.set('gameMode', currentGameMode)
              WatermelonUserStore.set('gameType', 'onion')

              if (gameModeSettings) {
                WatermelonUserStore.set(
                  'isTimerGame',
                  gameModeSettings.isTimerGame,
                )
                if (gameModeSettings.timerGameSeconds !== undefined) {
                  WatermelonUserStore.set(
                    'timerGameSeconds',
                    gameModeSettings.timerGameSeconds,
                  )
                }
              }

              startGame()
            }}
          />
          <img
            className='w-[40vw] absolute bottom-[16vw] right-[2vw] pointer-events-none z-30'
            src='../watermelonAssets/arcadeSendingNudge.png'
            alt='sendingNudge'
          />
          <TreasureHuntButton
            style={{
              width: '100%',
              padding: '4vw 4vw',
            }}
            text='공유하고 티켓 2장 받기 (도전권 중복 가능)'
            clickable={WatermelonUserStore?.showKakaoShare}
            onClick={async () => {
              await backendApis.commonInsert({
                data: {
                  type: 'clickShareButton',
                  gameType: mode.type,
                },
                collection: 'watermelon_clickLog',
              })

              WatermelonAction.kakaoLinkShare({
                shareType: 'Watermelon_ArcadeLinkShare',
                title: '[양파게임] 아케이드 모드 출시! 훨씬 쉬워졌어요',
                description: '양파를 10번 만들어 보세요!',
                imageUrl:
                  'https://assets.ilevit.com/a1ec211f-5e7e-4bfd-8da6-5912a93491f1.png',
                payload: {},
                templateId: 101363,
              })
            }}
          />
        </div>
      )}

      {status === 'playing' && (
        <div className='relative'>
          <div className='absolute top-[25vw] left-[4vw] z-[100] pointer-events-none font-bold whitespace-nowrap flex flex-row justify-center items-center text-center text-[#313138]'>
            {`모드: ${modeToKor(modeList?.[modeIndex]) || '아케이드'}`}
          </div>
          {WatermelonUserStore?.isTimerGame && (
            <div className='absolute top-[25vw] right-[4vw] z-[100] pointer-events-none font-bold whitespace-nowrap flex flex-row justify-center items-center text-center text-[#313138]'>
              남은 시간:
              <TreasureHuntTimer
                style={{
                  marginLeft: '8px',
                  color: '#313138',
                }}
                format='number'
                expiryTimestamp={moment()
                  .add(WatermelonUserStore?.timerGameSeconds, 'seconds')
                  .toDate()}
                onExpire={() => {
                  childRef.current = document.getElementById('child') // iframe
                  if (childRef.current) {
                    childRef.current.contentWindow.postMessage(
                      {
                        msg: 'gameOver',
                      },
                      '*',
                    )
                  }
                }}
              />
            </div>
          )}
          {WatermelonUserStore?.gameMode === GAMEMODE_TIME_ATTACK && (
            <div className='absolute top-[32vw] left-[4vw] z-[100] pointer-events-none font-bold whitespace-nowrap flex flex-row justify-center items-center text-center text-[#313138]'>
              만든 양파 수: {WatermelonUserStore?.onionCount || 0}개
            </div>
          )}
          <div className='absolute z-[10] left-[12vw] top-[154vw] px-2 pointer-events-none'>
            <img
              className='w-[70vw] h-full'
              src={`../watermelonAssets/${gameModeSettings.map}.png`}
              alt='map'
            />
          </div>

          {!WatermelonUserStore?.hideCloseButton && (
            <div
              className='absolute right-4 top-4 z-[10]'
              onClick={() => {
                THModalStore?.setType({
                  type: 'confirm',
                  config: {
                    forceOpen: true,
                    title: '게임 종료',
                    src: '../watermelonAssets/smile.png',
                    description: `진행상황이 사라져요\n정말 그만두시겠어요?`,
                    buttonText: '그만하기',
                    onConfirm: async () => {
                      // setStatus('lobby')
                      childRef.current = document.getElementById('child') // iframe
                      if (childRef.current) {
                        childRef.current.contentWindow.postMessage(
                          {
                            msg: 'gameOver',
                          },
                          '*',
                        )
                      }
                      THModalStore.close()
                    },
                    clickable: true,
                  },
                })
              }}
            >
              <img
                className='w-8 h-8'
                src='../watermelonAssets/close.png'
                alt='map'
              />
            </div>
          )}
          <Iframe
            styles={{ border: 0, margin: 0, overflow: 'hidden' }}
            frameBorder={0}
            url='/Watermelon.html'
            width='100%'
            height='100%'
            id='child'
            display='flex'
            position='fixed'
            allowFullScreen
          />
        </div>
      )}
      <div className='z-[-10] absolute -bottom-4 w-full h-[30vw] bg-white' />
    </div>
  )
})

export default WatermelonArcade
