import React from 'react'

import ComponentTitle from '../atoms/ComponentTitle'
import MainToonComponent from './mainToonComponent'

const RecommendRelatedToonsComponent = ({ toonInfo, type }) => {
  const otherToonsByAuthor = toonInfo.sortedSameAuthorToons
  const relatedToons = toonInfo.sortedRelatedToons

  return (
    <div className='pb-[14vw] '>
      {otherToonsByAuthor?.length > 0 && (
        <>
          <ComponentTitle
            text={`${toonInfo?.author || ''} 작가님의 다른 작품`}
            style={{ fontSize: '4.2vw' }}
          />
          <div className='flexRow flex items-center overflow-x-scroll justify-start '>
            {otherToonsByAuthor?.map((toon, index) => (
              <div key={toon?._id} className=''>
                <MainToonComponent
                  toon={toon}
                  index={index}
                  type='otherToonsByAuthor'
                  component='recommendRelated_otherToonsByAuthor'
                />
              </div>
            ))}
          </div>
          <div className='h-[10vw]' />
        </>
      )}

      {relatedToons?.length > 0 && (
        <>
          <div className='px-[2vw] py-[1vw] my-[1vw] rounded text-[2.8vw] text-[#F8323E] bg-[#FFEDEE] inline-block'>
            비슷한 작품 추천
          </div>
          <ComponentTitle
            text={`${toonInfo?.title ?? '이 작품'}만큼\n재밌는 작품 보러가기`}
            style={{ fontSize: '4.2vw' }}
          />
          <div className='grid grid-cols-3 gap-[2vw]'>
            {relatedToons?.slice(0, 6)?.map((toon, index) => (
              <MainToonComponent
                key={toon?._id}
                toon={toon}
                index={index}
                type={type}
                component={`recommendRelated_${type}`}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default RecommendRelatedToonsComponent
