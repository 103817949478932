import { observable, toJS } from 'mobx'
import moment from 'moment'

const WatermelonUserStore = observable({
  DEFAULT_IMAGE:
    'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d9a2da73-105d-4c7d-bf80-9c3191a977f7.png',
  ableToGetPrize: true,
  isLoading: true,
  rankData: [],
  myRank: 99,
  fixedTicket: 0,
  myRankLocal: 99,
  rankLoadDone: false,
  kakaoQuotaLimit: false,
  dynamicQuotaLimit: false,
  hasKakaoLink: false,
  hasInviteLink: false,
  showKakaoShare: true,
  showInviteShare: true,
  showEmergencyShare: false,
  isNewbie: false,
  backgroundColor: '#FFCCC9',
  gameType: 'onion',
  gameMode: 'normal',
  showContactInvite: false,
  isContactInviteLoad: false,
  uninstallUsers: [],
  churnedUsers: [],
  isShareEnoughUser: false,
  isTimerGame: false,
  hideTimer: false,
  hideCloseButton: false,
  exp: 0,
  coin: 0,
  arcadeTicket: 0,

  setMultiple(obj) {
    const arr = Object.entries(obj ?? {})
    for (const [key, value] of arr) {
      this.set(key, value)
    }
  },

  increase(key, value) {
    if (typeof value !== 'number') {
      return
    }

    function increaseProperty(obj, keys, val) {
      let key
      while (keys.length > 1) {
        key = keys.shift()
        if (!Object.prototype.hasOwnProperty.call(obj, key)) obj[key] = {}
        // if (!obj.hasOwnProperty(key)) obj[key] = {}
        obj = obj[key]
      }
      obj[keys[0]] += val
    }

    if (key?.includes('.')) {
      const keys = key.split('.')
      increaseProperty(this, keys, value)
    } else {
      this[key] += value
    }
  },

  set(key, value) {
    function setProperty(obj, keys, val) {
      let key
      while (keys.length > 1) {
        key = keys.shift()
        if (!Object.prototype.hasOwnProperty.call(obj, key)) obj[key] = {}
        // if (!obj.hasOwnProperty(key)) obj[key] = {}
        obj = obj[key]
      }
      obj[keys[0]] = val
    }

    if (key?.includes('.')) {
      const keys = key.split('.')
      setProperty(this, keys, value)
    } else {
      this[key] = value
    }
  },

  push(key, value) {
    this?.[key]?.push(value)
  },
})

export default WatermelonUserStore
