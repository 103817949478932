import { observable } from 'mobx'

const AltoonUserStore = observable({
  DEFAULT_IMAGE:
    'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d9a2da73-105d-4c7d-bf80-9c3191a977f7.png',
  ticket: 0,
  currentToonChapterInfo: [],
  currentToonPrice: 0,

  setMultiple(obj) {
    const arr = Object.entries(obj ?? {})
    for (const [key, value] of arr) {
      this.set(key, value)
    }
  },

  increase(key, value) {
    if (typeof value !== 'number') {
      return
    }

    function increaseProperty(obj, keys, val) {
      let key
      while (keys.length > 1) {
        key = keys.shift()
        if (!obj.hasOwnProperty(key)) obj[key] = {}
        obj = obj[key]
      }
      obj[keys[0]] += val
    }

    if (key?.includes('.')) {
      const keys = key.split('.')
      increaseProperty(this, keys, value)
    } else {
      this[key] += value
    }
  },

  set(key, value) {
    function setProperty(obj, keys, val) {
      let key
      while (keys.length > 1) {
        key = keys.shift()
        if (!obj.hasOwnProperty(key)) obj[key] = {}
        obj = obj[key]
      }
      obj[keys[0]] = val
    }

    if (key?.includes('.')) {
      const keys = key.split('.')
      setProperty(this, keys, value)
    } else {
      this[key] = value
    }
  },

  push(key, value) {
    this?.[key]?.push(value)
  },
})

export default AltoonUserStore
