import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'

const ViewInfo = observer(
  ({ selectedProviderId = '', selectedToonId = '' }) => {
    const [viewData, setViewData] = useState(null)
    const [paidUserData, setPaidUserData] = useState(null)
    const [conversionRateData, setConversionRateData] = useState(null)

    /// 매출 현황 데이터 가져오기
    useEffect(() => {
      const fetchData = async () => {
        const result = await AltoonCMSUserStore.loadDashboardInfo(
          selectedProviderId,
          selectedToonId,
        )
        if (result === true) {
          // 조회 고객(unique) 데이터
          const tempViewData = Object.entries(
            AltoonCMSUserStore.toonViewDataInfoByDate,
          ).map(([date, viewCount]) => {
            return { date, viewCount }
          })
          tempViewData.sort((a, b) => new Date(a.date) - new Date(b.date))
          setViewData(tempViewData)

          // 구매 고객(unique) 데이터
          const tempPaidUserData = Object.entries(
            AltoonCMSUserStore.toonPaidDataInfoByDate,
          ).map(([date, DTU]) => {
            return { date, DTU }
          })
          tempPaidUserData.sort((a, b) => new Date(a.date) - new Date(b.date))
          setPaidUserData(tempPaidUserData)

          // 열람고객 대비 구매고객 비율
          const rateData = tempViewData.map((viewItem) => {
            const paidItem = tempPaidUserData.find(
              (paid) => paid.date === viewItem.date,
            )
            return {
              date: viewItem.date,
              rate: paidItem
                ? ((paidItem.DTU / viewItem.viewCount || 0) * 100).toFixed(2)
                : 0,
            }
          })
          setConversionRateData(rateData)
        }
      }
      fetchData()
    }, [AltoonCMSUserStore.selectedMonth, selectedProviderId, selectedToonId])

    return (
      <div className='flex flex-col flex-1 h-full select-none'>
        <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md rounded-xl'>
          <h1 className='text-xl font-bold whitespace-nowrap'>고객 데이터</h1>
          <div className='flexRow'>
            <div className='flex flex-col flex-1 px-10 py-10 rounded-xl'>
              <h2 className='text-lg font-bold pt-2 pb-4'>일별 조회 수</h2>
              <BarChart width={300} height={300} data={viewData}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='date' />
                <YAxis width={50} />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend />
                <Bar dataKey='viewCount' fill='#82ca9d' />
              </BarChart>
            </div>
            <div className='flex flex-col flex-1 px-10 py-10 rounded-xl'>
              <h2 className='text-lg font-bold pt-2 pb-4'>일별 구매자 수</h2>
              <BarChart width={300} height={300} data={paidUserData}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='date' />
                <YAxis width={50} />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend />
                <Bar dataKey='DTU' fill='#82ca9d' />
              </BarChart>
            </div>
            <div className='flex flex-col flex-1 px-10 py-10 rounded-xl'>
              <h2 className='text-lg font-bold pt-2 pb-4'>일별 구매전환율</h2>
              <BarChart width={300} height={300} data={conversionRateData}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='date' />
                <YAxis width={50} />
                <Tooltip
                  formatter={(value) => `${parseFloat(value).toFixed(2)}%`}
                />
                <Legend />
                <Bar dataKey='rate' fill='#82ca9d' />
              </BarChart>
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export default ViewInfo
