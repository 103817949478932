import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useMyFlow } from '../../hooks/altoon/useMyFlow.ts'
import { useNavigateViewer } from '../../hooks/altoon/useNavigateViewer.ts'
import AltoonUserStore from '../../stores/AltoonUserStore'
import AuthStore from '../../stores/AuthStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import backendApis from '../../utils/backendApis'
import resize from '../../utils/resize'
import AlwayzImage from '../atoms/AlwayzImage'

const NovelChapterComponent = ({
  type, // 추후 확장 시 사용 ('main'만 사용중)
  novelData,
  viewLogData,
  chapterInfo,
  chapterCount,
  setModalType,
  setModalProp,
  setOpenModal,
}) => {
  // const navigate = useNavigate()
  const { pop, push } = useMyFlow()

  const { navigateToViewer } = useNavigateViewer()
  const viewedChapterList = viewLogData?.viewedChapterIdList
  const isViewed = viewedChapterList?.some(
    (viewLog) => viewLog?.chapterId.toString() === chapterInfo?._id.toString(),
  )
  const lastRentedAt = viewedChapterList?.find(
    (viewLog) => viewLog.chapterId.toString() === chapterInfo._id.toString(),
  )?.rentedAt

  const isViewableChapter =
    isViewed &&
    moment().diff(moment(lastRentedAt), 'days') <= novelData?.rentPeriod

  const promotionInfo = novelData?.promotionInfo
  const isOnWaitForFreePromotion =
    moment().isAfter(promotionInfo?.waitForFree?.eventStartedAt) &&
    moment().isBefore(promotionInfo?.waitForFree?.eventEndedAt)

  const waitForFreeAppliableChapter =
    chapterCount - promotionInfo?.waitForFree?.recentChapterLimit >=
    chapterInfo.episode

  const adultChecker = async () => {
    // 성인 작품인 경우 (작품에서 회차목록으로 진입할 때도 확인을 하지만, 회차목록에서도 이중 검사)
    if (novelData?.isAdultOnly) {
      // 성인인증 받은 경우
      if (AltoonUserStore.userData?.isAdult === true) {
        return { isAdult: true, code: 200 }
      }

      // 성인인증 받지 않았거나 성인이 아닌 경우
      if (
        AltoonUserStore.userData?.isAdult === false ||
        !AltoonUserStore.userData?.isAdult
      ) {
        const authInfo = await backendApis.loadAuthInfo()
        if (authInfo?.status === 200) {
          const userData = await backendApis.loadUserData()
          AltoonUserStore.set('userData.isAdult', userData?.data?.isAdult)
          if (userData?.data?.isAdult === false) {
            return { isAdult: false, code: 202 }
          }
          if (userData?.data?.isAdult === true) {
            return { isAdult: true, code: 200 }
          }
        } else if (authInfo?.status === 201) {
          return { isAdult: false, code: 201 }
        }
      }
    } else {
      return { isAdult: true, code: 200 }
    }
  }

  const onNavigateHandler = async () => {
    const adultCheckResult = await adultChecker()
    if (adultCheckResult.isAdult === true) {
      navigateToViewer({
        novelId: novelData?._id,
        chapterId: chapterInfo?._id,
      })
    } else if (adultCheckResult.isAdult === false) {
      if (adultCheckResult.code === 201) {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '안내',
            description: `성인 인증이 필요한 작품입니다.`,
            src: `/images/toon/isAdultIcon.png`,
            text: '확인',
            forceOpen: true,
            onClick: () => {
              window.location.href = `#navigate.${JSON.stringify({
                screen: 'SelfAuthenticationScreen',
                prop: {
                  onSuccess: async () => {
                    pop()
                  },
                  benefitType: 'toon',
                  defaultSuccessHandler: true,
                },
              })}`
            },
          },
        })
      }
      if (adultCheckResult.code === 202) {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '안내',
            description: `성인 인증이 필요한 작품입니다.`,
            src: `/images/toon/isAdultIcon.png`,
            text: '확인',
            forceOpen: true,
            onClick: () => {
              pop()
            },
          },
        })
      }
    }
  }

  const onClickHandler = async () => {
    // 유료회차 로직 추가
    if (chapterInfo.isFree === false && chapterInfo.chapterPrice > 0) {
      // 0. 볼 수 있는 회차?

      if (isViewableChapter) {
        // console.log('볼 수 있는 회차')
        onNavigateHandler()
      } else {
        // 각 모달에서 성인 여부를 이중검사하기가 어려워서 여기서 성인검사를 한번 더 함
        const adultCheckResult = await adultChecker()

        if (adultCheckResult.isAdult === true) {
          if (
            isOnWaitForFreePromotion &&
            waitForFreeAppliableChapter &&
            viewLogData.waitForFreeAvailable === true
          ) {
            setModalType('novel_useWaitForFree')
            setModalProp({
              novelData,
              chapterInfo,
              viewLogData,
              waitForFreeAppliableChapter,
            })
            setOpenModal(true)
          } else if (
            AltoonUserStore?.userData.toonCoin >= chapterInfo.chapterPrice
          ) {
            // console.log('코인 충분. 사용하고 이동.')

            if (isViewed) {
              setModalType('novel_useCoin')
              setModalProp({
                novelData,
                chapterInfo,
                modalType: 'rentExpired',
              })
              setOpenModal(true)
            } else {
              setModalType('novel_useCoin')
              setModalProp({
                novelData,
                chapterInfo,
              })
              setOpenModal(true)
            }
          }
          if (AltoonUserStore?.userData?.toonCoin < chapterInfo.chapterPrice) {
            // 2. 불충분한 코인
            // console.log('코인 불충분')
            setModalType('novel_lackCoin')
            setModalProp({})
            setOpenModal(true)
          }
        } else if (adultCheckResult.isAdult === false) {
          if (adultCheckResult.code === 201) {
            THModalStore.setType({
              type: 'reward',
              config: {
                rewardName: '안내',
                description: `성인 인증이 필요한 작품입니다.`,
                src: `/images/toon/isAdultIcon.png`,
                text: '확인',
                forceOpen: true,
                onClick: () => {
                  window.location.href = `#navigate.${JSON.stringify({
                    screen: 'SelfAuthenticationScreen',
                    prop: {
                      onSuccess: async () => {
                        pop()
                      },
                      benefitType: 'toon',
                      defaultSuccessHandler: true,
                    },
                  })}`
                },
              },
            })
          }
          if (adultCheckResult.code === 202) {
            THModalStore.setType({
              type: 'reward',
              config: {
                rewardName: '안내',
                description: `성인 인증이 필요한 작품입니다.`,
                src: `/images/toon/isAdultIcon.png`,
                text: '확인',
                forceOpen: true,
                onClick: () => {
                  pop()
                },
              },
            })
          }
        }
      }
    } else {
      onNavigateHandler()
    }
  }

  const StatusTextComponent = () => {
    if (chapterInfo.isFree) return <div className='text-[#FF3E3E]'>무료</div>
    if (!chapterInfo.isFree) {
      if (isViewableChapter) {
        return (
          <div className='px-[2vw] flexRow text-[3vw] text-[#FF3E3E] bg-[#FFEDED] rounded'>
            <span className='font-bold mr-1'>대여중</span>
            {moment(lastRentedAt)
              .add(novelData?.rentPeriod, 'days')
              .format('M월 D일')}
            까지
          </div>
        )
      }
      return (
        <div className='flexRow text-[3vw] text-[#BCBDC3]'>
          {isViewed ? '대여만료' : ''}
          <div className='text-black ml-1'>{chapterInfo?.chapterPrice}코인</div>
        </div>
      )
    }
  }

  return (
    <button
      type='button'
      key={chapterInfo?._id}
      onClick={async () => {
        onClickHandler()
      }}
      className={
        isViewed
          ? 'flex flex-row mb-4 w-full bg-[#F6F6FA] rounded'
          : 'flex flex-row mb-4 w-full'
      }
    >
      <div className='relative'>
        <div className='flex justify-center items-center w-[24vw] h-[20vw] relative roundedx  border-[#e93838]'>
          {/* 블러 처리된 배경 레이어 */}
          <div
            className='absolute top-0 left-0 right-0 bottom-0 w-[24vw] h-[20vw] z-0 rounded'
            style={{
              backgroundImage: `url(${chapterInfo?.detailThumbnail})`,
              backgroundSize: 'cover', // 배경 이미지가 비율 유지하면서 전체를 덮도록 설정
              backgroundPosition: 'center',
              filter: 'blur(4px)', // 배경에만 블러 처리
              opacity: 0.2,
            }}
          />

          {/* 실제 이미지 */}
          <AlwayzImage
            src={resize(chapterInfo?.detailThumbnail, 150, 200, 'fill')}
            alt=''
            className='h-[18vw] rounded-lg '
            lazy
          />
        </div>

        {novelData?.isAdultOnly && (
          <img
            src='/images/toon/isAdultIcon.png'
            alt='ticket'
            className='absolute top-[1vw] right-[1vw] w-[5vw] h-[5vw]'
          />
        )}
        {!chapterInfo.isFree &&
          isOnWaitForFreePromotion &&
          chapterCount - promotionInfo?.waitForFree?.recentChapterLimit >=
            chapterInfo.episode && (
            <img
              src='/images/toon/waitForFreeIcon.png'
              alt='ticket'
              className='absolute bottom-[1vw] right-[1vw] w-[10vw] h-auto'
            />
          )}
      </div>
      <div className='w-full h-full ml-[4vw] pt-[2vw] text-start text-[4vw]'>
        <div className='h-full flex flex-col justify-between'>
          <h3 className='font-bold'>{chapterInfo?.chapterTitle}</h3>

          <div className='mt-[4vw] flex flex-row text-[2.8vw] justify-start font-semibold'>
            <div className='flex flex-row items-center justify-between w-full text-[#9C9DA4]'>
              <div className='flex flex-row items-center '>
                <img
                  alt=''
                  style={{
                    marginRight: 2,
                    width: '3vw',
                    height: '3vw',
                  }}
                  src='/images/toon/star.png'
                />
                {Math.round(
                  (chapterInfo?.chapterRatingScore /
                    chapterInfo?.chapterRatingCount) *
                    100,
                ) / 100 || 0}
                <div className='ml-[4vw]'>
                  {moment(chapterInfo?.publishedAt).format('YY.MM.DD')}
                </div>
              </div>
              <div className='pr-[2vw]'>
                <StatusTextComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </button>
  )
}
export default NovelChapterComponent
