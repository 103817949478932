import commaNumber from 'comma-number'
import { useRef } from 'react'

import dealImages from '../../../images/deal'
import UserStore from '../../../stores/UserStore'
import ImageAtom from '../../atoms/imageAtom'
import NameTag from '../tag/nameTag'
import { handleOnClickFactory } from './itemNavigateHandler'

const CategoryDealItemComponent = ({
  itemInfo,
  empty = false,
  dealEndedAt = '',
  isFrom = 'CategoryDealPage',
  isScrolling = false,
}) => {
  const ref = useRef(null)
  const teamPrice = parseInt(itemInfo?.teamPurchasePrice, 10)
  const dealPrice = parseInt(itemInfo?.minDealTeamPurchasePrice, 10)
  const type = 'categoryDeal'
  const validHandleOnClick = handleOnClickFactory(
    itemInfo,
    isFrom,
    type,
    dealEndedAt,
  )
  const handleOnClick = isScrolling ? () => {} : validHandleOnClick

  if (empty) {
    return (
      <div className='flex flex-col flex-1 w-full justify-items-center'>
        <div className='flex items-center justify-center flex-1 w-full p-4 bg-white rounded-xl animate-pulse'>
          <div className='flex flex-col items-center justify-center flex-1 h-full'>
            <div className='flex flex-row items-stretch justify-start flex-1 w-full h-full'>
              <div className='mr-3'>
                <div className='w-32 h-32 bg-gray-200 rounded-lg' />
              </div>
              <div className='flex flex-col justify-between flex-1'>
                <div className='w-24 h-4 bg-gray-200 rounded-lg' />
                <div className='flex flex-row flex-1 mt-1 leading-tight'>
                  <div className='w-full h-3 bg-gray-200 rounded-lg' />
                </div>
                <div className='flex flex-col items-end justify-end mr-2'>
                  <div className='w-16 h-6 bg-gray-200 rounded-lg' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-center flex-1 w-full'>
          <div className='w-[90%] border-t border-gray-200' />
        </div>
      </div>
    )
  }
  return (
    <div className='flex flex-col flex-1 w-full justify-items-center'>
      <div
        aria-hidden='true'
        className='flex items-center justify-center flex-1 w-full p-4 bg-white rounded-xl'
        onClick={handleOnClick}
      >
        <div className='flex flex-col items-center justify-center flex-1 h-full'>
          <div className='flex flex-row items-stretch justify-start flex-1 w-full h-full'>
            <div className='mr-3'>
              <ImageAtom
                src={itemInfo?.mainImageUris[0]}
                className='object-cover w-32 h-32 border border-gray-300 rounded-lg'
                width={256}
                height={256}
              />
            </div>
            <div className='flex flex-col justify-between flex-1'>
              <NameTag type={type} css='text-white bg-blue-400' />
              <div className='flex flex-row flex-1 mt-1 leading-tight text-ellipsis'>
                <div
                  className='h-[2.4rem] overflow-hidden'
                  style={{
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {itemInfo?.itemTitle}
                </div>
              </div>
              <div className='flex flex-col items-end justify-end mr-2'>
                <div className='text-sm font-bold text-gray-500'>
                  팀구매가 {commaNumber(teamPrice)}원
                </div>
                <div className='flex flex-row items-center flex-1'>
                  <div className='flex items-center flex-1 text-base font-bold text-blue-500 whitespace-nowrap'>
                    <div className='flex flex-row flex-1'>한정특가</div>
                    <span className='text-xl ml-1 text-gray-800 mx-0.5'>
                      {commaNumber(dealPrice)}원
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row justify-center flex-1 w-full'>
        <div className='w-[90%] border-t border-gray-200' />
      </div>
    </div>
  )
}
export default CategoryDealItemComponent
