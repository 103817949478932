import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { css } from 'styled-components'

import backendApis from '../../../../../utils/backendApis'
import { CommentSortType } from '../../../../../utils/interface'
import { novelChapterCommentListQuery } from '../../../remotes/comment'
import { Flex } from '../../Flex'

const MAX_LENGTH = 200

interface CommentInputProps {
  onSubmitFinish?: () => void
}

export const CommentInput = (props: CommentInputProps) => {
  const location = useLocation()
  const { chapterId, novelId } = location.state || {}
  const [text, setText] = useState('')

  const { refetch } = useQuery(
    novelChapterCommentListQuery({
      chapterId,
      novelId,
      sortType: CommentSortType.my,
      count: 3,
      skip: 0,
    }),
  )

  const isDisabled = text.length === 0 || text.length > MAX_LENGTH

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }

  const handleSubmit = async () => {
    if (isDisabled) return

    await backendApis.postNovelChapterComment({
      chapterId,
      novelId,
      comment: text,
    })

    await refetch()

    setText('')

    props.onSubmitFinish?.()
  }

  return (
    <Flex.Row
      css={css`
        width: 100%;
        height: max-content;
        border-radius: 8px;
        overflow: hidden;
        padding-top: 16px;
      `}
    >
      <div
        css={css`
          flex: 1;
          background: #eeeff3;
          height: 56px;
          overflow: hidden;
        `}
      >
        <textarea
          value={text}
          onChange={handleChange}
          placeholder='댓글을 입력해주세요.'
          css={textAreaCSS}
          maxLength={MAX_LENGTH}
          className='focus:outline-none focus:ring-0'
          onFocus={(e) => e.preventDefault()}
        ></textarea>
      </div>
      <button
        onClick={handleSubmit}
        css={css`
          border: none;
          background: #eeeff3;
          padding: 0px 12px;
        `}
      >
        <div
          css={css`
            //styleName: Body 3/Bold;
            /* font-family: Pretendard Variable; */
            font-size: 14px;
            font-weight: 700;
            line-height: 19.6px;
            letter-spacing: -0.20000000298023224px;
            text-align: left;

            color: ${isDisabled ? '#9C9DA4' : '#FF3E3E'};
          `}
        >
          게시
        </div>
      </button>
    </Flex.Row>
  )
}

const textAreaCSS = css`
  border: none;
  background: none;
  outline: none;
  resize: none;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }

  :focus {
    outline: none;
  }

  flex: 1;
  ::placeholder {
    color: #9c9da4;
  }
  width: 100%;
  height: 100%;
  font-size: 14px;
  margin-top: 8px;
`
