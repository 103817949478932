import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import backendApis from '../../utils/backendApis'
import TreasureHuntButton from './TreasureHuntButton'

const THBambooInvite = observer(() => {
  const [CTAText, setCTAText] = useState('친구 초대하기')

  return (
    <div className='default relative justify-between items-center w-full pb-4 text-[#313138] p-4'>
      <div className='flex flex-col flex-1 overflow-y-scroll w-full text-[#313138]  max-h-[60vh]'>
        <div className='font-bold text-[5vw]'>
          초대한 친구가 보물찾기를 시작하면
        </div>
        <div className='font-bold text-[5vw] mb-4'>
          친구도 <span className='text-[#ff5951] text-[6vw]'>3천원</span> 나도{' '}
          <span className='text-[#ff5951] text-[6vw]'>3천원</span>을 받아요!
        </div>

        <div className='flex flex-col bg-[#ffecec] border-[#ff5951] justify-start items-center p-4 rounded-xl mb-6'>
          <div className='flex flex-row justify-start items-center font-semibold w-full mb-2'>
            <img
              className='w-[6vw] h-[6vw] mr-3 '
              src='../images/treasureHunt/bambooInvite/letter.png'
              alt='letter'
            />{' '}
            올웨이즈를 안해본 친구를 초대해주세요
          </div>
          <div className='flex flex-row justify-start items-center font-semibold w-full'>
            <img
              className='w-[6vw] h-[6vw] mr-3'
              src='../images/treasureHunt/bambooInvite/hand.png'
              alt='hand'
            />{' '}
            링크를 통해 가입하고 보물찾기 시작하면 끝!
          </div>
        </div>
      </div>
      <TreasureHuntButton
        clickable
        text={CTAText}
        className='w-full py-3 text-[5vw]'
        onClick={() => {
          backendApis.THInsert({
            data: {
              shareType: 'TreasureHunt_BambooInvite',
              type: 'clickShareCTA',
            },
            collection: 'clickLog',
          })
          TreasureHuntAction.linkShare({
            shareType: 'TreasureHunt_BambooInvite',
            title: '[보물찾기] 접속만해도 3천원 쏜다!',
            description: '3천원 받고 매달 신상 귀걸이도 공짜로 받기',
            imageUrl:
              'https://assets.ilevit.com/27629713-faf7-48da-bf4a-586cd6672080.png',
            payload: {},
          })
        }}
      />
    </div>
  )
})

export default THBambooInvite
