import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import AltoonAction from '../../actions/AltoonAction'
import { useMyFlow } from '../../hooks/altoon/useMyFlow.ts'
import AltoonUserStore from '../../stores/AltoonUserStore'
import AuthStore from '../../stores/AuthStore'
import ToastStore from '../../stores/ToastStore'
import UserStore from '../../stores/UserStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import AB_V3 from '../../utils/ab_v3'
import backendApis from '../../utils/backendApis'
import useCheckCodePushVersion from '../../utils/useConvertCodePushVersion'
import ToonModal from '../templates/ToonModal'
import AltoonMissionComponent from './AltoonMissionComponent'

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

function attendanceTimeTextConverter(time) {
  if (time === 'Morning' || time === 'PreMorning' || time === 'Dawn') {
    return '오전'
  }
  if (time === 'Dinner' || time === 'PreDinner') {
    return '오후'
  }
}

function getNextTimeText(time) {
  if (time === 'Morning' || time === 'PreDinner') {
    return '오후'
  }
  if (time === 'Dinner' || time === 'PreMorning' || time === 'Dawn') {
    return '오전'
  }
}

/**
 * 올툰 열람권 미션 목록 영역
 */
const TicketMissionSection = observer(() => {
  const { push } = useMyFlow()
  const codePushVersionAvailable = useCheckCodePushVersion()

  let isCancelled = false

  const [clickCount, setClickCount] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isScreenFocused, setIsScreenFocused] = useState(true)
  const [openToonModal, setOpenToonModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [modalProp, setModalProp] = useState([])
  const [tempDailyCheckIn, setTempDailyCheckIn] = useState(null)
  const [tempBrowsing30, setTempBrowsing30] = useState(null)
  const [tempPurchase, setTempPurchase] = useState(null)
  const [timeTempAttendance, setTimeTempAttendance] = useState(null)
  const [attendanceTimeText, setAttendanceTimeText] = useState('Dawn')
  const [attendanceMissionFinishText, setAttendanceMissionFinishText] =
    useState('오전 7시 가능')
  const [isWaitingForShowingAd, setIsWaitingForShowingAd] = useState(false)

  const isImproveShowRateTest = codePushVersionAvailable(
    UserStore?.userInfo?.codepushVersion,
    '6.5.41',
  )

  const isRewardAdReady = UserStore.isRewardAdReady

  useEffect(() => {
    if (isWaitingForShowingAd && isRewardAdReady) {
      AltoonAction.showRewardedAd()
      THModalStore.close()
    }
  }, [isWaitingForShowingAd, isRewardAdReady])

  const currentCoin =
    AltoonUserStore?.userData?.toonCoin > 0
      ? AltoonUserStore?.userData?.toonCoin
      : 0
  const currentPaidCoin =
    AltoonUserStore?.userData?.paidCoin > 0
      ? AltoonUserStore?.userData?.paidCoin
      : 0

  const SectionTitle = ({ type }) => {
    const goodsKOR = [
      { goods: 'ticket', goodsKOR: '열람권' },
      { goods: 'toonCoin', goodsKOR: '코인' },
    ]

    const goodsObj = goodsKOR.find((item) => item.goods === type)
    const goodsNameKOR = goodsObj ? goodsObj.goodsKOR : ''

    return (
      <button
        type='button'
        className='items-center pt-1 font-bold flexRow'
        onClick={() => {
          setClickCount(clickCount + 1)
        }}
      >
        <img
          src={`/images/toon/${type}.png`}
          alt='ticket'
          className='w-[8vw] h-[8vw] mr-2'
        />
        {goodsNameKOR} 미션
      </button>
    )
  }

  const handleVisibilityChange = async () => {
    if (document.hidden) {
      setIsScreenFocused(false)
    } else {
      setIsScreenFocused(true)
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const result = await backendApis.loadToonMission()
      const userData = await backendApis.loadUserData()
      if (result?.status === 200 && result?.data?.dailyMission) {
        AltoonUserStore.set('dailyMission', result.data.dailyMission)
      }
      if (userData?.status === 200) {
        AltoonUserStore.set('userData', userData?.data)
      }

      // 'dailyMission' 객체가 존재하는지 확인
      if (AltoonUserStore?.dailyMission) {
        if (userData?.data?.dailyMission?.appPushInfo) {
          AltoonUserStore.set(
            'dailyMission.appPushInfo',
            userData.data.dailyMission.appPushInfo,
          )
        }
        if (userData?.data?.dailyMission?.firstPurchase) {
          AltoonUserStore.set(
            'dailyMission.firstPurchase',
            userData.data.dailyMission.firstPurchase,
          )
        }

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

        const showModalSequentially = async (initModalList) => {
          for (const init of initModalList) {
            await delay(800)
            if (isCancelled) break
            THModalStore.setType(init)
          }
          if (!isCancelled) {
            await backendApis.setToonInitModal({ initModalList: [] })
          }
        }

        if (userData?.data?.initModalList) {
          showModalSequentially(userData.data.initModalList)
        }
      }
      setIsLoaded(true)
      backendApis.logToonAction('AltoonTicketPage', 'enteredScreen', '')
    }
    fetchData()

    return () => {
      isCancelled = true // 컴포넌트 언마운트 또는 useEffect 재실행 시 현재 진행 중인 작업 취소
    }
  }, [isScreenFocused])

  useEffect(() => {
    const HHmm = moment().format('HHmm')
    if (HHmm < '0000') {
      setAttendanceTimeText('Dawn')
    } else if (HHmm >= '0000' && HHmm < '1200') {
      setAttendanceTimeText('Morning')
      // } else if (HHmm >= '1100' && HHmm < '1100') {
      //   setAttendanceTimeText('PreDinner')
    } else if (HHmm >= '1200' && HHmm < '2400') {
      setAttendanceTimeText('Dinner')
      // } else if (HHmm >= '2400') {
      //   setAttendanceTimeText('PreMorning')
    } else {
      setAttendanceTimeText('')
    }
  }, [])

  useEffect(() => {
    if (['Dinner', 'PreMorning', 'Dawn']?.includes(attendanceTimeText)) {
      setAttendanceMissionFinishText('내일 가능')
    } else {
      setAttendanceMissionFinishText('오후 가능')
    }
  }, [attendanceTimeText, AltoonUserStore?.userData?.ticket])

  useEffect(() => {
    setTempDailyCheckIn({
      ...AltoonUserStore?.dailyMission?.dailyCheckIn,
    })
    setTimeTempAttendance({
      ...AltoonUserStore?.dailyMission?.[`attendance${attendanceTimeText}`],
    })
    setTempBrowsing30({
      ...AltoonUserStore?.dailyMission?.browsing30,
    })
    setTempPurchase({
      ...AltoonUserStore?.dailyMission?.purchaseMission,
    })
  }, [AltoonUserStore?.dailyMission])

  useEffect(() => {
    const handleRewardedAdMission = async () => {
      if (UserStore.isWatchingAd === true) {
        // 1초 대기(앱러빈 시청완료 api 오는데 살짝 시간이 걸릴수 있어서)
        await sleep(1000)
        const result = await backendApis.loadToonMission()
        if (result?.status === 200 && result?.data?.dailyMission) {
          AltoonUserStore.set('dailyMission', result?.data?.dailyMission)
        }
        const userData = await backendApis.loadUserData()
        if (userData?.status === 200) {
          AltoonUserStore.set('userData', userData?.data)
          // 'dailyMission' 객체가 존재하는지 확인
          if (AltoonUserStore?.dailyMission) {
            if (userData?.data?.dailyMission?.appPushInfo) {
              AltoonUserStore.set(
                'dailyMission.appPushInfo',
                userData.data.dailyMission.appPushInfo,
              )
            }
            if (userData?.data?.dailyMission?.firstPurchase) {
              AltoonUserStore.set(
                'dailyMission.firstPurchase',
                userData.data.dailyMission.firstPurchase,
              )
            }
          }
        }
        if (result?.status === 200) {
          // 보상형 광고 시청시 status bar 때문에 닫기 버튼 안눌리는 이슈 있음
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'statusBarStatus',
              data: {
                hidden: false,
              },
            }),
          )

          THModalStore.setType({
            type: 'reward',
            config: {
              rewardName: '광고 시청 완료',
              description: `${
                AltoonUserStore?.dailyMission?.rewardedAd?.rewardedAmount || 10
              } 코인을 받았어요!`,
              src: '/images/toon/toonCoin.png',
              text: '확인',
              forceOpen: true,
              onClick: () => {},
            },
          })
          UserStore.setIsWatchingAd(false)
        }
      }
    }
    handleRewardedAdMission()
  }, [UserStore.isWatchingAd])

  const shareKakao = () => {
    AltoonAction.kakaoLinkShare({
      shareType: 'Altoon_toonShare',
      title: `[올툰] ${
        UserStore?.userInfo?.userName
          ? `${UserStore?.userInfo?.userName}님이`
          : '친구가'
      } 추천해요!`,
      description: '무료로 즐기는 꿀잼 웹툰, 올툰',
      imageUrl:
        'https://assets.ilevit.com/8a6787fd-acc0-4b28-aa59-993fe40a9240.png',
      payload: {},
      templateId: 104208,
    })
  }

  return (
    <>
      {isLoaded && AltoonUserStore?.dailyMission && (
        <div className={openToonModal ? 'fixed px-4' : 'px-4'}>
          {/* <div>
            <img
              className='w-[96vw] h-[66vw] mx-auto mb-8'
              alt='ticket'
              src='/images/toon/ticketExplain.png'
            />
            <SectionTitle type='ticket' />

            <>
              {showShareKakao && (
                <AltoonMissionComponent
                  mission={{
                    type: 'shareKakao',
                    status:
                      AltoonUserStore?.dailyMission?.shareKakao?.rewardedCount >
                      50
                        ? 'finish'
                        : 'action',
                    title: '[설 이벤트] 공유하기',
                    actionText: '공유하기',
                    finishText: '미션 완료',
                    description: '링크 공유만 해도 열람권 ',
                    rewardType: 'ticket',
                    rewardAmount: 1,
                    isSpecial: true,
                    actionHandler: () => {
                      backendApis.logToonAction(
                        'ticketMissionSection',
                        'clickedMission',
                        'shareKakao',
                      )
                      shareKakao()
                      // setModalType('shareKakao')
                      // setModalProp({
                      //   rewardedCount:
                      //     AltoonUserStore?.dailyMission?.shareKakao
                      //       ?.rewardedCount,
                      // })
                      // setOpenToonModal(true)
                    },
                  }}
                />
              )}
            
              {!AltoonUserStore?.dailyMission?.appPushInfo && (
                <AltoonMissionComponent
                  mission={{
                    type: 'setAppPush',
                    status:
                      AltoonUserStore?.dailyMission?.setAppPush || 'action',
                    title: '올툰 알림받기',
                    description: '최초 알림 설정하면 ',
                    actionText: '알림 받기',
                    rewardType: 'ticket',
                    rewardAmount: 1,

                    actionHandler: () => {
                      backendApis.logToonAction(
                        'ticketMissionSection',
                        'clickedMission',
                        'setAppPush',
                      )

                      AltoonAction.giveAltoonMissionReward({
                        type: `setAppPush`,
                      })
                      ToastStore.toastOn({
                        type: 'emoji',
                        message: `알림설정 완료!`,
                        duration: 2000,
                        emoji: '🔔',
                      })
                    },
                  }}
                />
              )}
              
            </>
          </div> */}
          {/* <div className='h-4' /> */}

          <div>
            {/* <SectionTitle type='toonCoin' /> */}
            <AltoonMissionComponent
              mission={{
                type: 'checkIn',
                status: AltoonUserStore?.dailyMission?.dailyCheckIn || 'reward',
                title: '출석하기',
                finishText: '출석 완료',
                subTitle: '매일 출석하고 열람권 받기',
                description: '출석하면 매일 ',
                actionText: '출석하기',
                rewardType: 'toonCoin',
                rewardAmount: 300,

                rewardHandler: () => {
                  backendApis.logToonAction(
                    'ticketMissionSection',
                    'clickedMission',
                    'checkIn',
                  )
                  if (
                    moment().isAfter(moment('2024-06-10 00:00:00')) &&
                    currentCoin - currentPaidCoin + 300 > 10000
                  ) {
                    THModalStore.setType({
                      type: 'reward',
                      config: {
                        rewardName: '최대 보상 도달',
                        description: `미션 획득 코인은 최대 10,000C까지만 보유할 수 있어요!`,
                        src: `/images/toon/warningEmoji.png`,
                        text: '확인',
                        forceOpen: true,
                      },
                    })
                    return
                  }
                  AltoonAction.giveAltoonMissionReward({
                    type: `dailyCheckIn`,
                  })
                },
                ...tempDailyCheckIn,
              }}
            />

            {!AltoonUserStore?.dailyMission?.appPushInfo && (
              <AltoonMissionComponent
                mission={{
                  type: 'setAppPush',
                  status: AltoonUserStore?.dailyMission?.setAppPush || 'action',
                  title: '올툰 알림받기',
                  description: '최초 알림 설정하면 ',
                  actionText: '알림 받기',
                  rewardType: 'toonCoin',
                  rewardAmount: 300,

                  actionHandler: () => {
                    backendApis.logToonAction(
                      'ticketMissionSection',
                      'clickedMission',
                      'setAppPush',
                    )
                    if (
                      moment().isAfter(moment('2024-06-10 00:00:00')) &&
                      currentCoin - currentPaidCoin + 300 > 10000
                    ) {
                      THModalStore.setType({
                        type: 'reward',
                        config: {
                          rewardName: '최대 보상 도달',
                          description: `미션 획득 코인은 최대 10,000C까지만 보유할 수 있어요!`,
                          src: `/images/toon/warningEmoji.png`,
                          text: '확인',
                          forceOpen: true,
                        },
                      })
                      return
                    }
                    AltoonAction.giveAltoonMissionReward({
                      type: `setAppPush`,
                    })
                    ToastStore.toastOn({
                      type: 'emoji',
                      message: `알림설정 완료!`,
                      duration: 2000,
                      emoji: '🔔',
                    })
                  },
                }}
              />
            )}
            {AltoonUserStore?.dailyMission?.firstPurchase?.status !==
              'finish' && (
              <AltoonMissionComponent
                mission={{
                  type: 'firstPurchase',
                  status:
                    AltoonUserStore?.dailyMission?.firstPurchase || 'action',
                  title: '초특가로 충전하기 (최초 1회)',
                  description: '첫구매 전용 70% 할인! ',
                  actionText: '충전하기',
                  rewardType: 'toonCoin',
                  rewardAmount: 6000,
                  noText: true,
                  actionHandler: () => {
                    backendApis.logToonAction(
                      'ticketMissionSection',
                      'clickedMission',
                      'firstPurchase',
                    )

                    push('AltoonItemPage', {
                      itemId: '65e0160e3401440ed9cecde3',
                      component: 'mission_firstPurchase',
                    })
                  },
                }}
              />
            )}
            {codePushVersionAvailable(
              UserStore?.userInfo?.codepushVersion,
              '5.0.1',
            ) && (
              <AltoonMissionComponent
                mission={{
                  type: 'rewardedAd',
                  status: (() => {
                    if (
                      AltoonUserStore?.dailyMission?.rewardedAd?.status ===
                      'finish'
                    ) {
                      return 'finish'
                    }

                    return isImproveShowRateTest || UserStore.isRewardAdReady
                      ? 'action'
                      : 'loading'
                  })(),
                  title: `광고 보고 코인 받기`,
                  description: '보기만 해도 최대 ',
                  actionText: '광고 보기',
                  finishText:
                    // This is fine because inside the componet,
                    // The internal button text state will be changed to action text
                    AltoonUserStore?.dailyMission?.rewardedAd?.status ===
                    'finish'
                      ? '내일 가능'
                      : '준비중',
                  rewardType: 'toonCoin',
                  rewardAmount: 50,
                  actionHandler: () => {
                    backendApis.logToonAction(
                      'ticketMissionSection',
                      'clickedMission',
                      'rewardedAd',
                    )
                    if (
                      moment().isAfter(moment('2024-06-10 00:00:00')) &&
                      currentCoin - currentPaidCoin + 300 > 10000
                    ) {
                      setIsWaitingForShowingAd(false)
                      THModalStore.setType({
                        type: 'reward',
                        config: {
                          rewardName: '최대 보상 도달',
                          description: `미션 획득 코인은 최대 10,000C까지만 보유할 수 있어요!`,
                          src: `/images/toon/warningEmoji.png`,
                          text: '확인',
                          forceOpen: true,
                        },
                      })
                      return
                    }
                    if (isImproveShowRateTest) {
                      if (!isRewardAdReady) {
                        window.ReactNativeWebView?.postMessage(
                          JSON.stringify({
                            type: 'loadAdmob',
                          }),
                        )
                      }
                    }

                    THModalStore?.setType({
                      type: 'confirm',
                      config: {
                        forceOpen: true,
                        title: '광고 보고 포인트 받기',
                        src: null,
                        description: `광고를 보고 상단에 나타나는\nX버튼을 눌러주세요\n소리가 나올 수 있어요`,
                        buttonText: '광고보기',
                        onConfirm: () => {
                          if (isImproveShowRateTest) {
                            THModalStore.updateConfig({
                              isLoading: true,
                              clickable: true,
                            })
                            if (UserStore.isRewardAdReady) {
                              THModalStore.close()
                              UserStore.setIsRewardAdReady(false)
                              AltoonAction.showRewardedAd()
                            } else {
                              setIsWaitingForShowingAd(true)
                            }
                          } else {
                            THModalStore.close()
                            AltoonAction.showRewardedAd()
                          }
                        },
                        clickable: true,
                      },
                    })
                  },
                }}
              />
            )}
            <AltoonMissionComponent
              mission={{
                rewardType: 'toonCoin',
                rewardAmount: 10,
                finishText: attendanceMissionFinishText,
                status: ['Morning', 'Dinner']?.includes(attendanceTimeText)
                  ? 'reward'
                  : 'finish',
                type: 'attendance',
                actionText: '보상 받기',
                title: `${
                  AltoonUserStore?.dailyMission?.[
                    `attendance${attendanceTimeText}`
                  ]?.status === 'finish'
                    ? `${getNextTimeText(attendanceTimeText)} 코인 받기`
                    : `${attendanceTimeTextConverter(
                        attendanceTimeText,
                      )} 코인 받기`
                }`,
                description: '오전, 오후 출석하면 ',
                rewardHandler: () => {
                  backendApis.logToonAction(
                    'ticketMissionSection',
                    'clickedMission',
                    'attendance',
                  )
                  if (
                    moment().isAfter(moment('2024-06-10 00:00:00')) &&
                    currentCoin - currentPaidCoin + 10 > 10000
                  ) {
                    THModalStore.setType({
                      type: 'reward',
                      config: {
                        rewardName: '최대 보상 도달',
                        description: `미션 획득 코인은 최대 10,000C까지만 보유할 수 있어요!`,
                        src: `/images/toon/warningEmoji.png`,
                        text: '확인',
                        forceOpen: true,
                      },
                    })
                    return
                  }

                  AltoonAction.giveAltoonMissionReward({
                    type: `attendance${attendanceTimeText}`,
                  })
                },
                ...timeTempAttendance,
              }}
            />
            {codePushVersionAvailable(
              UserStore?.userInfo?.codepushVersion,
              '4.5.31',
            ) && (
              <AltoonMissionComponent
                mission={{
                  rewardType: 'toonCoin',
                  rewardAmount: 80,
                  status: AltoonUserStore?.dailyMission?.browsing30 || 'action',
                  type: 'browsing30',
                  actionText: '구경하기',
                  finishText: '미션 완료',
                  title: '30초 구경하기',
                  description: '상품을 둘러보기만 해도 ',
                  actionHandler: () => {
                    backendApis.logToonAction(
                      'ticketMissionSection',
                      'clickedMission',
                      'browsing30',
                    )

                    if (
                      moment().isAfter(moment('2024-06-10 00:00:00')) &&
                      currentCoin - currentPaidCoin + 80 > 10000
                    ) {
                      THModalStore.setType({
                        type: 'reward',
                        config: {
                          rewardName: '최대 보상 도달',
                          description: `미션 획득 코인은 최대 10,000C까지만 보유할 수 있어요!`,
                          src: `/images/toon/warningEmoji.png`,
                          text: '확인',
                          forceOpen: true,
                        },
                      })
                      return
                    }

                    window.location.href = `#navigate.${JSON.stringify({
                      screen: 'AltoonCompatibleMission',
                      prop: {
                        missionType: 'browsing30',
                        missionName: '상품 30초 구경하기',
                        rewardType: 'toonCoin',
                        rewardTypeKor: '코인',
                        unit: 'C',
                        rewardAmount: 80,
                        defaultLeftTime: 30,
                        defaultCategory: 'total',
                        bannerUri:
                          'https://assets.ilevit.com/4e43fe3f-a8e0-49ed-a5b0-22666d0c81f5.png',
                        // 코드푸시 4.5.47이상이면 구매넛지 배너
                      },
                    })}`
                  },
                  ...tempBrowsing30,
                }}
              />
            )}
            {codePushVersionAvailable(
              UserStore?.userInfo?.codepushVersion,
              '4.5.47',
            ) && (
              //  이건 저니블락 때문에 최신 코드푸시 버전으로 분기 쳐야함! 4.5.47
              <AltoonMissionComponent
                mission={{
                  rewardType: 'toonCoin',
                  rewardAmount: 500,
                  status:
                    AltoonUserStore?.dailyMission?.purchaseMission || 'action',
                  type: 'purchase',
                  actionText: '구매하기',
                  finishText: '미션 완료',
                  title: '상품 구매하기',
                  description: '상품을 구매하면 ',
                  actionHandler: () => {
                    backendApis.logToonAction(
                      'ticketMissionSection',
                      'clickedMission',
                      'purchase',
                    )
                    if (
                      moment().isAfter(moment('2024-06-10 00:00:00')) &&
                      currentCoin - currentPaidCoin + 500 > 10000
                    ) {
                      THModalStore.setType({
                        type: 'reward',
                        config: {
                          rewardName: '최대 보상 도달',
                          description: `미션 획득 코인은 최대 10,000C까지만 보유할 수 있어요!`,
                          src: `/images/toon/warningEmoji.png`,
                          text: '확인',
                          forceOpen: true,
                        },
                      })
                      return
                    }

                    window.location.href = `#navigate.${JSON.stringify({
                      screen: 'AltoonCompatibleMission',
                      prop: {
                        missionType: 'purchase',
                        missionName: '상품 구매하기',
                        rewardType: 'toonCoin',
                        rewardTypeKor: '코인',
                        unit: 'C',
                        rewardAmount: 500,
                        defaultCategory: 'total',
                        bannerUri:
                          'https://assets.ilevit.com/97196363-09a8-4b31-95de-4b6c823e863f.png',
                      },
                    })}`
                  },
                  ...tempPurchase,
                }}
              />
            )}
          </div>
        </div>
      )}
      {openToonModal && (
        <ToonModal
          modalName={modalType}
          coinCount={
            AltoonUserStore?.userData?.toonCoin > 0
              ? AltoonUserStore?.userData?.toonCoin || 0
              : 0
          }
          modalProp={modalProp}
          setOpenTicketModal={setOpenToonModal}
        />
      )}
    </>
  )
})
export default TicketMissionSection
