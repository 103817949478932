import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import { Suspense, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { useNavigateViewer } from '../../../../hooks/altoon/useNavigateViewer'
import { CommentSortType } from '../../../../utils/interface'
import { useNovelChapterPermission } from '../../hooks/useNovelChapterPermission'
import {
  loadNovelDetailQuery,
  novelChapterCommentListQuery,
} from '../../remotes/comment'
import {
  getNovelChapterDocumentQuery,
  getNovelChapterInfoQuery,
} from '../../remotes/novelChapter'
import { viewerStore } from '../../store'
import { stopPropagation } from '../../utils/stopPropagation'
import { useAsyncOverlay } from '../../utils/useAsyncOverlay'
import { BookmarkBottomsheet } from '../Bookmark/BookmarkBottomsheet'
import { CommentBottomsheet } from '../Comment/CommentBottomsheet/CommentBottomsheet'
import { TouchArea } from '../TouchArea/TouchArea'
import { ChapterList } from './ChapterList'
import { ScrollSlider } from './ScrollSlider'

export const BottomController = observer(() => {
  const [isChapterListOpen, setIsChapterListOpen] = useState(false)
  const location = useLocation()
  const { chapterId, novelId } = location.state || {}
  const { navigateToViewer } = useNavigateViewer()
  const { openAsync } = useAsyncOverlay()

  const { data: novelChapter } = useSuspenseQuery(
    getNovelChapterInfoQuery({ novelId, chapterId }),
  )
  const { data: novel } = useQuery(loadNovelDetailQuery({ novelId }))

  const { data: bestCommentList } = useQuery(
    novelChapterCommentListQuery({
      chapterId,
      novelId,
      sortType: CommentSortType.my,
      count: 3,
      skip: 0,
    }),
  )

  const { checkPermission } = useNovelChapterPermission()

  const navigateToPrevNovelChapter = async () => {
    if (!novelChapter.prevChapter) return

    const isPermitted = await checkPermission({
      targetChapterId: novelChapter.prevChapter?._id,
    })

    if (!isPermitted) {
      return
    }

    navigateToViewer(
      {
        novelId,
        chapterId: novelChapter.prevChapter?._id,
      },
      {
        replace: true,
      },
    )
  }

  const navigateToNextNovelChapter = async () => {
    if (!novelChapter.nextChapter) return

    const isPermitted = await checkPermission({
      targetChapterId: novelChapter.nextChapter?._id,
    })

    if (!isPermitted) {
      return
    }
    navigateToViewer(
      {
        novelId,
        chapterId: novelChapter.nextChapter?._id,
      },
      {
        replace: true,
      },
    )
  }

  const openCommentBottomsheet = () => {
    openAsync<void>(({ isOpen, close }) => {
      return <CommentBottomsheet isOpen={isOpen} onClose={close} />
    })
  }

  const openBookmarkBottomsheet = () => {
    openAsync<void>(({ isOpen, close }) => {
      return (
        <Suspense>
          <BookmarkBottomsheet isOpen={isOpen} onClose={close} />
        </Suspense>
      )
    })
  }

  const navigateToChapter = (chapterId: string) => {
    navigateToViewer(
      {
        novelId,
        chapterId,
      },
      {
        replace: true,
      },
    )
  }

  return (
    <ControllerWrapper
      visible={viewerStore.isControllerVisible}
      onClick={stopPropagation}
      onTouchMove={stopPropagation}
    >
      <ScrollSlider />
      <ButtonContainer>
        <Group gap={16}>
          {/* 뷰어 바텀바 회차목록 */}
          <TouchArea
            width={54}
            height={56}
            onClick={() => {}}
            css={[
              columnCSS,
              css`
                position: relative;
              `,
            ]}
          >
            {isChapterListOpen && <ChapterList />}
            <img src='/icon_list.svg' alt='icon' width={24} height={24} />
            <div
              css={textCSS}
              onClick={() => setIsChapterListOpen((cur) => !cur)}
            >
              회차목록
            </div>
          </TouchArea>

          {/* 뷰어 바텀바 책갈피 */}
          <TouchArea
            width={54}
            height={56}
            css={columnCSS}
            onClick={openBookmarkBottomsheet}
          >
            <img
              src='/icon_bookmark_stroke.svg'
              alt='icon'
              width={24}
              height={24}
            />
            <div css={textCSS}>책갈피</div>
          </TouchArea>

          {/* 뷰어 바텀바 댓글 */}
          <TouchArea
            width={54}
            height={56}
            css={columnCSS}
            onClick={openCommentBottomsheet}
          >
            <img src='/icon_chat.svg' alt='icon' width={24} height={24} />
            <div css={textCSS}>{bestCommentList?.totalLength ?? 0}</div>
          </TouchArea>
        </Group>

        <Group gap={16}>
          <TouchArea
            width={54}
            height={60}
            onClick={navigateToPrevNovelChapter}
            disabled={!novelChapter.prevChapter}
            css={gapCSS}
          >
            {novelChapter.prevChapter ? (
              <FiChevronLeft className='w-24 h-24 stroke-gray-600' />
            ) : (
              <FiChevronLeft className='w-24 h-24 stroke-gray-300' />
            )}
            <p
              css={css`
                //styleName: Detail 1/Medium;
                /* font-family: Pretendard Variable; */
                font-size: 13px;
                font-weight: 500;
                line-height: 19.5px;
                letter-spacing: -0.20000000298023224px;
                text-align: center;

                white-space: nowrap;
              `}
            >
              이전화
            </p>
          </TouchArea>

          <TouchArea
            width={54}
            height={60}
            onClick={navigateToNextNovelChapter}
            disabled={!novelChapter.nextChapter}
            css={gapCSS}
          >
            {novelChapter.nextChapter ? (
              <FiChevronRight className='w-24 h-24 stroke-gray-600' />
            ) : (
              <FiChevronRight className='w-24 h-24 stroke-gray-300' />
            )}
            <p
              css={css`
                //styleName: Detail 1/Medium;
                /* font-family: Pretendard Variable; */
                font-size: 13px;
                font-weight: 500;
                line-height: 19.5px;
                letter-spacing: -0.20000000298023224px;
                text-align: center;

                white-space: nowrap;
              `}
            >
              다음화
            </p>
          </TouchArea>
        </Group>
      </ButtonContainer>
    </ControllerWrapper>
  )
})

// #BCBDC3 #000000
const ControllerWrapper = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 112px;
  background: white;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.05);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.2s ease-out;
  user-select: none;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 32px);
  height: 50px;
  padding: 0px 4px;
`

const gapCSS = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Group = styled.div<{ gap: number }>`
  display: flex;
  gap: ${(props) => props.gap}px;
`

const textCSS = css`
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
`

const columnCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 56px;
  gap: 4px;
`
