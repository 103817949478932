import { observable, toJS } from 'mobx'
import moment from 'moment'

const EggMoneyStore = observable({
  egg: {
    _id: null,
    name: '달걀1',
    img: '../images/eggMoney/eggHome.png',
  },
  boxSelectedEgg: {
    count: 0,
    egg: { img: '../images/eggMoney/skeletonEgg.png', name: '-' },
  },
  user: {},
  claimEggHistory: [],

  setMultiple(obj) {
    const arr = Object.entries(obj ?? {})
    for (const [key, value] of arr) {
      this.set(key, value)
    }
  },

  increase(key, value) {
    if (typeof value !== 'number') {
      return
    }

    function increaseProperty(obj, keys, val) {
      let key
      while (keys.length > 1) {
        key = keys.shift()
        if (!obj.hasOwnProperty(key)) obj[key] = {}
        obj = obj[key]
      }
      obj[keys[0]] += val
    }

    if (key?.includes('.')) {
      const keys = key.split('.')
      increaseProperty(this, keys, value)
    } else {
      this[key] += value
    }
  },

  set(key, value) {
    function setProperty(obj, keys, val) {
      let key
      while (keys.length > 1) {
        key = keys.shift()
        if (!obj.hasOwnProperty(key)) obj[key] = {}
        obj = obj[key]
      }
      obj[keys[0]] = val
    }

    if (key?.includes('.')) {
      const keys = key.split('.')
      setProperty(this, keys, value)
    } else {
      this[key] = value
    }
  },

  push(key, value) {
    this?.[key]?.push(value)
  },
})

export default EggMoneyStore
