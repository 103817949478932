import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import THUserStore from '../../stores/treasureHunt/THUserStore'
import TreasureHuntButton from './TreasureHuntButton'
import DailyMission from './molecules/DailyMission'
import GaugeBar from './molecules/GaugeBar'

function attendanceTimeTextConverter(time) {
  if (time === 'Dawn') {
    return '새벽'
  }
  if (time === 'Morning') {
    return '아침'
  }
  if (time === 'Lunch') {
    return '점심'
  }
  if (time === 'Dinner') {
    return '저녁'
  }
}

const THLuck = observer(() => {
  const [starboxTierImage, setStarboxTierImage] = useState('starboxTierOne')
  const [attendanceTimeText, setAttendanceTimeText] = useState('Dawn')

  const Starbox = observer(({ starbox }) => {
    return (
      <div className='flex flex-1 flex-col justify-between items-center bg-[#F8F8F8] rounded-xl p-2 text-[#313138]'>
        <div className='font-bold text-[3.5vw] mb-2'>{starbox?.name}</div>

        <div className='flex flex-1 flex-row justify-between items-center w-full'>
          <div className='flex flex-col justify-between items-center relative'>
            <img
              className='w-[16vw] h-[16vw] mb-2'
              src={`../images/treasureHunt/luck/starbox/${starbox?.type}.png`}
              alt='starboxTierTwo'
            />
            <div className='absolute -bottom-0 -right-2 bg-white rounded-full px-1 border-[1px] text-[3vw] font-bold text-[#313138] whitespace-nowrap border-[#89d35d] '>
              {`x${commaNumber(THUserStore?.[starbox?.type])}`}
            </div>
          </div>
          <div className='flex justify-end items-center flex-col flex-1 pl-[6vw] px-2 h-full'>
            <div className='mb-1 flex flex-row text-[3.5vw]'>
              <div>행운+</div>
              <div className='font-semibold'>{starbox?.rewardAmount}</div>
            </div>
            <TreasureHuntButton
              className='shadow-none'
              style={{
                fontSize: '4vw',
                width: '100%',
                paddingTop: '1vw',
                paddingBottom: '1vw',
              }}
              clickable={THUserStore?.[starbox?.type] > 0}
              text='사용'
              onClick={() => {
                TreasureHuntAction.openStarbox({
                  type: starbox?.type,
                })
              }}
            />
          </div>
        </div>
      </div>
    )
  })

  useEffect(() => {
    if (THUserStore?.luck >= 75) {
      setStarboxTierImage('starboxTierTwo')
    } else if (THUserStore?.luck >= 10 && THUserStore?.luck < 75) {
      setStarboxTierImage('starboxTierOne')
    } else if (THUserStore?.luck < 10) {
      setStarboxTierImage('starboxGray')
    }
  }, [THUserStore?.luck])

  useEffect(() => {
    const HHmm = moment().format('HHmm')
    if (HHmm >= '0000' && HHmm < '0600') {
      setAttendanceTimeText('Dawn')
    } else if (HHmm >= '0600' && HHmm < '1200') {
      setAttendanceTimeText('Morning')
    } else if (HHmm >= '1200' && HHmm < '1800') {
      setAttendanceTimeText('Lunch')
    } else if (HHmm >= '1800' && HHmm < '2400') {
      setAttendanceTimeText('Dinner')
    }
  }, [])

  const getLuckStyle = () => {
    if (THUserStore?.luck < 10) {
      return {
        backgroundColor: '#d9d9d9',
      }
    }
    if (THUserStore?.luck >= 10 && THUserStore?.luck < 75) {
      return {
        backgroundColor: '#E8FEDB',
      }
    }
    if (THUserStore?.luck >= 75) {
      return {
        backgroundColor: '#F9F0CE',
      }
    }
    // 다른 조건들에 대한 스타일을 추가하고 싶으면 여기에 추가할 수 있습니다.
    return {} // 기본 스타일 (조건이 충족되지 않을 때)
  }

  return (
    <div className='default relative justify-start items-center w-full pb-4 text-[#313138] p-4 max-h-[80vh] overflow-y-scroll'>
      <div className='w-full px-6 my-0 mb-[12vw] justify-center items-center flex flex-col'>
        <div
          className='relative flex flex-row justify-center items-center mb-[8vw] p-4 rounded-full whitespace-nowrap w-[24vw] h-[24vw] text-[5vw] font-bold'
          style={getLuckStyle()}
        >
          {THUserStore?.luck >= 75 && (
            <>
              <img
                className='w-[5vw] h-[5vw] absolute -right-[6vw] top-16 THluckEffect'
                src='../images/treasureHunt/luck/effect.png'
                alt='effect'
              />
              <img
                className='w-[8vw] h-[8vw] absolute -left-10 top-2 THluckEffect'
                src='../images/treasureHunt/luck/effect.png'
                alt='effect'
              />
            </>
          )}
          <img
            className='w-full h-full'
            src={`../images/treasureHunt/luck/starbox/${starboxTierImage}.png`}
            alt='luck'
          />
          <div
            className='absolute -bottom-[4vw] 
          bg-[#c3f5a6] px-4 py-0 rounded-full -translate-x-1/2 left-1/2 whitespace-nowrap 
          text-[#57AF1E]'
          >
            {`${THUserStore?.luck ?? 0}`}
          </div>
        </div>
        <GaugeBar currentExp={THUserStore?.luck ?? 0} totalExp={100} />
      </div>
      <div className='flex flex-row w-full justify-between items-center mt-4 mb-2 space-x-2'>
        {/* 고급 */}
        <Starbox
          starbox={{
            name: '황금 클로버',
            type: 'starboxTierTwo',
            rewardAmount: 35,
          }}
        />

        {/* 일반 */}
        <Starbox
          starbox={{
            name: '클로버',
            type: 'starboxTierOne',
            rewardAmount: 10,
          }}
        />
      </div>

      <div className='flex flex-col w-full justify-start items-center py-2'>
        <DailyMission
          mission={{
            rewardType: 'starboxTierOne',
            rewardAmount: 2,
            status: 'reward',
            dailyLimit: 4,
            type: 'attendanceStarbox',
            actionText: '보상받기',
            title: `${attendanceTimeTextConverter(
              attendanceTimeText,
            )} 클로버 받기`,
            rewardHandler: () => {
              TreasureHuntAction.giveMissionReward({
                type: `attendanceStarbox${attendanceTimeText}`,
              })
            },
            ...THUserStore?.dailyMission?.[
              `attendanceStarbox${attendanceTimeText}`
            ],
          }}
        />
        <DailyMission
          mission={{
            rewardType: 'starboxTierOne',
            rewardAmount: 2,
            status: 'action',
            type: 'linkShareStarbox',
            actionText: '공유하기',
            title: '매일 공유하기',
            actionHandler: () => {
              TreasureHuntAction.kakaoLinkShare({
                shareType: 'TreasureHunt_linkShareStarbox',
                title: '귀걸이 지원금 3,000원이 지급되었어요!',
                description: '캐시를 모으면 매달 귀걸이를 무료로 받아요!',
                imageUrl:
                  'https://assets.ilevit.com/80ec3d27-37c3-456d-a487-ec5650ff3546.png',
                payload: {},
                templateId: 99743,
              })
              THBottomSheetStore.close()
            },
            rewardHandler: () => {
              TreasureHuntAction.giveMissionReward({
                type: 'linkShareStarbox',
              })
            },
            ...THUserStore?.dailyMission?.linkShareStarbox,
          }}
        />

        <DailyMission
          mission={{
            rewardType: 'starboxTierTwo',
            rewardAmount: 2,
            status: 'action',
            type: 'purchaseMissionStarbox',
            actionText: '구매하기',
            title: '상품 구매하기',
            rewardFixed: true,
            actionHandler: () => {
              const payload = {
                enteringComponent: 'TreasureHunt_PurchaseMissionStarbox',
                text: '보물찾기',
                source:
                  'https://assets.ilevit.com/3f2e41e1-7032-4d1b-95dc-58c76855714d.png',
                rewardText: `상품을 구매하고 황금 클로버 2개를 받아보세요`,
              }

              TreasureHuntAction.navigateRN({
                screen: 'TreasureHuntMissionPurchaseScreen',
                payload,
              })
              THBottomSheetStore.close()
            },
            rewardHandler: () => {
              TreasureHuntAction.giveMissionReward({
                type: 'purchaseMissionStarbox',
              })
            },
            ...THUserStore?.dailyMission?.purchaseMissionStarbox,
          }}
        />

        <DailyMission
          mission={{
            rewardType: 'starboxTierOne',
            rewardAmount: 2,
            status: 'action',
            type: 'browsingMissionStarbox',
            actionText: '둘러보기',
            title: '상품 둘러보기',
            actionHandler: () => {
              const payload = {
                enteringComponent: 'TreasureHunt_BrowsingMissionStarbox',
                text: '보물찾기',
                source:
                  'https://assets.ilevit.com/3f2e41e1-7032-4d1b-95dc-58c76855714d.png',
                rewardText: `상품을 구매하고 황금 클로버 2개를 받아보세요`,
                isTimerOn: true,
                timeStamp: moment().add(30, 'seconds').toDate(),
                timerText: `초 구경하면 클로버를 최대 2개까지 받아요!`,
                timerMinutes: 0.5,
              }

              TreasureHuntAction.navigateRN({
                screen: 'TreasureHuntMissionPurchaseScreen',
                payload,
              })
              THBottomSheetStore.close()
            },
            rewardHandler: () => {
              TreasureHuntAction.giveMissionReward({
                type: 'browsingMissionStarbox',
              })
            },
            ...THUserStore?.dailyMission?.browsingMissionStarbox,
          }}
        />
      </div>
    </div>
  )
})

export default THLuck
