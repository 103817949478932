import { observer } from 'mobx-react-lite'
import React, { memo, useEffect, useState } from 'react'
import { MdArrowForwardIos as ArrowRight } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import dealImages from '../../../images/deal'
import AuthStore from '../../../stores/AuthStore'
import ItemImpressionStore from '../../../stores/ItemImpressionStore'
import backendApis from '../../../utils/backendApis'
import insertDealHandler from '../../../utils/insertDealHandler'
import TextTimer from '../../TextTimer'
import HorizontalList from '../item/horizontalList'
import LureDealItemComponent from '../item/lureDealItemComponent'

const LureDealSection = () => {
  const navigate = useNavigate()
  const [dealItemsInfo, setDealItemsInfo] = useState([])
  const [loadNum, setLoadNum] = useState(0)
  const dealType = 'lureDeal'
  const currentDate = new Date()
  const endedAt = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    23,
    59,
    59,
  )
  const ITEM_HEIGHT = 260
  const ITEM_WIDTH = 148
  const SCREEN_WIDTH = window.innerWidth
  const fetchDealItem = async (dealType, loadNum) => {
    const result = await backendApis.getDealItemsV3(dealType, loadNum)
    if (result?.status === 200) {
      insertDealHandler.dataSetter(dealType, result?.data)
      setDealItemsInfo((arr) => [...arr, ...result?.data])
      setLoadNum(loadNum + result?.data?.length)
    }
  }

  const renderItem = memo(({ index, style, data }) => {
    if (!data[index])
      return <LureDealItemComponent key={`${index}`} style={style} empty />
    return (
      <LureDealItemComponent
        key={`${index}`}
        style={style}
        itemInfo={data[index]}
      />
    )
  })

  useEffect(() => {
    if (AuthStore.token) fetchDealItem(dealType, loadNum)
  }, [AuthStore.token])

  return (
    <div className='relative flex flex-col items-start justify-center flex-1 p-3 overflow-hidden select-none'>
      <div className='z-20 flex items-center justify-between flex-1 w-full my-3 text-lg font-bold text-white'>
        <div>오늘만 이 가격, 순삭특가!</div>
        <div
          aria-hidden
          onClick={async () => {
            ItemImpressionStore.setPreviousIsFrom('LureDealSection')
            navigate('/deal/lure-deal')
          }}
          className='flex flex-row items-center justify-end flex-1'
        >
          <TextTimer
            endedAt={endedAt}
            boxCss='px-1 text-orange-600 text-xs bg-white rounded'
            dotCss='mx-1 text-base'
          />
          <ArrowRight />
        </div>
      </div>
      <img
        src={dealImages.LureDealBackground}
        className='absolute inset-0 object-cover w-full h-full'
        alt='lureDeal'
      />
      <div>
        <HorizontalList
          screenWidth={SCREEN_WIDTH}
          itemWidth={ITEM_WIDTH}
          itemHeight={ITEM_HEIGHT}
          itemData={dealItemsInfo}
          itemCount={loadNum === 0 ? 20 : loadNum}
          renderItem={renderItem}
          isFrom='LureDealSection'
          isItemList
        />
      </div>
    </div>
  )
}
export default observer(LureDealSection)
