/* eslint-disable no-nested-ternary */
import moment from 'moment'
import React from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { useMyFlow } from '../../hooks/altoon/useMyFlow.ts'
import AltoonUserStore from '../../stores/AltoonUserStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import backendApis from '../../utils/backendApis'
import resize from '../../utils/resize'
import AlwayzImage from '../atoms/AlwayzImage'
import AltoonTagComponent from './AltoonTagComponent'

const MainNovelComponent = ({
  novel = {},
  index = {},
  type = 'main',
  isToday = false,
}) => {
  const navigate = useNavigate()
  const { pop } = useMyFlow()
  const isOnWaitForFreePromotion =
    moment().isAfter(novel?.promotionInfo?.waitForFree?.eventStartedAt) &&
    moment().isBefore(novel?.promotionInfo?.waitForFree?.eventEndedAt)

  const onClickHandler = async () => {
    if (novel?.isAdultOnly) {
      if (AltoonUserStore.userData?.isAdult === false) {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '안내',
            description: `성인 인증이 필요한 작품입니다.`,
            src: `/images/toon/isAdultIcon.png`,
            text: '확인',
            forceOpen: true,
            onClick: () => {
              window.location.href = `#navigate.${JSON.stringify({
                screen: 'SelfAuthenticationScreen',
                prop: {
                  onSuccess: async () => {
                    pop()
                  },
                  benefitType: 'toon',
                  defaultSuccessHandler: true,
                },
              })}`
            },
          },
        })
      }
      if (AltoonUserStore.userData?.isAdult === true) {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '안내',
            description: `기존 인증 내역으로 성인 인증이 완료되었습니다.`,
            src: `/images/toon/isAdultIcon.png`,
            text: '확인',
            forceOpen: true,
          },
        })

        navigate(`/altoon-novel-detail?novelId=${novel?._id}`)
      }
      if (!AltoonUserStore.userData?.isAdult) {
        const authInfo = await backendApis.loadAuthInfo()
        if (authInfo?.status === 200) {
          const userData = await backendApis.loadUserData()
          AltoonUserStore.set('userData.isAdult', userData?.data?.isAdult)
          if (userData?.data?.isAdult === false) {
            THModalStore.setType({
              type: 'reward',
              config: {
                rewardName: '안내',
                description: `성인 인증이 필요한 작품입니다.`,
                src: `/images/toon/isAdultIcon.png`,
                text: '확인',
                forceOpen: true,
                onClick: () => {
                  window.location.href = `#navigate.${JSON.stringify({
                    screen: 'SelfAuthenticationScreen',
                    prop: {
                      onSuccess: async () => {
                        pop()
                      },
                      benefitType: 'toon',
                      defaultSuccessHandler: true,
                    },
                  })}`
                },
              },
            })
          }
          if (userData?.data?.isAdult === true) {
            THModalStore.setType({
              type: 'reward',
              config: {
                rewardName: '안내',
                description: `기존 인증 내역으로 성인 인증이 완료되었습니다.`,
                src: `/images/toon/isAdultIcon.png`,
                text: '확인',
                forceOpen: true,
              },
            })
            navigate(`/altoon-novel-detail?novelId=${novel?._id}`)
          }
        } else if (authInfo?.status === 201) {
          THModalStore.setType({
            type: 'reward',
            config: {
              rewardName: '안내',
              description: `성인 인증이 필요한 작품입니다.`,
              src: `/images/toon/isAdultIcon.png`,
              text: '확인',
              forceOpen: true,
              onClick: () => {
                window.location.href = `#navigate.${JSON.stringify({
                  screen: 'SelfAuthenticationScreen',
                  prop: {
                    onSuccess: async () => {
                      pop()
                    },
                    benefitType: 'toon',
                    defaultSuccessHandler: true,
                  },
                })}`
              },
            },
          })
        }
      }
      return
    }

    navigate(`/altoon-novel-detail?novelId=${novel?._id}`)
  }

  if (type === 'horizontal') {
    return (
      <button
        type='button'
        className='mr-1'
        key={novel?._id || index}
        onClick={() => {
          onClickHandler()
        }}
      >
        <div className='relative'>
          <AlwayzImage
            src={resize(novel?.thumbnail, 236, 236, 'fill')}
            alt={novel?.title}
            lazy
            className='w-[21vw] h-[28vw] rounded-lg border border-[#F5F5F5] min-w-[21vw] min-h-[28vw]'
          />
          {isOnWaitForFreePromotion && (
            <img
              src='/images/toon/waitForFreeIcon.png'
              alt='ticket'
              className='absolute bottom-[1vw] right-[1vw] w-[9vw] h-auto'
            />
          )}
          {novel?.isAdultOnly && (
            <img
              src='/images/toon/isAdultIcon.png'
              alt='ticket'
              className='absolute top-[1vw] right-[1vw] w-[3.6vw] h-[3.6vw]'
            />
          )}
        </div>
        <div className='py-1'>
          <h3 className='font-bold text-start text-[2.8vw] mb-1'>
            {novel?.title?.length > 8
              ? `${novel?.title.slice(0, 8)}...`
              : `${novel?.title}`}
          </h3>
        </div>
      </button>
    )
  }

  if (type === 'mypage_recently_viewed') {
    return (
      <button
        type='button'
        className='flex flex-row justify-between items-center py-[1vh] text-[3vw] relative'
        key={novel?._id || index}
        onClick={() => {
          onClickHandler()
        }}
      >
        <div className='flex flex-row '>
          <img
            src={resize(novel?.thumbnail, 187, 234, 'fill')}
            alt={novel?.title}
            className='w-[24vw] h-[30vw] rounded-lg mr-3 border border-gray-200'
          />
          {novel?.isAdultOnly && (
            <img
              src='/images/toon/isAdultIcon.png'
              alt='ticket'
              className='absolute top-[3vw] left-[1vw] w-[5vw] h-[5vw]'
            />
          )}
          <div className='py-3 flex flex-col justify-between items-start text-start'>
            <div>
              <h3 className='font-bold text-[3.8vw] pr-2 mb-1'>
                {novel?.title}
              </h3>
              <div className='text-gray-600'>
                <p>{novel?.author}</p>
              </div>
            </div>
            <div className='text-gray-600'>
              {moment(novel?.viewLog?.lastViewedAt).format('YYYY.MM.DD 열람')}
            </div>
          </div>
        </div>
        <div
          type='button'
          className='w-[16vw] flex flex-row items-center justify-end'
          onClick={() => {
            navigate(`/altoon-novel-detail?novelId=${novel?._id}`)
          }}
        >
          이어보기
          <FiChevronRight className='w-3 h-3' />
        </div>
      </button>
    )
  }

  return (
    <button
      type='button'
      key={novel?._id || index}
      onClick={async () => {
        onClickHandler()
      }}
      className='flex flex-row w-full h-[40vw]'
    >
      <div className='relative w-[44vw]'>
        <AlwayzImage
          src={resize(novel?.thumbnail, 248, 340, 'fill')}
          alt={novel?.title}
          className='w-[29vw] h-[40vw] rounded-lg border border-[#F5F5F5]'
          lazy
        />
        {isToday && (
          <img
            src='/images/toon/upIcon.png'
            alt='ticket'
            className='absolute top-[1vw] left-[1vw] w-[5vw] h-[3vw]'
          />
        )}
        {isOnWaitForFreePromotion && (
          <img
            src='/images/toon/waitForFreeIcon.png'
            alt='ticket'
            className='absolute bottom-[1vw] right-[1vw] w-[13vw] h-auto'
          />
        )}
        {novel?.isAdultOnly && (
          <img
            src='/images/toon/isAdultIcon.png'
            alt='ticket'
            className='absolute top-[1vw] right-[1vw] w-[5vw] h-[5vw]'
          />
        )}
      </div>
      <div className='w-full h-full ml-2 mb-1 pt-2 text-start text-[4vw]'>
        <div className='h-full flex flex-col justify-between'>
          <div>
            <h3 className='font-bold'>{novel?.title}</h3>
            <div className='pt-1 pb-2 flex flex-row text-[2.8vw] justify-start text-start'>
              <p>{novel?.author}</p>
              {type !== 'onboarding' && (
                <p className='flex flex-row items-center justify-end pl-2'>
                  <img
                    alt=''
                    style={{
                      marginRight: 2,
                      width: '3vw',
                      height: '3vw',
                    }}
                    src='/images/toon/star.png'
                  />
                  {Math.round((novel?.ratingScore / novel?.ratingCount) * 100) /
                    100 || 0}
                </p>
              )}
            </div>
            <div className='text-[2.8vw] justify-start text-gray-600 text-start'>
              {novel?.isAdultOnly
                ? '본인인증이 필요한 작품입니다.'
                : novel?.description?.length > 84
                ? `${novel?.description?.slice(0, 84)}..`
                : novel?.description}
            </div>
          </div>
          {type !== 'onboarding' && (
            <div className='flex flex-row mt-2 text-[2.8vw]'>
              {novel?.tagList?.hot === true && (
                <AltoonTagComponent key={Math.random()} tag='hot' />
              )}

              {novel?.tagList?.complete === true && (
                <AltoonTagComponent key={Math.random()} tag='complete' />
              )}

              {novel?.tagList?.new && (
                <AltoonTagComponent key={Math.random()} tag='new' />
              )}
              {novel?.tagList?.mainGenre?.slice(0, 2)?.map((tag) => {
                return <AltoonTagComponent key={Math.random()} tag={tag} />
              })}
            </div>
          )}
          {type === 'onboarding' && (
            <div className='flex flex-row items-center justify-center py-1 text-[3vw] bg-red-500 text-white font-semibold rounded'>
              보러가기
              <FiChevronRight className='inline-block ml-1' />
            </div>
          )}
        </div>
      </div>
    </button>
  )
}
export default MainNovelComponent
