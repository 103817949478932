import commaNumber from 'comma-number'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import Button from '../../../pages/EggMoney/Button'
import EggMoneyStore from '../../../stores/EggMoney/EggMoneyStore'
import ToastStore from '../../../stores/ToastStore'
import THModalStore from '../../../stores/treasureHunt/THModalStore'
import backendApis from '../../../utils/backendApis'

function renderDescription(text) {
  return text?.split?.('\n')?.map((line, index) => (
    <span key={Math.random()}>
      {line}
      {index < text?.split('\n').length - 1 && <br />}
    </span>
  ))
}

const EggMoneyBoxDetail = ({ config }) => {
  const [buttonText, setButtonText] = useState('-')

  const navigate = useNavigate()

  useEffect(() => {
    if (EggMoneyStore?.boxSelectedEgg?.egg?.name === '-') return
    const text =
      EggMoneyStore?.boxSelectedEgg?.count > 0 ? '판매하기' : '구매하기'
    setButtonText(text)
  }, [EggMoneyStore?.boxSelectedEgg?.egg?._id])

  useEffect(() => {
    // check if bookmarked

    const eggId = EggMoneyStore?.boxSelectedEgg?.egg?._id
    for (const egg of EggMoneyStore?.allBookmarks ?? []) {
      if (egg.eggId?.toString() === eggId?.toString()) {
        EggMoneyStore.set('boxSelectedEgg.bookmarked', true)
        return
      }
    }
  }, [EggMoneyStore?.boxSelectedEgg])

  const bookmarkEgg = async () => {
    const res = await backendApis.bookmarkEgg({
      params: {
        eggId: EggMoneyStore?.boxSelectedEgg?.egg?._id,
      },
    })
    if (res?.status === 201) {
      ToastStore.toastOn({
        type: 'error',
        message: '찜하기 개수를 초과했어요',
        duration: 2000,
      })
    } else if (res.status === 200) {
      if (res?.data?.bookmarked) {
        ToastStore.toastOn({
          type: 'success',
          message: '에그를 찜했어요 (등장확률 UP!)',
          duration: 2000,
        })
      } else {
        ToastStore.toastOn({
          type: 'success',
          message: '찜하기를 취소했어요',
          duration: 2000,
        })
      }
      EggMoneyStore.set('boxSelectedEgg.bookmarked', res?.data?.bookmarked)
      EggMoneyStore.set('allBookmarks', res?.data?.allBookmarks)
    } else {
      ToastStore.networkError(res.status)
    }
  }

  return (
    <div className='flex flex-col justify-start items-center mt-4'>
      <div
        className={`text-sm  px-2 py-1 rounded-md rank-${EggMoneyStore?.boxSelectedEgg?.egg?.rarity} mb-2`}
      >
        <div className={`font-semibold `}>
          {`${
            EggMoneyStore?.boxSelectedEgg?.egg?.rarity
              ? `${EggMoneyStore?.boxSelectedEgg?.egg?.rarity?.toUpperCase()}등급`
              : '-'
          }`}
        </div>
      </div>
      <div className='font-bold my-0 text-2xl'>
        {EggMoneyStore?.boxSelectedEgg?.egg?.name}
      </div>
      <div className='bg-[#F6F6FA] rounded-2xl my-2 p-2 w-1/2 relative'>
        <img src={EggMoneyStore?.boxSelectedEgg?.egg?.img} alt='egg' />
        <div
          className='absolute right-2 top-2'
          onClick={() => {
            bookmarkEgg()
          }}
        >
          <img
            className='w-8 h-8'
            src={`../images/eggMoney/bookmark${
              !EggMoneyStore?.boxSelectedEgg?.bookmarked ? 'Empty' : ''
            }.png`}
            alt='bookmark'
          />
          {EggMoneyStore?.allBookmarks?.length <= 0 && (
            <div className='absolute top-1/2 -translate-y-1/2 -right-[19vw] flex flex-row items-center justify-center font-bold text-sm text-[#FF5951] bg-[#FFDFDF] p-2 py-1 rounded-full'>
              <FiArrowLeft className='mr-[2px] text-lg' />
              {` 찜하기`}
            </div>
          )}
        </div>
      </div>

      <Button
        clickable={EggMoneyStore?.boxSelectedEgg?.count > 0}
        text='대표 에그로 설정하기'
        style={{
          paddingBottom: 8,
          paddingTop: 8,
          borderRadius: 8,
        }}
        onClick={async () => {
          const res = await backendApis.eggMoneySet({
            set: {
              mainEgg: toJS(EggMoneyStore?.boxSelectedEgg?.egg),
            },
            collection: 'eggMoney_user',
          })
          if (res.status === 200) {
            ToastStore.toastOn({
              type: 'success',
              message: '대표 에그로 설정되었어요',
              duration: 800,
            })
            THModalStore.close()
          } else {
            ToastStore.networkError()
          }
        }}
      />

      <div className='flex flex-row w-full justify-center items-center space-x-4 mt-4'>
        {EggMoneyStore?.boxSelectedEgg?.egg?.name === '-' && (
          <div className='bg-[#F6F6FA] text-[#5D5F68] px-4 rounded-md font-medium py-[2px]'>
            -
          </div>
        )}
        {EggMoneyStore?.boxSelectedEgg?.count -
          (EggMoneyStore?.boxSelectedEgg?.reservedCount ?? 0) >
          0 && (
          <div className='bg-[#F6F6FA] text-[#5D5F68] px-4 rounded-md font-medium py-[2px]'>
            판매 가능{' '}
            <span>
              {EggMoneyStore?.boxSelectedEgg?.count -
                (EggMoneyStore?.boxSelectedEgg?.reservedCount ?? 0) ?? '0'}
            </span>
            개
          </div>
        )}
        {EggMoneyStore?.boxSelectedEgg?.reservedCount > 0 && (
          <div className='bg-[#F6F6FA] text-[#5D5F68] px-4 rounded-md font-medium  py-[2px]'>
            판매 대기{' '}
            <span>{EggMoneyStore?.boxSelectedEgg?.reservedCount}</span>개
          </div>
        )}
        {(EggMoneyStore?.boxSelectedEgg?.count ?? 0) <= 0 && (
          <div className='bg-[#F6F6FA] text-[#5D5F68] px-4 rounded-md font-medium py-[2px]'>
            에그를 구매해보세요!
          </div>
        )}
      </div>
      <div className='w-full flex justify-center items-center my-4 space-x-4 px-4'>
        <div className='flex-1 relative'>
          <Button
            className='px-4 flex-1'
            style={{
              paddingTop: 12,
              paddingBottom: 12,
              background: '#E3F2FF',
              color: '#5498FF',
            }}
            clickable
            text='자세히 보기'
            onClick={() => {
              EggMoneyStore.set('egg', EggMoneyStore?.boxSelectedEgg?.egg)
              navigate('/eggMoney/eggDetail')
              THModalStore.close()
            }}
          />
        </div>
        <div className='flex-1 relative'>
          <Button
            className='px-4 flex-1'
            style={{
              paddingTop: 12,
              paddingBottom: 12,
              background:
                EggMoneyStore?.boxSelectedEgg?.count > 0
                  ? '#5498FF'
                  : '#FF5951',
            }}
            clickable={EggMoneyStore?.boxSelectedEgg?.egg?.name !== '-'}
            text={buttonText}
            onClick={() => {
              EggMoneyStore.set('egg', EggMoneyStore?.boxSelectedEgg?.egg)
              navigate('/eggMoney/eggTrade')
              THModalStore.close()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(EggMoneyBoxDetail)
