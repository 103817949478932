import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'

const AnimatedNumber = observer(
  ({ targetNumber = 0, interval = 1, className, delay = 0 }) => {
    const [currentValue, setCurrentValue] = useState(0)
    const startValue = useRef(0)

    useEffect(() => {
      let current = startValue.current
      const diff = targetNumber - current
      const step = Math.sign(diff) * Math.ceil(Math.abs(diff) / 100)
      let intervalId

      setTimeout(() => {
        intervalId = setInterval(() => {
          if (
            (step > 0 && current < targetNumber) ||
            (step < 0 && current > targetNumber)
          ) {
            current += step
            setCurrentValue(current)
          } else {
            clearInterval(intervalId)
            setCurrentValue(targetNumber)
            startValue.current = targetNumber
          }
        }, interval)
      }, delay)

      return () => clearInterval(intervalId)
    }, [targetNumber, delay]) // Also watch targetNumber for changes

    return <div className={className}>{commaNumber(currentValue)}</div>
  },
)

export default AnimatedNumber
