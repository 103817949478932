import { observer } from 'mobx-react-lite'
import React from 'react'

import {
  CloseThin,
  MoneyTreeCompleteIllustration,
  MoneyTreeIllustration,
} from '../../images/dailyCheckIn'

const MoneyTreeFriendInviteReceived = observer(
  ({ visible, close, ...params }) => {
    const Title = () => {
      if (params?.condition === 'matchedToday') {
        return (
          <div className='py-5' style={{ fontSize: '6vw' }}>
            앗 오늘 이미 만난 친구에요
          </div>
        )
      }
      if (params?.condition === 'available') {
        return (
          <div className='py-5' style={{ fontSize: '6vw' }}>
            핸드폰을 흔들어보세요!
          </div>
        )
      }
      if (params?.condition === 'participatedToday') {
        return (
          <div className='py-5' style={{ fontSize: '6vw' }}>
            오늘 이미 참여했어요
          </div>
        )
      }
      if (params?.condition === 'invitorMatchedFull') {
        return (
          <div className='py-5' style={{ fontSize: '6vw' }}>
            앗 이미 미션을 완료한 친구에요
          </div>
        )
      }
    }

    const TextComponent = () => {
      if (params?.condition === 'matchedToday') {
        return (
          <div
            style={{
              fontSize: '4vw',
              textAlign: 'center',
              color: '#517469',
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <div className='h-1' />
            <div>{params?.userName}님과는 오늘 이미 함께 흔들었어요.</div>
            <div>내일 다시 흔들어보세요!</div>
          </div>
        )
      }
      if (params?.condition === 'participatedToday') {
        return (
          <div
            style={{
              fontSize: '4vw',
              textAlign: 'center',
              color: '#517469',
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <div className='h-1' />
            <div>텔레파시 흔들기는 하루에 한번만 참여할 수 있어요.</div>
            <div>내일 다시 링크에 참여해보세요!</div>
          </div>
        )
      }
      if (params?.condition === 'available') {
        return (
          <div
            style={{
              fontSize: '4vw',
              textAlign: 'center',
              color: '#517469',
              paddingTop: 4,
              paddingBottom: 4,
              marginTop: 12,
            }}
          >
            <div className='flex flex-row justify-center items-center'>
              <div className='px-2 py-1 mr-1 flex flex-row bg-[#2CCC77] text-white rounded-full'>
                {params?.profileUrl && (
                  <div className='w-6 mr-1 rounded-full overflow-hidden border border-white'>
                    <img src={params?.profileUrl} alt=' ' />
                  </div>
                )}
                {params?.userName.length < 5
                  ? params?.userName
                  : `${params?.userName.slice(0, 4)}...`}
              </div>
              <div>님과 텔레파시가 연결되어</div>
            </div>
            <div className='h-1' />
            <div>큰 보상을 받을 수 있어요</div>
          </div>
        )
      }
      if (params?.condition === 'invitorMatchedFull') {
        return (
          <div
            style={{
              fontSize: '4vw',
              textAlign: 'center',
              color: '#517469',
              paddingTop: 4,
              paddingBottom: 4,
              marginTop: 12,
            }}
          >
            <div className='flex flex-row justify-center items-center'>
              <div>
                {params?.userName.length < 5
                  ? params?.userName
                  : `${params?.userName.slice(0, 4)}...`}
                님은 이미 오늘 미션을 완료했어요.
              </div>
            </div>
            <div className='h-1' />
            <div>다른 링크에 참여해보세요!</div>
          </div>
        )
      }
    }

    const ButtonComponent = () => {
      return (
        <button
          type='button'
          className='w-5/6 z-40 mt-2 py-3 bg-[#008E5B] rounded-xl text-lg text-white'
          style={{ top: '65%', left: '12%' }}
          onClick={() => {
            close()
          }}
        >
          확인
        </button>
      )
    }

    return (
      <div>
        {/* 딤드 배경 */}
        <button
          type='button'
          className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-900 opacity-70 z-20'
          onClick={close}
          aria-label='Close Button'
        />
        {/* 모달 상자 */}
        <div
          className='fixed left-1/2 py-6 z-30  bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
          style={{ top: '52%', width: '90%', height: '50%' }}
        />

        <button
          type='button'
          className='fixed p-2 z-40  rounded-full'
          style={{ right: '4%', top: '20%' }}
          onClick={close}
        >
          <CloseThin className='w-9 h-9' />
        </button>

        {/* 모달 내용 */}
        <div
          style={{ top: '25%', left: '5%', width: '90%', height: '50%' }}
          className='p-4 flex flex-col fixed items-center justify-between z-30'
        >
          <Title />
          {(params?.condition === 'available' ||
            params?.condition === 'matchedToday') && (
            <MoneyTreeIllustration className='w-32 h-32' />
          )}
          {(params?.condition === 'participatedToday' ||
            params?.condition === 'invitorMatchedFull') && (
            <MoneyTreeCompleteIllustration className='w-32 h-32' />
          )}
          <TextComponent />
          <ButtonComponent />
        </div>
      </div>
    )
  },
)

export default MoneyTreeFriendInviteReceived
