import { memo, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { viewerStore } from '../../store'
import {
  DocumentAlign,
  FontFamily,
  FontSize,
  LineHeight,
  Padding,
} from '../../types/viewerOptions'
import {
  documentAlignCSS,
  fontFamilyCSS,
  fontSizeCSS,
  lineHeightCSS,
  paddingCSS,
} from '../../utils/viewerOptions'

interface PureHTMLRendererProps {
  innerRef: React.RefObject<HTMLDivElement>
  coverImgUrl: string | null
  htmlString: string
  documentAlign: DocumentAlign
  maxPage: number
  fontFamily: FontFamily
  fontSize: FontSize
  lineHeight: LineHeight
  padding: Padding
}

export const PureHTMLRenderer = memo((props: PureHTMLRendererProps) => {
  useEffect(() => {
    // 컴포넌트 마운트 된 이후 다음 프레임에 로드 완료를 알립니다.
    requestAnimationFrame(() => (viewerStore.isLoaded = true))
  }, [])

  return (
    <>
      <meta
        name='viewport'
        content='width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
      />
      {props.coverImgUrl && (
        <CoverImage
          src={props.coverImgUrl}
          documentAlign={props.documentAlign}
          alt='cover-image'
          // Shadow DOM 하위 click 이벤트가 간헐적으로 전파되지 않는 이슈(iOS Safari)
          onClick={() => {}}
        />
      )}

      <ContentContainer
        ref={props.innerRef}
        dangerouslySetInnerHTML={{ __html: props.htmlString }}
        // Shadow DOM 하위 click 이벤트가 간헐적으로 전파되지 않는 이슈(iOS Safari)
        onClick={() => {}}
        documentAlign={props.documentAlign}
        fontFamily={props.fontFamily}
        fontSize={props.fontSize}
        lineHeight={props.lineHeight}
        padding={props.padding}
        hasCover={!!props.coverImgUrl}
      />
    </>
  )
})

const CoverImage = styled.img<{ documentAlign: DocumentAlign }>`
  ${({ documentAlign }) =>
    documentAlign === DocumentAlign.Horizontal
      ? css`
          position: absolute;
          left: 0px;
          top: 0px;
          width: calc(100vw - 48px);
          height: calc(100vh - 110px);
          padding: 48px 24px 48px;
          object-fit: contain;
          object-position: 0% 0%;
        `
      : css`
          width: calc(100vw - 48px);
          padding: 60px 24px 50px;
          object-fit: contain;
          object-position: 0% 0%;
        `}
`

const ContentContainer = styled.div<{
  documentAlign: DocumentAlign
  fontFamily: FontFamily
  fontSize: FontSize
  lineHeight: LineHeight
  padding: Padding
  hasCover: boolean
}>`
  word-break: break-word;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  ${({
    documentAlign,
    fontFamily,
    fontSize,
    lineHeight,
    padding,
    hasCover,
  }) => css`
    ${documentAlignCSS({
      documentAlign,
      padding,
      hasCover,
    })};
    ${fontFamilyCSS(fontFamily)};
    ${fontSizeCSS(fontSize)};
    ${lineHeightCSS(lineHeight)};
    ${paddingCSS(padding)};
  `}
`
