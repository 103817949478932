import { createStore, get, set } from 'idb-keyval'
import { useEffect } from 'react'

import { viewerStore } from '../store'
import { DocumentAlign } from '../types/viewerOptions'
import { throttle } from '../utils/throttle'

const LastScrollDB = createStore('viewer-database', 'scroll-store')

const storeLastScroll = (chapterId: number, scrollPosition: number) => {
  return set(chapterId, scrollPosition, LastScrollDB)
}

const retrieveLastScroll = async (chapterId: number) => {
  try {
    const storedScroll = await get<number>(chapterId, LastScrollDB)
    return storedScroll ?? 0
  } catch {
    console.warn('Error retrieving last scroll position, defaulting to 0')
    return 0
  }
}

const MEMO_THROTTLE_DELAY = 100

export const useScrollPositionMemo = (params: { chapterId: number }) => {
  useEffect(() => {
    const viewport = viewerStore.viewport

    if (!viewport) return

    const handleSaveLastViewedPage = throttle(() => {
      // console.log('params.chapterId: ', params.chapterId);
      const scrollPosition =
        viewerStore.options.documentAlign === DocumentAlign.Horizontal
          ? viewport.scrollLeft
          : viewport.scrollTop
      const threshold =
        viewerStore.options.documentAlign === DocumentAlign.Horizontal
          ? viewport.clientWidth
          : viewport.clientHeight

      if (scrollPosition > threshold) {
        storeLastScroll(params.chapterId, scrollPosition ?? 0)
      }
      storeLastScroll(params.chapterId, scrollPosition ?? 0)
    }, MEMO_THROTTLE_DELAY)

    viewport.addEventListener('scroll', handleSaveLastViewedPage)
    ;(async () => {
      const lastViewedScroll = await retrieveLastScroll(params.chapterId)
      const scrollDirection =
        viewerStore.options.documentAlign === DocumentAlign.Horizontal
          ? 'left'
          : 'top'

      viewport.scrollTo({
        [scrollDirection]: lastViewedScroll,
      })
    })()

    return () =>
      viewport.removeEventListener('scroll', handleSaveLastViewedPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
