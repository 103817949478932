import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { Close } from 'src/images/dailyCheckIn'
import backendApis from 'src/utils/backendApis'

const TeamCheckInSearchTeamModal = observer(
  ({ visible, close, reload, userInfo, ...params }) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const inputRef = useRef(null)

    const Title = () => {
      return <div className='text-xl font-bold pt-3'>팀 참여하기</div>
    }

    const SearchComponent = () => {
      const handleSearch = async () => {
        const result = await backendApis.searchTeamName(searchTerm)
        setSearchResults(result?.data)
      }

      const handleInputChange = (event) => {
        setSearchTerm(event.target.value)
      }

      const handleButtonClick = () => {
        if (searchTerm !== '') {
          handleSearch()
        }
      }
      useEffect(() => {
        inputRef.current.focus()
      }, [])
      return (
        <div className='border flex flex-row w-4/5 h-12 mt-4'>
          <input
            type='text'
            placeholder='Search'
            value={searchTerm}
            onInput={handleInputChange}
            ref={inputRef}
            className='flex '
          />
          <button className='flex' onClick={handleButtonClick}>
            검색하기
          </button>
        </div>
      )
    }

    const SearchResultComponent = () => {
      const TeamResult = ({ teamData }) => {
        const handleJoinTeam = async () => {
          const isNewUserCheckIn = await backendApis.getIsCheckInInviteNewUser(
            userInfo.userId,
          )
          const result = await backendApis.joinTeamCheckIn(
            teamData?._id,
            isNewUserCheckIn?.data,
          )
          if (result.status === 200) {
            reload()
            close()
          } else {
            alert('알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요')
          }
        }

        return (
          <div className='flex flex-row justify-between text-xs'>
            <div className='flex flex-col'>
              <div className='font-bold'>{teamData?.teamName}</div>
              <div className='flex items-center py-2'>
                <img
                  src={teamData?.teamInfo[0]?.profileUrl}
                  alt={teamData?.teamInfo[0]?.userName}
                  className='w-4 h-4 rounded-full'
                />
                <span className='ml-1'>
                  {teamData?.teamInfo[0]?.userName}
                  {teamData?.teamInfo?.length - 1 > 0
                    ? ` 외 ${teamData?.teamInfo?.length - 1}명`
                    : ''}
                </span>
              </div>
            </div>
            <button
              onClick={handleJoinTeam}
              className='px-2 py-1 bg-red-500 text-white font-bold rounded-lg'
            >
              참여하기
            </button>
          </div>
        )
      }

      return (
        <>
          {searchResults && searchResults !== 'no result' && (
            <ul className='w-4/5 mt-4'>
              {searchResults.map((result) => (
                <li
                  key={result._id}
                  className='border border-gray-300 rounded-md p-4 mb-4'
                >
                  <TeamResult teamData={result} />
                </li>
              ))}
            </ul>
          )}
          {searchResults === 'no result' && <div>찾는 팀 이름이 없어요</div>}
        </>
      )
    }
    const JoinButton = () => {
      return (
        <button
          onClick={() => {}}
          className='w-4/5 bg-red-500 py-3 px-2 rounded-lg justify-center align-center text-white text-base font-bold'
        >
          팀 참여하기
        </button>
      )
    }

    return (
      <div>
        {/* 딤드 배경 */}
        <button
          className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-10'
          onClick={close}
        />
        {/* 모달 상자 */}
        <div
          className='fixed left-1/2 py-6 z-40 bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
          style={{ top: '52%', width: '90%', height: '64%', zIndex: 11 }}
        />

        <button
          className='fixed z-30'
          style={{ left: '86%', top: '16%' }}
          onClick={close}
        >
          <Close className='w-6 h-6' />
        </button>

        {/* 모달 내용 + 받기 버튼 */}
        <div
          style={{ top: '22%', width: '100%', height: '60%' }}
          className='flex flex-col fixed items-center z-30'
        >
          <div className='flex flex-col w-full justify-between items-center'>
            <Title />
            <SearchComponent />
          </div>
          <SearchResultComponent />
          {/* <JoinButton /> */}
        </div>
      </div>
    )
  },
)

export default TeamCheckInSearchTeamModal
