import styled from 'styled-components';

export const Flex = () => {};

Flex.Column = styled.div`
  display: flex;
  flex-direction: column;
`;

Flex.Row = styled.div`
  display: flex;
  flex-direction: row;
`;
