import { observer } from 'mobx-react-lite'
import React from 'react'
import { PigFoodColored, TutorialTouch } from 'src/images/dailyCheckIn'

const CheckInPigTutorial = observer(({ visible, close, afterClick }) => {
  async function onClicked() {
    close()
    afterClick()
  }

  return (
    <div>
      {/* 딤드 배경 */}
      <button
        style={{ height: '78vh' }}
        className='w-full fixed left-0 top-0 flex items-center justify-center bg-gray-500 opacity-70 z-10'
        onClick={async () => {
          onClicked()
        }}
      />
      <div className='flex flex-row'>
        <button
          style={{ width: '68vw', height: '16vh', top: '78vh' }}
          className='fixed left-0 top-0 flex items-center justify-center bg-gray-500 opacity-70 z-10'
          onClick={async () => {
            onClicked()
          }}
        />
        <button
          style={{ width: '32vw', height: 140, top: '78vh' }}
          className='fixed right-0 flex items-center justify-center opacity-100 z-10'
          onClick={async () => {
            onClicked()
          }}
        />
      </div>
      <button
        style={{ height: '80%', top: '94vh' }}
        className='w-full fixed left-0 flex items-center justify-center bg-gray-500 opacity-70 z-10'
        onClick={async () => {
          onClicked()
        }}
      />
      {/* 모달 상자 */}
      <button
        style={{ left: '8%', top: '40%', borderRadius: 8, height: 230 }}
        className='flex flex-col items-center justify-center w-5/6 fixed bg-white z-20 text-black'
        onClick={async () => {
          onClicked()
        }}
      >
        <PigFoodColored className='w-16 h-full pb-6' />
        <div className='pb-2 font-bold text-2xl'>돈돈이에게 밥을 주세요!</div>
        <div className='font-bold text-base'>
          아래 버튼을 누르면 줄 수 있어요
        </div>
      </button>
      <button
        className='fixed z-30'
        style={{ left: '86%', top: '88vh' }}
        onClick={async () => {
          onClicked()
        }}
      >
        <TutorialTouch className='animate-pulse w-8 h-full' />
      </button>
    </div>
  )
})

export default CheckInPigTutorial
