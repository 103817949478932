import React, { useEffect, useState } from 'react'

const GaugeTimer = ({
  endDate,
  totalHour = 2,
  totalMin = 0,
  onTimerEnd = () => {},
  normalColor = 'blue-500',
  urgentColor = 'red-500',
}) => {
  const targetTime = new Date(endDate).getTime()
  const [remainingTime, setRemainingTime] = useState(getRemainingTime())

  useEffect(() => {
    const timer = setInterval(() => {
      const newRemainingTime = getRemainingTime()
      setRemainingTime(newRemainingTime)
      if (newRemainingTime === 0) {
        clearInterval(timer)
        onTimerEnd()
      }
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [onTimerEnd])

  function getRemainingTime() {
    const currentTime = new Date().getTime()
    const difference = targetTime - currentTime
    return Math.max(0, difference)
  }

  function formatTime(ms) {
    const seconds = Math.floor((ms / 1000) % 60)
    const minutes = Math.floor((ms / 1000 / 60) % 60)
    const hours = Math.floor((ms / 1000 / 3600) % 24)
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  const totalMilliseconds = totalHour * 60 * 60 * 1000 + totalMin * 60 * 1000
  const gaugeWidth = Math.max(
    (remainingTime / totalMilliseconds) * 100 || 100,
    5,
  )

  const gaugeColorOption =
    gaugeWidth <= 20 ? `bg-${urgentColor}` : `bg-${normalColor}`
  const textColorOption =
    gaugeWidth <= 20 ? `text-${urgentColor}` : `text-${normalColor}`

  return (
    <div className='flex items-center justify-between flex-1 p-2 rounded-lg'>
      <div className='flex items-center flex-1 h-4 mr-3 bg-gray-300 rounded-xl'>
        <div
          className={`h-full rounded-xl transition-width duration-500 ${gaugeColorOption}`}
          style={{ width: `${Math.max(gaugeWidth, 10)}%` }}
        />
      </div>
      <div
        className={`w-1/4 text-right text-base font-bold ${textColorOption}`}
      >
        {formatTime(remainingTime)}
      </div>
    </div>
  )
}

export default GaugeTimer
