import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import THUserStore from '../../../stores/treasureHunt/THUserStore'

const THAlert = observer(() => {
  const [img, setImg] = useState('grandSuccess.png')

  useEffect(() => {
    if (THUserStore?.alert?.isOn) {
      setImg(`${THUserStore?.alert?.type}.png`)
      setTimeout(() => {
        THUserStore?.set('alert.isOn', false)
      }, 3000)
    }
  }, [THUserStore?.alert?.type, THUserStore?.alert?.isOn])

  return (
    <div
      className={`fixed w-full h-full top-0 left-0 z-50 flex justify-center items-center
      pointer-events-none
      ${THUserStore?.alert?.isOn && 'bg-black/20'}
      `}
    >
      <img
        className={`
w-[60vw] absolute left-1/2 top-1/4 -translate-x-1/2 -translate-y-1/2 transform transition-all duration-300
${
  THUserStore?.alert?.isOn
    ? 'scale-100 left-1/2 translate-x-[-50%] translate-y-[-50%] '
    : 'scale-0 left-1/2 translate-x-[-50%] translate-y-[-50%]'
}`}
        src={`../images/treasureHunt/alert/${img}`}
        alt='alert'
      />
    </div>
  )
})

export default THAlert
