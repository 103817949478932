import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import { viewerStore } from '../../store'
import {
  DocumentAlign,
  FontFamily,
  FontSize,
  LineHeight,
  Padding,
  TransitionEffect,
} from '../../types/viewerOptions'
import { BottomSheet } from '../Bottomsheet'
import { Spacing } from '../Spacing/Spacing'
import { OptionCounter } from './OptionCounter'
import { OptionSelector } from './OptionSelector'
import { OptionSlider } from './OptionSlider'
import { OptionThemeSelector } from './OptionThemeSelector'

interface ControllerBottomsheetProps {
  isOpen: boolean
  onClose: () => void
}

export const ControllerBottomsheet = observer(
  (props: ControllerBottomsheetProps) => {
    return (
      <BottomSheet isOpen={props.isOpen} onClose={props.onClose}>
        <Wrapper>
          <Reset onClick={() => viewerStore.resetViewerOptions()}>
            설정 초기화
          </Reset>

          {/* TODO: 밝기 조절 브릿지 */}
          {/* <OptionSlider
            label='화면 밝기'
            value={viewerStore.options.brightness}
            onChange={(brightness) => viewerStore.setOption('brightness', brightness)}
            min={0}
            max={100}
          /> */}

          <Spacing size={12} />
          <Divider />
          <Spacing size={12} />

          <OptionSelector
            label='넘김 방식'
            options={Object.values(DocumentAlign)}
            selectedOption={viewerStore.options.documentAlign}
            onSelect={(align) => viewerStore.setOption('documentAlign', align)}
          />
          <OptionSelector
            label='넘김 효과'
            options={Object.values(TransitionEffect)}
            selectedOption={viewerStore.options.transitionEffect}
            onSelect={(effect) =>
              viewerStore.setOption('transitionEffect', effect)
            }
          />
          <OptionThemeSelector
            label='테마'
            value={viewerStore.options.theme}
            onSelect={(theme) => viewerStore.setOption('theme', theme)}
          />

          <Spacing size={12} />
          <Divider />
          <Spacing size={12} />

          <OptionSelector
            label='글꼴'
            options={Object.values(FontFamily)}
            selectedOption={viewerStore.options.fontFamily}
            onSelect={(fontFamily) =>
              viewerStore.setOption('fontFamily', fontFamily)
            }
          />

          <OptionCounter
            label='글자 크기'
            value={viewerStore.options.fontSize}
            onChange={(fontSize) => viewerStore.setOption('fontSize', fontSize)}
            min={FontSize.XSmall}
            max={FontSize.XLarge}
          />

          <OptionCounter
            label='줄 간격'
            value={viewerStore.options.lineHeight}
            onChange={(lineHeight) =>
              viewerStore.setOption('lineHeight', lineHeight)
            }
            min={LineHeight.XSmall}
            max={LineHeight.XLarge}
          />

          <OptionCounter
            label='페이지 여백'
            value={viewerStore.options.padding}
            onChange={(pagePadding) =>
              viewerStore.setOption('padding', pagePadding)
            }
            min={Padding.XSmall}
            max={Padding.XLarge}
          />

          <Spacing size={12} />
        </Wrapper>
      </BottomSheet>
    )
  },
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eeeff3;
`

const Reset = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  color: #71737c;

  padding: 20px 16px 8px;
  display: flex;
  justify-content: flex-end;
`
