import { observer } from 'mobx-react-lite'
import React from 'react'

import {
  CloseThin,
  DefaultProfileImage,
  GreenPlus,
} from '../../images/dailyCheckIn'
import UserStore from '../../stores/UserStore'

const MoneyTreeFriendInvite = observer(
  ({ visible, close, userInfo, receiveReward, ...params }) => {
    const Title = () => {
      return (
        <div className='py-4 mt-2' style={{ fontSize: '5vw' }}>
          친구랑 텔레파시로 흔들기
        </div>
      )
    }

    const TeammateComponent = ({ userData }) => {
      return (
        <div className='w-1/3 mx-3 my-2 flex flex-col items-center justify-center'>
          <div
            className={`w-12 h-12 mb-1 rounded-full overflow-hidden ${
              userData?.isRewarded ? '' : 'border border-[#4BDD8F]'
            }`}
          >
            {userData?.profileUrl && <img src={userData?.profileUrl} alt=' ' />}
            {!userData?.profileUrl && (
              <DefaultProfileImage className='w-12 h-12' />
            )}
          </div>
          <div style={{ fontSize: '2.8vw' }}>
            {userData?.userName.length < 5
              ? userData?.userName
              : `${userData?.userName.slice(0, 4)}...`}
          </div>
          <button
            type='button'
            style={{ color: 'white', fontSize: '3vw' }}
            disabled={userData?.isRewarded}
            onClick={async () => {
              close()
              if (userData?.isRewarded) return
              receiveReward(
                userData?.userId,
                userData?.userName,
                userData?.profileUrl,
              )
            }}
          >
            {userData.isRewarded ? (
              <div
                style={{
                  background: '#B5CFC9',
                  border: '2px',
                  borderRadius: 12,
                }}
                className='w-full px-3 py-1 my-1'
              >
                완료
              </div>
            ) : (
              <div
                style={{
                  background:
                    'linear-gradient(180deg, #8DE297 0%, #00D387 100%)',
                  border: '2px',
                  borderColor: 'rgba(0, 0, 0, 0.03)',
                  borderRadius: 12,
                }}
                className='w-full px-3 py-1 my-1'
              >
                보상받기
              </div>
            )}
          </button>
        </div>
      )
    }
    const EmptyComponent = () => {
      return (
        <button
          type='button'
          className='flex w-1/3 mx-3 my-2 rounded-full justify-center'
          onClick={() => {
            window.location.href = `#openBottomShareSheet.${JSON.stringify({
              code: 'MoneyTreeInvite',
              shareParams: UserStore?.userInfo?._id,
            })}`
          }}
        >
          <GreenPlus className='w-12' />
        </button>
      )
    }

    const TeamListComponent = () => {
      return (
        <div className='w-5/6 py-2 flex flex-row items-start justify-center rounded-xl text-[#525052] bg-[#F6F7F5]'>
          {params.inviteLog?.map((member) => (
            <TeammateComponent key={member?.userId} userData={member} />
          ))}
          {Array.from({ length: 3 - params.inviteLog?.length })?.map(() => (
            <EmptyComponent key={Math.random()} />
          ))}
        </div>
      )
    }

    const TextComponent = () => {
      return (
        <div
          style={{
            fontSize: '4vw',
            textAlign: 'center',
            color: '#102F29',
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <div className='flex flex-row'>
            친구가 링크로 들어오면 흔들기 보너스{' '}
            <div className='text-red-500 ml-1'>5배!</div>
          </div>
          <div className='h-1' />
          <div style={{ fontSize: '4vw', color: '#517469' }}>
            새친구면 보너스를 <span className='text-red-500'> 100배</span>로
            받아요
          </div>
        </div>
      )
    }

    const ButtonComponent = () => {
      return (
        <button
          type='button'
          className='w-5/6 z-40 mt-2 py-3 bg-[#008E5B] rounded-xl text-lg text-white'
          style={{ top: '65%', left: '12%' }}
          onClick={() => {
            if (params?.inviteLog?.length >= 3) {
              close()
            } else {
              window.location.href = `#openBottomShareSheet.${JSON.stringify({
                code: 'MoneyTreeInvite',
                shareParams: UserStore?.userInfo?._id,
              })}`
            }
          }}
        >
          {params?.inviteLog?.length >= 3 ? '확인' : '초대하기'}
        </button>
      )
    }

    return (
      <div>
        {/* 딤드 배경 */}
        <button
          type='button'
          className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-900 opacity-70 z-20'
          onClick={close}
          aria-label='Close Button'
        />
        {/* 모달 상자 */}
        <div
          className='fixed left-1/2 py-6 z-30  bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
          style={{ top: '52%', width: '90%', height: '50%' }}
        />

        <button
          type='button'
          className='fixed p-2 z-40  rounded-full'
          style={{ right: '4%', top: '20%' }}
          onClick={close}
        >
          <CloseThin className='w-9 h-9' />
        </button>

        {/* 모달 내용 */}
        <div
          style={{ top: '25%', left: '5%', width: '90%', height: '50%' }}
          className='p-4 flex flex-col fixed items-center justify-between z-30'
        >
          <Title />
          <TeamListComponent />
          <TextComponent />
          <ButtonComponent />
        </div>
      </div>
    )
  },
)

export default MoneyTreeFriendInvite
