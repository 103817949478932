import React, { useEffect, useRef, useState } from 'react'
import { useSearchParam } from 'react-use'
import useDebounce from 'src/components/useDebounce'
import backendApis from 'src/utils/backendApis'
import { URLList } from 'src/utils/coffeeURLList'

const AlfarmCoffeeBridgePage = () => {
  const debounce = useDebounce()
  const timeout = useRef()
  const [opacity, setOpacity] = useState(1)
  const channelType = useSearchParam('channelType')

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const onScroll = () => {
    setOpacity(0.4)
    debounce(stopScroll, 10)
    clearTimeout(timeout.current)
  }

  const stopScroll = () => {
    timeout.current = setTimeout(() => {
      setOpacity(1)
    }, 200)
  }

  const logEvent = async (eventLocation, eventAction) => {
    await backendApis.recordEventLogForWeb('POST', {
      eventLocation,
      eventAction,
    })
  }

  const inputButton = async () => {
    const eventLocation = channelType?.includes('DirectTrial')
      ? 'direct_alfarmCoffeeBridgePage'
      : 'alfarmCoffeeBridgePage'

    logEvent(eventLocation, 'click start alfarm button')
    window.location.href = URLList[channelType ?? 'basic'] ?? URLList.basic
  }

  const Button = ({
    bottom,
    button,
    width,
    height,
    opacity,
    onClick,
    style,
  }) => {
    return (
      <button
        style={{
          marginBottom: bottom,
          opacity,
          transition: 'opacity 500ms ease',
          ...style,
        }}
        onClick={onClick}
      >
        <img
          style={{
            width,
            zIndex: 3,
            height,
            objectFit: 'contain',
          }}
          src={button}
        />
      </button>
    )
  }
  return (
    <>
      <div style={{ backgroundColor: '#60953C' }} onScroll={() => onScroll()}>
        <img
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alfarmCoffeeBridge/alfarmBridge1.png'
        />
        <img
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alfarmCoffeeBridge/alfarmBridge2.png'
        />
        <img
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alfarmCoffeeBridge/alfarmBridge3.png'
        />
        <img
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alfarmCoffeeBridge/alfarmBridge4.png'
        />
        <img
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alfarmCoffeeBridge/alfarmBridge5.png'
        />

        <img
          style={{
            height: '100%',
            width: '100%',
          }}
          src='/images/alfarmCoffeeBridge/alfarmBridge7.png'
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100px',
            // border: '1px solid black',
            position: 'fixed',
            bottom: 8,
          }}
        >
          <Button
            bottom='32px'
            button='/images/alfarmCoffeeBridge/button.png'
            width='80vw'
            height='14vw'
            opacity={opacity}
            onClick={async () => {
              if (opacity <= 0.5) return
              debounce(inputButton, 500)
            }}
          />
        </div>
        <Button
          bottom='37vw'
          button='/images/alfarmCoffeeBridge/button2.png'
          width='20vw'
          height='20vw'
          onClick={() => {
            window.location.href = `/alfarmTrial?channelType=${channelType}`
          }}
          style={{ right: 16, position: 'fixed', bottom: 0 }}
          className='shake-bottom'
        />
      </div>
    </>
  )
}

export default AlfarmCoffeeBridgePage
