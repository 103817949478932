import commaNumber from 'comma-number'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useSearchParam } from 'react-use'

import BlackTextTimer from '../components/BlackTextTimer'
import Header from '../components/header'
import Layout from '../components/layout'
import useDebounce from '../components/useDebounce'
import MandarinShareNudgeModal from '../modals/MandarinShareNudgeModal'
import NewUserNudgeModal from '../modals/NewUserNudgeModal'
import backendApis from '../utils/backendApis'

let isButtonDisable = false
let isPurchasableStatus = false

const userBadge = (userType, isNewUser) => {
  if (isNewUser) {
    return (
      <div
        style={{
          backgroundColor: '#EE3E3E',
          // height: 20,
          color: 'white',
          borderRadius: 4,
          fontSize: 12,
          boxSizing: 'border-box',
          padding: 4,
          fontWeight: 'bold',
          marginLeft: 4,
        }}
      >
        새로운 친구
      </div>
    )
  }

  if (userType === 2) {
    return (
      <div
        style={{
          backgroundColor: '#EE3E3E',
          // height: 20,
          color: 'white',
          borderRadius: 4,
          fontSize: 12,
          boxSizing: 'border-box',
          padding: 4,
          fontWeight: 'bold',
          marginLeft: 4,
        }}
      >
        새로운 친구
      </div>
    )
  }
  if (userType === 3) {
    return (
      <div
        style={{
          backgroundColor: '#C2185B',
          // height: 20,
          color: 'white',
          borderRadius: 4,
          fontSize: 12,
          boxSizing: 'border-box',
          padding: 4,
          fontWeight: 'bold',
          marginLeft: 4,
        }}
      >
        돌아온 친구
      </div>
    )
  }
  if (userType === 4) {
    return (
      <div
        style={{
          backgroundColor: '#09AF00',
          // height: 20,
          color: 'white',
          borderRadius: 4,
          fontSize: 12,
          boxSizing: 'border-box',
          padding: 4,
          fontWeight: 'bold',
          marginLeft: 4,
        }}
      >
        행운의 친구
      </div>
    )
  }
}

function ZeroWonStoreItemPage() {
  const alert = useAlert()

  const [ongoingZeroWonStoreTeamInfo, setOngoingZeroWonStoreTeamInfo] =
    useState()
  const token = useSearchParam('token')
  const [canPurchaseConfig, setCanPurchaseConfig] = useState({})
  const [isAlreadyExist, setIsAlreadyExist] = useState()
  const debounce = useDebounce()
  const [showNewUserNudgeModal, setShowNewUserNudgeModal] = useState(false)
  const [showMandarinShareNudgeModal, setShowMandarinShareNudgeModal] =
    useState(false)

  useEffect(() => {
    const getZeroWonStoreTeamInfo = async () => {
      const result = await backendApis.getZeroWonStoreTeamInfo(token)
      if (result?.status === 200) {
        setOngoingZeroWonStoreTeamInfo(result?.data)
        // dev에서의 감귤 아이디 6363d364e252a3fc17b592d4 production에서는 6363d220f17fd2dd1a6f93cb
        if (
          result?.data?.hostUserInfo?.isNewUser &&
          result?.data?.itemInfo?._id === '6363d220f17fd2dd1a6f93cb' &&
          result?.data?.remainedPrice === result?.data?.goalPrice * 0.05
        ) {
          setShowNewUserNudgeModal(true)
        }
        if (result?.data?.itemInfo?.canPurchaseArray) {
          const tmpArray = [...result?.data?.itemInfo?.canPurchaseArray]
          const tmpArrayDecending = [
            ...result?.data?.itemInfo?.canPurchaseArray,
          ]

          let canPurchasePrice
          let canNextPurchasePrice

          tmpArray.sort((a, b) => a - b)
          tmpArrayDecending.sort((a, b) => b - a)

          if (tmpArray?.length > 0) {
            tmpArray.some((v) => {
              if (result?.data?.remainedPrice <= v) {
                canPurchasePrice = v
                return true
              }
            })
          }

          for (const p of tmpArrayDecending) {
            canNextPurchasePrice = p
            if (p < result?.data?.remainedPrice) {
              canNextPurchasePrice = p
              break
            }
          }

          setCanPurchaseConfig({
            array: tmpArray,
            arrayDecending: tmpArrayDecending,
            purchasePrice: canPurchasePrice,
            canNextPurchasePrice,
          })
        } else {
        }
      } else {
        alert.show(`알 수 없는 오류가 발생했어요.\n다시 시도해 주세요`, {
          closeCopy: '확인',
        })
      }
    }
    getZeroWonStoreTeamInfo()
  }, [])

  useEffect(() => {
    let tmpCanNextPurchasePrice
    if (canPurchaseConfig?.arrayDecending?.length) {
      for (const p of canPurchaseConfig?.arrayDecending) {
        tmpCanNextPurchasePrice = p
        if (p < ongoingZeroWonStoreTeamInfo?.remainedPrice) {
          tmpCanNextPurchasePrice = p
          break
        }
      }
      setCanPurchaseConfig({
        ...canPurchaseConfig,
        canNextPurchasePrice: tmpCanNextPurchasePrice,
      })
    }
  }, [ongoingZeroWonStoreTeamInfo])

  const missionComponent = (imgUri, missionName, status, onClick) => (
    <>
      {status && status === 'receiveReward' ? (
        <div
          style={{
            backgroundColor: '#FCF8EC',
            border: '2px solid #ccc',
            borderBottom: 'transparent',
            borderRadius: 8,
            width: '16vw',
            height: '22vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: 14,
            marginRight: 14,
          }}
        >
          <button
            style={{
              backgroundColor: 'transparent',
              width: '20vw',
              borderWidth: 0,
              borderRadius: 8,
              color: '#fff',
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
              height: '16vw',
            }}
            onClick={() => {}}
          >
            <img
              style={{
                width: '70%',
                height: '70%',
                display: 'flex',
              }}
              src={imgUri}
            />
            <div
              style={{
                backgroundColor: '#ccc',
                border: '2.1px solid #ccc',
                width: '20vw',
                borderWidth: 0,
                borderRadius: 8,
                color: '#fff',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                boxSizing: 'border-box',
                padding: '6px',
                fontSize: 16,
              }}
            >
              완료
            </div>
          </button>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: '#FCF8EC',
            border: '2px solid #F09637',
            borderBottom: 'none',
            borderRadius: 8,
            width: '16vw',
            height: '22vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: 14,
            marginRight: 14,
          }}
        >
          <button
            className={`${'scaleUp'}`}
            style={{
              backgroundColor: 'transparent',
              width: '20vw',
              borderWidth: 0,
              borderRadius: 8,
              color: '#fff',
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
              height: '16vw',
            }}
            onClick={() => {
              if (isButtonDisable) {
                return
              }
              isButtonDisable = true
              debounce(onClick, 500)
            }}
          >
            <img
              style={{
                width: '70%',
                height: '70%',
                display: 'flex',
              }}
              src={imgUri}
            />
            <div
              style={{
                backgroundColor: '#F09637',
                border: '2.1px solid #F09637',
                width: '20vw',
                borderWidth: 0,
                borderRadius: 8,
                color: '#fff',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                boxSizing: 'border-box',
                padding: '6px',
                fontSize: 16,
              }}
            >
              {missionName}
            </div>
          </button>
        </div>
      )}
    </>
  )

  const checkTodayCheckInHistory = async () => {
    if (
      ongoingZeroWonStoreTeamInfo?.missionStatus?.isCheckInStatus ===
      'preMission'
    ) {
      const result = await backendApis.checkTodayCheckInHistory(token)
      if (result?.data === 'preMission') {
        window.location.href = `#navigate.${JSON.stringify({
          screen: 'DailyCheckInScreenB',
          prop: { origin: 'zeroWonStoreItemScreen' },
        })}`
        // 출첵하러 보내기
      } else if (result?.data === 'clearMission') {
        if (!ongoingZeroWonStoreTeamInfo?.itemInfo?.isNewUserOnlyItem) {
          alert.show('출석체크를 완료하여 보상을 받을 수 있어요', {
            closeCopy: '확인',
          })
        } else {
          alert.show(
            '출석체크를 완료했어요. 20초 게임을 진행하고 0원 상품을 받아가세요!',
            {
              closeCopy: '확인',
            },
          )
        }
        const result = await backendApis.setTodayCheckInReward(token, 'PUT')
        setOngoingZeroWonStoreTeamInfo(result?.data)
      }
    } else if (
      ongoingZeroWonStoreTeamInfo?.missionStatus?.isCheckInStatus ===
      'clearMission'
    ) {
      alert.show('출석체크를 완료하여 보상을 받을 수 있어요', {
        closeCopy: '확인',
      })
      const result = await backendApis.setTodayCheckInReward(token, 'PUT')
      setOngoingZeroWonStoreTeamInfo(result?.data)
    }
    isButtonDisable = false
  }

  const checkTodayAlfarmHistory = async () => {
    if (
      ongoingZeroWonStoreTeamInfo?.missionStatus?.isAlfarmStatus ===
      'preMission'
    ) {
      const result = await backendApis.checkTodayAlfarmHistory(token)
      if (result?.data === 'preMission') {
        const ongoingAlfarmData = await backendApis.getAlFarmUserData(token)
        if (ongoingAlfarmData?.status !== 200) {
          isButtonDisable = false
          return
        }
        if (ongoingAlfarmData?.data?._id) {
          setIsAlreadyExist(true)
        } else {
          setIsAlreadyExist(false)
        }
        window.location.href = `#navigate.${JSON.stringify({
          screen: 'gameTest',
          prop: {
            origin: 'zeroWonStoreItemScreen',
            isAlreadyExist: !!ongoingAlfarmData?.data?._id,
            itemType: ongoingAlfarmData?.data?.itemType,
          },
        })}`
      } else if (result?.data === 'clearMission') {
        alert.show('올팜에 물주기를 완료하여 보상을 받을 수 있어요', {
          closeCopy: '확인',
        })
        const result = await backendApis.setTodayAlfarmReward(token, 'PUT')
        setOngoingZeroWonStoreTeamInfo(result?.data)
      }
    } else if (
      ongoingZeroWonStoreTeamInfo?.missionStatus?.isAlfarmStatus ===
      'clearMission'
    ) {
      alert.show('올팜에 물주기를 완료하여 보상을 받을 수 있어요', {
        closeCopy: '확인',
      })
      const result = await backendApis.setTodayAlfarmReward(token, 'PUT')
      setOngoingZeroWonStoreTeamInfo(result?.data)
    }
    isButtonDisable = false
  }

  const checkTodayAlfarmLiveReviewHistory = async () => {
    if (
      ongoingZeroWonStoreTeamInfo?.missionStatus?.isAlfarmLiveReviewStatus ===
      'preMission'
    ) {
      const result = await backendApis.checkTodayAlfarmLiveReviewHistory(token)
      if (result?.data === 'preMission') {
        alert.show(
          '물받기에 있는 생생후기 보기 미션을 완료하여 보상을 받을 수 있어요',
          {
            closeCopy: '확인',
          },
        )
        const ongoingAlfarmData = await backendApis.getAlFarmUserData(token)
        setTimeout(() => {
          if (ongoingAlfarmData?.status !== 200) {
            isButtonDisable = false
            return
          }
          if (ongoingAlfarmData?.data?._id) {
            setIsAlreadyExist(true)
          } else {
            setIsAlreadyExist(false)
          }
          window.location.href = `#navigate.${JSON.stringify({
            screen: 'gameTest',
            prop: {
              origin: 'zeroWonStoreItemScreen',
              isAlreadyExist: !!ongoingAlfarmData?.data?._id,
              itemType: ongoingAlfarmData?.data?.itemType,
            },
          })}`
        }, 2500)
      } else if (result?.data === 'clearMission') {
        alert.show('올팜에서 생생후기 보기를 완료하여 보상을 받을 수 있어요', {
          closeCopy: '확인',
        })
        const result = await backendApis.setTodayAlfarmLiveReviewReward(
          token,
          'PUT',
        )
        setOngoingZeroWonStoreTeamInfo(result?.data)
      }
    } else if (
      ongoingZeroWonStoreTeamInfo?.missionStatus?.isAlfarmLiveReviewStatus ===
      'clearMission'
    ) {
      alert.show('올팜에서 생생후기 보기를 완료하여 보상을 받을 수 있어요', {
        closeCopy: '확인',
      })
      const result = await backendApis.setTodayAlfarmLiveReviewReward(
        token,
        'PUT',
      )
      setOngoingZeroWonStoreTeamInfo(result?.data)
    }
    isButtonDisable = false
  }

  const checkTodayAlfarmGganbuHistory = async () => {
    if (
      ongoingZeroWonStoreTeamInfo?.missionStatus?.isAlfarmGganbuStatus ===
      'preMission'
    ) {
      const result = await backendApis.checkTodayAlfarmGganbuHistory(token)
      if (result?.data === 'preMission') {
        const ongoingAlfarmData = await backendApis.getAlFarmUserData(token)
        if (ongoingAlfarmData?.status !== 200) {
          isButtonDisable = false
          return
        }
        if (ongoingAlfarmData?.data?._id) {
          setIsAlreadyExist(true)
        } else {
          setIsAlreadyExist(false)
        }
        window.location.href = `#navigate.${JSON.stringify({
          screen: 'gameTest',
          prop: {
            origin: 'zeroWonStoreItemScreen',
            isAlreadyExist: !!ongoingAlfarmData?.data?._id,
            itemType: ongoingAlfarmData?.data?.itemType,
          },
        })}`
      } else if (result?.data === 'clearMission') {
        alert.show('올팜에서 맞팜하기를 완료하여 보상을 받을 수 있어요', {
          closeCopy: '확인',
        })
        const result = await backendApis.setTodayAlfarmGganbuReward(
          token,
          'PUT',
        )
        setOngoingZeroWonStoreTeamInfo(result?.data)
      }
    } else if (
      ongoingZeroWonStoreTeamInfo?.missionStatus?.isAlfarmGganbuStatus ===
      'clearMission'
    ) {
      alert.show('올팜에 맞팜하기를 완료하여 보상을 받을 수 있어요', {
        closeCopy: '확인',
      })
      const result = await backendApis.setTodayAlfarmGganbuReward(token, 'PUT')
      setOngoingZeroWonStoreTeamInfo(result?.data)
    }
    isButtonDisable = false
  }

  const purchaseItemButton = () => {
    window.location.href = `#pushToItemScreen.${JSON.stringify({
      screen: 'MainStackDItemScreen',
      prop: {
        itemId: ongoingZeroWonStoreTeamInfo?.itemInfo?.itemId,
        itemInfo: ongoingZeroWonStoreTeamInfo?.itemInfo,
        enteringComponent: 'zeroWonStore',
        type: 'zeroWonStore',
      },
    })}`
  }

  const purchaseMandarinButton = () => {
    setShowMandarinShareNudgeModal(true)
  }

  const checkTodayPurchaseHistory = async () => {
    if (
      ongoingZeroWonStoreTeamInfo?.missionStatus?.isPurchaseStatus ===
      'preMission'
    ) {
      const result = await backendApis.checkTodayPurchaseHistory(token)
      if (result?.data === 'preMission') {
        window.location.href = `#navigate.${JSON.stringify({
          screen: 'TimeDealScreen',
          prop: { origin: 'zeroWonStoreItemScreen' },
        })}`
      } else if (result?.data === 'clearMission') {
        alert.show('구매를 완료하여 보상을 받을 수 있어요', {
          closeCopy: '확인',
        })
        const result = await backendApis.setTodayPurchaseReward(token, 'PUT')
        setOngoingZeroWonStoreTeamInfo(result?.data)
      }
    } else if (
      ongoingZeroWonStoreTeamInfo?.missionStatus?.isPurchaseStatus ===
      'clearMission'
    ) {
      alert.show('구매를 완료하여 보상을 받을 수 있어요', {
        closeCopy: '확인',
      })
      const result = await backendApis.setTodayPurchaseReward(token, 'PUT')
      setOngoingZeroWonStoreTeamInfo(result?.data)
    }
    isButtonDisable = false
  }

  const attendantList = (attendantListData) => {
    const attendantComponent = (attendantData) => {
      function UserImageUri() {
        if (attendantData?.userName === '출석체크') {
          return (
            <div
              style={{
                width: 28,
                height: 28,
                // borderRadius: 28,
                overflow: 'hidden',
              }}
            >
              <img
                style={{ width: '100%', height: '100%' }}
                src='https://assets.ilevit.com/c96146d5-9953-4d27-a79b-6b57d6d34ddb.png'
              />
            </div>
          )
        }
        if (attendantData?.userName === '올팜 물주기') {
          return (
            <div
              style={{
                width: 28,
                height: 28,
                // borderRadius: 28,
                overflow: 'hidden',
              }}
            >
              <img
                style={{ width: '100%', height: '100%' }}
                src='https://assets.ilevit.com/6d234cd5-3414-4e8a-890b-2bf50f935838.png'
              />
            </div>
          )
        }
        if (attendantData?.userName === '올팜 생생후기 보기') {
          return (
            <div
              style={{
                width: 28,
                height: 28,
                // borderRadius: 28,
                overflow: 'hidden',
              }}
            >
              <img
                style={{ width: '100%', height: '100%' }}
                src='https://assets.ilevit.com/fd5f86cf-3f94-458e-9408-043975e23c9c.png'
              />
            </div>
          )
        }
        if (attendantData?.userName === '올팜 맞팜하기') {
          return (
            <div
              style={{
                width: 28,
                height: 28,
                // borderRadius: 28,
                overflow: 'hidden',
              }}
            >
              <img
                style={{ width: '100%', height: '100%' }}
                src='https://assets.ilevit.com/9a3e419e-69f7-4450-a201-596fe4101315.png'
              />
            </div>
          )
        }
        if (attendantData?.userName === '상품 구매') {
          return (
            <div
              style={{
                width: 28,
                height: 28,
                // borderRadius: 28,
                overflow: 'hidden',
              }}
            >
              <img
                style={{ width: '100%', height: '100%' }}
                src='https://assets.ilevit.com/f667d477-9c1e-427b-9632-f385751ac8df.png'
              />
            </div>
          )
        }
        return (
          <div
            style={{
              width: 28,
              height: 28,
              borderRadius: 28,
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img src={attendantData?.userImageUri} />
          </div>
        )
      }
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            padding: 5,
            fontSize: 18,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UserImageUri />
            <div style={{ boxSizing: 'border-box', paddingLeft: 12 }}>
              {attendantData?.userName}
            </div>
            {userBadge(attendantData?.userType, attendantData?.isNewUser)}
          </div>

          <div
            style={{
              color: '#ff3e3e',
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              // flex: 1,
            }}
          >
            <div
              style={{
                width: 28,
                height: 28,
                marginRight: 2,
                padding: 1,
                boxSizing: 'border-box',
              }}
            >
              <img
                style={{ width: '100%', height: '100%' }}
                src='https://assets.ilevit.com/1a21c80b-5978-4f59-b339-45aaa0f14eec.png'
              />
            </div>
            {attendantData?.discountPrice?.toLocaleString()}원 할인
          </div>
        </div>
      )
    }
    return (
      <div
        style={{
          width: '100%',
          backgroundColor: '#F8D9A2',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          borderRadius: 12,
          marginBottom: 16,
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            paddingTop: 12,
            paddingBottom: 12,
            fontSize: 18,
            // fontWeight: 600,
          }}
        >
          참여한 친구 목록
        </div>
        <div
          style={{
            backgroundColor: '#FCF8EC',
            width: '100%',
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 8,
            paddingRight: 8,
            boxSizing: 'border-box',
          }}
        >
          {attendantListData?.reverse().map((x) => attendantComponent(x))}
        </div>
      </div>
    )
  }

  return (
    <Layout>
      <Header />
      <div
        style={{
          overflowY: 'scroll',
          height: '100%',
        }}
      >
        <div
          style={{
            color: 'black',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: 250,
            backgroundImage: "url('/images/ZeroWonStoreItemTopImage.png')",
            backgroundSize: 'cover',
          }}
        >
          <div
            style={{
              boxSizing: 'border-box',
              padding: 16,
              fontSize: 20,
              color: '#fff',
              fontWeight: 'bold',
              display: 'flex',
              textAlign: 'center',
            }}
          >
            {ongoingZeroWonStoreTeamInfo?.hostUserInfo?.isNewUser ? (
              <>
                {ongoingZeroWonStoreTeamInfo?.hostUserInfo?.userName}님의 0원
                상점
              </>
            ) : (
              <></>
            )}
            <br />
            {ongoingZeroWonStoreTeamInfo?.itemInfo?.itemTitle}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: 132,
              height: 132,
              boxSizing: 'border-box',
              // padding: 16,
              borderRadius: 10,
              overflow: 'hidden',
              marginBottom: 8,
            }}
          >
            <img src={ongoingZeroWonStoreTeamInfo?.itemInfo?.mainImageUri[0]} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            paddingTop: 24,
            paddingLeft: 24,
            paddingRight: 24,
            backgroundColor: '#FAE9CB',
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
            marginTop: -12,
          }}
        >
          <div
            style={{
              backgroundColor: '#FCF8EC',
              width: '100%',
              borderRadius: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: 12,
              marginBottom: 20,
            }}
          >
            {ongoingZeroWonStoreTeamInfo?.createdAt && (
              <div
                style={{
                  backgroundColor: '#FAE9CB',
                  borderBottomLeftRadius: 12,
                  borderBottomRightRadius: 12,
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 24,
                  paddingRight: 24,
                  fontSize: 16,
                  marginBottom: 12,
                  color: 'black',
                }}
              >
                <BlackTextTimer
                  color='black'
                  timeStamp={new Date(ongoingZeroWonStoreTeamInfo?.createdAt)}
                  timerMinutes={60 * 24}
                />{' '}
                남음
              </div>
            )}
            <div
              style={{
                lineHeight: 1.5,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {ongoingZeroWonStoreTeamInfo?.remainedPrice > 0 ? (
                <>
                  {/* <div style={{ fontWeight: 'bold' }}>
                    미션을 수행하고 0원에 가져가세요
                  </div> */}
                  <div
                    // className={`${'scaleUp'}`}
                    style={{
                      // fontWeight: 'bold',
                      marginBottom: 8,
                      fontSize: 16,
                    }}
                  >
                    {/* {commaNumber(ongoingZeroWonStoreTeamInfo?.goalPrice)}
                    원까지 */}
                    <span
                      style={{
                        color: '#ff3e3e',
                        paddingLeft: 5,
                        // fontWeight: 'bold',
                      }}
                    >
                      {commaNumber(ongoingZeroWonStoreTeamInfo?.remainedPrice)}
                      원
                    </span>
                    만 더 깎으면 0원에 드려요!
                  </div>

                  {Math.round(
                    ongoingZeroWonStoreTeamInfo?.remainedPrice /
                      ongoingZeroWonStoreTeamInfo?.itemInfo?.levelObject
                        ?.newUser[0][0],
                  ) > 0 &&
                    Math.round(
                      ongoingZeroWonStoreTeamInfo?.remainedPrice /
                        ongoingZeroWonStoreTeamInfo?.itemInfo?.levelObject
                          ?.newUser[0][0],
                    ) < 4 &&
                    !ongoingZeroWonStoreTeamInfo?.itemInfo
                      ?.isNewUserOnlyItem && (
                      <div>
                        <div
                          style={{
                            fontSize: 13,
                            textAlign: 'center',
                            width: '100%',
                            backgroundColor: '#ddd',
                            color: 'black',
                            padding: 4,
                            borderRadius: 12,
                            fontWeight: '500',
                          }}
                        >
                          새 친구{' '}
                          <span
                            style={{ color: '#ff3e3e', fontWeight: 'bold' }}
                          >
                            {Math.round(
                              ongoingZeroWonStoreTeamInfo?.remainedPrice /
                                ongoingZeroWonStoreTeamInfo?.itemInfo
                                  ?.levelObject?.newUser[0][0],
                            )}
                          </span>
                          명을 초대하면 즉시 받을 수 있어요!
                        </div>
                        <div
                          style={{
                            marginLeft: `${
                              (1 -
                                canPurchaseConfig?.canNextPurchasePrice /
                                  ongoingZeroWonStoreTeamInfo?.goalPrice) *
                              100
                            }%`,
                            height: '12px',
                            position: 'relative',
                          }}
                        >
                          <div
                            className='triangle'
                            style={{
                              position: 'absolute',
                              right: '0px',
                            }}
                          />
                        </div>
                      </div>
                    )}
                  {/* {ongoingZeroWonStoreTeamInfo?.remainedPrice -
                    canPurchaseConfig?.arrayDecending?.[0] >
                  0 ? (
                    <>
                      {' '}
                      <div style={{ fontWeight: 'bold', marginBottom: 16 }}>
                        0원 달성까지
                        <span
                          style={{
                            color: '#ff3e3e',
                            paddingLeft: 5,
                            fontWeight: 'bold',
                          }}
                        >
                          {commaNumber(
                            ongoingZeroWonStoreTeamInfo?.remainedPrice,
                          )}
                          원{' '}
                        </span>
                        남았어요
                      </div>
                    </>
                  ) : (
                    <>
                      {' '}
                      <div style={{ fontWeight: 'bold', marginBottom: 16 }}>
                        0원 달성까지
                        <span
                          style={{
                            color: '#ff3e3e',
                            paddingLeft: 5,
                            fontWeight: 'bold',
                          }}
                        >
                          {commaNumber(
                            ongoingZeroWonStoreTeamInfo?.remainedPrice,
                          )}
                          원{' '}
                        </span>
                        남았어요
                      </div>
                    </>
                  )} */}
                </>
              ) : (
                <>
                  {' '}
                  <div style={{ fontWeight: 'bold' }}>
                    0원 달성에 성공했어요!
                  </div>
                  <div style={{ fontWeight: 'bold', marginBottom: 16 }}>
                    0원에 상품을 받아보세요 :)
                  </div>
                </>
              )}

              <div
                style={{
                  width: '100%',
                  paddingRight: 16,
                  paddingLeft: 16,
                  boxSizing: 'border-box',
                  alignItems: 'center',
                }}
              >
                {ongoingZeroWonStoreTeamInfo?.remainedPrice > 0 && (
                  <>
                    {canPurchaseConfig?.arrayDecending?.[0] &&
                    ongoingZeroWonStoreTeamInfo?.remainedPrice >
                      (canPurchaseConfig?.arrayDecending?.[0] || 0) ? (
                      <div>
                        <div
                          style={{
                            fontSize: 13,
                            width: '100%',
                            textAlign: 'center',
                            backgroundColor: '#ddd',
                            color: 'black',
                            padding: 4,
                            borderRadius: 12,
                            fontWeight: '500',
                          }}
                        >
                          {commaNumber(
                            ongoingZeroWonStoreTeamInfo?.goalPrice -
                              canPurchaseConfig?.arrayDecending?.[0],
                          )}
                          원만 모아도 같은 금액의 할인권을 드려요!
                        </div>
                        <div
                          style={{
                            marginLeft: `${
                              (1 -
                                ongoingZeroWonStoreTeamInfo?.itemInfo
                                  ?.canPurchaseArray?.[0] /
                                  ongoingZeroWonStoreTeamInfo?.goalPrice) *
                              100
                            }%`,
                            height: '12px',
                            position: 'relative',
                          }}
                        >
                          <div
                            className='triangle'
                            style={{
                              position: 'absolute',
                              left: '-8px',
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                      // <div>
                      //   <div
                      //     style={{
                      //       fontSize: 13,
                      //       textAlign: 'center',
                      //       width: '100%',
                      //       backgroundColor: '#ddd',
                      //       color: 'black',
                      //       padding: 4,
                      //       borderRadius: 12,
                      //       fontWeight: '500',
                      //     }}
                      //   >
                      //     {commaNumber(
                      //       ongoingZeroWonStoreTeamInfo?.remainedPrice -
                      //         canPurchaseConfig?.canNextPurchasePrice,
                      //     )}
                      //     원만 더 채우면{' '}
                      //     {commaNumber(canPurchaseConfig?.canNextPurchasePrice)}
                      //     원에 구매가 가능해요!
                      //   </div>
                      //   <div
                      //     style={{
                      //       marginLeft: `${
                      //         (1 -
                      //           canPurchaseConfig?.canNextPurchasePrice /
                      //             ongoingZeroWonStoreTeamInfo?.goalPrice) *
                      //         100
                      //       }%`,
                      //       height: '12px',
                      //       position: 'relative',
                      //     }}
                      //   >
                      //     <div
                      //       className='triangle'
                      //       style={{
                      //         position: 'absolute',
                      //         left: '-8px',
                      //       }}
                      //     />
                      //   </div>
                      // </div>
                    )}
                  </>
                )}
                <div
                  style={{
                    backgroundColor: '#FBE5BD',
                    width: '100%',
                    borderRadius: 10,
                    height: 24,
                    boxSizing: 'border-box',
                    marginTop: 4,
                    marginBottom: 16,
                    overflow: 'hidden',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#F09637',
                      width: `${Math.min(
                        (1 -
                          ongoingZeroWonStoreTeamInfo?.remainedPrice /
                            ongoingZeroWonStoreTeamInfo?.goalPrice) *
                          100,
                        100,
                      )}%`,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      height: '100%',
                      // fontWeight: 'bold',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    {commaNumber(
                      ongoingZeroWonStoreTeamInfo?.goalPrice -
                        ongoingZeroWonStoreTeamInfo?.remainedPrice,
                    )}
                    원을 깎았어요 (
                    {Math.min(
                      (1 -
                        ongoingZeroWonStoreTeamInfo?.remainedPrice /
                          ongoingZeroWonStoreTeamInfo?.goalPrice) *
                        100,
                      100,
                    ).toFixed(1)}
                    %)
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {!ongoingZeroWonStoreTeamInfo?.itemInfo?.isNewUserOnlyItem &&
                  ongoingZeroWonStoreTeamInfo?.isReceived && (
                    <button
                      style={{
                        borderWidth: 0,
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingRight: 24,
                        paddingLeft: 24,
                        fontSize: 16,
                        borderRadius: 24,
                        color: 'white',
                        backgroundColor: '#ccc',
                        fontWeight: 600,
                        marginBottom: 4,
                      }}
                    >
                      이미 수령을 완료했어요
                      <div>시간이 지나면 다시 시도할 수 있어요</div>
                    </button>
                  )}
                {ongoingZeroWonStoreTeamInfo?.hostUserInfo?.isNewUser &&
                  ongoingZeroWonStoreTeamInfo?.remainedPrice > 0 &&
                  ongoingZeroWonStoreTeamInfo?.itemInfo?.isNewUserOnlyItem && (
                    <>
                      <button
                        className={`${'scaleUp'}`}
                        style={{
                          borderWidth: 0,
                          paddingTop: 12,
                          paddingBottom: 12,
                          paddingRight: 24,
                          paddingLeft: 24,
                          fontSize: 16,
                          borderRadius: 24,
                          color: 'white',
                          backgroundColor: '#E8612D',
                          fontWeight: 600,
                          marginBottom: 8,
                          marginLeft: 16,
                          marginRight: 16,
                        }}
                        onClick={() => {
                          if (
                            ongoingZeroWonStoreTeamInfo?.missionStatus
                              ?.isCheckInStatus === 'preMission'
                          ) {
                            checkTodayCheckInHistory()
                          } else {
                            checkTodayAlfarmHistory()
                          }
                        }}
                      >
                        {ongoingZeroWonStoreTeamInfo?.missionStatus
                          ?.isCheckInStatus === 'preMission'
                          ? '10초 출석체크하고 감귤 받기'
                          : '20초 게임하고 미션 끝내기'}
                      </button>
                    </>
                  )}
                {ongoingZeroWonStoreTeamInfo?.hostUserInfo?.isNewUser &&
                  ongoingZeroWonStoreTeamInfo?.itemInfo?.isNewUserOnlyItem &&
                  ongoingZeroWonStoreTeamInfo?.remainedPrice <= 0 && (
                    <button
                      style={{
                        borderWidth: 0,
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingRight: 24,
                        paddingLeft: 24,
                        fontSize: 16,
                        borderRadius: 24,
                        color: 'white',
                        backgroundColor: '#E8612D',
                        fontWeight: 600,
                        marginBottom: 4,
                      }}
                      onClick={() => {
                        debounce(purchaseMandarinButton, 500)
                      }}
                    >
                      {!ongoingZeroWonStoreTeamInfo?.isReceived
                        ? '지금 0원 감귤 받으러 가기'
                        : '새친구에게 0원 감귤 공유하기'}
                    </button>
                  )}

                {ongoingZeroWonStoreTeamInfo?.hostUserInfo?.isNewUser &&
                  !ongoingZeroWonStoreTeamInfo?.itemInfo?.isNewUserOnlyItem &&
                  ongoingZeroWonStoreTeamInfo?.remainedPrice >
                    ongoingZeroWonStoreTeamInfo?.goalPrice * 0.03 && (
                    <>
                      <button
                        className={`${'scaleUp'}`}
                        style={{
                          borderWidth: 0,
                          paddingTop: 12,
                          paddingBottom: 12,
                          paddingRight: 24,
                          paddingLeft: 24,
                          fontSize: 16,
                          borderRadius: 24,
                          color: 'white',
                          backgroundColor: '#E8612D',
                          fontWeight: 600,
                          marginBottom: 8,
                          marginLeft: 16,
                          marginRight: 16,
                        }}
                        onClick={() => {
                          if (
                            ongoingZeroWonStoreTeamInfo?.missionStatus
                              ?.isAlfarmStatus === 'preMission'
                          ) {
                            checkTodayAlfarmHistory()
                          } else {
                            checkTodayCheckInHistory()
                          }
                        }}
                      >
                        {ongoingZeroWonStoreTeamInfo?.missionStatus
                          ?.isAlfarmStatus === 'preMission'
                          ? '10초 게임하고 '
                          : '출석체크하고 '}
                        최대{' '}
                        {commaNumber(
                          ongoingZeroWonStoreTeamInfo?.remainedPrice,
                        )}
                        원 깎기
                      </button>
                    </>
                  )}

                {!(
                  ongoingZeroWonStoreTeamInfo?.hostUserInfo?.isNewUser &&
                  ongoingZeroWonStoreTeamInfo?.remainedPrice >
                    ongoingZeroWonStoreTeamInfo?.goalPrice * 0.03
                ) &&
                  !ongoingZeroWonStoreTeamInfo?.itemInfo?.isNewUserOnlyItem &&
                  !ongoingZeroWonStoreTeamInfo?.isReceived &&
                  ongoingZeroWonStoreTeamInfo?.remainedPrice > 0 && (
                    <>
                      <button
                        className={`${'scaleUp'}`}
                        style={{
                          borderWidth: 0,
                          paddingTop: 12,
                          paddingBottom: 12,
                          paddingRight: 24,
                          paddingLeft: 24,
                          fontSize: 18,
                          borderRadius: 24,
                          color: 'white',
                          backgroundColor: '#E8612D',
                          fontWeight: 800,
                          marginBottom: 8,
                          marginLeft: 16,
                          marginRight: 16,
                        }}
                        onClick={() => {
                          window.location.href = `#openBottomShareSheet.${JSON.stringify(
                            {
                              code: 'zeroWonStore',
                            },
                          )}`
                        }}
                      >
                        쿠폰 뿌리고 최대{' '}
                        {commaNumber(
                          ongoingZeroWonStoreTeamInfo?.remainedPrice,
                        )}
                        원 깎기
                      </button>
                      <div
                        style={{
                          fontSize: 14,
                          // color: '#ff3e3e',
                          marginBottom: 8,
                          textAlign: 'center',
                        }}
                      >
                        새친구에게 뿌리면 더 큰 금액을 깎을 수 있어요
                      </div>
                    </>
                  )}
                {!ongoingZeroWonStoreTeamInfo?.isReceived &&
                  !ongoingZeroWonStoreTeamInfo?.itemInfo?.isNewUserOnlyItem &&
                  ongoingZeroWonStoreTeamInfo?.remainedPrice <= 0 && (
                    <button
                      style={{
                        borderWidth: 0,
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingRight: 24,
                        paddingLeft: 24,
                        fontSize: 18,
                        borderRadius: 24,
                        color: 'white',
                        backgroundColor: '#E8612D',
                        fontWeight: 800,
                        marginBottom: 4,
                      }}
                      onClick={() => {
                        if (isButtonDisable) {
                          return
                        }
                        isButtonDisable = true
                        debounce(purchaseItemButton, 500)
                      }}
                    >
                      {ongoingZeroWonStoreTeamInfo?.itemInfo?.itemTitle} 0원에
                      받기
                    </button>
                  )}
                {!ongoingZeroWonStoreTeamInfo?.isReceived &&
                  typeof canPurchaseConfig?.purchasePrice === 'number' &&
                  canPurchaseConfig?.purchasePrice > 0 && (
                    <div
                      style={{
                        display: 'flex',
                        height: '2em',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button
                        style={{
                          backgroundColor: 'transparent',
                          fontSize: 18,
                          color: '#ff3e3e',
                          fontWeight: 800,
                          marginTop: 12,
                          marginBottom: 12,
                          borderWidth: 0,
                          padding: 0,
                        }}
                        onClick={() => {
                          if (isButtonDisable) {
                            return
                          }
                          isButtonDisable = true
                          debounce(purchaseItemButton, 500)
                        }}
                      >
                        {commaNumber(
                          ongoingZeroWonStoreTeamInfo?.goalPrice -
                            canPurchaseConfig?.purchasePrice,
                        )}
                        원 할인받고 즉시 구매하기
                      </button>
                      <div style={{ height: '100%' }}>
                        <img
                          style={{
                            paddingTop: 4,
                            paddingBottom: 4,
                            height: '100%',
                            boxSizing: 'border-box',
                          }}
                          src='/images/RedRightArrow.png'
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          {attendantList(
            ongoingZeroWonStoreTeamInfo?.guestUserInfosArray || [],
          )}
          <>
            {' '}
            <div style={{ boxSizing: 'border-box', marginBottom: 8 }}>
              <div style={{ fontSize: 18, fontWeight: 600, marginBottom: 12 }}>
                보너스 할인 찬스
              </div>
              <div
                style={{
                  // backgroundColor: '#FCF8EC',
                  overflowY: 'scroll',
                  // overflowX: 'hidden',
                  flex: 1,
                  display: 'flex',
                  height: '24vw',
                }}
              >
                {missionComponent(
                  'https://assets.ilevit.com/6d234cd5-3414-4e8a-890b-2bf50f935838.png',
                  '물주기',
                  ongoingZeroWonStoreTeamInfo?.missionStatus?.isAlfarmStatus,
                  () => {
                    checkTodayAlfarmHistory()
                  },
                )}
                {missionComponent(
                  'https://assets.ilevit.com/c96146d5-9953-4d27-a79b-6b57d6d34ddb.png',
                  '출석체크',
                  ongoingZeroWonStoreTeamInfo?.missionStatus?.isCheckInStatus,
                  () => {
                    checkTodayCheckInHistory()
                  },
                )}
                {ongoingZeroWonStoreTeamInfo?.hostUserInfo?.isNewUser &&
                  missionComponent(
                    'https://assets.ilevit.com/fd5f86cf-3f94-458e-9408-043975e23c9c.png',
                    '생생후기',
                    ongoingZeroWonStoreTeamInfo?.missionStatus
                      ?.isAlfarmLiveReviewStatus,
                    () => {
                      checkTodayAlfarmLiveReviewHistory()
                    },
                  )}
                {ongoingZeroWonStoreTeamInfo?.hostUserInfo?.isNewUser &&
                  missionComponent(
                    'https://assets.ilevit.com/9a3e419e-69f7-4450-a201-596fe4101315.png',
                    '맞팜하기',
                    ongoingZeroWonStoreTeamInfo?.missionStatus
                      ?.isAlfarmGganbuStatus,
                    () => {
                      checkTodayAlfarmGganbuHistory()
                    },
                  )}
                {missionComponent(
                  'https://assets.ilevit.com/f667d477-9c1e-427b-9632-f385751ac8df.png',
                  '구매하기',
                  ongoingZeroWonStoreTeamInfo?.missionStatus?.isPurchaseStatus,
                  () => {
                    checkTodayPurchaseHistory()
                  },
                )}
              </div>
            </div>
          </>
        </div>
        <div
          style={{
            width: '100%',
            backgroundColor: '#F4F6F8',
            boxSizing: 'border-box',
            padding: 16,
            paddingBottom: 56,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 8 }}>
            유의사항
          </div>
          <div style={{ lineHeight: '22.6px' }}>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 한번에 하나의 상품만 진행할 수 있어요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 성공한 상품은 재도전이 안돼요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 20세 이상의 고객만 참여갸 가능해요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 지난 0원 상점을 열고나서 24시간 이후에 새로운 0원 상점을 열 수
              있어요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 성공하였을 경우 24시간 동안만 상품 주문이 가능해요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 성공한 이후 24시간이 지나면 상품 주문을 할 수 없어요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 일반 상품 구매를 통해 추가할인을 받은 후, 일반 상품을 의도적으로
              취소하면 0원 상품 수령에 제한이 있을 수 있어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 경품은 영업일 기준 20일 내외로 발송되며 지정일 배송과 출고 후
              주소 변경은 불가능해요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 사전에 고지된 상품의 옵션은 변경이 불가능해요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 제한 시간이 종료되면 이전 기록은 인정되지 않으며 처음부터 다시
              시작 해야해요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 상품은 입력하신 주소지로 배송돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 친구를 한명 초대할 때 마다 가격이 할인돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 친구 초대를 통한 금액은 이벤트참여시기, 이벤트달성정도 등 다양한
              요소를 고려하여 랜덤하게 지급돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 가입한지 2시간 이내이며 올웨이즈의 타 이벤트 참여 이력이 없는
              고객만 신규 고객으로 인정돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 이벤트가 시작된 이후 가입한 고객은 반드시 본인인증을 진행해야만
              신규 고객으로 인정돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 기존 친구를 초대해도 랜덤으로 큰 할인 금액이 주어져요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 탈퇴 후 재참여하시면 새친구 보상을 드리지 않으며, 회원탈퇴할
              경우 24시간 동안 친구에게 가격 할인을 제공할 수 없어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 친구에게 하루 3번 할인을 도와줄 수 있고, 오전 9시를 기준으로
              다시 3번의 기회가 생겨요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 부정한 방법을 통한 참여는 인정되지 않으며, 성공하여도 취소될 수
              있습니다.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 재고 소진 시 유사 상품이나 해당 금액으로 대체하여 제공될 수
              있어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 10만원 이상의 상품에 대해서는 제세공과금이 발생할 수 있어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 본 이벤트는 당사의 사정에 의해 진행 중인 고객님이 있어도 사전
              고지 없이 변경 또는 조기종료될 수 있어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 문의는 내 정보 > 고객문의 > 1:1 실시간 상담하기에서 하실 수
              있어요.
            </div>
          </div>
        </div>
      </div>
      {showNewUserNudgeModal && (
        <NewUserNudgeModal
          setShowNewUserNudgeModal={setShowNewUserNudgeModal}
          userName={ongoingZeroWonStoreTeamInfo?.hostUserInfo?.userName}
          onClick={() => {
            // setShowNewUserNudgeModal(false)
          }}
        />
      )}
      {showMandarinShareNudgeModal && (
        <MandarinShareNudgeModal
          setShowMandarinShareNudgeModal={setShowMandarinShareNudgeModal}
          isReceived={ongoingZeroWonStoreTeamInfo?.isReceived}
          isPurchasableStatus={isPurchasableStatus}
          onClick={() => {
            setShowMandarinShareNudgeModal(false)
            if (ongoingZeroWonStoreTeamInfo?.isReceived) {
              window.location.href = `#openBottomShareSheet.${JSON.stringify({
                code: 'zeroWonStore',
              })}`
            } else if (
              !ongoingZeroWonStoreTeamInfo?.isReceived &&
              !isPurchasableStatus
            ) {
              window.location.href = `#openBottomShareSheet.${JSON.stringify({
                code: 'zeroWonStore',
              })}`
              isPurchasableStatus = true
            } else {
              window.location.href = `#pushToItemScreen.${JSON.stringify({
                screen: 'MainStackDItemScreen',
                prop: {
                  itemId: ongoingZeroWonStoreTeamInfo?.itemInfo?.itemId,
                  itemInfo: ongoingZeroWonStoreTeamInfo?.itemInfo,
                  enteringComponent: 'zeroWonStore',
                  type: 'zeroWonStore',
                },
              })}`
            }
          }}
        />
      )}
    </Layout>
  )
}

export default ZeroWonStoreItemPage
