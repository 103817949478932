import { observer } from 'mobx-react-lite'
import React from 'react'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'

const Header = observer(() => {
  return (
    <div className='fixed right-0 z-20 flex justify-end p-4 bg-white shadow-md select-none left-60'>
      <button
        type='button'
        className='flex flex-row items-center w-16 mr-4'
        onClick={() => {
          AltoonCMSUserStore?.setCurrentScreen('main')
        }}
      >
        <img
          alt='logo'
          src='../images/toon/cms/LogoRed.png'
          className='w-8 h-8 mr-4'
        />
        홈
      </button>
      <div className='flex flex-1' />
      <button
        className='z-50 mr-5 text-base bg-transparent font-gray-400'
        type='button'
        onClick={() => {
          localStorage.removeItem('@altoon@provider@token@')
          window.location.href = '/altoon-cms-login'
        }}
      >
        로그아웃
      </button>
    </div>
  )
})
export default Header
