import { observable } from 'mobx'

const tester = [
  '6243835a9d22e940cecde8a5',
  '626aa6a6c4d18f4110ecd6cb',
  '65d5c659cd52d3555c564a07',
  '626ec2b1a66592f5c1603f5f',
  '627d10bfd430af8ffc940c7a',
]

const ABStore = observable({
  initialize({ userId }) {
    // this.attendanceTest = AB(userId, 'attendanceTest1127', [90])

    // 출석체크 이벤트
    // 에그머니 ab
    // this.eggMoneyUITester = AB(userId, 'eggMoneyUITester20240702', [95])

    if (tester.includes(userId)) {
      //
      // this.eggMoneyUITester = 'a'
    }
  },
})

export default ABStore
