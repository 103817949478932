import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../../pages/EggMoney/Button'
import THModalStore from '../../../stores/treasureHunt/THModalStore'

function renderDescription(text) {
  return text?.split?.('\n')?.map((line, index) => (
    <span key={Math.random()}>
      {line}
      {index < text?.split('\n').length - 1 && <br />}
    </span>
  ))
}

const EggMoneyInviteModal = ({ config }) => {
  return (
    <div className='items-center justify-center default'>
      {config?.title && (
        <div className='default font-bold text-center text-[6vw]'>
          {config?.title}
        </div>
      )}
      {config?.subTitle && (
        <div className='font-bold text-center mt-2'>
          {renderDescription(config?.subTitle)}
        </div>
      )}
      <div className='relative flex items-center justify-center w-full'>
        <img
          alt='main'
          src={config?.src}
          className={`relative z-[10] w-full h-auto ${config?.imgClassName}`}
        />
      </div>
      {config?.description && (
        <div className='font-bold text-center'>
          {renderDescription(config?.description)}
        </div>
      )}

      <Button
        className='w-full py-3 mt-6'
        clickable
        text={config?.buttonText ?? '초대하기'}
        onClick={() => {
          if (config?.onClick) {
            config.onClick?.()
          }
          THModalStore?.close()
        }}
      />
    </div>
  )
}

export default observer(EggMoneyInviteModal)
