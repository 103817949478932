import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import THUserStore from '../../stores/treasureHunt/THUserStore'
import THCollectionItem from './THCollectionItem'

const THCollection = observer(({ config }) => {
  const scrollContainerRef = useRef(null)

  const [currentTab, setCurrentTab] = useState('face')
  const [maxId, setMaxId] = useState(99)
  const [collectionArr, setCollectionArr] = useState(
    Array.from({ length: 99 }, (_, i) => i),
  )
  const [collectionArrSecond, setCollectionArrSecond] = useState(
    Array.from({ length: 0 }, (_, i) => i),
  )

  useEffect(() => {
    // currentTab이 변경될 때마다 실행
    if (scrollContainerRef.current) {
      // 스크롤 위치를 0으로 설정
      scrollContainerRef.current.scrollTop = 0
    }
  }, [currentTab]) // currentTab이 변경될 때마다 useEffect 실행

  return (
    <div className='default w-full mb-4 text-[#313138]'>
      <div className='flex flex-row w-ful justify-around items-center py-2 '>
        <div
          className={`px-4 ${
            currentTab === 'face'
              ? 'font-bold border-b-2 border-b-[#313138]'
              : 'font-normal'
          }`}
          onClick={() => {
            setCurrentTab('face')
            setMaxId(100)
            setCollectionArr(Array.from({ length: 100 }, (_, i) => i))
            setCollectionArrSecond([])
          }}
        >
          사람
        </div>
        <div
          className={`px-4 ${
            currentTab === 'animal'
              ? 'font-bold border-b-2 border-b-[#313138]'
              : 'font-normal'
          }`}
          onClick={() => {
            setCurrentTab('animal')
            setMaxId(48)
            setCollectionArr(Array.from({ length: 40 }, (_, i) => i))
            setCollectionArrSecond(Array.from({ length: 8 }, (_, i) => i))
          }}
        >
          동물
        </div>
        <div
          className={`px-4 ${
            currentTab === 'event'
              ? 'font-bold border-b-2 border-b-[#313138]'
              : 'font-normal'
          }`}
          onClick={() => {
            setCurrentTab('event')
            setMaxId(16)
            setCollectionArr(Array.from({ length: 16 }, (_, i) => i))
            setCollectionArrSecond([])
          }}
        >
          이벤트
        </div>
      </div>
      <div className='flex flex-row justify-between items-center text-[4vw] pt-4 px-2'>
        <div className='flex flex-row pl-2 font-bold pb-4 items-end'>
          {`내가 찾은 캐릭터: ${
            THUserStore?.collection?.[currentTab]?.filter(
              (item) => item?.capturedAt,
            )?.length ?? 0
          }개`}
          <div className='ml-2 text-[#8E8D9B] text-[3.5vw]'>
            {maxId -
              (THUserStore?.collection?.[currentTab]?.filter(
                (item) => item?.capturedAt,
              )?.length ?? 0)}
            개 남음
          </div>
        </div>
        <div className='pr-2 font-bold pb-4 text-[#FF847D] text-[3.5vw]'>{`진행도: ${
          Math.round(
            ((THUserStore?.collection?.[currentTab]?.filter(
              (item) => item?.capturedAt,
            )?.length ?? 0) /
              maxId) *
              100,
          ) ?? 0
        }%`}</div>
      </div>

      <div
        className='block h-[50vh] overflow-y-scroll px-2 pointer-events-auto '
        ref={scrollContainerRef}
      >
        {collectionArr.map((_, i) => {
          const isCaptured = THUserStore?.collection?.[currentTab]?.find(
            (item) => {
              return item?.emojiId === i
            },
          )

          return (
            <THCollectionItem
              key={i}
              type={currentTab}
              emojiId={i}
              captured={isCaptured}
            />
          )
        })}

        {collectionArrSecond?.length > 0 && (
          <div>
            <div className='mx-2 mt-2 font-bold text-[5vw] pt-2'>특별</div>

            {collectionArrSecond.map((_, i) => {
              const isCaptured = THUserStore?.collection?.[
                `special${
                  currentTab.charAt(0).toUpperCase() + currentTab.slice(1)
                }`
              ]?.find((item) => {
                return item?.emojiId === i
              })

              return (
                <THCollectionItem
                  key={i}
                  type={`special${
                    currentTab.charAt(0).toUpperCase() + currentTab.slice(1)
                  }`}
                  emojiId={i}
                  captured={isCaptured}
                />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
})

export default THCollection
