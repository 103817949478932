import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useSearchParam } from 'react-use'

import ConfirmAlert from '../components/atoms/confirmAlert'
import RafflingModal from '../components/templates/RafflingModal'
import {
  Check,
  DefaultProfileImage,
  RaffleBackground,
  RaffleBall,
  RaffleBubble,
  RaffleExplanation,
  RedBox,
} from '../images/dailyCheckIn'
import DailyCheckInModalHandler from '../modals/dailyCheckIn/DailyCheckInModalHandler'
import UserStore from '../stores/UserStore'
import backendApis from '../utils/backendApis'

let isPressed = false
let errorCode = 0

const RafflePage = observer(() => {
  const navigate = useNavigate()
  const token = useSearchParam('token')
  const checkInAlarmConsent = useSearchParam('checkInAlarmConsent')
  const [isLoading, setIsLoading] = useState(false)

  // 모달 관련 useState
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [openAlarmConsentModal, setOpenAlarmConsentModal] = useState(false)
  const [openRafflingModal, setOpenRafflingModal] = useState(false)
  const [openPreviousWinnerModal, setOpenPreviousWinnerModal] = useState(false)
  const [openAnnouncementDueModal, setOpenAnnouncementDueModal] =
    useState(false)
  const [modalConfig, setModalConfig] = useState({ visible: false })

  const [raffleHistory, setRaffleHistory] = useState(null)
  const [enteredToday, setEnteredToday] = useState(false)
  const [raffleStatus, setRaffleStatus] = useState('')
  const [raffling, setRaffling] = useState(false)
  const [previousWinner, setPreviousWinner] = useState(null)

  const fetchData = async () => {
    setIsLoading(true)
    const result = await backendApis.getRaffleHistory()
    if (result?.status === 200) {
      setRaffleHistory(result?.data)
      setRaffleStatus('one raffle')

      if (
        !!result.data.lastEnteredAt &&
        moment(result?.data?.lastEnteredAt).isSame(moment(), 'day')
      ) {
        setEnteredToday(true)
      }
    } else {
      const proceedingRaffle = await backendApis.getProceedingRaffle()
      if (proceedingRaffle?.status === 200) {
        if (proceedingRaffle?.data === 'announcement due') {
          setRaffleStatus('announcement due')
          setOpenAnnouncementDueModal(true)
        } else if (proceedingRaffle?.data === 'no raffle') {
          setRaffleStatus('no raffle')
        } else if (proceedingRaffle?.data === 'multiple raffles') {
          setRaffleStatus('multiple raffles')
        } else if (proceedingRaffle?.data) {
          setRaffleStatus('one raffle')
          setRaffleHistory(proceedingRaffle?.data)
          if (
            !!result.data.lastEnteredAt &&
            moment(result.data.lastEnteredAt).isSame(moment(), 'day')
          ) {
            setEnteredToday(true)
          }
        } else {
          errorCode = 1
          setOpenErrorModal(true)
        }
      } else {
        errorCode = 2
        setOpenErrorModal(true)
      }
    }
    setIsLoading(false)
  }

  const fetchPreviousWinner = async () => {
    const result = await backendApis.getPreviousRaffleWinner()
    if (result?.status === 200 && result?.data) {
      const proceedingRaffle = await backendApis.getProceedingRaffle()
      setPreviousWinner(result?.data)
      setRaffling(true)
      setTimeout(() => {
        setRaffling(false)
      }, 3000)
      if (proceedingRaffle?.data === 'announcement due') {
        setOpenAnnouncementDueModal(true)
      } else {
        setOpenPreviousWinnerModal(true)
      }
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if (token) {
      fetchData()
      if (!enteredToday) {
        fetchPreviousWinner()
      }
    }
    setIsLoading(false)
  }, [token])

  const HeaderComponent = () => {
    return (
      <section className='w-full h-16 flex flex-row overflow-hidden z-10 bg-white relative items-center justify-between'>
        <button
          type='button'
          className='py-2 px-3 whitespace-nowrap'
          onClick={() => {
            if (isLoading) return
            navigate(`/daily-check-in?token=${token}`)
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
        </button>
        <h1 className='font-bold ' style={{ fontSize: '5vw' }}>
          행운번호 뽑기
        </h1>
        <div className='w-14 py-2 pr-4 whitespace-nowrap' />
      </section>
    )
  }

  const NoticeComponent = () => {
    return (
      <div className='absolute z-30 top-[12vw] w-[90vw] mx-[5vw] py-2 mt-2 mb-4 flexRow text-[3vw] bg-[#F6F6F6] font-semibold rounded-full'>
        <img
          src='/images/toon/megaphoneBlack.png'
          alt='megaphoneBlack'
          className='w-[4vw] h-[4vw] mr-[3vw]'
        />
        행운번호 뽑기는 본 회차를 끝으로 종료돼요.
        <br />더 풍부한 이벤트로 돌아올게요, 이용해주셔서 감사합니다.
      </div>
    )
  }

  const RaffleItemSection = () => {
    const finishedAt = moment(raffleHistory?.finishedAt)
    const now = moment()
    const duration = moment.duration(finishedAt.diff(now))
    const timeLeft = `${
      duration.days() > 0 ? `${duration.days()}일 ` : ''
    }${duration.hours()}시간 ${duration.minutes()}분 남음`

    if (raffleHistory) {
      return (
        <div className='w-full p-2 flex flex-col justify-center items-center z-10'>
          <img
            src={raffleHistory?.itemInfo?.imageUri}
            className='w-52 h-52 rounded-full shadow-sm animate-float'
            alt=''
          />
          <div className='w-4/5 text-center pt-2 text-sm font-bold text-gray-600'>
            {raffleHistory?.itemInfo?.brandName}
          </div>
          <div className='w-4/5 text-center pt-2 pb-4 text-2xl font-bold'>
            {raffleHistory?.itemInfo?.itemTitle}
          </div>
          {raffleHistory?.itemInfo?.sellerId && (
            <button
              type='button'
              className='flex flex-row justify-center px-3 py-1 -mt-2 mb-2 text-[3vw] font-bold text-gray-600'
              onClick={() => {
                backendApis.recordEventLog(
                  'RafflePage',
                  'clicked_SellerPageButton',
                )
                window.location.href = `#navigate.${JSON.stringify({
                  screen: 'SellerItemsScreen',
                  prop: {
                    isFromRaffle: true,
                    sellerInfo: {
                      _id: raffleHistory?.itemInfo?.sellerId,
                    },
                  },
                })}`
              }}
            >
              판매자 상품 보러가기 <FiChevronRight className='w-4 h-4' />
            </button>
          )}
          <div className='bg-[#CF8A88] w-4/5 px-2 py-1 rounded-full text-white font-semibold text-center'>
            종료까지: {timeLeft || 0}
          </div>
        </div>
      )
    }
    if (raffleHistory === null) {
      return (
        <div className=' w-full p-8 flex flex-col justify-center items-center z-10'>
          {/* <RedBox className='w-48 h-48' /> */}

          <div> 진행중인 행운뽑기가 없어요 </div>
          {/* <div className='w-2/5 h-8 bg-gray-100 text-center my-2 rounded-lg' />
          <div className='w-4/5 h-16 bg-gray-100 text-center mb-4 rounded-lg' /> */}

          <div className='bg-[#CF8A88] w-4/5 px-2 py-1 rounded-full text-white font-semibold text-center'>
            종료까지: 0일 00시간 00분 남음
          </div>
        </div>
      )
    }
    if (!raffleHistory) {
      return (
        <div className=' w-full p-8 flex flex-col justify-center items-center z-10'>
          <RedBox className='w-48 h-48' />
          <div className='w-2/5 h-8 bg-gray-100 text-center my-2 rounded-lg' />
          <div className='w-4/5 h-16 bg-gray-100 text-center mb-4 rounded-lg' />
          <div className='bg-[#CF8A88] w-4/5 px-2 py-1 rounded-full text-white font-semibold text-center'>
            종료까지: 0일 00시간 00분 남음
          </div>
        </div>
      )
    }
  }

  const EnteringHistorySection = () => {
    const dayCount = 9
    const renderCheckpoints = (count) => {
      const checkpoints = []
      for (let i = 1; i <= dayCount; i += 1) {
        checkpoints.push(
          <React.Fragment key={i}>
            <div
              className={
                count >= i
                  ? 'flex bg-[#EB4F47] rounded-full w-7 h-7 items-center justify-center'
                  : count + 1 === i && !enteredToday
                  ? 'flex bg-[#542c2b] rounded-full w-7 h-7 items-center justify-center'
                  : 'flex bg-[#E9B5B3] border-dashed	border border-[#542c2b] rounded-full w-7 h-7 items-center justify-center'
              }
            >
              {count >= i && <Check className='w-6 h-6' />}
              {count + 1 === i && !enteredToday && (
                <div className='animate-pulse w-10 h-6 text-white font-bold text-center text-base'>
                  {i}
                </div>
              )}
            </div>
            {i !== dayCount && <div className='w-2 h-1' />}
          </React.Fragment>,
        )
      }
      return checkpoints
    }

    return (
      <div className=' w-full mt-2 py-2 flex flex-col items-center z-10 text-sm'>
        {/* {enteredToday
          ? `${raffleHistory?.enteringInfo?.length || 0}번째 응모 완료!`
          : ` 오늘도 응모해보세요!`} */}
        <div className='flex flex-row items-center mt-2'>
          {renderCheckpoints(raffleHistory?.enteringInfo?.length)}
        </div>
      </div>
    )
  }

  const TipSection = () => {
    return (
      <div className='flex flex-row pt-1 text-xs z-10'>
        <div className='mr-2 font-bold text-[#EB4F47]'>TIP |</div>
        <div className='text-[#666666]'>
          매일 한 번씩 응모할 수 있어요. <br />
          응모하는 만큼 당첨 확률이 높아져요!
        </div>
      </div>
    )
  }

  const PreviousRaffleWinner = () => {
    return (
      <div className='bg-white rounded-xl'>
        <div className='w-full px-4 py-2 mb-2 flex flex-col items-center justify-center text-[4vw]'>
          <div className='flex py-2 font-bold'>당첨내역</div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/bhvmul/btr0IGNE9yd/j8ezKQfEgRnNihBTqczIU0/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                쩡*님 행운번호
                <div className='text-[#EB4F47] ml-1'>3445999</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  다이슨 슈퍼소닉 헤어드라이어
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/a0cc8de8-c234-49a9-91cb-a155d683bf04.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/d64c4127-995e-4d21-80bf-4551662d6004.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/b1a12cc0-8d39-4e06-bc47-9836f4ad0bf7.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/e3a364b3-e593-43b8-a1b5-c14b72e09629.png'
              className='rounded-lg w-16 h-16'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            혹시나 하고 하고 응모했는데 당첨될 줄 몰랐는데 진짜로 당첨돼서 너무
            너무 기쁘네요! 그것도 진짜 갖고 싶었던 다이슨 드라이기! 머리 말리는
            시간도 줄고 제 머리가 골슬기가 많은데 잔머리에도 좋고 넘 좋아요~
            이런 이벤트 하면 설마 당첨되는 사람이 있을까 생각했는데 제가 당첨될
            줄은 몰랐네요 ㅎㅎ 나중에 올웨이즈 이벤트 하면 꼭 다시 참여하고
            싶어요! 여러분도 올웨이즈 이벤트 많이 많이 참여해보세요~!
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/cD3ANS/btraVDNtzFM/Jzk0ApmFSK4Pe4jgbPnE7K/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                E*님 행운번호
                <div className='text-[#EB4F47] ml-1'>8713996</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  테팔 매직핸즈 딜라이트 인덕션 멀티 6p세트
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/2a311fd6-f6f8-4a25-b255-18488e5b8724.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/82014ae6-c497-47e7-87ba-fd837233d90c.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/954f1d61-7eeb-446c-962c-eb1dd6083896.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            당첨같은거 잘 안되는데 넘나 감격!!! 감사합니당~ 택배받고 설레는
            마음으로 뜯어봤네요ㅎㅎ 우선 색상이 맘에 들어요~ 베이지핑크에 살짝
            펄감이 도는데 요리 직후에 따로 접시에 플레이팅 하지않고 그대로
            내놔도 손색없겠어요~ 그리고 18cm 편수냄비에 실리콘재질의 뚜껑이
            포함되어 들어있는데 헐렁거리지 않고 딱 들어맞아서 냄비째 냉장고에
            보관해도 냉장고 냄새 1도 안들어가겠어요ㅋㅋ 주말에 원팬 파스타
            만들어서 먹을려구요~ 손잡이만 분리하면 되니 설거지도 줄이고 넘~
            좋을것 같아요~ 앞으로도 이런 이벤트 많이 많이 부탁드려요~ 사랑합니다
            올웨이즈♡
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/dAFOEk/btrs8aAJlOa/j55bkXkQefQygQpfHc44k1/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                안*현님 행운번호
                <div className='text-[#EB4F47] ml-1'>8322997</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  삼성 블루스카이 공기청정기 신모델
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/dEDVEK/btrA34Hwboc/KxJVEHXdBu5LrKn4MqYmkk/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                SD**철님 행운번호
                <div className='text-[#EB4F47] ml-1'>1250999</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  에르메스 디저트 플레이트 골드 (2개입)
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/73e2b0a3-3d35-4d06-b8c7-ac3cc33f8d02.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/84cadf13-20a8-4945-ac9a-a33ef4978ce0.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/f5675107-9388-4b4a-9b68-800ef0b996ed.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/f512a637-503b-429e-8a6d-aec2a2910cda.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            경품 당첨이되다니~넘나 감격이었어요. 설레는 마음으로 기다리던 경품을
            받았는데 얼마나 꼼꼼하게 포장하셨는지 포장 뜯는데 한참 걸렸네요~
            사이즈 걱정이 많았는데 사이즈도 딱 좋고 골드빛이 영롱하네요.
            올웨이즈에서 전화주신분도 친절하셨구요. 다른기회에 또 당첨되면
            좋겠네요. 잘 사용하겠습니다~~^^
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <DefaultProfileImage className='rounded-full w-8 h-8 mr-2' />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                김*주님 행운번호
                <div className='text-[#EB4F47] ml-1'>5515998</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>삼성전자 Full HD TV</div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/abeed3ac-9954-489b-8707-78b19d319e3f.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/3b94a6fc-7edf-4f14-b5ae-6342b363497b.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/c7ab6f1e-2c46-4128-a22a-a69fa0dd1d16.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            응모해서 당첨된 적은 처음이어서 엄청 신기했어요!! 당첨되고도 믿기지
            않다가 배달기사님한테 연락 받으니 그제서야 실감 나더라구요. 제품
            설치 전에 새제품인 거 확인하고 설치 진행해주셨고, 티비도 올해 생산된
            따끈따끈한 제품이었답니다. 지금은 그냥 바닥에 두었지만 방 정리가
            되면 티비장 위에 예쁘게 올려두려구요. 티비도 43인치로 방에서 보기에
            너무 크지도 작지도 않은 사이즈여서 더욱 좋았어요. 올웨이즈 덕분에
            생각지도 못한 선물을 받은 느낌입니다. 앞으로도 이벤트 많이많이
            부탁드려요~
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/mf7Im/btsdtFJ08Wo/MkgKh8n3XVErR4JHJKLMT0/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                홍*민님 행운번호
                <div className='text-[#EB4F47] ml-1'>7424999</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  신세계 상품권 10만원권
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/wmzJI/btroSuqnplW/mmuzizgTxePy8kndSMXUBK/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                홍*숙님 행운번호
                <div className='text-[#EB4F47] ml-1'>8026879</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  LG전자 디오스 오브제 전자레인지 20L
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/8226f20d-04f7-4142-b258-f3890b65a60d.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/faf3849d-8c93-4f87-81fb-fa80d7aa773a.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/6c9a9470-75a3-4da2-92d9-84bab602252a.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            드디어 저도 당첨의 기회를 받았네요! 오호 ㅎㅎ 믿기지 않는 당첨
            문자에 의심 반 기쁨 반이었는데 진짜 이렇게 배송이 오다니 너무
            좋네요! 전에 쓰던 전자레인지보다는 작지만 오랜만에 새 제품 쓰는
            기쁨이 엄청 좋아요 저에게도 이런 기회가 생기다니.. 올웨이즈 출석체크
            최고예요 또 당첨되고 싶어요 앞으로도 이런 이벤트 많이 해주세요!
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <DefaultProfileImage className='rounded-full w-8 h-8 mr-2' />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                이님 행운번호
                <div className='text-[#EB4F47] ml-1'>8211590</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  한경희 오브제 UV살균 복합식 가습기
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/38423e7b-2f67-4c0f-8ac0-616cb5fd8d1d.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/38deec4b-91f7-446a-90f6-c019f866aaae.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/b19852d9-79d1-4038-9537-491a70ed5af3.png'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            당첨돼서 너무 기분좋습니다 응모하기 며칠전부터 딸아이가 방이 너무
            건조하다고해서 가습기를 장만할까 하는 찰나에 응모하는게 있어 혹시나
            하고 했는데 당첨될 줄이야 ㅎ 너무 감사합니다 당첨되고도 혹시나
            했는데 어제 받아보고 실감이 났어요 한경희 제품 가끔 쓰는데 가습기
            너무 좋습니다 올겨울 건조하지 않게 잘 보낼 것 같아요 번창하세요^^
            다시 한 번 감사드립니다.
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/FIVUG/btr65Bsy5QK/2gFhuSGDmLeZWjmfErrkV1/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                김*남님 행운번호
                <div className='text-[#EB4F47] ml-1'>4523999</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  에디르 가정용 전기히터 오방난로
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/d0d2be89-ef75-4bc8-a586-76b4b1af42b6.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/b3c2dc7d-3870-466a-842d-137d05337a92.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/3897b754-bffb-46ba-9e3d-460e9430ed2f.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/ef97c8f8-75af-460e-b5da-e14acc177ecd.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            올웨이즈 난로 경품에 담청되어 너무 좋아요~~ 모양도 이뿌고 따뜻한게
            정말 좋더라구요~~ 고구마도 구워먹으니 너무 맛있더라구요^^ 귤이랑
            이것저것 다 올려놔보려고 합니다 ㅎㅎ 올 겨울 오방난로로 따뜻하게
            보낼것 같아요 여러분도 많이 참여하세요~~~
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://assets.ilevit.com/cb949803-449a-4bed-b2ae-441d32cb0275_thumbnail.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                박*연님 행운번호
                <div className='text-[#EB4F47] ml-1'>7827274</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  테팔 무선청소기 에어포스 라이트
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/009277e2-79bb-4bae-bed0-476217495a32.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/0b395075-ce11-4d74-82a0-bce368a971bd.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/d897ffb9-d94d-4aeb-b9f0-6dc9f10d518f.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            살면서 당첨된적은 처음이라 매우 기쁘면서 실감이 나지 않았는데 문자
            상품을 받고나니 실감이나네요. 포장도 튼튼하게 왔구요 성능도 매우
            좋아요. 아들이 튼튼하고 간편해서 좋다고 자기방으로 가져갔네요
            출석체크 꼬박꼬박해서 또 당첨도전 해볼구요^^ 감사합니다~올웨이즈♡♡
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/e628a837-c494-4345-9101-08d1b8e3d0b7_thumbnail.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                쏭*님 행운번호
                <div className='text-[#EB4F47] ml-1'>9512964</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  네스프레소 버츄오 팝 캡슐커피머신
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/1291d7da-a54f-4600-954e-5f39340dc647.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/b271166c-6a0d-4ebb-9c1c-d58a9c9adc17.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/6144d977-e427-4d59-9b4b-79633523206e.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/f5f73974-be43-4490-9148-185d0630b474.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            캡슐커피머신은 전부터 사고팠는데.. 필수 가전이 아니다보니 고민만
            하다 돌아서곤 했는데 이렇게 당첨이 되다니..ㅎㅎ 올웨이즈 당첨
            문자보고 하루종일 기분도 좋고, 배송도 직원분과 연락후 다음날 바로
            왔답니다~~ 바로 씻어서 말린후 마셔봤는데.. 빠르고 맛도 좋았어요~~
            캡슐 다 먹어보고 젤 맛있는걸로 잔뜩 주문하려구요~~ 매번 응모는
            하면서도 번호가 없어져서 되기는 하는건지 의심했는데 여러분 진짜
            됩니다~~ 잊지말고 꼬박꼬박 응모해보세요♡
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/cgQYIs/btr0HRo31SO/AzKxEagUYmApZW3LQmfRf1/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                꾹님 행운번호
                <div className='text-[#EB4F47] ml-1'>5820512</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  니봇 프루나 벽난로 히터
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <DefaultProfileImage className='rounded-full w-8 h-8 mr-2' />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                박*주님 행운번호
                <div className='text-[#EB4F47] ml-1'>5625533</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  농협라이블리 한우 선물세트 프리미엄 4호
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/3348342d-4dd3-4cbd-88b1-8f74629da899.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/6b6f460c-88dc-4540-9b73-c58a4dd7cfa1.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/a32c500e-187d-4559-8b17-ef4c1969a97c.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/f1ac8f62-e246-44e2-a783-12e23232d064.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            반신반의 하는 마음으로 응모을 했는데 당첨되니 너무 기뻤어요. 한우을
            받아 보는 순간 실감이 나더라구요. 포장이 잘 되어 있고 비닐이 잘
            떼어져서 위생적이었어요. 한우을 먹어보니 너무 맛이 좋아서 다먹고
            나니 아쉬웠어요. 더 먹고 싶었어용~ 한우 종류가 4가지라 좋았어요~
            덕분에 골고루 먹었어용~ 올 웨이즈 행복하세요~^^~♡
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d9a2da73-105d-4c7d-bf80-9c3191a977f7.png'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                김*희님 행운번호
                <div className='text-[#EB4F47] ml-1'>6033517</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  네스카페 돌체구스토 인피니시마 커피머신
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/73338431-cc66-4bd2-816e-34b22435b938.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/5755a8ee-a23d-45bf-8628-3d8b376dccf4.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/bd31b342-3037-4d27-b826-58707963a808.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            네스프레소 머신은 있어서 돌체구스토 머신도 써보고 싶었는데 열심히
            출석체크 응모했더니 귀여운 캡슐머신이 당첨 되네요! 배송도 주소지
            보내드린 다음에 빠르게 왔고 잘 세척해서 사용하겠습니다~
            감사합니다~~!!
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/380e5312-4f97-4406-ab48-846ba6135873_thumbnail.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                나님 행운번호
                <div className='text-[#EB4F47] ml-1'>2810435</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  버버리 체크 캐시미어 스카프
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/b6b2dfa9-0f4c-4289-bf39-4681ced0b2b6.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/fef19980-bb0e-4207-b6c6-3c55e9d93a48.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/c5247bcf-dab0-41e7-abe8-57fd58cdbbc7.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            그동안 싱싱한 먹거리와 재미난 게임 거기에 더해 가족과 지인들의
            안부까지 물을 수 있던 올웨이즈 덕에 소소한 재미로 삶의 활력을
            더했는데 2024년 새해에 마법같은 일이 일어났어요~~ 경품 당첨은 나와는
            상관없는 일인줄 알았는데 어디에나 잘 어울리는 버버리 머플러를 선물로
            받게 되었어요~~ 너무 행복하고 감사합니다~~ 앞으로 더욱 더 번창하는
            올웨이즈를 기대하겠습니다
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/bhxTBV/btr7e1yZ2uL/XAdkJMC0onZSkc7e6adnl0/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                우*하늘♡님 행운번호
                <div className='text-[#EB4F47] ml-1'>8021430</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>샤오미 3H 공기청정기</div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/e6e02d5a-99e7-4f94-93d4-760f15a2cb22.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/82eb765e-13cc-44cf-940f-d822d43273fe.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/199d97c2-8375-4dfc-8d5d-3c9aa4568563.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            태어나서 처음으로 이벤트 당첨이됐습니다~! 처음 연락받고 얼떨떨
            했는데 상품 받고 이제야 실감이 나네요! 기존에 쓰던 공기청정기
            고장으로 봄에 쓰기위해 AS받을려고 준비하고있었는데 웬걸~ 공기청정기
            당첨이 되었네요 제품은 심플하게되어있어 어느집에나 무난하게
            놓을수있고 제가 생각했던 크기보다 큽니다. 사용설명 간단하게 되어있어
            사용하는데 무리없이 잘 쓸수있을것같습니다. 올웨이즈 이벤트
            너무좋아요~ 덕분에 가족들 모두 집안공기는 안심 됩니다~^^ 또 이벤트
            당첨되면 좋겠어요~ 올웨이즈 응원합니다~!
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d9a2da73-105d-4c7d-bf80-9c3191a977f7.png'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                이*꼼비님 행운번호
                <div className='text-[#EB4F47] ml-1'>3930427</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>한우 암소 선물세트</div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/11f910cc-8ef1-4298-86b5-24ef1a252022.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/d16545ec-47d4-4300-b463-5d37921700af.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/fc9b8b1e-c3bd-45e1-9f53-f15864068cd0.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            혹시나하는마음으로 경품응모~~ 당청문자왓으나 혹시 스팸문자 ㅋㅋㅋ
            그것도 잠시 저한테도 이런기회가오네요 ㅎ 감사합니다 요번연휴
            식구들과맛나게먹을께요 올웨이즈번창하시구~~ 또 당첨되는그날까지 욜심
            응모할랍니다^^
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d9a2da73-105d-4c7d-bf80-9c3191a977f7.png'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                이*주님 행운번호
                <div className='text-[#EB4F47] ml-1'>1010482</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>정관장 홍삼보가</div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/faf73aa6-90a7-477c-8fcc-1e19e9ecedd0.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/0d532269-2bc2-49aa-ab88-98d76d213b02.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/cc976471-3cfa-46e8-ac7d-5f2c5494fc57.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            올팜을 하면서 한번도 당첨된 적이 없어서 거짓말이라고 믿었는데 진짜로
            당첨되어서 놀랐어요~ 설마 설마 했는데 이런 행운이 나에게
            오다니~~~~^^ 감사합니다~ 앞으로 올팜 열심히 해야겠어요~!!
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/84bf5611-ffe4-446b-9bf8-ee1d5f4dd086_thumbnail.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                베*이님 행운번호
                <div className='text-[#EB4F47] ml-1'>8832351</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>삼성전자 HD TV</div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/a6eca159-0827-44b8-b943-2bbe15b41fa4.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/74b947fb-df99-4381-9f16-3bc1a05c6da0.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/d77ac36f-33e8-4198-a67f-c79bd7ad736d.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            사실 초반에는 응모 매일매일 열심히했는데 당첨이안되길래
            꾸준히안했었는데..당첨문자받고 반신반의했어요. 설치받고나니 실감이
            나네요 에너지효율1등급이라니 더 맘에듭니다 좋은상품 보내주셔서
            감사해요
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/ddu3ms/btr1pXI4hvb/IH7G4RCckTHklcdU0SKatk/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                이*진님 행운번호
                <div className='text-[#EB4F47] ml-1'>8848863</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  쿠쿠 에어쉐프 오븐형 에어프라이기
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/a7fbdb3a-9d1c-445e-88cb-2772494c8cbc.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/58520cda-96fe-4b70-88a0-66726d06e7d7.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/07b9b9b2-aa87-43e3-87fe-c1b38a791c8a.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            경품 연락 문자 보고 스팸인 줄 알았는데 제가 당첨이 되었네요 기존에
            쓰고있던 에어프라이어보다 기능이 많아서 편리하게 사용할 수 있을 것
            같아 너무 좋아요 올팜 감사합니다♥ 올팜 가까운 지인들에게 홍보 많이
            하겠습니다 올팜 화이팅!
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/e543051c-a72d-4c7b-9c3d-9054eeb217e7_thumbnail.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                까*까도님 행운번호
                <div className='text-[#EB4F47] ml-1'>9958984</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>쿠쿠 식기세척기</div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d9a2da73-105d-4c7d-bf80-9c3191a977f7.png'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                K.*.A님 행운번호
                <div className='text-[#EB4F47] ml-1'>7927939</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>에어팟프로 2세대</div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/07461d66-508b-4db4-be69-aebf15536d61.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/4f723800-da5d-4c4b-9a37-94c2053989c1.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/92567f2d-28a5-4313-af3b-19357c29b43e.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            습관적으로 꾸준히 경품 응모를 하다보니 내게도 이런 행운이
            찾아오네요~~ 깔끔한 포장안에 에어팟과 충전케이블 그리고 케이스까지
            알차게 들어있었고 갖고 싶었던 제품을 경품으로 받아서 너무 기분이
            좋네요^^ 정말 고맙습니다.올웨이즈♡ 앞으로도 이벤트 참여 열심히
            해야겠어요~~~
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://assets.ilevit.com/079b319e-42be-485e-bf74-6201997ffb0a_thumbnail.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                난 *~!!님 행운번호
                <div className='text-[#EB4F47] ml-1'>4557090</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>헬리녹스 체어투</div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/bdd5IX/btsARg1SPHM/YHI4uE3WsoDkd5WNQH3ch1/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                전*진님 행운번호
                <div className='text-[#EB4F47] ml-1'>6081757</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>갤럭시 워치6</div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/c99d04bc-fa6f-43ba-81a1-6751c7d5a9a8.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/11d8f787-1a67-4833-bf5e-c78498a51c60.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/01e2ea5d-01b3-4382-b9f5-3a743290e17b.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            와~이런건 누가 당첨되나 항상 신기해 하면서 습관처럼 응모했었는데
            제가 당첨이 되어서 정말 놀랐습니다! 사실 당첨 연락을 받고도 에이
            설마 진짜인가 싶어서 의심했었어요 가족들과 올웨이즈를 항상 열심히
            하고 있었지만 제가 당첨된 것을 보고 다들 더 열심히 응모하고
            있습니다ㅋㅋ 갤럭시워치 새로 나온 것을 보고 살까말까 고민중이었는데
            이렇게 받게 되어서 정말 기쁩니다~잘 사용하겠습니다 감사해요
            올웨이즈! 앞으로도 열심히 응모해보겠습니다
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d9a2da73-105d-4c7d-bf80-9c3191a977f7.png'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                박*님 행운번호
                <div className='text-[#EB4F47] ml-1'>9620929</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  샤오미 스마트 공기청정기
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/a57e61e2-fe7d-4ff5-a9c0-259c5aafb89a.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/0864bc2b-ad6c-4d7b-96d2-c2cb19567e2f.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/613f46b4-9499-44b8-b94e-9ab2f79dbbee.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            항상 응모하고 있었는데 마침 공기청정기가 상품으로 있어서 가지고
            싶더라고요. 요즘 미세먼지도 심한데 1대 꼭 가지고 싶다는 마음으로
            계속 눌렀는데, 설마 당첨되다니 너무 기분이 좋았어요. 요즘
            회사사람이나 가족들과도 다 같이 올웨이즈하면서 좋은 상품이나 농작물
            수확하는 재미도 컸는데, 당첨되니까 더욱 재미있고 기분이 좋네요.
            상품은 포장부터 완전 깔끔하고 상태도 이상없이 완전s급! 깨끗한
            공기마시면서 생활중입니다. 마지막으로 감사한 마음 전하면 올웨이즈
            크게 번창하길 기원합니다.
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/951761ec-224f-488f-801f-e5a1b6e9800e_thumbnail.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                마*까님 행운번호
                <div className='text-[#EB4F47] ml-1'>1668710</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  보아르 모아 공기청정제습기
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://assets.ilevit.com/e0a1a1a1-b6bc-4735-88ef-828209ec347f_thumbnail.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                한*님 행운번호
                <div className='text-[#EB4F47] ml-1'>8811082</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>버뮤다 무엽 선풍기</div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/21d0a2c9-6b63-45be-b60e-e1f1434d2492.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/80cc0009-b941-4187-b500-ed1631ffe072.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/99c7565e-c9ba-4d60-ae82-0a1724836d9d.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            새끼강아지가 있는 집인데 요즘 갑자기 더워진 날씨에 날개 있는
            선풍기를 틀어주니 너무 싫어해서 난감했었는데 이 제품은 날개가 없어서
            직접적인 바람이 없어서 강아지도 시원해하고 좋아하네요ㅎㅎ 선풍기가
            가습기 역할도 하고 uv살균도 된다고 하니 완전 만능입니다! 거기다
            터치작동에 리모컨까지 있다니 완전 대박이지 않나요,, 반려견이나 아기
            있는 가정에 여름필수템이 될 듯 하네요. 좋은 제품 경품으로 주셔서
            감사합니당 ◡̈
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/nlYBr/btrUuGU8hqx/K1n5sbDUJbCLLtWQdJbPrK/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                쩡님 행운번호
                <div className='text-[#EB4F47] ml-1'>7220962</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>신세계 상품권 10만원</div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/4f394c68-3bd4-4f65-85ff-f61580bdb644.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/c2897362-df7b-4c2c-8a37-be6794f029ab.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/c6f821e0-da9d-49fd-a4a5-68d2577e1314.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            올팜에서 "신세계백화점 상품권" 당첨!! 이마트에서 장 봤어요^^ 그런데
            이마트에서 장보고 결제할 때 약간의 불편함??이 있더라구요 ㅜㅜ
            신세계백화점 상품권을 이마트에서 사용 할때는 신세계백화점에서 상품권
            발행후 이마트에서 써야 하더라구요. 다행히 가까운곳에 신세계 백화점이
            위치해 있어서 냉큼 신랑이랑 다녀왔죠. 그리고 필요한 식재료들로
            장본걸 결제~~ 넘 요긴하게 잘 사용 했어요 감사합니다~~
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/2289718e-14e0-47b6-ba27-890be50a287b_thumbnail.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                정*님 행운번호
                <div className='text-[#EB4F47] ml-1'>3765694</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  도그독 프리미엄 특대형 폴딩카트
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <img
              src='https://assets.ilevit.com/b254ce0b-9ebd-4f75-9ec1-61d2a45ee3ca.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/a10b07d2-9d61-450d-9552-2895c216fbe7.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
            <img
              src='https://assets.ilevit.com/1a3831b0-205f-4bbf-b714-a7e0c428ec51.jpeg'
              className='rounded-lg w-16 h-16 mr-2'
              alt=''
            />
          </div>
          <div
            className='m-4 p-2 bg-gray-100 rounded-lg'
            style={{ fontSize: '2.6vw' }}
          >
            매번 응모할때 마다 될까? 하는마음으로 응모했는데 어머나 딱 당첨
            됐다고 연락이와서 너무 놀랬어요 안그래도 장보러갈때마다 기존에 쓰던
            장바구니가 너무 작아서 새로 사야지 했는데 바퀴소음도 작고 길에서도
            신나게 끌고 다니고 도서관 갈때도 참 유용할거같아요~ 덕분에 살림장만
            했네요 너무 감사합니다 잘쓸게요^^ 번창하세요 올팜
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/OgkcG/btsCMwB2tV8/mk4IMEhixRTKDomUI606dK/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                류*율님 행운번호
                <div className='text-[#EB4F47] ml-1'>9410234</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  명품 한우 1++ 소고기 선물세트
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d9a2da73-105d-4c7d-bf80-9c3191a977f7.png'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                나*님 행운번호
                <div className='text-[#EB4F47] ml-1'>7014270</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  한경희이지라이프 음식물 쓰레기 냉장고
                </div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/MKMDi/btrX6oOXKk0/1fS2KNeFFlf2SvknSnVjMK/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                종*님 행운번호
                <div className='text-[#EB4F47] ml-1'>9348812</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>삼성전자 HD LED TV</div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d9a2da73-105d-4c7d-bf80-9c3191a977f7.png'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                홍*호님 행운번호
                <div className='text-[#EB4F47] ml-1'>6210394</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>무풍 냉풍기</div>
                당첨!
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-start text-[4vw] font-semibold w-full p-4'>
            <img
              src='http://k.kakaocdn.net/dn/boy1Is/btrMrSAwYZw/EkGKGeOiBmofGnkvCUgSqk/img_110x110.jpg'
              className='rounded-full w-8 h-8 mr-2'
              alt=''
            />
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                유*숙님 행운번호
                <div className='text-[#EB4F47] ml-1'>1556712</div>
              </div>
              <div className='flex flex-row text-[3vw] text-[#716F71]'>
                <div className='text-[#EB4F47] mr-1'>
                  스탠리 어드벤쳐 쿨러 아이스박스
                </div>
                당첨!
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const GetNumberButton = () => {
    if (raffleStatus === 'no raffle') {
      return (
        <button
          type='button'
          className='w-full py-4 my-4 bg-[#EB4F47] text-white rounded-lg font-bold shadow-lg z-10'
          onClick={async () => {
            if (isLoading) return
            navigate(`/daily-check-in?token=${token}`)
          }}
        >
          뒤로가기
        </button>
      )
    }
    return (
      <button
        type='button'
        className={
          enteredToday
            ? 'w-full py-4 my-4 bg-[#BCA1A0] text-white rounded-lg font-bold shadow-sm z-10'
            : 'animate-zoom-slow w-full py-4 my-4 bg-[#EB4F47] text-white rounded-lg font-bold shadow-lg z-10'
        }
        onPointerDown={() => {
          if (isPressed) {
            return
          }
        }}
        onClick={async () => {
          if (isPressed) return
          isPressed = true
          setIsLoading(true)
          const result = await backendApis.logEnteringRaffle(
            raffleHistory?.itemId,
          )
          if (result?.status === 200) {
            fetchData()

            setOpenRafflingModal(true)
            setRaffling(true)
            setTimeout(() => {
              setRaffling(false)
            }, 3000)
          } else {
            errorCode = 3
            setOpenErrorModal(true)
          }
          isPressed = false
          setIsLoading(false)
        }}
        disabled={enteredToday}
      >
        {enteredToday ? '내일도 응모하면 당첨확률이 높아져요' : '응모하기'}
      </button>
    )
  }

  const TimelineSection = () => {
    const weeklyStampDayNameGenerator = (date) => {
      const engDayName = moment(date).format('ddd')
      switch (engDayName) {
        case 'Mon':
          return '월'
        case 'Tue':
          return '화'
        case 'Wed':
          return '수'
        case 'Thu':
          return '목'
        case 'Fri':
          return '금'
        case 'Sat':
          return '토'
        case 'Sun':
          return '일'
        default:
          return '월'
      }
    }

    return (
      <div className='py-2 flex flex-col items-start text-sm z-10'>
        <div className='flex flex-row items-center text-[#525252]'>
          응모 기한 |
          <div className='font-semibold ml-1 text-black'>
            {moment(raffleHistory?.finishedAt)
              .add(-1, 'day')
              .format('YYYY.M.D')}
            (
            {weeklyStampDayNameGenerator(
              moment(raffleHistory?.finishedAt).add(-1, 'day'),
            )}
            )까지
          </div>
        </div>
        <div className='flex flex-row items-center text-[#525252]'>
          당첨 발표 |
          <div className='font-semibold ml-1 text-black'>
            {moment(raffleHistory?.finishedAt).format('YYYY.M.D')}(
            {weeklyStampDayNameGenerator(raffleHistory?.finishedAt)})
          </div>
        </div>
      </div>
    )
  }

  const MyNumberSection = () => {
    return (
      <div className='flex flex-col w-full rounded-t-2xl px-2 py-4 mt-4 items-center bg-white font-bold z-10'>
        <div className='py-2 text-black text-base'>내가 응모한 행운번호</div>
        {raffleHistory?.enteringInfo?.map((item, index) => {
          return (
            <div
              className='flex flex-row items-center text-base p-4 border-b border-gray-300 w-full text-start'
              key={item?.uniqueNumber + Math.random()}
            >
              <RaffleBall className='w-8 h-8 mr-2' />
              {index + 1}번째{' '}
              <div className='text-[#EB4F47] ml-2'> {item?.uniqueNumber}</div>
            </div>
          )
        })}
      </div>
    )
  }

  const NoticeSection = () => {
    return (
      <div
        style={{
          borderColor: '#F5F5F5',
          borderTopWidth: 12,
          backgroundColor: 'white',
          color: '#999999',
          fontSize: 12,
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
        }}
      >
        <div
          style={{
            fontSize: 14,
            fontWeight: 'bold',
          }}
        >
          행운번호 뽑기 유의사항
        </div>
        <div
          style={{
            fontWeight: 'normal',
            color: '#999999',
            paddingBottom: 8,
          }}
        >
          • 본 이벤트는 올웨이즈 회원 중 일부 회원만 참여할 수 있는 이벤트로,
          보이지 않는 경우가 있을 수 있어요. <br /> • 본 이벤트의 경품이나 일정
          등의 진행 내용은 사전 고지 없이 변경 또는 종료될 수 있어요. <br /> •
          응모권은 본 페이지에서 '응모하기' 버튼을 눌러야만 지급되며, 하루 1개만
          지급돼요. <br /> • 기술적 결함을 악용하는 등의 부당한 방법을 통해
          이벤트에 참여하는 경우 경품에 당첨되더라도 경품이 지급되지 않을 수
          있어요. <br /> • 경품은 무작위 추첨을 통해 지급되며, 당첨 결과는
          당첨자에 한하여 경품별로 표기된 당첨 발표 기간에 개별 연락드려요.
          <br />• 당첨번호를 2명 이상 뽑은 경우, 해당 상품에 더 많이 응모한
          회원이 당첨자가 되며, 응모 횟수가 동률인 경우 해당 상품 첫 응모가 빠른
          회원이 당첨자가 돼요.
          <br />• 당첨자에게 서류 요청 후, 72시간 이내에 회신이 없으면 당첨이
          취소되며, 재추첨은 진행되지 않아요.
          <br />• 경품의 제세공과금(22%)은 당첨자가 부담하며, 당첨자가 이에
          동의하지 않는 경우 당첨이 취소되며, 재추첨은 진행되지 않아요.
          <br />• 당첨자는 당첨된 상품에 대해 사진 후기를 작성해주셔야 하며,
          미작성 시 추후 올웨이즈에서 진행하는 이벤트에서 불이익을 받을 수
          있어요. (사진 3장, 내용 100자 이상)
          <br />• 문의는 내 정보 {'>'} 고객 문의 {'>'} 1:1 카카오톡에서 하실 수
          있어요.
        </div>
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed bg-gray-800 opacity-70 z-10 inset-0 w-full h-full' />
        <div style={{ left: '40%', top: '40%' }} className='z-20 fixed'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className={
        openRafflingModal || openPreviousWinnerModal || openAnnouncementDueModal
          ? 'bg-[#F9DBDA] fixed w-full h-full'
          : 'bg-[#F9DBDA] w-full'
      }
    >
      <RaffleBackground className='w-full h-full absolute top-16 left-0 z-10 ' />
      <HeaderComponent />
      <NoticeComponent />
      <RaffleBubble className='w-full absolute top-16 left-0 z-10 animate-float' />
      {raffleStatus === 'one raffle' && (
        <div className='flex flex-col items-center px-6 pt-8 pb-4 z-20'>
          <RaffleItemSection />
          <EnteringHistorySection />
          <TipSection />
          <GetNumberButton />
          <TimelineSection />
          <div className='relative mt-4 py-2 z-10'>
            <RaffleExplanation className='' />
          </div>
          <div className='w-full relative mt-4 py-2 z-10'>
            <PreviousRaffleWinner />
          </div>
        </div>
      )}

      {raffleStatus === 'no raffle' && (
        <div className='relative flex flex-col items-center px-6 py-8 z-20'>
          <div className='animate-float flex items-center text-2xl font-bold text-center h-[25vh]'>
            아직 진행중인 <br /> 행운뽑기가 없어요
          </div>
          <GetNumberButton />
          <div className='relative mt-4 py-2 z-10'>
            <RaffleExplanation className='' />
          </div>
          <div className='w-full relative mt-4 py-2 z-10'>
            <PreviousRaffleWinner />
          </div>
        </div>
      )}

      {raffleHistory?.enteringInfo?.length > 0 && <MyNumberSection />}
      <NoticeSection />

      {isLoading && <LoadingIndicator />}
      {openLoginModal && (
        <ConfirmAlert
          modalTitle='로그인을 해주세요'
          modalContent='로그인을 해주셔야 출석체크를 사용할 수 있어요.'
          buttonText='확인'
          buttonLink={() => {
            setOpenLoginModal(false)
            window.location.href = `#navigate.${JSON.stringify({
              screen: 'MainBMyInfoScreen',
            })}`
          }}
          showModal
          setShowModal={setOpenLoginModal}
          onlyOneButton
          noFontWeight
        />
      )}
      {openErrorModal && (
        <ConfirmAlert
          modalTitle='연결이 불안정해요'
          modalContent={`인터넷 연결이 불안정해요. 잠시 후 다시 시도해주세요. ${
            errorCode > 0 ? `(코드: ${errorCode})` : ''
          }`}
          buttonText='확인'
          buttonLink={() => {
            window.location.href = `#navigate.${JSON.stringify({
              screen: 'MainALargeCategoryTabNavigator',
            })}`
            setOpenErrorModal(false)
          }}
          showModal
          setShowModal={setOpenErrorModal}
          onlyOneButton
        />
      )}

      {openAnnouncementDueModal && (
        <RafflingModal
          modalName='preparingWinner'
          previousWinner={previousWinner}
          raffleHistory={raffleHistory}
          raffling={raffling}
          setOpenRafflingModal={setOpenAnnouncementDueModal}
        />
      )}
      {openPreviousWinnerModal && (
        <RafflingModal
          modalName='announceWinner'
          previousWinner={previousWinner}
          raffleHistory={raffleHistory}
          raffling={raffling}
          setOpenRafflingModal={setOpenPreviousWinnerModal}
        />
      )}
      {openRafflingModal && (
        <RafflingModal
          modalName='raffling'
          raffleHistory={raffleHistory}
          raffling={raffling}
          checkInAlarmConsent={checkInAlarmConsent}
          setOpenRafflingModal={setOpenRafflingModal}
          setOpenAlarmConsentModal={setOpenAlarmConsentModal}
        />
      )}

      {openAlarmConsentModal && (
        <ConfirmAlert
          modalTitle='내일도 잊지 않게 알려드릴까요?'
          modalContent='응모를 많이 할수록 당첨 확률이 높아져요'
          buttonText='네, 알려주세요'
          buttonLink={async () => {
            await UserStore.setCheckInNotificationSettings(true, 10)
            setOpenAlarmConsentModal(false)
          }}
          showModal
          setShowModal={setOpenAlarmConsentModal}
        />
      )}

      {modalConfig?.visible && (
        <DailyCheckInModalHandler
          setModalConfig={setModalConfig}
          {...modalConfig}
        />
      )}
    </div>
  )
})

export default RafflePage
