import { AppScreen } from '@stackflow/plugin-basic-ui'
import React from 'react'
import { FiChevronLeft } from 'react-icons/fi'

import THImageText from '../components/treasureHunt/THImageText'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'

/**
 * 올툰 서비스 이용약관
 */
const AltoonTermsOfUse = () => {
  const { pop } = useMyFlow()
  const HeaderComponent = () => {
    return (
      <section className='w-full h-16 bg-white z-10 py-2 flex flex-row items-center justify-between overflow-hidden'>
        <button
          type='button'
          aria-label='back'
          className='px-3 py-2 whitespace-nowrap'
          onClick={async () => {
            pop()
          }}
        >
          <FiChevronLeft className='w-7 h-7 stroke-[0.2vw]' />
        </button>
        <div>올툰 서비스 이용약관</div>
        <div className='w-12' />
      </section>
    )
  }

  const TermsOfUse = () => {
    return (
      <div className='px-4 py-2 text-[3.6vw]'>
        <button type='button' className='w-full flexRow justify-between'>
          <div>올툰 서비스 이용약관</div>
        </button>
        <div>
          <THImageText
            fontSize={3}
            style={{
              color: '#8E8D9B',
            }}
            text={`
            제1조 (목적, 적용범위 등)
            ① 이 약관은 주식회사 레브잇(이하 “레브잇”)의 웹툰 서비스 “올툰”에서 콘텐츠를 이용하기 위해 사용되는 전용상품권과 관련하여 레브잇과 이용자(이하 "이용자") 사이의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.\n② 이 약관에서 정하지 않은 부분은 올웨이즈 서비스 이용약관(이하 "올웨이즈 이용약관")에서 정한 바에 따릅니다.
            
            제2조 (정의)
            ① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            - 1. "전용상품권"은 “올툰” 서비스에서 콘텐츠를 구매 또는 대여하기 위해 사용할 수 있는 레브잇이 발행한 전자적 증표 또는 그 증표에 관한 정보로서, 전자적 지급수단을 말하며 명칭은 레브잇의 결정에 따라 변경될 수 있습니다. 레브잇은 콘텐츠의 종류별로 별도의 전용상품권을 발행할 수 있으며 개별 전용상품권에 대한 정책에 따라 이를 운영합니다. 
            - 2. "작품별이용권"이라 함은 “올툰” 서비스에서 특정한 종류의 콘텐츠의 구매 또는 대여를 위해 사용할 수 있는 레브잇이 발행한 전자적 증표 또는 그 증표에 관한 정보로서, 대여모델에 사용되는 대여권과 구매모델에 사용되는 소장권으로 구분됩니다. 
            - 3. "전용상품권 등"이라 함은 전용상품권과 작품별이용권을 말합니다.
           
            제3조 (전용상품권의 구매 등)
            ① 레브잇은 이용자의 전용상품권 구매 전에 전용상품권에 대한 거래 조건을 정확하게 이해하고 실수나 착오 없이 거래할 수 있도록 다음 각 호의 사항들을 적절한 방법으로 이 약관 및 서비스 내에서 고지합니다.
              - 1. 판매자의 상호, 대표자 성명, 주소, 전화번호 등                    
              - 2. 전용상품권의 확인 및 선택, 구매 방법의 선택 및 결제정보의 입력, 구매 신청에 관한 확인, 종류 및 가격, 이용방법, 이용시기, 이용조건 등 구체적 내용                    
              - 3. 청약철회 및 계약해제의 기한・행사방법 및 효과, 환불 등에 관한 사항                    
              - 4. 소비자의 피해보상, 불만 처리 및 분쟁 처리에 관한 사항                    
            ② 레브잇은 이용자의 전용상품권 구매 신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보하거나, 추후 승낙을 취소할 수 있습니다. 
              - 1. 실명이 아니거나 타인의 명의를 이용한 경우 
              - 2. 허위의 정보를 기재하거나, 필수  사항을 기재하지 않은 경우 
              - 3. 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우 
              - 4. 동일 아이피의 대량 결제, 유사 아이디에서의 연속적인 결제, 위험 아이피에서의 결제 등 도용에 의한 전용상품권의 구매로 판단되는 경우 
              - 5. 만 19세 미만의 미성년자가 법정대리인의 동의 없이 신청한 경우 
              - 6. 결제에 대한 지급이 실제로  이루어지지 않았거나 않을 가능성이 있는 경우 
              - 7. 기타 이용자의  전용상품권 구매 신청이 레브잇과 제3자의 이익을 훼손할 위험이 있다고 판단되는 경우
            ③ 전용상품권의 구매 계약은 레브잇이 구매 완료를 신청절차 상에서 표시 또는 이용자에게 고지한 시점에 성립되며, 이때 레브잇은 제1항 각 호의 내용을 포함하여 계약체결의 내용을 즉시 이용자에게 교부합니다. 
            ④ 이용자는 이 약관 동의 및 전용상품권 구매에 따른 결제 후 서비스에서 전용상품권을 이용할 수 있습니다. 단, 구매를 전제하지 않는 무상 전용상품권 및 무상 작품별이용권의 경우에는 이 약관에 동의 후 이용할 수 있습니다. 
            ⑤ 레브잇은 이용자에게 사전에 공지하고 전용상품권 등의 종류와 가격, 지급수량 등 그 구체적인 내용을 변경하거나 특정한 전용상품권 및 작품별이용권을 폐지할 수 있습니다. 단, 그와 같은 변경 및 폐지가 이용자에게 불리한 경우에는 공지 외 “올툰” 서비스 내 전자우편 등의 전자적 수단을 통해 개별적으로 통지하여야 합니다. 
            ⑥ 레브잇이 전용상품권 등의 종류 및 내용 등을 변경 혹은 폐지함에 따라 이용자가 자신의 귀책 없이 기존에 구매한 전용상품권 등을 구매할 당시에 정한 조건 등에 따라 이용하지 못하게 될 경우, 레브잇은 그와 같이 이용하지 못한 부분에 대해서 회원에게 적절한 보상을 합니다. 
           
            제4조 (청약철회 등)
            ① 이용자는 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령에서 정한 바에 따라 유상으로 구매한 전용상품권에 대하여 계약체결에 대한 내용을 교부 받은 시점 혹은 구매한 전용상품권을 제공받은 시점으로부터 7일 이내에 청약의 철회 및 계약의 해제(이하 "청약철회등")를 할 수 있습니다. 단, 이용자가 구매한 전용상품권을 이미 사용한 경우에는 사용한 부분에 대해서는 청약철회등이 불가능하며, 이러한 불가능 사유에 대해서는 전용상품권 구매 시 레브잇이 이용자에게 명확하게 고지합니다. 
            ② 전항에도 불구하고 전용상품권의 내용이 표시・광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 이용자는 그 전용상품권을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회등을 할 수 있습니다. 
            ③ 제1항 또는 제2항에 따라서 이용자가 청약철회등을 한 경우 전자상거래 등에서의 소비자 보호에 관한 법률에 따라 이용자가 구매한 전용상품권은 반환되며, 레브잇은 동법에 따라 환급 절차를 진행합니다. 
            ④ 그 밖에 본 조에 따른 청약철회등에 대한 사항은 전자상거래 등에서의 소비자 보호에 관한 법률에 따라 규율 및 처리됩니다.
           
            제5조 (환불정책)
            ① 레브잇은 이용자가 구매한 전용상품권의 미사용분에 대해 환불을 요구할 경우 환불수수료에 해당하는 금액을 공제하고 환불할 수 있습니다. 단, 레브잇의 귀책으로 인한 경우에는 환불수수료를 공제하지 않습니다. 이용자가 전용상품권의 구매 시 함께 지급된 추가 무상전용상품권을 사용한 경우에는 유상 전용상품권의 미사용분에서 해당 분량을 제외하고 환불됩니다. 
            ② 무상 전용상품권은 전항의 환불 대상에서 제외됩니다. 
            ③ 레브잇은 이용자에 대하여 환불을 신청한 날부터 3영업일 이내에 그 결제와 동일한 방법으로 이를 환불합니다. 
            ④ 이용자가 구매한 작품별이용권의 미사용분에 대해 환불을 요구할 경우 환불을 신청한 날부터 3영업일 이내에 그 결제와 동일한 방법으로 이를 환불합니다. 단, 작품별이용권의 구매에 사용한 전용상품권 중 유효기간이 만료된 무상 전용상품권이 있는 경우 이를 제외하고 환불합니다. 
            ⑤ 무상 작품별이용권은 전항의 환불 대상에서 제외됩니다. 
            ⑥ 무상 이용권과 유상 이용권을 함께 보유한 경우, 유상 이용권부터 차감됩니다.
            
            제6조 (유효기간 등)
            ① 유상 전용상품권과 무상 전용상품권의 기본 유효기간은 충전일로부터 1개월입니다. 전용상품권 획득 시 별도의 조건 혹은 유효기간이 명시될 수 있으며, 별도의 안내가 없는 경우 기본 유효기간과 같은 것으로 간주합니다. 
            ② 유상 작품별이용권과 무상 작품별이용권의 기본 유효기간은 충전일로부터 1개월입니다. 작품별이용권 획득 시 별도의 조건 혹은 유효기간이 명시될 수 있으며, 별도의 안내가 없는 경우 기본 유효기간과 같은 것으로 간주합니다.
            
            제7조 (기타)
            ① 레브잇은 소비자의 피해보상, 불만 처리 및 분쟁 처리에 관한 사항, 환불에 대한 사항 등 소비자의 문제 제기에 대해 해결하기 위해서 고객센터를 운영합니다. 
            
            [부칙]\n 이 약관은 2023년 12월 1일부터 적용됩니다.`}
          />
        </div>
      </div>
    )
  }

  return (
    <AppScreen>
      <div className='pb-20'>
        <HeaderComponent />
        <TermsOfUse />
      </div>
    </AppScreen>
  )
}

export default AltoonTermsOfUse
