import React, { useEffect, useState } from 'react'
import { FiChevronRight } from 'react-icons/fi'

import ToastStore from '../../stores/ToastStore'
import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import backendApis from '../../utils/backendApis'
import ToonCommentComponent from './toonCommentComponent'

const ToonCommentSection = ({ toonInfo, chapterInfo, onClickSeeAll }) => {
  const [bestCommentData, setBestCommentData] = useState()
  const [myCommentData, setMyCommentData] = useState()
  const [myCommentLikedData, setMyCommentLikedData] = useState()
  const [newCommentData, setNewCommentData] = useState()
  const [myCommentBlockedData, setMyCommentBlockedData] = useState()
  const [filteredBestCommentData, setFilteredBestCommentData] = useState()
  const [filteredNewCommentData, setFilteredNewCommentData] = useState()
  const [registeredComment, setRegisteredComment] = useState(false)

  const commentRef = React.useRef()

  useEffect(() => {
    const fetchCommentData = async () => {
      // 댓글 데이터를 불러옵니다.
      const bestCommentData = await backendApis.loadComments(
        chapterInfo?._id,
        'bestWithoutMe',
        3,
        0,
      )
      const myCommentData = await backendApis.loadComments(
        chapterInfo?._id,
        'my',
        3,
        0,
      )
      const newCommentData = await backendApis.loadComments(
        chapterInfo?._id,
        'newWithoutMeAndBest',
        3,
        0,
      )
      const commentLikedData = await backendApis.loadCommentLikedData()
      if (bestCommentData?.status === 204) {
        ToastStore.networkError()
        backendApis.logToonError(
          'ToonCommentSection',
          'loadComments1',
          bestCommentData?.status,
        )
        return
      }
      if (myCommentData?.status === 204) {
        ToastStore.networkError()
        backendApis.logToonError(
          'ToonCommentSection',
          'loadComments2',
          myCommentData?.status,
        )
        return
      }
      if (newCommentData?.status === 204) {
        ToastStore.networkError()
        backendApis.logToonError(
          'ToonCommentSection',
          'loadComments3',
          newCommentData?.status,
        )
        return
      }

      // 전체 댓글 데이터를 설정합니다.
      setBestCommentData(
        bestCommentData?.data?.filter(
          (comment) => !myCommentBlockedData?.includes(comment._id),
        ),
      )
      setMyCommentData(myCommentData?.data)
      setNewCommentData(
        newCommentData?.data?.filter(
          (comment) => !myCommentBlockedData?.includes(comment._id),
        ),
      )
      // 좋아요한 댓글 아이디와 차단한 댓글 아이디를 설정합니다.
      setMyCommentLikedData(
        commentLikedData?.data[0]?.likedCommentIds || 'None',
      )
      setMyCommentBlockedData(commentLikedData?.data[0]?.blockedCommentIds)
    }
    fetchCommentData()
  }, [registeredComment, chapterInfo._id])

  useEffect(() => {
    if (myCommentBlockedData?.length > 0) {
      const filteredBestComments = bestCommentData?.filter(
        (comment) => !myCommentBlockedData?.includes(comment._id),
      )
      const filteredNewComments = newCommentData?.filter(
        (comment) => !myCommentBlockedData?.includes(comment._id),
      )
      setFilteredBestCommentData(filteredBestComments)
      setFilteredNewCommentData(filteredNewComments)
    } else {
      setFilteredBestCommentData(bestCommentData)
      setFilteredNewCommentData(newCommentData)
    }
  }, [myCommentBlockedData, myCommentData, bestCommentData, newCommentData])

  const registerComment = async () => {
    if (!commentRef.current) {
      return
    }
    const result = await backendApis.logComment(
      toonInfo?._id,
      chapterInfo?._id,
      commentRef.current,
    )
    if (result?.status === 200) {
      commentRef.current = ''
      ToastStore.toastOn({
        type: 'emoji',
        emoji: '✅',
        message: '댓글이 등록되었어요',
        duration: 2000,
      })
      setRegisteredComment(true)
    } else {
      ToastStore.toastOn({
        type: 'error',
        message: '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
        duration: 2000,
      })
    }
  }

  return (
    <div className='p-4 w-full flex flex-col items-start text-sm mb-16'>
      <div className='w-full'>
        <div className='py-2 w-full flex flex-col'>
          <div className='flex flex-row font-semibold text-sm items-center'>
            내 의견 달기{' '}
            <div className='ml-1 text-xs text-gray-500'>(최대 200자)</div>
          </div>
          <textarea
            maxLength={200}
            rows={4}
            id='commentInput'
            type='text'
            placeholder='주제와 무관한 댓글이나 스포일러, 악플은 경고조치 없이 삭제되며 징계 대상이 될 수 있습니다.'
            value={commentRef.current}
            onChange={(e) => {
              commentRef.current = e.target.value
            }}
            className='my-2 text-xs rounded border-[#a8a8a8]'
            style={{
              whiteSpace: 'pre-wrap',
              resize: 'none',
            }}
          />
          <button
            type='button'
            className=' self-end text-center px-2 py-2 w-1/5 border border-black font-semibold text-[3vw] rounded'
            disabled={commentRef?.current?.length === 0}
            onClick={registerComment}
          >
            등록하기
          </button>
        </div>
      </div>
      <div className='w-full'>
        {myCommentData?.length > 0 &&
          myCommentData?.map((comment, index) => (
            <ToonCommentComponent
              key={comment?._id}
              comment={comment}
              index={index}
              type='my'
              myCommentLikedData={myCommentLikedData}
              toonInfo={toonInfo}
            />
          ))}
        {filteredBestCommentData?.length > 0 &&
          filteredBestCommentData?.map((comment, index) => (
            <ToonCommentComponent
              key={comment?._id}
              comment={comment}
              index={index}
              type='best'
              myCommentLikedData={myCommentLikedData}
              toonInfo={toonInfo}
            />
          ))}
        {filteredNewCommentData?.length > 0 &&
          filteredNewCommentData?.map((comment, index) => (
            <ToonCommentComponent
              key={comment?._id}
              comment={comment}
              index={index}
              type='newWithoutMeAndBest'
              myCommentLikedData={myCommentLikedData}
              toonInfo={toonInfo}
            />
          ))}
        {/* {(myCommentData?.length > 0 || filteredBestCommentData?.length > 0) && ( */}
        <button
          type='button'
          className='w-full flex flex-row justify-center items-center border rounded my-2 py-2 text-center'
          onClick={() => {
            onClickSeeAll()
          }}
        >
          <div>전체 댓글 보기 </div>
          <FiChevronRight className='' />
        </button>
        {/* )} */}
      </div>
    </div>
  )
}
export default ToonCommentSection
