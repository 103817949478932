import { observer } from 'mobx-react-lite'
import React from 'react'

import THModalStore from '../../../stores/treasureHunt/THModalStore'

const ToonModal = observer(({ config }) => {
  return (
    <div className='relative justify-center items-center'>
      <button
        type='button'
        aria-label='banner'
        className='flex flex-col justify-center items-center flex-1'
        onClick={() => {
          if (config?.navigateTo) {
            config?.navigateTo()
          }
          THModalStore?.handleClose()
        }}
      >
        <img alt='' src={config?.src} className='w-full h-full' />
      </button>

      <div className='absolute -bottom-[10vw] flex flex-row justify-between w-full text-white font-bold text-[4vw]'>
        <button
          type='button'
          onClick={() => {
            if (config?.onClickReject) {
              config?.onClickReject()
            }
            THModalStore?.handleClose()
          }}
          className='pr-[4vw] py-[2vw]'
        >
          다시 보지 않기
        </button>
        <button
          type='button'
          onClick={() => THModalStore?.handleClose()}
          className='pl-[4vw] py-[2vw] flex items-center'
        >
          닫기
          <img
            alt=''
            src='/images/close.png'
            className='w-[4vw] h-[4vw] ml-[2vw]'
          />
        </button>
      </div>
    </div>
  )
})

export default ToonModal
