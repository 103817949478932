import { observer } from 'mobx-react-lite'
import React from 'react'
import { Close, ModalPig } from 'src/images/dailyCheckIn'
import ModalContents from 'src/modals/checkInPig/modalContents'

const CheckInPigModal = observer(
  ({ visible, close, contents, triggerFoodToast, ...params }) => {
    const PigImage = () => (
      <ModalPig className='w-20 h-20 absolute left-1/2  -translate-x-1/2 -top-24' />
    )

    const Blank = () => <div className='h-6' />

    const CloseButton = ({ onClick }) => (
      <div
        className='absolute right-0 -top-10 z-20'
        aria-hidden='true'
        onClick={onClick}
      >
        <Close className='w-8 h-8' />
      </div>
    )

    return (
      <>
        <div
          className='fixed top-0 left-0 w-full h-full bg-black opacity-30 z-30'
          aria-hidden='true'
          onClick={close}
        />
        <div
          className='fixed top-1/2 left-1/2 py-6 z-40 bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
          style={{ width: '90%' }}
        >
          {/* <PigImage /> */}
          {/* <Blank /> */}
          <ModalContents
            contents={contents}
            close={close}
            triggerFoodToast={triggerFoodToast}
            params={params}
          />
          <CloseButton onClick={close} />
        </div>
      </>
    )
  },
)

export default CheckInPigModal
