import imageUriWrapper from '../../utils/imageUrlWrapper'

const ImageAtom = ({
  src,
  alt = 'ImageAtom',
  style,
  className,
  width = 0,
  height = 0,
  ...props
}) => {
  const imageUri = imageUriWrapper(src, width, height)

  return (
    <img
      src={imageUri}
      style={style}
      className={className}
      alt={alt}
      {...props}
    />
  )
}

export default ImageAtom
