import UserStore from '../stores/ApplePang/UserStore'
import ToastStore from '../stores/ToastStore'
import backendApis from '../utils/backendApis'

class ApplePangAction {
  async getRankingAll() {
    const result = await backendApis.getApplepangRankingAll()
    if (result?.status === 200) {
      UserStore.set('rankData', result?.data?.users)
      const rank = result?.data?.users?.findIndex(
        (user) => user?.userId?.toString() === UserStore?.userId?.toString(),
      )
      if (rank < 0) {
        UserStore.set('myRank', 99)
      } else {
        UserStore.set('myRank', rank)
      }
      UserStore.set('userImageUri', result?.data?.myImage)
      UserStore.set('rankLoadDone', true)
    } else {
      ToastStore.networkError()
    }
  }

  async getRankingLocal({ contacts, skip = 0 }) {
    UserStore.set('contactList', contacts)
    if (UserStore.isBountyInviteLoad) {
      UserStore.set('isBountyInviteLoad', false)
      const result2 = await backendApis.getChurnedUsersByContacts({
        contacts: [...contacts?.slice(skip, skip + 50)],
      })
      if (result2?.status === 200) {
        UserStore.set('churnedUsers', [
          ...UserStore?.churnedUsers,
          ...result2?.data,
        ])
        UserStore.set('contactSkip', skip + 50)
      } else {
        ToastStore.networkError()
      }
      return
    }

    if (UserStore.isContactInviteLoad) {
      UserStore.set('isContactInviteLoad', false)
      const result2 = await backendApis.getUninstallUsersByContacts({
        contacts: [...contacts?.slice(skip, skip + 20)],
      })
      if (result2?.status === 200) {
        UserStore.set('uninstallUsers', [
          ...UserStore?.uninstallUsers,
          ...result2?.data,
        ])
        UserStore.set('contactSkip', skip + 20)
      } else {
        ToastStore.networkError()
      }
      return
    }

    if (!UserStore.isContactInviteLoad) {
      const result = await backendApis.getApplePangRankingLocal({
        contacts: contacts?.map((contact) => contact?.phoneNumber),
      })
      if (result?.status === 200) {
        UserStore.set('rankData', result?.data?.users)
        const rank = result?.data?.users?.findIndex(
          (user) => user?.userId?.toString() === UserStore?.userId?.toString(),
        )
        if (rank < 0) {
          UserStore.set('myRankLocal', 99)
        } else {
          UserStore.set('myRankLocal', rank)
        }
        UserStore.set('userImageUri', result?.data?.myImage)

        const rankWithoutMe = result?.data?.users?.filter(
          (user) => user?.userId?.toString() !== UserStore?.userId?.toString(),
        )
        const currentRank = rankWithoutMe?.reduce(
          (closestIndex, user, currentIndex, array) => {
            const currentScoreDifference =
              user?.highscore - UserStore?.currentScore
            const closestScoreDifference =
              array[closestIndex]?.highscore - UserStore?.currentScore
            return Math.abs(currentScoreDifference) <
              Math.abs(closestScoreDifference)
              ? currentIndex
              : closestIndex
          },
          0,
        )
        const usersBelowMe = rankWithoutMe?.slice(currentRank, currentRank + 3)

        UserStore.set('usersBelowCurrentRank', usersBelowMe)
        UserStore.set('rankLoadDone', true)
      } else {
        ToastStore.networkError()
      }
    }
  }

  async kakaoLinkShare({
    shareType,
    title,
    description,
    imageUrl,
    itemId,
    payload,
    templateId,
  }) {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'kakaoShare',
        data: {
          shareType,
          title,
          description,
          imageUrl,
          itemId,
          payload,
          templateId,
        },
      }),
    )
  }

  postMessage(data) {
    window?.ReactNativeWebView?.postMessage(JSON.stringify(data))
  }
}

export default new ApplePangAction()
