import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import THUserStore from '../../../stores/treasureHunt/THUserStore'

const PointComponent = observer(() => {
  useEffect(() => {
    if (THUserStore?.pointBounceAnimationOn) {
      setTimeout(() => {
        THUserStore?.set('pointBounceAnimationOn', false)
      }, 300)
    }
  }, [THUserStore?.pointBounceAnimationOn])

  return (
    <div
      className={`flex text-[4vw] flex-row justify-center items-center mr-2 rounded-full bg-[#FFD7D6] pr-[8px] pl-[4px] py-[4px] text-[#313138] font-bold
    ${THUserStore?.pointBounceAnimationOn ? 'animation-bounce' : ''}
    whitespace-nowrap
    `}
      onClick={() => {
        // const payload = {
        //   enteringComponent: 'TreasureHunt_PurchaseScreen',
        //   text: '보물상점',
        //   source:
        //     'https://assets.ilevit.com/14161f15-6e34-4435-8f4d-f771603011f6.png',
        //   rewardText: `포인트를 사용해서 무료로 구매해보세요!`,
        //   myPoint: `${commaNumber(THUserStore?.point)}원` ?? '로딩 중',
        // }
        // TreasureHuntAction.navigateRN({
        //   screen: 'TreasureHuntPurchaseScreen',
        //   payload,
        // })
      }}
    >
      <img
        className='w-[5vw] h-[5vw] mr-2'
        alt='cash'
        src='../images/treasureHunt/point.png'
      />
      {`${commaNumber(THUserStore?.point ?? 0)}`}원
    </div>
  )
})

export default PointComponent
