import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import AuthStore from '../../stores/AuthStore'
import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

const ProviderAdminScreen = observer(() => {
  const [providerList, setProviderList] = useState()

  const fetchData = async () => {
    const tempToken = localStorage.getItem('@altoon@provider@token@')

    const result = await altoonCMSBackendApis.loadProviderList(
      tempToken ?? AuthStore?.token,
    )
    if (result?.status === 200) {
      setProviderList(result?.data)
    }
  }

  useEffect(() => {
    AltoonCMSUserStore.setIsLoading(true)
    fetchData()
    setTimeout(() => {
      AltoonCMSUserStore.setIsLoading(false)
    }, 500)
  }, []) // currentPage 또는 itemsPerPage가 변경될 때마다 요청

  const RegisteredProviderList = ({ providerData }) => {
    return (
      <div
        className={`w-full ${
          providerData?.length > 0 ? 'h-[40vh]' : ''
        } overflow-y-scroll bg-white`}
      >
        {providerData?.length > 0 && (
          <table>
            <thead className='whitespace-nowrap text-[1vh] border-b-2'>
              <tr>
                <th>고유아이디</th>
                <th>설명</th>
                <th>전화번호</th>
                <th>회사전화</th>
                <th>이메일</th>
                <th>주소</th>
                <th>사업자등록번호</th>
                <th>대표자명</th>
                <th>담당md</th>
                <th>표시될 CP사명</th>
                <th>CP사명</th>
                <th>CP사 로고이미지1</th>
              </tr>
              <tr>
                <th>id</th>
                <th>description</th>
                <th>phoneNumber</th>
                <th>contactNumber</th>
                <th>email</th>
                <th>address</th>
                <th>businessRegistrationNumber</th>
                <th>representorName</th>
                <th>mdName</th>
                <th>providerDisplayName</th>
                <th>providerName</th>
                <th>providerImageUri</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 12, textAlign: 'center' }}>
              {providerData?.map((item) => (
                <tr key={item._id} className='border-b'>
                  <td style={{ fontSize: 4, width: '5vw', textWrap: 'nowrap' }}>
                    {item._id}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.description}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.phoneNumber}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.contactNumber}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.email}
                  </td>

                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.address}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.businessRegistrationNumber}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.representorName}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.mdName}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.providerDisplayName}
                  </td>
                  <td
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 10,
                      width: '10vw',
                      textWrap: 'nowrap',
                    }}
                  >
                    {item.providerName}
                  </td>
                  <td style={{ fontSize: 4, width: '5vw', textWrap: 'nowrap' }}>
                    {item.providerImageUri}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  const UpdateChapterInfo = () => {
    const [providerId, setProviderId] = useState('')
    const [field, setField] = useState('')
    const [value, setValue] = useState('')

    return (
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>CP사 정보 수정</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 12,
          }}
        >
          CP사 아이디
          <div style={{ marginBottom: 8 }}>
            <input
              type='text'
              value={providerId}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setProviderId(e.target.value)
              }}
            />
          </div>
          바꿀 field
          <div style={{ marginBottom: 8 }}>
            <input
              type='text'
              value={field}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setField(e.target.value)
              }}
            />
          </div>
          value
          <div style={{ marginBottom: 4 }}>
            <input
              type='text'
              value={value}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setValue(e.target.value)
              }}
            />
          </div>
        </div>
        <button
          type='button'
          className='mt-10 px-4 py-2 bg-gray-500 text-white rounded-xl'
          onClick={async () => {
            const result = await altoonCMSBackendApis.updateProviderInfo({
              providerId,
              field,
              value,
            })
            if (result?.status === 200) {
              alert('업데이트 완료')
              fetchData()
            }
          }}
        >
          정보 업데이트
        </button>
      </div>
    )
  }

  const ProviderRegisterSection = () => {
    const [description, setDescription] = useState('')
    const [providerDisplayName, setProviderDisplayName] = useState('')
    const [providerName, setProviderName] = useState('')
    const [contactNumber, setContactNumber] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [businessRegistrationNumber, setBusinessRegistrationNumber] =
      useState('')
    const [representorName, setRepresentorName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [providerImageUri, setProviderImageUri] = useState('')
    const [mdName, setMdName] = useState('')

    return (
      <>
        <div className='p-4 bg-white rounded-xl'>
          <div className='py-4 font-bold text-[2.6vh]'>CP사 등록</div>
          <div className='grid-cols-3 grid'>
            <div className='pt-4 flexRow items-center'>
              CP사 설명
              <input
                className='ml-2'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              표시될 CP사명
              <input
                className='ml-2'
                value={providerDisplayName}
                onChange={(e) => setProviderDisplayName(e.target.value)}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              로그인할 아이디
              <input
                className='ml-2'
                value={providerName}
                onChange={(e) => setProviderName(e.target.value)}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              회사 전화번호
              <input
                className='ml-2'
                type='text'
                value={contactNumber}
                onChange={(e) => {
                  setContactNumber(e.target.value)
                }}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              회사 이메일 주소
              <input
                className='ml-2'
                type='text'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              회사 주소
              <input
                className='ml-2'
                type='text'
                checked={address}
                onChange={(e) => {
                  setAddress(e.target.value)
                }}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              사업자등록번호
              <input
                className='ml-2'
                type='text'
                value={businessRegistrationNumber}
                onChange={(e) => {
                  setBusinessRegistrationNumber(e.target.value)
                }}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              대표자명
              <input
                className='ml-2'
                type='text'
                value={representorName}
                onChange={(e) => {
                  setRepresentorName(e.target.value)
                }}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              CP사 담당자 전화번호
              <input
                className='ml-2'
                type='text'
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value)
                }}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              로고이미지 url
              <input
                className='ml-2'
                type='text'
                value={providerImageUri}
                onChange={(e) => {
                  setProviderImageUri(e.target.value)
                }}
              />
            </div>
            <div className='pt-4 flexRow items-center'>
              담당MD(올웨이즈)
              <input
                className='ml-2'
                type='text'
                value={mdName}
                onChange={(e) => {
                  setMdName(e.target.value)
                }}
              />
            </div>
          </div>
          <div style={{ paddingTop: 12 }}>
            <button
              type='button'
              className='mt-10 px-4 py-2 bg-gray-500 text-white rounded-xl'
              onClick={async () => {
                if (
                  !description ||
                  !providerDisplayName ||
                  !providerName ||
                  !contactNumber ||
                  !email ||
                  !address ||
                  !businessRegistrationNumber ||
                  !representorName ||
                  !phoneNumber ||
                  !providerImageUri ||
                  !mdName
                ) {
                  return
                }

                const result = await altoonCMSBackendApis.registerProvider({
                  description,
                  providerDisplayName,
                  providerName,
                  contactNumber,
                  email,
                  address,
                  businessRegistrationNumber,
                  representorName,
                  phoneNumber,
                  providerImageUri,
                  mdName,
                })
                if (result?.status === 200) {
                  alert('등록되었습니다.')
                  fetchData()
                  setDescription('')
                  setProviderDisplayName('')
                  setProviderName('')
                  setContactNumber('')
                  setEmail('')
                  setAddress('')
                  setBusinessRegistrationNumber('')
                  setRepresentorName('')
                  setPhoneNumber('')
                  setProviderImageUri('')
                  setMdName('')
                } else {
                  alert(
                    '오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.',
                  )
                }
              }}
            >
              CP사 등록하기
            </button>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className='w-full my-10 min-w-7xl max-w-10xl select-text'>
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>CP사 관리</div>
        {providerList?.length > 0 && (
          <RegisteredProviderList providerData={providerList} />
        )}
      </div>
      <div className='h-4' />
      <UpdateChapterInfo />
      <div className='h-4' />
      <ProviderRegisterSection />
    </div>
  )
})

export default ProviderAdminScreen
