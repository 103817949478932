import { observable, toJS } from 'mobx'
import moment from 'moment'

const THUserStore = observable({
  DEFAULT_IMAGE:
    'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d9a2da73-105d-4c7d-bf80-9c3191a977f7.png',
  isLoading: true,
  _id: null,
  userName: null,
  codepushVersion: null,
  appVersion: null,
  needsLogin: null,
  userImageUri: null,
  longitude: 0,
  latitude: 0,
  gpsError: null,
  errorMessage: null,
  matchList: [],
  matchListPositions: [],
  energy: 0,
  maxEnergy: 0,
  isAllCleared: true,
  dailyMission: {},
  clickedIndices: [],
  tutorialStep: 0,
  oldTutorialStep: 0,
  tutorialMinorStep: 0,
  friends: [],
  friendMission: [],
  gift: 0,
  challenges: [],
  luck: 0,
  diceEventPurchaseOn: false,
  achievementList: [],
  ornamentList: [],
  characterInfo: {
    emojiId: 0,
    closetType: 'empty',
    closetId: 0,
  },

  setMultiple(obj) {
    const arr = Object.entries(obj ?? {})
    for (const [key, value] of arr) {
      this.set(key, value)
    }
  },

  increase(key, value) {
    if (typeof value !== 'number') {
      return
    }

    function increaseProperty(obj, keys, val) {
      let key
      while (keys.length > 1) {
        key = keys.shift()
        if (!obj.hasOwnProperty(key)) obj[key] = {}
        obj = obj[key]
      }
      obj[keys[0]] += val
    }

    if (key?.includes('.')) {
      const keys = key.split('.')
      increaseProperty(this, keys, value)
    } else {
      this[key] += value
    }
  },

  set(key, value) {
    function setProperty(obj, keys, val) {
      let key
      while (keys.length > 1) {
        key = keys.shift()
        if (!obj.hasOwnProperty(key)) obj[key] = {}
        obj = obj[key]
      }
      obj[keys[0]] = val
    }

    if (key?.includes('.')) {
      const keys = key.split('.')
      setProperty(this, keys, value)
    } else {
      this[key] = value
    }
  },

  push(key, value) {
    this?.[key]?.push(value)
  },
})

export default THUserStore
