import React, { useState } from 'react'
import { FcSalesPerformance as IdeaIcon } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'

import ItemImpressionStore from '../../../stores/ItemImpressionStore'
import ModalStore from '../../../stores/ModalStore'
import UserStore from '../../../stores/UserStore'
import backendApis from '../../../utils/backendApis'
import CategoryName from '../../../utils/categoryName'
import log from '../../../utils/log'
import Header from '../../atoms/header'

const categoryIds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12]

const RandomPick = () => {
  const navigate = useNavigate()
  const [pickedCategory, setPickedCategory] = useState([])
  const [pickingCount, setPickingCount] = useState(0)
  const maxPickingCount = 3
  const modalName = 'randomPickWin'
  const winCallBack = async (categoryIds) => {
    ModalStore.setCurrentModal({
      open: true,
      type: modalName,
      categoryIds,
      closeCallback: () => log.dealLog('win', 'join'),
      confirmCallback: () => log.dealLog('win', 'join'),
    })
    await backendApis.updateUserDealStatus('categoryDeal', 0, 10)
    ItemImpressionStore.setPreviousIsFrom('RandomPick')
    navigate('/deal/category-deal', {
      state: { categoryIds, mode: 'categoryExpansion' },
      replace: true,
    })
  }

  return (
    <>
      <Header
        title='랜덤 뽑기'
        bgColor='#171717'
        textColor='white'
        callbackFunction={() => {
          ModalStore.setCurrentModal({
            open: true,
            type: 'eventDealExit',
            event: '/deal/time-deal',
            closeCallback: () => log.dealLog('give_up', 'join'),
            confirmCallback: () => log.dealLog('give_up', 'join'),
          })
        }}
      />
      <div className='flex flex-col items-center justify-between flex-1 h-screen pb-12 pt-12 text-white bg-[#171717]'>
        <div>
          <div className='flex flex-row items-center justify-center my-5 animate-bounce'>
            <IdeaIcon className='w-6 h-6 mr-5' />
            뽑기를 뽑아보세요!
          </div>
          <div className='flex flex-row mb-10 justify-items-center'>
            {pickedCategory.map((categoryId, idx) => {
              return (
                <div
                  key={categoryId}
                  className={`px-3 py-1 font-bold ${
                    idx === maxPickingCount ? '' : 'mr-2'
                  } bg-green-500 shadow-lg rounded-xl`}
                >
                  {CategoryName(categoryId)}
                </div>
              )
            })}
          </div>
        </div>
        <div className='grid w-full grid-cols-3 gap-4 px-8 justify-items-center'>
          {categoryIds.map((categoryId, idx) => {
            return (
              <div
                key={categoryId}
                aria-hidden
                onClick={() => {
                  if (pickedCategory?.includes(categoryId)) return
                  if (pickingCount >= maxPickingCount) return
                  setPickedCategory([...pickedCategory, categoryId])
                  if (pickingCount + 1 === maxPickingCount) {
                    console.log(
                      Array.from(new Set([...pickedCategory, categoryId])),
                    )
                    winCallBack(
                      Array.from(new Set([...pickedCategory, categoryId])),
                    )
                  }
                  setPickingCount(pickingCount + 1)
                }}
                className={`relative flex items-center justify-center w-24 h-28 ${
                  pickedCategory?.includes(categoryId)
                    ? 'bg-green-400 animate-bounce'
                    : 'bg-red-400'
                } shadow-lg rounded-xl`}
              >
                <div
                  className={`absolute flex flex-row items-center justify-center flex-1 w-full h-24 p-1 ${
                    pickedCategory?.includes(categoryId)
                      ? 'text-lg'
                      : 'text-3xl'
                  } font-bold border-t-2 border-white border-dotted top-4`}
                >
                  {pickedCategory?.includes(categoryId)
                    ? CategoryName(categoryId)
                    : '?'}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
export default RandomPick
