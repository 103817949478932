import commaNumber from 'comma-number'
import React, { useState } from 'react'
import ScratchCard from 'src/components/atoms/scratchCard'
import {
  CheckInPigMain,
  ModalPigOnly,
  ModalPinkPig,
  PigFood,
  PigFoodColored,
  PigFoodLarge,
  PigHeartHundred,
} from 'src/images/dailyCheckIn'
import backendApis from 'src/utils/backendApis'

import ConsecutiveCheckInContent from './consecutiveCheckInContent'

const ModalContents = ({ contents, close, triggerFoodToast, ...params }) => {
  const [isScratched, setIsScratched] = useState(false)

  if (!contents) return null

  if (contents === 'Welcome') {
    return (
      <div className='w-full h-full flex flex-col items-center jua'>
        <div className='flex flex-row items-center justify-center '>
          <div className=' top-20 text-[#525052]'>반가워요!</div>
          <div className='text-3xl ml-1 mb-2 animate-wiggle'>👋</div>
        </div>

        <div className='text-[#525052]'>저는 돈주는 돼지 돈돈이에요.</div>
        <CheckInPigMain className='animate-float pr-4 pt-4 w-36 h-full' />

        <div className='text-[#525052]'>제게 밥을 주시면 돈을 드릴게요!</div>
        <div className='h-4' />
        <div
          className='w-full py-3 bg-red-500 rounded-xl text-lg text-white text-center'
          aria-hidden='true'
          onClick={async () => {
            await backendApis.gainFood('welcomeFood', 500, 'food')
            close()
            triggerFoodToast()
          }}
        >
          돈돈이 밥 받기
        </div>
      </div>
    )
  }

  if (contents === 'Introduction') {
    const TextComponent = () => {
      return (
        <>
          <div className='text-3xl py-2 animate-wiggle'>👋</div>
          <div className='flex flex-col justify-center items-center'>
            <div>반가워요! 저는 돈주는 돼지 돈돈이에요.</div>
            <div className='relative'>
              제게 밥을 주시면 돈을 드려요!
              <span
                className='absolute inset-x-0 h-1 ml-8'
                style={{
                  bottom: 3,
                  borderBottom: '8px solid rgba(255, 223, 221, 0.9)',
                  zIndex: '-1',
                }}
              />
            </div>
          </div>
        </>
      )
    }
    return (
      <div className='w-full h-full flex flex-col items-center text-[#525052] text-lg jua'>
        <ModalPigOnly className='animate-float w-32 h-full' />
        <TextComponent />
        <div className='h-4' />
        <div
          className='w-full py-3 bg-red-500 rounded-xl text-lg text-white text-center'
          aria-hidden='true'
          onClick={async () => {
            close()
          }}
        >
          확인
        </div>
      </div>
    )
  }

  if (contents === 'RouletteNotice') {
    return (
      <div className='w-full h-full jua'>
        <div className='text-[#525052] text-xl text-center'>유의사항</div>
        <div className='h-4' />
        <div className='text-[#525052] text-sm'>
          * 행운룰렛 이벤트는 일부 고객님들에게 보이는 이벤트에요
        </div>
        <div className='h-2' />
        <div className='text-[#525052] text-sm'>
          * 뽑기권은 룰렛 하단의 각종 미션을 통해 획득할 수 있어요
        </div>
        <div className='h-2' />
        <div className='text-[#525052] text-sm'>
          * 뽑기권이 남아있더라도 당사 사정에 의해 사전 고지없이 종료될 수
          있어요
        </div>
        <div className='h-2' />
        <div className='text-[#525052] text-sm'>
          * 모든 미션은 00시에 초기화되며, 오전/오후의 기준은 12시에요
        </div>
        <div className='h-2' />
        <div className='text-[#525052] text-sm'>
          * 상품 구매로 얻은 뽑기권은 구매 취소 시 회수되며, 이미 사용한
          경우에는 경험치 페널티가 적용돼요
        </div>
        <div className='h-2' />
        <div className='text-[#525052] text-sm'>
          * 관련 문의는 "내 정보 &gt; 고객문의 &gt; 1:1 실시간 상담하기"에서
          가능해요
        </div>
        <div className='h-2' />
      </div>
    )
  }

  if (contents === 'PinkPig') {
    const TextComponent = () => {
      return (
        <>
          <div className='text-3xl py-2 animate-wiggle'>👋</div>
          <div className='flex flex-col justify-center items-center'>
            <div>반가워요! 저는 핑크 돈돈이에요.</div>
            <div className='relative'>
              제게 밥을 주시면 돈을 더 빨리 드려요!
              <span
                className='absolute inset-x-0 h-1 ml-28'
                style={{
                  bottom: 3,
                  borderBottom: '8px solid rgba(255, 223, 221, 0.9)',
                  zIndex: '-1',
                }}
              />
            </div>
            <div className='text-sm mt-4'>
              잔여 시간: {params?.params?.leftTime}
            </div>
          </div>
        </>
      )
    }
    return (
      <div className='w-full h-full flex flex-col items-center text-[#525052] text-lg jua'>
        <ModalPinkPig className='animate-float w-44 h-full' />
        <TextComponent />
        <div className='h-4' />
        <div
          className='w-full py-3 bg-red-500 rounded-xl text-lg text-white text-center'
          aria-hidden='true'
          onClick={async () => {
            close()
          }}
        >
          확인
        </div>
      </div>
    )
  }

  if (contents === 'ConsecutiveCheckIn') {
    const count = params?.params?.count
    const data = [
      { id: 0, rewardType: 'food', value: 100 },
      { id: 1, rewardType: 'food', value: 150 },
      { id: 2, rewardType: 'food', value: 250 },
      { id: 3, rewardType: 'food', value: 500 },
      { id: 4, rewardType: 'food', value: 700 },
      { id: 5, rewardType: 'foodLarge', value: 1000 },
      { id: 6, rewardType: 'foodLarge', value: 2000 },
    ]

    const todayReward = data.find((each) => each.id === count)

    return (
      <div className='w-full h-full flex flex-col items-center jua'>
        <div className='text-[#525052] text-3xl'>연속 출석 도전!</div>

        <ConsecutiveCheckInContent count={count} available />
        <div className='h-2' />
        <div
          className='w-full py-3 bg-red-500 rounded-xl text-lg text-white text-center'
          aria-hidden='true'
          onClick={async () => {
            await backendApis.gainFood(
              'onboardingMission',
              todayReward.value,
              'food',
            )
            close()
            triggerFoodToast(todayReward.value)
          }}
        >
          오늘의 보상받기
        </div>
      </div>
    )
  }

  if (contents === 'ConsecutiveCheckInDone') {
    return (
      <div className='w-full h-full flex flex-col items-center jua'>
        <div className='text-[#525052] text-3xl'>연속 출석 도전!</div>
        <div className='h-6' />
        <PigFoodColored className='w-20' />
        <div className='h-6' />
        <div className='text-[#525052] text-base'>
          오늘의 연속출석 보상을 받았어요
        </div>
        <div className='h-2' />
        <div className='flex flex-row items-center justify-center '>
          <div className=' top-20 text-[#525052]'>내일 또 만나요!</div>
          <div className='text-xl ml-1 mb-2 animate-wiggle'>👋</div>
        </div>
        <div className='h-2' />
        <div
          className='w-full py-3 bg-red-500 rounded-xl text-lg text-white text-center'
          aria-hidden='true'
          onClick={() => {
            close()
          }}
        >
          확인
        </div>
      </div>
    )
  }

  if (contents === 'Notice') {
    return (
      <div className='w-full h-full jua'>
        <div className='text-[#525052] text-xl text-center'>유의사항</div>
        <div className='h-4' />
        <div className='text-[#525052] text-sm'>
          * "돈돈이"는 이벤트성 기능으로, 일부 고객님들께는 보이지 않을 수
          있어요
        </div>
        <div className='h-2' />
        <div className='text-[#525052] text-sm'>
          * 돈돈이를 모두 키우면 포인트가 지급되고 경험치는 초기화돼요
        </div>
        <div className='h-2' />
        <div className='text-[#525052] text-sm'>
          * 경험치가 오르는 속도는 게임 진행상황에 따라 다를 수 있어요
        </div>
        <div className='h-2' />
        <div className='text-[#525052] text-sm'>
          * 모든 미션은 00시에 초기화되며, 오전/오후의 기준은 12시에요
        </div>
        <div className='h-2' />
        <div className='text-[#525052] text-sm'>
          * 관련 문의는 "내 정보 &gt; 고객문의 &gt; 1:1 실시간 상담하기"에서
          가능해요
        </div>
        <div className='h-2' />
        <div className='text-[#525052] text-sm'>
          * 서비스의 운영상의 오류를 악용하여 부당한 이익을 얻는 경우 운영정책에
          따라 서비스 이용이 제한될 수 있어요
        </div>
        <div className='h-2' />
        <div className='text-[#525052] text-sm'>
          * 돈돈이를 키우고 계시더라도 당사의 사정에 의해 사전 고지 없이 변경
          또는 조기종료 될 수 있어요
        </div>
      </div>
    )
  }

  if (contents === 'BonusFood') {
    const givenBonusFood = params?.params?.pigData?.bonusFood
    const bonusFoodReward = params?.params?.pigLevelData
    let bonusFoodGoal = 0
    let rewardBonusFood = 0

    if (
      givenBonusFood < bonusFoodReward.level3 &&
      givenBonusFood >= bonusFoodReward.level2 + 600
    ) {
      bonusFoodGoal = bonusFoodReward.level3
      rewardBonusFood = bonusFoodReward.level3 + 700
    } else if (
      givenBonusFood < bonusFoodReward.level2 &&
      givenBonusFood >= bonusFoodReward.level1 + 700
    ) {
      bonusFoodGoal = bonusFoodReward.level2
      rewardBonusFood = bonusFoodReward.level2 + 600
    } else if (givenBonusFood < bonusFoodReward.level1) {
      bonusFoodGoal = bonusFoodReward.level1
      rewardBonusFood = bonusFoodReward.level1 + 700
    }

    return (
      <div className='w-full h-full flex flex-col items-center jua'>
        <div className='text-2xl top-20 text-[#525052]'>사료 창고</div>
        <div className='h-2' />
        <div className='text-sm text-[#525052]'>
          오늘 밥을 많이 줄수록, 내일 더 받아요
        </div>
        <div className='h-4' />
        {bonusFoodGoal > 0 && rewardBonusFood > 0 && (
          <>
            <div className='flex flex-row'>
              <div className='text-base text-[#525052]'>오늘 </div>
              <div className='w-1' />
              <div className='text-base text-red-500'>
                {commaNumber(bonusFoodGoal)}g
              </div>
              <div className='text-base text-[#525052]'>이 쌓이면 </div>
            </div>
            <div className='flex flex-row'>
              <div className='text-base text-[#525052]'>내일</div>
              <div className='w-1' />
              <div className='text-base text-red-500'>
                {commaNumber(rewardBonusFood)}g
              </div>
              <div className='text-base text-[#525052]'>으로 받아요</div>
            </div>
            <div className='h-4' />

            <div className='animate-wiggle text-xl absolute top-52 text-red-500 z-30'>
              {commaNumber(givenBonusFood)}g
            </div>
            <PigFoodLarge className='w-20 h-full' />
          </>
        )}
        {(!bonusFoodGoal > 0 || !rewardBonusFood > 0) && (
          <>
            <div className='pt-2 animate-wiggle text-xl absolute top-36 text-red-500 z-30'>
              {commaNumber(givenBonusFood)}g
            </div>
            <PigFoodLarge className='w-24 h-full' />
          </>
        )}
        <div className='h-4' />

        <div
          className='w-full py-3 bg-red-500 rounded-xl text-lg text-white text-center'
          aria-hidden='true'
          onClick={close}
        >
          돈돈이 밥 주러가기
        </div>
      </div>
    )
  }

  if (contents === 'GainedFood') {
    const foodAmount = JSON.stringify(params?.params?.foodAmount)

    return (
      <div className='w-full h-full flex flex-col items-center jua'>
        <div className=' top-20 text-[#525052]'>축하합니다</div>
        <div className=' top-20 text-[#525052]'>{foodAmount}g 획득!</div>

        <PigFood className='w-40 h-full' />
        <div className='h-4' />
        <div
          className='w-full py-3 bg-red-500 rounded-xl text-lg text-white text-center'
          aria-hidden='true'
          onClick={async () => {
            close()
          }}
        >
          돈돈이 밥 받기
        </div>
      </div>
    )
  }

  if (contents === 'LuckyScratch') {
    const scratchReward = params?.params?.scratchReward

    return (
      <div className='w-full h-full flex flex-col items-center jua'>
        <div className='text-2xl top-20 pb-4 text-[#525052]'>
          럭키 돈돈이 복권 당첨!
        </div>

        <ScratchCard
          width={250}
          height={180}
          image='/luckyScratch/scratch.png'
          style={{
            borderRadius: '16px',
            padding: 0,
            margin: 0,
          }}
          finishPercent={70}
          onComplete={() => {
            setIsScratched(true)
            triggerFoodToast()
          }}
        >
          <div
            style={{
              width: 250,
              height: 180,
              color: 'black',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundImage: 'url(../luckyScratch/scratched.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            <PigFoodColored className='w-20 pb-4' />
            <div className='text-center text-3xl jua'>
              {`${commaNumber(scratchReward)}g 당첨!`}
            </div>
          </div>
        </ScratchCard>
        {/* 버튼 */}
        {isScratched ? (
          <button
            className='w-full mt-4 py-3 bg-red-500 rounded-xl text-lg text-white text-center'
            aria-hidden='true'
            onClick={async () => {
              close()
              window.location.href = `#navigate.${JSON.stringify({
                screen: 'DailyCheckInCompatiblePurchaseMission',
                prop: {
                  missionType: 'LuckyScratch',
                  missionName: '럭키 돈돈이 복권',
                  // rewardText: '사료 20,000g과 어쩌고 3개를',
                  defaultCategory: 'total',
                  bannerUri:
                    'https://assets.ilevit.com/25529854-116f-4fae-9394-50b7afdca0ac.png',
                },
              })}`
            }}
          >
            {`상품 사고 ${commaNumber(scratchReward * 100)}g 더 받기`}
          </button>
        ) : (
          <div className='pt-4 text-base text-center text-[#525052] jua'>
            밥을 주면 낮은 확률로 나오는 <br />
            행운의 복권이에요!
          </div>
        )}
      </div>
    )
  }

  if (contents === 'LuckyScratchButton') {
    const scratchReward = params?.params?.scratchReward

    return (
      <div className='w-full h-full flex flex-col items-center jua'>
        <div className='text-2xl top-20 pb-4 text-[#525052]'>
          럭키 돈돈이 복권 당첨!
        </div>
        <div
          style={{
            width: 250,
            height: 180,
            color: 'black',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundImage: 'url(../luckyScratch/scratched.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <PigFoodColored className='w-20 pb-4' />
          <div className='text-center text-3xl jua'>
            {`${commaNumber(scratchReward)}g 당첨!`}
          </div>
        </div>
        {/* 버튼 */}
        <button
          className='w-full mt-4 py-3 bg-red-500 rounded-xl text-lg text-white text-center'
          aria-hidden='true'
          onClick={async () => {
            close()
            window.location.href = `#navigate.${JSON.stringify({
              screen: 'DailyCheckInCompatiblePurchaseMission',
              prop: {
                missionType: 'LuckyScratch',
                missionName: '럭키 돈돈이 복권',
                // rewardText: `돈돈이 사료 ${commaNumber(scratchReward * 100)}g`,
                defaultCategory: 'total',
                bannerUri:
                  'https://assets.ilevit.com/25529854-116f-4fae-9394-50b7afdca0ac.png',
              },
            })}`
          }}
        >
          {`상품 사고 ${commaNumber(scratchReward * 100)}g 더 받기`}
        </button>
      </div>
    )
  }

  if (contents === 'HappinessInfo') {
    const currentHappiness = params?.params?.pigData?.happiness
    const Title = () => {
      return (
        <>
          <div className='flex flex-row text-2xl top-20 text-[#525052]'>
            돈돈이 <div className='text-red-500 ml-1'> 행복도</div>란?
          </div>
          <div className='flex flex-row items-center justify-center mr-2'>
            <PigHeartHundred className='w-28' />
            <ModalPigOnly className='w-20' />
          </div>
        </>
      )
    }
    const CurrentHappinessComponent = () => {
      return (
        <div className='flex flex-row items-center justify-center text-[#525052]'>
          <div className='text-base mr-2'>현재 행복도</div>
          <div className='animate-wiggle text-xl text-red-500 z-30'>
            {currentHappiness >= 0 ? commaNumber(currentHappiness) : 0}
            <span
              className='absolute inset-x-0 h-1'
              style={{
                bottom: 3,
                borderBottom: '8px solid rgba(255, 223, 221, 0.9)',
                zIndex: '-1',
              }}
            />
          </div>
        </div>
      )
    }
    const ExplanationTable = () => {
      return (
        <div className='flex flex-col w-full text-base text-[#525052]'>
          <div className='flex flex-row border-b items-center justify-center'>
            <div
              className='flex flex-col items-start p-4 border-r'
              style={{ width: '32%' }}
            >
              <div>행복도가</div>
              <div className='text-red-500'>높을 때</div>
            </div>
            <div
              className='flex flex-col items-start p-4 text-[#796950]'
              style={{ width: '68%' }}
            >
              <div>경험치가 더 빨리 쌓여요</div>
              <div>돈봉투의 돈이 더 많아져요</div>
            </div>
          </div>
          <div className='flex flex-row'>
            <div
              className='flex flex-col items-start p-4 border-r'
              style={{ width: '32%' }}
            >
              <div>행복도가</div>
              <div className='text-red-500'>낮을 때</div>
              <div className='text-[#525052] text-xs'>(30 이하)</div>
            </div>
            <div
              className='flex flex-col items-start justify-center p-4 text-[#796950]'
              style={{ width: '68%' }}
            >
              <div>경험치가 더 느리게 쌓여요</div>
              <div>돈봉투의 돈이 줄어들어요</div>
            </div>
          </div>
        </div>
      )
    }
    const NoticeComponent = () => {
      return (
        <div className='text-xs text-[#796950] leading-relaxed'>
          유의사항 <br />
          * 밥을 주거나 행복상점 미션을 수행하면 행복도가 올라요 <br />*
          돈돈이를 돌보지 않으면 행복도가 조금씩 줄어들어요 <br />* 줄어드는
          속도는 행복도 구간에 따라 다를 수 있어요 <br />{' '}
          <div className='pt-2 text-[#525052] text-xs text-center'>
            {' '}
            TIP | 돈돈이는 쓰다듬어주거나 밥을 먹으면 행복해해요
          </div>
        </div>
      )
    }
    return (
      <div className='w-full h-full flex flex-col items-center jua'>
        <Title />
        {/* <div className='h-2' /> */}
        <CurrentHappinessComponent />
        <div className='h-2' />
        <ExplanationTable />
        <div className='h-2' />
        <NoticeComponent />
        <div className='h-2' />

        <div
          className='w-full py-3 bg-red-500 rounded-xl text-lg text-white text-center'
          aria-hidden='true'
          onClick={close}
        >
          확인
        </div>
      </div>
    )
  }

  return null
}

export default ModalContents
