import { observer } from 'mobx-react-lite'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import THUserStore from '../../stores/treasureHunt/THUserStore'

const THCollectionItem = observer(({ type, emojiId, captured, fromCloset }) => {
  return (
    <div
      className={`inline-block w-[20%] h-[20%] px-[2vw] py-2 relative  ${
        fromCloset && 'mb-4'
      }`}
      onClick={() => {
        if (fromCloset && captured) {
          TreasureHuntAction.selectClosetFace({ emojiId })
        }
      }}
    >
      {fromCloset ? (
        <>
          <img
            className={`${captured ? 'opacity-100' : 'opacity-[15%]'} ${
              THUserStore?.characterInfo?.emojiId === emojiId
                ? 'border-2 rounded-xl p-2 border-[#FF5951]'
                : 'border-2 rounded-xl p-[1vw] border-[#eee]'
            }`}
            src={`../images/treasureHunt/emoji/${type}/${emojiId}.png`}
            alt='emoji'
          />
          {THUserStore?.characterInfo?.emojiId === emojiId && (
            <div className='absolute right-0 top-0 border-[1px] border-[#fff] text-[2.5vw] font-bold bg-[#FF5951] rounded-md px-[1vw] py-[0.5vw] text-[#fff]'>
              장착중
            </div>
          )}
        </>
      ) : (
        <img
          className={`${captured ? 'opacity-100' : 'opacity-[15%]'}`}
          src={`../images/treasureHunt/emoji/${type}/${emojiId}.png`}
          alt='emoji'
        />
      )}
    </div>
  )
})

export default THCollectionItem
