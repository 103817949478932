import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMount } from 'react-use'
import styled from 'styled-components'

import backendApis from '../../../../utils/backendApis'
import { loadBookmarkQuery, loadNovelDetailQuery } from '../../remotes/comment'
import { getNovelChapterDocumentQuery } from '../../remotes/novelChapter'
import { viewerStore } from '../../store'
import { stopPropagation } from '../../utils/stopPropagation'
import { throttle } from '../../utils/throttle'
import { useAsyncOverlay } from '../../utils/useAsyncOverlay'
import { themeColors } from '../../utils/viewerOptions'
import { BookmarkConfirm } from '../Bookmark/BookmarkConfirm'
import { ControllerBottomsheet } from '../ControllerBottomsheet/ControllerBottomsheet'
import { TouchArea } from '../TouchArea/TouchArea'

export const TitleBar = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const { chapterId, novelId } = location.state || {}

  const { data: novelChapter } = useSuspenseQuery(
    getNovelChapterDocumentQuery({
      novelId,
      chapterId,
    }),
  )
  const { data: novel, refetch } = useQuery(loadNovelDetailQuery({ novelId }))
  const { data: bookmarkList } = useQuery(
    loadBookmarkQuery({
      chapterId,
    }),
  )
  const [isBookmarked, setIsBookmarked] = useState(false)

  const { openAsync } = useAsyncOverlay()

  useEffect(() => {
    const viewport = viewerStore.viewport
    if (!viewport) return

    const checkBookmark = throttle(() => {
      const isBookmarkPage = !!bookmarkList?.some(
        (b) => viewerStore.currentPage === b.page,
      )
      setIsBookmarked(isBookmarkPage)
    }, 20)

    checkBookmark()
    viewport.addEventListener('scroll', checkBookmark)

    return () => viewport.removeEventListener('scroll', checkBookmark)
  }, [viewerStore.viewport, bookmarkList])

  const isLiked = novel?.data?.find((item) => item._id === chapterId).isLiked

  const handleOpenSetting = async () => {
    await openAsync<void>(({ isOpen, close }) => (
      <ControllerBottomsheet isOpen={isOpen} onClose={close} />
    ))

    viewerStore.toggleIsControllerFocused()
  }

  const handleOpenBookmark = async () => {
    const isConfirmd = await openAsync<boolean>(({ isOpen, close }) => (
      <BookmarkConfirm
        isOpen={isOpen}
        onClose={close}
        chapterId={chapterId}
        page={viewerStore.currentPage}
      />
    ))

    setIsBookmarked(isConfirmd)
  }

  const handleLike = async (setLiked: boolean) => {
    await backendApis.likeNovelChapter({
      novelId,
      chapterId,
      setLiked,
    })

    refetch()
  }

  return (
    <Wrapper
      isVisible={viewerStore.isControllerVisible}
      themeColor={themeColors[viewerStore.options.theme].bgColor}
      onClick={stopPropagation}
      onTouchMove={stopPropagation}
    >
      <TouchArea
        width={44}
        height={44}
        onClick={() => navigate(`/altoon-novel-detail?novelId=${novelId}`)}
      >
        <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
      </TouchArea>

      <TitleText>{novelChapter.chapterTitle}</TitleText>

      {/* 설정 영역 */}
      <TouchArea width={48} height={48} onClick={handleOpenSetting}>
        <img src='/icon_setting.svg' alt='icon' width={32} height={32} />
      </TouchArea>

      {/* 좋아요 영역 */}
      <TouchArea width={48} height={48}>
        {isLiked ? (
          <div onClick={() => handleLike(false)}>
            <img src='/icon_heart_fill.svg' alt='icon' width={32} height={32} />
          </div>
        ) : (
          <div onClick={() => handleLike(true)}>
            <img
              src='/icon_heart_empty.svg'
              alt='icon'
              width={32}
              height={32}
            />
          </div>
        )}
      </TouchArea>

      {/* 북마크 영역 */}
      <TouchArea width={48} height={48} onClick={handleOpenBookmark}>
        {isBookmarked ? (
          <img
            src='/icon_bookmark_filled.svg'
            alt='icon'
            width={32}
            height={32}
          />
        ) : (
          <img src='/icon_bookmark.svg' alt='icon' width={32} height={32} />
        )}
      </TouchArea>
    </Wrapper>
  )
})

const Wrapper = styled.div<{
  isVisible: boolean
  themeColor: string
}>`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-out;
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
  z-index: 1000;
`

const TitleText = styled.div`
  min-width: 200px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 16px;
`
