import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import THModalStore from '../../stores/treasureHunt/THModalStore'
import THUserStore from '../../stores/treasureHunt/THUserStore'
import THClosetItem from './THClosetItem'
import THCollectionItem from './THCollectionItem'
import TreasureHuntButton from './TreasureHuntButton'

const THCloset = observer(({ config }) => {
  const scrollContainerRef = useRef(null)

  const [currentTab, setCurrentTab] = useState('closet')
  const [normalClosetArr, setNormalClosetArr] = useState(
    Array.from({ length: 13 }, (_, i) => i),
  )
  const [rareClosetArr, setRareClosetArr] = useState(
    Array.from({ length: 8 }, (_, i) => i),
  )
  const [uniqueClosetArr, setUniqueClosetArr] = useState(
    Array.from({ length: 6 }, (_, i) => i),
  )
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [clearedCount, setClearedCount] = useState(0)
  const [collectionArr, setCollectionArr] = useState(
    Array.from({ length: 99 }, (_, i) => i),
  )

  useEffect(() => {
    // currentTab이 변경될 때마다 실행
    if (scrollContainerRef.current) {
      // 스크롤 위치를 0으로 설정
      scrollContainerRef.current.scrollTop = 0
    }
  }, [currentTab]) // currentTab이 변경될 때마다 useEffect 실행

  useEffect(() => {
    let count = 0
    let clearCount = 0

    count += normalClosetArr?.length ?? 0
    for (const item of normalClosetArr) {
      if (item?.clearedAt) {
        clearCount += 1
      }
    }

    count += rareClosetArr?.length ?? 0
    for (const item of rareClosetArr) {
      if (item?.clearedAt) {
        clearCount += 1
      }
    }

    count += uniqueClosetArr?.length ?? 0
    for (const item of uniqueClosetArr) {
      if (item?.clearedAt) {
        clearCount += 1
      }
    }
    setTotalItemCount(count)
    setClearedCount(clearCount)
  }, [normalClosetArr])

  return (
    <div className='default w-full mb-4 text-[#313138] max-h-[70vh] border-[#313138]'>
      <div className='flex w-full justify-center items-end mt-2'>
        <div className='flex justify-center flex-col w-[20vw] items-center mt-[10vw]'>
          <div className='w-[20vw] h-[20vw] relative'>
            <img
              className='w-[20vw] h-[20vw]'
              src={`../images/treasureHunt/emoji/face/${
                THUserStore?.characterInfo?.emojiId ?? 0
              }.png`}
              alt='character'
            />
            <img
              className='absolute left-[50%] -translate-x-1/2 bottom-0'
              src={`../images/treasureHunt/closet/${
                THUserStore?.characterInfo?.closetType ?? 'empty'
              }/${THUserStore?.characterInfo?.closetId ?? 0}.png`}
              alt='character'
            />
          </div>
        </div>
        <div className='absolute right-[4vw]'>
          <img
            className='w-[20vw] h-[20vw]'
            src='../images/treasureHunt/closet/box.png'
            alt='box'
          />
          <TreasureHuntButton
            className='text-[3.5vw] -mt-[5vw]'
            clickable
            text='장식 뽑기'
            onClick={() => {
              THModalStore.setType({
                type: 'closetDraw',
              })
            }}
          />
        </div>
      </div>
      <div className='flex flex-row justify-between items-center text-[4vw] pt-4 px-2'>
        <div className='flex flex-row justify-around items-center py-2'>
          <div
            className={`px-4 ${
              currentTab === 'face'
                ? 'font-bold border-b-2 border-b-[#313138]'
                : 'font-normal'
            }`}
            onClick={() => {
              setCurrentTab('face')
            }}
          >
            캐릭터
          </div>
          <div
            className={`px-4 ${
              currentTab === 'closet'
                ? 'font-bold border-b-2 border-b-[#313138]'
                : 'font-normal'
            }`}
            onClick={() => {
              setCurrentTab('closet')
            }}
          >
            장식
          </div>
        </div>
      </div>
      <div
        className='block h-[50vh] overflow-y-scroll px-2 pointer-events-auto '
        ref={scrollContainerRef}
      >
        {currentTab === 'closet' && (
          <>
            <div className='mx-2 mt-2 font-bold text-[5vw] pt-2'>일반</div>
            <THClosetItem key={Math.random()} type='empty' index={0} captured />
            {normalClosetArr.map((_, i) => {
              const isCaptured = THUserStore?.closet?.normal?.find((item) => {
                return item?.closetId === i
              })

              return (
                <THClosetItem
                  key={i}
                  type='normal'
                  index={i}
                  captured={isCaptured}
                />
              )
            })}
            <div className='mx-2 mt-2 font-bold text-[5vw] pt-2'>희귀</div>
            {rareClosetArr.map((_, i) => {
              const isCaptured = THUserStore?.closet?.rare?.find((item) => {
                return item?.closetId === i
              })

              return (
                <THClosetItem
                  key={i}
                  type='rare'
                  index={i}
                  captured={isCaptured}
                />
              )
            })}
            <div className='mx-2 mt-2 font-bold text-[5vw] pt-2'>고급</div>
            {uniqueClosetArr.map((_, i) => {
              const isCaptured = THUserStore?.closet?.unique?.find((item) => {
                return item?.closetId === i
              })

              return (
                <THClosetItem
                  key={i}
                  type='unique'
                  index={i}
                  captured={isCaptured}
                />
              )
            })}
          </>
        )}
        {currentTab === 'face' && (
          <>
            {collectionArr.map((_, i) => {
              const isCaptured = THUserStore?.collection?.[currentTab]?.find(
                (item) => {
                  return item?.emojiId === i
                },
              )

              return (
                <THCollectionItem
                  key={i}
                  type={currentTab}
                  emojiId={i}
                  captured={isCaptured}
                  fromCloset
                />
              )
            })}
          </>
        )}
      </div>
    </div>
  )
})

export default THCloset
