import { useNavigate } from 'react-router-dom'

export const useNavigateViewer = () => {
  const navigate = useNavigate()

  return {
    navigateToViewer: (
      params: {
        novelId: string
        chapterId: string
      },
      options?: {
        replace: boolean
      },
    ) => {
      navigate('/altoon-novel-reader', {
        state: {
          novelId: params.novelId,
          chapterId: params.chapterId,
        },
        replace: options?.replace,
      })
    },
  }
}
