import { AppScreen } from '@stackflow/plugin-basic-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { ClipLoader } from 'react-spinners'

import ToonCommentComponent from '../components/toon/toonCommentComponent'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import backendApis from '../utils/backendApis'

const AltoonCommentPage = observer(({ params }) => {
  const { pop } = useMyFlow()
  const chapterId = params.chapterId
  // const chapterId = useSearchParam('chapterId')

  const [commentTab, setCommentTab] = useState('new')
  const [isLoading, setIsLoading] = useState(false)
  const [newCommentLength, setNewCommentLength] = useState(0)
  const [bestCommentLength, setBestCommentLength] = useState(0)
  const [bestCommentData, setBestCommentData] = useState()
  const [allCommentData, setAllCommentData] = useState()
  const [myCommentLikedData, setMyCommentLikedData] = useState()
  const [myCommentBlockedData, setMyCommentBlockedData] = useState()
  const [filteredBestCommentData, setFilteredBestCommentData] = useState()
  const [filteredNewCommentData, setFilteredNewCommentData] = useState()
  const [commentCount, setCommentCount] = useState(0)
  const SCREEN_HEIGHT = window.innerHeight - 100

  useEffect(() => {
    const fetchChapterData = async () => {
      const chapterInfo = await backendApis.loadChapter(chapterId)
      if (chapterInfo?.status === 200) {
        setCommentCount(chapterInfo?.data?.commentCount)
      }
    }

    const fetchCommentData = async () => {
      setIsLoading(true)

      // 댓글 데이터를 불러옵니다.
      const bestCommentData = await backendApis.loadComments(
        chapterId,
        'bestAll',
        32,
        0,
      )
      const newCommentData = await backendApis.loadComments(
        chapterId,
        'new',
        32,
        0,
      )

      // 전체 댓글 데이터를 설정합니다.
      setBestCommentData(bestCommentData?.data)
      setAllCommentData(newCommentData?.data)

      // 좋아요한 댓글 아이디와 차단한 댓글 아이디를 설정합니다.
      const commentLikedData = await backendApis.loadCommentLikedData()
      setMyCommentLikedData(commentLikedData?.data[0]?.likedCommentIds)
      setMyCommentBlockedData(commentLikedData?.data[0]?.blockedCommentIds)
      setIsLoading(false)
      setNewCommentLength(newCommentData?.length)
      setBestCommentLength(bestCommentData?.length)
    }
    fetchChapterData()
    fetchCommentData()
  }, [])

  useEffect(() => {
    // MyCommentBlockedData가 변경될 때마다 실행됩니다.
    if (myCommentBlockedData?.length > 0) {
      const filteredBestComments = bestCommentData?.filter(
        (comment) => !myCommentBlockedData?.includes(comment._id),
      )
      const filteredNewcomments = allCommentData?.filter(
        (comment) => !myCommentBlockedData?.includes(comment._id),
      )

      // 필터링된 댓글 데이터를 설정합니다.
      setFilteredBestCommentData(filteredBestComments)
      setFilteredNewCommentData(filteredNewcomments)
    } else {
      setFilteredBestCommentData(bestCommentData)
      setFilteredNewCommentData(allCommentData)
    }
  }, [
    myCommentBlockedData,
    allCommentData,
    bestCommentData,
    // filteredBestCommentData,
    // filteredNewCommentData,
  ])

  const loadComments = async () => {
    try {
      setIsLoading(true)

      if (commentTab === 'best') {
        if (bestCommentLength > filteredBestCommentData?.length) {
          const loadedBestCommentData = await backendApis.loadComments(
            chapterId,
            'bestAll',
            32,
            filteredBestCommentData?.length,
          )

          setFilteredBestCommentData([
            ...filteredBestCommentData,
            ...loadedBestCommentData?.data,
          ])
        }
      }

      if (commentTab === 'new') {
        if (newCommentLength > filteredNewCommentData?.length) {
          const loadedNewCommentData = await backendApis.loadComments(
            chapterId,
            'new',
            32,
            filteredNewCommentData?.length,
          )

          setFilteredNewCommentData([
            ...filteredNewCommentData,
            ...loadedNewCommentData?.data,
          ])
        }
      }
      setIsLoading(false)
    } catch (error) {
      console.error('Error loading comments:', error)
    }
  }

  const renderItem = (index, data) => {
    return (
      <div className='flex flex-col p-1 justify-start text-start flex-1 w-full h-full'>
        <ToonCommentComponent
          key={data?._id}
          comment={data}
          type='new'
          myCommentLikedData={myCommentLikedData}
        />
      </div>
    )
  }

  const HeaderComponent = () => {
    return (
      <section className='fixed top-0 flex flex-col w-full h-[15vh] bg-white z-10 py-2 '>
        <div className='flex flex-row items-center justify-between overflow-hidden'>
          <button
            type='button'
            aria-label='back'
            className='px-3 py-2 whitespace-nowrap'
            onClick={async () => {
              pop()
            }}
          >
            <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
          </button>
          <div className='text-2xl px-3 py-2 whitespace-nowrap'>
            <h3 className='relative flex flex-row items-center text-xl px-1 font-bold z-1'>
              댓글
              <div className='ml-1 text-sm'>({commentCount})</div>
            </h3>
          </div>
          <div className='w-10' />
        </div>
        <div className='flex flex-row w-full text-center'>
          <button
            type='button'
            onClick={() => {
              setCommentTab('best')
            }}
            className={`w-1/2 py-2 border ${
              commentTab === 'best'
                ? 'border-red-500 text-red-500 font-bold'
                : ''
            }}`}
          >
            베스트 댓글
          </button>
          <button
            type='button'
            onClick={() => {
              setCommentTab('new')
            }}
            className={`w-1/2 py-2 border ${
              commentTab === 'new'
                ? 'border-red-500 text-red-500 font-bold'
                : ''
            }}`}
          >
            최신 댓글
          </button>
        </div>
      </section>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <AppScreen>
      <div className='w-full' style={{ height: '100vh' }}>
        <HeaderComponent />
        <div className='px-4 pt-[15vh]'>
          {filteredBestCommentData?.length < 1 && commentTab === 'best' && (
            <div className='text-center font-semibold py-4'>
              아직 베스트 댓글이 없어요
            </div>
          )}
          {filteredNewCommentData?.length < 1 && commentTab === 'new' && (
            <div className='text-center font-semibold py-4'>
              아직 최신 댓글이 없어요
            </div>
          )}
          <div className='flex flex-col p-1 justify-start text-start flex-1 w-full h-full'>
            {filteredBestCommentData?.length > 0 &&
              commentTab === 'best' &&
              filteredBestCommentData?.map((data) => (
                <ToonCommentComponent
                  key={data?._id}
                  comment={data}
                  type={commentTab}
                  myCommentLikedData={myCommentLikedData}
                />
              ))}
            {filteredNewCommentData?.length > 0 &&
              commentTab === 'new' &&
              filteredNewCommentData?.map((data) => (
                <ToonCommentComponent
                  key={data?._id}
                  comment={data}
                  type={commentTab}
                  myCommentLikedData={myCommentLikedData}
                />
              ))}
            {filteredNewCommentData?.length > 0 &&
              commentTab === 'new' &&
              filteredNewCommentData?.length + 32 <= commentCount && (
                <button
                  type='button'
                  aria-label='load'
                  onClick={loadComments}
                  className='w-full py-2 my-4 border border-gray-300 rounded-lg font-bold'
                >
                  댓글 더보기
                </button>
              )}
          </div>
        </div>
        {isLoading && <LoadingIndicator />}
      </div>
    </AppScreen>
  )
})

export default AltoonCommentPage
