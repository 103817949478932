import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useSearchParam } from 'react-use'

import WriteBokMessageModal from '../modals/WriteBokMessageModal'
import ToastStore from '../stores/ToastStore'
import UserStore from '../stores/UserStore'
import backendApis from '../utils/backendApis'

const DecoBokPage = observer(() => {
  const inputRef = useRef(null)
  const navigate = useNavigate()

  const token = useSearchParam('token')
  const bokNumber = useSearchParam('bokNumber')
  const isLoggedIn = useSearchParam('isLoggedIn')

  const [bokUserData, setBokUserData] = useState()
  const [bokData, setBokData] = useState()
  const [messageData, setMessageData] = useState()
  const [shareLink, setShareLink] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [showNickNameModal, setShowNickNameModal] = useState(false)
  const [showTutorial, setShowTutorial] = useState(false)
  const [modalMessage, setModalMessage] = useState()
  const [showLoginNudgeModal, setShowLoginNudgeModal] = useState(false)
  const [showNotNowModal, setShowNotNowModal] = useState(false)
  const [openAlwayzInstallModal, setOpenAlwayzInstallModal] = useState(false)
  const [openMessageContentModal, setOpenMessageContentModal] = useState(false)
  const targetDate = moment('2024-02-10')
  const now = moment()
  const [currentDate, setCurrentDate] = useState(now)
  const today = moment()

  const [animate, setAnimate] = useState(true)

  // const fetchBokMessageData = async () => {
  //   setIsLoading(true)
  //   const result = await backendApis.bokMessage({
  //     bokNumber,
  //   })
  //   if (result?.status === 200) {
  //     setMessageData(result?.data)
  //   }
  //   setIsLoading(false)
  // }

  // const fetchBokData = async () => {
  //   setIsLoading(true)
  //   const result = await backendApis.bokUser({
  //     phoneNumber: bokNumber,
  //   })
  //   if (result?.status === 200) {
  //     setBokData(result?.data)
  //     fetchBokMessageData()
  //   } else if (result?.status === 201) {
  //     setBokData(result?.data)
  //     decoBokTutorial()
  //   } else {
  //     ToastStore.networkError()
  //   }

  //   const shareLinkFromStorage = window.localStorage.getItem(
  //     `bok_dynamic_link_${bokNumber}`,
  //   )
  //   if (shareLinkFromStorage) {
  //     setShareLink(shareLinkFromStorage)
  //   } else {
  //     const link = await backendApis.createDynamicLink({
  //       id: bokNumber,
  //       code: 'DecoBok',
  //       type: 'copyLink',
  //     })
  //     if (link?.status === 200) {
  //       setShareLink(link?.data?.data?.shortLink)

  //       window.localStorage.setItem(
  //         `bok_dynamic_link_${bokNumber}`,
  //         link?.data?.data?.shortLink,
  //       )
  //     } else {
  //       ToastStore.networkError()
  //     }
  //   }

  //   backendApis.logBokAction({
  //     bokNumber,
  //     screen: 'DecoBokPage',
  //     action: 'enteredScreen',
  //   })
  //   setIsLoading(false)
  // }

  // useEffect(() => {
  //   window.ReactNativeWebView?.postMessage(
  //     JSON.stringify({
  //       type: 'allowiPhoneGesture',
  //       boolean: true,
  //     }),
  //   )

  //   if (bokNumber) {
  //     fetchBokData()
  //   }
  //   // if (token) {
  //   //   fetchDate()
  //   // }
  //   setTimeout(() => {
  //     setAnimate(false)
  //   }, 3000)
  // }, [bokNumber, showLoginModal, showMessageModal, showNickNameModal])

  const decoBokTutorial = () => {
    setShowTutorial(true)
  }

  const HeaderComponent = () => {
    return (
      <section
        className='flex flex-row overflow-hidden w-screen top-0 fixed justify-start'
        style={{ zIndex: 1 }}
      >
        {token && (
          <button
            type='button'
            className='p-4'
            onClick={async () => {
              window.location.href = '#goBack'
            }}
          >
            <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
          </button>
        )}
        {/* {!token && <div></div>} */}
        {/* {isLoggedIn === 'true' && (
          <button
            type='button'
            className='p-4 text-[3vw] oneMobilePop'
            onClick={() => {
              setShowNickNameModal(true)
              backendApis.logBokAction({
                bokNumber,
                screen: 'DecoBokPage',
                action: 'clickChangeNicknameButton',
              })
            }}
          >
            이름 바꾸기
          </button>
        )} */}
      </section>
    )
  }

  const TextComponent = () => {
    return (
      <div className='absolute top-[10vh] p-4 rounded text-center text-[3vw] text-[#363846]'>
        <div className='pb-2 text-[6vw] text-center whitespace-pre-wrap oneMobilePop'>
          복주머니 이벤트는 종료되었어요
        </div>
        <div className='font-bold pb-1 text-[3.4vw]'>
          다음에 더 좋은 이벤트로 찾아올게요!
        </div>
      </div>
    )
  }

  const MainComponent = () => {
    const BokMessageComponent = ({ msg }) => {
      const randomNumber = Math.floor(Math.random() * 6) + 1

      if (msg?.empty) {
        // 빈 아이템을 위한 렌더링
        return (
          <button
            type='button'
            className='flexCol items-center py-2'
            onClick={() => {
              backendApis.logBokAction({
                bokNumber,
                screen: 'DecoBokPage',
                action: 'clickMessageBoxButton',
              })
              if (token) {
                navigate(
                  `/bok-message?isLoggedIn=${isLoggedIn}&bokNumber=${bokNumber}&token=${token}`,
                )
              } else {
                navigate(
                  `/bok-message?isLoggedIn=${isLoggedIn}&bokNumber=${bokNumber}`,
                )
              }
            }}
          >
            <img
              src='/images/viralEvent/bok_empty_hanging.png'
              alt=''
              className='w-[20vw] h-full'
            />
          </button>
        )
      }

      return (
        <button
          type='button'
          className='flexCol items-center py-2'
          onClick={() => {
            if (isLoggedIn !== 'true') {
              setShowLoginNudgeModal(true)
              return
            }
            setModalMessage(msg)
            backendApis.logBokAction({
              bokNumber,
              screen: 'DecoBokMessagePage',
              action: 'clickedMessage',
            })

            if (moment(today).isBefore(targetDate)) {
              setShowNotNowModal(true)
            } else if (!token && moment(currentDate).isAfter(targetDate)) {
              // 올웨이즈에서만 볼 수 있다는 모달 띄우기
              setOpenAlwayzInstallModal(true)
            } else if (token && moment(currentDate).isAfter(targetDate)) {
              // 메시지 모달 띄우기
              setOpenMessageContentModal(true)
            }
          }}
        >
          <img
            src={`/images/viralEvent/bok_${
              msg?.bokType ?? randomNumber
            }_hanging.png`}
            alt=''
            className='w-[20vw] h-full'
          />
          <div className='whitespace-nowrap oneMobilePop text-[3vw] px-2 py-1 rounded-full'>
            {msg?.nickname}
          </div>
        </button>
      )
    }

    const calculateMinWidth = () => {
      const baseWidth = 100 // 기본 너비를 100vw로 설정
      const itemsPerRow = 8 // 한 줄당 아이템 수
      const rowsNeeded = Math.ceil(filledMessageData.length / itemsPerRow) // 필요한 줄 수 계산
      return Math.max(rowsNeeded * baseWidth, 100) // 최소 100vw, 필요한 줄 수에 따라 증가
    }

    const filledMessageData = [...messageData]
    while (filledMessageData.length % 8 !== 0) {
      filledMessageData.push({
        _id: `empty-${filledMessageData.length}`,
        empty: true,
      })
    }

    return (
      <div className='relative flex flex-col items-center justify-center w-full h-full oneMobilePop'>
        {/* 복주머니 */}
        <div className='flex overflow-x-scroll items-start justify-center w-full'>
          <div
            className='flex flex-col'
            style={{
              minWidth: `${calculateMinWidth()}vw`,
              maxWidth: `${calculateMinWidth()}vw`,
            }}
          >
            {/* minWidth를 조정하여 스크롤 활성화 */}
            <div className='flex justify-around w-full'>
              {/* 첫 번째 줄의 복주머니 엽서 나열 */}
              {filledMessageData?.slice(0, 4).map((msg) => (
                <BokMessageComponent key={msg._id} msg={msg} />
              ))}
              {filledMessageData?.slice(8, 12).map((msg) => (
                <BokMessageComponent key={msg._id} msg={msg} />
              ))}
              {filledMessageData?.slice(16, 20).map((msg) => (
                <BokMessageComponent key={msg._id} msg={msg} />
              ))}
            </div>
            <div className='flex justify-around w-full'>
              {/* 두 번째 줄의 복주머니 엽서 나열 */}
              {filledMessageData?.slice(4, 8).map((msg) => (
                <BokMessageComponent key={msg._id} msg={msg} />
              ))}
              {filledMessageData?.slice(12, 16).map((msg) => (
                <BokMessageComponent key={msg._id} msg={msg} />
              ))}
              {filledMessageData?.slice(20, 24).map((msg) => (
                <BokMessageComponent key={msg._id} msg={msg} />
              ))}
            </div>
          </div>
          {filledMessageData.length > 8 && (
            <button
              type='button'
              className='absolute -right-[1vw] top-[40vw] bg-[#f8f8f8] rounded p-3 flexCol items-center opacity-20 hover:opacity-80'
              onClick={() => {
                backendApis.logBokAction({
                  bokNumber,
                  screen: 'DecoBokPage',
                  action: 'clickMessageBoxButton',
                })
                if (token) {
                  navigate(
                    `/bok-message?isLoggedIn=${isLoggedIn}&bokNumber=${bokNumber}&token=${token}`,
                  )
                } else {
                  navigate(
                    `/bok-message?isLoggedIn=${isLoggedIn}&bokNumber=${bokNumber}`,
                  )
                }
              }}
            >
              <FiChevronRight className='text-[5vw]' />
              <div className='text-[3vw]'>전체보기</div>
            </button>
          )}
        </div>
      </div>
    )
  }

  const WriteMessageButton = () => {
    return (
      <div className='flexCol'>
        <button
          type='button'
          className={
            animate
              ? 'w-[50vw] py-4 absolute bottom-[6vh] right-[5vw] text-[5vw] text-center text-white bg-[#FF5455] rounded-full shadow-sm whitespace-nowrap oneMobilePop animate-wiggle'
              : 'w-[50vw] py-4 absolute bottom-[6vh] right-[5vw] text-[5vw] text-center text-white bg-[#FF5455] rounded-full shadow-sm whitespace-nowrap oneMobilePop'
          }
          onClick={() => {
            setShowMessageModal(true)
            backendApis.logBokAction({
              bokNumber,
              screen: 'DecoBokPage',
              action: 'clickWriteMessageButton',
            })
          }}
        >
          복주머니 선물하기
        </button>
        <button
          type='button'
          className='w-[40vw] py-4 absolute bottom-[6vh] left-[5vw] text-[4vw] text-center rounded-full shadow-sm whitespace-pre-wrap oneMobilePop'
          onClick={async () => {
            backendApis.logBokAction({
              bokNumber,
              screen: 'DecoBokPage',
              action: 'clickLoginButton',
            })
            if (token) {
              const currentUrl = window.location.href
              const baseUrl = currentUrl.includes('?')
                ? currentUrl.split('?')[0]
                : currentUrl

              if (!bokUserData) {
                const userData = await backendApis.bokUserData()
                if (userData?.status === 200) {
                  const newUrl = `${baseUrl}?bokNumber=${userData?.data?.phoneNumber}&isLoggedIn=true&token=${token}`

                  window.location.href = newUrl
                  return
                }
                ToastStore.networkError()
              }

              const newUrl = `${baseUrl}?bokNumber=${bokUserData?.phoneNumber}&isLoggedIn=true&token=${token}`

              window.location.href = newUrl
              return
            }

            setShowLoginModal(true)
          }}
        >
          내 복주머니 만들기
        </button>
      </div>
    )
  }

  const LinkShareButton = () => {
    const handleCopyClipBoard = async (text) => {
      try {
        await navigator.clipboard.writeText(text)
        alert('링크가 복사되었어요!\n친구, 가족, 친척들에게 공유해보세요')
      } catch (e) {
        ToastStore.networkError()
      }
    }

    return (
      <button
        type='button'
        className='w-[80vw] py-4 absolute bottom-[6vh] left-[10vw] text-[5vw] text-center text-white bg-[#FF5455] rounded-full shadow-sm whitespace-nowrap oneMobilePop'
        onClick={async () => {
          handleCopyClipBoard(shareLink)
          backendApis.logBokAction({
            bokNumber,
            screen: 'DecoBokPage',
            action: 'clickShareButton',
          })
        }}
      >
        내 복주머니 링크 공유하기
      </button>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  const LoginModal = () => {
    const [number, setNumber] = useState('')
    const [verificationNumber, setVerificationNumber] = useState('')
    const [sentVerificationNumber, setSentVerificationNumber] = useState()

    const handlePhoneInputChange = (event) => {
      setNumber(event.target.value)
    }
    const handleInputChange = (event) => {
      setVerificationNumber(event.target.value)
    }

    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />

        <button
          type='button'
          className='fixed z-20 top-[18vw] right-[10vw] w-[10vw] h-[10vw] bg-gray-400 transparent-10 rounded-full flex justify-center items-center'
          onClick={() => setShowLoginModal(false)}
        >
          <img src='/images/close.png' alt='' className='w-[6vw] h-[6vw]' />
        </button>
        <div className='fixed z-20 top-[30vw] left-[10vw] w-[80vw] h-[80vw] bg-[#FFF5E9] flexCol items-center justify-between text-[4vw] rounded-lg oneMobilePop'>
          <div className='w-full whitespace-pre-wrap pt-[4vw] text-[6vw] text-[#424452] oneMobilePop'>
            내 복주머니로 이동하기
          </div>
          <div className='w-full text-[3vw] text-gray-500 pb-2'>
            올웨이즈 앱에서는 로그인 없이 내 복주머니를 확인할 수 있어요
          </div>
          <div className='w-full flexCol items-start px-4 pb-4'>
            <div className='py-2'>전화번호 </div>
            <div className='flexRow'>
              <input
                type='number'
                placeholder='전화번호를 입력해주세요'
                value={number}
                onInput={handlePhoneInputChange}
                ref={inputRef}
                className='w-[100%] h-[10vw] text-[3vw] rounded mr-2'
              />
              <button
                type='button'
                className='h-[10vw] px-2 py-2 text-center bg-white text-red-500 border-red-500 border rounded whitespace-nowrap text-[3vw]'
                onClick={async () => {
                  if (number?.length > 8 && number.slice(0, 2) === '01') {
                    const result = await backendApis.requestVerificationNumber({
                      phoneNumber: number,
                      cohort: null,
                      limit: 0,
                    })
                    backendApis.logBokAction({
                      bokNumber,
                      screen: 'DecoBokLogin',
                      action: 'requestVerificationNumber',
                    })
                    if (result?.status === 200) {
                      alert('인증번호가 전송되었습니다')
                      setSentVerificationNumber(
                        result?.data?.verificationNumber,
                      )
                    } else {
                      ToastStore.networkError()
                    }
                  } else {
                    ToastStore.toastOn({
                      type: 'emoji',
                      message: `정확한 휴대전화 번호를 입력해주세요.`,
                      emoji: '❗',
                      duration: 1500,
                    })
                  }
                }}
              >
                인증번호 받기
              </button>
            </div>
          </div>
          <div className='w-full flexCol items-start px-4 pb-4'>
            <div className='mb-2'>인증번호 </div>
            <input
              type='number'
              placeholder='인증번호'
              value={verificationNumber}
              onInput={handleInputChange}
              ref={inputRef}
              className='w-[100%] h-[10vw] text-[3vw] rounded'
            />
          </div>

          <button
            type='button'
            className='w-full py-4 left-[15vw] text-center bg-red-500 text-white rounded-b-lg shadow-sm'
            onClick={async () => {
              if (sentVerificationNumber === verificationNumber) {
                ToastStore.toastOn({
                  type: 'emoji',
                  message: `인증되었습니다`,
                  emoji: '🎉',
                  duration: 1500,
                })
                const currentUrl = window.location.href
                const baseUrl = currentUrl.includes('?')
                  ? currentUrl.split('?')[0]
                  : currentUrl
                const newUrl = `${baseUrl}?bokNumber=${number}&isLoggedIn=true`

                window.location.href = newUrl
                backendApis.logBokAction({
                  bokNumber,
                  screen: 'DecoBokLogin',
                  action: 'confirmVerificationNumber',
                })
                setShowLoginModal(false)
              } else {
                ToastStore.toastOn({
                  type: 'emoji',
                  message: `인증번호가 일치하지 않습니다`,
                  emoji: '❗',
                  duration: 1500,
                })
              }
            }}
          >
            확인
          </button>
        </div>
      </div>
    )
  }

  const NicknameModal = () => {
    const [nickname, setNickname] = useState('')
    const handleInputChange = (event) => {
      setNickname(event.target.value)
    }

    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <button
          type='button'
          className='fixed z-20 top-[18vw] right-[10vw] w-[10vw] h-[10vw] bg-gray-400 transparent-10 rounded-full flex justify-center items-center'
          onClick={() => {
            backendApis.logBokAction({
              bokNumber,
              screen: 'DecoBokNicknameModal',
              action: 'clickClose',
            })
            setShowNickNameModal(false)
          }}
        >
          <img src='/images/close.png' alt='' className='w-[6vw] h-[6vw]' />
        </button>
        <div className='fixed z-20 top-[30vw] left-[10vw] w-[80vw] h-[100vw] bg-[#FFF5E9] flexCol justify-between rounded-lg'>
          <div className='flexCol w-full py-[3vw] items-center'>
            <div className='whitespace-pre-wrap py-[4vw] text-[6vw] text-[#424452] oneMobilePop'>
              {`친구들에게 보여질\n이름을 알려주세요!`}
            </div>
            <img
              src='/images/viralEvent/bokNaming.png'
              alt=''
              className='w-[50vw] h-full'
            />
            <input
              type='text'
              placeholder='이름을 알려주세요'
              value={nickname}
              onInput={handleInputChange}
              ref={inputRef}
              className='w-[80%] my-2 oneMobilePop'
            />
          </div>
          <button
            type='button'
            className={
              nickname?.length > 0
                ? 'w-full py-4 left-[15vw] text-center bg-red-500 text-white font-bold rounded-b-lg shadow-sm'
                : 'w-full py-4 left-[15vw] text-center bg-gray-300 text-gray-600 font-bold rounded-b-lg shadow-sm'
            }
            onClick={async () => {
              if (nickname?.length < 6 && nickname?.length > 0) {
                const result = await backendApis.setNickname({
                  phoneNumber: bokNumber,
                  nickname,
                })
                if (result?.status === 200) {
                  fetchBokData()
                  ToastStore.toastOn({
                    type: 'emoji',
                    message: `안녕하세요 ${nickname}님!`,
                    emoji: '👋',
                    duration: 1500,
                  })
                  backendApis.logBokAction({
                    bokNumber,
                    screen: 'DecoBokTutorial',
                    action: 'setNickname',
                  })
                } else {
                  ToastStore.networkError()
                }
                setShowNickNameModal(false)
              } else {
                ToastStore.toastOn({
                  type: 'emoji',
                  message: `닉네임을 6자 이하로 적어주세요`,
                  emoji: '❗',
                  duration: 1500,
                })
              }
            }}
          >
            확인
          </button>
        </div>
      </div>
    )
  }

  const TutorialModal = () => {
    const [nextModal, setNextModal] = useState(false)
    const [onboardingMessageCount, setOnboardingMessageCount] = useState(0)
    const randomNumber = Math.floor(Math.random() * 3) + 1
    useEffect(() => {
      if (onboardingMessageCount === 0) {
        const receivedMessageCount = async () => {
          const result = await backendApis.bokMessage({
            bokNumber,
          })

          if (result?.status === 200 && result?.data?.length > 0) {
            setOnboardingMessageCount(randomNumber + result?.data?.length)
          } else {
            setOnboardingMessageCount(randomNumber)
          }
        }
        receivedMessageCount()
      }
    }, [])

    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />

        <div className='fixed z-20 top-[30vw] left-[10vw] w-[80vw] h-[100vw] bg-[#FFF5E9] flexCol justify-between rounded-lg'>
          <div className='flexCol w-full pt-[5vw] items-center'>
            <div className='whitespace-pre-wrap py-[4vw] text-[6vw] text-[#424452] oneMobilePop'>
              {nextModal
                ? `${onboardingMessageCount}명의 친구들이\n내게 복주머니를 남겼어`
                : `갑진년\n새해 복 많이 받으세`}
              <span className='text-[#F74A4A]'>용</span>!
            </div>

            <img
              src={
                nextModal
                  ? '/images/viralEvent/bokNudge.png'
                  : '/images/viralEvent/bokGreeting.png'
              }
              alt=''
              className='w-[40vw] h-[40vw]'
            />
          </div>
          <div className='w-full'>
            <button
              type='button'
              className='w-full py-4 left-[15vw] text-center bg-[#F74A4A] text-white font-bold rounded-b-lg shadow-sm'
              onClick={async () => {
                if (!nextModal) {
                  setNextModal(true)
                  await backendApis.createOnboardingMessage({
                    onboardingMessageCount,
                    phoneNumber: bokNumber,
                  })

                  backendApis.logBokAction({
                    bokNumber,
                    screen: 'DecoBokTutorial',
                    action: 'clickedNext',
                  })
                } else {
                  setShowNickNameModal(true)
                  setShowTutorial(false)

                  backendApis.logBokAction({
                    bokNumber,
                    screen: 'DecoBokTutorial',
                    action: 'clickedConfirm',
                  })
                }
              }}
            >
              {nextModal ? `내 복주머니 확인하기` : '다음'}
            </button>
          </div>
        </div>
      </div>
    )
  }

  const NotNowModal = () => {
    const handleCopyClipBoard = async (text) => {
      try {
        await navigator.clipboard.writeText(text)
        alert('링크가 복사되었어요!\n친구, 가족, 친척들에게 공유해보세요')
      } catch (e) {
        ToastStore.networkError()
      }
    }

    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <button
          type='button'
          className='fixed z-20 top-[18vw] right-[10vw] w-[10vw] h-[10vw] bg-gray-400 transparent-10 rounded-full flex justify-center items-center'
          onClick={() => setShowNotNowModal(false)}
        >
          <img src='/images/close.png' alt='' className='w-[6vw] h-[6vw]' />
        </button>

        <div className='fixed z-20 top-[30vw] left-[10vw] w-[80vw] h-[100vw] bg-[#FFF5E9] flexCol justify-between rounded-t-lg'>
          <div className='flexCol w-full pt-[3vw] pb-[3vw] items-center'>
            <div className='whitespace-pre-wrap py-[4vw] text-[5vw] text-[#424452] oneMobilePop'>
              {`내용은 설 당일에 볼 수 있어용\n\n링크를 공유하고\n복주머니를 가득 모아보세용!`}
            </div>

            <img
              src='/images/viralEvent/bokNotNow.png'
              alt=''
              className='w-[50vw] h-[50vw]'
            />
          </div>
          <div className='w-full'>
            <button
              type='button'
              className='w-full py-4 left-[15vw] text-center bg-[#F74A4A] text-white font-bold rounded-b-lg shadow-sm'
              onClick={async () => {
                handleCopyClipBoard(shareLink)
                backendApis.logBokAction({
                  bokNumber,
                  screen: 'DecoBokMessagePage',
                  action: 'clickedCopyLink',
                })
              }}
            >
              링크 복사하기
            </button>
          </div>
        </div>
      </div>
    )
  }

  const AlwayzInstallModal = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <button
          type='button'
          className='fixed z-20 top-[36vw] right-[10vw] w-[10vw] h-[10vw] bg-gray-400 transparent-10 rounded-full flex justify-center items-center'
          onClick={() => setOpenAlwayzInstallModal(false)}
        >
          <img src='/images/close.png' alt='' className='w-[6vw] h-[6vw]' />
        </button>

        <div className='fixed z-20 top-[50vw] left-[10vw] w-[80vw] h-[80vw] bg-[#FFF5E9] flexCol justify-between rounded-t-lg'>
          <div className='flexCol w-full pt-[3vw] pb-[3vw] items-center'>
            <div className='whitespace-pre-wrap py-[4vw] text-[5vw] text-[#424452] oneMobilePop'>
              {`덕담은 보안을 위해\n올웨이즈 앱에서만 보실 수 있어요\n`}
            </div>

            <img
              src='/images/viralEvent/bokLocationInfo.png'
              alt=''
              className='w-[70vw] h-full'
            />
          </div>
          <div className='w-full'>
            <button
              type='button'
              className='w-full py-4 left-[15vw] text-center bg-[#F74A4A] text-white font-bold rounded-b-lg shadow-sm'
              onClick={async () => {
                window.location.href = 'https://alwayzshop.page.link/kWfa'
              }}
            >
              내게 온 덕담 보러가기
            </button>
          </div>
        </div>
      </div>
    )
  }

  const MessageContentModal = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <button
          type='button'
          className='fixed z-20 top-[36vw] right-[8vw] w-[10vw] h-[10vw] bg-gray-400 transparent-10 rounded-full flex justify-center items-center'
          onClick={() => setOpenMessageContentModal(false)}
        >
          <img src='/images/close.png' alt='' className='w-[6vw] h-[6vw]' />
        </button>
        <img
          src='/images/viralEvent/bokMessageTemplate.png'
          alt=''
          className='fixed z-20 top-[40vw] left-[5vw] w-[90vw] h-[90vw]'
        />

        <div className='fixed z-20 top-[50vw] left-[18vw] w-[64vw] h-[80vw] flexCol justify-between rounded-t-lg'>
          <div className='flexCol w-full pt-[3vw] pb-[3vw] items-start'>
            <div className='whitespace-pre-wrap w-full text-start py-[4vw] text-[4vw] text-[#424452] oneMobilePop'>
              {`From: ${modalMessage?.nickname}`}
              <div className='whitespace-pre-wrap pt-[1vw] text-end text-[3vw] text-[#D4C5A8] oneMobilePop'>
                {`${modalMessage?.createdDate} 작성`}
              </div>
            </div>
            <div className='whitespace-pre-wrap h-[40vw] py-[1vw] text-center text-[4vw] text-[#424452] oneMobilePop overflow-y-scroll'>
              {`${modalMessage?.message}`}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const LoginNudgeModal = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <button
          type='button'
          className='fixed z-20 top-[18vw] right-[10vw] w-[10vw] h-[10vw] bg-gray-400 transparent-10 rounded-full flex justify-center items-center'
          onClick={() => setShowLoginNudgeModal(false)}
        >
          <img src='/images/close.png' alt='' className='w-[6vw] h-[6vw]' />
        </button>

        <div className='fixed z-20 top-[30vw] left-[10vw] w-[80vw] h-[100vw] bg-[#FFF5E9] flexCol justify-between rounded-lg'>
          <div className='flexCol w-full pt-[3vw] pb-[3vw] items-center'>
            <div className='whitespace-pre-wrap py-[4vw] text-[5vw] text-[#424452] oneMobilePop'>
              {`친구의 복주머니는\n열어볼 수 없어용\n직접 복주머니를 모아보세용!`}
            </div>

            <img
              src='/images/viralEvent/bokGreeting.png'
              alt=''
              className='w-[50vw] h-[50vw]'
            />
          </div>
          <div className='w-full'>
            <button
              type='button'
              className='w-full py-4 left-[15vw] text-center bg-[#F74A4A] text-white font-bold rounded-b-lg shadow-sm'
              onClick={async () => {
                backendApis.logBokAction({
                  bokNumber,
                  screen: 'DecoBokPage',
                  action: 'clickLoginButton',
                })

                if (token) {
                  const currentUrl = window.location.href
                  const baseUrl = currentUrl.includes('?')
                    ? currentUrl.split('?')[0]
                    : currentUrl

                  if (!bokUserData) {
                    const userData = await backendApis.bokUserData()
                    if (userData?.status === 200) {
                      const newUrl = `${baseUrl}?bokNumber=${userData?.data?.phoneNumber}&isLoggedIn=true&token=${token}`

                      window.location.href = newUrl
                      return
                    }
                    ToastStore.networkError()
                  }

                  const newUrl = `${baseUrl}?bokNumber=${bokUserData?.phoneNumber}&isLoggedIn=true&token=${token}`

                  window.location.href = newUrl
                  return
                }

                setShowLoginModal(true)
                setShowLoginNudgeModal(false)
              }}
            >
              내 복주머니 만들기
            </button>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='w-full h-full flexCol items-center fixed'>
      <img
        src='/images/viralEvent/bokBackground.png'
        className='absolute z-0 top-0 w-full h-full object-fit'
        alt='bg'
      />
      <HeaderComponent />
      <TextComponent />
      {/* {messageData && (
        <div className='absolute top-[34vh] w-full'>
          <MainComponent />
        </div>
      )}
      {(isLoggedIn === 'true' || isLoggedIn === true) && <LinkShareButton />}
      {isLoggedIn !== 'true' && isLoggedIn !== true && <WriteMessageButton />}
      {isLoading && <LoadingIndicator />}
      {showLoginModal && <LoginModal />}
      {showNickNameModal && <NicknameModal />}
      {showLoginNudgeModal && <LoginNudgeModal />}
      {showTutorial && <TutorialModal />}
      {showNotNowModal && <NotNowModal />}
      {openAlwayzInstallModal && <AlwayzInstallModal />}
      {openMessageContentModal && <MessageContentModal />}
      {showMessageModal && (
        <WriteBokMessageModal
          setShowWriteMessageModal={setShowMessageModal}
          bokData={bokData}
        />
      )} */}
    </div>
  )
})

export default DecoBokPage
