import moment from 'moment'
import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { BottomSheet } from 'react-spring-bottom-sheet'

import { Close, CouponPackNudge, PinkGift } from '../images/dailyCheckIn'
import { GoldBox } from '../images/treasureBox'
import backendApis from '../utils/backendApis'
import CheckInCouponBottomSheetItem from './CheckInCouponBottomSheetItem'

let isPressed = false

const CouponPackBottomSheet = ({
  accumulatedPoints,
  couponPackData,
  loadCouponPackData,
  checkInAlarmConsent,
  openCouponModal,
  onDismiss,
  setAccumulatedPoints,
  setIsLoadingTrue,
  setIsLoadingFalse,
}) => {
  const [showGiftModal, setShowGiftModal] = useState(false)

  const triggerToast = () =>
    toast(`포인트가 부족해요`, {
      id: 'no points',
      duration: 2000,
      position: 'top-center',
      style: {
        paddingLeft: 12,
        paddingRight: 8,
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 'bold',
      },
      icon: '🪙',
    })

  const GiftModal = () => {
    return (
      <div className='absolute top-1/2 left-[40vw] z-30'>
        <PinkGift className='shaking w-[20vw] h-[20vw] object-contain' />
      </div>
    )
  }

  return (
    <>
      <BottomSheet open={openCouponModal} onDismiss={onDismiss}>
        {couponPackData?.length > 0 && (
          <div className='mx-2'>
            <div className='flex items-center justify-between h-16 pl-4 pr-9'>
              <div className='w-12' />
              <h3 className='px-2 text-lg font-bold border-b-2 border-black'>
                주간 쿠폰팩 이벤트
              </h3>
              <button
                type='button'
                className='flex items-center justify-center w-8 h-8 p-1 bg-gray-900 rounded-full opacity-20 outline-0'
                onClick={onDismiss}
              >
                <Close className='' />
              </button>
            </div>

            <div className='flex justify-start mx-2 text-xs text-gray-500'>
              *{' '}
              {moment(couponPackData[0].finishedAt)
                .subtract(1, 'day')
                .format('M월 D일')}
              까지 매일 1개씩 받을 수 있어요
            </div>
            <div className='flex justify-start mx-2 text-xs text-gray-500'>
              * 사용 기한: 발급 시점으로부터 12시간
            </div>
            {/* 미션 목록 시작 */}
            <div className='flex flex-row mt-2'>
              {couponPackData[0]?.couponInfo?.slice(0, 3).map((coupon) => {
                return (
                  <CheckInCouponBottomSheetItem
                    key={coupon.couponUniqueNumber + Math.random()}
                    value={coupon.value}
                    couponType={coupon.couponType}
                    condition={coupon.condition}
                    conditionType={coupon.conditionType}
                    receivedCoupon={coupon.receivedCoupon}
                    lastReceivedAt={couponPackData[0].lastReceivedAt}
                    checkInAlarmConsent={checkInAlarmConsent}
                    onButtonClick={async () => {
                      setIsLoadingTrue()
                      await backendApis.receiveCheckInCouponFromPack(
                        coupon.value,
                        coupon.couponType,
                        coupon.condition,
                        coupon.conditionType,
                      )
                      loadCouponPackData()
                      setIsLoadingFalse()
                    }}
                  />
                )
              })}
            </div>
            <div className='flex flex-row'>
              {couponPackData[0]?.couponInfo?.slice(3, 6).map((coupon) => {
                return (
                  <CheckInCouponBottomSheetItem
                    key={coupon.couponUniqueNumber + Math.random()}
                    value={coupon.value}
                    couponType={coupon.couponType}
                    condition={coupon.condition}
                    conditionType={coupon.conditionType}
                    receivedCoupon={coupon.receivedCoupon}
                    lastReceivedAt={couponPackData[0].lastReceivedAt}
                    checkInAlarmConsent={checkInAlarmConsent}
                    onButtonClick={async () => {
                      setIsLoadingTrue()

                      await backendApis.receiveCheckInCouponFromPack(
                        coupon.value,
                        coupon.couponType,
                        coupon.condition,
                        coupon.conditionType,
                      )
                      loadCouponPackData()
                      setIsLoadingFalse()
                    }}
                  />
                )
              })}
            </div>
            <div className='w-full bg-[#F5F5F5] h-2 my-4' />

            {/* <div className='relative mx-2 font-bold'>
              오늘만 드려요! {moment(today).format('M월 D일')} 한정쿠폰
              <span
                className='absolute inset-x-0 w-32 h-1'
                style={{
                  left: 92,
                  bottom: 3,
                  borderBottom: '8px solid rgba(255, 223, 221, 0.9)',
                  zIndex: '-1',
                }}
              />
            </div>
            <div className='flex flex-row items-center justify-between h-full p-2 m-2 border rounded-lg'>
              <div className='flex flex-col items-center'>
                <div>10% 할인</div>
                <div>(최대 2,000원)</div>
              </div>
              <div>20,000원 이상 사용</div>
              <button
                type='button'
                className='px-4 py-2 text-white bg-red-500 rounded-lg'
                onClick={() => {
                }}
              >
                받기
              </button>
            </div>
            <div className='w-full bg-[#F5F5F5] h-2 my-4' /> */}
            <div className='relative mx-2 font-bold'>
              주문을 10번하면 무료 상품에 응모할 수 있어요!
              <span
                className='absolute inset-x-0 w-16 h-1'
                style={{
                  left: 108,
                  bottom: 3,
                  borderBottom: '8px solid rgba(255, 223, 221, 0.9)',
                  zIndex: '-1',
                }}
              />
            </div>
            <div className='flex flex-row items-center justify-between h-full p-2 m-2 border rounded-lg'>
              <GoldBox className='w-10 h-full' />
              <div className='text-sm italic text-gray-600'>
                아직 준비중인 이벤트에요!
              </div>
              <button
                type='button'
                className='px-4 py-2 text-white bg-gray-300 rounded-lg'
                onClick={() => {}}
              >
                받기
              </button>
            </div>
          </div>
        )}
        {(couponPackData?.length === 0 || !couponPackData) && (
          <>
            <div className='flex flex-col items-center justify-between pt-2'>
              <div className='flex items-center justify-between w-full h-16 pl-4 pr-6'>
                <div className='w-12' />
                <h3 className='px-2 text-2xl font-bold'>주간 쿠폰팩 이벤트</h3>
                <button
                  type='button'
                  className='flex items-center justify-center w-8 h-8 p-1 bg-gray-900 rounded-full opacity-20 outline-0'
                  onClick={onDismiss}
                >
                  <Close className='' />
                </button>
              </div>
              <div className='flex flex-col items-center py-2 text-sm text-gray-600'>
                <div className='flex flex-row'>
                  <div className='font-semibold text-[#E27C78]'>
                    1,500포인트
                  </div>
                  를 사용해서 쿠폰팩을 뽑아보세요
                </div>
                <div className='flex flex-row'>
                  <div className='font-bold text-red-600'>
                    최소 3천원~최대 2만원
                  </div>
                  의 쿠폰팩에 당첨될 수 있어요!
                </div>
                <div className='flex flex-row mt-1 text-xs text-gray-500'>
                  (* 쿠폰팩은 1주일간 유효해요)
                </div>
              </div>
              <CouponPackNudge className='w-full py-2 pr-4' />
              <button
                type='button'
                className={
                  accumulatedPoints < 1500
                    ? 'w-full pt-4 pb-6 mt-2 bg-gray-300 text-white text-center font-bold text-xl'
                    : 'w-full pt-4 pb-6 mt-2 bg-red-600 text-white text-center font-bold text-xl'
                }
                onClick={async () => {
                  backendApis.recordEventLog(
                    'checkInCouponPack',
                    'buyCouponPack',
                  )
                  if (accumulatedPoints < 1500) {
                    triggerToast()
                    return
                  }
                  if (isPressed) {
                    return
                  }
                  isPressed = true
                  const result = await backendApis.drawCouponPack()
                  if (result === 'not enough points') {
                    triggerToast()
                  } else {
                    setAccumulatedPoints(accumulatedPoints - 1500)

                    setShowGiftModal(true)
                    setTimeout(async () => {
                      await loadCouponPackData()
                      setShowGiftModal(false)
                    }, 2000)
                  }
                  isPressed = false
                }}
              >
                {accumulatedPoints < 1500 ? '포인트가 부족해요' : '쿠폰팩 뽑기'}
              </button>
            </div>
          </>
        )}
      </BottomSheet>
      {showGiftModal && <GiftModal />}
    </>
  )
}

export default CouponPackBottomSheet
