const CategoryName = (categoryId, short = false) => {
  if (!short) {
    if (categoryId === 0) return '패션'
    if (categoryId === 1) return '뷰티'
    if (categoryId === 2) return '출산/유아동'
    if (categoryId === 3) return '식품'
    if (categoryId === 4) return '주방용품'
    if (categoryId === 5) return '생활용품'
    if (categoryId === 6) return '건강'
    if (categoryId === 7) return '펫용품'
    if (categoryId === 8) return '가전'
    if (categoryId === 9) return '홈인테리어'
    if (categoryId === 10) return '도서'
    if (categoryId === 11) return '취미/완구'
    if (categoryId === 12) return '스포츠/레저'
  }
  if (categoryId === 0) return '패션'
  if (categoryId === 1) return '뷰티'
  if (categoryId === 2) return '출산'
  if (categoryId === 3) return '식품'
  if (categoryId === 4) return '주방'
  if (categoryId === 5) return '생활'
  if (categoryId === 6) return '건강'
  if (categoryId === 7) return '펫'
  if (categoryId === 8) return '가전'
  if (categoryId === 9) return '홈'
  if (categoryId === 10) return '도서'
  if (categoryId === 11) return '취미'
  if (categoryId === 12) return '스포츠'
}
export default CategoryName
