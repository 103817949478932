import commaNumber from 'comma-number'
import { useRef } from 'react'

import dealImages from '../../../images/deal'
import UserStore from '../../../stores/UserStore'
import GaugeBar from '../../atoms/gaugeBar'
import ImageAtom from '../../atoms/imageAtom'
import NameTag from '../tag/nameTag'
import { handleOnClickFactory } from './itemNavigateHandler'

const TreasureDealItemComponent = ({
  itemInfo,
  empty = false,
  dealEndedAt = '',
  isFrom = 'TreasureDealPage',
  isScrolling = false,
}) => {
  const ref = useRef(null)
  const teamPrice = parseInt(itemInfo?.teamPurchasePrice, 10)
  const dealPrice = parseInt(itemInfo?.minDealTeamPurchasePrice, 10)
  const type = 'treasureDeal'
  const validHandleOnClick = handleOnClickFactory(
    itemInfo,
    isFrom,
    type,
    dealEndedAt,
  )
  const handleOnClick = isScrolling ? () => {} : validHandleOnClick

  if (empty) {
    return (
      <div className='flex flex-col items-center justify-center flex-1 w-full'>
        <div className='flex flex-row items-center justify-start flex-1 w-full p-5'>
          <div className='flex-none w-32 h-32 mr-5 border border-gray-300 rounded-lg' />
          <div className='flex flex-col items-start justify-start flex-1' />
        </div>
        <div className='w-[90%] border-b border-gray-300' />
      </div>
    )
  }
  return (
    <div className='flex flex-1 w-full p-2'>
      <div
        aria-hidden='true'
        className='flex items-center justify-center flex-1 w-full bg-white rounded-xl'
        onClick={handleOnClick}
      >
        <div className='flex flex-col items-center justify-center flex-1 h-full'>
          <div className='flex flex-row justify-start flex-1 w-full h-full p-2'>
            <div className='flex-none mr-3 place-self-center'>
              <ImageAtom
                src={itemInfo?.mainImageUris[0]}
                className='object-cover w-32 h-32 border border-gray-300 rounded-lg'
                width={256}
                height={256}
              />
            </div>
            <div className='flex flex-col justify-between flex-1'>
              <NameTag type='treasureDeal' css='text-white bg-[#171717]' />
              <div
                className='flex flex-col flex-1 mt-1 leading-tight text-ellipsis'
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {itemInfo?.itemTitle}
              </div>
              <div className='flex flex-col items-end justify-end mr-2'>
                <div className='text-sm font-bold text-gray-500'>
                  일반가 {commaNumber(teamPrice)}원
                </div>
                <div className='flex flex-row items-center flex-1'>
                  <div className='flex flex-1 items-center text-base font-bold text-[#FD9F28] whitespace-nowrap'>
                    <div className='flex flex-row flex-1 text-sm'>
                      <img
                        src={dealImages?.Unlocked}
                        className='w-4 h-4 mr-1'
                        alt='Unlocked'
                      />
                      보물특가
                    </div>
                    <span className='text-xl ml-1 text-red-500 mx-0.5'>
                      {commaNumber(dealPrice)}원
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-[90%] border-b border-gray-300' />
        </div>
      </div>
    </div>
  )
}

export default TreasureDealItemComponent
