import { observer } from 'mobx-react-lite'
import React from 'react'

import { CheckinIcon } from '../../images/dailyCheckIn'
import yellowCoins from '../../images/dailyCheckIn/yellowCoins.png'
import UserStore from '../../stores/UserStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import backendApis from '../../utils/backendApis.ts'

const OfferwallCheckInComponent = observer(({ isLoading, setIsLoading }) => {
  const [isCheckedIn, setIsCheckedIn] = React.useState(false)

  const handleOfferwallCheckIn = async () => {
    if (isLoading) return
    setIsLoading(true)

    const processOpenEnvelopeResult = await backendApis.processOpenEnvelopeV2(
      'ey-daily-check-in-220410-v1',
      0,
      'offerwallDailyCheckIn',
      'a',
      null,
      false,
      true,
      false,
    )

    if (processOpenEnvelopeResult?.status === 200) {
      setIsCheckedIn(true)
      THModalStore.setType({
        type: 'reward',
        config: {
          removeCloseButton: true,
          earnEffect: true,
          removeFont: true,
          rewardName: `${processOpenEnvelopeResult.data.totalReward}원을 받았어요!`,
          description: '오늘의 출석체크 완료',
          src: yellowCoins,
          text: '확인',
          forceOpen: true,
        },
      })

      UserStore.setAccumulatedPoints(
        UserStore.accumulatedPoints +
          processOpenEnvelopeResult.data.totalReward,
      )
    }

    setIsLoading(false)
  }

  React.useEffect(() => {
    const checkStatus = async () => {
      const todayRewards = await backendApis.getTodayRewards()
      if (todayRewards?.status === 200) {
        const offerwallCheckIn =
          todayRewards?.result?.dailyCheckInRewardHistory?.find(
            (reward) => reward.rewardType === 'offerwallDailyCheckIn',
          )
        setIsCheckedIn(!!offerwallCheckIn)
      }
    }

    checkStatus()
  }, [])

  return (
    <div className='flex items-center justify-between px-4 py-3 mx-4 mt-4 border border-[#EEEFF3] rounded-xl'>
      <div className='flex items-center'>
        <CheckinIcon className='w-[48px] h-[48px] mr-[6px]' />
        <div>
          <div className='text-[18px] text-[#191D28] font-bold'>
            출석체크하기
          </div>
          <div className='text-[14px] text-[#71737C]'>최대 5포인트</div>
        </div>
      </div>
      <button
        type='button'
        onClick={handleOfferwallCheckIn}
        disabled={isCheckedIn}
        className={`px-4 py-2 text-sm font-bold rounded-lg ${
          isCheckedIn
            ? 'bg-gray-200 text-gray-500'
            : 'bg-[#FFEDEE] text-[#F8323E] active:scale-95 duration-200'
        }`}
      >
        {isCheckedIn ? '출석완료' : '출석하기'}
      </button>
    </div>
  )
})

export default OfferwallCheckInComponent
