/* eslint-disable no-alert */
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import AuthStore from '../../stores/AuthStore'
import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

const AltoonCMSLoginScreen = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const [provider, setProvider] = useState(location.state?.provider || '')
  const [password, setPassword] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    AltoonCMSUserStore.setProvider(provider)

    const result = await altoonCMSBackendApis.attemptLogin({
      providerName: provider,
      password,
    })

    // console.log('data: ', result)

    if (result?.status === 200) {
      AuthStore.setToken(result?.data?.token)
      AltoonCMSUserStore.setProviderId(result?.data?.providerId)
      localStorage.setItem('@altoon@provider@token@', result?.data?.token)
      localStorage.setItem('@altoon@provider@', result?.data?.providerId)

      // const seller = await SellerStore.loadSellerInfo()
      // if (seller)
      //   Log.event('loginButton', 'LoginPage', {
      //     click: 'sellerLoggedIn',
      //   })
      if (result?.data?.isMasterAccount) {
        AltoonCMSUserStore.setIsMasterAccount(true)
      } else {
        AltoonCMSUserStore.setIsMasterAccount(false)
      }
      navigate('/altoon-cms/main')
    } else {
      alert('아이디 혹은 비밀번호가 잘못되었습니다.')
    }
  }
  return (
    <div className='flex flex-col items-center w-screen h-screen py-24 bg-gradient-to-r from-theme-500 to-theme-400'>
      <div className='flex flex-col items-center w-[480px] pt-16 pb-12 px-12 text-center bg-white rounded-md'>
        <img className='w-1/4' src='../images/toon/cms/LogoRed.png' alt='' />
        <div className='mt-5 text-3xl font-black'>올툰 콘텐츠 관리 시스템</div>
        <div className='mt-5 mb-2 text-md'>
          올툰 <span className='font-bold '>콘텐츠 제공자 아이디</span>로
          로그인해주세요
        </div>
        <input
          className='w-full h-[48px] py-2 mt-2 mb-1 bg-theme-100 focus:bg-white'
          type='text'
          name='name'
          placeholder='아이디'
          value={provider}
          onChange={(e) => {
            setProvider(e.target.value)
          }}
        />
        <input
          className='w-full h-[48px] py-2 my-1 bg-theme-100 focus:bg-white'
          type='password'
          name='password'
          placeholder='비밀번호'
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(e)
            }
          }}
        />
        <button
          className='w-full py-4 mt-4 text-xl font-extrabold text-white rounded-md border-theme-500 bg-theme-500'
          type='button'
          onClick={handleSubmit}
        >
          로그인
        </button>
        <button
          className='mt-3 text-base font-medium text-theme-500'
          type='button'
          onClick={() => {
            alert(
              '아직 준비중인 기능입니다. 꼭 필요하신 분은 올툰에 문의해주세요.',
            )
            // navigate('/altoon-cms-login/find-password')
          }}
        >
          <span>비밀번호 찾기</span>
        </button>
        {/* <div className='mt-8 mb-2 prose text-gray-600'>
          아직 올웨이즈 입점사가 아니신가요?
        </div>
        <button
          className='w-full py-4 my-2 text-xl border rounded-md text-theme-500 border-theme-500'
          type='button'
          onClick={() => {
            navigate.push('/login/register-seller')
          }}
        >
          <span>신규 입점사 신청하기</span>
        </button> */}
      </div>
    </div>
  )
})

export default AltoonCMSLoginScreen
