import { UseQueryOptions } from '@tanstack/react-query'

import backendApis from '../../../utils/backendApis'

// interfaces
interface NovelChapter {
  id: number // chapterId
  novelId: number // novelId
  sequence: number // 회차 번호
  title: string // 작품명
  cover_image_url: string // 커버 이미지
  is_viewable: boolean // 열람 가능 여부
}

interface NovelChapterInfo extends NovelChapter {
  prevNovelChapter: NovelChapter | null // 다음화 없을 경우 null
  nextNovelChapter: NovelChapter | null // 이전화 없을 경우 null
}

enum DocumentType {
  Style = 'style',
  Cover = 'cover',
  Body = 'body',
  Image = 'image',
}

interface NovelChapterDocument {
  chapterId: number
  text: {
    type: DocumentType
    name: string
    content: string // 본문 html string
  }[]
}

// remotes
function extractBodyContent(htmlString: string) {
  // DOMParser를 사용하여 HTML 문자열을 파싱
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')

  // body 태그 안의 내용을 추출
  const bodyContent = doc.body.innerHTML

  return bodyContent
}

export const getNovelInfo = async (params: { novelId: number }) => {
  const { data } = await backendApis.loadNovelInfoById({
    novelId: params.novelId,
  })
  return data
}

export const getNovelChapterInfo = async (params: {
  novelId: number
  chapterId: number
}) => {
  const { data } = await backendApis.loadNovelChapterInfo({
    novelId: params.novelId,
    chapterId: params.chapterId,
  })
  return data
}

export const getNovelChapterDocument = async (params: {
  novelId: number
  chapterId: number
}) => {
  const { data } = await backendApis.loadNovelChapterDetail({
    novelId: params.novelId,
    chapterId: params.chapterId,
  })
  return data
}

export const getNovelViewLogDocument = async (params: {
  novelId: number
  chapterId: number
}) => {
  const { data } = await backendApis.logNovelChapterView({
    novelId: params.novelId,
    chapterId: params.chapterId,
  })
  return data
}

// queries
const queryOptions = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
>(
  options: UseQueryOptions<TQueryFnData, TError, TData>,
) => {
  return options
}

export const SEQUENCE_SEPARATOR = 'viewer-sequence-separator'

export const getNovelInfoQuery = (params: { novelId: number }) => {
  return queryOptions({
    queryKey: ['novelInfo', params],
    queryFn: () => getNovelInfo(params),
  })
}

export const getNovelChapterInfoQuery = (params: {
  novelId: number
  chapterId: number
}) => {
  return queryOptions({
    queryKey: ['chapterInfo', params],
    queryFn: () => getNovelChapterInfo(params),
  })
}

export const getNovelChapterDocumentQuery = (params: {
  novelId: number
  chapterId: number
}) => {
  return queryOptions({
    queryKey: ['chapterDocument', params],
    queryFn: () => getNovelChapterDocument(params),
    gcTime: 0,
    select: (data) => {
      const htmlString = data.text
        .map((doc) => {
          if (doc.type === DocumentType.Body) {
            return `<div class=${SEQUENCE_SEPARATOR}>${extractBodyContent(
              doc.content,
            )}</div>`
          }
          return doc.content
        })
        .join('')
      return {
        ...data,
        htmlString,
      }
    },
  })
}

export const logNovelChapterViewQuery = (params: {
  novelId: number
  chapterId: number
}) => {
  return queryOptions({
    queryKey: ['chapterViewDocument', params],
    queryFn: () => getNovelViewLogDocument(params),
    refetchOnMount: 'always',
    gcTime: Infinity,
  })
}
