import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import WatermelonAction from '../../actions/WatermelonAction'
import WatermelonUserStore from '../../stores/WatermelonUserStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import backendApis from '../../utils/backendApis'
import WatermelonDailyMission from './molecules/WatermelonDailyMission'

function attendanceTimeTextConverter(time) {
  if (time === 'Morning' || time === 'PreMorning' || time === 'Dawn') {
    return '오전'
  }
  if (time === 'Dinner' || time === 'PreDinner') {
    return '오후'
  }
}

function getNextTimeText(time) {
  if (time === 'Morning' || time === 'PreDinner') {
    return '오후'
  }
  if (time === 'Dinner' || time === 'PreMorning' || time === 'Dawn') {
    return '오전'
  }
}

const WatermelonBackpack = observer(() => {
  const [attendanceTimeText, setAttendanceTimeText] = useState('Dawn')
  const [attendanceMissionFinishText, setAttendanceMissionFinishText] =
    useState('오전 7시 가능')
  const [dailyCheckInFinishText, setDailyCheckInFinishText] =
    useState('출석완료')
  const [timeTempAttendance, setTimeTempAttendance] = useState(null)
  const [tempDailyCheckIn, setTempDailyCheckIn] = useState(null)

  useEffect(() => {
    const HHmm = moment().format('HHmm')
    if (HHmm >= '0000' && HHmm < '1200') {
      setAttendanceTimeText('Morning')
    } else if (HHmm >= '1200' && HHmm < '2400') {
      setAttendanceTimeText('Dinner')
    } else {
      setAttendanceTimeText('')
    }
  }, [])

  useEffect(() => {
    if (['Dinner', 'PreMorning', 'Dawn']?.includes(attendanceTimeText)) {
      setAttendanceMissionFinishText('내일 오전 가능')
    } else {
      setAttendanceMissionFinishText('오후 가능')
    }
    if (WatermelonUserStore?.userData?.arcadeTicket >= 20) {
      setAttendanceMissionFinishText('도전권 가득참')
    }
  }, [attendanceTimeText, WatermelonUserStore?.userData?.arcadeTicket])

  useEffect(() => {
    if (WatermelonUserStore?.userData?.arcadeTicket >= 20) {
      setTimeTempAttendance({
        ...WatermelonUserStore?.dailyMission?.[
          `arcadeAttendance${attendanceTimeText}`
        ],
        status: 'finish',
      })
      setTempDailyCheckIn({
        ...WatermelonUserStore?.dailyMission?.arcadeDailyCheckIn,
        status: 'finish',
      })
      setDailyCheckInFinishText('도전권 가득참')
    } else if (WatermelonUserStore?.userData?.arcadeTicket < 20) {
      setTimeTempAttendance({
        ...WatermelonUserStore?.dailyMission?.[
          `arcadeAttendance${attendanceTimeText}`
        ],
      })
      setTempDailyCheckIn({
        ...WatermelonUserStore?.dailyMission?.arcadeDailyCheckIn,
      })
      setDailyCheckInFinishText('출석완료')
    }
  }, [
    WatermelonUserStore?.userData?.arcadeTicket,
    WatermelonUserStore?.dailyMission?.arcadeDailyCheckIn?.status,
    WatermelonUserStore?.dailyMission?.[`arcadeAttendance${attendanceTimeText}`]
      ?.status,
    WatermelonUserStore?.dailyMission?.[
      `arcadeAttendance${attendanceTimeText}`
    ],
    WatermelonUserStore?.dailyMission?.arcadeDailyCheckIn,
  ])

  return (
    <div className='default relative justify-center items-center w-full pb-4 text-[#313138] p-4 max-h-[60vh] overflow-y-scroll'>
      <div className='flex flex-col w-full justify-start items-center overflow-x-scroll py-2'>
        <WatermelonDailyMission
          mission={{
            gameType: 'watermelonArcade',
            rewardType: 'arcadeTicket',
            rewardAmount: 1,
            rewardFixed: '확정',
            finishText: dailyCheckInFinishText,
            status: 'reward',
            type: 'arcadeDailyCheckIn',
            actionText: '보상 받기',
            title: '출석하기',
            rewardHandler: () => {
              WatermelonAction.giveWatermelonMissionReward({
                type: `arcadeDailyCheckIn`,
              })
            },
            ...tempDailyCheckIn,
          }}
        />

        {/* <WatermelonDailyMission
          mission={{
            gameType: 'watermelonArcade',
            rewardType: 'arcadeTicket',
            rewardAmount: 1,
            rewardFixed: '확정',
            finishText: attendanceMissionFinishText,
            status: ['Morning', 'Dinner']?.includes(attendanceTimeText)
              ? 'reward'
              : 'finish',
            type: 'arcadeAttendance',
            actionText: '보상 받기',
            title: `${
              WatermelonUserStore?.dailyMission?.[
                `attendance${attendanceTimeText}`
              ]?.status === 'finish'
                ? `${getNextTimeText(attendanceTimeText)} 티켓 받기`
                : `${attendanceTimeTextConverter(attendanceTimeText)} 티켓 받기`
            }`,
            rewardHandler: () => {
              WatermelonAction.giveWatermelonMissionReward({
                type: `arcadeAttendance${attendanceTimeText}`,
              })
            },
            ...timeTempAttendance,
          }}
        /> */}

        <WatermelonDailyMission
          mission={{
            gameType: 'watermelonArcade',
            rewardType: 'arcadeTicket',
            rewardAmount: 2,
            rewardFixed: '확정',
            status: WatermelonUserStore?.showKakaoShare ? 'action' : 'finish',
            type: 'arcadeShare',
            actionText: '공유하기',
            title: '공유하기 (도전권 중복 가능)',
            actionHandler: () => {
              backendApis.commonInsert({
                data: {
                  type: 'clickArcadeShareButton',
                },
                collection: 'watermelon_clickLog',
              })
              WatermelonAction.kakaoLinkShare({
                shareType: 'Watermelon_ArcadeLinkShare',
                title: '[양파게임] 아케이드 모드 출시! 훨씬 쉬워졌어요',
                description: '양파를 10번 만들어 보세요!',
                imageUrl:
                  'https://assets.ilevit.com/a1ec211f-5e7e-4bfd-8da6-5912a93491f1.png',
                payload: {},
                templateId: 101363,
              })
            },
          }}
        />

        <WatermelonDailyMission
          mission={{
            gameType: 'watermelonArcade',
            rewardType: 'arcadeTicket',
            rewardAmount: 5,
            status: WatermelonUserStore.showInviteShare ? 'action' : 'finish',
            type: 'arcadeNewInvite',
            actionText: '초대하기',
            title: '새친구 초대하기',
            rewardFixed: true,
            actionHandler: () => {
              backendApis.commonInsert({
                data: {
                  type: 'clickArcadeInviteButton',
                },
                collection: 'watermelon_clickLog',
              })
              THModalStore.setType({
                type: 'watermelonRule',
                config: {
                  title: '새친구 초대',
                  forceOpen: true,
                  src: 'inviteModal',
                  onClick: () => {
                    TreasureHuntAction.linkShare({
                      shareType: 'Watermelon_ArcadeInvite',
                      title: '[양파게임] 아케이드 모드 출시! 훨씬 쉬워졌어요',
                      description: '양파를 10번 만들어 보세요!',
                      imageUrl:
                        'https://assets.ilevit.com/a1ec211f-5e7e-4bfd-8da6-5912a93491f1.png',
                      payload: {},
                    })
                  },
                  text: '초대하기',
                },
              })
            },
          }}
        />
      </div>
    </div>
  )
})

export default WatermelonBackpack
