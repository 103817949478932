/* eslint-disable react/button-has-type */
import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import TableData from '../../components/atoms/tableData'
import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'

const SettlementInfo = observer(() => {
  const className = 'border-2 border-transparent'
  const className2 = 'border-2 border-transparent font-bold'

  const [totalSettlementAmount, setTotalSettlementAmount] = useState(0)
  const [totalAppStoreFee, setTotalAppStoreFeeFee] = useState(0)
  const [totalAlwayzFee, setTotalAlwayzFee] = useState(0)

  const [totalConfirmAmount, setTotalConfirmAmount] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      const dashboardInfo = AltoonCMSUserStore.dashboardInfoByToonId

      // console.log('dashboardInfo', dashboardInfo)

      const totalSettlementAmount = Object.values(dashboardInfo).reduce(
        (sum, item) =>
          sum + (item.settlementAmount !== '-' ? item.settlementAmount : 0),
        0,
      )
      setTotalSettlementAmount(totalSettlementAmount)

      const totalAppStoreFee = Object.values(dashboardInfo).reduce(
        (sum, item) =>
          sum +
          (item.settlementAppStoreFeeAmount !== '-'
            ? item.settlementAppStoreFeeAmount || 0
            : 0),
        0,
      )
      setTotalAppStoreFeeFee(totalAppStoreFee)

      const totalAlwayzFee = Object.values(dashboardInfo).reduce(
        (sum, item) =>
          sum +
          (item.settlementAlwayzFeeAmount !== '-'
            ? item.settlementAlwayzFeeAmount || 0
            : 0),
        0,
      )
      setTotalAlwayzFee(totalAlwayzFee)

      const totalConfirmAmount = Object.values(dashboardInfo).reduce(
        (sum, item) =>
          sum +
          (item.settlementConfirmAmount !== '-'
            ? item.settlementConfirmAmount || 0
            : 0),
        0,
      )
      setTotalConfirmAmount(totalConfirmAmount)
    }
    fetchData()
  }, [AltoonCMSUserStore.dashboardInfoByToonId])

  return (
    <>
      <div className='flex flex-col flex-1 h-full select-none'>
        <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md rounded-xl'>
          {AltoonCMSUserStore.loadingDashboardInfo ? (
            <div className='text-lg font-bold'>
              정산 내역을 불러오는 중입니다.
            </div>
          ) : (
            <>
              <div className='flex justify-between items-center mb-4'>
                <h1 className='text-xl font-bold'>정산 현황</h1>
                {/* <button
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                  onClick={async () => {
                    const confirmResult = window.confirm(
                      '정말 정산 현황을 다운로드 하시겠습니까? 오랜 시간이 걸릴 수 있습니다.',
                    )
                  }}
                >
                  정산 대상 내역 다운로드
                </button> */}
              </div>
              <table className='border-transparent'>
                <tbody>
                  <tr>
                    <TableData className={className}>정산 대상액</TableData>
                    <TableData className={className2}>
                      {commaNumber(totalSettlementAmount || 0)}원
                    </TableData>
                  </tr>
                  <tr>
                    <TableData className={className}>
                      정산 제외액(앱스토어 수수료)
                    </TableData>
                    <TableData className={className2}>
                      {commaNumber(totalAppStoreFee || 0)}원
                    </TableData>
                  </tr>
                  <tr>
                    <TableData className={className}>
                      정산 제외액(올웨이즈 수수료)
                    </TableData>
                    <TableData className={className2}>
                      {commaNumber(Math.ceil(totalAlwayzFee) || 0)}원
                    </TableData>
                  </tr>
                  <tr>
                    <TableData className={`${className}`}>
                      정산 확정액
                    </TableData>
                    <TableData className={`${className2} text-theme-500`}>
                      {commaNumber(Math.ceil(totalConfirmAmount) || 0)}원
                    </TableData>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </>
  )
})

export default SettlementInfo
