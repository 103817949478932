import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import THModalStore from '../../../stores/treasureHunt/THModalStore'
import TreasureHuntButton from '../TreasureHuntButton'

function renderDescription(text) {
  return text.split('\n').map((line, index) => (
    <span key={Math.random()}>
      {line}
      {index < text.split('\n').length - 1 && <br />}
    </span>
  ))
}

const ApplePangModal = ({ config }) => {
  const [, set] = useState()

  return (
    <div className='default justify-center items-center'>
      <div className='default font-bold text-center text-[6vw] mb-4'>{`${
        config?.amount ? commaNumber(config?.amount) : ''
      } ${config?.rewardName}`}</div>
      <img
        alt='main'
        src={config.src}
        className={`h-[30vw] ${config?.imgClassName}`}
      />

      <div
        className={`default font-bold text-center text-[4.5vw] mt-6 ${config?.descriptionClassName}`}
      >
        {renderDescription(`${config?.description}`)}
      </div>
      <TreasureHuntButton
        className='mt-6 w-full py-3'
        clickable
        text={config?.text ?? '닫기'}
        onClick={() => {
          THModalStore?.close()
          if (config?.onClick) {
            config?.onClick()
          }
        }}
      />
    </div>
  )
}

export default observer(ApplePangModal)
