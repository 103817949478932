import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import THUserStore from '../../../stores/treasureHunt/THUserStore'
import TreasureHuntButton from '../TreasureHuntButton'
import TreasureHuntTimer from '../TreasureHuntTimer'
import MissionButton from './MissionButton'

const DailyMissionList = observer(() => {
  const MissionItem = observer(({ mission }) => {
    const [title, setTitle] = useState('')
    const [unit, setUnit] = useState('원')
    const [missionType, setMissionType] = useState('일일')
    const [expired, setExpired] = useState(false)
    const [buttonText, setButtonText] = useState('보상 받기')

    useEffect(() => {
      if (mission.type === 'catch') {
        setTitle('발견하기')
      }
      if (mission?.rewardType === 'point') {
        setUnit('원')
      } else if (mission?.rewardType === 'energy') {
        setUnit('개')
      }

      if (
        moment().isAfter(moment(mission?.endAt)) &&
        !mission?.clearedAt &&
        !mission?.receivedAt
      ) {
        setExpired(true)
        setButtonText('시간 초과')
      }

      if (mission?.receivedAt) {
        setButtonText('받기 완료')
      } else if (mission?.clearedAt) {
        setButtonText('보상 받기')
      }
    }, [])

    return (
      <div className='flex-shrink-0 flex flex-row justify-between items-center w-full bg-[#FFF6F6] rounded-lg px-3 pb-2 pt-[1.5vw] text-[#313138] mb-2'>
        <div>
          <div className='font-bold w-full mt-1 mb-2 text-[#313138] flex flex-row justify-between items-start'>
            <div className='flex flex-row'>
              <img
                className='z-[10] w-[6vw] h-[6vw] mr-1'
                alt='emoji'
                src={`../images/treasureHunt/emoji/${mission?.emojiType}/${mission?.emojiId}.png`}
              />
              {`${title}`}
            </div>
          </div>
          <div className='flex flex-row justify-between items-end w-full'>
            <div className='font-bold text-[#595864] flex flex-row justify-start items-center'>
              <img
                className='z-[10] w-[4vw] h-[4vw] mr-1'
                alt='emoji'
                src={`../images/treasureHunt/${mission?.rewardType}.png`}
              />
              {`${mission?.rewardAmount}${unit}`}
            </div>
          </div>
        </div>
        <TreasureHuntButton
          className='w-[30vw] font-bold text-[4vw] shadow-none'
          style={{
            padding: '2vw 0',
          }}
          clickable={mission?.clearedAt && !mission?.receivedAt && !expired}
          text={buttonText}
          onClick={() => {
            TreasureHuntAction.getMissionReward({ mission })
          }}
        />
      </div>
    )
  })

  return (
    <div className='flex flex-col w-full justify-start items-center overflow-x-scroll py-2'>
      {THUserStore?.dailyMission?.missions?.length > 0 &&
        [...THUserStore?.dailyMission?.missions]
          ?.sort((a, b) => {
            const now = new Date()

            // endAt이 지난 경우를 확인
            const aExpired = new Date(a.endAt) < now
            const bExpired = new Date(b.endAt) < now
            if (aExpired && !bExpired) return 1
            if (!aExpired && bExpired) return -1

            // 첫 번째 우선순위: clearedAt이 있고 receivedAt은 없는 객체
            const aFirstPriority = a.clearedAt && !a.receivedAt
            const bFirstPriority = b.clearedAt && !b.receivedAt
            if (aFirstPriority && !bFirstPriority) return -1
            if (!aFirstPriority && bFirstPriority) return 1

            // 두 번째 우선순위: clearedAt이 없는 객체, 이러한 객체들은 endAt으로 정렬
            const aSecondPriority = !a.clearedAt
            const bSecondPriority = !b.clearedAt
            if (aSecondPriority && bSecondPriority) {
              return new Date(a.endAt) - new Date(b.endAt) // endAt로 정렬
            }
            if (aSecondPriority) return -1
            if (bSecondPriority) return 1

            // 세 번째 우선순위: receivedAt이 있는 객체
            return 0
          })
          .map((mission) => {
            return <MissionItem key={Math.random()} mission={mission} />
          })}
    </div>
  )
})

export default DailyMissionList
