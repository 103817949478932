import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useSearchParam } from 'react-use'
import ConfirmAlert from 'src/components/atoms/confirmAlert'
import { RedP, RedPoint, YellowPoint } from 'src/images/dailyCheckIn'
import DailyCheckInModalHandler from 'src/modals/dailyCheckIn/DailyCheckInModalHandler'
import AuthStore from 'src/stores/AuthStore'
import UserStore from 'src/stores/UserStore'
import backendApis from 'src/utils/backendApis'

const MoneyTreePointExchangePage = observer(() => {
  const navigate = useNavigate()
  const token = useSearchParam('token')
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [modalConfig, setModalConfig] = useState({ visible: false })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    AuthStore.setToken(token)
    setIsLoading(false)
  }, [])

  const HeaderComponent = () => {
    return (
      <section className='z-10 py-2 overflow-hidden'>
        <button
          className='px-3 py-2 whitespace-nowrap'
          onClick={() => {
            navigate(`/money-tree?token=${token}`)
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-white' />
        </button>
      </section>
    )
  }

  const PointStatusComponent = () => {
    return (
      <div className='w-full h-1/5 flex flex-col items-start justify-center text-[#0A6054] p-4'>
        <div className='text-xl'>
          {UserStore?.userInfo?.userName || '회원'}님의 보유금액
        </div>
        <div className='flex flex-row items-end justify-between w-full mt-2'>
          <div className='flex flex-row items-center text-4xl text-white'>
            <YellowPoint className='w-16 h-16 mr-2' />
            <div
              style={{
                fontSize: 52,
              }}
              className='flex flex-row items-end'
            >
              {/* {commaNumber(UserStore?.moneyTreePoints || 0)} */}
              <div className='ml-2 text-2xl'>원</div>
            </div>
          </div>
          {/* <div className='flex flex-row items-center text-[#C9EBE6]'>
            사용 내역
            <FiChevronRight className='w-4 h-4 stroke-[#C9EBE6]' />
          </div> */}
        </div>
      </div>
    )
  }

  const RewardListComponent = () => {
    const ExchangeComponent = ({ reward, cost, eventCost }) => {
      return (
        <button
          className='flex flex-row items-center justify-between w-full px-2 py-4 my-4 bg-white rounded-2xl'
          onClick={() => {
            setModalConfig({
              ...modalConfig,
              visible: true,
              modalType: 'MoneyTreeExchange',
              reward,
              cost,
            })
          }}
        >
          <div className='flex flex-row items-center'>
            <RedPoint className='w-6 h-6 mr-2' />
            {commaNumber(reward)} 올포인트
          </div>
          <div className='flex flex-row items-center'>
            {eventCost && (
              <div className='flex flex-col items-center text-xs text-[#989898] mr-2'>
                <div>할인 이벤트!</div>
                <div className='line-through text-xs text-[#989898]'>
                  {commaNumber(eventCost)}원
                </div>
              </div>
            )}
            <div className='px-4 py-2 bg-[#19AD98] rounded-full text-white text-base'>
              {commaNumber(cost)}원
            </div>
          </div>
        </button>
      )
    }
    return (
      <div className='w-full p-4 rounded-t-2xl text-xl bg-[#F3F3F3] h-4/5'>
        <div className='px-2 py-4 text-2xl'>포인트 환전</div>
        <ExchangeComponent reward={1000} cost={1200} />
        <ExchangeComponent reward={1500} cost={1500} eventCost={1600} />
        <ExchangeComponent reward={2000} cost={2000} eventCost={2200} />
        <ExchangeComponent reward={3000} cost={3000} eventCost={3300} />
        <ExchangeComponent reward={5000} cost={5000} eventCost={5500} />
        <ExchangeComponent reward={10000} cost={10000} eventCost={11000} />
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <div className='bg-[#19AD98] w-full jua' style={{ height: '100vh' }}>
      <HeaderComponent />
      <PointStatusComponent />
      <RewardListComponent />

      {isLoading && <LoadingIndicator />}
      {modalConfig?.visible && (
        <DailyCheckInModalHandler
          setModalConfig={setModalConfig}
          {...modalConfig}
        />
      )}
      {openLoginModal && (
        <ConfirmAlert
          modalTitle='로그인을 해주세요'
          modalContent='로그인을 해주셔야 출석체크를 사용할 수 있어요.'
          buttonText='확인'
          buttonLink={() => {
            setOpenLoginModal(false)
            window.location.href = `#navigate.${JSON.stringify({
              screen: 'MainBMyInfoScreen',
            })}`
          }}
          showModal
          setShowModal={setOpenLoginModal}
          onlyOneButton
          noFontWeight
        />
      )}
    </div>
  )
})

export default MoneyTreePointExchangePage
