import styled from 'styled-components';

export const OpacityMask = styled.div<{
  fading: boolean;
  color: string;
}>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  opacity: ${({ fading }) => (fading ? 1 : 0)};
  background: ${({ color }) => color};
  transition: opacity 0.25s ease-out;
  pointer-events: none;
`;
