import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import Header from '../../components/atoms/header'
import Banner from '../../components/deal/banner/banner'
import BrandDealItemComponent from '../../components/deal/item/brandDealItemComponent'
import VerticalList from '../../components/deal/item/verticalList'
import AuthStore from '../../stores/AuthStore'
import backendApis from '../../utils/backendApis'

const BrandDealPage = observer(() => {
  const [dealItemsInfo, setDealItemsInfo] = useState([])
  const [loadNum, setLoadNum] = useState(0)
  const SCREEN_HEIGHT = window.innerHeight
  const dealType = 'brandDeal'
  const token = AuthStore.token
  const fetchDealItem = async (dealType, loadNum) => {
    const result = await backendApis.getDealItemsV3(dealType, loadNum)
    if (result?.status === 200) {
      setDealItemsInfo((arr) => [...arr, ...result?.data])
      setLoadNum(loadNum + result?.data?.length)
    }
  }

  const renderItem = (index, data, isScrolling) => {
    if (!data)
      return (
        <BrandDealItemComponent
          key={`${index}`}
          empty
          version='vertical'
          isFrom='BrandDealPage'
          isScrolling={isScrolling}
        />
      )
    return (
      <BrandDealItemComponent
        key={`${index}`}
        itemInfo={data}
        version='vertical'
        isFrom='BrandDealPage'
        isScrolling={isScrolling}
      />
    )
  }

  useEffect(() => {
    if (token) fetchDealItem(dealType, loadNum)
  }, [token])

  return (
    <div>
      <Header title='오늘의 브랜드딜' goBackRoute='/deal/time-deal' />
      <div className='pt-12'>
        <Banner type={dealType} version='screen' token={token} />
        <VerticalList
          itemData={dealItemsInfo}
          height={SCREEN_HEIGHT}
          renderItem={renderItem}
          isFrom='BrandDealPage'
          isItemList
        />
      </div>
    </div>
  )
})
export default BrandDealPage
