import GroupPurchaseStore from '../stores/GroupPurchaseStore'
import backendApis from './backendApis'

const groupPurchaseWithdrawUtil = async (
  token,
  accountInfo,
  setAccountInfo,
  userInfo,
) => {
  const { bankCode, bankName, account, receiver } = accountInfo

  const result = await backendApis.groupPurchaseWithdraw(token, 'POST', {
    bankCode,
    bankName,
    bankAccountNumber: account,
    receiver,
    userInfo,
  })
  if (result?.status === 2000) {
    // 입금 성공 혹은 입금 대기 중
    GroupPurchaseStore.openWithdrawSuccessModal()
    setAccountInfo({
      bankCode: null,
      bankName: null,
      account: null,
      receiver: null,
    })
  } else if (result?.status === 2001) {
    // 돈 모자름
    GroupPurchaseStore.openOneButtonModal({
      title: '안내',
      firstLineText: '리워드가 부족해요',
    })
  } else if (result?.status === 2002) {
    // 계좌 정보 없음
    GroupPurchaseStore.openOneButtonModal({
      title: '안내',
      firstLineText: '계좌번호를 재확인해주세요',
    })
  } else if (result?.status === 2004) {
    // 입금 실패
    GroupPurchaseStore.openOneButtonModal({
      title: '안내',
      firstLineText: '예금주명을 확인해주세요',
    })
  } else if (result?.status === 2005) {
    // 오늘 이미 신청함
    GroupPurchaseStore.openOneButtonModal({
      title: '안내',
      firstLineText: '오늘은 이미 출금 신청을 했어요',
    })
  } else if (result?.status === 2006) {
    // 오늘 이미 신청함
    GroupPurchaseStore.openOneButtonModal({
      title: '이용제한 안내',
      firstLineText: '이용이 제한된 계정이에요',
    })
  } else {
    // 원인 불명
    GroupPurchaseStore.openOneButtonModal({
      title: '안내',
      firstLineText: '네트워크 오류 발생 (2007)',
    })
  }
  return result?.status
}

export default groupPurchaseWithdrawUtil
