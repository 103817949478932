import commaNumber from 'comma-number'
import React from 'react'

import { CouponIcon, CouponPackBanner } from '../images/dailyCheckIn'
import backendApis from '../utils/backendApis'

const CouponHorizontalList = ({ couponPackData, setOpenCouponModal }) => {
  return (
    <div className='flex flex-col p-5 mx-[4vw] my-4 bg-white rounded-xl'>
      <div className='items-end justify-between pb-3 mx-1 text-xl font-bold'>
        행운의 쿠폰팩 뽑기
      </div>
      <div className='flex flex-row items-center justify-start overflow-x-scroll'>
        {couponPackData.length > 0 &&
          couponPackData[0]?.couponInfo?.map((coupon) => {
            return (
              <button
                type='button'
                key={coupon.couponUniqueNumber + Math.random()}
                className='relative flex flex-col items-center justify-center w-32 p-4 mx-2 rounded-lg h-28 drop-shadow outline-0'
                onClick={() => {
                  setOpenCouponModal(true)
                }}
              >
                <div className='h-24 w-28'>
                  <div className='text-2xl font-bold'>
                    {commaNumber(coupon.value)}
                    {coupon.couponType === 'won' ? '원' : ''}
                  </div>
                  <div className='text-xs text-gray-500'>
                    {coupon.condition > 0
                      ? `${commaNumber(coupon.condition)}원 이상 구매`
                      : '조건 없음'}
                  </div>
                </div>
                <CouponIcon className='absolute top-0 left-0 w-full h-full -z-10' />
              </button>
            )
          })}
        {(!couponPackData || couponPackData.length === 0) && (
          <button
            type='button'
            onClick={() => {
              backendApis.recordEventLog(
                'checkInCouponPack',
                'pressCouponPackBanner',
              )
              setOpenCouponModal(true)
            }}
          >
            <CouponPackBanner className='' />
          </button>
        )}
      </div>
    </div>
  )
}

export default CouponHorizontalList
