import React, { Component } from 'react'

class ScratchCard extends Component {
  constructor(props) {
    super(props)
    this.state = { loaded: false, mouseX: 0, mouseY: 0 }
  }

  componentDidMount() {
    this.isDrawing = false
    this.lastPoint = null
    this.ctx = this.canvas.getContext('2d')

    const image = new Image()
    image.crossOrigin = 'Anonymous'
    image.onload = () => {
      this.ctx.drawImage(image, 0, 0, this.props.width, this.props.height)
      this.setState({ loaded: true })
    }
    image.src = this.props.image
  }

  handlePercentage(filledInPixels = 0) {
    if (filledInPixels > this.props.finishPercent) {
      this.canvas.parentNode.removeChild(this.canvas)
      this.setState({ finished: true })
      if (this.props.onComplete) {
        this.props.onComplete()
      }
    }
  }

  getFilledInPixels(stride) {
    if (!stride || stride < 1) {
      stride = 1
    }

    const pixels = this.ctx.getImageData(
      0,
      0,
      this.canvas.width,
      this.canvas.height,
    )
    const total = pixels.data.length / stride
    let count = 0

    for (let i = 0; i < pixels.data.length; i += stride) {
      if (parseInt(pixels.data[i], 10) === 0) {
        count++
      }
    }

    return Math.round((count / total) * 100)
  }

  getMouse(e, canvas) {
    const { top, left } = canvas.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

    return {
      x: (e.pageX || e.touches[0].clientX) - left - scrollLeft,
      y: (e.pageY || e.touches[0].clientY) - top - scrollTop,
    }
  }

  distanceBetween(point1, point2) {
    return Math.sqrt((point2.x - point1.x) ** 2 + (point2.y - point1.y) ** 2)
  }

  angleBetween(point1, point2) {
    return Math.atan2(point2.x - point1.x, point2.y - point1.y)
  }

  handleMouseDown(e) {
    this.isDrawing = true
    this.lastPoint = this.getMouse(e, this.canvas)
  }

  handleMouseMove(e) {
    if (!this.isDrawing) {
      return
    }

    const currentPoint = this.getMouse(e, this.canvas)
    const distance = this.distanceBetween(this.lastPoint, currentPoint)
    const angle = this.angleBetween(this.lastPoint, currentPoint)

    let x
    let y

    for (let i = 0; i < distance; i++) {
      x = this.lastPoint.x + Math.sin(angle) * i
      y = this.lastPoint.y + Math.cos(angle) * i
      this.setState({
        mouseX: x - this.props.customBrush?.width / 2,
        mouseY: y - this.props.customBrush?.height / 2,
      })

      this.ctx.globalCompositeOperation = 'destination-out'
      this.ctx.beginPath()
      this.ctx.arc(x, y, this.props.brushSize || 20, 0, 2 * Math.PI, false)
      this.ctx.fill()
    }

    this.lastPoint = currentPoint
    this.handlePercentage(this.getFilledInPixels(32))
  }

  handleMouseUp() {
    this.isDrawing = false
  }

  render() {
    const brushStyle = {
      position: 'absolute',
      left: this.state.mouseX,
      top: this.state.mouseY,
      pointerEvents: 'none', // 마우스 이벤트가 이미지에 영향을 미치지 않도록
      zIndex: 2,
      width: `${this.props.customBrush?.width}px`,
      height: `${this.props.customBrush?.height}px`,
    }

    const containerStyle = {
      width: `${this.props.width}px`,
      height: `${this.props.height}px`,
      position: 'relative',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
    }

    const canvasStyle = {
      position: 'absolute',
      top: 0,
      zIndex: 1,
      ...this.props.style,
    }

    const resultStyle = {
      visibility: this.state.loaded ? 'visible' : 'hidden',
    }

    const canvasProps = {
      ref: (ref) => (this.canvas = ref),
      className: 'ScratchCard__Canvas',
      style: canvasStyle,
      width: this.props.width,
      height: this.props.height,
      onMouseDown: this.handleMouseDown.bind(this),
      onTouchStart: this.handleMouseDown.bind(this),
      onMouseMove: this.handleMouseMove.bind(this),
      onTouchMove: this.handleMouseMove.bind(this),
      onMouseUp: this.handleMouseUp.bind(this),
      onTouchEnd: this.handleMouseUp.bind(this),
    }

    return (
      <div className='ScratchCard__Container' style={containerStyle}>
        {this.state.mouseX !== 0 && this.props.customBrush && (
          <img
            src={this.props.customBrush?.image}
            style={brushStyle}
            alt='brush'
          />
        )}

        <canvas {...canvasProps} />
        <div className='ScratchCard__Result' style={resultStyle}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default ScratchCard
