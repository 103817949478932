import { useQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import backendApis from '../../../../utils/backendApis'
import { CommentSortType } from '../../../../utils/interface'
import { novelChapterCommentListQuery } from '../../remotes/comment'
import { BottomSheet } from '../Bottomsheet'
import { useConfirmModal } from '../Confirm'
import { Divider } from '../Divider'
import { useToast } from '../Toast'
import { Comment } from './Comment'
import { CommentSkeleton } from './CommentSkeleton'

interface CommentListProps {
  chapterId: string
  novelId: string
}

export const CommentList = observer(
  ({ chapterId, novelId }: CommentListProps) => {
    const navigate = useNavigate()
    const { open } = useConfirmModal()
    const { openToast } = useToast()

    const { data: bestCommentList, refetch: refetchBest } = useQuery(
      novelChapterCommentListQuery({
        chapterId,
        novelId,
        sortType: CommentSortType.bestWithoutMe,
        count: 3,
        skip: 0,
      }),
    )
    const { data: newCommentList, refetch: refetchNew } = useQuery(
      novelChapterCommentListQuery({
        chapterId,
        novelId,
        sortType: CommentSortType.newWithoutMeAndBest,
        count: 3,
        skip: 0,
      }),
    )
    const { data: myCommentList, refetch: refetchMy } = useQuery(
      novelChapterCommentListQuery({
        chapterId,
        novelId,
        sortType: CommentSortType.my,
        count: 3,
        skip: 0,
      }),
    )

    const refetchEvery = () => {
      refetchBest()
      refetchNew()
      refetchMy()
    }

    const likeComment = async (commentId: string, setLiked: boolean) => {
      await backendApis.likeNovelChapterComment({
        chapterId,
        novelId,
        commentId,
        setLiked,
      })

      refetchEvery()
    }

    const deleteComment = async (commentId: string) => {
      const isConfirmed = await open({
        title: '댓글을 삭제할까요?',
        description: '삭제한 댓글은 더 이상 보이지 않아요.',
        confirmText: '삭제하기',
        cancelText: '취소',
      })

      if (!isConfirmed) {
        return
      }

      await backendApis.deleteNovelChapterComment({
        chapterId,
        novelId,
        commentId,
      })

      openToast({
        text: '댓글을 삭제했어요.',
      })

      refetchEvery()
    }

    const reportComment = async (commentId: string) => {
      const isConfirmed = await open({
        title: '댓글을 신고할까요?',
        description: '신고된 댓글은 검수 후 삭제됩니다.',
        confirmText: '신고하기',
        cancelText: '취소',
      })

      if (!isConfirmed) {
        return
      }

      await backendApis.reportNovelChapterComment({
        chapterId,
        novelId,
        commentId,
        type: 'report',
      })

      openToast({
        text: '신고를 완료했어요.',
      })

      refetchEvery()
    }

    const blockComment = async (commentId: string) => {
      const isConfirmed = await open({
        title: '댓글을 차단할까요?',
        description: '차단한 댓글은 더 이상 보이지 않아요.',
        confirmText: '차단하기',
        cancelText: '취소',
      })

      if (!isConfirmed) {
        return
      }

      await backendApis.reportNovelChapterComment({
        chapterId,
        novelId,
        commentId,
        type: 'block',
      })

      openToast({
        text: '차단을 완료했어요.',
      })

      refetchEvery()
    }

    const navigateToCommentList = () => {
      navigate('/altoon-novel-comment-list', {
        state: {
          novelId,
          chapterId,
        },
      })
    }

    if (myCommentList?.totalLength === 0) {
      return <CommentSkeleton />
    }

    return (
      <>
        {myCommentList?.data.map((item) => (
          <>
            <Comment
              isLiked={item.isLiked}
              isMyComment={item.isMine}
              userName={item.userName}
              comment={item.comment}
              likeCount={item.liked}
              onDelete={() => deleteComment(item._id)}
              onLike={(setLiked) => likeComment(item._id, setLiked)}
              onBlock={() => blockComment(item._id)}
              onReport={() => reportComment(item._id)}
              createdAt={item.createdAt}
            />
            <Divider />
          </>
        ))}
        {bestCommentList?.data.map((item) => (
          <>
            <Comment
              isBest
              isLiked={item.isLiked}
              isMyComment={item.isMine}
              userName={item.userName}
              comment={item.comment}
              likeCount={item.liked}
              onBlock={() => blockComment(item._id)}
              onReport={() => reportComment(item._id)}
              createdAt={item.createdAt}
            />
            <Divider />
          </>
        ))}
        {newCommentList?.data.map((item) => (
          <>
            <Comment
              isLiked={item.isLiked}
              isMyComment={item.isMine}
              userName={item.userName}
              comment={item.comment}
              likeCount={item.liked}
              onBlock={() => blockComment(item._id)}
              onReport={() => reportComment(item._id)}
              createdAt={item.createdAt}
            />
            <Divider />
          </>
        ))}
        <Divider />

        <NavigateComment onClick={() => navigateToCommentList()}>
          댓글 더 보기
        </NavigateComment>
      </>
    )
  },
)

const NavigateComment = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
  color: #4f4f4f;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-top: 16px;
  margin-bottom: 60px;
  border: 1px solid #eeeff3;
  border-radius: 8px;
`
