import { observer } from 'mobx-react-lite'
import React from 'react'

import { Close } from '../../images/dailyCheckIn'
import backendApis from '../../utils/backendApis'

const DailyCheckInNewUserRewardMultiply = observer(({ visible, close }) => {
  return (
    <div>
      {/* 딤드 배경 */}
      <button
        type='button'
        className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-10'
        onClick={async () => {
          await backendApis.setDailyCheckInPushApprovedLog(false)
          close()
        }}
        aria-label='close'
      />

      {/* 모달 상자 */}
      <div
        style={{ left: '17%', top: '20%', borderRadius: 8, height: '52vh' }}
        className='w-2/3 fixed bg-white z-20'
      />
      <button
        type='button'
        aria-label='close'
        className='fixed z-30 p-1 bg-gray-300 rounded-full'
        style={{ left: '74%', top: '22%' }}
        onClick={async () => {
          await backendApis.setDailyCheckInPushApprovedLog(false)
          close()
        }}
      >
        <Close className='w-6 h-6' />
      </button>

      {/* 모달 내용 + 받기 버튼 */}
      <div
        style={{ left: '20%', top: '28%', width: '60%', height: '40vh' }}
        className='fixed z-30 flex flex-col justify-between'
      >
        <div className='flex flex-col font-bold justify-center items-center'>
          <span className='text-xl mb-3'>첫 출석 기념!</span>
          <span className='text-6xl mb-4'>🎉 </span>
          <span className='text-xl mb-3'> 내일 출석하면 포인트</span>
          <span className='text-3xl mb-3 text-red-500'> 5배!</span>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <button
            type='button'
            className='p-3 w-4/5 mb-2 bg-red-500 rounded text-base text-white font-bold'
            onClick={async () => {
              await backendApis.setDailyCheckInPushApprovedLog(true)
              close()
            }}
          >
            내일 알림 받을래요
          </button>
        </div>
      </div>
    </div>
  )
})

export default DailyCheckInNewUserRewardMultiply
