class DailyCheckInAction {
  showRewardedAd() {
    window?.ReactNativeWebView?.postMessage?.(
      JSON.stringify({
        type: 'showRewardedAd',
        data: {
          adType: 'rewarded',
          placement: 'DAILYCHECKIN_MISSION_REWARD',
          product: 'DAILYCHECKIN',
        },
      }),
    )
  }

  goExternalbrowser(url) {
    if (url) {
      window?.ReactNativeWebView?.postMessage?.(
        JSON.stringify({
          type: 'externalBrowser',
          data: {
            url,
          },
        }),
      )
    }
  }

  getIFA() {
    window?.ReactNativeWebView?.postMessage?.(
      JSON.stringify({ type: 'getIFA' }),
    )
  }
}

export default new DailyCheckInAction()
