export interface Comment {
  novelId: string
  _id: string
  chapterId: string
  userId: string
  userName: string
  comment: string
  type: string
  liked: number
  isLiked: boolean
  disliked: number
  createdAt: string
  isMine: boolean
}

export interface LoadNovelChapterCommentList {
  status: number
  data: Comment[]
  totalLength: number
}

export enum CommentSortType {
  my = 'my',
  bestWithoutMe = 'bestWithoutMe',
  newWithoutMeAndBest = 'newWithoutMeAndBest',
  bestAll = 'bestAll',
  new = 'new',
}
