import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import Button from '../../components/atoms/button'
import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import AuthStore from '../../stores/AuthStore'
import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

const MyInfoScreen = observer(() => {
  const profile = 'profile image'
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassWord, setNewPassWord] = useState('')
  const [verifyNewPassword, setVerifyNewPassword] = useState('')

  return (
    <>
      <div className='grid w-full grid-cols-2 gap-5 my-10 min-w-7xl max-w-7xl'>
        <div className='text-xl font-bold text-sub-500 text-center col-start-1 col-end-3'>
          <div className='flex flex-col items-center justify-center mb-10 text-xl font-bold'>
            <div className='pt-10'>
              <button
                type='button'
                onClick={() => {
                  AltoonCMSUserStore?.setCurrentScreen('main')
                }}
              >
                <img
                  alt={profile}
                  src={AltoonCMSUserStore?.providerInfo?.providerImageUri}
                  className='w-28 rounded-full h-[auto]'
                />
              </button>
            </div>
            <div className='pt-6'>
              <h1>{AltoonCMSUserStore?.providerInfo?.providerDisplayName}</h1>
            </div>
            <div className='text-sm pt-2'>
              {AltoonCMSUserStore?.providerInfo?.address}
            </div>
          </div>
          <>
            <div className='flexCol bg-white border px-4 py-5 text-gray-700 text-sm'>
              <div className='flexRow'>
                <div className='w-[15vw] whitespace-nowrap text-start'>
                  현재 비밀번호
                </div>
                <input
                  type='password'
                  name='password'
                  placeholder='현재 비밀번호를 입력해주세요'
                  value={currentPassword}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value)
                  }}
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='flexRow'>
                <div className='w-[15vw] whitespace-nowrap text-start'>
                  새 비밀번호
                </div>
                <input
                  type='password'
                  name='password'
                  placeholder='새 비밀번호를 입력해주세요'
                  value={newPassWord}
                  onChange={(e) => {
                    setNewPassWord(e.target.value)
                  }}
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='flexRow'>
                <div className='w-[15vw] whitespace-nowrap text-start'>
                  새 비밀번호 확인
                </div>
                <input
                  type='password'
                  name='password'
                  placeholder='새 비밀번호를 다시 한번 입력해주세요'
                  value={verifyNewPassword}
                  onChange={(e) => {
                    setVerifyNewPassword(e.target.value)
                  }}
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
            </div>

            <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
              <Button
                type='submit'
                size='md'
                onClick={async (e) => {
                  e.preventDefault()

                  if (verifyNewPassword !== newPassWord) {
                    alert('새 비밀번호를 확인해주세요')
                    return
                  }
                  if (
                    verifyNewPassword === newPassWord &&
                    newPassWord?.length > 0 &&
                    currentPassword?.length > 0
                  ) {
                    const tempToken = localStorage.getItem(
                      '@altoon@provider@token@',
                    )

                    const result = await altoonCMSBackendApis.changePassword(
                      tempToken ?? AuthStore?.token,
                      {
                        providerId: AltoonCMSUserStore?.providerInfo?._id,
                        currentPassword,
                        newPassWord,
                      },
                    )
                    if (result) {
                      alert(
                        `${
                          result?.data?.message ||
                          '오류가 발생했습니다. 담당자에게 문의해주세요.'
                        }`,
                      )
                    }
                  }
                }}
              >
                비밀번호 변경하기
              </Button>
            </div>
          </>
        </div>
      </div>
    </>
  )
})

export default MyInfoScreen
