import commaNumber from 'comma-number'

const DiscountTag = ({ gap }) => {
  return (
    <>
      <div className='px-2 py-1 text-xs font-bold text-red-500 rounded-md bg-red-50 w-max'>
        {commaNumber(gap)}원 추가 할인
      </div>
    </>
  )
}
export default DiscountTag
