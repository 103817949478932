import ModalStore from '../stores/ModalStore'
import UserStore from '../stores/UserStore'
import backendApis from './backendApis'

const log = {
  dealLog: (action, stage) =>
    backendApis.recordDealLog(
      UserStore?.sessionId,
      UserStore?.targetEventGame,
      action,
      stage,
      ModalStore?.currentModal?.type,
    ),
}
export default log
