import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import UserStore from '../stores/UserStore'

const ErrorPage = observer(() => (
  <div>
    <div>Sorry, Error page</div>
  </div>
))

export default ErrorPage
