import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import TextTimer from '../../components/TextTimer'
import Header from '../../components/atoms/header'
import TreasureDealItemComponent from '../../components/deal/item/treasureDealItemComponent'
import VerticalList from '../../components/deal/item/verticalList'
import dealImages from '../../images/deal'
import AuthStore from '../../stores/AuthStore'
import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'
import log from '../../utils/log'

const TreasureDealPage = observer(() => {
  const { state } = useLocation()
  const token = AuthStore?.token
  const hours = state?.hours || 0
  const minutes = state?.minutes || 30
  const [dealItemsInfo, setDealItemsInfo] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [dealEndedAt, setDealEndedAt] = useState()
  const [indices, setIndices] = useState([])
  const loadNum = dealItemsInfo?.length
  const dealType = 'treasureDeal'
  const version = 'categoryExpansion'
  const condition = {}
  const SCREEN_HEIGHT = window.innerHeight

  const initialFetch = async (version) => {
    await fetchDealItem(dealType, loadNum, version, [], condition, true)
  }

  const loadDealUserStatus = async (dealType) => {
    const result = await backendApis.getUserDealStatus()
    if (result?.status === 2000) {
      const { dealAuth } = result?.data
      const matchedDeal = dealAuth?.find((item) => item?.dealType === dealType)
      const { endedAt } = matchedDeal
      setIsOpen(new Date(endedAt) > new Date())
      setDealEndedAt(new Date(endedAt))
      UserStore.setUserDealStatus(result?.data)
    }
  }

  const fetchDealItem = async (
    dealType,
    loadNum,
    version,
    indices,
    condition,
    first = false,
  ) => {
    const itemIndices = indices.slice(0, 32)
    setIndices((arr) => arr.slice(32))
    const result = await backendApis.getDealItemsV3(
      dealType,
      loadNum,
      version,
      itemIndices,
      condition,
    )
    dealStateSetter(result, first)
  }

  const dealStateSetter = (result, first) => {
    if (result?.status === 200) {
      if (first) {
        setDealItemsInfo(result?.data)
      } else {
        setDealItemsInfo((arr) => [...arr, ...result?.data])
      }
      if (result?.itemIndices?.length > 0) {
        setIndices(result?.itemIndices)
      }
    }
  }

  const renderItem = (index, data, isScrolling) => {
    return (
      <TreasureDealItemComponent
        key={`${index}`}
        itemInfo={data}
        version={version}
        dealEndedAt={dealEndedAt}
        isFrom='TreasureDealPage'
        isScrolling={isScrolling}
      />
    )
  }
  const Skeleton = () => {
    return (
      <>
        <TreasureDealItemComponent empty dealEndedAt={dealEndedAt} />
        <TreasureDealItemComponent empty dealEndedAt={dealEndedAt} />
        <TreasureDealItemComponent empty dealEndedAt={dealEndedAt} />
        <TreasureDealItemComponent empty dealEndedAt={dealEndedAt} />
        <TreasureDealItemComponent empty dealEndedAt={dealEndedAt} />
      </>
    )
  }

  const loadMore = async () => {
    const itemIndices = indices.slice(0, 32)
    setIndices((arr) => arr.slice(32))

    await fetchDealItem(
      dealType,
      loadNum,
      version,
      itemIndices,
      condition,
      false,
    )
  }

  useEffect(() => {
    if (!isOpen) loadDealUserStatus(dealType)
    initialFetch('categoryExpansion')
  }, [token])

  return (
    <div className='bg-[#171717]'>
      <Header
        title='보물특가'
        bgColor='[#171717]'
        textColor='white'
        callbackFunction={() => {
          ModalStore.setCurrentModal({
            open: true,
            type: 'eventDealExit',
            event: '/deal/time-deal',
            closeCallback: () => log.dealLog('give_up', 'reward'),
            confirmCallback: () => log.dealLog('give_up', 'reward'),
          })
        }}
      />
      <div className='pt-12' />
      {isOpen ? (
        <>
          <div className='flex flex-row items-center justify-between flex-1 px-2 py-2'>
            <div className='font-bold text-white'>특가 종료까지</div>
            <TextTimer
              endedAt={new Date(dealEndedAt)}
              boxCss='text-white font-bold text-base bg-[#171717] rounded'
              dotCss='text-base text-white'
            />
          </div>
          <div className='px-2 mb-3'>
            <img
              src={dealImages?.TreasureDealMainImage}
              alt='TreasureDealMainImage'
            />
          </div>
          {dealItemsInfo?.length > 0 ? (
            <VerticalList
              itemData={dealItemsInfo}
              height={SCREEN_HEIGHT}
              renderItem={renderItem}
              loadMore={loadMore}
              isFrom='TreasureDealPage'
              isItemList
            />
          ) : (
            <Skeleton />
          )}
        </>
      ) : (
        <div className='flex items-center justify-center flex-col flex-1 w-screen h-screen bg-[#171717]'>
          <img
            src={dealImages?.Locked}
            alt='Locked'
            className='mb-10 w-28 h-28'
          />
          <div className='text-lg text-white'>보물특가 접근 권한이 없어요.</div>
          <div className='mb-5 text-lg text-white'>다음에 만나요!</div>
        </div>
      )}
    </div>
  )
})

export default TreasureDealPage
