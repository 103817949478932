import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'

import WatermelonAction from '../../actions/WatermelonAction'
import AuthStore from '../../stores/AuthStore'
import WatermelonUserStore from '../../stores/WatermelonUserStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import utility from '../../utils/utility'
import WatermelonRankItem from './WatermelonRankItem'
import ContactPermissionModal from './modals/ContactPermissionModal'

const WatermelonRanking = observer(() => {
  const [currentTab, setCurrentTab] = useState('all')
  const scrollContainerRef = useRef(null)
  const [showsContactsModal, setShowsContactsModal] = useState(false)

  useEffect(() => {
    // currentTab이 변경될 때마다 실행
    if (scrollContainerRef.current) {
      // 스크롤 위치를 0으로 설정
      scrollContainerRef.current.scrollTop = 0
    }
  }, [])

  useEffect(() => {
    if (currentTab === 'all') {
      WatermelonUserStore.set('rankLoadDone', false)

      WatermelonAction.getRankingAll()
    } else {
      WatermelonUserStore.set('rankLoadDone', false)
      if (utility.codepushVersionCheck(AuthStore.codePushVersion, '6.5.24')) {
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'requestContactPermissionOnly' }),
        )
      } else {
        WatermelonAction.checkContactPermission()
      }
    }
  }, [currentTab])

  useEffect(() => {
    if (
      currentTab !== 'all' &&
      utility.codepushVersionCheck(AuthStore.codePushVersion, '6.5.24')
    ) {
      if (WatermelonUserStore.contactPermission) {
        WatermelonAction.checkContactPermission()
      } else {
        setShowsContactsModal(true)
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: `친구 랭킹`,
            src: `../watermelonAssets/smile.png`,
            description: `올웨이즈에서 친구의 랭킹을 확인하기 위해 연락처 접근과 수집 동의가
        필요해요`,
            forceOpen: true,
            text: '동의하기',
            onClick: () => {
              WatermelonAction.checkContactPermission()
            },
          },
        })
      }
    }
  }, [currentTab, WatermelonUserStore.contactPermission])

  return (
    <div
      className='default flex relative justify-center items-center w-full p-4 pt-0 text-[#313138] overflow-y-scroll'
      style={{
        fontFamily: 'maplestory',
      }}
    >
      {utility.codepushVersionCheck(AuthStore.codePushVersion, '4.3.29') && (
        <div className='flex flex-row w-full justify-around items-center py-2 '>
          <div
            className={`px-4 ${
              currentTab === 'all'
                ? 'font-bold border-b-2 border-b-[#313138]'
                : 'font-normal'
            }`}
            onClick={() => {
              setCurrentTab('all')
            }}
          >
            전체 랭킹
          </div>
          <div
            className={`px-4 ${
              currentTab === 'local'
                ? 'font-bold border-b-2 border-b-[#313138]'
                : 'font-normal'
            }`}
            onClick={() => {
              setCurrentTab('local')
            }}
          >
            친구 랭킹
          </div>
          <div
            className={`px-4 ${
              currentTab === 'alltimeBest'
                ? 'font-bold border-b-2 border-b-[#313138]'
                : 'font-normal'
            }`}
            onClick={() => {
              setCurrentTab('alltimeBest')
            }}
          >
            내 최고 점수
          </div>
        </div>
      )}
      <div
        className='block h-[70vh] overflow-y-scroll px-2 pointer-events-auto w-full pt-4 space-y-4'
        ref={scrollContainerRef}
      >
        {currentTab !== 'alltimeBest' ? (
          <>
            <div className='-mb-2'>내 랭킹</div>

            <div className='w-full bg-[beige] pr-4 rounded-xl relative'>
              <WatermelonRankItem
                key={Math.random()}
                user={WatermelonUserStore?.userData}
                currentTab={currentTab}
                index={
                  currentTab === 'all'
                    ? WatermelonUserStore?.myRank - 3
                    : WatermelonUserStore?.myRankLocal - 3
                }
              />
            </div>
          </>
        ) : (
          <>
            <div className='-mb-2'>내 역대 최고 점수</div>

            <div className='w-full bg-[beige] pr-4 rounded-xl relative'>
              <WatermelonRankItem
                key={Math.random()}
                user={{
                  ...WatermelonUserStore?.userData,
                  highscore: WatermelonUserStore?.userData?.allTimeHighscore,
                }}
                currentTab={currentTab}
                index={0}
              />
            </div>
          </>
        )}
        {currentTab !== 'alltimeBest' && (
          <>
            <div className='flex flex-row w-full space-x-2 justify-around relative'>
              <div className='absolute right-0 top-0 text-[2.6vw] font-[#313138]'>
                *매주 일요일 초기화
              </div>
              <div className='flex flex-col justify-center items-center h-[50vw] flex-1 relative pt-[20vw]'>
                <img
                  className='absolute z-[0] bottom-0'
                  src='/watermelonAssets/rankStand2.png'
                  alt=''
                />
                <div className='z-[1] flex flex-col justify-center items-center'>
                  <img
                    className='w-10 h-10 rounded-full'
                    src={
                      WatermelonUserStore?.rankData?.[1]?.userImageUri ??
                      WatermelonUserStore?.DEFAULT_IMAGE
                    }
                    onError={(e) => {
                      e.target.src = WatermelonUserStore?.DEFAULT_IMAGE
                    }}
                    alt='mission profile'
                  />
                  <div className='text-[3.5vw] text-center mt-1'>
                    {WatermelonUserStore?.rankData?.[1]?.userName ?? ''}
                  </div>
                  <div className='text-[4vw] font-bold text-center'>{`${commaNumber(
                    WatermelonUserStore?.rankData?.[1]?.highscore ?? 0,
                  )}점`}</div>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center h-[50vw] flex-1 relative pt-[10vw]'>
                <img
                  className='absolute z-[0] bottom-0'
                  src='/watermelonAssets/rankStand1.png'
                  alt=''
                />
                <div className='z-[1] flex flex-col justify-center items-center'>
                  <img
                    className='w-10 h-10 rounded-full'
                    src={
                      WatermelonUserStore?.rankData?.[0]?.userImageUri ??
                      WatermelonUserStore?.DEFAULT_IMAGE
                    }
                    onError={(e) => {
                      e.target.src = WatermelonUserStore?.DEFAULT_IMAGE
                    }}
                    alt='mission profile'
                  />
                  <div className='text-[3.5vw] text-center mt-1'>
                    {WatermelonUserStore?.rankData?.[0]?.userName ?? ''}
                  </div>
                  <div className='text-[4vw] font-bold text-center'>{`${commaNumber(
                    WatermelonUserStore?.rankData?.[0]?.highscore ?? 0,
                  )}점`}</div>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center h-[50vw] flex-1 relative pt-[20vw]'>
                <img
                  className='absolute z-[0] bottom-0'
                  src='/watermelonAssets/rankStand3.png'
                  alt=''
                />
                <div className='z-[1] flex flex-col justify-center items-center'>
                  <img
                    className='w-10 h-10 rounded-full'
                    src={
                      WatermelonUserStore?.rankData?.[2]?.userImageUri ??
                      WatermelonUserStore?.DEFAULT_IMAGE
                    }
                    onError={(e) => {
                      e.target.src = WatermelonUserStore?.DEFAULT_IMAGE
                    }}
                    alt='mission profile'
                  />
                  <div className='text-[3.5vw] text-center mt-1'>
                    {WatermelonUserStore?.rankData?.[2]?.userName ?? ''}
                  </div>
                  <div className='text-[4vw] font-bold text-center'>{`${commaNumber(
                    WatermelonUserStore?.rankData?.[2]?.highscore ?? 0,
                  )}점`}</div>
                </div>
              </div>
            </div>

            {WatermelonUserStore?.rankData?.slice(3)?.map((user, i) => {
              return (
                <WatermelonRankItem key={Math.random()} user={user} index={i} />
              )
            })}
          </>
        )}
      </div>
    </div>
  )
})

export default WatermelonRanking
