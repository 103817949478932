export function throttle<T extends (...args: any[]) => any>(
  func: T,
  delay: number,
  trailing: boolean = true, // trailing 옵션 추가
): (...args: Parameters<T>) => ReturnType<T> | void {
  let lastCall = 0
  let timeoutId: ReturnType<typeof setTimeout> | null = null
  let lastArgs: Parameters<T> | null = null

  return function (...args: Parameters<T>): ReturnType<T> | void {
    const now = new Date().getTime()
    const remainingTime = delay - (now - lastCall)

    if (remainingTime <= 0 || remainingTime > delay) {
      // 딜레이 기간이 지나면 즉시 실행
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
      }
      lastCall = now
      return func(...args)
    }

    if (trailing) {
      // 마지막 호출 저장
      lastArgs = args
      if (!timeoutId) {
        timeoutId = setTimeout(() => {
          lastCall = new Date().getTime()
          timeoutId = null
          if (lastArgs) {
            func(...lastArgs)
            lastArgs = null
          }
        }, remainingTime)
      }
    }
  }
}
