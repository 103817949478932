import { RxChevronLeft as LeftIcon } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'

import ItemImpressionStore from '../../stores/ItemImpressionStore'
import UserStore from '../../stores/UserStore'
import backHandler from '../../utils/backHandler'
import useCheckCodePushVersion from '../../utils/useConvertCodePushVersion'

const Header = ({
  title = '스크린 타이틀',
  bgColor = 'white',
  textColor = 'gray-800',
  callbackFunction = 'callbackFunction', // 콜백펑션 필요할 때는 함수형태로 전달.
  isWebViewEntrance = false,
}) => {
  const navigate = useNavigate()
  const codePushVersionAvailable = useCheckCodePushVersion()

  const handleGoBack = () => {
    if (callbackFunction !== 'callbackFunction') {
      callbackFunction()
      return
    }

    if (
      codePushVersionAvailable(UserStore?.userInfo?.codepushVersion, '2.5.49')
    ) {
      backHandler()
    } else {
      // older versions
      if (isWebViewEntrance) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'goBack',
          }),
        )
        ItemImpressionStore.uploadAndClearImpressions()
        return
      }
      navigate('/deal/time-deal')
    }
  }
  return (
    <section
      className={`fixed h-12 t-0 z-50 w-full flex flex-row items-center flex-1 py-2 overflow-hidden ${`bg-${bgColor}`}`}
    >
      <button
        type='button'
        className='z-10 flex items-center justify-center flex-1 w-10 h-12'
        onClick={handleGoBack}
      >
        <LeftIcon className={`w-8 h-8 ${`text-${textColor}`}`} />
      </button>

      <div
        className={`-ml-12 items-center justify-center w-full text-lg font-bold text-center ${`text-${textColor}`}`}
      >
        {title}
      </div>
    </section>
  )
}
export default Header
