import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { FcExpand, FcInternal, FcNext } from 'react-icons/fc'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import Button from 'src/components/atoms/button'
import { Autoplay, FreeMode, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

import {
  BronzeBox,
  DiamondBox,
  ExpBadge,
  ExpPocket,
  GoldBox,
  SilverBox,
} from '../images/treasureBox'

const TreasureBoxTutorialPage = observer(() => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  return (
    <>
      <section className='flex flex-row overflow-hidden h-12 w-screen z-10 relative items-center'>
        <button
          className='flex flex-1 py-2 px-3 whitespace-nowrap'
          onClick={() => navigate('/treasurebox')}
        >
          <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
        </button>
        <div className='p-3 w-full text-center text-gray-800 font-medium'>
          보물상자 안내
        </div>
        <div className='flex w-[60px]'>{` `}</div>
      </section>

      <div className='mb-12'>
        <h2 className='p-6 text-2xl font-bold'>
          보물상자를 열면 <br />
          <span className='text-positive-500'>최대 99% 할인</span>
          상품들이 나타나요
        </h2>
        <div className='p-6 mt-6 flex flex-row text-gray-800'>
          <Swiper
            slidesPerView={2}
            spaceBetween={30}
            enteredSlides
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
            }}
            loop
            pagination={{
              type: 'none',
            }}
            modules={[Autoplay, FreeMode, Pagination]}
          >
            <SwiperSlide key='0'>
              <div className='flex flex-col'>
                <img
                  className='rounded-lg'
                  width={177}
                  height={177}
                  src='https://assets.ilevit.com/4e78855a-7839-4813-a77d-13b63d7520a7_original.jpg'
                />
                <div>
                  <div className='mt-1'>키토제닉 황태껍질튀각</div>
                  <div className='flex flex-row mt-1 '>
                    <div className='text-sm mr-0.5 font-bold text-[#333333]'>
                      98%
                    </div>
                    <div className='text-sm line-through text-[#999999]'>
                      6,700원
                    </div>
                  </div>
                  <div className='text-[#FF3E3E] font-bold'>100원</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide key='1'>
              <div className='flex flex-col'>
                <img
                  className='rounded-lg'
                  width={177}
                  height={177}
                  src='https://assets.ilevit.com/48444ce4-9b13-48c6-aaf4-9d4ebafe0af3_original.jpg'
                />
                <div>
                  <div className='mt-1'>대용량 레이스 기름종이</div>
                  <div className='flex flex-row mt-1 '>
                    <div className='text-sm mr-0.5 font-bold text-[#333333]'>
                      97%
                    </div>
                    <div className='text-sm line-through text-[#999999]'>
                      8,500원
                    </div>
                  </div>
                  <div className='text-[#FF3E3E] font-bold'>210원</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide key='2'>
              <div className='flex flex-col'>
                <img
                  className='rounded-lg'
                  width={177}
                  height={177}
                  src='https://assets.ilevit.com/296dcd5b-14a8-4b06-8dad-db7c10beb1ae_original.jpg'
                />
                <div>
                  <div className='mt-1'>기모본딩팬츠 3종 택 1</div>
                  <div className='flex flex-row mt-1'>
                    <div className='text-sm mr-0.5 font-bold text-[#333333]'>
                      99%
                    </div>
                    <div className='text-sm line-through text-[#999999]'>
                      49,000원
                    </div>
                  </div>
                  <div className='text-[#FF3E3E] font-bold'>380원</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide key='3'>
              <div className='flex flex-col'>
                <img
                  className='rounded-lg'
                  width={177}
                  height={177}
                  src='https://assets.ilevit.com/038f039a-3e44-4e45-b055-2c7fefa35f1e_original.jpg'
                />
                <div>
                  <div className='mt-1'>만능 다용도 스틱</div>
                  <div className='flex flex-row mt-1 '>
                    <div className='text-sm mr-0.5 font-bold text-[#333333]'>
                      94%
                    </div>
                    <div className='text-sm line-through text-[#999999]'>
                      1,900원
                    </div>
                  </div>
                  <div className='text-[#FF3E3E] font-bold'>100원</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide key='4'>
              <div className='flex flex-col'>
                <img
                  className='rounded-lg'
                  width={177}
                  height={177}
                  src='https://assets.ilevit.com/61a0e6f7-9bf7-415e-b3f1-789698242cdb_original.jpg'
                />
                <div>
                  <div className='mt-1'>구운김밥김 김밥용 김</div>
                  <div className='flex flex-row mt-1'>
                    <div className='text-sm mr-0.5 font-bold text-[#333333]'>
                      92%
                    </div>
                    <div className='text-sm line-through text-[#999999]'>
                      5,900원
                    </div>
                  </div>
                  <div className='text-[#FF3E3E] font-bold'>450원</div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div className='mb-12'>
        <h2 className='p-6 text-2xl font-bold'>보물상자는 이렇게 열어요</h2>
        <div className='flex flex-col'>
          <div className='p-6 flex flex-row text-lg font-medium text-gray-600'>
            <span className='text-positive-500 pr-4'>1 </span>
            <div>
              <div className=' '>
                <span className='shadow-md rounded-md px-1 mr-1 text-[#129DEF] font-bold'>
                  <ExpBadge className='inline-block w-4 h-4 mr-0.5' />
                  경험치
                </span>
                상품을 구매해 경험치를 얻어요
              </div>
              <div className='py-2 text-sm font-normal text-gray-500 leading-relaxed'>
                얻은 경험치는 구매완료 화면에서 확인 가능해요 <br />
              </div>
            </div>
          </div>
          <div className='px-6 py-3 flex flex-row bg-gray-100 items-center justify-center'>
            <div className='flex flex-col'>
              <div className='flex flex-col p-4 shadow-lg rounded-lg bg-white scale-75'>
                <img
                  className='rounded-lg'
                  width={177}
                  height={177}
                  src='https://assets.ilevit.com/936f7c88-92ab-484d-a22e-de507ad3c246_original.jpg'
                />
                <div>
                  <div className='flex flex-row items-center mt-2 text-xs text-[#129DEF] font-bold '>
                    <ExpBadge className='flex w-4 h-4 mr-1' />
                    경험치
                  </div>
                  <div className='mt-1'>햇 꿀고구마 3kg</div>
                  <div className='flex flex-row mt-1'>
                    <div className='text-sm mr-0.5 font-bold text-[#333333]'>
                      68%
                    </div>
                    <div className='text-sm line-through text-[#999999]'>
                      18,900원
                    </div>
                  </div>
                  <div className='text-[#FF3E3E] font-bold'>6,000원</div>
                </div>
              </div>
            </div>

            <FcNext className='mr-4 w-8 h-8 flex animate-pulse' />

            <div className='flex flex-col items-center'>
              <div className='flex flex-col p-4 text-center justify-center items-center shadow-lg rounded-lg bg-white scale-90'>
                <div className='mb-2 py-0.5 px-2 text-sm font-medium rounded-md text-gray-600 bg-gray-200 whitespace-nowrap'>
                  구매완료 화면
                </div>
                <div className='mb-1 py-1 text-xs text-gray-600 whitespace-nowrap'>
                  경험치를 확인해보세요!
                </div>
                <ExpPocket className='w-16 h-16 flex' />
                <FcInternal className='w-8 h-8 my-1 animate-pulse flex' />
                <div className='text-[#129DEF] font-bold text-base'>
                  10,573점
                </div>
              </div>
            </div>
          </div>
          <div className='p-6 flex flex-row text-lg font-medium text-gray-600'>
            <span className='text-positive-500 pr-4'>2 </span>
            <div>
              <div className=' '>
                경험치 1만부터
                <span className='text-positive-500 font-bold ml-1'>
                  보물상자
                </span>
                를 열 수 있어요
              </div>
              <div className='py-2 text-sm font-normal text-gray-500 leading-relaxed'>
                실버는 2만{`, `}
                <span className='text-amber-600 '>골드는 3만</span>
                {`, `}
                <span className='text-cyan-600 '>다이아몬드는 4만</span>이
                필요하고
                <br />
                <span className='text-orange-900 '>브론즈는 경험치 1만</span>
                을 소비해 열 수 있어요
                <br />
              </div>
            </div>
          </div>
          <div className='p-6 flex flex-col bg-gray-100'>
            <div className='flex flex-1 flex-col p-4 shadow-lg rounded-lg bg-white scale-75'>
              <div className='flex flex-col'>
                <div className='text-gray-500 text-xs'>현재 경험치</div>
                <div className='font-bold text-xl'>
                  <ExpBadge className='w-4 h-4 mr-1 inline-block' />
                  10,000
                </div>
              </div>
              <div className='flex flex-1 h-6 my-2 bg-gray-200 rounded-md'>
                <div
                  className='relative flex justify-between flex-row h-2 items-center px-2 bg-gradient-to-r from-blue-500 to-cyan-500 text-white duration-300 ease-in-out rounded-md'
                  style={{ width: `20%` }}
                />
              </div>
              <div className='mb-5 flex justify-between text-xs text-center text-gray-400'>
                <div> </div>
                <div>1만</div>
                <div>2만</div>
                <div>3만</div>
                <div>4만</div>
                <div className='text-gray-500'>MAX</div>
              </div>
            </div>
            <div className='flex flex-col items-center justify-center '>
              <FcExpand className='w-8 h-8 animate-bounce' />
              <button className='p-2 m-2 items-center justify-center text-xs bg-positive-100 text-positive-600 rounded-lg shadow-lg'>
                <BronzeBox className='w-20 h-20' />
                브론즈
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='mb-24'>
        <h2 className='p-6 text-2xl font-bold'>
          <span className='text-lg font-medium text-gray-600'>
            <span className='text-positive-500'>높은 등급 상자</span>
            를 열수록
            <br />
          </span>
          다양한 상품이 <br />
          <span className='text-positive-500'>더 많이 할인</span>돼요
        </h2>
        <div className='p-4 mt-6 flex flex-row text-gray-800'>
          <button
            type='button'
            className='m-2 text-center text-xs  w-full transition ease-in-out duration-300 rounded-lg'
          >
            <BronzeBox className=' ' />
            브론즈
          </button>
          <button
            type='button'
            className='m-2 text-center text-xs  w-full transition ease-in-out duration-300 rounded-lg'
          >
            <SilverBox className=' ' />
            실버
          </button>
          <button
            type='button'
            className='m-2 text-center text-xs  w-full transition ease-in-out duration-300 rounded-lg'
          >
            <GoldBox className='' />
            골드
          </button>
          <button
            type='button'
            className='p-2 m-2 text-center text-xs  w-full rounded-lg shadow-lg shadow-blue-500/40 bg-positive-100 text-positive-600 scale-125 font-medium'
          >
            <DiamondBox className='' />
            다이아몬드
          </button>
        </div>
      </div>
      <div className='bg-gray-100'>
        <div className='p-6 text-lg font-bold'>안내사항</div>
        <div className='px-6 pb-24 text-sm text-start text-gray-500 leading-loose'>
          • 경험치 표시가 있는 상품을 구매하면, 보물상자를 열 수 있는 경험치를
          얻어요. <br />
          • 얻은 경험치는 구매완료, 보물상자 화면에 보여요.
          <br />
          • 경험치를 소비해 보물상자를 열 수 있으며 각 상자별로 필요한 경험치는
          다음과 같아요.
          <br />
          → 브론즈: 1만, 실버: 2만, 골드: 3만, 다이아몬드: 4만
          <br />
          • 보물상자는 제한없이 여러번 열 수 있어요.
          <br />• 높은 등급의 보물상자일수록 할인폭이 크고 더 많은 상품이
          나타나요. (브론즈 {`<`} 실버 {`<`} 골드 {`<`} 다이아몬드)
          <br />
          • 팀구매가 취소되면 경험치가 회수돼요. <br />
        </div>
      </div>

      <div className='fixed flex flex-1 w-full px-4 pt-8 pb-2 bottom-0 bg-gradient-to-t from-white via-white'>
        <Button
          appearance=' '
          size='flexlg'
          className='bg-red-200 h-14 rounded-2xl'
          onClick={() => navigate('/treasurebox')}
        >
          <span className='text-base font-semibold text-red-600'>
            보물상자 열어보러 가기
          </span>
        </Button>
      </div>

      {isLoading && (
        <div className='z-10 flex items-center justify-center flex-1'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={150}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      )}
    </>
  )
})

export default TreasureBoxTutorialPage
