import moment from 'moment'
import React, { useState } from 'react'

import { Close } from '../../images/dailyCheckIn'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'
import ToonModal from '../templates/ToonModal'

const ToonCommentComponent = ({
  comment,
  index,
  type,
  myCommentLikedData,
  toonInfo,
}) => {
  const [modalType, setModalType] = useState('')
  const [modalProp, setModalProp] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [likeCount, setLikeCount] = useState(comment?.liked)
  const [likedComment, setLikedComment] = useState(
    myCommentLikedData?.includes(comment?._id?.toString()),
  )
  const myComment =
    comment?.userId.toString() === UserStore?.userInfo?._id.toString()

  const UserProfile = () => {
    return (
      <div className='flex flex-row items-center'>
        {(type === 'my' || type === 'best') && (
          <img
            src={
              myComment
                ? '/images/toon/myCommentIcon.png'
                : '/images/toon/bestCommentIcon.png'
            }
            alt='block'
            className='w-[9vw] h-[4vw] mr-2'
          />
        )}
        <div className='font-semibold'>
          {comment?.userName?.length > 10
            ? `${comment?.userName.slice(0, 10)}..`
            : comment?.userName}
        </div>
        <div className='pl-2 text-xs text-gray-400'>
          {moment(comment?.createdAt).format('YYYY.MM.DD HH:mm:ss')}
        </div>
      </div>
    )
  }

  const DeleteComment = () => {
    return (
      <button
        type='button'
        aria-label='delete'
        className='px-[1vw] bg-gray-300 rounded'
        onClick={() => {
          setModalType('deleteComment')
          setModalProp({
            commentId: comment?._id,
            toonInfo,
          })

          setIsModalOpen(true)
        }}
      >
        <Close className='w-[2.8vw] h-[2.8vw]' />
      </button>
    )
  }

  const BlockReportComment = () => {
    return (
      <div className='flex flex-row'>
        <button
          type='button'
          className='mr-3'
          onClick={() => {
            setModalType('blockComment')
            setModalProp({
              commentId: comment?._id,
              toonInfo,
            })
            setIsModalOpen(true)
          }}
        >
          <img
            src='/images/toon/blockIcon.png'
            alt='block'
            className='w-[4.2vw] h-[4.2vw]'
          />
        </button>
        <button
          type='button'
          onClick={() => {
            setModalType('reportComment')
            setModalProp({
              commentId: comment?._id,
              toonInfo,
            })
            setIsModalOpen(true)
          }}
        >
          <img
            src='/images/toon/reportIcon.png'
            alt='block'
            className='w-[4.2vw] h-[4.2vw]'
          />
        </button>
      </div>
    )
  }

  const LikeComponent = () => {
    return (
      <button
        type='button'
        className={`px-3 py-2 flex flex-row items-center justify-center rounded border ${
          likedComment
            ? 'bg-red-500 text-white font-bold border-red-500'
            : 'bg-white'
        }`}
        onClick={async () => {
          try {
            // API 호출 시 현재 liked 상태의 반대 값을 전달
            await backendApis.logCommentLike(
              comment?._id,
              !likedComment, // 현재 liked의 반대 값을 전달
            )
            // API 호출이 성공적으로 완료된 후 liked 상태 업데이트
            setLikedComment(!likedComment)

            if (!likedComment) {
              setLikeCount(likeCount + 1)
            } else if (likedComment) {
              setLikeCount(likeCount - 1)
            }
          } catch (error) {
            console.error('Error toggling like:', error)
          }
        }}
      >
        <img
          key={index}
          src={
            likedComment
              ? '/images/toon/toonHeart.png'
              : '/images/toon/toonHeartFilled.png'
          }
          alt='like'
          className='w-[3.8vw] h-[3.8vw]'
        />
        <div className='ml-1 text-[3.4vw]'>{likeCount > 0 ? likeCount : 0}</div>
        {/* 싫어요 버튼 추후 구현 예정 */}
        {/* <div className='px-2 py-1 border'>{comment?.disliked}</div> */}
      </button>
    )
  }

  return (
    <div className='py-4 flex flex-col w-full items-start border-b border-[#F5F5F5]'>
      <div className='pb-2 flex flex-row w-full justify-between'>
        <UserProfile />
        {myComment && <DeleteComment />}
        {!myComment && <BlockReportComment />}
      </div>

      <div className='text-sm'>{comment?.comment}</div>
      <div className='pt-2 flex flex-row w-full justify-between text-xs'>
        <div className='px-2 py-1 flex flex-row items-center mr-2'>
          {/* 답글 추후 구현 예정 */}
          {/* 답글 <div className='font-bold ml-1'>1</div> */}
        </div>
        <LikeComponent />
      </div>
      {isModalOpen && (
        <ToonModal
          modalName={modalType}
          modalProp={modalProp}
          setOpenTicketModal={setIsModalOpen}
        />
      )}
    </div>
  )
}
export default ToonCommentComponent
