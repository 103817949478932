import { RefObject, useEffect } from 'react';
import { DocumentAlign } from '../types/viewerOptions';
import { useEventCallback } from 'usehooks-ts';
import { SEQUENCE_SEPARATOR } from '../remotes/novelChapter';
import { viewerStore } from '../store';

export const useRenderEngine = ({
  contentRef,
  documentAlign,
  hasCover,
  totalPages,
  updateTotalPages,
}: {
  contentRef: RefObject<HTMLDivElement>;
  documentAlign: DocumentAlign;
  hasCover: boolean;
  totalPages: number;
  updateTotalPages: (pages: number) => void;
}) => {
  const calculateTotalPages = useEventCallback((lastElem: HTMLElement) => {
    const totalContentPages =
      Math.floor(lastElem.offsetLeft / window.innerWidth) +
      (lastElem.getClientRects().length - 1); // 마지막 줄이 넘어가는 경우 대응
    const extraPage = 1; // 추가 페이지
    const coverPages = hasCover ? 1 : 0;

    const newTotalPages = totalContentPages + coverPages + extraPage;

    // console.log('calculateTotalPages: ', totalPages, newTotalPages);
    if (totalPages !== newTotalPages) {
      updateTotalPages(newTotalPages);
    }
  });

  const addPaddingToSectionEnds = useEventCallback(
    (sectionEndElems: HTMLElement[]) => {
      sectionEndElems.forEach((sectionElem) => {
        const sectionBottom = Math.floor(
          sectionElem.getBoundingClientRect().bottom
        );
        const paddingBottom = parseInt(
          getComputedStyle(contentRef.current as HTMLDivElement).paddingBottom
        );
        const remainingHeight = Math.max(
          window.innerHeight - sectionBottom - paddingBottom,
          0
        );
        sectionElem.style.marginBottom = `${remainingHeight}px`;
      });
    }
  );

  useEffect(() => {
    
    
    const monitorContent = () => {
      const coreElement = contentRef.current;
      if (coreElement) {
        const lastElement = findLastBlockElement(coreElement);
        const sectionEndElems = Array.from(
          coreElement.getElementsByClassName(SEQUENCE_SEPARATOR)
        ).map((elem) => findLastBlockElement(elem as HTMLDivElement));
        // documentAlign을 클로저로 처리하면 안 됌
        if (viewerStore.options.documentAlign === DocumentAlign.Horizontal) {
          calculateTotalPages(lastElement);
          addPaddingToSectionEnds(sectionEndElems);

        }
      }
      requestAnimationFrame(monitorContent);
    };

    monitorContent();
  }, [
    addPaddingToSectionEnds,
    calculateTotalPages,
    contentRef,
    documentAlign,
    viewerStore.isLoaded,
  ]);
};

const findLastBlockElement = (parent: HTMLElement): HTMLElement => {
  const { children } = parent;

  if (children.length === 0) {
    return parent;
  }

  const lastChildElem = children[children.length - 1] as HTMLElement;

  if (getComputedStyle(lastChildElem).display === 'block') {
    return lastChildElem;
  }

  return parent;
};
