import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import { Close } from '../../images/dailyCheckIn'

const MoneyTreeCloseNoticeModal = observer(({ visible, close, ...params }) => {
  const navigate = useNavigate()
  const token = useSearchParam('token')

  const Title = () => {
    return (
      <div className='pt-3' style={{ fontSize: '7vw' }}>
        서비스 종료 안내
      </div>
    )
  }

  const TextComponent = () => {
    return (
      <div style={{ fontSize: '4vw' }} className='text-[#525052]'>
        <div
          style={{ fontSize: '3vw', textAlign: 'center' }}
          className='text-[#525052]'
        >
          * 본 이벤트는 6월 30일까지만 운영되며 이후 사용이 불가능해요
        </div>
        <div className='h-6' />
        <div className='px-2'>
          '돈나무 흔들기' 이벤트를 사랑해주셔서 감사해요. 더 많은 혜택을 더욱
          재미있게 드리기 위해 이벤트를 재정비해서 돌아올게요.
        </div>
        <div className='h-2' />
      </div>
    )
  }

  const ButtonComponent = () => {
    return (
      <button
        type='button'
        className='w-5/6 z-40 mt-6 py-3 bg-[#008E5B] rounded-xl text-lg text-white'
        onClick={() => {
          navigate(`/daily-check-in?token=${token}`)
        }}
      >
        확인
      </button>
    )
  }

  return (
    <div>
      {/* 딤드 배경 */}
      <button
        type='button'
        className='w-full h-full fixed left-0 top-0 flex items-center justify-center bg-gray-800 opacity-70 z-20'
        onClick={() => {
          navigate(`/daily-check-in?token=${token}`)
        }}
        aria-label='background'
      />
      {/* 모달 상자 */}
      <div
        className='fixed left-1/2 py-6 z-30 bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'
        style={{ top: '48%', width: '90%', height: '38%' }}
      />

      <button
        type='button'
        className='fixed p-2 z-40 bg-gray-300 rounded-full'
        style={{ left: '84%', top: '22%' }}
        onClick={() => {
          navigate(`/daily-check-in?token=${token}`)
        }}
      >
        <Close className='w-6 h-6' />
      </button>

      {/* 모달 내용 */}
      <div
        style={{ top: '28%', left: '5%', width: '90%', height: '78%' }}
        className='p-4 flex flex-col fixed items-center z-30'
      >
        <Title />
        <TextComponent />
        <ButtonComponent />
      </div>
    </div>
  )
})

export default MoneyTreeCloseNoticeModal
