import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import AuthStore from '../../stores/AuthStore'

const ExpirePointModal = observer(({ visible, close, ...params }) => {
  const navigate = useNavigate()

  const Title = () => {
    return (
      <div className='pt-[10vw] font-bold' style={{ fontSize: '6vw' }}>
        소멸 예정 포인트 안내
      </div>
    )
  }

  const TextComponent = () => {
    if (params.expireDuePoints[0]?.expiredPoints > 0) {
      return (
        <div style={{ fontSize: '4vw' }} className='w-full whitespace-nowrap'>
          <div className='py-4 flex flex-col items-center'>
            <div className='font-bold text-[5.6vw]'>
              {commaNumber(params.expireDuePoints[0]?.expiredPoints)}P
            </div>
            <div className='font-normal text-gray-600'>
              이번 달 말에 소멸 예정이에요
            </div>
          </div>
        </div>
      )
    }
    return (
      <div
        style={{ fontSize: '4vw' }}
        className='whitespace-break-spaces w-full border'
      >
        <div className='py-4 flex flex-col items-center'>
          <div>이번 달 말에 소멸 포인트를 확인해보세요</div>
        </div>
      </div>
    )
  }

  const ButtonComponent = () => {
    return (
      <>
        <div className='w-full flex flex-row text-[4.2vw]'>
          <button
            type='button'
            className='py-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
            onClick={close}
          >
            닫기
          </button>
          <button
            type='button'
            className='py-4 w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
            onClick={async () => {
              navigate(`/expire-history?token=${AuthStore.token}&isFrom=web`)
              close()
            }}
          >
            확인하기
          </button>
        </div>
      </>
    )
  }

  return (
    <>
      <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
      <div className='absolute top-[30vh] left-[10vw] z-30 w-4/5 h-[30vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'>
        <Title />
        <TextComponent />
        <ButtonComponent />
      </div>
    </>
  )
})

export default ExpirePointModal
