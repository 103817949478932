import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

const GaugeBar = observer(({ currentExp, totalExp }) => {
  return (
    <>
      <div className='relative w-full'>
        <div className='gauge-bg-treasureHunt w-full'>
          <div
            className='relative gauge-bar-treasureHunt'
            style={{
              width: `${Math.min(100, (currentExp / totalExp) * 100)}%`,
            }}
          />
          <div className='absolute text-center left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-[2.5vw] font-bold'>
            {/* {currentExp} */}
          </div>
        </div>
        <div
          className='absolute rounded-full bg-[#fff] border-2 border-[#E8FEDB] w-[9vw] h-[9vw] top-1/2 -translate-y-1/2 
          left-[10%] -translate-x-1/2 text-center
        font-bold text-[#313138] text-[3.5vw] flex justify-center items-center  
        '
          style={
            currentExp >= 10
              ? { backgroundColor: '#89D35D', color: 'white' }
              : { backgroundColor: '#fff', color: 'black' }
          }
        >
          10
        </div>
        <div
          className='absolute left-[10%] -translate-x-1/2  -bottom-[13vw] 
        whitespace-nowrap text-center font-bold text-[#FF847D] bg-[#FF847D] 
        rounded-full px-4 py-1 text-[3.5vw]'
        >
          <img
            className='w-5 h-5 absolute -top-2 left-1/2 -translate-x-1/2'
            style={{ transform: 'scaleY(-1) translateX(-50%)' }} // 수정된 부분
            src='../images/treasureHunt/triangle.png'
            alt='triangle'
          />
          <div
            className='left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 
          absolute w-full h-full text-white flex flex-col justify-center items-center'
          >
            탐색 실패 방지
          </div>
          탐색 실패 방지
        </div>

        <div
          className='absolute rounded-full bg-[#fff] border-2 border-[#E8FEDB] w-[9vw] h-[9vw] top-1/2 -translate-y-1/2 left-[75%] -translate-x-1/2 text-center
        font-bold text-[#313138] text-[3.5vw] flex justify-center items-center  
        '
          style={
            currentExp >= 75
              ? { backgroundColor: '#489A18', color: 'white' }
              : { backgroundColor: '#fff', color: 'black' }
          }
        >
          75
        </div>
        <div
          className='absolute left-[75%] -translate-x-1/2  -bottom-[13vw] 
        whitespace-nowrap text-center font-bold text-[#FF847D] bg-[#FF847D] 
        rounded-full px-4 py-1 text-[3.5vw]'
        >
          <img
            className='w-5 h-5 absolute -top-2 left-1/2 -translate-x-1/2'
            style={{ transform: 'scaleY(-1) translateX(-50%)' }} // 수정된 부분
            src='../images/treasureHunt/triangle.png'
            alt='triangle'
          />
          <div
            className='left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 
          absolute w-full h-full text-white flex flex-col justify-center items-center'
          >
            탐색 대성공 확률 40%
          </div>
          탐색 대성공 확률 40%
        </div>
      </div>
    </>
  )
})

export default GaugeBar
