import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { Close, PigFoodColored } from '../../images/dailyCheckIn'

const CheckInPigDailyBonusFoodModal = observer(
  ({ visible, close, contents, triggerFoodToast, ...params }) => {
    const currentBonus = JSON.stringify(params?.currentBonus)
    const givenBonusFood = JSON.stringify(params?.givenBonusFood)

    const CloseButton = ({ onPointerDown }) => (
      <button
        type='button'
        className='absolute right-8 top-8 z-40'
        aria-hidden='true'
        onClick={onPointerDown}
      >
        <Close className='w-8 h-8' />
      </button>
    )

    if (contents === 'BonusFoodGiven') {
      return (
        <>
          <div
            className='fixed top-0 left-0 w-full h-full bg-black opacity-80 z-30'
            aria-hidden='true'
          />
          <div
            className='fixed top-1/2 left-1/2 pt-12 z-40 -translate-x-1/2 -translate-y-1/2'
            style={{ width: '100%', height: '100%' }}
          >
            <div className='w-full h-full flex flex-col items-center jua'>
              <div className='h-1/5' />
              <div className='text-3xl top-20 mb-6 text-white'>
                내일 획득 사료
              </div>
              <PigFoodColored className='w-24 h-full' />
              <div className='flex flex-row animate-pulse pt-4 text-lg text-white z-30'>
                <div className='mr-1'>{commaNumber(currentBonus)}</div>
                <div className='mr-1 mt-1 text-base'>➡️</div>
                <div className=''>{commaNumber(givenBonusFood)}g</div>
              </div>

              <div className='mt-4 text-2xl text-white'>오늘 목표를 채워서</div>
              <div className='mt-1 text-2xl text-white'>
                내일 {commaNumber(givenBonusFood)}g 을 받아요!
              </div>
              <div className='h-4' />

              <div
                className='w-4/5 py-3 bg-red-500 rounded-xl text-lg text-white text-center'
                aria-hidden='true'
                onClick={close}
              >
                돈돈이 밥 더 주러가기
              </div>
            </div>
            <CloseButton
              onPointerDown={() => {
                close()
              }}
            />
          </div>
        </>
      )
    }
    if (contents === 'RetrieveBonusFood') {
      return (
        <>
          <div
            className='fixed top-0 left-0 w-full h-full bg-black opacity-80 z-30'
            aria-hidden='true'
          />
          <div
            className='fixed top-1/2 left-1/2 pt-12 z-40 -translate-x-1/2 -translate-y-1/2'
            style={{ width: '100%', height: '100%' }}
          >
            <div className='w-full h-full flex flex-col items-center jua'>
              <div className='h-1/5' />
              <div className='text-3xl top-20 mb-6 text-white'>내일받기</div>
              <PigFoodColored className='w-24 h-full' />
              <div className='flex flex-row animate-pulse pt-4 text-lg text-white z-30'>
                <div className=''>{commaNumber(givenBonusFood)}g</div>
              </div>

              <div className='mt-4 text-2xl text-white'>어제 모은 사료</div>
              <div className='mt-1 text-2xl text-white'>
                {commaNumber(givenBonusFood)}g 을 받아가세요!
              </div>
              <div className='h-4' />

              <div
                className='w-4/5 py-3 bg-red-500 rounded-xl text-lg text-white text-center'
                aria-hidden='true'
                onClick={() => {
                  close()
                  triggerFoodToast()
                }}
              >
                사료 받기
              </div>
            </div>
            <CloseButton
              onPointerDown={() => {
                close()
              }}
            />
          </div>
        </>
      )
    }
  },
)

export default CheckInPigDailyBonusFoodModal
