import { useOverlay } from '@toss/use-overlay';

export type OverlayElement<T> = (props: {
  isOpen: boolean;
  close: (value: T) => void;
  exit: () => void;
}) => JSX.Element;

export const useAsyncOverlay = () => {
  const overlay = useOverlay();

  const openAsync = <T,>(overlayElement: OverlayElement<T>) => {
    return new Promise<T>((resolve) =>
      overlay.open((params) => (
        <>
          {overlayElement({
            ...params,
            close: (value: T) => {
              params.close();
              resolve(value);
            },
          })}
        </>
      ))
    );
  };
  return { openAsync };
};
