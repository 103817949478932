import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import THUserStore from '../../stores/treasureHunt/THUserStore'
import THAchievementItem from './THAchievementItem'

const THAchievement = observer(({ config }) => {
  const scrollContainerRef = useRef(null)

  const [currentTab, setCurrentTab] = useState('onGoing')
  const [achievementArr, setAchievementArr] = useState(
    THUserStore?.achievementList,
  )
  const [maxId, setMaxId] = useState(0)
  const [clearedCount, setClearedCount] = useState(0)

  useEffect(() => {
    // currentTab이 변경될 때마다 실행
    if (scrollContainerRef.current) {
      // 스크롤 위치를 0으로 설정
      scrollContainerRef.current.scrollTop = 0
    }
  }, [currentTab]) // currentTab이 변경될 때마다 useEffect 실행

  useEffect(() => {
    let count = 0
    let clearCount = 0

    for (const achievement of achievementArr) {
      count += achievement?.objectives?.length ?? 0

      const achievementTypeItems =
        THUserStore?.achievement?.[achievement?.type] || {}
      for (const item of Object.values(achievementTypeItems)) {
        if (item?.clearedAt) {
          clearCount += 1
        }
      }
    }

    setMaxId(count)
    setClearedCount(clearCount)
  }, [achievementArr])

  return (
    <div className='default w-full mb-4 text-[#313138]'>
      <div className='flex flex-row justify-between items-center text-[4vw] pt-4 px-2'>
        <div className='flex flex-row pl-2 font-bold pb-4 items-end'>
          {`내가 달성한 업적: ${clearedCount ?? 0}개`}
          <div className='ml-2 text-[#8E8D9B] text-[3.5vw]'>
            {maxId - clearedCount}개 남음
          </div>
        </div>
        <div className='pr-2 font-bold pb-4 text-[#FF847D] text-[3.5vw]'>{`달성률: ${
          Math.round((clearedCount / maxId) * 100) ?? 0
        }%`}</div>
      </div>

      <div
        className='flex flex-col overflow-y-scroll px-2 pointer-events-auto max-h-[60vh] pb-4'
        ref={scrollContainerRef}
      >
        {achievementArr.map((achievement, i) => {
          return <THAchievementItem key={i} achievement={achievement} />
        })}
      </div>
    </div>
  )
})

export default THAchievement
